import ErrorTooltip from 'components/elements/tooltips/ErrorTooltip';
import InsideComponent from 'components/wrappers/InsideComponent';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

const InputErrorHandlerWrapper = ({ id, children, errorProps, style }) => {
    const [checkValidity, error, showError, hideError] = errorProps;

    return (
        <InsideComponent
            onOutsideClick={() => {
                hideError();
            }}
            style={style}
        >
            <div id={id} style={{ position: 'relative' }}>
                {children}
                <CSSTransitionGroup transitionName="tooltip" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                    {error && <ErrorTooltip message={error} />}
                </CSSTransitionGroup>
            </div>
        </InsideComponent>
    );
};

export default InputErrorHandlerWrapper;
