import { buildActionType } from './buildActionType';
import { ActionStatus } from '../core/ActionStatus';
import {
    GET_ALL_COMPANY_ROLES,
    GET_COMPANY_ROLES_PAGINATED,
    GET_ROLES_CONNECTED_TO_SET,
    SAVE_ALL_ROLES,
    SAVE_ALL_ROLES_NEW,
    USERS_MODIFIED_IN_ROLES,
} from '../constants/users.constants';
import {
    fetchRolesApiRequest,
    fetchRolesConnectedToSetApiRequest,
    getRolesPaginatedApiRequest,
    saveAllRolesNewApiRequest,
} from '../../services/api/apiRequests/users';
import { ApiExecutor } from './ApiExecutor';
import _ from 'lodash';

export const updateRoleName = (roleId, newName) => async (dispatch, getState) => {
    let rolesState = getState().userManagement.roles;
    let roles = rolesState.data?.roles;
    let role = roles?.find(e => e.roleId === roleId);
    role.roleName = newName;
    role.modified = true;

    dispatchUpdatedState(rolesState, roles, dispatch);
};

export const removeRole = (roleId, newRole) => async (dispatch, getState) => {
    let rolesState = getState().userManagement.roles;
    let roles = rolesState.data?.roles;

    if (!newRole) {
        let rolesToRemove = rolesState.data.rolesToRemove !== undefined ? rolesState.data.rolesToRemove : [];
        rolesState.data.rolesToRemove = [...rolesToRemove, roleId];
    }

    dispatchUpdatedState(
        rolesState,
        roles.filter(el => el.roleId !== roleId),
        dispatch
    );
};

export const addRole = newNode => async (dispatch, getState) => {
    let rolesState = getState().userManagement.roles;
    let roles = rolesState.data?.roles ? rolesState.data?.roles : [];
    let rolesNew = [...roles, newNode];

    dispatchUpdatedState(rolesState, rolesNew, dispatch);
};

export const removeNameSetFlag = roleId => async (dispatch, getState) => {
    let rolesState = getState().userManagement.roles;
    let roles = rolesState.data?.roles;
    let role = roles?.find(e => e.roleId === roleId);
    role.nameSet = true;
    role.modified = true;

    dispatchUpdatedState(rolesState, roles, dispatch);
};

export const updateTrainingConnection = (roleId, newTrainingId, newTrainingName, setModifiedInTraining) => async (dispatch, getState) => {
    let rolesState = getState().userManagement.roles;
    let roles = rolesState.data?.roles;
    let role = roles?.find(e => e.roleId === roleId);

    let removeTraining = role.connectedSet === newTrainingId;

    role.connectedSet = removeTraining ? null : newTrainingId;
    role.connectedSetName = removeTraining ? '' : newTrainingName;
    role.modified = true;

    if (setModifiedInTraining) {
        rolesState.data.rolesModifiedInTraining = true;
    }

    dispatchUpdatedState(rolesState, roles, dispatch);
};

export const updateRolesInTrainingAction = (roleIdsMap, newTrainingId, newTrainingName, setModifiedInTraining) => async (dispatch, getState) => {
    let rolesState = _.cloneDeep(getState().userManagement.roles);
    let roles = rolesState.data?.roles;

    for (let i = 0; i < Object.keys(roleIdsMap).length; i++) {
        let roleId = Object.keys(roleIdsMap)[i];
        let isConnected = roleIdsMap[roleId];
        let role = roles?.find(e => e.roleId === roleId);

        role.connectedSet = !isConnected ? null : newTrainingId;
        role.connectedSetName = !isConnected ? '' : newTrainingName;
        role.modified = true;

        if (setModifiedInTraining) {
            rolesState.data.rolesModifiedInTraining = true;
        }
    }

    dispatchUpdatedState(rolesState, roles, dispatch);
    dispatch(
        saveModifiedRoles({
            ...rolesState,
            roles: roles,
        })
    );
};

export const saveModifiedRoles = state => async dispatch => {
    let filtered = state.data.roles.filter(r => r.modified === true);
    let rolesToRemove = state.data.rolesToRemove;

    if (filtered?.length > 0 || rolesToRemove?.length > 0) {
        dispatch({ type: buildActionType(SAVE_ALL_ROLES_NEW, ActionStatus.START) });

        let data = {
            roles: filtered,
            rolesToRemove: rolesToRemove,
        };

        let response = await saveAllRolesNewApiRequest(data);

        if (response.success) {
            dispatch({
                type: buildActionType(SAVE_ALL_ROLES_NEW, ActionStatus.DONE),
                payload: response.data,
            });
        } else {
            dispatch({
                type: buildActionType(SAVE_ALL_ROLES_NEW, ActionStatus.FAILED),
                payload: response.data,
            });

            return;
        }

        let modifiedRoles = response.data;
        let updatedRolesState = [...state.data.roles];

        modifiedRoles.forEach(r => {
            let id = r.previousId ? r.previousId : r.roleId;

            let index = updatedRolesState.findIndex(role => role.roleId === id);

            updatedRolesState[index] = r;
        });

        dispatch({
            type: buildActionType(GET_ALL_COMPANY_ROLES, ActionStatus.DONE),
            payload: {
                roles: updatedRolesState,
                rolesToRemove: [],
                rolesModifiedInTraining: false,
            },
        });
    }
};

export const setRoles = data => async dispatch => {
    dispatch({ type: buildActionType(GET_ALL_COMPANY_ROLES, ActionStatus.DONE), payload: { ...data } });
};

export const resetRoleChangesAction = () => async dispatch => {
    dispatch({ type: buildActionType(GET_ALL_COMPANY_ROLES, ActionStatus.RESET) });
    dispatch({ type: buildActionType(USERS_MODIFIED_IN_ROLES, ActionStatus.RESET) });
};

export const resetSaveAllRoles = () => async dispatch => {
    dispatch({ type: buildActionType(SAVE_ALL_ROLES, ActionStatus.RESET) });
};

export const getAllRoles = () => async dispatch => {
    dispatch({
        type: buildActionType(GET_ALL_COMPANY_ROLES, ActionStatus.START),
    });

    let response = await fetchRolesApiRequest();

    dispatch({
        type: buildActionType(GET_ALL_COMPANY_ROLES, ActionStatus.DONE),
        payload: {
            roles: response.data,
            rolesModifiedInTraining: false,
        },
    });
};

export const getRolesConnectedToSetAction = setId => async dispatch => {
    await dispatch(ApiExecutor(fetchRolesConnectedToSetApiRequest(setId), GET_ROLES_CONNECTED_TO_SET));
};

export const fetchRolesPaginatedAction = queryData => async dispatch => {
    await dispatch(ApiExecutor(getRolesPaginatedApiRequest(queryData), GET_COMPANY_ROLES_PAGINATED));
};

export const resetFetchRolesPaginatedAction = queryData => async dispatch => {
    dispatch({ type: buildActionType(GET_COMPANY_ROLES_PAGINATED, ActionStatus.RESET) });
};

const dispatchUpdatedState = (rolesState, roles, dispatch) => {
    dispatch({
        type: buildActionType(GET_ALL_COMPANY_ROLES, ActionStatus.DONE),
        payload: {
            ...rolesState.data,
            roles: roles,
        },
    });
};
