import React from 'react';
import InputContent from 'components/features/content_management/inputs/InputContent';
import { useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import InputAnswerCheckboxes from 'components/features/content_management/inputs/InputAnswerCheckboxes';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import { validatorCategories } from 'services/validation-module/local-constants';
import Add from 'assets/icons/icon-add.svg';
import { Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';

export default function TrafficInputs({ game, id, filters, initializeQuestion }) {
    const { t } = useTranslation();

    const { answers: answersList, updateAnswer, insertAnswer } = useMultipleChoiceAnswers();

    function handleOnPaste(e, index) {
        let elements = e.clipboardData.getData('Text').split(/\r?\n/);
        elements = elements.filter(e => e != '');

        if (elements.length === 1) {
            return;
        }

        e.preventDefault();

        for (let i = 0; i < elements.length; i++) {
            if (index + i >= answersList.length) {
                insertAnswer();
            }
            updateAnswer({ index: index + i, element: { text: elements[i], isCorrect: false } });
        }
    }

    return (
        <InputContent initializeQuestion={initializeQuestion} game={game} id={id} filters={filters}>
            <div className="mt-4" style={{ position: 'relative' }}>
                <div>
                    <Caption1 color={colors.gray6}>{t('OPTIONS')}</Caption1>
                </div>
                {answersList.map((ans, index) => (
                    <InputAnswerCheckboxes
                        value={ans.text}
                        index={index}
                        game={game}
                        handleOnPaste={handleOnPaste}
                        isCorrect={ans.isCorrect}
                        // showRemove={answersList.length > 10}
                    />
                ))}
                <div className="d-flex flex-row align-content-center mt-2 cursor-pointer" onClick={insertAnswer}>
                    <img alt="Add" height={20} width={20} src={Add} />
                    <Body2 id="add-option-button-compare" className="ml-2">
                        {t('Add New')}
                    </Body2>
                </div>
            </div>
        </InputContent>
    );
}
