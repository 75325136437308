import React from 'react';
import PropTypes from 'prop-types';
import InputContent from 'components/features/content_management/inputs/InputContent';
import { useCompareVisualOptions } from 'services/content-management/insertion-control';
import InputOptionsCompare from 'components/features/content_management/inputs/InputOptionsCompare';
import Add from 'assets/icons/icon-add.svg';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import { validatorCategories } from 'services/validation-module/local-constants';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { compareOptions } from 'static/game_constants';
import { useTranslation } from 'react-i18next';
import InputAnswerCheckboxes from 'components/features/content_management/inputs/InputAnswerCheckboxes';
import Checkbox from 'components/elements/checkbox/Checkbox';

export default function CompareInputs({ game, id, filters, initializeQuestion, compareCriteria, onCriteriaChanged }) {
    const { answers: options, insertAnswer: insertOption } = useCompareVisualOptions();

    const { t } = useTranslation();
    return (
        <InputContent initializeQuestion={initializeQuestion} game={game} id={id} filters={filters}>
            <div className="mt-2">
                <ValidationPopup category={validatorCategories.COMPARING_CRITERIA_REQUIRED} position="left" />
                <Caption1 className={'mt-4'} color={colors.gray6}>
                    {t('COMPARING CRITERIA')}
                </Caption1>
                <div className="d-flex flex-row justify-content-start" style={{ width: '80%', padding: '0', margin: '0' }}>
                    <div style={{ width: '100%' }}>
                        <CustomSelect
                            value={compareCriteria}
                            onChange={onCriteriaChanged}
                            id="compare-choose-comparing-criteria"
                            options={compareOptions}
                            styles={{ backgroundColor: colors.white }}
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <Caption1 color={colors.gray6}>{t('OPTIONS')}</Caption1>
                </div>
                <div style={{ overflow: 'auto', height: '60%' }}>
                    {options.map((option, index) => {
                        return (
                            <InputOptionsCompare
                                value={option.text}
                                index={index}
                                image={option?.images?.length > 0 && option?.images[0] ? option.images[0].urlRatio2x1 : null}
                                key={`input-options-compare-${option.id}`}
                                // showRemove={options.length > 5}
                                compareCriteria={compareCriteria}
                            />
                        );
                    })}
                </div>
                <div className="d-flex flex-row align-content-center mt-2" onClick={insertOption}>
                    <img alt="Add" height={20} width={20} src={Add} />
                    <Body2 id="add-option-button-compare" className="cursor-pointer ml-2">
                        {t('Add New')}
                    </Body2>
                </div>
            </div>
        </InputContent>
    );
}

CompareInputs.propTypes = {
    compareCriteria: PropTypes.any,
    filters: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
    initializeQuestion: PropTypes.func,
    onCriteriaChanged: PropTypes.func,
};
