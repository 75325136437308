import PropTypes from 'prop-types';
import React from 'react';
import InputFieldPagination from 'components/features/data_table/pagination_component/InputFieldPagination';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

import AngleDoubleLeft from 'assets/icons/angle-double-left.svg';
import AngleLeft from 'assets/icons/angle-left.svg';
import AngleDoubleRight from 'assets/icons/angle-double-right.svg';
import AngleRight from 'assets/icons/angle-right.svg';

import './PaginationComponents.css';
import { Trans, useTranslation } from 'react-i18next';

export default function PaginationComponent({ getPrev, getNext, currentPage, totalPages, getPage, totalItems, size, setSize, canChangeSize = true }) {
    const { t } = useTranslation();

    return (
        <div className={'d-flex flex-row justify-content-between align-items-center pag-comp--container'}>
            {canChangeSize && (
                <div className="d-flex flex-row align-items-center">
                    <Body2 color={colors.gray6} className="mr-2">
                        {t('Items per page:')}
                    </Body2>
                    <div className="mr-2">
                        <select value={size} onChange={e => setSize(e.target.value)} className="select-box">
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                        </select>
                    </div>
                </div>
            )}
            {!canChangeSize && <div style={{ height: 17, width: 100 }}></div>}
            <div className="d-flex flex-row align-items-center justify-content-center">
                <img alt="First" className="cursor-pointer" src={AngleDoubleLeft} height={17} width={17} onClick={() => getPage(0)} />
                <img alt="Previous" className="ml-3 mr-4 cursor-pointer" src={AngleLeft} height={17} width={17} onClick={getPrev} />
                <Body2 color={colors.gray6} className="mr-2">
                    {t('Page')}
                </Body2>
                <InputFieldPagination currentPage={currentPage} totalPages={totalPages} getQuestionsPage={getPage} />
                <Body2 color={colors.gray6}> {` ${t('of')} ${totalPages}`}</Body2>
                <img alt="Next" className="ml-4 cursor-pointer" src={AngleRight} height={17} width={17} onClick={getNext} />
                <img
                    alt="Last"
                    className="ml-3 cursor-pointer"
                    src={AngleDoubleRight}
                    height={17}
                    width={17}
                    onClick={() => getPage(totalPages - 1)}
                />
            </div>
           <Trans>
           <Body2 color={colors.gray6}>{t('<strong>{{totalItems}}</strong> items found', { totalItems: totalItems })}</Body2>
           </Trans>
        </div>
    );
}

PaginationComponent.propTypes = {
    canChangeSize: PropTypes.bool,
    currentPage: PropTypes.any,
    getNext: PropTypes.func,
    getPage: PropTypes.func,
    getPrev: PropTypes.func,
    setSize: PropTypes.func,
    size: PropTypes.any,
    totalItems: PropTypes.any,
    totalPages: PropTypes.number,
};
