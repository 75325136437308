import React from 'react';
import { useTranslation } from 'react-i18next';

export default function RecallOptionsView({ answers }) {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-row" style={{ flex: 1 }}>
            <div className="col-8 d-flex" style={{ height: '100%' }}>
                <div style={{ flex: 1, paddingBottom: '5px' }} className="d-flex flex-column justify-content-start">
                    {answers.map((ans, ind) => (
                        <div style={{ fontSize: '12px', textTransform: 'uppercase', color: '#8f8e8d', marginTop: '5px' }}>
                            {ans.text || t(`OPTION {{index}}`, { index: ind + 1 })}
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-4"></div>
        </div>
    );
}
