import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { H6 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import FeedbackIcon from 'assets/icons/icon-emptystate-tags.svg';
import { useTranslation } from 'react-i18next';

export function TagsEmptyState({ setAddLabelModalOpen }) {
    const { t } = useTranslation();

    return (
        <div style={{ marginTop: '25%', transform: 'translateY(-50%)' }} className={'d-flex flex-row align-items-center justify-content-center'}>
            <div className="d-flex flex-column align-items-center">
                <img style={{ width: 80, height: 80 }} src={FeedbackIcon} alt="" />

                <H6 color={colors.secoundaryText} className={'mt-4'}>
                    {t('Create user tags for better management')}
                </H6>
                <Body2 className={'mb-0 mt-1 w-50 text-center'} color={colors.gray6}>
                    {t(
                        'You can create labels and add tags to users in order to form a specific custom user group from the company, for different needs, filtering and management.'
                    )}
                </Body2>
                <button
                    onClick={() => setAddLabelModalOpen(true)}
                    type={'button'}
                    className="btn btn-dark d-flex flex-row align-items-center mt-4"
                    style={{ color: colors.mainTheme, width: 160 }}
                >
                    <FontAwesomeIcon icon={faPlus} className={'mr-auto'}></FontAwesomeIcon>
                    {t('Create tag label')}
                </button>
            </div>
        </div>
    );
}

TagsEmptyState.propTypes = {
    setAddLabelModalOpen: PropTypes.func,
};
