import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { Paper, makeStyles } from '@material-ui/core';
import { H4, H7 } from 'style/typography/Heading';
import { Body1, Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { UploadUserTags } from './UploadUserTags';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsNotInitialized } from 'redux/core/stateHelpers';
import { resetUpdateUserTagsProgressAction, resetUploadUserTagsAction } from 'redux/actions/tags.actions';
import { Trans, useTranslation } from 'react-i18next';

export default function BulkSetTagsModal({ isOpen, handleClose, onUpload }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const uploadUserTagsState = useSelector(state => state.content.uploadUserTags);
    const uploadUserTagsProgressState = useSelector(state => state.content.uploadUserTagsProgress);

    useEffect(() => {
        if (stateIsLoaded(uploadUserTagsState) || stateIsLoaded(uploadUserTagsProgressState)) {
            dispatch(resetUploadUserTagsAction());
            dispatch(resetUpdateUserTagsProgressAction());
        }
    }, []);

    useEffect(() => {
        if (stateIsLoaded(uploadUserTagsState)) {
            onUpload();
        }
    }, [uploadUserTagsState]);

    return (
        <div>
            <Modal
                open={isOpen}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Paper className={`${classes.paper} d-flex flex-column`}>
                    <H4 className="text-center my-4">{t('Set tags bulk')}</H4>
                    <div className="text-center">
                        <Body1 color={colors.gray6} className="mt-3">
                            <Trans>{t('Make sure to upload <strong>.xlsx</strong> file and adhere to our format')}</Trans>
                        </Body1>
                        {stateIsNotInitialized(uploadUserTagsState) && stateIsNotInitialized(uploadUserTagsProgressState) && <UploadUserTags />}
                        {!stateIsLoaded(uploadUserTagsState) && stateIsLoaded(uploadUserTagsProgressState) && (
                            <div style={{ height: '40vh' }} className="row justify-content-center align-items-center">
                                <div className="d-flex flex-column w-50 text-center">
                                    <Body2 color={colors.gray6}>{t('Importing...')}</Body2>
                                    <div className="progress bg-white w-100 mt-3">
                                        <div
                                            className="progress-bar bg-warning"
                                            role="progressbar"
                                            style={{ width: `${uploadUserTagsProgressState?.data?.progress}%` }}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {uploadUserTagsProgressState?.data?.progress}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {stateIsLoaded(uploadUserTagsState) && (
                            <div>
                                <H7 style={{ paddingTop: '25%' }} color={colors.gray6}>
                                    {t('Successfully updated {{count}}', { count: uploadUserTagsState.data.successfulUsersUpdated })}
                                </H7>
                            </div>
                        )}
                    </div>
                </Paper>
            </Modal>
        </div>
    );
}

BulkSetTagsModal.propTypes = {
    handleClose: PropTypes.func,
    isOpen: PropTypes.bool,
    onUpload: PropTypes.func,
};

const useStyles = makeStyles(theme => {
    return {
        paper: {
            position: 'absolute',
            width: '70vw',
            backgroundColor: theme.palette.background.main,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            height: '90vh',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            textAlign: 'center',
        },
        alertFixPaper: {
            maxWidth: '2000px',
            width: '600px',
        },
    };
});
