import React, { useEffect, useState } from 'react';
import SortableTree from 'react-sortable-tree';
import { colors } from 'style/colors';
import { Body1 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';
import { formatDateOnly } from 'services/utils/stringHelpers';
import { Caption1 } from 'style/typography/Caption';

import File from 'assets/icons/topic-icon-paper.svg';
import Folder from 'assets/icons/topic-icon-folder.svg';

import './UsedInTreeStyle.css';

export function Info({ createdBy, dateCreated, timeCreated, usedIn, topicTitle }) {
    const { t } = useTranslation();

    const [usedInTrees, setUsedInTrees] = useState([]);

    useEffect(() => {
        if (usedIn === null || usedIn === undefined || usedIn.length === 0) {
            setUsedInTrees([]);
            return;
        }

        let topic = {
            title: topicTitle,
            className: 'child',
        };
        setUsedInTrees(
            usedIn.map(el => {
                let parent = {
                    title: el.setName,
                    expanded: true,
                    className: 'parent',
                    children: [topic],
                };
                return [parent];
            })
        );
    }, [usedIn]);

    return (
        <div className={'d-flex flex-column ml-3'}>
            <div className="d-flex flex-column mt-3">
                <div className="d-flex flex-column text-left">
                    <Caption1 weight="medium" color={colors.gray6}>
                        {t('CREATED BY')}
                    </Caption1>
                    <Body1 color={colors.dark}>{createdBy}</Body1>
                </div>
                <div className="d-flex flex-column text-left mt-3">
                    <Caption1 weight="medium" color={colors.gray6}>
                        {t('DATE CREATED')}
                    </Caption1>
                    <div className="d-flex flex-row">
                        <Body1 color={colors.dark}>{formatDateOnly(dateCreated)}</Body1>
                    </div>
                </div>
            </div>
            <div style={{ maxHeight: '40vh', overflow: 'scroll' }} className={'d-flex flex-column mt-3'}>
                {usedInTrees?.map(tree => (
                    <SortableTree
                        style={{ height: 150, minHeight: 150 }}
                        key={Math.random()}
                        onChange={() => {}}
                        treeData={tree}
                        canDrop={false}
                        canDrag={false}
                        generateNodeProps={({ node, path }) => {
                            return {
                                className: `${node.className}`,
                                title: (
                                    <div
                                        style={node.className === 'parent' ? { height: 40 } : { height: 40 }}
                                        className={'d-flex flex-row align-items-center'}
                                    >
                                        <div style={{ height: '100%' }} className={'px-2 d-flex flex-row align-items-center'}>
                                            <img alt="Type" src={node.className === 'child' ? File : Folder} style={{ width: 20, height: 20 }} />
                                        </div>
                                        <Body1
                                            weight="medium"
                                            color={colors.dark}
                                            className={node.className === 'parent' ? 'mb-0 ml-1' : 'mb-0 ml-1'}
                                            style={{ maxWidth: '250px', overflow: 'hidden' }}
                                        >
                                            {node.title}
                                        </Body1>
                                    </div>
                                ),
                            };
                        }}
                    />
                ))}
            </div>
        </div>
    );
}
