import React, { useEffect } from 'react';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { colors } from 'style/colors';
import Icon from 'assets/icons/checkmark-img.svg';
import Logo from 'assets/icons/coach-logo-black.svg';
import { useDispatch, useSelector } from 'react-redux';
import { passwordLoginAction, resetLogout } from 'redux/actions/auth.actions';
import { Trans, useTranslation } from 'react-i18next';
import { H7 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { DefaultButton } from 'components/elements/Button';

import './AdministratorRegistration.css';

export function AdministratorRegistrationSuccess({ history }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const registrationState = useSelector(state => state.userManagement.registerAdministrator);
    const auth = useSelector(state => state.auth);

    function doLogin() {
        dispatch(passwordLoginAction(registrationState.data.username, registrationState.data.password));
    }

    useEffect(() => {
        if (stateIsLoaded(auth) && auth.persisted) {
            dispatch(resetLogout());

            history.push('/login?newUser=true');
        }
    }, [auth, dispatch, history]);

    return (
        <div className="administrator-registration--success">
            <img alt="logo" src={Logo} width={70} height={70}></img>
            <div className="administrator-registration--success-inner">
                <img alt="icon" src={Icon} width={70} height={70}></img>
                <H7 style={{ marginTop: 30 }} color={colors.gray6}>
                    {t('Your account is set!')}
                </H7>
                <Body2 style={{ maxWidth: '35vw', marginTop: 30 }}>
                    <Trans>{t('Welcome aboard! <br /> Proceed with adding your first company employees to train with the Coach® app.')}</Trans>
                </Body2>

                <DefaultButton
                    style={{ width: '200px' }}
                    text={t('Continue to dashboard')}
                    disabled={stateIsLoading(auth)}
                    onClick={doLogin}
                    textColor="white"
                />
            </div>
        </div>
    );
}
