export const marks = [
    {
        value: 0,
        label: '',
        name: 'Week',
        selector: 'WEEK',
    },
    {
        value: 1,
        label: '',
        name: 'Month',
        selector: 'MONTH',
    },
    {
        value: 2,
        label: '',
        name: '3 Months',
        selector: 'THREE_MONTHS',
    },
    {
        value: 3,
        label: '',
        name: '6 Months',
        selector: 'SIX_MONTHS',
    },
    {
        value: 4,
        label: '',
        name: 'Year',
        selector: 'YEAR',
    },
];


export const xAxis = {
    WEEK: ['6d', '5d', '4d', '3d', '2d', '1d', 'now'],
    MONTH: ['4w', '3w', '2w', '1w', 'now'],
    THREE_MONTHS: ['12w', '10w', '8w', '6w', '4w', '2w', 'now'],
    SIX_MONTHS: ['6m', '5m', '4m', '3m', '2m', '1m', 'now'],
    YEAR: ['12m', '10m', '8m', '6m', '4m', '2m', 'now'],
};