import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'style/colors';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Body2 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';

export function SessionStats({ value, last }) {
    const { t } = useTranslation();

    return (
        <div className={`d-flex flex-row align-items-start pb-2 ${!last ? 'border-bottom' : ''} w-75 mt-2`}>
            <FontAwesomeIcon color={colors.mainTheme} className={'mr-2 mt-1'} icon={faCircle} size={'xs'} />
            <div className="d-flex flex-column align-items-start">
                <Body2 weight="medium" className={'mb-0'}>
                    {value.formattedTime}
                </Body2>
                <Body2 color={colors.gray6} className={'mt-1 mb-1'}>
                    {t('Login duration: {{loginDuration}} min.', { loginDuration: value.loginDuration })}
                </Body2>
            </div>
        </div>
    );
}

SessionStats.propTypes = {
    last: PropTypes.any,
    value: PropTypes.any,
};
