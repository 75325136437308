import React, { useEffect } from 'react';

import './ContentBlocksBuilder.css';

import { Caption1 } from 'style/typography/Caption';
import { Body2 } from 'style/typography/Body';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { useContentBlocksBuilder } from 'services/content-blocks-management/content-blocks-builder';
import { colors } from 'style/colors';
import { constraintsErrorTexts, contentBlockConstraintsByGame } from 'services/content-blocks-management/content-blocks-builder/local-constants';
import { useParams } from 'react-router-dom';
import { useContentInsertionQuestion } from 'services/content-management/insertion-control';

export default function ContentBlocksDetailsList({ setErrorTexts }) {
    const { game } = useParams();
    const gameContentBlockConstraints = contentBlockConstraintsByGame[game];
    const { attributes, setAttributes, contentBlock, setCorrectValuesMap, setQuestionAttribute, comparatorCriteria } = useContentBlocksBuilder();
    const { question, setQuestion } = useContentInsertionQuestion();

    function getErrorTexts() {
        let errorTexts = [];

        let currentlySelectedAttributesCount = attributes.filter(elInside => elInside.selected)?.length;
        if (currentlySelectedAttributesCount < gameContentBlockConstraints.requiredAttributesCount) {
            let remainingAttributesCount = gameContentBlockConstraints.requiredAttributesCount - currentlySelectedAttributesCount;
            errorTexts.push(`Select ${remainingAttributesCount} more attribute${remainingAttributesCount == 1 ? '' : 's'}`);
        }

        let requiredAttributes =
            game === 'compare'
                ? gameContentBlockConstraints.requiredAttributes[comparatorCriteria.value]
                : gameContentBlockConstraints.requiredAttributes;

        Object.entries(requiredAttributes).forEach(el => {
            let k = el[0];
            let v = el[1];

            if (v != 0) {
                let currentlySelectedTypeAttributes = attributes.filter(elInside => elInside.selected && elInside.type === k)?.length;
                if (currentlySelectedTypeAttributes < v) {
                    errorTexts.push(constraintsErrorTexts[k]);
                }
            }
        });

        setErrorTexts(errorTexts);
    }
    useEffect(() => {
        getErrorTexts();
    }, [attributes]);

    function handleAttributeSelect(el) {
        let questionVariable = question.match("<%(.*)%>")?.[1];


        let currentlySelectedAttributes = attributes.filter(elInside => elInside.selected)?.length;
        let wasElementSelected = attributes.find(elInside => elInside == el)?.selected;

        if (wasElementSelected && el.value == questionVariable) {
            return;
        }

        if (currentlySelectedAttributes >= gameContentBlockConstraints.requiredAttributesCount && !wasElementSelected) {
            return;
        }
        setCorrectValuesMap(null);
        setQuestionAttribute(null);
        setAttributes(
            attributes.map(elInside => {
                if (elInside.value == el.value) {
                    elInside.selected = !elInside.selected;
                }
                return elInside;
            })
        );
    }

    return (
        <div className="cb-builder--details-list">
            <Caption1>Items: {contentBlock.data.rows.length}</Caption1>
            <Caption1 style={{ paddingTop: 10 }}>Attributes: </Caption1>
            {attributes.map(el => {
                return (
                    <div className="cb-builder--details-list-item">
                        <div style={{ position: 'relative', width: 20, height: 20 }}>
                            <Checkbox
                                onClick={_ => {
                                    handleAttributeSelect(el);
                                }}
                                checked={el.selected}
                            ></Checkbox>
                        </div>
                        <Body2>{el.label}</Body2>
                        <Caption1 color={colors.gray6}>{`(${el.type})`}</Caption1>
                    </div>
                );
            })}
        </div>
    );
}
