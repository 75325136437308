import React, { useEffect, useState } from 'react';
import Icon from 'assets/icons/coach-logo-black.svg';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { colors } from 'style/colors';
import { validateEmail } from 'services/utils/stringHelpers';
import { forgotPasswordRequestAction } from 'redux/actions/users.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AnimatedTextField } from 'components/elements/inputs/AnimatedTextField';
import { DefaultButton } from 'components/elements/Button';
import { Body2 } from 'style/typography/Body';
import { H7 } from 'style/typography/Heading';

import '../ForgotPassword.css';

export function ForgotPasswordRequest({ history }) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const forgotPasswordRequestState = useSelector(state => state.userManagement.forgotPasswordRequest);

    function nextStep() {
        dispatch(forgotPasswordRequestAction(email));
    }

    useEffect(() => {
        if (stateIsLoaded(forgotPasswordRequestState)) {
            history.push('/users/forgot/request/success');
        }
    }, [forgotPasswordRequestState, history]);

    return (
        <div className="forgot-password-screen">
            <img alt="logo" src={Icon} width={70} height={70}></img>
            <H7 style={{ marginTop: 30 }} color={colors.gray6}>
                {t('Forgot your password?')}
            </H7>
            <Body2 style={{ maxWidth: '30vw' }}>
                {t('Don’t worry! Just enter the email address associated with your account, and we’ll send you a link to reset your password.')}
            </Body2>
            <div style={{ marginTop: 30 }}>
                <AnimatedTextField label={t('Email')} value={email} setValue={val => setEmail(val)} />
            </div>

            <DefaultButton
                style={{ marginTop: 50, width: '150px' }}
                text={t('Send reset link')}
                disabled={!validateEmail(email)}
                onClick={nextStep}
                textColor="white"
            />
            <Body2 style={{ marginTop: 20, cursor: 'pointer' }} onClick={() => history.push('/login')} color={colors.mainTheme}>
                {t('Return to login')}
            </Body2>
        </div>
    );
}
