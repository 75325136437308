import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { ProgressBar } from './ProgressBar';
import { Body1, Body2 } from 'style/typography/Body';

export function CategoryAccuracy({ categoryName, accuracy, experience, index }) {

    return (
        <div className={'d-flex flex-row'}>
            <div
                style={{
                    border: `1px solid ${colors.mainTheme}`,
                    width: '22px',
                    height: '20px',
                }}
                className="d-flex align-items-center justify-content-center rounded-circle mr-2 pt-1"
            >
                <Body1 className={'mb-0'}>{index}</Body1>
            </div>
            <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row">
                    <Body1 className={'mb-1 mr-2'}>{categoryName}</Body1>
                    <Body1 className={'font-weight-bold mb-1'}>{accuracy}%</Body1>
                    <Body2 className={'ml-auto mb-1'} style={{ color: colors.mainTheme }}>
                        {experience}
                    </Body2>
                </div>
                <ProgressBar progress={accuracy} />
            </div>
        </div>
    );
}

CategoryAccuracy.propTypes = {
    accuracy: PropTypes.any,
    categoryName: PropTypes.any,
    experience: PropTypes.any,
    index: PropTypes.any,
};
