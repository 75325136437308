import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import WarningIcon from 'assets/icons/warning-icon.png';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function RemoveQuestionFromTopicDialog({ modalOpen, setModalOpen, onAction }) {
    const { t } = useTranslation();

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                setModalOpen(false);
                onAction();
            }}
            title={t('Question already added')}
            text={t(
                'The question already belongs to certain topic. If you choose to add this question to a new topic, the previous relation will be removed.'
            )}
            buttons={[
                {
                    title: t('Add anyway'),
                    color: colors.white,
                },
            ]}
            hasCancel={true}
            image={WarningIcon}
        ></ModalDialog>
    );
}

RemoveQuestionFromTopicDialog.propTypes = {
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    setModalOpen: PropTypes.func,
};
