import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

export default function SelectFolderTab({ icon, iconWhite, text, selected, onClick, isPrevious }) {
    const { t } = useTranslation();

    return (
        <div
            onClick={onClick}
            className="d-flex flex-row align-items-center cursor-pointer"
            style={{
                background: selected ? colors.mainTheme : isPrevious ? colors.gray2 : 'transparent',
                width: '100%',
                marginTop: '10px',
                height: 45,
            }}
        >
            <img alt="Folder" src={selected ? iconWhite : icon} style={{ width: 35, height: 30, margin: '3px 0', paddingLeft: '10px' }} />

            <Body2 weight={selected ? 'medium' : 'regular'} style={{ marginLeft: '10px' }}>
                {t(text)}
            </Body2>
        </div>
    );
}

SelectFolderTab.propTypes = {
    icon: PropTypes.any,
    iconWhite: PropTypes.any,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    text: PropTypes.any,
};
