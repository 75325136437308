import { ApiExecutor } from './ApiExecutor';
import {
    saveFoldersApiRequest,
    saveImageToFolderApiRequest,
    getImagesFromFolderApiRequest,
    deleteFolderApiRequest,
    updateImageApiRequest,
    getAllImagesApiRequest,
    getUncategorizedImagesApiRequest,
    moveImagesToFolderApiRequest,
    imagesDeleteSoftApiRequest,
    imagesDeleteForceApiRequest,
    getFolderStructureApiRequest,
    getImagesCountApiRequest,
} from 'services/api/apiRequests/content';

import {
    SAVE_FOLDER_STRUCTURE,
    GET_IMAGES_FROM_FOLDER,
    SAVE_IMAGE_TO_FOLDER,
    DELETE_IMAGE_FOLDER,
    UPDATE_IMAGE,
    GET_ALL_IMAGES,
    GET_UNCATEGORIZED_IMAGES,
    MOVE_IMAGES_TO_FOLDER,
    IMAGES_DELETE_SOFT,
    IMAGES_DELETE_FORCE,
    GET_FOLDER_STRUCTURE,
    GET_IMAGES_COUNT,
} from '../constants/content.constants';

import { buildActionType } from './buildActionType';
import { ActionStatus } from '../core/ActionStatus';

export const saveImageToFolder = (folderId, fileOriginal, fileImageRatio1x1, fileImageRatio2x1, fileImageRatio4x3, callback) => async dispatch => {
    let formData = new FormData();
    formData.append('urlOriginal', fileOriginal);
    formData.append('urlRatio1x1', fileImageRatio1x1);
    formData.append('urlRatio2x1', fileImageRatio2x1);
    formData.append('urlRatio4x3', fileImageRatio4x3);
    formData.append('folderId', folderId);

    dispatch({ type: buildActionType(SAVE_IMAGE_TO_FOLDER, ActionStatus.START) });

    let image = await saveImageToFolderApiRequest(formData);

    if (image) {
        dispatch({ type: buildActionType(SAVE_IMAGE_TO_FOLDER, ActionStatus.DONE), payload: image });
        callback(image);
    } else {
        dispatch({ type: buildActionType(SAVE_IMAGE_TO_FOLDER, ActionStatus.FAILED), payload: image?.errors });
    }
    return image;
};

export const updateImageAction = (
    folderId,
    fileOriginal,
    fileImageRatio1x1,
    fileImageRatio2x1,
    fileImageRatio4x3,
    imageId,
    callback
) => async dispatch => {
    let formData = new FormData();
    formData.append('urlOriginal', fileOriginal);
    formData.append('urlRatio1x1', fileImageRatio1x1);
    formData.append('urlRatio2x1', fileImageRatio2x1);
    formData.append('urlRatio4x3', fileImageRatio4x3);
    formData.append('folderId', folderId);
    formData.append('imageId', imageId);

    dispatch({ type: buildActionType(UPDATE_IMAGE, ActionStatus.START) });

    let image = await updateImageApiRequest(formData);

    if (image) {
        dispatch({ type: buildActionType(UPDATE_IMAGE, ActionStatus.DONE), payload: image });
        callback(image);
    } else {
        dispatch({ type: buildActionType(UPDATE_IMAGE, ActionStatus.FAILED), payload: image?.errors });
    }
    return image;
};

export const resetSaveImageToFolder = () => async dispatch => {
    await dispatch({ type: buildActionType(SAVE_IMAGE_TO_FOLDER, ActionStatus.RESET) });
};

export const getImagesFromFolderAction = queryData => async dispatch => {
    await dispatch({ type: buildActionType(SAVE_IMAGE_TO_FOLDER, ActionStatus.START) });

    await dispatch(ApiExecutor(getImagesFromFolderApiRequest(queryData), GET_IMAGES_FROM_FOLDER));
};

export const resetGetImagesFromFolderAction = queryData => async dispatch => {
    await dispatch({ type: buildActionType(SAVE_IMAGE_TO_FOLDER, ActionStatus.RESET) });
};

export const getAllImagesAction = queryData => async dispatch => {
    await dispatch(ApiExecutor(getAllImagesApiRequest(queryData), GET_ALL_IMAGES));
};

export const getImagesCountAction = () => async dispatch => {
    await dispatch(ApiExecutor(getImagesCountApiRequest(), GET_IMAGES_COUNT));
};

export const getUncategorizedImagesAction = queryData => async dispatch => {
    await dispatch(ApiExecutor(getUncategorizedImagesApiRequest(queryData), GET_UNCATEGORIZED_IMAGES));
};

export const getFolderStructure = () => async dispatch => {
    await dispatch(ApiExecutor(getFolderStructureApiRequest(), GET_FOLDER_STRUCTURE));
};

export const saveFoldersAction = data => async dispatch => {
    await dispatch(ApiExecutor(saveFoldersApiRequest(data), SAVE_FOLDER_STRUCTURE));
};

export const resetSaveFolersAction = () => async dispatch => {
    await dispatch({ type: buildActionType(SAVE_FOLDER_STRUCTURE, ActionStatus.RESET) });
};

export const deleteFolderAction = id => async dispatch => {
    await dispatch(ApiExecutor(deleteFolderApiRequest(id), DELETE_IMAGE_FOLDER));
};

export const resetDeleteFolderAction = () => async dispatch => {
    await dispatch({ type: buildActionType(DELETE_IMAGE_FOLDER, ActionStatus.RESET) });
};

export const moveToFolderAction = (imageIds, folderId) => async dispatch => {
    await dispatch(ApiExecutor(moveImagesToFolderApiRequest(imageIds, folderId), MOVE_IMAGES_TO_FOLDER));
};
export const resetMoveToFolderAction = () => async dispatch => {
    dispatch({ type: buildActionType(MOVE_IMAGES_TO_FOLDER, ActionStatus.RESET) });
};

export const imagesDeleteSoftAction = imageIds => async dispatch => {
    dispatch(ApiExecutor(imagesDeleteSoftApiRequest(imageIds), IMAGES_DELETE_SOFT));
};

export const imagesDeleteForceAction = imageIds => async dispatch => {
    dispatch(ApiExecutor(imagesDeleteForceApiRequest(imageIds), IMAGES_DELETE_FORCE));
};

export const resetImagesDeleteSoft = () => async dispatch => {
    await dispatch({ type: buildActionType(IMAGES_DELETE_SOFT, ActionStatus.RESET) });
};

export const resetImagesDeleteForce = () => async dispatch => {
    await dispatch({ type: buildActionType(IMAGES_DELETE_FORCE, ActionStatus.RESET) });
};
