export const filterByValue = obj => handler => {
    let filtered = {};

    for (let [key, value] of Object.entries(obj)) {
        if (handler(value)) filtered[key] = value;
    }

    return filtered;
};

export const cloneProperties = (from, to) => {
    for (let key in from) {
        if (from.hasOwnProperty(key)) {
            to[key] = from[key];
        }
    }
};

export function closureWithDelay() {
    let timeout = {};
    return {
        addCallback: (callback, delay, method) => {
            if (timeout[method]) {
                clearTimeout(timeout[method]);
            }
            timeout[method] = setTimeout(() => {
                callback();
                clearTimeout(timeout);
            }, delay);
        },
        removeCallback: method => {
            if (timeout[method]) {
                clearTimeout(timeout[method]);
            }
        },
    };
}

export const setTimeoutLastExecute = closureWithDelay();

export const updateFieldCount = (current, prevValue, newValue) => {};

export const getTextWidth = ({ text, font, fontSize }) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = font || getComputedStyle(document.body).font;

    if (!font && fontSize) return context.measureText(text).width * (fontSize / 14);
    else return context.measureText(text).width;
};

export function object_equals(x, y) {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
        if (!x.hasOwnProperty(p)) continue;
        // other properties were tested using x.constructor === y.constructor

        if (!y.hasOwnProperty(p)) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) continue;
        // if they have the same strict value or identity then they are equal

        if (typeof x[p] !== 'object') return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!object_equals(x[p], y[p])) return false;
        // Objects and Arrays must be tested recursively
    }

    for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    // allows x[ p ] to be set to undefined

    return true;
}
export function removeEmptyPropsFromObject(obj) {
    let obj2 = Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v));
    return obj2;
}

export function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
}