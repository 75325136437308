import React from 'react';
import Text from './Text';
import { colors } from 'style/colors';

const Body = ({ weight, tag, fontWeight, fontHeight, color = colors.dark, style, ...attr }) => {
    return Text(weight, bodyTagDefaults[tag], fontWeight, fontHeight, color, style, attr);
};

const Body1 = props => <Body {...props} tag="body1" />;
const Body2 = props => <Body {...props} tag="body2" />;

const bodyTagDefaults = {
    body1: {
        fontSize: 16,
    },
    body2: {
        fontSize: 14,
    },
};

export { Body1, Body2 };
