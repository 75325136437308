import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Body1, Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';
import { Caption1 } from 'style/typography/Caption';

import PhoneMockup from 'assets/icons/phone-mockup.svg';
import CaretIcon from 'assets/icons/filter-caret.svg';
import ImgIcon from 'assets/icons/placeholder-image-news-mockup.svg';

import '../News.css';

export default function PreviewNews({ articleCount, currentIndex, setIndex, articlesState }) {
    const { t } = useTranslation();

    const getPagination = () => {
        let dots = [];
        for (let i = 0; i < articleCount; i++) {
            dots.push(<div className={i === currentIndex ? 'dot-active' : 'dot'}></div>);
        }
        return dots;
    };
    const currentArticle = useMemo(() => {
        return articlesState?.articles?.[currentIndex];
    }, [articlesState, currentIndex]);

    return (
        <div className="create-news--preview-container">
            <Caption1 color={colors.gray6} weight="medium">
                {t('PREVIEW')}
            </Caption1>
            <div className="create-news--preview-phone">
                <img
                    alt="caret"
                    src={CaretIcon}
                    height={30}
                    width={30}
                    style={{ transform: 'rotateZ(90deg)', cursor: 'pointer' }}
                    onClick={() => {
                        if (currentIndex > 0) {
                            setIndex(currentIndex - 1);
                        }
                    }}
                />
                <div className="news-preview--phone">
                    <img alt="Phone mockup" src={PhoneMockup} width={250}></img>

                    <div className="news-preview--phone-inner">
                        {articlesState.platform === 'WEB' && (
                            <div className="news-preview--content">
                                <Caption1 color={colors.gray6}>{t('No preview available')}</Caption1>
                            </div>
                        )}
                        {articlesState.platform !== 'WEB' && (
                            <div className="news-preview--content">
                                <Body1 color={colors.dark}>{currentArticle?.title ? currentArticle?.title : t('Title of this slide')}</Body1>
                                <div className="news-preview--img-container">
                                    <img alt="preview" src={currentArticle?.img ? currentArticle?.img : ImgIcon} className="news-preview--img" />
                                </div>
                                <Caption1 color={colors.gray6}>
                                    {currentArticle?.story ? currentArticle?.story : t('News story goes here...')}
                                </Caption1>
                                <div className="news-preview--pagination">{getPagination()}</div>
                                <Body2 weight="medium" color={colors.mainTheme}>
                                    {articlesState?.buttonTitle ? articlesState.buttonTitle : t('Got it!')}
                                </Body2>
                            </div>
                        )}
                    </div>
                </div>
                <img
                    alt="caret"
                    src={CaretIcon}
                    height={30}
                    width={30}
                    style={{ transform: 'rotateZ(-90deg)', cursor: 'pointer' }}
                    onClick={() => {
                        if (currentIndex < articleCount - 1) {
                            setIndex(currentIndex + 1);
                        }
                    }}
                />
            </div>
        </div>
    );
}

PreviewNews.propTypes = {
    articleCount: PropTypes.number,
    articlesState: PropTypes.object,
    currentIndex: PropTypes.number,
    setIndex: PropTypes.func,
};
