import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { DefaultButton } from 'components/elements/Button';
import { Trans, useTranslation } from 'react-i18next';
import { RequestLicencesModal } from './RequestLicencesModal';

import './Header.css';

export default function LicencesLeft() {
    const { t } = useTranslation();

    const companyDetailsState = useSelector(state => state.userManagement.company);

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className="header--licences-left-container">
            <RequestLicencesModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onClose={() => setModalOpen(false)}
                onAction={() => window.open('mailto:support@getcoach.app')}
            />
            <Body2 color={colors.gray6}>
                <Trans>
                    {t('Licences left: <strong>{{licencesLeft}}</strong> of <strong>{{licences}}</strong>', {
                        licencesLeft: companyDetailsState.data?.userLicencesLeft,
                        licences: companyDetailsState.data?.totalUserLicences,
                    })}
                </Trans>
            </Body2>
            <DefaultButton
                text={t('Request more')}
                style={{ height: '30px' }}
                textColor={colors.dark}
                backgroundColor={colors.background}
                onClick={e => {
                    e.preventDefault();
                    setModalOpen(true);
                }}
            />
        </div>
    );
}
