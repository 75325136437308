export const actionTypes = {
    setContentBlock: 'SET_CONTENT_BLOCK',
    setAttributes: 'SET_ATTRIBUTES',
    setQuestionAttribute: 'SET_QUESTION_ATTRIBUTE',
    setAnswerAttribute: 'SET_ANSWER_ATTRIBUTE',
    setImageAttribute: 'SET_IMAGE_ATTRIBUTE',
    setComparator: 'SET_COMPARATOR',
    setCorrectValuesMap: 'SET_CORRECT_VALUES_MAP',
    setAutogenerateValue: 'SET_AUTOGENERATE_VALUE',
    setComparatorCriteria: 'SET_COMPARATOR_CRITERIA',
    setComparatorCorrect: 'SET_COMPARATOR_CORRECT',
    setIsTemplate: 'SET_IS_TEMPLATE',
    resetState: 'RESET_STATE',
    resetValues:'RESET_VALUES',
};
