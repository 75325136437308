import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CroppingComponent from './CroppingComponent';
import getCroppedImg from 'services/image_management/cropImage';
import { palette } from 'style/theme/theme-constants';
import { DefaultButton } from 'components/elements/Button';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { aspectRatios, views } from 'services/image_management/image-editor-management/local-constants';
import { useTranslation } from 'react-i18next';

export default function EditableImageItem({ file, index }) {
    const {
        currentActivePreviewImage,
        aspectRatio,
        setCurrentActiveIndex,
        setCroppedAreaPixels,
        setCurrentActivePreviewImage,
        setView,
    } = useImageEditor();

    const [enteredMouseOnFile, setEnteredMouseOnFile] = useState(null);

    const { t } = useTranslation();

    return (
        <div
            key={file.name}
            onClick={() => {
                setCurrentActivePreviewImage(file);
                setCurrentActiveIndex(index);
            }}
            onMouseEnter={() => {
                setEnteredMouseOnFile(file);
            }}
            onMouseLeave={() => {
                setEnteredMouseOnFile(null);
            }}
            className="d-flex col-3 py-5 px-0 m-2"
            style={{
                border: `2px solid ${currentActivePreviewImage === file ? palette.primary.main : 'transparent'}`,
                backgroundColor: enteredMouseOnFile === file ? 'rgba(255,255,255, 0.7)' : 'white',
                justifyContent: 'center',
                position: 'relative',
            }}
        >
            {enteredMouseOnFile === file && (
                <DefaultButton
                    className="mr-2"
                    text={t('Edit image')}
                    color="secondary"
                    style={{
                        position: 'absolute',
                        zIndex: 99,
                        bottom: 25,
                        padding: '2px 15px',
                    }}
                    onClick={() => {
                        setCurrentActivePreviewImage(file);
                        setView(views[1]);
                    }}
                />
            )}

            {aspectRatios.map(el => (
                <div
                    style={{
                        opacity: aspectRatio === el ? 1 : 0,
                        zIndex: aspectRatio === el ? 1 : 0,
                    }}
                >
                    <CroppingComponent
                        hovering={enteredMouseOnFile === file}
                        preview={true}
                        file={file}
                        aspectRatio={el}
                        croppedAreaPixels={file.croppedAreaPixels}
                        hasInit={true}
                        setCroppedAreaPixels={async (file, croppedAreaPixels, aspectRatio) => {
                            let croppedUrl = await getCroppedImg(file.url, croppedAreaPixels);
                            file.croppedImage[aspectRatio] = croppedUrl;

                            setCroppedAreaPixels(file, croppedAreaPixels, aspectRatio);
                        }}
                    />
                </div>
            ))}
        </div>
    );
}

EditableImageItem.propTypes = {
    file: PropTypes.any,
    index: PropTypes.any,
};
