import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { CircularProgress } from '@material-ui/core';
import { resetEditQuestionsBulkState } from 'redux/actions/content.actions';
import { useTranslation } from 'react-i18next';

export function BulkEditSavingModal({ modalOpen, setModalOpen }) {
    const editQuestionsBulk = useSelector(state => state.content.editQuestionsBulk);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onClose={() => {
                dispatch(resetEditQuestionsBulkState());
            }}
            onAction={() => {
                setModalOpen(false);
                dispatch(resetEditQuestionsBulkState());
            }}
            title={stateIsLoading(editQuestionsBulk) ? t('Loading') : t('Saving changes')}
            text={
                stateIsLoaded(editQuestionsBulk)
                    ? t(editQuestionsBulk.data)
                    : stateHasFailed(editQuestionsBulk)
                    ? t('Something went wrong, please try again later')
                    : ''
            }
            buttons={[
                {
                    title: t('Continue'),
                    color: colors.white,
                },
            ]}
            hasCancel={false}
            alert={stateIsLoading(editQuestionsBulk) ? <CircularProgress /> : <></>}
        ></ModalDialog>
    );
}

BulkEditSavingModal.propTypes = {
    modalOpen: PropTypes.any,
    setModalOpen: PropTypes.func,
};
