import { CustomSelect } from "components/elements/selector/CustomSelector";
import React, { useEffect, useState } from "react";
import { useContentBlocksBuilder } from "services/content-blocks-management/content-blocks-builder";
import { games } from "services/content-blocks-management/content-blocks-builder/local-constants";
import { useContentInsertionQuestion } from "services/content-management/insertion-control";
import { colors } from "style/colors";
import { Body2 } from "style/typography/Body";
import { Caption1 } from "style/typography/Caption";
import ContentBlocksValuesList from "./ContentBlocksValuesList";

export default function GenerateOptionsByGame({ game }) {


    const {
        attributes,
        questionAttribute,
        setQuestionAttribute,
        setCorrectValuesMap,
        setImageAttribute,
    } = useContentBlocksBuilder();

    const [showGenerateBySelector, setShowGenerateBySelector] = useState(false);

    const { question, setQuestion } = useContentInsertionQuestion();

    function getGenerateByValue() {
        return attributes.find(el => el.value == questionAttribute?.value);
    }

    useEffect(() => {
        let questionVariable = question.match("<%(.*)%>")?.[1];

        if (questionVariable) {
            setCorrectValuesMap(null);
            let attrCol = attributes.find(el => el.value == questionVariable);
            setQuestionAttribute({ value: attrCol.value, id: attrCol.id });
            return;
        }

        if ([games.RACE_CARS, games.TRAFFIC, games.RECALL, games.PINPOINT].includes(game)) {
            setShowGenerateBySelector(true);
            if (game === games.PINPOINT) {
                let imageAttributePinpoint = attributes.find(el => el.selected === true && el.type === "image");
                setImageAttribute({ value: imageAttributePinpoint.value, id: imageAttributePinpoint.id })
            }
            return;
        }

        let questionAttr

        if (game === games.COMPARE) {
            questionAttr = attributes.find(el => el.selected === true && el.type === "image");
        } else if (game == games.SORT_IT) {
            questionAttr = attributes.find(el => el.selected === true && el.type !== "image");
        }
        else if (game === games.NUMBERS) {
            questionAttr = attributes.find(el => el.selected === true && el.type !== "number");
        }
        setCorrectValuesMap(null);
        setQuestionAttribute({ value: questionAttr.value, id: questionAttr.id });
    }, [])

    return (
        <div className="cb-builder--options">
            <Caption1 weight="medium" color={colors.gray6}>
                OPTIONS
            </Caption1>

            {showGenerateBySelector &&
                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
                    <Body2>Autogenerate options by: </Body2>
                    <CustomSelect
                        options={attributes.filter(el => el.selected == true)}
                        value={getGenerateByValue()}
                        placeholder="Select"
                        onChange={el => {
                            setCorrectValuesMap(null);
                            setQuestionAttribute({ value: el.value, id: el.id });
                        }}
                    ></CustomSelect>
                </div>
            }

            <ContentBlocksValuesList />
        </div>
    );
}