import { addNodeUnderParent, getTreeFromFlatData } from 'react-sortable-tree';
import { changeNodeAtPath, removeNodeAtPath, getFlatDataFromTree, walk, find, getNodeAtPath } from 'components/features/trees/SortableTree';

export const convertFromFlatToTree = (data, getNodeKeyParam, getParentKeyParam) => {
    let myFinalResult = [];

    data.forEach(value => {
        let node = {
            ...value,
            treeIndex: value.treeIndex,
            generatedTreeIndex: value.generatedTreeIndex,
            title: value.title,
            parentNode: value.parentNode,
            new: value.main ? value.main : '',
            main: value.main ? value.main : '',
            lessonType: value.lessonType ? value.lessonType : '',
            lessonUrl: value.lessonUrl ? value.lessonUrl : '',
            lessonContent: value.lessonContent ? value.lessonContent : '',
            lessonTitle: value.lessonTitle ? value.lessonTitle : '',
            numberOfQuestions: value.numberOfQuestions ? value.numberOfQuestions : '',
            dateAvailableFrom: value.dateAvailableFrom,
            path: [],
            children: value.children ? value.children : [],
            topic: value.topic ? value.topic : '',
            folderId: value.folderId,
            left: -1,
            right: -1,
            root: value.root ? value.root : null,
            expanded: value.expanded !== undefined && value.expanded !== null ? value.expanded : true,
            imageList: value.imageList ? value.imageList : [],
        };
        if (node.parentNode?.treeIndex === null) {
            node.parentNode.treeIndex = 0;
        }
        myFinalResult.push(node);
    });

    let myTree = getTreeFromFlatData({
        flatData: myFinalResult,
        getKey: getNodeKeyParam ? getNodeKeyParam : getNodeKey,
        getParentKey: getParentKeyParam ? getParentKeyParam : getParentKey,
        rootKey: '0',
    });

    return myTree;
};

export function getParentKey(node) {
    if (!node.parentNode) return -1;
    if (node.parentNode.treeIndex !== null) return node.parentNode.treeIndex;
    if (node.parentNode.generatedTreeIndex !== null) return node.parentNode.generatedTreeIndex;
    return -1;
}

export function removeNode(node, path, data) {
    let res = removeNodeAtPath({
        treeData: data,
        path: path,
        getNodeKey,
    });
    return res;
}

export const setExpand = ({ node, path, data, expand, onUpdate }) => {
    if (node) {
        node.expanded = expand ? true : !node.expanded;

        if (onUpdate) {
            onUpdate(node, node.expanded);
        }

        return changeNodeAtPath({
            treeData: data,
            path: path,
            newNode: { ...node },
            getNodeKey,
        });
    }
};
export const expandAll = (callback, data, onUpdate) => {
    let expand = true;
    walk({
        treeData: data,
        getNodeKey,
        callback: (...props) => {
            data = setExpand({ ...props[0], data, expand, onUpdate });
        },
    });

    if (callback !== null && callback !== undefined && typeof callback === 'function') {
        return callback(data);
    }

    return data;
};

export const changeExpand = (callback, data, node, path, onUpdate) => {
    if (node) {
        node.expanded = !node.expanded;
        onUpdate(node, node.expanded);

        data = changeNodeAtPath({
            treeData: data,
            path: path,
            newNode: { ...node },
            getNodeKey,
        });
    } else {
        walk({
            treeData: data,
            getNodeKey,
            callback: (...props) => {
                data = setExpand({ ...props[0], data, onUpdate });
            },
        });
    }

    if (callback !== null && callback !== undefined && typeof callback === 'function') {
        return callback(data);
    }
};

export const getNodeKey = function(node) {
    let nodeCurr = node.node ? node.node : node;
    return nodeCurr.treeIndex !== null && nodeCurr.treeIndex != -1
        ? nodeCurr.treeIndex
        : nodeCurr.generatedTreeIndex !== null
        ? nodeCurr.generatedTreeIndex
        : -1;
};

export const customSearchMethod = ({ node, searchQuery }) => searchQuery && node.treeIndex == searchQuery;

export const traverseDFSAndSetLeftAndRight = node => {
    // pre order traversal

    let currentId = 0;

    const innerTraverse = node => {
        currentId++;
        node.left = currentId;

        //ako ima deca
        if (node.children?.length > 0) {
            let flatData = getFlatDataFromTree({
                treeData: node.children,
                getNodeKey,
                ignoreCollapsed: false,
            });

            node.right = flatData.length * 2 + currentId + 1;

            node.children.forEach(child => {
                innerTraverse(child);
            });
        }
        //ako e list
        else {
            node.right = node.left + 1;
        }

        currentId = node.right;

        return node;
    };

    return innerTraverse(node);
};

export const traverseDFSAndSetLeftAndRightForTopics = node => {
    // pre order traversal
    let nodeFiltered = filterTree(node, child => child.topic !== true);

    return traverseDFSAndSetLeftAndRight(nodeFiltered);
};

export function DFSPreorderCalculateValues(data, expandBeforeTraversal) {
    if (expandBeforeTraversal) {
        return expandAll(traverseDFSAndSetLeftAndRight, data);
    }
    return traverseDFSAndSetLeftAndRight(data);
}

const appendChildToTree = (treeData, addAsFirstChild, path, node) => {
    return addNodeUnderParent({
        treeData: treeData,
        parentKey: addAsFirstChild ? null : path[path.length - 1],
        expandParent: false,
        ignoreCollapsed: false,
        getNodeKey,
        newNode: node,
        numberOfQuestions: node?.numberOfQuestions ? node.numberOfQuestions : 0,
        addAsFirstChild: addAsFirstChild,
    }).treeData;
};

export const appendTopicsToFolders = (topics, folders, localTopicChanges, searchValue) => {
    let nodeIdentifier = 0;
    let data = topics;
    let treeData = convertFromFlatToTree(folders, getNodeKey, getParentKey);

    let rootFolder = treeData.find(el => el.root === true);

    treeData = rootFolder ? rootFolder.children : treeData;

    if (data !== undefined && data !== null) {
        for (let i = 0; i < data.length; i++) {
            let t = data[i];
            if (localTopicChanges && localTopicChanges[t.treeIndex] !== undefined) {
                t = localTopicChanges[t.treeIndex].treeData;
            }
            if (searchValue && !t.title?.toUpperCase().includes(searchValue?.toUpperCase())) {
                continue;
            }

            t.topic = true;
            t.nodeIdentifier = nodeIdentifier;
            nodeIdentifier++;
            let parent;

            if (t.folderId !== null) {
                parent = findParent(t.folderId, treeData);
            }

            if (parent !== null && parent !== undefined) {
                let parentTreeIndex = parent?.node?.treeIndex ? parent?.node?.treeIndex : parent?.node?.generatedTreeIndex;

                if (parentTreeIndex === rootFolder?.treeIndex) {
                    treeData = appendChildToTree(treeData, false, [], t);
                } else {
                    treeData = appendChildToTree(treeData, false, parent.path, t);
                }
            } else {
                treeData = appendChildToTree(treeData, false, [], t);
            }
        }
    }

    return treeData;
};

export function filterTree(node, filterFunc) {
    // create a new array to store the filtered children
    const filteredChildren = [];

    // loop through each child of the node
    for (let child of node.children) {
        // check if the child passes the filter function
        if (filterFunc(child)) {
            // if the child passes the filter, add it to the filtered array
            child = filterTree(child, filterFunc);
            filteredChildren.push(child);
        }

        // call the filterTree function recursively on the child node
    }

    // set the node's children to the filtered array
    node.children = filteredChildren;

    // return the node
    return node;
}

function findParent(parentTreeIndex, treeData) {
    const searchMethod = ({ node, searchQuery }) => node.treeIndex == searchQuery || node.generatedTreeIndex == searchQuery;

    if (parentTreeIndex !== null && parentTreeIndex !== undefined) {
        let result = find({
            getNodeKey,
            treeData: treeData,
            searchQuery: parentTreeIndex,
            searchMethod: searchMethod,
        });

        if (result.matches.length >= 1) {
            let res = result.matches.filter(m => m.node.topic !== true);
            if (res.length === 1) {
                return res[0];
            }
        }
    }
}

export function findParentByNodePath(path, treeData) {
    const newPath = [...path];
    newPath.splice(-1, 1);
    // newPath is the path of the parent, starting from the current node.

    const parentNode = getNodeAtPath({
        treeData,
        path: newPath,
        getNodeKey: node => node.treeIndex,
    })?.node;

    return parentNode;
}

export function getNodePath(node, flatData) {
    let path = [node.treeIndex];
    let parentId = node.parentNode.treeIndex;

    while (parentId) {
        let prevNode = flatData.find(el => el.node.treeIndex === parentId);
        if (!prevNode) {
            break;
        }
        path = [prevNode.node.treeIndex, ...path];
        parentId = prevNode.node.parentNode.treeIndex;
    }

    return path;
}
export function flatten(data) {
    const result = [];
    recursive(data);
    return result;

    function recursive(data) {
        data.forEach(member => {
            result.push(member);
            recursive(member.children);
        });
    }
}
