import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useContentInsertionQuestion, useCompareVisualOptions } from 'services/content-management/insertion-control';
import { optionDefaults, questionTypes } from 'services/content-management/insertion-control/local-constants';
import { randomize } from 'services/utils/arrayHelpers';
import { InputContentWrapper } from 'components/wrappers/InputContentWrapper';
import { compareOptions } from 'static/game_constants';
import ComparePreview from './ComparePreview';
import CompareInputs from './CompareInputs';

const AddQuestionCompare = ({ game, id, filters }) => {
    const { setQuestion } = useContentInsertionQuestion();

    const { answers: options, extra, insertAnswer, setOptions, setExtra } = useCompareVisualOptions();

    const [images, setImages] = useState([]);

    const previousOptionsLength = useRef(0);
    const randomOptionsRef = useRef(randomize(options, 3, optionDefaults[questionTypes.COMPARE_VISUAL]));

    const [correctOption, setCorrectOption] = useState(null);

    const [compareCriteria, setCompareCriteria] = useState(null);
    const compareInterval = useRef(null);

    function clearIntervalCompare() {
        if (compareInterval.current) {
            clearInterval(compareInterval.current);
            compareInterval.current = null;
        }
    }

    const initializeQuestion = () => {
        setQuestion('');
        insertAnswer(
            Array(5)
                .fill(0)
                .map((_, index) => ({ ...optionDefaults.default, id: index }))
        );
    };

    const sortCompareOptions = (result1, result2) => {
        if (!compareCriteria) {
            return 0;
        }
        if (compareCriteria.value == 'bigger' || compareCriteria.value == 'boolean') {
            if (+result1?.text > +result2?.text) {
                return 1;
            } else if (+result1?.text < +result2?.text) {
                return -1;
            } else return 0;
        } else if (compareCriteria.value == 'smaller') {
            if (+result1?.text < +result2?.text) {
                return 1;
            } else if (+result1?.text > +result2?.text) {
                return -1;
            } else return 0;
        } else return 0;
    };

    function onCriteriaChanged(option) {
        setCompareCriteria(option ? option : '');
        setExtra({
            criteria: option.value,
        });
        if (option.value === 'boolean') {
            setOptions([...options.map(el => ({ ...el, text: el.text ? el.text : '0' }))]);
        }
    }

    useEffect(() => {
        initializeQuestion();

        return () => clearInterval(compareInterval.current);
    }, []);

    useEffect(() => {
        clearIntervalCompare();
    }, [compareCriteria]);

    useEffect(() => {
        if (extra && extra.criteria) {
            let optionsCompareVal = compareOptions.find(el => el.value == extra.criteria);
            setCompareCriteria(optionsCompareVal);
        }
    }, [extra]);

    useEffect(() => {
        let allOptions = options.filter(el => el.images[0]).map(el => el.images[0]);

        if (images.length === 0 && allOptions.length > 0) {
            clearIntervalCompare();
            let result = randomize(allOptions, 2, optionDefaults[questionTypes.COMPARE_VISUAL], false);

            setImages(result);
            randomOptionsRef.current = result;
        } else if (previousOptionsLength.current !== allOptions.length) {
            clearIntervalCompare();
            let result = randomize(allOptions, 2, optionDefaults[questionTypes.COMPARE_VISUAL], false);
            setImages(result);
            randomOptionsRef.current = result;
        }

        previousOptionsLength.current = allOptions.length;
    }, [options]);

    useEffect(() => {
        let allOptions = options.filter(el => el.images[0]);

        if (!compareInterval.current && images[0] && images[1]) {
            compareInterval.current = setInterval(() => {
                setTimeout(() => {
                    let result = randomize(allOptions, 2, optionDefaults[questionTypes.COMPARE_VISUAL]);
                    setImages(result.map(el => el?.images[0]));
                    setCorrectOption(null);

                    setTimeout(() => {
                        let res = sortCompareOptions(result[0], result[1]);
                        if (res == 1) {
                            setCorrectOption('TOP');
                        } else if (res == -1) {
                            setCorrectOption('BOTTOM');
                        } else {
                            setCorrectOption('BOTH');
                        }
                    }, 1000);
                }, 2000);
            }, 2000);
        }
    }, [images, options]);

    return (
        <div className="d-flex flex-row h-100 m-0">
            <InputContentWrapper id={id} filters={filters}>
                <div className="d-flex flex-row w-100 h-100">
                    <CompareInputs
                        game={game}
                        id={id}
                        filters={filters}
                        initializeQuestion={initializeQuestion}
                        compareCriteria={compareCriteria}
                        onCriteriaChanged={onCriteriaChanged}
                    />
                    <ComparePreview images={images} correctOption={correctOption} />
                </div>
            </InputContentWrapper>
        </div>
    );
};

AddQuestionCompare.propTypes = {
    filters: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
};

export default AddQuestionCompare;
