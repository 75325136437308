import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useContentInsertionQuestion, useNumericalAnswer } from 'services/content-management/insertion-control';
import { InputContentWrapper } from 'components/wrappers/InputContentWrapper';
import NumbersInput from './NumbersInputs';
import NumbersPreview from './NumbersPreview';

const AddQuestionNumbers = ({ game, id, filters }) => {
    const { setQuestion } = useContentInsertionQuestion();

    const { setOptions } = useNumericalAnswer();

    const initializeQuestion = () => {
        setQuestion('');
        setOptions([{ text: '', id: 0 }]);
    };

    useEffect(() => {
        initializeQuestion();
    }, []);

    return (
        <div className="d-flex flex-row h-100 m-0">
            <InputContentWrapper id={id} filters={filters}>
                <div className="d-flex flex-row w-100 h-100">
                    <NumbersInput initializeQuestion={initializeQuestion} game={game} id={id} filters={filters} />
                    <NumbersPreview />
                </div>
            </InputContentWrapper>
        </div>
    );
};

AddQuestionNumbers.propTypes = {
    filters: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
};

export default AddQuestionNumbers;
