import React from 'react';
import { Paper } from '@material-ui/core';
import List from '@material-ui/core/List';
import coachAdminIconNew from 'assets/icons/coach-admin-new.png';
import MenuLink from 'containers/layout/module/local_components/navbar/menu_link/MenuLink';

import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard-icon.svg';
import { ReactComponent as ContentIcon } from 'assets/icons/content-icon.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/company-icon.svg';
import { ReactComponent as FeedbackIcon } from 'assets/icons/feedback-icon.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users-icon.svg';
import { ReactComponent as AIIcon } from 'assets/icons/ai-training-wand.svg';
import { ReactComponent as settingsIcon } from 'assets/icons/settings-icon.svg';

import './Navbar.css';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';

const Navbar = ({ history }) => {
    const { t } = useTranslation();

    return (
        <div className="navbar--wrapper">
            <Paper className="navigation-menu navbar--container">
                <div className="d-flex justify-content-center py-4">
                    <img className="navbar--logo" src={coachAdminIconNew} alt="coach admin" />
                </div>
                <List component="nav" aria-label="secondary mailbox folders">
                    <MenuLink id="dashboard-page" to="/dashboard" menuIcon={DashboardIcon} className=" mt-4">
                        {t('Dashboard')}
                    </MenuLink>
                    <MenuLink id="content-page" to="/content/questions" menuIcon={ContentIcon}>
                        {t('Content')}
                    </MenuLink>
                    <MenuLink id="users-page" to="/users/organization" menuIcon={UsersIcon}>
                        {t('Users')}
                    </MenuLink>
                    {/*<MenuLink*/}
                    {/*    to="/settings"*/}
                    {/*    images={{ active: settingsIconActive, inactive: settingsIcon }}*/}
                    {/*    */}
                    {/*>*/}
                    {/*    Settings*/}
                    {/*</MenuLink>*/}

                    <MenuLink id="feedback-page" to="/internal/content-feedback" menuIcon={FeedbackIcon}>
                        {t('Internal Messaging')}
                    </MenuLink>
                    <MenuLink id="company-page" to="/company/roles" menuIcon={CompanyIcon}>
                        {t('Company')}
                    </MenuLink>
                    <MenuLink id="ai-training-page" to="/ai_training" menuIcon={AIIcon}>
                        {t('AI Training')}
                    </MenuLink>
                </List>
                <Caption1 className="navbar--version" color={colors.gray6}>
                    v{process.env.REACT_APP_VERSION}
                </Caption1>
            </Paper>
        </div>
    );
};

export default Navbar;
