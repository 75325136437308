import React from 'react';
import PropTypes from 'prop-types';
import { DefaultButton } from 'components/elements/Button';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { CustomDateRangePicker } from 'components/elements/daterangepicker/CustomDateRangePicker';
import { CustomDatePicker } from 'components/elements/datepicker/CustomDatePicker';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';
import AddFilters from './AddFilters';

import Select from 'react-select';
import Close from 'assets/icons/filter-close.svg';
import Dropdown from 'assets/icons/filter-caret.svg';

import 'style/colors.css';
import './Subheader.css';

export const Subheader = ({ inputs }) => {
    const { t } = useTranslation();

    const DropdownIndicator = props => {
        return <img alt="Dropdown" className="mr-2 cursor-pointer" src={Dropdown} height={17} width={17} />;
    };

    const ClearIndicator = props => {
        return <img alt="Clear" className="mr-2 cursor-pointer" src={Close} height={17} width={17} onClick={props.clearValue} />;
    };

    const MultiValueRemove = props => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', lineHeight: '20px' }} onClick={props.innerProps.onClick}>
                <img alt="Remove" className="mr-2 cursor-pointer" src={Close} height={17} width={17} />
            </div>
        );
    };

    return (
        <div className={'d-flex flex-row justify-content-between w-100 p-0 m-0'}>
            <div className="d-flex flex-row">{getInputs()}</div>
            <div className={'d-flex justify-content-end'}>
                {inputs
                    .filter(input => input.type === 'button' || input.type === 'button-secondary')
                    .map((input, index) => {
                        if (input.type === 'button')
                            return (
                                <div key={index} className={`d-flex justify-content-end ${index !== 0 ? 'ml-2' : ''}`}>
                                    <DefaultButton text={t(input.name)} onClick={input.onClick} />
                                </div>
                            );
                        else {
                            return (
                                <div key={index} className={`d-flex justify-content-end ${index !== 0 ? 'ml-2' : ''}`}>
                                    <DefaultButton text={t(input.name)} backgroundColor="white" textColor={colors.dark} onClick={input.onClick} />
                                </div>
                            );
                        }
                    })}
            </div>
        </div>
    );

    function getInputs() {
        return inputs
            .filter(input => input.type !== 'button' && input.active)
            .map((input, index) => {
                switch (input.type) {
                    case 'value': {
                        return (
                            <div key={index} className={`${index !== inputs.length - 1 ? 'mr-1' : ''}`}>
                                <CustomTextField
                                    isSearch={true}
                                    value={input.value}
                                    setValue={input.setValue}
                                    placeholder={input.placeholder ? t(input.placeholder) : 'Search'}
                                    style={input?.style}
                                ></CustomTextField>
                            </div>
                        );
                    }
                    case 'dropdown': {
                        return (
                            <div key={index} className="mx-1" style={{ width: '140px' }}>
                                <Select
                                    value={input.value ? [...input.dropdownValues].filter(option => option.value === input.value) : ''}
                                    isClearable
                                    components={{ DropdownIndicator, ClearIndicator }}
                                    arrowRenderer={() => {}}
                                    styles={customStyles}
                                    onChange={option => input.setValue(option ? option.value : '')}
                                    placeholder={t(input.placeholder)}
                                    options={[...input.dropdownValues]}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            neutral50: colors.disabled,
                                        },
                                    })}
                                />
                            </div>
                        );
                    }
                    case 'dropdown-multiple': {
                        return (
                            <div key={index} className="mx-1" style={{ width: '300px' }}>
                                <Select
                                    value={input.value}
                                    isClearable
                                    isMulti
                                    components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
                                    arrowRenderer={() => {}}
                                    styles={customStyles}
                                    onChange={input.setValue}
                                    placeholder={t(input.placeholder)}
                                    options={[...input.dropdownValues]}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            neutral50: colors.disabled,
                                        },
                                    })}
                                />
                            </div>
                        );
                    }
                    case 'datepicker': {
                        return (
                            <div key={index}>
                                <CustomDatePicker className={'mx-1'} value={input.value} onChange={date => input.setValue(date)} border={false} />
                            </div>
                        );
                    }
                    case 'daterangepicker': {
                        return (
                            <CustomDateRangePicker
                                key={index}
                                className={'mx-1'}
                                calendarClassName={'calendar-class'}
                                value={input.value}
                                onChange={input.setValue}
                                showDoubleView={true}
                                disableClock={true}
                            ></CustomDateRangePicker>
                        );
                    }
                    case 'add-filters': {
                        return <AddFilters activeFilters={input.activeFilters} updateFilterState={input.updateFilterState} />;
                    }
                    case 'custom': {
                        return input.child;
                    }

                    default: {
                        return <div key={index}> </div>;
                    }
                }
            });
    }
};

Subheader.propTypes = {
    inputs: PropTypes.any,
};

const customStyles = {
    menu: styles => ({
        ...styles,
        zIndex: 1,
    }),
    control: styles => ({
        ...styles,
        border: '1px solid white',
        boxShadow: 'none',
        zIndex: 1,
        '&:hover': {
            border: `1px solid ${colors.mainTheme}`,
        },
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            '&:active': {
                backgroundColor: colors.mainTheme,
            },
            zIndex: 1,
            color: colors.dark,
            backgroundColor: isFocused ? colors.gray3 : 'white',
        };
    },
    indicatorSeparator: (provided, state) => ({}),
};
