import React, { useState } from 'react';
import { colors } from 'style/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NewTopicModal } from './NewTopicModal';
import AddTopics from 'assets/icons/icon-emptystate-topics.svg';
import { useTranslation } from 'react-i18next';

export function EmptyState({ topic, setTopic, setCurrentNode }) {
    const {t} = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div style={{ marginTop: '25%', transform: 'translateY(-50%)' }} className={'d-flex flex-row align-items-center justify-content-center'}>
            <div className="d-flex flex-column align-items-center">
                <img style={{ width: 80, height: 80 }} src={AddTopics} alt="" />
                <h4 style={{ color: colors.secoundaryText }} className={'mt-4'}>
                    {t("Start by adding a topic")}
                </h4>
                <p className={'mb-0 mt-1'} style={{ color: colors.gray6 }}>
                    {t("Start building your content structure by adding your first group of content, called a topic.")}
                </p>
                <button
                    id="content-page-add-new-topic"
                    onClick={() => setModalOpen(true)}
                    type={'button'}
                    className="btn btn-dark d-flex flex-row align-items-center mt-4"
                    style={{ color: colors.mainTheme, width: 150 }}
                >
                    <FontAwesomeIcon icon={faPlus} className={'mr-auto'}></FontAwesomeIcon>
                    {t("Add topic")}
                </button>
            </div>
            <NewTopicModal topic={topic} setTopic={setTopic} modalOpen={modalOpen} setModalOpen={setModalOpen} setCurrentNode={setCurrentNode} />
        </div>
    );
}
