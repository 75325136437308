import { userStatusOptions } from 'static/status_options';

export const usersTableSubheaders = ({
    filtersState,
    activeFilters,
    updateFilterState,
    dispatchFilters,
    tagOptions,
    roleOptions,
    setInviting,
    resetBulkActions,
    inviteVisible = true,
}) => [
    {
        id: 'users-search-input',
        type: 'value',
        value: filtersState.name,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, name: val });
        },
        placeholder: 'Search',
        active: true,
    },
    {
        id: 'users-filter-by-status',
        type: 'dropdown',
        value: filtersState.status,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, status: val });
        },
        placeholder: 'Status',
        clearOnBackspace: true,
        dropdownValues: userStatusOptions,
        active: activeFilters.status.active,
    },
    {
        id: 'users-filter-by-role',
        type: 'dropdown',
        value: filtersState.role,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, role: val });
        },
        placeholder: 'Role',
        clearOnBackspace: true,
        dropdownValues: roleOptions,
        active: activeFilters.role.active,
    },
    {
        id: 'users-filter-by-tags',
        type: 'dropdown-multiple',
        value: filtersState.tags,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, tags: val });
        },
        placeholder: 'Tags',
        clearOnBackspace: true,
        dropdownValues: tagOptions,
        active: activeFilters.tags.active,
    },
    { type: 'add-filters', active: true, activeFilters: activeFilters, updateFilterState: updateFilterState },
    inviteVisible && {
        id: 'invite-user-modal',
        type: 'button',
        name: 'Invite user',
        onClick: setInviting,
    },
];
