import React from 'react';
import Icon from 'assets/icons/checkmark-img.svg';
import Logo from 'assets/icons/coach-logo-black.svg';
import { Trans, useTranslation } from 'react-i18next';
import { H7 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';

export function CreateCompanyWithCodeSuccess() {
    const { t } = useTranslation();

    return (
        <div className="create-company--success">
            <img alt="logo" src={Logo} width={70} height={70}></img>
            <div className="create-company--success-inner">
                <img alt="icon" src={Icon} width={70} height={70}></img>
                <H7 style={{ marginTop: 30 }} color={colors.gray6}>
                    {t('Company successfully created!')}
                </H7>
                <Body2 style={{ maxWidth: '35vw', marginTop: 30 }}>
                    <Trans>
                        {t(
                            "Check your email that you've entered in the form previously and proceed with activating your first account. <br/> You have two more user invitations left to use."
                        )}
                    </Trans>
                </Body2>
            </div>
        </div>
    );
}
