import React, { useMemo, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';
import { OutlinedButton } from '../../elements/Button';
import Paper from '@material-ui/core/Paper/Paper';
import { colors } from '../../../style/colors';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { H7 } from 'style/typography/Heading';
import { Caption1 } from 'style/typography/Caption';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { convertFromFlatToTree } from 'services/utils/treeHelpers';
import { Body1 } from 'style/typography/Body';
import HorizontalMenuPicker from 'components/elements/horizontal_menu_picker/HorizontalMenuPicker';
import SortableTree from '../trees/SortableTree';

import TopicWhite from 'assets/icons/topic-icon-paper-white.svg';
import Topic from 'assets/icons/topic-icon-paper.svg';

import './ConflictModal.css';

export default function ConflictModal({ modalOpen, setModalOpen, oldData, newData, forceSave, treeKeyName }) {
    const classes = useStyles();
    const history = useHistory();
    const classesDialogContent = useStylesDialogContent();

    const [firstTabSelected, setFirstTabSelected] = useState(true);

    function resetPage() {
        history.go(0);
    }

    function handleForceSave() {
        forceSave(true);
        setModalOpen(false);
    }

    let oldDataTree = useMemo(() => {
        if (treeKeyName && treeKeyName in oldData) {
            return convertFromFlatToTree(oldData[treeKeyName]);
        }
        return [];
    }, [oldData, treeKeyName]);

    let newDataTree = useMemo(() => {
        if (treeKeyName && treeKeyName in newData) {
            return convertFromFlatToTree(newData[treeKeyName]);
        }
        return [];
    }, [newData, treeKeyName]);

    return (
        <Modal
            open={modalOpen}
            className={classes.modal}
            onClose={() => {
                setModalOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div>
                <Paper className={`${classesDialogContent.paper} conflict--paper`}>
                    <div className="text-center">
                        <H7 color={colors.dark}>Conflict</H7>
                    </div>
                    <Caption1 color={colors.gray6}>
                        The version you are trying to save is clashing with a different version recently saved. Check the differences and pick which
                        version to keep.
                    </Caption1>
                    <HorizontalMenuPicker
                        firstElement={'JSON preview'}
                        secondElement={'Tree preview'}
                        firstElementSelected={firstTabSelected}
                        setFirstElementSelected={setFirstTabSelected}
                        disabled={!(oldDataTree.length > 0 && newDataTree.length > 0)}
                    />
                    <div className={'col-12 conflict--preview-body'}>
                        {firstTabSelected && (
                            <ReactDiffViewer
                                key={'diff-viewer'}
                                oldValue={JSON.stringify(oldData, null, 5)}
                                newValue={JSON.stringify(newData, null, 5)}
                                splitView={true}
                                styles={newStyles}
                                showDiffOnly={true}
                                leftTitle="Your version"
                                rightTitle="DB version"
                                compareMethod={DiffMethod.LINES}
                            />
                        )}
                        {!firstTabSelected && (
                            <div className="conflict--tree-container">
                                <ConflictModalTree title="Your version" data={oldDataTree} compareToData={newData[treeKeyName]} />
                                <ConflictModalTree title="DB version" data={newDataTree} compareToData={oldData[treeKeyName]} />
                            </div>
                        )}
                    </div>
                    <div className="conflict--button-wrapper">
                        <OutlinedButton text="Force save my changes" onClick={handleForceSave} />
                        <OutlinedButton text="Keep DB changes and refresh" onClick={resetPage} />
                        <OutlinedButton text="Close" onClick={() => setModalOpen(false)} />
                    </div>
                </Paper>
            </div>
        </Modal>
    );
}

function ConflictModalTree({ title, data, compareToData }) {
    return (
        <div>
            <Caption1 color={colors.gray6} weight={'medium'}>
                {title}
            </Caption1>
            <SortableTree
                style={{ height: '45vh', minHeight: '45vh', width: '30vw', minWidth: '30vw' }}
                key={Math.random()}
                onChange={() => {}}
                treeData={data[0].children}
                canDrop={false}
                canDrag={false}
                generateNodeProps={({ node, path }) => {
                    let comparableNode = compareToData.find(el => el.treeIndex === node.treeIndex);
                    let hasSameParent = comparableNode?.parentNode?.treeIndex === node?.parentNode?.treeIndex;
                    let hasSameName = comparableNode?.titleForTraining === node?.titleForTraining;
                    return {
                        className: `${node.className} ${!(hasSameParent && hasSameName) ? 'conflict--red' : ''}`,
                        title: (
                            <div
                                style={node.className === 'parent' ? { height: 40 } : { height: 40 }}
                                className={'d-flex flex-row align-items-center'}
                            >
                                <div style={{ height: '100%' }} className={'px-2 d-flex flex-row align-items-center'}>
                                    <img alt="Type" src={!(hasSameParent && hasSameName) ? TopicWhite : Topic} style={{ width: 20, height: 20 }} />
                                </div>
                                <Body1
                                    weight="medium"
                                    color={!(hasSameParent && hasSameName) ? colors.white : colors.dark}
                                    className={node.className === 'parent' ? 'mb-0 ml-1' : 'mb-0 ml-1'}
                                    style={{ maxWidth: '250px', overflow: 'hidden' }}
                                >
                                    {node.titleForTraining}
                                </Body1>
                            </div>
                        ),
                    };
                }}
            />
        </div>
    );
}

const useStyles = makeStyles(theme => {
    return {
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});
const useStylesDialogContent = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: colors.gray4,
            border: '1px solid ' + colors.gray6,
            boxShadow: theme.shadows[5],
            width: '80vw',
            height: '90vh',
        },
    };
});

const newStyles = {
    variables: {
        light: {
            codeFoldGutterBackground: '#6F767E',
            codeFoldBackground: '#E2E4E5',
        },
    },
};
