export const infoStrings = {
    users:
        'Here you can see info about how many total Coach users are in your company, how many of them are active, invited, administrators, and how many of them have been active on the platform within last week.',
    games:
        'In this section you can track and review which games your employees played the most so far, sorted by the number of total gameplays. The second tab contains the hardest games for your employees, sorted by the percentage of failed questions in the gameplays.',
    questions:
        'This widget shows you how many total questions (divided in active and drafts) you have in all the trainings combined, the percentage of average accuracy your employees have achieved in the app so far, and the most failed, low-performing questions that your employees have answered (incorrectly) through playing in the app.',
    trainings:
        'You can review your trainings here and see how many users are assigned to each training, plus the number of proficient users. Click on each of the trainings to go to their separate training pages and see more details.',
    gameplays:
        'This widget shows you the latest times that any of your employees in the app have finished a gameplay. For each gameplay session, you can track exactly who played it, when they played it, the achieved score and which game it is. The list is updated regularly in real-time.',
};
