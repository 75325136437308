import React, { useEffect, useRef } from 'react';

import './CustomModal.css';

export default function CustomModal({ children, modalOpen, setModalOpen }) {
    const itemRef = useRef();

    function handleClickOutside(event) {
        if (itemRef.current && !itemRef.current.contains(event.target) && modalOpen) {
            setModalOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalOpen]);

    return (
        <div className="custom-modal--modal" style={{ display: modalOpen ? 'block' : 'none' }}>
            <div className="custom-modal--item">
                <div ref={itemRef}>{children}</div>
            </div>
        </div>
    );
}
