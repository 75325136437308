import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRolesAction, inviteMultipleAction, inviteMultipleAdminsAction, setAdminsInvited, setUsersInvited } from 'redux/actions/users.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useHistory } from 'react-router-dom';
import { validateEmail } from 'services/utils/stringHelpers';
import { cloneProperties } from 'services/utils/objectHelpers';
import InvitationFormMultiple from './InvitationFormMultiple';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { colors } from 'style/colors';
import { Circular } from 'components/features/data_table/Circular';
import { OutlinedButton } from 'components/elements/Button';
import { useTranslation } from 'react-i18next';

const MultipleUsersInviteAdmin = ({ data, internalSubmit }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const adminsInvited = useSelector(state => state.userManagement.adminsInvited);

    // useEffect(() => {
    //     dispatch(getAllRolesAction());
    // }, [dispatch]);

    let company = useSelector(state => state.userManagement.company.data);
    let inviteMultipleStateAdmin = useSelector(state => state.userManagement.inviteMultipleUsersAdmin);
    // const roles = useSelector(state => state.userManagement.roles);

    const [forms, setForms] = useState([{ formId: 0, email: '' }]);
    const [formId, setFormId] = useState(0);

    const emailsMap = useRef({});

    let timeout = useRef(0);

    useEffect(() => {
        clearTimeout(timeout.current);
        timeout.current = null;
        timeout.current = setTimeout(() => {
            dispatch(setAdminsInvited(forms));
        }, 500);
    }, [forms]);

    // useEffect(() => {
    //     if (stateIsLoaded(usersInvited)) {
    //         setForms(usersInvited.data);
    //         setFormId(usersInvited.data.length);
    //     }
    // }, [usersInvited]);

    useEffect(() => {
        let checkData = data || forms;
        checkData.forEach((form, index) => {
            if (form.email) {
                if (emailsMap.current[form.email]) {
                    emailsMap.current[form.email].push(form.formId);
                    emailsMap.current[form.email].sort();
                } else {
                    emailsMap.current[form.email] = [index];
                }
            }
        });

        let newForms = checkData
            //     .filter(d => {
            //     return !(d.email?.length === 0 && d.role?.length === 0);
            //
            // })
            .map((d, index) => {
                return {
                    emailError: getEmailError(d.email),
                    formId: index,
                    email: d.email,
                    // role: d['system role'] || d.role,
                    role: d.role,
                };
            });
        setForms(newForms);
        setFormId(checkData.length);

        //eslint-disable-next-line
    }, [data]);

    function addRow() {
        let item = { formId: formId + 1, email: '', role: '' };
        item.emailError = getEmailError('');
        let newForms = [...forms, item];
        setFormId(formId + 1);
        setForms(newForms);
    }

    const handleRemoveRow = id => {
        let form = forms.find(f => f.formId === id);
        if (form) {
            if (emailsMap.current[form.email]) emailsMap.current[form.email] = emailsMap.current[form.email].filter(f => f !== id);
        }
        let newForms = forms.filter(f => f.formId !== id);
        for (let formIndx in newForms) {
            onChangeEmail(newForms[formIndx], newForms[formIndx].email);
        }
        setForms(newForms);
    };

    const getEmailError = (email, id) => {
        if (!email) {
            return {
                message: t('Email must not be empty'),
            };
        } else {
            let okay = validateEmail(email);
            if (!okay) {
                return {
                    message: t('Invalid email'),
                };
            } else {
                let emailMapped = emailsMap.current[email];
                if (emailMapped && emailMapped.length > 1) {
                    if (emailMapped.length > 1) {
                        return {
                            message: t('Email already exists'),
                        };
                    } else return null;
                } else return null;
            }
        }
    };

    function onChangeEmail(form, email) {
        let stateForm = forms.find(f => f.formId === form.formId);
        // let index = forms.findIndex(f => f.formId === form.formId);
        if (form.email) {
            if (emailsMap.current[form.email]) {
                if (emailsMap.current[form.email].length > 1) {
                    emailsMap.current[form.email] = emailsMap.current[form.email].filter(a => a !== form.formId);
                    emailsMap.current[form.email].forEach(id => {
                        let f = forms.find(frm => frm.formId === id);
                        if (f) {
                            f.emailError = getEmailError(f.email, f.formId);
                        }
                    });
                } else {
                    if (emailsMap.current[form.email][0] === form.formId) delete emailsMap.current[form.email];
                }
            }
        }
        if (email) {
            if (emailsMap.current[email]) {
                emailsMap.current[email].push(form.formId);
                emailsMap.current[email].sort();
                emailsMap.current[email].forEach(id => {
                    let f = forms.find(frm => frm.formId === id);
                    if (f) {
                        f.emailError = getEmailError(f.email, f.formId);
                    }
                });
            } else {
                emailsMap.current[email] = [form.formId];
            }
        }
        form.email = email;
        form.emailError = getEmailError(form.email, form.formId);
        cloneProperties(stateForm, form);
        setForms([...forms]);
    }
    function submit() {
        let filteredForms = [];
        let hasError = false;

        let newForms = forms.map((d, index) => {
            let err = {};

            err.emailError = getEmailError(d.email);

            return {
                ...err,
                formId: index,
                email: d.email,
                // role: d['system role'] || d.role,
                role: d.role,
            };
        });
        setForms(newForms);

        if (newForms.filter(f => f.emailError !== null || f.roleError !== null).length > 0) {
            hasError = true;
        }

        if (!hasError) {
            filteredForms = forms.filter(form => form.email.length > 0 && form.role.length > 0);
            dispatch(inviteMultipleAdminsAction(filteredForms));
        }
    }

    return (
        <div className={'row justify-content-center p-2 pt-0 mr-2'}>
            {forms.map(form => {
                return (
                    <InvitationFormMultiple
                        usersInvited={adminsInvited}
                        key={`invitation-multiple-${form.formId}`}
                        company={company.name}
                        onChangeEmail={onChangeEmail}
                        formObj={form}
                        onRemoveRow={handleRemoveRow}
                        showRemove={forms.length > 1}
                        hasRoles={false}
                    />
                );
            })}
            <div className="row mt-3">
                <OutlinedButton id="add-row-button" text={t('Add row')} className="mr-2" onClick={addRow} />
                {internalSubmit && <OutlinedButton text={t('Submit')} className="mr-2" onClick={submit} />}
            </div>
        </div>
    );
};
export default MultipleUsersInviteAdmin;
