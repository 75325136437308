import { ApiExecutor } from './ApiExecutor';
import {
    getQuestionByIdApiRequest,
    getQuestionsPaginatedApiRequest,
    insertQuestionApiRequest,
    getAllQuestionsFromCategoryApiRequest,
    saveNewsApiRequest,
    getNewsApiRequest,
    getSpecificNewsApiRequest,
    deleteNewsApiRequest,
    globalStatisticsApiRequest,
    getGamesApiRequest,
    uploadContentApiRequest,
    companyUnitStructureApiRequest,
    getCompanyInfoApiRequest,
    updateCompanyGlobalConfigApiRequest,
    dashboardStatisticsApiRequest,
    getGameQuestionTypeVariationsRequest,
    getNewsPaginatedApiRequest,
    getQuestionsUnusedInCompanyApiRequest,
    updateMultipleQuestionsTopicsApiRequest,
    editQuestionsBulkApiRequest,
    singleTopicStatsApiRequest,
    getAllNotificationsPaginatedApiRequest,
    createNewNotificationApiRequest,
    saveContentBlockApiRequest,
    getContentBlocksPaginatedApiRequest,
    getContentBlockByIdApiRequest,
    deleteContentBlockByIdApiRequest,
    generateContentFromFileApiRequest,
    generateContentForTopicApiRequest,
    getSingleNotificationApiRequest,
    deleteNotificationApiRequest,
    getLatestUpdatesApiRequest,
    setLatestNewsSeenApiRequest,
    getAllGamesApiRequest,
    preBulkActionQuestionsApiRequest,
    getQuestionsIndexesApiRequest,
} from 'services/api/apiRequests/content';
import _ from 'lodash';
import {
    GET_QUESTION,
    GET_QUESTIONS_FROM_COMPANY,
    GET_QUESTIONS_FOR_CATEGORY,
    INSERT_QUESTION,
    SAVE_NEWS,
    GET_NEWS,
    GET_SPECIFIC_NEWS,
    DELETE_NEWS,
    GET_GLOBAL_STATISTICS,
    GET_GAMES,
    DOWNLOAD_GAME_TEMPLATES,
    UPLOAD_CONTENT,
    EDITING_QUESTIONS_LIST,
    COMPANY_UNIT_STRUCTURE,
    GET_COMPANY_INFO,
    UPDATE_COMPANY_GLOBAL_CONFIGURATION,
    GET_DASHBOARD_STATISTICS,
    EXPORT_GAME_QUESTIONS,
    GET_GAME_QUSTION_TYPE_VARIATIONS,
    GET_QUESTIONS_PAGINATED,
    GET_NEWS_PAGINATED,
    GET_QUESTIONS_UNUSED_IN_COMPANY,
    UPDATE_MULTIPLE_QUESTIONS_TOPICS,
    EDIT_QUESTIONS_BULK,
    GET_SINGLE_TOPIC_STATS,
    GET_ALL_NOTIFICATIONS_PAGINATED,
    CREATE_NEW_NOTIFICATION,
    SAVE_CONTENT_BLOCK,
    GET_CONTENT_BLOCKS_PAGINATED,
    GET_CONTENT_BLOCK_BY_ID,
    DELETE_CONTENT_BLOCK_BY_ID,
    UPLOAD_CONTENT_PROGRESS,
    GENERATE_CONTENT,
    GENERATING_CONTENT_PROGRESS,
    GENERATE_CONTENT_FOR_TOPIC,
    EXPORT_ALL_QUESTIONS,
    EXPORT_QUESTIONS_PROGRESS,
    GET_SINGLE_NOTIFICATION,
    DELETE_NOTIFICATION,
    GET_LATEST_UPDATES,
    SET_LATEST_UPDATES_SEEN,
    GET_ALL_GAMES,
    PRE_BULK_ACTION_QUESTIONS,
    GET_QUESTIONS_INDEXES,
} from '../constants/content.constants';

import { buildActionType } from './buildActionType';
import { ActionStatus } from '../core/ActionStatus';

import { EXPORT_ALL_QUESTIONS_API, EXPORT_GAME_QUESTIONS_API } from 'services/api/api_links';
import { DOWNLOAD_GAME_TEMPLATES_API } from '../../services/api/api_links';

export const getQuestionsPaginatedAction = queryData => async dispatch => {
    await dispatch(ApiExecutor(getQuestionsPaginatedApiRequest(queryData), GET_QUESTIONS_PAGINATED));
};

export const getQuestionsIndexesAction = queryData => async dispatch => {
    await dispatch(ApiExecutor(getQuestionsIndexesApiRequest(queryData), GET_QUESTIONS_INDEXES));
};

export const setQuestionsPaginatedAction = questionIds => async (dispatch, getState) => {
    const paginatedQuestions = getState().content.questionsPaginated.data;
    let elems = paginatedQuestions?.content?.filter(el => questionIds.includes(el.questionId));
    await dispatch({
        type: buildActionType(GET_QUESTIONS_PAGINATED, ActionStatus.DONE),
        payload: {
            ...paginatedQuestions,
            totalElements: elems.length,
            totalPages: 1,
            size: elems.length,
            number: 0,
            content: elems,
        },
    });
};

export const resetGetQuestionsPaginatedAction = () => async dispatch => {
    await dispatch({ type: buildActionType(GET_QUESTIONS_PAGINATED, ActionStatus.RESET) });
};

export const getQuestionById = id => async dispatch => {
    await dispatch(ApiExecutor(getQuestionByIdApiRequest(id), GET_QUESTION));
};

export const updateMultipleQuestionsTopicsAction = data => async dispatch => {
    await dispatch(ApiExecutor(updateMultipleQuestionsTopicsApiRequest(data), UPDATE_MULTIPLE_QUESTIONS_TOPICS));
};
export const resetUpdateMultipleQuestionsAction = data => async dispatch => {
    await dispatch({ type: buildActionType(UPDATE_MULTIPLE_QUESTIONS_TOPICS, ActionStatus.RESET) });
};

export const insertQuestion = data => async dispatch => {
    dispatch({
        type: buildActionType(INSERT_QUESTION, ActionStatus.START),
    });
    await ApiExecutor(insertQuestionApiRequest(data), INSERT_QUESTION)(dispatch);
};
export const resetInsertQuestionAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(INSERT_QUESTION, ActionStatus.RESET),
    });
};

export const getAllQuestionsFromCategory = categoryId => async dispatch => {
    await dispatch(ApiExecutor(getAllQuestionsFromCategoryApiRequest(categoryId), GET_QUESTIONS_FOR_CATEGORY));
};

export const resetEditQuestionsBulkState = () => async dispatch => {
    await dispatch({ type: buildActionType(EDIT_QUESTIONS_BULK, ActionStatus.RESET) });
};

export const setEditingQuestionsListAction = questionList => async dispatch => {
    await dispatch({
        type: buildActionType(EDITING_QUESTIONS_LIST, ActionStatus.DONE),
        payload: {
            questions: questionList,
            index: 0,
        },
    });
};

export const resetSingleQuestionAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_QUESTION, ActionStatus.RESET),
    });
};

export const resetQuestionIndexesAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_QUESTIONS_INDEXES, ActionStatus.RESET),
    });
};

export const saveNewsAction = (data, type, companyId) => async dispatch => {
    await dispatch(ApiExecutor(saveNewsApiRequest(data, type, companyId), SAVE_NEWS));
};

export const getSpecificNewsAction = newsId => async dispatch => {
    await dispatch(ApiExecutor(getSpecificNewsApiRequest(newsId), GET_SPECIFIC_NEWS));
};

export const resetSpecificNewsAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_SPECIFIC_NEWS, ActionStatus.RESET),
    });
};

export const deleteNewsAction = newsId => async (dispatch, getState) => {
    dispatch({ type: buildActionType(DELETE_NEWS, ActionStatus.START) });

    let response = await deleteNewsApiRequest(newsId);

    if (response.success) {
        dispatch({ type: buildActionType(DELETE_NEWS, ActionStatus.DONE) });
    }
};
export const resetDeleteNewsAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(DELETE_NEWS, ActionStatus.RESET),
    });
};

export const getNewsAction = () => async dispatch => {
    await dispatch(ApiExecutor(getNewsApiRequest(), GET_NEWS));
};

export const getLatestUpdatesAction = () => async dispatch => {
    await dispatch(ApiExecutor(getLatestUpdatesApiRequest(), GET_LATEST_UPDATES));
};

export const resetGetLatestUpdatesAction = () => async dispatch => {
    await dispatch({ type: buildActionType(GET_LATEST_UPDATES, ActionStatus.RESET) });
};

export const setLatestNewsSeenAction = () => async dispatch => {
    await dispatch(ApiExecutor(setLatestNewsSeenApiRequest(), SET_LATEST_UPDATES_SEEN));
};

export const resetSetLatestNewsSeenAction = () => async dispatch => {
    await dispatch({ type: buildActionType(SET_LATEST_UPDATES_SEEN, ActionStatus.RESET) });
};

export const getNewsPaginatedAction = queryData => async dispatch => {
    await dispatch({ type: buildActionType(GET_NEWS_PAGINATED, ActionStatus.START) });

    await dispatch(ApiExecutor(getNewsPaginatedApiRequest(queryData), GET_NEWS_PAGINATED));
};

export const resetGetNewsPaginatedAction = () => async dispatch => {
    await dispatch({ type: buildActionType(GET_NEWS_PAGINATED, ActionStatus.RESET) });
};

export const resetSaveNewsState = () => async dispatch => {
    await dispatch({ type: buildActionType(SAVE_NEWS, ActionStatus.RESET) });
};

export const resetEditingNewsState = () => async dispatch => {
    await dispatch({ type: buildActionType(GET_SPECIFIC_NEWS, ActionStatus.RESET) });
};

export const dashboardStatisticsAction = () => async dispatch => {
    await dispatch(ApiExecutor(dashboardStatisticsApiRequest(), GET_DASHBOARD_STATISTICS));
};

export const resetDashboardStatisticsAction = () => async dispatch => {
    await dispatch({ type: buildActionType(GET_DASHBOARD_STATISTICS, ActionStatus.RESET) });
};

export const globalStatisticsAction = userId => async dispatch => {
    await dispatch(ApiExecutor(globalStatisticsApiRequest(userId), GET_GLOBAL_STATISTICS));
};

export const editQuestionsBulkAction = (action, questionIds, data) => async dispatch => {
    await dispatch(ApiExecutor(editQuestionsBulkApiRequest(action, questionIds, data), EDIT_QUESTIONS_BULK));
};

export const preBulkActionQuestionsAction = (questionIds, data) => async dispatch => {
    await dispatch(ApiExecutor(preBulkActionQuestionsApiRequest(questionIds, data), PRE_BULK_ACTION_QUESTIONS));
};

export const resetPreBulkActionQuestionsAction = () => async dispatch => {
    await dispatch({ type: buildActionType(PRE_BULK_ACTION_QUESTIONS, ActionStatus.RESET) });
};

export const companyUnitStructureAction = () => async dispatch => {
    await dispatch(ApiExecutor(companyUnitStructureApiRequest(), COMPANY_UNIT_STRUCTURE));
};

export const singleTopicStatsAction = (userId, topicId) => async dispatch => {
    await dispatch(ApiExecutor(singleTopicStatsApiRequest(userId, topicId), GET_SINGLE_TOPIC_STATS));
};
export const getGamesAction = () => async dispatch => {
    await dispatch(ApiExecutor(getGamesApiRequest(), GET_GAMES));
};
export const getAllGamesAction = () => async dispatch => {
    await dispatch(ApiExecutor(getAllGamesApiRequest(), GET_ALL_GAMES));
};

export const getGameQuestionTypeVariationsAction = () => async dispatch => {
    await dispatch(ApiExecutor(getGameQuestionTypeVariationsRequest(), GET_GAME_QUSTION_TYPE_VARIATIONS));
};

export const downloadGameTemplatesAction = token => async dispatch => {
    await dispatch({ type: buildActionType(DOWNLOAD_GAME_TEMPLATES, ActionStatus.START) });

    const headers = {
        Authorization: 'Bearer ' + token,
    };
    const fetchParams = {
        method: 'GET',
        headers: headers,
    };
    const response = await fetch(DOWNLOAD_GAME_TEMPLATES_API, fetchParams);

    if (response.ok) {
        await dispatch({ type: buildActionType(DOWNLOAD_GAME_TEMPLATES, ActionStatus.DONE) });
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.download = 'game-templates.zip';
        link.href = url;

        link.click();
    } else {
        await dispatch({ type: buildActionType(DOWNLOAD_GAME_TEMPLATES, ActionStatus.FAILED) });
    }
};

export const uploadContentAction = file => async dispatch => {
    await dispatch(ApiExecutor(uploadContentApiRequest(file), UPLOAD_CONTENT));
};

export const updateUploadContentProgressAction = requestProgress => async dispatch => {
    dispatch({
        type: buildActionType(UPLOAD_CONTENT_PROGRESS, ActionStatus.DONE),
        payload: requestProgress,
    });
};
export const resetUploadContentProgressAction = () => async dispatch =>
    dispatch({
        type: buildActionType(UPLOAD_CONTENT_PROGRESS, ActionStatus.RESET),
    });

export const exportGameQuestionsAction = (gameSlug, token) => async dispatch => {
    await dispatch({ type: buildActionType(EXPORT_GAME_QUESTIONS, ActionStatus.START) });

    const headers = {
        Authorization: 'Bearer ' + token,
    };
    const fetchParams = {
        method: 'GET',
        headers: headers,
    };
    const response = await fetch(EXPORT_GAME_QUESTIONS_API(gameSlug), fetchParams);

    if (response.ok) {
        await dispatch({ type: buildActionType(EXPORT_GAME_QUESTIONS, ActionStatus.DONE) });
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.download = `${gameSlug}_export.xls`;
        link.href = url;

        link.click();
    } else {
        await dispatch({ type: buildActionType(EXPORT_GAME_QUESTIONS, ActionStatus.FAILED) });
    }
};

export const exportAllQuestionsAction = token => async dispatch => {
    await dispatch({ type: buildActionType(EXPORT_ALL_QUESTIONS, ActionStatus.START) });

    const headers = {
        Authorization: 'Bearer ' + token,
    };
    const fetchParams = {
        method: 'GET',
        headers: headers,
    };
    const response = await fetch(EXPORT_ALL_QUESTIONS_API, fetchParams);

    if (response.ok) {
        await dispatch({ type: buildActionType(EXPORT_ALL_QUESTIONS, ActionStatus.DONE) });
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.download = `all_questions_export.zip`;
        link.href = url;

        link.click();
    } else {
        await dispatch({ type: buildActionType(EXPORT_ALL_QUESTIONS, ActionStatus.FAILED) });
    }
};

export const resetExportQuestionsProgressAction = () => async dispatch =>
    dispatch({
        type: buildActionType(EXPORT_QUESTIONS_PROGRESS, ActionStatus.RESET),
    });
export const resetExportQuestionsAction = () => async dispatch =>
    dispatch({
        type: buildActionType(EXPORT_ALL_QUESTIONS, ActionStatus.RESET),
    });

export const resetUploadContentStateAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(UPLOAD_CONTENT, ActionStatus.RESET),
    });
};

export const getCompanyInfoAction = () => async dispatch => {
    await dispatch(ApiExecutor(getCompanyInfoApiRequest(), GET_COMPANY_INFO));
};

export const updateCompanyGlobalConfigurationAction = data => async dispatch => {
    await dispatch(ApiExecutor(updateCompanyGlobalConfigApiRequest(data), UPDATE_COMPANY_GLOBAL_CONFIGURATION));
};

export const resetUpdateCompanyGlobalConfigurationAction = data => async dispatch => {
    await dispatch({
        type: buildActionType(UPDATE_COMPANY_GLOBAL_CONFIGURATION, ActionStatus.RESET),
    });
};

export const getQuestionsUnusedInCompanyAction = (id, query) => async (dispatch, getState) => {
    let state = getState().content.topics.questionsUnusedInCompany;
    let localState = getState().content.topics.localTopicChanges;

    dispatch({ type: buildActionType(GET_QUESTIONS_UNUSED_IN_COMPANY, ActionStatus.START) });

    let changedQuestions = localState?.data?.changedQuestions;
    if (changedQuestions) {
        let ignoreIds = [];
        ignoreIds.push(...changedQuestions.map(el => el.questionId));
        query = { ...query, ignoreIds: ignoreIds };
    }
    let response = await getQuestionsUnusedInCompanyApiRequest(id, query);
    if (response.success) {
        let prevData = state.data;
        let itemCount = 0;
        let allItems = [];

        let changedQuestions = localState?.data?.changedQuestions;

        let localRemoved = changedQuestions?.filter(el => el.categoryId == null);

        if (localRemoved) {
            itemCount = itemCount + localRemoved?.length;
        }

        if (changedQuestions && query.page == 0) {
            if (localRemoved.length > 0) {
                allItems.push(...localRemoved);
            }
        } else if (query.page != 0) {
            if (prevData?.content && response.data.number != 0) {
                allItems.push(...prevData.content);
            }
        }
        itemCount = itemCount + (response.data?.totalElements ?? 0);

        allItems.push(...response.data.content);
        dispatch({
            type: buildActionType(GET_QUESTIONS_UNUSED_IN_COMPANY, ActionStatus.DONE),
            payload: { ...response.data, content: allItems, localItemCount: itemCount },
        });
    }
};

// NOTIFICATION ACTIONS

export const resetNotificationCreatedAction = () => async dispatch => {
    dispatch({ type: buildActionType(CREATE_NEW_NOTIFICATION, ActionStatus.RESET) });
};

export const getAllNotificationsAction = queryData => async dispatch => {
    await dispatch(ApiExecutor(getAllNotificationsPaginatedApiRequest(queryData), GET_ALL_NOTIFICATIONS_PAGINATED));
};

export const resetGetAllNotificationsAction = queryData => async dispatch => {
    dispatch({ type: buildActionType(GET_ALL_NOTIFICATIONS_PAGINATED, ActionStatus.RESET) });
};

export const getSingleNotificationAction = notificationId => async dispatch => {
    await dispatch(ApiExecutor(getSingleNotificationApiRequest(notificationId), GET_SINGLE_NOTIFICATION));
};

export const resetGetSingleNotificationAction = () => async dispatch => {
    await dispatch({ type: buildActionType(GET_SINGLE_NOTIFICATION, ActionStatus.RESET) });
};

export const createNewNotificationAction = data => async dispatch => {
    await dispatch(ApiExecutor(createNewNotificationApiRequest(data), CREATE_NEW_NOTIFICATION));
};

export const deleteNotificationAction = id => async dispatch => {
    await dispatch(ApiExecutor(deleteNotificationApiRequest(id), DELETE_NOTIFICATION));
};

export const resetDeleteNotificationAction = () => async dispatch => {
    dispatch({ type: buildActionType(DELETE_NOTIFICATION, ActionStatus.RESET) });
};

// CONTENT BLOCK ACTIONS

export const saveContentBlock = data => async dispatch => {
    await dispatch(ApiExecutor(saveContentBlockApiRequest(data), SAVE_CONTENT_BLOCK));
};

export const deleteContentBlock = id => async dispatch => {
    await dispatch(ApiExecutor(deleteContentBlockByIdApiRequest(id), DELETE_CONTENT_BLOCK_BY_ID));
};
export const resetDeleteContentBlock = () => async dispatch => {
    await dispatch({ type: buildActionType(DELETE_CONTENT_BLOCK_BY_ID, ActionStatus.RESET) });
};

export const getContentBlocksPaginated = queryData => async dispatch => {
    await dispatch(ApiExecutor(getContentBlocksPaginatedApiRequest(queryData), GET_CONTENT_BLOCKS_PAGINATED));
};

export const getContentBlockById = id => async dispatch => {
    await dispatch(ApiExecutor(getContentBlockByIdApiRequest(id), GET_CONTENT_BLOCK_BY_ID));
};
export const resetSaveContentBlock = () => async dispatch => {
    await dispatch({ type: buildActionType(SAVE_CONTENT_BLOCK, ActionStatus.RESET) });
};

//AI TRAINING ACTIONS

export const generateContentFromFileAction = (file, title, trainingId, language, gamesToGenerateFor) => async dispatch => {
    dispatch({ type: buildActionType(GENERATE_CONTENT, ActionStatus.START) });
    await dispatch(ApiExecutor(generateContentFromFileApiRequest(file, title, trainingId, language, gamesToGenerateFor), GENERATE_CONTENT));
};

export const generateContentForTopicAction = (topicId, language, chosenGames) => async dispatch => {
    await dispatch(ApiExecutor(generateContentForTopicApiRequest(topicId, language, chosenGames), GENERATE_CONTENT_FOR_TOPIC));
};

export const resetGenerateContentForTopicAction = () => async dispatch => {
    await dispatch({ type: buildActionType(GENERATE_CONTENT_FOR_TOPIC, ActionStatus.RESET) });
};

export const updateGeneratingContentProgressAction = requestProgress => async dispatch => {
    dispatch({
        type: buildActionType(GENERATING_CONTENT_PROGRESS, ActionStatus.DONE),
        payload: requestProgress,
    });
};
export const resetUpdateGeneratingContentProgressAction = () => async dispatch =>
    dispatch({
        type: buildActionType(GENERATING_CONTENT_PROGRESS, ActionStatus.RESET),
    });
