import PropTypes from 'prop-types';
import React from 'react';

import 'style/colors.css';
import './Checkbox.css';

function Checkbox(props) {
    return (
        <div>
            <label className={`${props.circular ? 'circular' : ''} container`}>
                <input type="checkbox" checked={props.checked} onChange={props.onClick} disabled={props.disabled} value="" />
                <span className="checkmark"></span>
            </label>
        </div>
    );
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    circular: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Checkbox;
