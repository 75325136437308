import React, { useEffect, useMemo, useState } from 'react';
import { H5 } from 'style/typography/Heading';
import CaretTable from 'assets/icons/caret-table.svg';
import { TableCellContent, TableHeader } from 'components/features/data_table/TableElements';
import { Subheader } from 'components/features/data_table/subheader/Subheader';
import DataTable from 'react-data-table-component';
import { customStylesTable } from 'style/elements/table';

import './ContentBlocks.css';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { ActionMenu } from 'components/features/data_table/table_helpers/ActionMenu';
import { userStatusOptions } from 'static/status_options';
import { useHistory } from 'react-router-dom';
import ContentBlock from './content_block/ContentBlock';

import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { useContentBlock } from 'services/content-blocks-management/content-blocks';
import { cellTypes } from 'services/content-blocks-management/content-blocks/local-constants';
import { deleteContentBlock, getContentBlockById, getContentBlocksPaginated, resetDeleteContentBlock, saveContentBlock } from 'redux/actions/content.actions';
import { useDispatch, useSelector } from 'react-redux';
import { create_UUID } from 'services/utils/stringHelpers';
import { stateIsLoaded } from 'redux/core/stateHelpers';

import _ from 'lodash';
import toast from 'react-hot-toast';


export default function ContentBlocksTab() {
    const history = useHistory();
    const dispatch = useDispatch();

    const { contentBlock, setContentBlock, resetState } = useContentBlock();

    const contentBlocksPaginatedState = useSelector(state => state.content.contentBlocksPaginated);
    const contentBlockByIdState = useSelector(state => state.content.contentBlockById);
    const deleteContentBlockState = useSelector(state => state.content.deleteContentBlock);

    function getContentBlockTypes(data) {
        let typesString = '';
        if (data?.columns?.find(el => el.type === cellTypes.TEXT)) typesString.length == 0 ? (typesString = 'T') : (typesString += ' T');
        if (data?.columns?.find(el => el.type === cellTypes.NUMERICAL)) typesString.length == 0 ? (typesString = 'N') : (typesString += ' N');
        if (data?.columns?.find(el => el.type === cellTypes.IMAGE)) typesString.length == 0 ? (typesString = 'I') : (typesString += ' I');
        return typesString;
    }

    const columns = useMemo(
        () => [
            {
                name: <TableHeader text="Name" />,
                selector: 'name',
                sortable: 'true',
                wrap: true,
                width: '15%',
                fontWeight: '800w',
                cell: row => <TableCellContent onClick={() => onRowClick(row)} text={row.name} isLoading={false} />,
            },
            {
                name: <TableHeader text="Created By" />,
                selector: 'created_by',
                sortable: 'true',
                width: '15%',
                cell: row => <TableCellContent onClick={() => onRowClick(row)} text={row.created_by} isLoading={false} />,
            },
            {
                name: <TableHeader text="Date" />,
                selector: 'date',
                sortable: 'true',
                width: '10%',
                cell: row => <TableCellContent onClick={() => onRowClick(row)} text={'no data yet'} isLoading={false} />,
            },
            {
                name: <TableHeader text="Items" />,
                selector: 'items',
                sortable: 'true',
                width: '8%',
                cell: row => <TableCellContent onClick={() => onRowClick(row)} text={row.data?.rows?.length} isLoading={false} />,
            },
            {
                name: <TableHeader text="Types" />,
                selector: 'types',
                sortable: 'true',
                width: '10%',
                cell: row => <TableCellContent onClick={() => onRowClick(row)} text={getContentBlockTypes(row.data)} isLoading={false} />,
            },
            {
                name: <TableHeader text="Questions" />,
                selector: 'questions',
                sortable: 'true',
                width: '10%',
                cell: row => <TableCellContent onClick={() => onRowClick(row)} text={'631'} isLoading={false} />,
            },
            {
                name: <TableHeader text="Topic" />,
                selector: 'topic',
                sortable: 'true',
                width: '23%',
                cell: row => <TableCellContent onClick={() => onRowClick(row)} text={'no data yet'} isLoading={false} />,
            },
            {
                name: <TableHeader text="" />,
                allowOverflow: true,
                button: true,
                width: '5%',
                cell: row => <ActionMenu row={row} buttons={actionButtons(row)} />,
            },
        ],
        []
    );
    const subheaders = [
        {
            type: 'value',
            value: '',
            setValue: val => {
                // setCurrentPage(0);
                // setSearchValue(val);
            },
            placeholder: 'Name',
        },
        {
            type: 'dropdown',
            value: 'filterValueStatus',
            setValue: val => {
                // setCurrentPage(0);
                // setFilterValueStatus(val);
            },
            placeholder: 'Category',
            clearOnBackspace: true,
            dropdownValues: userStatusOptions,
        },
        {
            type: 'dropdown',
            value: 'filterValueStatus',
            setValue: val => {
                // setCurrentPage(0);
                // setFilterValueStatus(val);
            },
            placeholder: 'Type',
            clearOnBackspace: true,
            dropdownValues: userStatusOptions,
        },
        {
            type: 'dropdown',
            value: 'filterValueStatus',
            setValue: val => {
                // setCurrentPage(0);
                // setFilterValueStatus(val);
            },
            placeholder: 'Game',
            clearOnBackspace: true,
            dropdownValues: userStatusOptions,
        },
        {
            type: 'button',
            name: 'Create Table',
            onClick: () => {
                let newData = {
                    name: 'New Content Block',
                    data: { columns: [], rows: [] },
                    id: -1
                };
                resetState();
                setContentBlock(newData);
            },
        },
    ];

    const actionButtons = row => [
        {
            name: 'View',
            onClick: () => { },
            visible: true,
        },
        {
            name: 'Delete',
            onClick: () => {
                dispatch(deleteContentBlock(row.id));
            },
            visible: true,
        },
    ];

    function handleSort(_) { }

    useEffect(() => {
        dispatch(getContentBlocksPaginated({ page: 0, size: 10 }));
    }, [deleteContentBlockState])

    useEffect(() => {
        if (stateIsLoaded(deleteContentBlockState)) {
            dispatch(resetDeleteContentBlock());
            toast.success('Successfully deleted!');
        }
    }, [deleteContentBlockState])


    function getContentBlockDataFormated(data) {
        let columnsOrderCurrent = 0;
        let cellsId = 0;
        let rowOrderCurrent = 1;

        let tempColToUUID = {};

        return {
            columns: data.columns.map(el => {
                let uniqueUUIDCol = create_UUID();
                tempColToUUID[el.key] = uniqueUUIDCol;
                return {
                    generatedId: uniqueUUIDCol,
                    colOrder: columnsOrderCurrent++,
                    blockId: 1,
                    type: el.type,
                    key: el.key,
                    name: el.value,
                }
            }), rows: data.rows.map(el => {
                let uniqueUUIDRow = create_UUID();
                let items = Object.keys(el).map(elObj => {
                    let uniqueUUIDCell = create_UUID();
                    return {
                        generatedId: uniqueUUIDCell,
                        generatedColId: tempColToUUID[elObj],
                        generatedRowId: uniqueUUIDRow,
                        cellValue: el[elObj],
                    }
                })
                return {
                    generatedRowId: uniqueUUIDRow, rowOrder: rowOrderCurrent++, cells: items
                };
            }),
        }
    }

    function onRowClick(row) {
        dispatch(getContentBlockById(row.id));
    }

    function navigateToContentBlock() {
        history.push({
            pathname: `/content_blocks/4`,
        });
    }

    useEffect(() => {
        if (stateIsLoaded(contentBlockByIdState)) {
            resetState();
            setContentBlock(_.cloneDeep(contentBlockByIdState.data));
        }
    }, [contentBlockByIdState])

    return (
        <div className="content-blocks--tab-container">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 15 }}>
                <H5 weight="light">Content blocks</H5>
                {contentBlock && (
                    <div style={{ cursor: 'pointer' }}>
                        <Body2
                            color={colors.gray6}
                            onClick={() => {
                                setContentBlock('');
                            }}
                        >
                            Go Back
                        </Body2>
                    </div>
                )}
            </div>
            {contentBlock && <ContentBlock />}
            {!contentBlock && (
                <DataTable
                    sortIcon={<img height={15} width={13} style={{ marginLeft: '10px' }} src={CaretTable}></img>}
                    selectableRowsComponent={React.forwardRef((props, ref) => (
                        <div ref={ref} style={{ height: '100%', width: '100%' }}>
                            <Checkbox onClick={props.onClick} checked={props.checked}></Checkbox>
                        </div>
                    ))}
                    columns={columns}
                    paginationPerPage={100}
                    data={
                        contentBlocksPaginatedState?.data?.content
                    }
                    defaultSortField="name"
                    noHeader={true}
                    subHeader={true}
                    onSort={handleSort}
                    sortServer
                    onRowClicked={onRowClick}
                    pagination={true}
                    selectableRows={true}
                    highlightOnHover={true}
                    subHeaderComponent={<Subheader inputs={subheaders} />}
                    subHeaderAlign={'left'}
                    contextActions={actionButtons}
                    // onSelectedRowsChange={({ selectedRows }) => onSelectedRow(selectedRows)}
                    customStyles={customStylesTable}
                    theme={'tableTheme'}
                // paginationComponent={_ => (
                //     <PaginationComponent
                //         getPrev={getPrevPage}
                //         getNext={getNextPage}
                //         getPage={getPage}
                //         currentPage={currentPage}
                //         totalPages={usersPaginatedState.data.totalPages}
                //         totalItems={usersPaginatedState?.data?.totalElements}
                //         size={size}
                //         setSize={size => {
                //             setCurrentPage(0);
                //             setSize(size);
                //         }}
                //     />
                // )}
                ></DataTable>
            )}
        </div>
    );
}
