import React, { useEffect } from 'react';
import { logout } from '../../redux/actions/auth.actions';
import { useDispatch } from 'react-redux';

export const Logout = ({ history }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
    }, [dispatch, history]);

    return <div></div>;
};
