import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setEditingQuestionsListAction,
    getQuestionsPaginatedAction,
    editQuestionsBulkAction,
    exportAllQuestionsAction,
    preBulkActionQuestionsAction,
    resetPreBulkActionQuestionsAction,
    getQuestionsIndexesAction,
} from 'redux/actions/content.actions';

import { stateHasFailed, stateIsLoaded, stateIsLoading, stateIsNotInitialized } from 'redux/core/stateHelpers';
import DataTable from 'react-data-table-component';
import { useHistory, useLocation } from 'react-router-dom';
import { Subheader } from 'components/features/data_table/subheader/Subheader';
import { AddQuestionModal } from './add_question_modal/AddQuestionModal';
import { getQueryVariable } from 'services/utils/linkHelpers';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { customStylesTable } from 'style/elements/table';
import { TableCellContent, TableHeader } from 'components/features/data_table/TableElements';
import CaretTable from 'assets/icons/caret-table.svg';
import PaginationComponent from 'components/features/data_table/pagination_component/PaginationComponent';
import { linkBuilderFromQuery } from 'services/api/api_builders';
import { getTitleCase } from 'services/utils/stringHelpers';
import { paramsToObject, removeEmptyPropsFromObject, setTimeoutLastExecute } from 'services/utils/objectHelpers';
import CustomCheckboxForTable from 'components/elements/checkbox/CustomCheckboxForTable';
import { useTableSelections } from 'services/custom-hooks/useTableSelections';
import { DeleteBulkModal } from './local_components/modals/DeleteBulkModal';
import { ChangeStatusBulkModal } from './local_components/modals/ChangeStatusBulkModal';
import { ChangeTopicBulkModal } from './local_components/modals/ChangeTopicBulkModal';
import { BulkEditSavingModal } from './local_components/modals/BulkEditSavingModal';
import { usePagination } from 'components/features/data_table/pagination_component/usePagination';
import { getAllTrainingsAction } from 'redux/actions/trainings.actions';
import { questionActionsOptions } from 'static/question_constants';
import QuestionTableActions from './table_components/QuestionTableActions';
import { questionConstValuesInitialState, questionFiltersInitialState } from 'static/filters';
import { questionTableSubheaders } from './table_components/QuestionTableSubheaders';
import { ExportQuestionsModal } from './export_questions_modal/ExportQuestionsModal';
import { useFilters } from 'components/features/data_table/pagination_component/useFilters';
import { tokenHelper } from '../../../../../../services/auth/tokenHelpers';
import { useTranslation } from 'react-i18next';
import TableEmptyState from 'components/features/data_table/table_helpers/TableEmptyState';
import { LoadingAnimation } from 'components/features/lottie/LoadingAnimation';

import './Questions.css';

export function Questions() {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    const filterLastIncomplete = getQueryVariable('filterLastIncomplete');
    const dispatch = useDispatch();
    const paginatedQuestionsState = useSelector(state => state.content.questionsPaginated);
    const allTopicsState = useSelector(state => state.content.topics.allTopicsFromCompany);
    const gamesState = useSelector(state => state.content.games);
    const editQuestionsBulk = useSelector(state => state.content.editQuestionsBulk);
    const allTrainingsState = useSelector(state => state.content.allTrainings);

    const [savingModalOpened, setSavingModalOpened] = useState(false);

    const [stateLoaded, setStateLoaded] = useState(false);

    const [addQuestionModalOpen, setAddQuestionModalOpen] = useState(false);
    const [exportQuestionsModalOpen, setExportQuestionsModalOpen] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    const isInitialLoad = useRef(true);

    const {
        areAllOptionsFromThisPageSelected,
        selectedRows,
        setSelectedRows,
        addSelectedRow,
        deselectAll,
        removeSelectedRow,
        selectAll,
        allQuestionsSelected,
        setAllQuestionsSelected,
        filteredData,
        selectedRowsArr,
    } = useTableSelections({
        paginatedState: paginatedQuestionsState,
        rowSelector: row => row.questionId,
    });

    const [currentActionOption, setCurrentActionOption] = useState(false);

    const { filtersState, activeFilters, dispatchFilters, updateFilterState } = useFilters({ initialFiltersState: questionFiltersInitialState });

    const [constValuesState, dispatchValues] = useReducer((state, action) => {
        return { ...state, ...action };
    }, questionConstValuesInitialState);

    const filters = useMemo(() => {
        return {
            searchQuery: filtersState.searchQuery ? encodeURIComponent(filtersState.searchQuery) : '',
            createdFrom: filtersState.dateRange ? Date.parse(filtersState.dateRange[0]) : null,
            createdTo: filtersState.dateRange ? Date.parse(filtersState.dateRange[1]) : null,
            page: filtersState.page,
            status: filtersState.status,
            game: filtersState.game,
            topic: filtersState.topic,
            training: filtersState.training,
            size: filtersState.size,
            orderBy: filtersState.sortParam?.selector,
            ascending: filtersState.sortParam?.ascending,
        };
    }, [filtersState]);

    const { getPrevious, getNext, getPage } = usePagination({
        paginatedState: paginatedQuestionsState,
        currentPage: filters.page,
        setCurrentPage: page => dispatchFilters({ page: page }),
    });

    const totalSelectedQuestions = allQuestionsSelected ? paginatedQuestionsState.data.totalElements : selectedRowsArr.length;

    const createQuestions = () => {
        setAddQuestionModalOpen(true);
    };

    const handleRowClick = row => {
        dispatch(getQuestionsIndexesAction({ ...filters }));
        history.push({
            pathname: `/content/questions/update/${row.questionId}`,
            state: {
                from: location.pathname,
                filters: filters,
                id: 5,
            },
        });
    };

    const handleSort = async (column, sortDirection) => {
        if (selectedRowsArr.length === 0) {
            dispatchFilters({ page: 0, sortParam: { selector: column.selector, ascending: sortDirection == 'asc' ? 'true' : 'false' } });
        }
    };

    const resetBulkActions = useCallback(() => {
        if (Object.keys(selectedRows).length > 0) {
            setSelectedRows({});
        }
        setAllQuestionsSelected(false);
        setCurrentActionOption(false);
    }, [selectedRows]);

    function getLocationQuestionClick(row) {
        return {
            pathname: `/content/questions/update/${row.questionId}`,
            state: {
                from: location.pathname,
                filters: filters,
                id: 5,
            },
        };
    }

    const areItemsSelected = selectedRowsArr.length === 0;

    const columns = useMemo(
        () => [
            {
                name: <CustomCheckboxForTable checked={areAllOptionsFromThisPageSelected()} selectAll={selectAll} deselectAll={deselectAll} />,
                width: '50px',
                cell: row => {
                    return (
                        <div style={{ height: '100%', width: '100%' }}>
                            <Checkbox
                                onClick={e => {
                                    if (!row.selected) {
                                        addSelectedRow(row);
                                    } else {
                                        removeSelectedRow(row);
                                        setAllQuestionsSelected(false);
                                    }
                                }}
                                checked={!!row.selected}
                            ></Checkbox>
                        </div>
                    );
                },
            },
            {
                name: areItemsSelected ? (
                    <TableHeader text={t('Question')} />
                ) : (
                    <QuestionTableActions
                        currentActionOption={currentActionOption}
                        setCurrentActionOption={setCurrentActionOption}
                        setModalOpened={setModalOpened}
                        handleRowClick={handleRowClick}
                        selectedRows={selectedRows}
                        allQuestionsSelected={allQuestionsSelected}
                        selectedRowsArr={selectedRowsArr}
                        preCheckBulk={preCheckBulk}
                    />
                ),
                selector: 'text',
                sortable: areItemsSelected,
                wrap: true,
                sortFunction: (a, b) => a.question.localeCompare(b.question),
                width: 'calc(40%)',
                allowOverflow: selectedRowsArr.length > 0,
                cell: row => {
                    return (
                        <TableCellContent
                            key="title"
                            onClick={handleRowClick}
                            row={row}
                            to={getLocationQuestionClick(row)}
                            isLoading={!stateLoaded}
                            cellFactor={0.4}
                            text={row.question}
                        />
                    );
                },
            },
            {
                name: areItemsSelected ? <TableHeader text={t('Topic')} /> : null,
                selector: 'categoryName',
                sortable: areItemsSelected,
                sortFunction: (a, b) => a.topic.localeCompare(b.topic),
                width: '15%',
                cell: row => (
                    <TableCellContent
                        key="categoryName"
                        to={getLocationQuestionClick(row)}
                        onClick={handleRowClick}
                        row={row}
                        cellFactor={0.15}
                        isLoading={!stateLoaded}
                        text={row.topic}
                    />
                ),
            },
            {
                name: areItemsSelected ? <TableHeader text={t('Created')} /> : null,
                selector: 'dateCreated',
                sortable: areItemsSelected,
                width: '15%',
                cell: row => (
                    <TableCellContent
                        key="dateCreated"
                        cellFactor={0.15}
                        onClick={handleRowClick}
                        row={row}
                        to={getLocationQuestionClick(row)}
                        isLoading={!stateLoaded}
                        text={row.dateCreated}
                    />
                ),
            },
            {
                name: areItemsSelected ? <TableHeader text={t('Game')} /> : null,
                selector: 'gameName',
                sortable: areItemsSelected,
                width: 'calc(15%-48px)',
                cell: row => (
                    <TableCellContent
                        key="gameName"
                        cellFactor={0.15}
                        to={getLocationQuestionClick(row)}
                        onClick={handleRowClick}
                        row={row}
                        isLoading={!stateLoaded}
                        text={t(row.game)}
                    />
                ),
            },
            {
                name: areItemsSelected ? <TableHeader text={t('Status')} /> : null,
                selector: 'status',
                sortable: areItemsSelected,
                width: '15%',
                cell: row => (
                    <TableCellContent
                        key="status"
                        cellFactor={0.15}
                        to={getLocationQuestionClick(row)}
                        onClick={handleRowClick}
                        row={row}
                        isLoading={!stateLoaded}
                        text={getTitleCase(row.status)}
                    />
                ),
            },
        ],
        [stateLoaded, selectedRows, areAllOptionsFromThisPageSelected, addSelectedRow, removeSelectedRow, currentActionOption, selectAll]
    );

    async function exportQuestions() {
        let token = await tokenHelper.getToken();

        dispatch(exportAllQuestionsAction(token));
        setExportQuestionsModalOpen(true);
    }

    function setFilters(stateFilters) {
        dispatchFilters({
            searchQuery: stateFilters.searchQuery,
            page: Number(stateFilters.page ?? 0),
            status: stateFilters.status,
            game: stateFilters.game,
            topic: Number(stateFilters.topic),
            training: Number(stateFilters.training),
            size: Number(stateFilters.size ?? 10),
            dateRange:
                stateFilters.createdFrom && stateFilters.createdTo
                    ? [new Date(parseInt(stateFilters.createdFrom)), new Date(parseInt(stateFilters.createdTo))]
                    : null,
            sortParam: stateFilters.orderBy && stateFilters.ascending ? { selector: stateFilters.orderBy, ascending: stateFilters.ascending } : null,
        });
    }

    function preCheckBulk() {
        dispatch(preBulkActionQuestionsAction(selectedRowsArr, allQuestionsSelected ? { filters: removeEmptyPropsFromObject(filters) } : null));
    }

    function hasFilters() {
        return filters.searchQuery || filters.status || filters.game || filters.topic || filters.training;
    }

    useEffect(() => {
        let stateFilters;
        if (location?.state?.filters) {
            stateFilters = location?.state?.filters;
        }
        if (location?.search) {
            const query = new URLSearchParams(location.search);
            stateFilters = paramsToObject(query.entries());
        }
        if (stateFilters) {
            setFilters(stateFilters);
        }
    }, []);

    useEffect(() => {
        if (filterLastIncomplete) {
            let dateToday = new Date().toISOString().slice(0, 10);
            let dateTommorow = new Date(new Date().getTime() + 86400000);
            dispatchFilters({
                status: 'DRAFT',
                dateRange: [dateToday, dateTommorow.toISOString().slice(0, 10)],
            });
        }
    }, [filterLastIncomplete]);

    useEffect(() => {
        if (stateIsLoaded(editQuestionsBulk)) {
            dispatch(
                getQuestionsPaginatedAction({
                    ...filters,
                    page: filters.page,
                })
            );
        }
        if (!stateIsNotInitialized(editQuestionsBulk)) {
            setSavingModalOpened(true);
        }
    }, [editQuestionsBulk]);

    useEffect(() => {
        if (stateIsLoaded(gamesState) && constValuesState.gameNames.length === 0) {
            dispatchValues({
                gameNames: gamesState.data,
                gameValues: gamesState.data.map((el, i) => {
                    return { value: el.gameSlug, label: t(el.gameName) };
                }),
            });
        }
    }, [gamesState]);

    useEffect(() => {
        if (stateIsLoaded(allTopicsState)) {
            dispatchValues({
                topics: allTopicsState.data.map((el, i) => {
                    return { value: el.treeIndex, label: `${el.title} (${el.treeIndex})` };
                }),
            });
        }
    }, [allTopicsState]);

    useEffect(() => {
        if (!stateIsLoaded(allTrainingsState) && !stateIsLoading(allTrainingsState) && !stateHasFailed(allTrainingsState)) {
            dispatch(getAllTrainingsAction());
        } else if (stateIsLoaded(allTrainingsState)) {
            dispatchValues({
                trainings: allTrainingsState.data.learningSets.map((el, i) => {
                    return { value: el.learningSetInfo.setId, label: el.learningSetInfo.name };
                }),
            });
        }
    }, [allTrainingsState]);

    useEffect(() => {
        setStateLoaded(false);
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(
                    getQuestionsPaginatedAction({
                        ...filters,
                        page: filters.page,
                    })
                );
                let search = linkBuilderFromQuery('', filters);
                window.history.replaceState('page2', 'Title', '/content/questions' + search);
            },
            300,
            'getQuestionsPaginatedActionUseEffect'
        );
        return () => setTimeoutLastExecute.removeCallback('getQuestionsPaginatedActionUseEffect');
    }, [dispatch, filtersState]);

    useEffect(() => {
        dispatch(
            setEditingQuestionsListAction(
                filteredData.map(el => {
                    return {
                        questionId: el.questionId,
                        gameSlug: el.gameSlug,
                    };
                })
            )
        );
    }, [dispatch, filteredData]);

    useEffect(() => {
        if (stateIsLoaded(paginatedQuestionsState)) {
            setStateLoaded(true);
            isInitialLoad.current = false;
        } else {
            setStateLoaded(false);
        }
    }, [paginatedQuestionsState]);

    if (!stateLoaded && !hasFilters() && isInitialLoad.current) {
        return <LoadingAnimation />;
    }

    return (
        <div style={{ height: '90vh' }}>
            {addQuestionModalOpen && stateIsLoaded(gamesState) && (
                <AddQuestionModal open={addQuestionModalOpen && stateIsLoaded(gamesState)} setOpen={setAddQuestionModalOpen} />
            )}
            {exportQuestionsModalOpen && (
                <ExportQuestionsModal open={exportQuestionsModalOpen} setOpen={setExportQuestionsModalOpen}></ExportQuestionsModal>
            )}
            <DeleteBulkModal
                bulkQuestionSize={totalSelectedQuestions}
                modalOpen={modalOpened === questionActionsOptions(t)[0].value}
                setModalOpen={setModalOpened}
                onAction={() => {
                    dispatch(
                        editQuestionsBulkAction(
                            'delete',
                            selectedRowsArr,
                            allQuestionsSelected ? { filters: removeEmptyPropsFromObject(filters) } : null
                        )
                    );
                    resetBulkActions();
                }}
                onClose={() => {
                    resetBulkActions();
                    dispatch(resetPreBulkActionQuestionsAction());
                }}
            />
            <ChangeStatusBulkModal
                bulkQuestionSize={totalSelectedQuestions}
                modalOpen={modalOpened === questionActionsOptions(t)[1].value}
                setModalOpen={setModalOpened}
                onAction={({ currentActionOption, dateAvailableFrom }) => {
                    let data = {
                        questionStatus: currentActionOption.value,
                    };
                    if (dateAvailableFrom) {
                        data = { ...data, dateAvailableFrom: dateAvailableFrom.getTime() };
                    }
                    if (allQuestionsSelected) {
                        data = { ...data, filters: removeEmptyPropsFromObject(filters) };
                    }
                    dispatch(editQuestionsBulkAction('change_status', selectedRowsArr, data));
                    resetBulkActions();
                }}
                onClose={resetBulkActions}
            />

            <ChangeTopicBulkModal
                bulkQuestionSize={totalSelectedQuestions}
                modalOpen={modalOpened === questionActionsOptions(t)[2].value}
                setModalOpen={setModalOpened}
                onAction={statusOpted => {
                    let data = {
                        topicId: statusOpted.value,
                    };
                    if (allQuestionsSelected) {
                        data = { ...data, filters: removeEmptyPropsFromObject(filters) };
                    }
                    dispatch(editQuestionsBulkAction('change_topic', selectedRowsArr, data));
                    resetBulkActions();
                }}
                onClose={resetBulkActions}
            />

            <BulkEditSavingModal modalOpen={savingModalOpened} setModalOpen={setSavingModalOpened} />

            <div className="custom-data-table">
                <DataTable
                    sortIcon={selectedRowsArr.length === 0 ? <img height={15} width={13} className="ml-2" src={CaretTable} alt="sort"></img> : <></>}
                    columns={columns}
                    onSort={handleSort}
                    sortServer
                    data={filteredData}
                    defaultSortField="question"
                    subHeader={true}
                    noDataComponent={<TableEmptyState />}
                    overflowY={'visible'}
                    className="overflow-visible"
                    overflowYOffset={'0px'}
                    paginationPerPage={100}
                    onRowClicked={handleRowClick}
                    pagination={true}
                    selectableRows={false}
                    highlightOnHover={true}
                    subHeaderComponent={
                        <Subheader
                            inputs={questionTableSubheaders({
                                filtersState: filtersState,
                                activeFilters,
                                dispatchFilters,
                                updateFilterState,
                                constValuesState: constValuesState,
                                createQuestions,
                                exportQuestions,
                                resetBulkActions,
                            })}
                        />
                    }
                    subHeaderAlign={'left'}
                    customStyles={customStylesTable}
                    theme={'tableTheme'}
                    selectableRowSelected={row => row.selected}
                    noHeader={true}
                    paginationComponent={_ => (
                        <PaginationComponent
                            getPrev={getPrevious}
                            getNext={getNext}
                            getPage={getPage}
                            currentPage={filters.page}
                            totalPages={paginatedQuestionsState.data.totalPages}
                            totalItems={paginatedQuestionsState.data.totalElements}
                            size={filters.size}
                            setSize={size => {
                                dispatchFilters({ page: 0, size: size });
                            }}
                        />
                    )}
                />
            </div>
        </div>
    );
}
