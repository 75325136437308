import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { AnalyticsContainer } from 'components/features/analytics/AnalyticsContainer';
import { colors } from 'style/colors';
import { TrainingSelect } from './assigned_trainings/TrainingSelect';
import { CustomPieChart } from 'components/features/analytics/covered_material/CustomPieChart';
import { CoveredMaterial } from 'components/features/analytics/covered_material/CoveredMaterial';
import { KnowledgeOverTimeWrapper } from 'components/features/analytics/progress_over_time/ProgressOverTimeWrapper';
import { CategoryAccuracy } from 'components/features/analytics/training_accuracy/CategoryAccuracy';
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts';
import { Subtitle2 } from 'style/typography/Subtitle';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { singleTopicStatsAction } from 'redux/actions/content.actions';
import { Circular } from 'components/features/data_table/Circular';
import { analyticType, trainingAnalyticsDefault } from 'static/analytic_constants';
import { useTranslation } from 'react-i18next';
import UserStatisticsEmptyState from '../local_widgets/UserStatisticsEmptyState';

import '../UserAnalytics.css';
import { Body2 } from 'style/typography/Body';

export function TrainingAnalyticsWrapper({ trainings, userId, userName }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const singleTopicsStatsState = useSelector(state => state.content.singleTopicStats);

    const [selectedTraining, setSelectedTraining] = useState(trainings?.length > 0 ? trainings[0] : {});
    const [hovering, setHovering] = useState('');

    const [trainingAnalytics, dispatchTrainingAnalytics] = useReducer((state, action) => {
        return { ...state, ...action };
    }, trainingAnalyticsDefault);

    useEffect(() => {
        if (stateIsLoaded(singleTopicsStatsState)) {
            let chosenCategoryData = singleTopicsStatsState.data;
            if (chosenCategoryData !== undefined) {
                dispatchTrainingAnalytics({
                    pieData: [
                        {
                            name: 'Covered',
                            value: chosenCategoryData.coveredTrainingMaterial?.totalQuestionsCoveredPercent,
                        },
                        { name: 'Left', value: chosenCategoryData.coveredTrainingMaterial?.totalQuestionsLeftPercent },
                    ],
                    totalQuestionsCovered: chosenCategoryData.coveredTrainingMaterial?.totalQuestionsCovered,
                    totalQuestionsLeft: chosenCategoryData.coveredTrainingMaterial?.totalQuestionsLeft,
                    totalQuestions: chosenCategoryData.coveredTrainingMaterial?.totalQuestions,
                    progressOverTimeData: [chosenCategoryData.trainingProgressOverTime],
                    accuracyInCategories: chosenCategoryData.accuracyInCategories,
                    knowledgeMap: chosenCategoryData.accuracyInCategories.map((value, index) => {
                        return {
                            category: index + 1,
                            user: value.accuracy,
                            others: chosenCategoryData.averageAccuracyInCategoriesOtherUsers[index]?.accuracy,
                            fullMark: 100,
                        };
                    }),
                    progressOverTimeChildrenCategories: chosenCategoryData.knowledgeProgressOverTimeForCategoriesInTraining,
                });
            }
        }
    }, [singleTopicsStatsState]);

    useEffect(() => {
        if (selectedTraining && Object.keys(selectedTraining).length !== 0) {
            dispatch(singleTopicStatsAction(userId, selectedTraining.catId));
        }
    }, [dispatch, selectedTraining, userId]);

    return (
        <div className="col-10">
            <div className={'row justify-content-center'}>
                <div className="col-3 mr-4">
                    <div className="d-flex flex-column">
                        <div style={{ width: '100%' }} className={'border-right mt-5'}>
                            <Subtitle2 weight="medium" color={colors.gray6} className="pb-2">
                                {t('Assigned topics:')}
                            </Subtitle2>
                        </div>
                        {trainings?.map(t => {
                            return (
                                <TrainingSelect
                                    key={t.catId}
                                    active={t.catId === selectedTraining.catId}
                                    onClick={() => setSelectedTraining(t)}
                                    progress={t.accuracy}
                                    title={t.name}
                                ></TrainingSelect>
                            );
                        })}
                    </div>
                </div>
                {stateIsLoading(singleTopicsStatsState) && (
                    <div style={{ height: '2400px' }} className="col-8">
                        <div style={{ height: '100%' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                            <Circular />
                        </div>
                    </div>
                )}
                {stateIsLoaded(singleTopicsStatsState) && (
                    <div key={selectedTraining?.catId} className="col-8">
                        <div className="row justify-content-center">
                            <AnalyticsContainer
                                customColWidth={'col-12'}
                                title={t('Covered material')}
                                description={t('See how much of the content in this training the user has covered')}
                                infoString={t('Here you can see the total coverage of the material in the selected topic.')}
                            >
                                <div className="row mb-5 justify-content-center">
                                    <div className="col-12 d-flex flex-row text-center justify-content-center">
                                        <CustomPieChart
                                            style={{ display: 'flex', position: '' }}
                                            data={trainingAnalytics.pieData}
                                            height={170}
                                            width={400}
                                        ></CustomPieChart>
                                    </div>
                                    <div className="col-10">
                                        <CoveredMaterial
                                            totalQuestions={trainingAnalytics.totalQuestions}
                                            totalQuestionsLeft={trainingAnalytics.totalQuestionsLeft}
                                            totalQuestionsCovered={trainingAnalytics.totalQuestionsCovered}
                                        ></CoveredMaterial>
                                    </div>
                                </div>
                            </AnalyticsContainer>
                            <AnalyticsContainer
                                customColWidth={'col-12'}
                                title={t('Training progress over time')}
                                description={t('See how this user has progressed over a selected period of time in the assigned training.')}
                                infoString={t(
                                    'Training progress over time shows the course and development of the accuracy of the user when playing the selected topic from the training in a certain past period of time. The time period is selected via the slider below the graph, and can range from one week, one month, 3 months, 6 months, and one year.'
                                )}
                            >
                                <div className="row mb-5 justify-content-center">
                                    <div className="col-12">
                                        <KnowledgeOverTimeWrapper
                                            key={'KnowledgeOverTimeSpecificTraining'}
                                            data={trainingAnalytics.progressOverTimeData}
                                            switchable={false}
                                        ></KnowledgeOverTimeWrapper>
                                    </div>
                                </div>
                            </AnalyticsContainer>
                            <AnalyticsContainer
                                customColWidth={'col-12'}
                                title={t('Accuracy in categories')}
                                description={t(
                                    'See how well this user performs and compares to other players in the content categories included in the assigned training.'
                                )}
                                infoString={t(
                                    'Accuracy in categories shows the accuracy (in percentage) that the user achieved while playing the various sub-topics in the main selected topic from the training, and compares it with other users who played.'
                                )}
                            >
                                {!(trainingAnalytics.accuracyInCategories?.length > 0) && <UserStatisticsEmptyState type={analyticType.PROGRESS} />}
                                {trainingAnalytics.accuracyInCategories?.length > 0 && (
                                    <div className="row mb-5 justify-content-center">
                                        <div style={{ borderColor: colors.underlineColor }} className="col-10 pr-2 pl-2">
                                            <div className={'row justify-content-center'}>
                                                {trainingAnalytics.accuracyInCategories?.map((value, index) => {
                                                    return (
                                                        <div className="col-10 mt-3">
                                                            <CategoryAccuracy
                                                                accuracy={value.accuracy.toFixed()}
                                                                categoryName={value.category.name}
                                                                experience={value.experienceLabel}
                                                                index={index + 1}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex flex-column justify-content-center text-center align-items-center mb-2 mt-5">
                                            <Body2 color={colors.gray6} className={'mb-2'}>
                                                {t('Compared to other players')}
                                            </Body2>
                                            <RadarChart
                                                style={{ position: '' }}
                                                outerRadius={90}
                                                width={320}
                                                height={240}
                                                data={trainingAnalytics.knowledgeMap}
                                            >
                                                <PolarGrid stroke={colors.mainTheme} gridType={'circle'} />
                                                <PolarAngleAxis dataKey={'category'} />
                                                <PolarRadiusAxis
                                                    tickCount={trainingAnalytics.knowledgeMap.length}
                                                    type={'number'}
                                                    domain={[0, 100]}
                                                />
                                                <Radar
                                                    name={t('Others')}
                                                    dataKey="others"
                                                    stroke={colors.gray1}
                                                    fill={colors.gray1}
                                                    fillOpacity={0.6}
                                                />
                                                <Radar
                                                    name={userName}
                                                    dataKey="user"
                                                    stroke={colors.mainTheme}
                                                    fill={colors.mainTheme}
                                                    fillOpacity={0.5}
                                                />
                                                <Legend
                                                    style={{ width: '100%' }}
                                                    wrapperStyle={{ position: '', width: '100%' }}
                                                    iconType={'circle'}
                                                />
                                            </RadarChart>
                                        </div>
                                    </div>
                                )}
                            </AnalyticsContainer>
                            <AnalyticsContainer
                                customColWidth={'col-12'}
                                title={t('Category progress over time')}
                                description={t(
                                    'See how this user has progressed over a selected period of time in each content category included in this training.'
                                )}
                                infoString={t(
                                    'Category progress over time shows the course and development of the accuracy of the user when playing each sub-topic of the main selected topic of the training, in a certain past period of time. To see the data on the graph for each separate subtopic, you need to press the left or right arrow of the window and the graph will update accordingly.'
                                )}
                            >
                                {!(trainingAnalytics.progressOverTimeChildrenCategories?.length > 0) && (
                                    <UserStatisticsEmptyState type={analyticType.OVER_TIME} />
                                )}
                                {trainingAnalytics.progressOverTimeChildrenCategories?.length > 0 && (
                                    <div className="row mb-5 justify-content-center">
                                        <div className="col-12">
                                            {trainingAnalytics.progressOverTimeChildrenCategories.length > 0 && (
                                                <KnowledgeOverTimeWrapper
                                                    key={`KnowledgeOverTimeChildrenCategories-${new Date()}`}
                                                    data={trainingAnalytics.progressOverTimeChildrenCategories}
                                                    switchable={true}
                                                ></KnowledgeOverTimeWrapper>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </AnalyticsContainer>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

TrainingAnalyticsWrapper.propTypes = {
    trainings: PropTypes.any,
    userId: PropTypes.any,
    userName: PropTypes.any,
};
