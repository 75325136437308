import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import EyeOpenIcon from 'assets/icons/gallery-icon-preview.svg';
import CheckmarkIcon from 'assets/icons/checkmark.svg';

import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { PreviewImageDialog } from './PreviewImageDialog';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { aspectRatioNoImageMap } from 'static/image_constans';
import _ from 'lodash';

import './CustomGalleryGrid.css';
export default function CustomGalleryGrid({ images, onSelect }) {
    const [currentImageId, setCurrentImageId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const [errorImages, setErrorImages] = useState([]);

    const [isHovering, setIsHovering] = useState(null);

    const { aspectRatio, allImagesSelected } = useImageEditor();

    const imageSizes = useRef({});
    return (
        <div className="custom-gallery-grid--container">
            {images.map(el => {
                if (errorImages.includes(el.fullObj.id) || !el.thumbnail) {
                    return (
                        <div
                            onClick={e => {
                                onSelect(el);
                                e.stopPropagation();
                            }}
                        >
                            <div
                                key={el.fullObj.id}
                                className={`custom-gallery-grid--image-wrap ${el.isSelected || allImagesSelected ? 'selected' : ''}`}
                            >
                                {(el.isSelected || allImagesSelected) && (
                                    <div className={`custom-gallery-grid--selected`}>
                                        {<img alt="Checkmark" height={18} width={18} src={CheckmarkIcon}></img>}
                                    </div>
                                )}
                                <img alt="Blank" height={130} src={aspectRatioNoImageMap[aspectRatio]}></img>
                            </div>
                            <Caption1 color={colors.secoundaryText}>{el.caption}</Caption1>
                        </div>
                    );
                }

                let elTuhumb = _.cloneDeep(el.thumbnail);
                if (!(elTuhumb in imageSizes.current)) {
                    imageSizes.current[elTuhumb] = 0;

                    fetch(elTuhumb)
                        .then(response => response.blob())
                        .then(blob => {
                            imageSizes.current[elTuhumb] = Math.round(blob.size / 1000);
                        })
                        .catch(e => {
                            console.log('error img', elTuhumb, e);
                        });
                }
                return (
                    <div
                        onMouseOver={() => setIsHovering(el.fullObj.id)}
                        onMouseLeave={() => setIsHovering(null)}
                        onClick={e => {
                            onSelect(el);
                            e.stopPropagation();
                        }}
                    >
                        <div
                            key={el.fullObj.id}
                            className={`custom-gallery-grid--image-wrap ${el.isSelected || allImagesSelected ? 'selected' : ''}`}
                        >
                            {isHovering === el.fullObj.id && (
                                <div
                                    className={`custom-gallery-grid--preview`}
                                    onClick={e => {
                                        setModalOpen(true);
                                        setCurrentImageId(el.fullObj.id);
                                        e.stopPropagation();
                                    }}
                                >
                                    {<img alt="Checkmark" height={16} width={16} src={EyeOpenIcon}></img>}
                                </div>
                            )}
                            {(el.isSelected || allImagesSelected) && (
                                <div className={`custom-gallery-grid--selected`}>
                                    {<img alt="Checkmark" height={18} width={18} src={CheckmarkIcon}></img>}
                                </div>
                            )}
                            <img
                                style={{ zIndex: -5 }}
                                alt="Thumbnail"
                                height={130}
                                src={el.thumbnail}
                                onError={() => {
                                    setErrorImages(prevState => [...prevState, el.fullObj.id]);
                                }}
                            ></img>
                        </div>
                        <Caption1 color={colors.secoundaryText}>{el.caption}</Caption1>
                    </div>
                );
            })}
            {modalOpen && (
                <PreviewImageDialog currentImageId={currentImageId} filteredImages={images} modalOpen={modalOpen} setModalOpen={setModalOpen} />
            )}
        </div>
    );
}

CustomGalleryGrid.propTypes = {
    images: PropTypes.array,
    onSelect: PropTypes.func,
};
