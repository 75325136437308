import RequestApproved from 'assets/icons/icon-request-approved.svg';
import RequestRejected from 'assets/icons/icon-request-rejected.svg';
import TrashIcon from 'assets/icons/icon-delete.svg';

export const modifyDevicesModalContent = {
    approve: {
        icon: null,
        title: 'Approve this request?',
        description:
            "If you approve the request for changing the user's device ID, it means that the user will be able to login to Coach only with that approved device ID, as there can't be more than one approved device IDs.",
        button: 'Approve request',
    },
    reject: {
        icon: null,
        title: 'Reject this request?',
        description:
            "If you reject the request, the user's current device ID will not change, meaning the user will be able to login to Coach only using the current device.",
        button: 'Reject request',
    },
    'approve-success': {
        icon: RequestApproved,
        title: 'Request approved!',
        description: "The user's current device ID has been changed.",
        button: 'Continue',
    },
    'reject-success': {
        icon: RequestRejected,
        title: 'Request rejected.',
        description: "The user's current device ID was not changed.",
        button: 'Continue',
    },
    delete: {
        icon: TrashIcon,
        title: 'Delete request?',
        description: 'This request will be permanently deleted.',
        button: 'Delete',
    },
    'delete-success': {
        icon: TrashIcon,
        title: 'Successfuly deleted',
        button: 'Continue',
    },
};
