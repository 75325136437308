import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import WarningIcon from 'assets/icons/warning-icon.png';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { useTranslation } from 'react-i18next';

export function RemoveRoleDialog({ modalOpen, setModalOpen, onAction }) {
    const { t } = useTranslation();

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                onAction();
            }}
            title={t('Remove role from user')}
            text={t(
                'If you choose to remove the role from the user, the user will lose access to the assigned training and the Coach app will be unusable.'
            )}
            buttons={[
                {
                    title: t('Remove anyway'),
                    color: colors.white,
                },
            ]}
            hasCancel={true}
            image={WarningIcon}
        ></ModalDialog>
    );
}

RemoveRoleDialog.propTypes = {
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    setModalOpen: PropTypes.func,
};
