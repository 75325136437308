import React, { useState } from 'react';

export const useStateWithErrors = (state, validation, errorMsg) => {
    // const [value, setValue] = useState(initialValue);
    const [error, setError] = useState(false);

    const checkValidity = () => {
        if (validation(state[0])) {
            hideError();

            return true;
        } else {
            setError(errorMsg);

            return false;
        }
    };

    const showError = msg => {
        setError(msg);
    };
    const hideError = () => {
        setError(false);
    };

    return [state[0], state[1], [checkValidity, error, showError, hideError]];
};

export const validateInputs = inputs => {
    let flag = true;
    inputs.forEach(element => {
        let [checkValidity, error, showError, hideError] = element;
        let check = checkValidity();
        if (!check) {
            flag = false;
        }
    });
    return flag;
};
