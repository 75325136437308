export const defaultNode = {
    path: [],
    children: [],
    left: -1,
    right: -1,
};

export const defaultRootNode = {
    title: '',
    titleForTraining: '',
    isNew: true,
    main: false,
    visible: false,
    path: [],
    treeIndex: null,
    left: -1,
    right: -1,
    children: [],
    parentNode: { treeIndex: 0 },
    topic: true,
    createdBy: '',
    dateCreated: Date.now(),
    timeCreated: Date.now(),
    generatedTreeIndex: '',
};

export const defaultLearningSetInfo = {
    name: '',
    isNew: true,
    generatedSetId: '',
    roles: [],
    dateAvailableFrom: null,
    dateAvailableTo: null,
    createdBy: '',
    questions: 0,
    topics: 0,
    totalCategories: 0,
    totalCategoriesUsedInSet: 0,
};

export const defaultRoleNode = {
    roleName: '',
    roleId: '',
    connectedSet: null,
    connectedSetName: null,
    connectedUnits: [],
    connectedUnitsIds: [],
    newRole: true,
    nameSet: false,
    createdBy: '',
    dateCreated: '',
    timeCreated: '',
    dateModified: '',
    timeModified: '',
};
