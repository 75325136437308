import React from 'react';
import DatePicker from 'react-date-picker';
import PropTypes from 'prop-types';

import Calendar from 'assets/icons/filter-date.svg';
import Close from 'assets/icons/filter-close.svg';

import './CustomDatePicker.css';
// trigger
function CustomDatePicker({ style, value, onChange, border = true, minDate, maxDate, className }) {
    return (
        <DatePicker
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            style={style}
            className={(border ? 'border_calendar ' : ' ') + className}
            value={!isNaN(value) && value ? new Date(value) : value}
            showLeadingZeros={true}
            onChange={onChange}
            calendarIcon={<img alt="Calendar" src={Calendar} height={20} width={20} />}
            clearIcon={<img alt="Calendar" src={Close} height={20} width={20} />}
            minDate={minDate}
            maxDate={maxDate}
        ></DatePicker>
    );
}

CustomDatePicker.propTypes = {
    style: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    border: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
};

export { CustomDatePicker };
