import React from 'react';

import { colors } from 'style/colors';
import { Body1, Body2 } from 'style/typography/Body';
import { useSelector } from 'react-redux';
import { Caption1 } from 'style/typography/Caption';
import { H2 } from 'style/typography/Heading';
import QuestionDashboard from 'assets/icons/dashboard-questions.svg';
import ProficiencyDashboard from 'assets/icons/dashboard-proficiency.svg';
import Warning from 'assets/icons/dashboard-warning.svg';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

import QuestionsIconEmpty from 'assets/icons/dashboard-questions-empty.svg';

import './DashStatistics.css';

export default function QuestionsStatisticsDashboard() {
    const history = useHistory();
    const { t } = useTranslation();

    const dashboardStatsState = useSelector(state => state.content.dashboardStats);

    if (!stateIsLoaded(dashboardStatsState)) {
        return (
            <div className="dash-s--empty-container">
                <img alt="empty-icon" src={QuestionsIconEmpty} height={60} width={60} />
                <Body1 color={colors.gray6}>{t('Once your users start playing in the app, detailed game stats will appear here.')}</Body1>
            </div>
        );
    }

    return (
        <div>
            <div className="dash-s--questions-container">
                <div className="dash-s--questions-statistic-container">
                    <img alt="questions" src={QuestionDashboard} height={70} width={70} />
                    <div className="dash-s--questions-stat">
                        <H2 color={colors.mainTheme} weight="bold">
                            {dashboardStatsState.data?.totalQuestions?.toString()}
                        </H2>
                        <Body2 color={colors.primaryText}>{t('total questions')}</Body2>
                    </div>
                    <div className="dash-s--questions-type-container">
                        <div
                            className="dash-s-questions-total-type"
                            onClick={() => {
                                history.push('/content/questions?status=ACTIVE');
                            }}
                        >
                            <div className="dot-orange"></div>
                            <Body2 color={colors.gray6}>{t('active:')}</Body2>
                            <Body2 color={colors.gray6} weight="bold">
                                {dashboardStatsState.data?.activeQuestions?.toString()}
                            </Body2>
                        </div>
                        <div
                            className="dash-s-questions-total-type"
                            onClick={() => {
                                history.push('/content/questions?status=DRAFT');
                            }}
                        >
                            <div className="dot-gray"></div>
                            <Body2 color={colors.gray6}>{t('draft:')}</Body2>
                            <Body2 color={colors.gray6} weight="bold">
                                {dashboardStatsState.data?.draftQuestions?.toString()}
                            </Body2>
                        </div>
                    </div>
                </div>
                <div className="dash-s--questions-statistic-container">
                    <img alt="proficiency-icon" src={ProficiencyDashboard} height={70} width={70} />
                    <div className="dash-s--questions-stat">
                        <H2 color={colors.mainTheme} weight="bold">
                            {dashboardStatsState.data?.companyLevelProficiency
                                ? (Math.round(+dashboardStatsState.data.companyLevelProficiency * 10) / 10).toString()
                                : '0'}
                        </H2>
                        <Body2 color={colors.primaryText}>{t('company level proficiency')}</Body2>
                    </div>
                </div>
            </div>
            <div className="divider"></div>
            <div className="dash-s--questions-fails-items">
                <div className="dash-s--questions-fails-header">
                    <Caption1 color={colors.gray1}>{t('question')}</Caption1>
                    <Caption1 color={colors.gray1}>{t('fails')}</Caption1>
                </div>
                {dashboardStatsState.data?.questionAndFailRates?.map(question => {
                    return (
                        <div
                            className="dash-s--questions-fails-item"
                            onClick={() => {
                                history.push('/content/questions/update/' + question.questionId);
                            }}
                        >
                            <div className="dash-s--questions-fails-text">
                                <img alt="warning" src={Warning} height={15} width={15} />
                                <Caption1 style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} color={colors.gray6}>
                                    {question.question}
                                </Caption1>
                            </div>
                            <Body2 style={{ marginLeft: 'auto' }} color={colors.primaryText} weight="bold">
                                {question.failRate + '%'}
                            </Body2>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
