import React from 'react';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import WarningIcon from 'assets/icons/icon-role-delete.svg';

import { useTranslation } from 'react-i18next';

export function DeleteRoleDialog({ modalOpen, setModalOpen, onAction }) {
    const { t } = useTranslation();

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                setModalOpen(false);
                onAction();
            }}
            title={t('Delete role')}
            text={t('If you choose to delete this role, all the users connected will lose access to the assigned training.')}
            buttons={[
                {
                    title: t('Delete anyway'),
                    color: colors.white,
                },
            ]}
            hasCancel={true}
            image={WarningIcon}
        ></ModalDialog>
    );
}
