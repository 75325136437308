import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from 'style/colors';
import { resetSingleQuestionAction } from 'redux/actions/content.actions';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { Body2 } from 'style/typography/Body';
import { linkBuilderFromQuery } from 'services/api/api_builders';

import AngleLeft from 'assets/icons/angle-left.svg';
import AngleRight from 'assets/icons/angle-right.svg';
import InputFieldPagination from 'components/features/data_table/pagination_component/InputFieldPagination';
import { floor } from 'lodash';
import { useTranslation } from 'react-i18next';
import { splitOnNthOccurence } from 'services/utils/stringHelpers';

export function InputContentWrapper({ children, id, filters }) {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const urlPath = window.location.pathname;

    const questionIndexesState = useSelector(state => state.content.questionIndexes);
    const [questionIndex, setQuestionIndex] = useState(0);

    const getQuestion = indx => {
        const data = questionIndexesState.data;

        history.replace({
            pathname: `/content/questions/update/${data.questionIds[indx]}`,
            state: {
                from: location.pathname,
                filters: filters,
            },
        });
    };

    const nextQuestion = () => {
        // const data = paginatedQuestionsState.data;
        const data = questionIndexesState.data;
        console.log('data.questionIds.length', data.questionIds.length);
        if (questionIndex + 1 < data.questionIds.length) {
            history.replace({
                pathname: `/content/questions/update/${data.questionIds[questionIndex + 1]}`,
                state: {
                    from: location.pathname,
                    filters: filters,
                },
            });
        }
        // } else {
        //     if (currentPage + 1 < totalPages) {
        //         getQuestionIndex.current = 0;
        //         dispatch(resetGetQuestionsPaginatedAction());
        //         dispatch(
        //             getQuestionsPaginatedAction({
        //                 ...filters,
        //                 page: currentPage + 1,
        //             })
        //         );
        //     }
        // }
    };

    const previousQuestion = () => {
        // const data = paginatedQuestionsState.data;

        if (questionIndex - 1 >= 0) {
            const data = questionIndexesState.data;

            history.replace({
                pathname: `/content/questions/update/${data.questionIds[questionIndex - 1]}`,
                state: {
                    from: location.pathname,
                    filters: filters,
                },
            });
        }
    };

    const goBackFunction = () => {
        console.log('goBackFunction');
        dispatch(resetSingleQuestionAction());
        // dispatch(resetQuestionIndexesAction());
        let search = linkBuilderFromQuery('', filters);
        history.push({
            pathname: '/content/questions',
            search: search ? search : '',
        });
    };

    useEffect(() => {
        window.addEventListener('popstate', goBackFunction);
        return () => window.removeEventListener('popstate', goBackFunction);
    });

    useEffect(() => {
        if (stateIsLoaded(questionIndexesState) && id) {
            let questionIndex = questionIndexesState.data.questionIds.findIndex(el => {
                console.log(el, 'is this', id);
                return el + '' === id;
            });
            console.log('this is id', id, 'this is index', questionIndex);
            setQuestionIndex(questionIndex);
        }
    }, [questionIndexesState]);

    console.log(questionIndexesState);
    if (stateIsLoading(questionIndexesState)) {
        return <div>Loading...</div>;
    }

    // const prevLocation = history.location?.state?.from;
    const totalQuestions = questionIndexesState?.data?.questionIds?.length;
    return (
        <div className={'w-100 d-flex flex-column py-2 px-4'}>
            <div className="row mt-2 ml-4">
                <div className=" d-flex flex-row w-100" style={{ justifyContent: 'space-between' }}>
                    <div role="button" id="back-to-question-list" onClick={goBackFunction} className="d-flex flex-row align-items-center w-50 ml-2">
                        <FontAwesomeIcon color={colors.gray6} className={'mr-2'} icon={faArrowLeft} />
                        <Body2 weight="medium" color={colors.gray6} className={'mb-0 '}>
                            {t('Back to questions list')}
                        </Body2>
                    </div>
                    {stateIsLoaded(questionIndexesState) && totalQuestions > 0 && (
                        <div className={'ml-auto d-flex flex-row align-items-center mr-4'}>
                            <img className="mr-3 cursor-pointer user-select-none" src={AngleLeft} height={17} width={17} onClick={previousQuestion} />
                            <InputFieldPagination currentPage={questionIndex} totalPages={totalQuestions} getQuestionsPage={getQuestion} />
                            <p className={'mb-0'}>
                                {t('of')} {totalQuestions}
                            </p>

                            <img className="ml-3 cursor-pointer user-select-none" src={AngleRight} height={17} width={17} onClick={nextQuestion} />
                        </div>
                    )}
                </div>
                {children}
            </div>
        </div>
    );
}
