import React, { useEffect, useState } from 'react';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import Select from 'react-select';
import { ExternalLesson } from './external_lesson/ExternalLesson';
import { InternalLesson } from './internal_lesson/InternalLesson';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';

import Dropdown from 'assets/icons/filter-caret.svg';
import Close from 'assets/icons/filter-close.svg';
import { changeLessonContentAction, updateLocalTopicChanges } from 'redux/actions/topics.actions';
import { lessonTypes } from 'static/lesson_type';
import { GenerateContent } from './GenerateContent';
import { useTranslation } from 'react-i18next';

export function Content({ selectedNode, setSelectedNode }) {
    const topicContentState = useSelector(state => state.content.topics.topicContent);
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);
    const [currentTopicData, setCurrentTopicData] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const DropdownIndicator = props => {
        return <img alt="Show" className="mr-2 cursor-pointer" src={Dropdown} height={17} width={17} />;
    };

    const ClearIndicator = props => {
        return <img alt="Close" className="mr-2 cursor-pointer" src={Close} height={17} width={17} onClick={props.clearValue} />;
    };

    function changeLessonType(type) {
        dispatch(updateLocalTopicChanges(currentTopicData, { lessonType: type, lessonContent: '' }, []));
    }

    function changeLessonContent(lessonContent) {
        dispatch(changeLessonContentAction(currentTopicData, lessonContent));
    }

    useEffect(() => {
        let data = {};
        if (stateIsLoaded(topicContentState) && topicContentState.data?.treeIndex == selectedNode.treeIndex) {
            data = topicContentState.data;
        }
        if (stateIsLoaded(localTopicChangesState) && localTopicChangesState.data[selectedNode.treeIndex]) {
            data = { ...data, ...localTopicChangesState.data[selectedNode.treeIndex].treeData };
        }
        setCurrentTopicData(data);
    }, [topicContentState, localTopicChangesState]);

    return (
        <div className={'d-flex flex-column mt-2'} style={{ width: '90%' }}>
            {/* <div className={'d-flex flex-column'}>
                <Caption1 weight="medium" color={colors.gray6} className={'mr-2 mb-1'}>
                    {t('TITLE')}
                </Caption1>
                <CustomOutlinedTextField
                    id="input-title-for-learners"
                    width={'100%'}
                    value={currentTopicData?.titleForTraining || ''}
                    setValue={value => dispatch(updateLocalTopicChanges(currentTopicData, { titleForTraining: value }, []))}
                    placeholder={t('Enter title...')}
                ></CustomOutlinedTextField>
            </div> */}
            <div className={'d-flex flex-row align-items-center'}>
                <Caption1 weight="medium" color={colors.gray6} className={'mr-2'}>
                    {t('TYPE')}
                </Caption1>
                <Select
                    id="dropdown-button-for-lessons"
                    className={'w-25'}
                    value={currentTopicData?.lessonType == '' ? lessonTypes[1] : lessonTypes.filter(t => t.value === currentTopicData?.lessonType)}
                    onChange={option => changeLessonType(option ? option.value : '')}
                    options={lessonTypes}
                    components={{
                        DropdownIndicator,
                        ClearIndicator,
                    }}
                    styles={{
                        control: styles => ({
                            ...styles,
                            border: `1px solid ${colors.gray5}`,
                            height: '40px',
                            boxShadow: 'none',
                            '&:hover': {
                                border: `1px solid ${colors.mainTheme}`,
                            },
                        }),
                        option: (styles, { isFocused }) => {
                            return {
                                ...styles,
                                '&:active': {
                                    backgroundColor: colors.mainTheme,
                                },
                                color: colors.dark,
                                backgroundColor: isFocused ? colors.gray3 : 'white',
                            };
                        },
                        indicatorSeparator: (provided, state) => ({}),
                    }}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            neutral50: colors.disabled,
                        },
                    })}
                />
                {currentTopicData?.lessonType === 'internal' && <GenerateContent selectedNode={selectedNode}></GenerateContent>}
            </div>

            {(currentTopicData?.lessonType == '' || currentTopicData?.lessonType === 'external') && (
                <ExternalLesson
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                    lessonContent={currentTopicData?.lessonContent}
                    setLessonContent={changeLessonContent}
                />
            )}
            {currentTopicData?.lessonType === 'internal' && (
                <InternalLesson
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                    lessonContent={currentTopicData?.lessonContent}
                    setLessonContent={changeLessonContent}
                />
            )}
        </div>
    );
}
