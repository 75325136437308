
export const trainingRolePickerSubheaders = ({
    filtersState,
    dispatchFilters,
    resetBulkActions,
    allTrainings,
}) => [
    {
        id: 'users-search-input',
        type: 'value',
        value: filtersState.searchQuery,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, searchQuery: val });
        },
        placeholder: 'Search for role...',
        active: true,
    },
    {
        type: 'dropdown',
        value: filtersState.trainingId,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, trainingId: val });
        },
        placeholder: 'Training',
        clearOnBackspace: true,
        dropdownValues: allTrainings,
        active: true,
    },
];
