import React from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { colors } from 'style/colors';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function AddQuestionByGameChooser() {
    const gamesState = useSelector(state => state.content.games);

    const history = useHistory();
    const { t } = useTranslation();
    return (
        <div style={{ height: '100%' }} className={'d-flex flex-column justify-content-center align-items-center pt-5'}>
            <div className={'px-3 mt-5'} style={{ height: '50%', maxWidth: '60vw' }}>
                <ScrollMenu>
                    {gamesState.data?.map(g => {
                        return <Card title={t(g.gameName)} img={g.gameIcon} onClick={() => history.push(`/content/questions/add/${g.gameSlug}`)} />;
                    })}
                </ScrollMenu>
            </div>
            <div className={'mt-auto'}>
                <button style={{ color: colors.mainTheme }} className={'btn btn-dark px-5'}>
                    {t('Next')}
                </button>
            </div>
        </div>
    );
}

export function Card({ title, img, onClick }) {
    return (
        <div
            id={'add-question-' + title.replace(/\s+/g, '-').toLowerCase()}
            role="button"
            onClick={onClick}
            style={{ height: 180, width: 150, backgroundColor: colors.white_transparent }}
            className={'d-flex flex-column shadow-sm align-items-center justify-content-center mr-3 mb-5'}
        >
            <img style={{ width: 50, height: 50 }} src={img}></img>
            <h6 className={'mt-4'}>{title}</h6>
        </div>
    );
}
