import { questionTypes } from 'services/content-management/insertion-control/local-constants';

import RaceCarsIcon from 'assets/icons/game-icon-race-cars.svg';
import TrafficIcon from 'assets/icons/game-icon-traffic.svg';
import SortItIcon from 'assets/icons/game-icon-sort-it.svg';
import CompareIcon from 'assets/icons/game-icon-compare.svg';
import NumbersIcon from 'assets/icons/game-icon-numbers.svg';
import RecallIcon from 'assets/icons/game-icon-recall.svg';
import SequenceIcon from 'assets/icons/game-icon-sequence.svg';
import PinpointIcon from 'assets/icons/game-icon-pinpoint.svg';

export const gameQuestionTypeMapper = {
    traffic: questionTypes.CHECKBOXES,
    'sort-it': questionTypes.IMAGE_CATEGORIES,
    compare: questionTypes.COMPARE_VISUAL,
    numbers: questionTypes.NUMERICAL,
    recall: questionTypes.ANSWER_TEXT,
    'race-cars': questionTypes.CHECKBOXES,
    sequence: questionTypes.POSITIONED,
    pinpoint: questionTypes.CHECKBOXES_WITH_IMAGE,
};

export const gameToGameIconMapper = {
    traffic: TrafficIcon,
    'sort-it': SortItIcon,
    compare: CompareIcon,
    numbers: NumbersIcon,
    recall: RecallIcon,
    'race-cars': RaceCarsIcon,
    sequence: SequenceIcon,
    pinpoint: PinpointIcon,
};

export const compareOptions = [
    {
        label: 'Bigger',
        value: 'bigger',
    },
    {
        label: 'Smaller',
        value: 'smaller',
    },
    {
        label: 'Boolean',
        value: 'boolean',
    },
];

export const numbersStyles = [
    { bottom: '50px' },
    { bottom: '50px', transform: 'translateX(-60px)' },
    { bottom: '50px', transform: 'translateX(60px)' },
    { bottom: '100px', transform: 'translateX(-30px)' },
    { bottom: '100px', transform: 'translateX(30px)' },
    { bottom: '0px', transform: 'translateX(-30px)' },
    { bottom: '0px', transform: 'translateX(30px)' },
];

export const gameIconsMap = {
    'race-cars': RaceCarsIcon,
    traffic: TrafficIcon,
    numbers: NumbersIcon,
};
