import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { H6 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';

import EmptyStateStructure from 'assets/icons/emptystate-notification.svg';
import { DefaultButton } from 'components/elements/Button';
import { useTranslation } from 'react-i18next';

export function NotificationsEmptyState({ onClick }) {
    const { t } = useTranslation();

    return (
        <div style={{ height: '100%' }} className={'d-flex flex-row align-items-center justify-content-center'}>
            <div className="d-flex flex-column align-items-center text-center">
                <img style={{ width: 100, height: 100 }} src={EmptyStateStructure} alt="" />
                <H6 color={colors.secoundaryText} className={'mt-4'}>
                    {t('Create your first notification')}
                </H6>
                <Body2 className={'mb-0 mt-1'} style={{ maxWidth: '30vw' }} color={colors.gray6}>
                    {t(
                        'Craft customized notifications for a selected group of users by your choice, in order to bring more engagement within the app, give announcements or nudge them with training reminders.'
                    )}
                </Body2>
                <DefaultButton
                    id="company-add-notification"
                    onClick={onClick}
                    type={'button'}
                    className="btn btn-dark d-flex flex-row align-items-center mt-4"
                    style={{ color: colors.mainTheme, width: 160 }}
                    text={t('Create notification')}
                ></DefaultButton>
            </div>
        </div>
    );
}

NotificationsEmptyState.propTypes = {
    onClick: PropTypes.func,
};
