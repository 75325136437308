const PERSIST_KEY = 'persist:coach';

const authService = {
    _user: null,

    get user() {
        const persist = localStorage.getItem(PERSIST_KEY);
        try {
            const data = JSON.parse(persist);
            const auth = JSON.parse(data.auth);
            return parseJwt(auth.data.access_token);
        } catch (error) {
            return null;
        }
    },

    get auth() {
        const persist = localStorage.getItem(PERSIST_KEY);
        try {
            const data = JSON.parse(persist);
            const auth = JSON.parse(data.auth);

            return auth.data;
        } catch (error) {
            return null;
        }
    },

    get token() {
        const persist = localStorage.getItem(PERSIST_KEY);
        try {
            const data = JSON.parse(persist);
            const auth = JSON.parse(data.auth);

            return auth.data.access_token;
        } catch (error) {
            return null;
        }
    },

    get providerId() {
        const persist = localStorage.getItem(PERSIST_KEY);
        try {
            const data = JSON.parse(persist);
            const auth = JSON.parse(data.auth);
            return auth.data.providerId;
        } catch (error) {
            return null;
        }
    },

    get authDomain() {
        const baseDomain = process.env.REACT_APP_BASE_DOMAIN;
        const tenant = window.location.hostname.replace(baseDomain, '');
        // reserve www
        if (!tenant || tenant === 'www') {
            return '';
        }
        // remove ending '.' from subdomain
        return tenant.slice(0, -1);
    },

    getLoginRedirectUrl(domain) {
        const { port, protocol } = window.location;
        const host = `${protocol}//${domain}.${process.env.REACT_APP_BASE_DOMAIN}${port && `:${port}`}`;
        return `${host}/login`;
    },

    getSignUpUrl() {
        const { port, protocol } = window.location;
        const host = `${protocol}//${process.env.REACT_APP_BASE_DOMAIN}${port && `:${port}`}`;
        return `${host}/sign-up`;
    },
    parseJwt(token) {
        let tokenParts = token.split('.');
        if (tokenParts.length > 1) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
            return JSON.parse(jsonPayload);
        }

        return false;
    },
};

function parseJwt(token) {
    let tokenParts = token.split('.');
    if (tokenParts.length > 1) {
        var base64Url = tokenParts[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        return JSON.parse(jsonPayload);
    }

    return '';
}

export { authService };
