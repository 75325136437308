import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from 'containers/layout/module/local_components/header/Header';
import { News } from '../internal_messaging/news/News';
import { Notifications } from '../internal_messaging/notifications/Notifications';
import { useTranslation } from 'react-i18next';
import { Feedback } from './feedback/Feedback';

const InternalMessaging = ({ history }) => {
    const { type } = useParams();
    const [activeTab, setActiveTab] = useState(type);

    const { t } = useTranslation();

    useEffect(() => {
        setActiveTab(type);
    }, [type]);

    function changeTab(tab) {
        history.replace(`/internal/${tab}`);
    }

    const typesTabs = [
        {
            id: 'internal-content',
            type: 'content-feedback',
            name: t('Content feedback'),
        },
        {
            id: 'internal-content',
            type: 'positive-feedback',
            name: t('Positive feedback'),
        },
        {
            id: 'internal-news',
            type: 'news',
            name: t('News'),
        },
        {
            id: 'internal-notifications',
            type: 'notifications',
            name: t('Notifications'),
        },
    ];

    return (
        <Header tabs={typesTabs} activeTab={activeTab} setActiveTab={changeTab} title={'Content'}>
            {activeTab === 'content-feedback' && <Feedback />}
            {activeTab === 'positive-feedback' && <Feedback />}
            {activeTab === 'news' && <News />}
            {activeTab === 'notifications' && <Notifications />}
        </Header>
    );
};

export default InternalMessaging;
