import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import Checkbox from 'components/elements/checkbox/Checkbox';
import CustomCheckboxForTable from 'components/elements/checkbox/CustomCheckboxForTable';
import { UserCircle } from 'components/elements/UserCircle';
import PaginationComponent from 'components/features/data_table/pagination_component/PaginationComponent';
import { TableCellContent, TableHeader } from 'components/features/data_table/TableElements';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersPaginatedAction } from 'redux/actions/users.actions';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { useTableSelections } from 'services/custom-hooks/useTableSelections';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import CaretTable from 'assets/icons/caret-table.svg';
import { Subheader } from 'components/features/data_table/subheader/Subheader';
import { usePagination } from 'components/features/data_table/pagination_component/usePagination';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';
import { H6 } from 'style/typography/Heading';
import Close from 'assets/icons/filter-close.svg';
import { customStylesTable } from 'style/elements/table';
import { DefaultButton } from 'components/elements/Button';
import { usersFiltersInitialState } from 'static/filters';

import { usersTableSubheaders } from '../../users/organization_users/table_components/UsersTableSubheaders';
import TagPill from '../../users/organization_users/local_components/TagPill';
import { SkeletonLoader } from 'components/elements/SkeletonLoader';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getTagsAction } from 'redux/actions/tags.actions';
import { getAllRoles } from 'redux/actions/roles.actions';
import { useFilters } from 'components/features/data_table/pagination_component/useFilters';
import { useTranslation } from 'react-i18next';

import './UserPickerDialog.css';

export default function UserPickerDialog({ modalOpen, setModalOpen, onConfirm }) {
    const usersPaginatedState = useSelector(state => state.userManagement.usersPaginated);
    const tagsState = useSelector(state => state.content.tags);
    const rolesState = useSelector(state => state.userManagement.roles);
    const singleNotificationState = useSelector(state => state.content.singleNotification);

    const [tagOptions, setTagOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);

    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    const {
        areAllOptionsFromThisPageSelected,
        addSelectedRow,
        removeSelectedRow,
        selectAll,
        allQuestionsSelected: allUsersSelected,
        setAllQuestionsSelected,
        deselectAll,
        filteredData,
        selectedRowsArr,
        setSelectedRows,
    } = useTableSelections({
        paginatedState: usersPaginatedState,
        rowSelector: row => row.id,
    });

    const classes = useStyles();
    const { notificationId } = useParams();

    const dataLoading = stateIsLoading(usersPaginatedState);

    const totalSelectedRows = allUsersSelected ? usersPaginatedState.data.totalElements : selectedRowsArr.length;

    const { filtersState, activeFilters, dispatchFilters, updateFilterState } = useFilters({
        initialFiltersState: { ...usersFiltersInitialState, size: { value: 8 } },
    });
    const filters = {
        name: filtersState.name,
        role: filtersState.role,
        status: filtersState.status,
        page: filtersState.page,
        size: filtersState.size,
        tags: filtersState.tags.map(el => el.value),
        orderBy: filtersState.sortParam?.selector,
        ascending: filtersState.sortParam?.ascending,
    };

    const { getPrevious, getNext, getPage } = usePagination({
        paginatedState: usersPaginatedState,
        currentPage: filters.page,
        setCurrentPage: page => dispatchFilters({ page: page }),
    });

    const handleSort = async (column, sortDirection) => {
        if (selectedRowsArr.length === 0) {
            dispatchFilters({ page: 0, sortParam: { selector: column.selector, ascending: sortDirection == 'asc' ? 'true' : 'false' } });
        }
    };

    const columns = [
        {
            name: <CustomCheckboxForTable checked={areAllOptionsFromThisPageSelected()} selectAll={selectAll} deselectAll={deselectAll} />,
            width: '50px',
            cell: row => {
                return (
                    <div style={{ height: '100%', width: '100%' }}>
                        <Checkbox
                            onClick={e => {
                                if (!row.selected) {
                                    addSelectedRow(row);
                                } else {
                                    removeSelectedRow(row);
                                    setAllQuestionsSelected(false);
                                }
                            }}
                            checked={row.selected}
                        ></Checkbox>
                    </div>
                );
            },
        },
        {
            name:
                selectedRowsArr.length === 0 ? (
                    <TableHeader text={t('Name')} />
                ) : (
                    <div className="d-flex flex-row align-items-center">
                        <Caption1 style={{ fontSize: 15 }} weight={'bold'}>
                            {totalSelectedRows}
                        </Caption1>
                        <Caption1 color={colors.gray6} style={{ padding: '10px 10px', borderRight: `1px solid ${colors.gray1}` }}>
                            {t('users selected')}
                        </Caption1>
                    </div>
                ),
            selector: 'name',
            sortable: 'true',
            wrap: true,
            width: 'calc(25% - 48px)',
            fontWeight: '800w',
            cell: row => (
                <TableCellContent
                    elementBefore={
                        <div className="mr-2">
                            <UserCircle className={'pt-2 pb-2'} name={row.name} lastName={row.lastName} bgColor={colors.gray4} />
                        </div>
                    }
                    text={row.name}
                    isLoading={dataLoading}
                />
            ),
        },
        {
            name: selectedRowsArr.length === 0 ? <TableHeader text={t('E-Mail')} /> : <></>,
            selector: 'email',
            sortable: 'true',
            width: '25%',
            cell: row => <TableCellContent isLoading={dataLoading} text={row.email} />,
        },
        {
            name: selectedRowsArr.length === 0 && <TableHeader text={t('Tags')} />,
            width: '23%',
            cell: row => renderTags(row.tags),
            allowOverflow: true,
            wrap: true,
        },
        {
            name: selectedRowsArr.length === 0 ? <TableHeader text={t('Role')} /> : <></>,
            selector: 'roleName',
            sortable: 'true',
            width: '13%',
            cell: row => <TableCellContent isLoading={dataLoading} text={row.companyRole?.roleName} />,
        },
        {
            name: selectedRowsArr.length === 0 ? <TableHeader text={t('Access level')} /> : <></>,
            selector: 'authRole',
            sortable: 'true',
            width: '13%',
            cell: row => <TableCellContent isLoading={dataLoading} text={getRoleParsed(row.authRole)} />,
        },
    ];

    function getRoleParsed(roleName) {
        if (roleName != undefined && roleName != null && roleName.length > 0) {
            var roleName = roleName?.toLowerCase().replace('role_', '');
            return roleName[0]?.toUpperCase() + roleName?.slice(1);
        }
        return '';
    }

    function renderTags(tags) {
        if (dataLoading) {
            return <SkeletonLoader />;
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', flexWrap: 'wrap', padding: '10px 0' }}>
                {tags.map(tag => (
                    <TagPill tag={tag} />
                ))}
            </div>
        );
    }

    useEffect(() => {
        dispatch(getTagsAction());
        dispatch(getAllRoles());
    }, []);

    useEffect(() => {
        if (stateIsLoaded(singleNotificationState) && notificationId) {
            var selectedUsersObject = {};
            for (var i = 0; i < singleNotificationState.data.userIds.length; ++i) {
                selectedUsersObject[singleNotificationState.data.userIds[i]] = { selected: true };
            }
            setSelectedRows(selectedUsersObject);
        }
    }, [singleNotificationState]);

    useEffect(() => {
        dispatch(
            fetchUsersPaginatedAction({
                ...filters,
                page: filters.page,
            })
        );
    }, [filtersState]);

    useEffect(() => {
        if (stateIsLoaded(tagsState) && tagsState.data) {
            let labelMap = {};

            for (let tagIndx in tagsState.data) {
                let tag = tagsState.data[tagIndx];
                if (!(tag.label in labelMap)) {
                    labelMap[tag.label] = [];
                }
                labelMap[tag.label].push({ label: tag.tag, value: tag.tagId });
            }
            let formatedTagOptions = [];

            for (let labelIndx in Object.keys(labelMap)) {
                let label = Object.keys(labelMap)[labelIndx];
                formatedTagOptions.push({
                    label: label,
                    options: labelMap[label],
                });
            }
            setTagOptions(formatedTagOptions);

            if (location?.search) {
                const query = new URLSearchParams(location.search);
                let tagIds = query
                    ?.get('tags')
                    ?.split(',')
                    .filter(el => el);
                if (tagIds && tagIds.length > 0) {
                    let filterValTag = tagsState.data
                        .filter(el => tagIds.includes(el.tagId.toString()))
                        .map(el => {
                            return { label: el.tag, value: el.tagId };
                        });
                    dispatchFilters({ tags: filterValTag });
                }
            }
        }
    }, [tagsState]);

    useEffect(() => {
        if (stateIsLoaded(rolesState)) {
            setRoleOptions(
                rolesState.data.roles.map(el => {
                    return {
                        label: el.roleName,
                        value: el.roleId,
                    };
                })
            );
        }
    }, [rolesState]);

    return (
        <Modal
            open={modalOpen}
            className={classes.modal}
            onClose={() => {
                setModalOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="user-picker-dialog--container">
                <div className="d-flex flex-row align-content-center my-2">
                    <H6>{t('Select recipients')}</H6>
                    <img
                        alt="Close"
                        className="ml-auto cursor-pointer"
                        src={Close}
                        height={20}
                        width={20}
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    />
                </div>
                <DataTable
                    sortIcon={selectedRowsArr.length === 0 ? <img height={15} width={13} className="ml-2" src={CaretTable} alt="sort"></img> : <></>}
                    columns={columns}
                    onSort={handleSort}
                    sortServer
                    data={filteredData}
                    overflowY={'visible'}
                    overflowYOffset={'0px'}
                    defaultSortField="name"
                    subHeader={true}
                    paginationPerPage={100}
                    pagination={true}
                    selectableRows={false}
                    highlightOnHover={true}
                    subHeaderComponent={
                        <Subheader
                            inputs={usersTableSubheaders({
                                filtersState,
                                activeFilters,
                                updateFilterState,
                                dispatchFilters,
                                tagOptions,
                                roleOptions,
                                setInviting: () => {},
                                resetBulkActions: () => {},
                                inviteVisible: false,
                            })}
                        />
                    }
                    subHeaderAlign={'left'}
                    customStyles={customStylesTable}
                    theme={'tableTheme'}
                    selectableRowSelected={row => row.selected}
                    noHeader={true}
                    paginationComponent={_ => (
                        <PaginationComponent
                            getPrev={getPrevious}
                            getNext={getNext}
                            getPage={getPage}
                            currentPage={filters.page}
                            totalPages={usersPaginatedState.data.totalPages}
                            totalItems={usersPaginatedState?.data?.totalElements}
                            canChangeSize={false}
                            size={filters.size}
                        />
                    )}
                ></DataTable>
                <div className="user-picker-dialog--buttons">
                    <DefaultButton
                        backgroundColor={colors.white}
                        textColor={colors.dark}
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        Cancel
                    </DefaultButton>
                    <DefaultButton
                        textColor={colors.white}
                        style={{ marginLeft: 15 }}
                        onClick={() => {
                            onConfirm(selectedRowsArr, {
                                allUsersSelected: allUsersSelected,
                                allUsersCount: totalSelectedRows,
                                userFilters: filters,
                            });
                        }}
                    >
                        {t('Confirm Selection')}
                    </DefaultButton>
                </div>
            </div>
        </Modal>
    );
}

UserPickerDialog.propTypes = {
    modalOpen: PropTypes.any,
    onConfirm: PropTypes.func,
    setModalOpen: PropTypes.func,
};

const useStyles = makeStyles(theme => {
    return {
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `solid 1px ${colors.gray4}`,
        },
    };
});
