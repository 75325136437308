import { newsStatusOptions } from 'static/status_options';

export const newsTableSubheaders = ({ filtersState, activeFilters, updateFilterState, dispatchFilters, addNewPost }) => [
    {
        id: 'news-search-input',
        type: 'value',
        value: filtersState.searchQuery,
        setValue: val => {
            dispatchFilters({ page: 0, searchQuery: val });
        },
        placeholder: 'Search',
        active: true,
    },
    {
        id: 'news-valid-from-dropdown',
        type: 'datepicker',
        value: filtersState.validFrom,
        setValue: val => {
            dispatchFilters({ page: 0, validFrom: val });
        },
        placeholder: 'Valid from',
        clearOnBackspace: true,
        active: activeFilters.validFrom.active,
    },
    {
        id: 'news-valid-until-dropdown',
        type: 'datepicker',
        value: filtersState.validUntil,
        setValue: val => {
            dispatchFilters({ page: 0, validUntil: val });
        },
        placeholder: 'Valid until',
        clearOnBackspace: true,
        active: activeFilters.validUntil.active,
    },
    {
        id: 'news-status-dropdown',
        type: 'dropdown',
        value: filtersState.status,
        setValue: val => {
            dispatchFilters({ page: 0, status: val });
        },
        placeholder: 'Status',
        clearOnBackspace: true,
        dropdownValues: newsStatusOptions,
        active: activeFilters.status.active,
    },
    { type: 'add-filters', active: true, activeFilters: activeFilters, updateFilterState: updateFilterState },
    {
        id: 'news-add-new-post-button',
        type: 'button',
        name: 'Add new post',
        onClick: addNewPost,
    },
];
