import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { AnalyticsContainer } from 'components/features/analytics/AnalyticsContainer';
import { GlobalStatistics } from './GlobalStatistics';
import { colors } from 'style/colors';
import { CategoryAccuracy } from 'components/features/analytics/training_accuracy/CategoryAccuracy';
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts';
import { KnowledgeOverTimeWrapper } from 'components/features/analytics/progress_over_time/ProgressOverTimeWrapper';
import { CustomPieChart } from 'components/features/analytics/covered_material/CustomPieChart';
import { CoveredMaterial } from 'components/features/analytics/covered_material/CoveredMaterial';
import { AchievementStatsWrapper } from './achievement_stats/AchievementStatsWrapper';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import TimeLearning from 'assets/icons/profilestats-icon-time.svg';
import Gameplays from 'assets/icons/profilestats-icon-games.svg';
import Accuracy from 'assets/icons/profilestats-icon-accuracy.svg';
import CorrectAnswers from 'assets/icons/profilestats-icon-correctans.svg';
import DayStreak from 'assets/icons/profilestats-icon-streak.svg';
import TotalAnswers from 'assets/icons/profilestats-icon-totalans.svg';
import { getHoursAndMinutesParsed } from 'services/utils/numberHelpers';
import { analyticType, userAnalyticsDefault } from 'static/analytic_constants';
import { useTranslation } from 'react-i18next';
import { Body2 } from 'style/typography/Body';
import UserStatisticsEmptyState from '../local_widgets/UserStatisticsEmptyState';

export function UserAnalyticsWrapper({ globalStats, name }) {
    const { t } = useTranslation();

    const [userAnalytics, dispatchUserAnalytics] = useReducer((state, action) => {
        return { ...state, ...action };
    }, userAnalyticsDefault);
    
    useEffect(() => {
        if (stateIsLoaded(globalStats)) {
            dispatchUserAnalytics({
                coachScore: globalStats.data.mainStats.coachScore,
                coachScoreImprovement: globalStats.data.mainStats.coachScoreImprovementPercent,
                coachScorePercentile: globalStats.data.mainStats.coachScorePercentile,
                firstRowStats: [
                    {
                        firstValue: getHoursAndMinutesParsed(globalStats.data.mainStats.totalMinutesLearning),
                        secondValue: '',
                        description: 'learning in-game',
                        icon: TimeLearning,
                    },
                    {
                        firstValue: globalStats.data.mainStats.totalGameplays,
                        secondValue: '',
                        description: 'games played',
                        icon: Gameplays,
                    },
                    {
                        firstValue: globalStats.data.mainStats.accuracy.toFixed() + '%',
                        description: 'accuracy',
                        icon: Accuracy,
                    },
                ],
                secondRowStats: [
                    {
                        firstValue: globalStats.data.mainStats.correctAnswers,
                        secondValue: '',
                        description: 'correct answers',
                        icon: CorrectAnswers,
                    },
                    {
                        firstValue: globalStats.data.mainStats.maxDayStreak,
                        secondValue: '',
                        description: 'days max. streak',
                        icon: DayStreak,
                    },
                    {
                        firstValue: globalStats.data.mainStats.totalAnswers,
                        secondValue: '',
                        description: 'total answers',
                        icon: TotalAnswers,
                    },
                ],

                knowledgeMap: globalStats.data.knowledgeMap?.userList?.map((value, index) => {
                    return {
                        category: index + 1,
                        user: value.accuracy,
                        others: globalStats.data.knowledgeMap.otherUsersList[index]?.accuracy,
                        fullMark: 100,
                    };
                }),

                totalQuestions: globalStats.data.contentCoverage?.totalQuestions,
                totalQuestionsCovered: globalStats.data.contentCoverage?.totalQuestionsCovered,
                totalQuestionsLeft: globalStats.data.contentCoverage?.totalQuestionsLeft,

                pieData: [
                    { name: 'Covered', value: globalStats.data.contentCoverage?.totalQuestionsCoveredPercent },
                    { name: 'Left', value: globalStats.data.contentCoverage?.totalQuestionsLeftPercent },
                ],

                knowledgeOverTimeData: globalStats.data.knowledgeOverTime,
                unlockedBadges: globalStats.data.achievementStats.unlockedBadges,
                lockedBadges: globalStats.data.achievementStats.lockedBadges,
                totalBadges: globalStats.data.achievementStats.totalBadges,
                totalUnlockedBadges: globalStats.data.achievementStats.totalUnlockedBadges,
                sessions: globalStats.data.sessionStats,
            });
        }
    }, [globalStats]);

    return (
        <div className={'row justify-content-center'}>
            <AnalyticsContainer
                title={t(`{{name}}'s statistics`, { name: name })}
                description={t('The user’s most important statistics gathered from their use of the Coach app.')}
                infoString={t(
                    "Here you can see the user's global rank and its growth/decrease trend compared to the previous week. On the right, there are six key statistics: the total time the user spent playing ('learning in-game'), the total number of games played ('games played'), the average accuracy of answered questions in the games (accuracy), the total number of correct answers (correct answers), the highest achieved regularity (days max. streak), and the total number of answers (total answers)."
                )}
            >
                <GlobalStatistics
                    name={name}
                    coachScore={userAnalytics.coachScore}
                    increase={userAnalytics.coachScoreImprovement}
                    percentile={typeof coachScorePercentile == 'number' ? userAnalytics.coachScorePercentile.toFixed() : ''}
                    firstRowStats={userAnalytics.firstRowStats}
                    secondRowStats={userAnalytics.secondRowStats}
                ></GlobalStatistics>
            </AnalyticsContainer>
            <AnalyticsContainer
                title={t(`{{name}}’s accuracy in trainings`, { name: name })}
                description={t('See how well this user compares to other players in each of the assigned trainings.')}
                infoString={t(
                    'Here you can see the percentage of accuracy that the user has achieved in the various topics of the training, as well as the same accuracy compared to other users playing.'
                )}
            >
                {!(Object.keys(globalStats.data.knowledgeMap)?.length > 0) && <UserStatisticsEmptyState type={analyticType.ACCURACY} />}
                {Object.keys(globalStats.data.knowledgeMap)?.length > 0 && (
                    <div className={'row mb-2'}>
                        <div style={{ borderColor: colors.underlineColor }} className="col-7 border-right pr-2 pl-2">
                            <div className={'row justify-content-center'}>
                                {globalStats.data.knowledgeMap?.userList?.map((value, index) => {
                                    return (
                                        <div className="col-10 mt-3">
                                            <CategoryAccuracy
                                                accuracy={value.accuracy.toFixed()}
                                                categoryName={value.category.name}
                                                experience={value.experienceLabel}
                                                index={index + 1}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col-5 d-flex flex-column justify-content-center text-center align-items-center mb-2">
                            <Body2 style={{ fontSize: 12 }} className={'text-muted mb-2'}>
                                {t('Compared to other players')}
                            </Body2>
                            <RadarChart style={{ position: '' }} outerRadius={90} width={300} height={220} data={userAnalytics.knowledgeMap}>
                                <PolarGrid stroke={colors.mainTheme} gridType={'circle'} />
                                <PolarAngleAxis dataKey={'category'} />
                                <PolarRadiusAxis tickCount={userAnalytics.knowledgeMap.length} type={'number'} domain={[0, 100]} />
                                <Radar name={t('Others')} dataKey="others" stroke={colors.gray1} fill={colors.gray1} fillOpacity={0.6} />
                                <Radar name={name} dataKey="user" stroke={colors.mainTheme} fill={colors.mainTheme} fillOpacity={0.5} />
                                <Legend style={{ width: '100%' }} wrapperStyle={{ position: '', width: '100%' }} iconType={'circle'} />
                            </RadarChart>
                        </div>
                    </div>
                )}
            </AnalyticsContainer>
            <AnalyticsContainer
                title={t(`{{name}}’s progress over time`, { name: name })}
                description={t('See how this user has progressed over a selected period of time in each assigned training.')}
                infoString={t(
                    'Here you can see a graph that shows the progress and development of the user (his accuracy in answering and playing) according to a selected period of time (one week, one month, several months, one year) for each topic of the assigned training.'
                )}
            >
                {!(globalStats.data.knowledgeOverTime?.length > 0) && <UserStatisticsEmptyState type={analyticType.OVER_TIME} />}

                {globalStats.data.knowledgeOverTime?.length > 0 && (
                    <KnowledgeOverTimeWrapper key={'KnowledgeOverTimeAllTrainings'} switchable={true} data={globalStats.data.knowledgeOverTime} />
                )}
            </AnalyticsContainer>
            <AnalyticsContainer
                title={t(`{{name}}’s Covered Material`, { name: name })}
                description={t('See how much of the content in this training the user has covered')}
                infoString={t(
                    "Here you can see the user's coverage of the material, and it contains a graph showing the percentage of training questions covered, as well as the percentage of questions the user has yet to complete by practicing and playing in the application."
                )}
            >
                <div className="d-flex flex-row">
                    <div className="text-center justify-content-center">
                        <CustomPieChart
                            style={{ display: 'flex', position: '' }}
                            data={userAnalytics.pieData}
                            height={250}
                            width={400}
                        ></CustomPieChart>
                    </div>
                    <div>
                        <CoveredMaterial
                            totalQuestions={userAnalytics.totalQuestions}
                            totalQuestionsLeft={userAnalytics.totalQuestionsLeft}
                            totalQuestionsCovered={userAnalytics.totalQuestionsCovered}
                        ></CoveredMaterial>
                    </div>
                </div>
            </AnalyticsContainer>
            <AnalyticsContainer
                title={t(`{{name}}’s Achievements`, { name: name })}
                description={t('See how many badges the user has already unlocked, and which ones are left to achieve.')}
                twoSided={true}
                secondTitle={t('Last sessions')}
                secondDescription={t('See the last times the user has logged in.')}
                infoString={t(
                    'Here you can see data on which achievements (awarded badges) the user has won while training in Coach, as well as those that remain to be achieved. On the right, you can see the five most recent playing sessions, i.e. exactly at what moment the selected user logged into the application, and what was the duration of their training.'
                )}
            >
                <AchievementStatsWrapper
                    lockedBadges={userAnalytics.lockedBadges}
                    unlockedBadges={userAnalytics.unlockedBadges}
                    sessions={userAnalytics.sessions}
                    totalBadges={userAnalytics.totalBadges}
                    totalUnlockedBadges={userAnalytics.totalUnlockedBadges}
                />
            </AnalyticsContainer>
        </div>
    );
}

UserAnalyticsWrapper.propTypes = {
    globalStats: PropTypes.any,
    name: PropTypes.any,
};
