import { monthNames } from 'static/months';
import { imageLink } from './linkHelpers';

export const truncateString = (text, length, clamp) => {
    let txt = text || '';
    let cl = clamp || '...';
    let l = length || 100;
    let t = txt.length > l ? txt.substring(0, l) + cl : txt;
    return t;
};

export const fixBrokenHtml = string => {
    return new DOMParser().parseFromString(string, 'text/html').body.innerHTML;
};

export const searchInWord = (keyword, word) => {
    return word.search(new RegExp(keyword, 'i')) !== -1;
};

export const constructQueryString = queryItems => {
    return queryItems
        .filter(item => item.value)
        .map(item => `${item.name}=${item.value}`)
        .join('&');
};

export const fixHtmlImageSource = html => {
    let element = document.createElement('div');
    element.innerHTML = html;
    let imgSrcUrls = element.getElementsByTagName('img');

    for (let i = 0; i < imgSrcUrls.length; i++) {
        let urlValue = imgSrcUrls[i].getAttribute('src');
        if (urlValue) {
            imgSrcUrls[i].setAttribute('src', imageLink(urlValue));
        }
    }
    return element.outerHTML;
};

export const stringToArrayStructure = (str, delimiter = ',') => {
    return str.split(delimiter).map(item => String(item).trim());
};

export const shortenString = (str, maxLen) => {
    if (str && str.length > maxLen) {
        return str.substring(0, 5) + '...' + str.substring(str.length - 10, str.length);
    }
    return str;
};

export const getNameFromLink = str => {
    if (str) {
        return str.split('/').pop();
    }
    return str;
};

export const validateEmail = email => {
    //eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const parseAsBool = val => ['true', 'y', 'yes'].includes(val && val.toString().toLowerCase());

export const create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};

export const parseDate = date => {
    let currentTime = new Date();
    let timeDifference = currentTime.getTime() - date.getTime();
    let daysAgo = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    if (daysAgo == 0) {
        return 'Today';
    } else if (daysAgo == 1) {
        return 'Yesterday';
    } else {
        return daysAgo + ' days ago';
    }
};
export const getTitleCase = string => {
    if (string && string.length > 1) {
        return string.slice(0, 1).toUpperCase() + string.slice(1, string.length).toLowerCase();
    }
    return string;
};

export const formatDate = dateString => {
    let date = new Date(dateString);
    return `${date.getDate()} ${date.getMonth()} ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`;
};

export const formatDateOnly = dateString => {
    let date = new Date(dateString);
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const removeEmptyLines = str =>
    str
        .split(/\r?\n/)
        .filter(line => line.trim() !== '')
        .join('\n');

export const splitOnNthOccurence = (string, delimiter, n) => {
    let arr = string.split(delimiter);
    let newStr = '';

    if (arr.length < n) {
        return string;
    }

    for (let i = 0; i < n; i++) {
        newStr += arr[i] + '/';
    }

    return newStr;
};
