import { ActionStatus } from '../core/ActionStatus';
import { buildActionType } from './buildActionType';
import {
    ACTIVATE_USER,
    CHANGE_PASSWORD,
    DEACTIVATE_USER,
    DELETE_USER,
    EDIT_USER,
    EDIT_USERS_IN_BATCH,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SET_NEW,
    GET_ALL_AUTH_ROLES,
    GET_ALL_COMPANY_ROLES,
    GET_COMPANY,
    GET_SINGLE_ROLE,
    GET_SINGLE_USER,
    GET_USERS,
    GET_USERS_WITH_ROLE,
    GET_USERS_PAGINATED,
    GET_USERS_WITH_UNASSIGNED_ROLE,
    HANDLE_USERS_INVITE,
    INVITE_MULTIPLE_USERS,
    INVITE_USER,
    REGISTER_ADMINISTRATOR,
    UPLOAD_USERS,
    HANDLE_ADMINS_INVITE,
    INVITE_MULTIPLE_USERS_ADMIN,
    USERS_MODIFIED_IN_ROLES,
    INVITE_MULTIPLE_USERS_PROGRESS,
    GET_ACTIVE_USERS_COUNT_FOR_MONTH,
    EDIT_USERS_ROLE_IN_BATCH,
    REINVITE_USERS,
    GET_DEVICE_REQUESTS_PAGINATED,
    RESOLVE_DEVICE_REQUESTS_BULK,
    DELETE_DEVICE_REQUESTS_BULK,
    GET_DEVICE_REQUESTS_FOR_USER,
} from '../constants/users.constants';

import {
    activateUserApiRequest,
    changePasswordApiRequest,
    deactivateUserApiRequest,
    deleteUserApiRequest,
    downloadTemplateApiRequest,
    editUserApiRequest,
    editUsersInBatchApiRequest,
    fetchAuthRolesApiRequest,
    fetchSingleRoleApiRequest,
    fetchSingleUserApiRequest,
    fetchUsersWithRoleApiRequest,
    fetchUsersPaginatedApiRequest,
    fetchUsersWithUnassignedRoleApiRequest,
    forgotPasswordRequestApiRequest,
    forgotPasswordSetNewApiRequest,
    getCompanyApiRequest,
    inviteMultipleApiRequest,
    inviteUserApiRequest,
    registerAdministratorApiRequest,
    uploadUsersApiRequest,
    userBatchActionApiRequest,
    inviteMultipleAdminsApiRequest,
    downloadActiveUsersForMonthApiRequest,
    getActiveUsersCountForMonthApiRequest,
    downloadUsersFullTemplateApiRequest,
    uploadUsersFullApiRequest,
    editUsersRoleInBatchApiRequest,
    changePasswordForUserApiRequest,
    reInviteUsersApiRequest,
    fetchUserDevicesPaginatedApiRequest,
    resolveDeviceRequestsBulkApiRequest,
    deleteDeviceRequestsBulkApiRequest,
    getDeviceRequestsForUserApiRequest,
} from 'services/api/apiRequests/users';

import { HANDLE_NEXT_ERROR } from 'redux/constants/users.constants';
import { ApiExecutor } from './ApiExecutor';

export const fetchUsersPaginatedAction = queryData => async dispatch => {
    await dispatch(ApiExecutor(fetchUsersPaginatedApiRequest(queryData), GET_USERS_PAGINATED));
};

export const resetFetchUsersPaginatedAction = () => dispatch => {
    dispatch({ type: buildActionType(GET_USERS_PAGINATED, ActionStatus.RESET) });
};

export const fetchUsersWithUnassignedRoleAction = (searchQuery, page, reset) => async (dispatch, getState) => {
    let modifiedUsersState = getState().userManagement.usersModifiedInRoles;
    let modifiedUsersData = modifiedUsersState.data;
    if (modifiedUsersData === undefined) modifiedUsersData = [];
    let ignoreIds = modifiedUsersData?.map(u => u.id);

    dispatch({ type: buildActionType(GET_USERS_WITH_UNASSIGNED_ROLE, ActionStatus.START) });
    let response = await fetchUsersWithUnassignedRoleApiRequest(ignoreIds, searchQuery ? searchQuery : '', page);

    if (response.success) {
        let usersWithUnassignedRoleState = getState().userManagement.usersWithUnassignedRole;
        let usersWithUnassignedRole = usersWithUnassignedRoleState?.data;

        if (usersWithUnassignedRole.length === 0)
            usersWithUnassignedRole = {
                users: [],
                page: -1,
                totalElements: 0,
                totalPages: 0,
            };

        if (!reset && page !== 0) {
            usersWithUnassignedRole.users = [...usersWithUnassignedRole.users, ...response.data.content];
        } else {
            usersWithUnassignedRole.users = [...response.data.content];
        }
        usersWithUnassignedRole.page = page;
        usersWithUnassignedRole.totalPages = response.data.totalPages;
        usersWithUnassignedRole.totalElements =
            response.data.totalElements +
            modifiedUsersState.data?.filter(el => !el.companyRole && ((!el.name && !searchQuery) || el.name.toUpperCase().includes(searchQuery.toUpperCase())))?.length;

        dispatch({ type: buildActionType(GET_USERS_WITH_UNASSIGNED_ROLE, ActionStatus.DONE), payload: usersWithUnassignedRole });
    } else {
        dispatch({
            type: buildActionType(GET_USERS_WITH_UNASSIGNED_ROLE, ActionStatus.DONE),
            payload: {
                users: [],
                page: -1,
                totalElements: 0,
                totalPages: 0,
            },
        });
    }
};

export const fetchUsersWithRoleAction = (roleId, searchQuery, page, reset) => async (dispatch, getState) => {
    let modifiedUsersState = getState().userManagement.usersModifiedInRoles;
    let modifiedUsersData = modifiedUsersState.data;
    if (modifiedUsersData === undefined) modifiedUsersData = [];
    let ignoreIds = modifiedUsersData?.map(u => u.id);

    dispatch({ type: buildActionType(GET_USERS_WITH_ROLE, ActionStatus.START) });
    let response = await fetchUsersWithRoleApiRequest(roleId, ignoreIds, searchQuery ? searchQuery : '', page);

    if (response.success) {
        let usersWithRoleState = getState().userManagement.usersWithRole;
        let usersWithRoleData = usersWithRoleState?.data;

        if (usersWithRoleData.length === 0)
            usersWithRoleData = {
                users: [],
                role: roleId,
                page: -1,
                totalElements: 0,
                totalPages: 0,
            };

        if (usersWithRoleData.role !== roleId) {
            usersWithRoleData.users = response?.data?.content?.length > 0 ? [...response?.data?.content] : [];
        } else {
            if (!reset && page !== 0) {
                usersWithRoleData.users =
                    response?.data?.content?.length > 0 ? [...usersWithRoleData.users, ...response?.data?.content] : [...usersWithRoleData.users];
            } else {
                usersWithRoleData.users = response?.data?.content?.length > 0 ? [...response?.data?.content] : [];
            }
        }

        usersWithRoleData.role = roleId;
        usersWithRoleData.page = page;
        usersWithRoleData.totalPages = response.data.totalPages;
        usersWithRoleData.totalElements =
            response.data.totalElements +
            modifiedUsersState.data?.filter(
                el => el.companyRole?.roleId == roleId && (!el.name || el.name.toUpperCase().includes(searchQuery.toUpperCase()))
            ).length;

        dispatch({ type: buildActionType(GET_USERS_WITH_ROLE, ActionStatus.DONE), payload: usersWithRoleData });
    } else {
        dispatch({
            type: buildActionType(GET_USERS_WITH_ROLE, ActionStatus.DONE),
            payload: {
                users: [],
                role: roleId,
                page: -1,
                totalElements: 0,
                totalPages: 0,
            },
        });
    }
};

export const addUsersToUsersModifiedInRoles = user => async (dispatch, getState) => {
    let state = getState().userManagement.usersModifiedInRoles;
    let data = state !== undefined ? [...state.data, user] : [user];

    dispatch({ type: buildActionType(USERS_MODIFIED_IN_ROLES, ActionStatus.DONE), payload: data });
};

export const resetUsersModifiedInRolesAction = () => async dispatch => {
    dispatch({ type: buildActionType(USERS_MODIFIED_IN_ROLES, ActionStatus.RESET) });
};

export const resetUsersWithRole = () => async dispatch => {
    dispatch({ type: buildActionType(GET_USERS_WITH_ROLE, ActionStatus.RESET) });
};
export const resetUsersWithUnassignedRole = () => async dispatch => {
    dispatch({ type: buildActionType(GET_USERS_WITH_UNASSIGNED_ROLE, ActionStatus.RESET) });
};

export const clearUsersModifiedInRoles = () => async dispatch => {
    let data = [];
    dispatch({ type: buildActionType(USERS_MODIFIED_IN_ROLES, ActionStatus.DONE), payload: data });
};

export const editUserAction = (data, id) => async dispatch => {
    await dispatch(ApiExecutor(editUserApiRequest(data, id), EDIT_USER));
};

export const editModifiedUsersInBatchAction = (data, additionalActionsOnResponse) => async dispatch => {
    dispatch({ type: buildActionType(EDIT_USERS_IN_BATCH, ActionStatus.START) });

    let response;

    response = await editUsersInBatchApiRequest(data);

    if (response.success) {
        dispatch({ type: buildActionType(EDIT_USERS_IN_BATCH, ActionStatus.DONE), payload: response.data });

        if (additionalActionsOnResponse?.length > 0) {
            additionalActionsOnResponse.forEach(action => dispatch(action(response.data)));
        }
    } else {
        dispatch({ type: buildActionType(EDIT_USERS_IN_BATCH, ActionStatus.FAILED) });
    }
};

export const editUsersRoleInBatchAction = data => async dispatch => {
    let response = await dispatch(ApiExecutor(editUsersRoleInBatchApiRequest(data), EDIT_USERS_ROLE_IN_BATCH));
};

export const resetEditUsersRoleInBatchAction = () => async dispatch => {
    dispatch({ type: buildActionType(EDIT_USERS_ROLE_IN_BATCH, ActionStatus.RESET) });
};

export const resetEditModifiedUsersInBatchAction = () => async dispatch => {
    dispatch({ type: buildActionType(EDIT_USERS_IN_BATCH, ActionStatus.RESET) });
};

export const deleteUsersInBatchAction = users => async dispatch => {
    let data = {
        userIds: [...users.map(user => user.id)],
        action: 'delete',
    };

    let response = await dispatch(ApiExecutor(userBatchActionApiRequest(data), DELETE_USER));

    return !!response.success;
};
export const deactivateUsersInBatchAction = users => async dispatch => {
    let data = {
        userIds: [...users.map(user => user.id)],
        action: 'deactivate',
    };

    let response = await dispatch(ApiExecutor(userBatchActionApiRequest(data), DEACTIVATE_USER));

    return !!response.success;
};

export const addRoleToUserAction = (roleId, userId) => async (dispatch, getState) => {
    let usersWithUnassignedRoleState = getState().userManagement.usersWithUnassignedRole;
    let usersWithRoleData = getState().userManagement.usersWithRole?.data;
    let usersModifiedInRolesState = getState().userManagement.usersModifiedInRoles;

    let user = usersWithUnassignedRoleState?.data?.users?.find(u => u.id === userId);
    if (user === undefined) user = usersModifiedInRolesState?.data?.find(u => u.id === userId);

    if (user !== undefined) {
        user.companyRole = {
            roleId: roleId,
        };

        let modifiedUsersData = usersModifiedInRolesState?.data;
        if (modifiedUsersData === undefined) modifiedUsersData = [];

        let indexOfUser = modifiedUsersData.findIndex(u => u.id === userId);

        //the user is not present in the modified users list, add it
        if (indexOfUser === -1) {
            modifiedUsersData = [...modifiedUsersData, user];
        }
        //the user is present, find it and edit it
        else {
            modifiedUsersData[indexOfUser] = user;
        }

        dispatch({ type: buildActionType(USERS_MODIFIED_IN_ROLES, ActionStatus.DONE), payload: modifiedUsersData });
        dispatch({
            type: buildActionType(GET_USERS_WITH_ROLE, ActionStatus.DONE),
            payload: {
                ...usersWithRoleData,
                totalElements: Number(usersWithRoleData.totalElements) + 1,
            },
        });
        dispatch({
            type: buildActionType(GET_USERS_WITH_UNASSIGNED_ROLE, ActionStatus.DONE),
            payload: {
                ...usersWithUnassignedRoleState?.data,
                users: [...usersWithUnassignedRoleState?.data?.users?.filter(u => u.id !== userId)],
                totalElements: usersWithUnassignedRoleState.data.totalElements - 1,
            },
        });
    }
};

export const removeRoleFromUserAction = userId => async (dispatch, getState) => {
    let usersWithRoleData = getState().userManagement.usersWithRole?.data;
    let usersModifiedInRolesState = getState().userManagement.usersModifiedInRoles;
    let usersWithUnassignedRoleState = getState().userManagement.usersWithUnassignedRole;

    let user = usersWithRoleData?.users?.find(u => u.id === userId);

    if (user === undefined) user = usersModifiedInRolesState?.data?.find(u => u.id === userId);

    if (user !== undefined) {
        user.companyRole = null;
        user.modified = true;

        let modifiedUsersData = usersModifiedInRolesState?.data;
        if (modifiedUsersData === undefined) modifiedUsersData = [];

        let indexOfUser = modifiedUsersData.findIndex(u => u.id === userId);

        //the user is not present in the modified users list, add it
        if (indexOfUser === -1) {
            modifiedUsersData = [...modifiedUsersData, user];
        }
        //the user is present, find it and edit it
        else {
            modifiedUsersData[indexOfUser] = user;
        }

        dispatch({ type: buildActionType(USERS_MODIFIED_IN_ROLES, ActionStatus.DONE), payload: [...modifiedUsersData] });
        dispatch({
            type: buildActionType(GET_USERS_WITH_UNASSIGNED_ROLE, ActionStatus.DONE),
            payload: {
                ...usersWithUnassignedRoleState?.data,
                totalElements: usersWithUnassignedRoleState.data.totalElements + 1,
            },
        });
        dispatch({
            type: buildActionType(GET_USERS_WITH_ROLE, ActionStatus.DONE),
            payload: {
                ...usersWithRoleData,
                users: [...usersWithRoleData.users?.filter(u => u.id !== userId)],
                totalElements: Number(usersWithRoleData.totalElements) - 1,
            },
        });
    }
};
export const deleteUserAction = id => async dispatch => {
    await dispatch(ApiExecutor(deleteUserApiRequest(id), DELETE_USER));
};
export const deactivateUserAction = id => async dispatch => {
    await dispatch(ApiExecutor(deactivateUserApiRequest(id), DEACTIVATE_USER));
};

export const resetDeactivateUserAction = () => async dispatch => {
    dispatch({ type: buildActionType(DEACTIVATE_USER, ActionStatus.RESET) });
};

export const activateUserAction = id => async dispatch => {
    await dispatch(ApiExecutor(activateUserApiRequest(id), ACTIVATE_USER));
};

export const resetActivateUserAction = () => async dispatch => {
    dispatch({ type: buildActionType(ACTIVATE_USER, ActionStatus.RESET) });
};

export const inviteUserAction = (email, role) => async (dispatch, getState) => {
    dispatch({
        type: buildActionType(INVITE_USER, ActionStatus.RESET),
    });

    let data = new FormData();
    data.set('email', email);
    data.set('role', role);
    let response = await dispatch(ApiExecutor(inviteUserApiRequest(data), INVITE_USER));

    if (response.success) {
        let fullUser = response.data;
        let users = getState().userManagement.users.data;

        dispatch({
            type: buildActionType(GET_USERS, ActionStatus.DONE),
            payload: [...users, fullUser],
        });
    }
};

export const reinviteUsersAction = userIds => async dispatch => {
    dispatch({
        type: buildActionType(REINVITE_USERS, ActionStatus.RESET),
    });

    await dispatch(ApiExecutor(reInviteUsersApiRequest(userIds), REINVITE_USERS));
};

export const resetReinviteUsersAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(REINVITE_USERS, ActionStatus.RESET),
    });
};

export const registerAdministratorAction = data => async dispatch => {
    dispatch({ type: buildActionType(REGISTER_ADMINISTRATOR, ActionStatus.START) });
    let formData = new FormData();

    formData.set('name', data.name);
    formData.set('lastName', data.lastName);
    formData.set('password', data.password);
    formData.set('code', data.code);
    let response = await registerAdministratorApiRequest(formData);

    dispatch({
        type: buildActionType(REGISTER_ADMINISTRATOR, ActionStatus.DONE),
        payload: {
            ...response.data,
            name: data.name,
            password: data.password,
        },
    });
};
export const forgotPasswordRequestAction = email => async dispatch => {
    let formData = new FormData();
    formData.set('email', email);

    await dispatch(ApiExecutor(forgotPasswordRequestApiRequest(formData), FORGOT_PASSWORD_REQUEST));
};

export const forgotPasswordSetNewAction = data => async dispatch => {
    let formData = new FormData();
    formData.set('code', data.code);
    formData.set('password', data.password);

    await dispatch(ApiExecutor(forgotPasswordSetNewApiRequest(formData), FORGOT_PASSWORD_SET_NEW));
};
export const getSingleUserAction = id => async dispatch => {
    dispatch(ApiExecutor(fetchSingleUserApiRequest(id), GET_SINGLE_USER));
};

export const getSingleRoleAction = id => async dispatch => {
    dispatch(ApiExecutor(fetchSingleRoleApiRequest(id), GET_SINGLE_ROLE));
};

export const resetGetAllRolesAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_ALL_COMPANY_ROLES, ActionStatus.RESET),
    });
};
export const getAllAuthRolesAction = () => async dispatch => {
    await dispatch(ApiExecutor(fetchAuthRolesApiRequest(), GET_ALL_AUTH_ROLES));
};
export const getCompanyAction = () => async dispatch => {
    await dispatch(ApiExecutor(getCompanyApiRequest(), GET_COMPANY));
};
export const inviteMultipleAction = users => async dispatch => {
    await dispatch(ApiExecutor(inviteMultipleApiRequest(users), INVITE_MULTIPLE_USERS));
};

export const inviteMultipleAdminsAction = users => async dispatch => {
    await dispatch(ApiExecutor(inviteMultipleAdminsApiRequest(users), INVITE_MULTIPLE_USERS_ADMIN));
};

export const resetInviteMultipleUsersAction = () => async dispatch =>
    dispatch({
        type: buildActionType(INVITE_MULTIPLE_USERS, ActionStatus.RESET),
    });

export const updateUsersInviteProgressAction = requestProgress => async dispatch => {
    dispatch({
        type: buildActionType(INVITE_MULTIPLE_USERS_PROGRESS, ActionStatus.DONE),
        payload: requestProgress,
    });
};

export const resetInviteMultipleUsersAdminAction = () => async dispatch =>
    dispatch({
        type: buildActionType(INVITE_MULTIPLE_USERS_ADMIN, ActionStatus.RESET),
    });
export const resetInviteUsersProgressAction = () => async dispatch =>
    dispatch({
        type: buildActionType(INVITE_MULTIPLE_USERS_PROGRESS, ActionStatus.RESET),
    });
export const uploadUsersAction = file => async dispatch => {
    await dispatch(ApiExecutor(uploadUsersApiRequest(file), UPLOAD_USERS));
};

export const uploadUsersFullAction = (token, file) => async dispatch => {
    await uploadUsersFullApiRequest(token, file);
};

export const uploadUserTemplateDoneAction = () => async dispatch => {
    dispatch({
        type: buildActionType(UPLOAD_USERS, ActionStatus.DONE),
    });
};
export const resetUploadUserTemplateAction = () => async dispatch => {
    dispatch({
        type: buildActionType(UPLOAD_USERS, ActionStatus.RESET),
    });
};

export const downloadTemplateAction = token => async dispatch => {
    await downloadTemplateApiRequest(token);
};

export const downloadUsersFullTemplateAction = token => async dispatch => {
    await downloadUsersFullTemplateApiRequest(token);
};

export const downloadActiveUsersForMonthAction = (from, to) => async dispatch => {
    await downloadActiveUsersForMonthApiRequest(from, to);
};

export const getActiveUsersCountForMonthAction = (from, to) => async dispatch => {
    await dispatch(ApiExecutor(getActiveUsersCountForMonthApiRequest(from, to), GET_ACTIVE_USERS_COUNT_FOR_MONTH));
};

export const changePasswordAction = data => async dispatch => {
    dispatch({
        type: buildActionType(CHANGE_PASSWORD, ActionStatus.START),
    });

    let response = await changePasswordApiRequest(data);

    if (response.success) {
        dispatch({ type: buildActionType(CHANGE_PASSWORD, ActionStatus.DONE), payload: response.data });
    }
};

export const changePasswordForUserAction = (id, data) => async dispatch => {
    dispatch({
        type: buildActionType(CHANGE_PASSWORD, ActionStatus.START),
    });

    let response = await changePasswordForUserApiRequest(id, data);

    if (response.success) {
        dispatch({ type: buildActionType(CHANGE_PASSWORD, ActionStatus.DONE), payload: response.data });
    } else {
        dispatch({ type: buildActionType(CHANGE_PASSWORD, ActionStatus.FAILED), payload: response.data });
    }
};

export const resetChangePasswordForUserAction = () => async dispatch => {
    dispatch({ type: buildActionType(CHANGE_PASSWORD, ActionStatus.RESET) });
};

export const showNextErrorAction = error => async dispatch => {
    dispatch({ type: buildActionType(HANDLE_NEXT_ERROR, ActionStatus.DONE), payload: error });
};

export const resetNextErrorAction = () => async dispatch => {
    dispatch({ type: buildActionType(HANDLE_NEXT_ERROR, ActionStatus.RESET) });
};

export const setUsersInvited = users => async dispatch => {
    dispatch({ type: buildActionType(HANDLE_USERS_INVITE, ActionStatus.DONE), payload: users });
};

export const setAdminsInvited = users => async dispatch => {
    dispatch({ type: buildActionType(HANDLE_ADMINS_INVITE, ActionStatus.DONE), payload: users });
};

export const resetUsersInvitedAction = () => async dispatch => {
    dispatch({ type: buildActionType(HANDLE_USERS_INVITE, ActionStatus.RESET) });
};

export const resetAdminsInvitedAction = () => async dispatch => {
    dispatch({ type: buildActionType(HANDLE_ADMINS_INVITE, ActionStatus.RESET) });
};
export const resetForgotPasswordRequestAction = () => async dispatch => {
    dispatch({ type: buildActionType(FORGOT_PASSWORD_REQUEST, ActionStatus.RESET) });
};

export const resetForgotPasswordSetNewAction = () => async dispatch => {
    dispatch({ type: buildActionType(FORGOT_PASSWORD_SET_NEW, ActionStatus.RESET) });
};

export const resetEditUserAction = () => async dispatch => {
    dispatch({ type: buildActionType(EDIT_USER, ActionStatus.RESET) });
};

// User devices

export const fetchUserDevicesPaginatedAction = queryData => async dispatch => {
    await dispatch(ApiExecutor(fetchUserDevicesPaginatedApiRequest(queryData), GET_DEVICE_REQUESTS_PAGINATED));
};
export const resetFetchUserDevicesPaginatedAction = () => dispatch => {
    dispatch({ type: buildActionType(GET_DEVICE_REQUESTS_PAGINATED, ActionStatus.RESET) });
};

export const resolveDeviceRequestsBulkAction = data => async dispatch => {
    await dispatch(ApiExecutor(resolveDeviceRequestsBulkApiRequest(data), RESOLVE_DEVICE_REQUESTS_BULK));
};
export const resetResolveDeviceRequestsBulkAction = () => dispatch => {
    dispatch({ type: buildActionType(RESOLVE_DEVICE_REQUESTS_BULK, ActionStatus.RESET) });
};

export const deleteDeviceRequestsBulkAction = data => async dispatch => {
    await dispatch(ApiExecutor(deleteDeviceRequestsBulkApiRequest(data), DELETE_DEVICE_REQUESTS_BULK));
};
export const resetDeleteDeviceRequestsBulkAction = () => dispatch => {
    dispatch({ type: buildActionType(DELETE_DEVICE_REQUESTS_BULK, ActionStatus.RESET) });
};

export const getDeviceRequestsForUserAction = userId => async dispatch => {
    await dispatch(ApiExecutor(getDeviceRequestsForUserApiRequest(userId), GET_DEVICE_REQUESTS_FOR_USER));
};

export const resetGetDeviceRequestsForUserAction = () => dispatch => {
    dispatch({ type: buildActionType(GET_DEVICE_REQUESTS_FOR_USER, ActionStatus.RESET) });
};
