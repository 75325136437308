import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsLoading, stateIsNotInitialized } from 'redux/core/stateHelpers';
import { modifyDevicesModalContent } from './ModifyDevicesModalContent';
import { deleteDeviceRequestsBulkAction } from 'redux/actions/users.actions';

export function DeleteUserDeviceRequestModal({ modalOpenAction, setModalOpenAction, onSuccess, onClose, userIds }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const deleteDeviceRequestsState = useSelector(state => state.content.deleteDeviceRequests);

    const contentItem = modifyDevicesModalContent['delete' + (stateIsLoaded(deleteDeviceRequestsState) ? '-success' : '')];
    return (
        <ModalDialog
            modalOpen={modalOpenAction}
            setModalOpen={setModalOpenAction}
            onAction={() => {
                if (stateIsNotInitialized(deleteDeviceRequestsState)) {
                    dispatch(deleteDeviceRequestsBulkAction(userIds));
                } else {
                    if (onSuccess) {
                        onSuccess();
                    }
                    setModalOpenAction(null);
                }
            }}
            image={contentItem?.icon}
            onClose={() => {
                if (onClose) {
                    onClose();
                }
                if (stateIsLoaded(deleteDeviceRequestsState)) {
                    if (onSuccess) {
                        onSuccess();
                    }
                }
            }}
            title={t(contentItem?.title)}
            imageSize={70}
            text={stateIsLoading(deleteDeviceRequestsState) ? 'Loading' : contentItem?.description}
            buttons={[
                {
                    title: t(contentItem?.button),
                    color: colors.white,
                    disabled: stateIsLoading(deleteDeviceRequestsState),
                },
            ]}
            hasCancel={true}
        ></ModalDialog>
    );
}

DeleteUserDeviceRequestModal.propTypes = {
    bulkQuestionSize: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
