import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoading } from 'redux/core/stateHelpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { uploadUserTagsAction } from 'redux/actions/tags.actions';
import { downloadUserTagsTemplate } from 'services/api/apiRequests/content';
import { useTranslation } from 'react-i18next';

export const UploadUserTags = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const uploadUserTagsState = useSelector(state => state.content.uploadUserTags);
    const [file, setFile] = useState('');

    function fileUpload() {
        dispatch(uploadUserTagsAction(file));
        setFile('');
    }
    function downloadTemplate() {
        downloadUserTagsTemplate();
    }

    const handleFileChange = files => {
        if (files.length > 0) {
            setFile(files[0]);
        }
    };

    return (
        <div className={'row justify-content-center p-2'}>
            <div className="col-12 mt-2 d-flex justify-content-center">
                <Button
                    onClick={downloadTemplate}
                    className="download-template-users"
                    variant="contained"
                    color="secondary"
                    startIcon={<GetAppIcon />}
                >
                    {t('Download template')}
                </Button>
                {stateIsLoading(uploadUserTagsState) && (
                    <CircularProgress style={{ position: 'absolute', zIndex: 2, marginLeft: 10 }} size={35} className={'text-info'} />
                )}
                <Button
                    disabled={stateIsLoading(uploadUserTagsState) || file.length < 1}
                    onClick={fileUpload}
                    className={'ml-2'}
                    style={{ textTransform: 'none' }}
                    variant="contained"
                    color="secondary"
                    startIcon={<PublishIcon />}
                >
                    {t('Upload user tags')}
                </Button>
            </div>
            <div className="col-12 p-4 pt-2">
                <div className="mx-3">
                    <FileUploader
                        dropzoneText={<Typography variant="subtitle1">{t('Drag and drop the file here or click to upload')}</Typography>}
                        acceptedFiles={['.xlsx']}
                        filesLimit={1}
                        onChange={handleFileChange}
                        dropzoneClass="user-tags-upload"
                    ></FileUploader>
                </div>
            </div>
        </div>
    );
};
