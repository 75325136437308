import React from 'react';

import placeholder from 'assets/game-previews/sortit-emptycard-big.png';

export default function SortItCardReel({ randomImages }) {
    return (
        <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
            {randomImages.map((ri, index) => (
                <div
                    style={{
                        width: `${100 - index * 10}px`,
                        height: '100px',
                        backgroundColor: '#bfbfbf',
                        position: 'absolute',
                        bottom: `${index * 10 + (index * 20) / (index + 1) ** 2}px`,
                        border: '1px solid white',
                        zIndex: 10 - index,
                        borderRadius: '10px',
                        overflow: 'hidden',
                    }}
                    key={`card-reels-${index}`}
                >
                    {!ri?.image && (
                        <img id={`reel-image-placeholder-${index}`} src={placeholder} alt="preview" className="category-images-preview-card-reel" />
                    )}
                    {ri?.image && <ReelImage file={ri?.image?.urlRatio1x1} index={index} />}
                </div>
            ))}
        </div>
    );
}

const ReelImage = ({ file, index }) => {
    return <img id={`reel-image-${index}`} src={file} alt="preview" className="category-images-preview-card-reel" />;
};
