import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { Body1 } from 'style/typography/Body';
import { Subtitle2 } from 'style/typography/Subtitle';

import Questions from 'assets/icons/topic-icon-questions.svg';
import File from 'assets/icons/topic-icon-paper.svg';

export default function TrainingSelectorItem({ training, setSelectedTraining, setIsOpen }) {
    return (
        <div
            id="already-existing-training"
            key={training.setId ?? training.generatedSetId}
            onClick={() => {
                setSelectedTraining(training.setId ?? training.generatedSetId);
                setIsOpen(false);
            }}
            style={{ backgroundColor: colors.white }}
            className={'training-dropdown--card'}
        >
            <div className="d-flex flex-column justify-content-center" style={{ width: '100%' }}>
                <Subtitle2 style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} className="mb-2">
                    {training.name}
                </Subtitle2>
                <div className="d-flex flex-row mt-2 position-relative">
                    <div className="d-flex flex-row align-items-center mr-4">
                        <img alt="File" style={{ height: 20, width: 20 }} className={'mr-1'} color={colors.gray1} src={File} />
                        <Body1 color={colors.gray1} className={'mb-0 mr-2'}>
                            {training.topics > 0 ? training.topics - 1 : 0}
                        </Body1>
                    </div>
                    <div className="d-flex flex-row align-items-center" style={{ position: 'absolute', left: 80 }}>
                        <img alt="Questions" style={{ height: 20, width: 20 }} className={'mr-2'} color={colors.gray1} src={Questions} />
                        <Body1 color={colors.gray1} className={'mb-0 mr-2'}>
                            {training.questions}
                        </Body1>
                    </div>
                </div>
            </div>
        </div>
    );
}

TrainingSelectorItem.propTypes = {
    setSelectedTraining: PropTypes.func,
    trainings: PropTypes.object,
};
