import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Checkbox from 'components/elements/checkbox/Checkbox';
import PaginationComponent from 'components/features/data_table/pagination_component/PaginationComponent';
import { TableCellContent, TableHeader } from 'components/features/data_table/TableElements';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { useTableSelections } from 'services/custom-hooks/useTableSelections';
import { colors } from 'style/colors';
import { Subheader } from 'components/features/data_table/subheader/Subheader';
import { usePagination } from 'components/features/data_table/pagination_component/usePagination';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';
import { H6 } from 'style/typography/Heading';
import { customStylesTable } from 'style/elements/table';
import { DefaultButton } from 'components/elements/Button';
import { addRolesInitialState } from 'static/filters';
import { fetchRolesPaginatedAction, resetFetchRolesPaginatedAction, updateRolesInTrainingAction } from 'redux/actions/roles.actions';
import { useFilters } from 'components/features/data_table/pagination_component/useFilters';
import { useTranslation } from 'react-i18next';
import { trainingRolePickerSubheaders } from './TrainingRolePickerSubheaders';
import { getAllTrainingsAction } from 'redux/actions/trainings.actions';
import { Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import _ from 'lodash';

import CaretTable from 'assets/icons/caret-table.svg';
import Close from 'assets/icons/filter-close.svg';

import '../TrainingSettings.css';

export default function RolePickerDialog({ modalOpen, setModalOpen }) {
    const rolesPaginatedState = useSelector(state => state.userManagement.rolesPaginated);
    const setInfo = useSelector(state => state.content.singleTraining.data.learningSetInfo);
    const allTrainingsState = useSelector(state => state.content.allTrainings);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        addSelectedRow,
        removeSelectedRow,
        setAllQuestionsSelected,
        selectedRows,
        filteredData,
        selectedRowsArr,
        setSelectedRows,
    } = useTableSelections({
        paginatedState: rolesPaginatedState,
        rowSelector: row => row.roleId,
    });

    const classes = useStyles();

    const dataLoading = stateIsLoading(rolesPaginatedState);

    const { filtersState, activeFilters, dispatchFilters, updateFilterState } = useFilters({
        initialFiltersState: { ...addRolesInitialState, size: { value: 8 } },
    });
    const filters = {
        page: filtersState.page,
        searchQuery: filtersState.searchQuery,
        trainingId: filtersState.trainingId,
        size: filtersState.size,
        orderBy: filtersState.sortParam?.selector,
        ascending: filtersState.sortParam?.ascending,
    };

    const { getPrevious, getNext, getPage } = usePagination({
        paginatedState: rolesPaginatedState,
        currentPage: filters.page,
        setCurrentPage: page => dispatchFilters({ page: page }),
    });

    const modifiedRoles = useRef({});
    const [allTrainings, setAllTrainings] = useState([]);

    const handleSort = async (column, sortDirection) => {
        if (selectedRowsArr.length === 0) {
            dispatchFilters({ page: 0, sortParam: { selector: column.selector, ascending: sortDirection == 'asc' ? 'true' : 'false' } });
        }
    };

    const updateRolesLocally = () => {
        dispatch(updateRolesInTrainingAction(modifiedRoles.current, setInfo.setId, setInfo.name, true));
        dispatch(resetFetchRolesPaginatedAction());
        setModalOpen(false);
    };

    useEffect(() => {
        dispatch(
            fetchRolesPaginatedAction({
                ...filters,
                page: filters.page,
            })
        );
    }, [filtersState]);

    useEffect(() => {
        if (!stateIsLoaded(allTrainingsState) && !stateIsLoading(allTrainingsState) && !stateHasFailed(allTrainingsState)) {
            dispatch(getAllTrainingsAction());
        } else if (stateIsLoaded(allTrainingsState)) {
            setAllTrainings(
                allTrainingsState.data.learningSets.map((el, i) => {
                    return { value: el.learningSetInfo.setId, label: el.learningSetInfo.name };
                })
            );
        }
    }, [allTrainingsState]);

    useEffect(() => {
        if (stateIsLoaded(rolesPaginatedState)) {
            var selectedRolesObject = _.cloneDeep(selectedRows);
            for (var i = 0; i < rolesPaginatedState.data.content.length; ++i) {
                let setId = rolesPaginatedState.data.content[i].connectedSet;
                let roleId = rolesPaginatedState.data.content[i].roleId;
                let notInModified = !(roleId.toString() in modifiedRoles.current);

                if (notInModified && setId == setInfo?.setId && !(setId in selectedRolesObject)) {
                    selectedRolesObject[roleId] = { selected: true };
                }
            }
            setSelectedRows(selectedRolesObject);
        }
    }, [rolesPaginatedState]);

    const columns = [
        {
            name: '',
            width: '50px',
            cell: row => {
                return (
                    <div style={{ height: '100%', width: '100%' }}>
                        <Checkbox
                            onClick={e => {
                                if (!row.selected) {
                                    modifiedRoles.current[row.roleId] = true;
                                    addSelectedRow(row);
                                } else {
                                    removeSelectedRow(row);
                                    modifiedRoles.current[row.roleId] = false;
                                    setAllQuestionsSelected(false);
                                }
                            }}
                            checked={row.selected}
                            disabled={row.connectedSet && row.connectedSet != setInfo?.setId}
                        ></Checkbox>
                    </div>
                );
            },
        },
        {
            name: <TableHeader text={t('Role')} />,
            selector: 'roleName',
            sortable: 'true',
            wrap: true,
            width: '55%',
            fontWeight: '800w',
            cell: row => (
                <TableCellContent
                    text={
                        <div>
                            <Body2 color={colors.gray6}>{row.roleName}</Body2>
                            <Caption1 color={colors.gray1}>
                                {row.connectedSet && row.connectedSet != setInfo?.setId ? ' (already used in another training)' : ''}
                            </Caption1>
                        </div>
                    }
                    textColor={row.connectedSet && row.connectedSet != setInfo?.setId ? colors.gray1 : colors.gray6}
                    isLoading={dataLoading}
                />
            ),
        },
        {
            name: <TableHeader text={t('Users in role')} />,
            selector: 'usersInRole',
            sortable: 'true',
            width: '20%',
            cell: row => <TableCellContent isLoading={dataLoading} text={row.usersInRole} />,
        },
        {
            name: <TableHeader text={t('Active training')} />,
            selector: 'connectedSetName',
            sortable: 'true',
            width: '20%',
            cell: row => <TableCellContent isLoading={dataLoading} text={row.connectedSetName} />,
        },
    ];

    return (
        <Modal
            open={modalOpen}
            className={classes.modal}
            onClose={() => {
                setModalOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="user-picker-dialog--container">
                <div className="d-flex flex-row align-content-center my-2">
                    <H6>{t("Select one or more roles for the training : '{{trainingName}}'", { trainingName: setInfo?.name })}</H6>
                    <img
                        alt="Close"
                        className="ml-auto cursor-pointer"
                        src={Close}
                        height={20}
                        width={20}
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    />
                </div>
                <DataTable
                    sortIcon={selectedRowsArr.length === 0 ? <img height={15} width={13} className="ml-2" src={CaretTable} alt="sort"></img> : <></>}
                    columns={columns}
                    onSort={handleSort}
                    sortServer
                    data={filteredData}
                    overflowY={'visible'}
                    overflowYOffset={'0px'}
                    defaultSortField="name"
                    className="overflow-visible"
                    subHeader={true}
                    paginationPerPage={100}
                    pagination={true}
                    selectableRows={false}
                    highlightOnHover={true}
                    subHeaderComponent={
                        <Subheader
                            inputs={trainingRolePickerSubheaders({
                                filtersState,
                                dispatchFilters,
                                allTrainings,
                                resetBulkActions: () => {},
                            })}
                        />
                    }
                    subHeaderAlign={'left'}
                    customStyles={customStylesTable}
                    theme={'tableTheme'}
                    selectableRowSelected={row => row.selected}
                    noHeader={true}
                    paginationComponent={_ => (
                        <PaginationComponent
                            getPrev={getPrevious}
                            getNext={getNext}
                            getPage={getPage}
                            currentPage={filters.page}
                            totalPages={rolesPaginatedState.data.totalPages}
                            totalItems={rolesPaginatedState?.data?.totalElements}
                            canChangeSize={false}
                            size={filters.size}
                        />
                    )}
                ></DataTable>
                <div className="user-picker-dialog--buttons">
                    <DefaultButton
                        backgroundColor={colors.white}
                        textColor={colors.dark}
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        Cancel
                    </DefaultButton>
                    <DefaultButton
                        textColor={colors.white}
                        style={{ marginLeft: 15 }}
                        onClick={() => {
                            updateRolesLocally();
                        }}
                    >
                        {t('Update Roles')}
                    </DefaultButton>
                </div>
            </div>
        </Modal>
    );
}

RolePickerDialog.propTypes = {
    modalOpen: PropTypes.any,
    onConfirm: PropTypes.func,
    setModalOpen: PropTypes.func,
};

const useStyles = makeStyles(theme => {
    return {
        modal: {
            display: 'flex',
            alignItems: 'center',
            width: '90%',
            marginLeft: '5%',
            justifyContent: 'center',
        },
    };
});
