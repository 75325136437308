import { questionTypes } from './local-constants';

export const questionTypeAnswerMappers = {
    [questionTypes.CHECKBOXES_WITH_IMAGE]: item => ({
        text: item.text,
        isCorrect: item.isCorrect,
        answerId: item.answerId,
    }),
    [questionTypes.CHECKBOXES]: item => ({
        text: item.text,
        isCorrect: item.isCorrect,
        answerId: item.answerId,
    }),
    [questionTypes.COMPARE_VISUAL]: item => ({
        text: item.text,
        images: item.images,
        answerId: item.answerId,
    }),
    [questionTypes.ANSWER_TEXT]: item => ({
        text: item.text,
        answerId: item.answerId,
    }),
    [questionTypes.IMAGE_CATEGORIES]: item => ({
        text: item.text,
        images: item.images,
        answerId: item.answerId,
    }),
    [questionTypes.NUMERICAL]: item => ({
        text: item.text,
        answerId: item.answerId,
    }),
    [questionTypes.POSITIONED]: item => ({
        text: item.text,
        answerId: item.answerId,
        position: item.position,
    }),
    [questionTypes.DEFAULT]: item => ({
        text: item.text,
        answerId: item.answerId,
    }),
};

export const questionTypeAnswerFilters = {
    [questionTypes.CHECKBOXES_WITH_IMAGE]: item => item.text,
    [questionTypes.CHECKBOXES]: item => item.text,
    [questionTypes.COMPARE_VISUAL]: item => item.text && item.images,
    [questionTypes.ANSWER_TEXT]: item => item.text,
    [questionTypes.IMAGE_CATEGORIES]: item => item.text && item.images.length > 0,
    [questionTypes.NUMERICAL]: item => item.text,
    [questionTypes.POSITIONED]: item => item.text,
    [questionTypes.DEFAULT]: item => item.text,
};

export const getQuestionTypeOptions = {
    [questionTypes.CHECKBOXES_WITH_IMAGE]: item => item.options,
    [questionTypes.CHECKBOXES]: item => item.options,
    [questionTypes.COMPARE_VISUAL]: item => item.options,
    [questionTypes.ANSWER_TEXT]: item => item.options,
    [questionTypes.IMAGE_CATEGORIES]: item => item.options,
    [questionTypes.NUMERICAL]: item => item.options,
    [questionTypes.POSITIONED]: item => item.text,
    [questionTypes.DEFAULT]: item => item.text,
};
