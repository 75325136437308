import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

export default function GalleryFolderTab({ icon, iconWhite, text, selected, onClick, count }) {
    const { t } = useTranslation();

    return (
        <div
            onClick={onClick}
            className="d-flex flex-row align-items-center cursor-pointer"
            style={{ background: selected ? colors.mainTheme : 'transparent', width: '90%', marginTop: '10px' }}
        >
            <img alt="Folder" src={selected ? iconWhite : icon} style={{ width: 35, height: 30, margin: '3px 0', paddingLeft: '10px' }} />

            <Body2 weight={selected ? 'medium' : 'regular'} style={{ marginLeft: '10px' }}>
                {t(text)}
            </Body2>
            <Body2
                style={{
                    marginLeft: 'auto',
                    padding: '0 10px',
                    borderRadius: 5,
                    background: selected ? colors.white_transparent : colors.white,
                    marginRight: 10,
                }}
            >
                {count}
            </Body2>
        </div>
    );
}

GalleryFolderTab.propTypes = {
    icon: PropTypes.any,
    iconWhite: PropTypes.any,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    text: PropTypes.any,
};
