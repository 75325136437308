import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from 'containers/layout/module/local_components/header/Header';
import { Circular } from 'components/features/data_table/Circular';
import { useTranslation } from 'react-i18next';
import UserDeviceRequestViewPreview from './UserDeviceRequestViewPreview';
import UserDeviceRequestInfo from './UserDeviceRequestInfo';
import { ModifyUserDeviceRequestModal } from './modals/ModifyUserDeviceRequestModal';

import '../UserDeviceRequests.css';
import { stateIsLoading } from 'redux/core/stateHelpers';
import {
    getDeviceRequestsForUserAction,
    resetDeleteDeviceRequestsBulkAction,
    resetResolveDeviceRequestsBulkAction,
} from 'redux/actions/users.actions';
import { DeleteUserDeviceRequestModal } from './modals/DeleteUserDeviceRequestModal';

export function UserDeviceRequestView({ history }) {
    const { id } = useParams();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const deviceRequestsForUserState = useSelector(state => state.content.deviceRequestsForUser);

    const [modalOpenAction, setModalOpenAction] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const deviceRequest = {};

    useEffect(() => {
        dispatch(getDeviceRequestsForUserAction(id));
        dispatch(resetResolveDeviceRequestsBulkAction());
        dispatch(resetDeleteDeviceRequestsBulkAction());
    }, []);

    if (stateIsLoading(deviceRequestsForUserState)) {
        return (
            <div className="device-req-view--loading">
                <Circular />
            </div>
        );
    }

    return (
        <Header title={t(`User device request #{{id}}`, { id: 2 })} stateDependencies={[deviceRequestsForUserState]}>
            <ModifyUserDeviceRequestModal modalOpenAction={modalOpenAction} setModalOpenAction={setModalOpenAction} />
            <DeleteUserDeviceRequestModal
                modalOpenAction={deleteModalOpen}
                setModalOpenAction={setDeleteModalOpen}
                userIds={[id]}
                onSuccess={() => {
                    dispatch(resetDeleteDeviceRequestsBulkAction());
                    history.replace('/users/user-device-request');
                }}
            />

            <div className="device-req-view">
                <UserDeviceRequestViewPreview
                    deviceRequest={deviceRequest}
                    history={history}
                    setModalOpenAction={setModalOpenAction}
                    setDeleteModalOpen={setDeleteModalOpen}
                />
                <UserDeviceRequestInfo
                    deviceRequest={deviceRequest}
                    history={history}
                    setModalOpenAction={setModalOpenAction}
                    setDeleteModalOpen={setDeleteModalOpen}
                />
            </div>
        </Header>
    );
}
