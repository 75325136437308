import React from 'react';
import { colors } from 'style/colors';
import { FeedbackResolveDialogContent } from './local_components/FeedbackResolveDialogContent';

export const unresolvedFeedbackModalData = () => {
    let text =
        "If you have fixed the issue or addressed the problem the user had, mark this feedback as resolved. An automatic message will be sent to the user's email in order to notify them that their feedback has been resolved.";
    let title = 'Resolve this feedback?';
    let button = {
        title: 'Resolve',
        color: colors.mainTheme,
    };
    let contentComponent = <FeedbackResolveDialogContent text={text}></FeedbackResolveDialogContent>;
    return {
        text: text,
        title: title,
        buttons: [button],
        contentComponent: contentComponent,
    };
};

export const resolvedFeedbackModalData = () => {
    let text = 'If you need to reassess this issue again or a new problem regarding the issue has recently came up, mark it as unresolved.';
    let title = 'Unresolve this feedback';
    let button = {
        title: 'Unresolve',
        color: colors.white,
    };

    return {
        text: text,
        title: title,
        buttons: [button],
    };
};

export const deleteFeedbackModalData = () => {
    let text = "If you delete this feedback, you won't be able to see it again.";
    let title = 'Delete this feedback?';
    let button = {
        title: 'Delete',
        color: colors.white,
    };
    return {
        text: text,
        title: title,
        buttons: [button],
    };
};
