import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import { InputContentWrapper } from 'components/wrappers/InputContentWrapper';
import { PinpointInputs } from './PinpointInputs';
import { PinpointPreview } from './PinpointPreview';

const AddQuestionPinpoint = ({ adding, game, id, filters }) => {
    const { extra, setExtra } = useMultipleChoiceAnswers();
    const [image, setImage] = useState(false);

    const removeImage = () => {
        setImage(false);
        setExtra(null);
    };

    useEffect(() => {
        if (extra?.image) {
            setImage({ url: extra?.image.urlRatio2x1 });
        }
    }, [extra]);

    return (
        <div className="d-flex flex-row h-100 m-0">
            <InputContentWrapper id={id} filters={filters}>
                <div className="d-flex flex-row w-100 h-100">
                    <PinpointInputs adding={adding} game={game} id={id} filters={filters} image={image} removeImage={removeImage} />
                    <PinpointPreview image={image} />
                </div>
            </InputContentWrapper>
        </div>
    );
};

AddQuestionPinpoint.propTypes = {
    adding: PropTypes.any,
    filters: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
};

export default AddQuestionPinpoint;
