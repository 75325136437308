
const ASSETS_ENDPOINT = process.env.REACT_APP_COACH_ASSETS_ENDPOINT;

export const testAbsoluteUrl = url => {
    let r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
};

export const imageLink = (link, sizeType) => {
    if (testAbsoluteUrl(link)) return link;
    else {
        return `${ASSETS_ENDPOINT}${link}`;
    }
};

export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] == variable) {
            return decodeURIComponent(pair[1].replaceAll("+", " "));
        }
    }
    return false;
}
export const removeQuery = (key, history) => {
    let pathname = window.location.pathname;
    // returns path: '/app/books'
    let searchParams = new URLSearchParams(window.location.search);
    // returns the existing query string: '?type=fiction&author=fahid'
    searchParams.delete(key);
    history.replace({
        pathname: pathname,
        search: searchParams.toString()
    });
};

export const addQuery = (key, value, history) => {
    let pathname = window.location.pathname;
    // returns path: '/app/books'
    let searchParams = new URLSearchParams(window.location.search);
    // returns the existing query string: '?type=fiction&author=fahid'
    searchParams.set(key, value);
    history.replace({
        pathname: pathname,
        search: searchParams.toString()
    });
};

export function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}

export const convertArrayToQueryVariables = (array) => {
    let string = '';

    array.forEach((el,index) => {
        string += el;

        if (index !== array.length - 1){
            string += ","
        }
    })

    return string;
}
