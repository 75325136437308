import UserProfile from 'containers/layout/module/pages/users/user_profile/UserProfile';
import MultipleUsersInvite from 'containers/not_logged_in_pages/invite_users_flow/MultipleUsersInvite.js';
import { UploadUsers } from 'containers/not_logged_in_pages/invite_users_flow/UploadUsers';
import Content from 'containers/layout/module/pages/content/Content';
import { Logout } from '../../../not_logged_in_pages/Logout';
import UpdateQuestion from 'containers/layout/module/pages/content/questions/question_editor/UpdateQuestion';
import { AddQuestion } from 'containers/layout/module/pages/content/questions/question_editor/AddQuestion';
import { Company } from 'containers/layout/module/pages/company/Company';

import Users from 'containers/layout/module/pages/users/Users';
import {
    addQuestionCollection,
    companyCollection,
    contentCollection,
    dashboardCollection,
    rolesCollection,
    updateQuestionCollection,
} from 'redux/constants/collections';
import CreateNotification from '../pages/internal_messaging/notifications/create_notification/CreateNotification';

import ContentBlock from '../pages/content/content_blocks/content_block/ContentBlock';
import { RolesWrapper } from '../pages/company/roles/RolesWrapper';
import AITraining from '../pages/ai_training/AITraining';
import Dashboard from '../pages/dashboard/Dashboard';
import { CreateAndEditPost } from '../pages/internal_messaging/news/edit_news/CreateAndEditPost';
import InternalMessaging from '../pages/internal_messaging/InternalMessaging';
import { FeedbackView } from '../pages/internal_messaging/feedback/view/FeedbackView';
import NotFound from 'containers/NotFound';
import { UserDeviceRequestView } from '../pages/users/user_device_requests/local_components/UserDeviceRequestView';

export const routes = [
    {
        title: 'Invite multiple users',
        path: 'users/invite/multiple',
        Component: MultipleUsersInvite,
        exact: true,
        dependencies: [],
    },
    {
        title: 'Upload users',
        path: 'users/invite/upload',
        Component: UploadUsers,
        exact: true,
        dependencies: [],
    },
    {
        title: 'User details',
        path: 'user/:id',
        Component: UserProfile,
        exact: true,
        dependencies: [],
    },
    {
        title: 'users',
        path: 'users/:type',
        Component: Users,
        exact: true,
        dependencies: [],
    },
    {
        title: 'View feedback',
        path: 'user-device-request/:id/view',
        Component: UserDeviceRequestView,
        exact: true,
        dependencies: [],
    },
    {
        title: 'company',
        path: 'company/:tab',
        Component: Company,
        exact: true,
        dependencies: companyCollection,
    },
    {
        title: 'Update question',
        path: 'content/questions/update/:questionId',
        Component: UpdateQuestion,
        exact: true,
        dependencies: updateQuestionCollection,
        excludeGlobalCalls: true,
    },

    {
        title: 'Add question',
        path: 'content/questions/add/:game',
        Component: AddQuestion,
        exact: true,
        dependencies: addQuestionCollection,
    },
    {
        title: 'Create news',
        path: 'internal/news/create',
        Component: CreateAndEditPost,
        exact: true,
    },
    {
        title: 'Edit news',
        path: 'internal/news/edit/:newsId',
        Component: CreateAndEditPost,
        exact: true,
    },
    {
        title: 'Create notification',
        path: 'internal/notifications/create',
        Component: CreateNotification,
        exact: true,
        // dependencies: [],
    },
    {
        title: 'Notification preview',
        path: 'internal/notifications/preview/:notificationId',
        // Component: CreateNotification,
        exact: true,
    },
    {
        title: 'View notification',
        path: 'internal/notifications/:notificationId/edit',
        Component: CreateNotification,
        exact: true,
    },
    // {
    //     title: 'content Questions',
    //     route: 'content/questions',
    //     Component: ContentQuestions,
    // },

    {
        title: 'Content',
        path: 'content/:type',
        Component: Content,
        exact: true,
        dependencies: contentCollection,
    },

    // {
    //     title: 'settings',
    //     route: 'settings',
    //     Component: settings,
    // },

    {
        title: 'Dashboard',
        path: 'dashboard',
        Component: Dashboard,
        exact: true,
        dependencies: dashboardCollection,
    },
    // {
    //     title: 'content blocks',
    //     route: 'content_blocks',
    //     Component: content_blocks,
    // },

    {
        title: 'View feedback',
        path: 'internal/feedback/:id/view',
        Component: FeedbackView,
        exact: true,
        dependencies: [],
    },
    {
        title: 'Internal Messaging',
        path: 'internal/:type',
        Component: InternalMessaging,
        exact: true,
        dependencies: [],
    },
    {
        title: 'Roles',
        path: 'company/roles',
        Component: RolesWrapper,
        exact: true,
        dependencies: rolesCollection,
    },
    {
        title: 'AI Training',
        path: 'ai_training',
        Component: AITraining,
        exact: true,
        dependencies: [],
    },
    {
        title: 'Content Block',
        path: 'content_blocks/:id',
        Component: ContentBlock,
        exact: true,
        dependencies: [],
    },
    {
        title: 'Logout',
        path: 'logout',
        Component: Logout,
        exact: true,
        dependencies: [],
    },
    {
        title: 'Home',
        path: '',
        Component: NotFound,
        exact: true,
        dependencies: [],
    },
];
