export const actionTypes = {
    setContentBlock: 'SET_CONTENT_BLOCK',
    setRowsToShow: 'SET_ROWS_TO_SHOW',
    setColumnWidths: 'SET_COLUMN_WIDTHS',
    setSelectedEdges: 'SET_SELECTED_EDGES',
    setFirstCell: 'SET_FIRST_CELL',
    setCurrentCell: 'SET_CURRENT_CELL',
    resetState: 'RESET_STATE',
    setHistory: 'SET_HISTORY',
    setState: 'SET_STATE',
    updateColumn: 'UPDATE_COLUMN',
    updateCell: 'UPDATE_CELL',
    undo: 'UNDO',
    addRow: 'ADD_ROW',
    addColumn: 'ADD_COLUMN',
    deleteColumn: 'DELETE_COLUMN',
    setColWidthTo: 'SET_COL_WIDTH_TO',
    pasteMultipleValuesInRow: 'PASTE_MULTIPLE_VALUES_IN_ROW',
    addImages: 'ADD_IMAGES',
    removeImage: 'REMOVE_IMAGE',
};
