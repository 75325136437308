import React from 'react';

import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

import CheckmarkOrange from 'assets/icons/check-orange.svg';
import ErrorIcon from 'assets/icons/error-icon.svg';

import '../GenerationTabs.css';

export default function GenerationStatusItem({ text, status }) {
    const hasStatus = status === 'loading' || status === 'finished' || status === 'error';

    return (
        <div className="generation-status-item">
            {!hasStatus && <div className="generation-status-item--dot"></div>}
            {status === 'loading' && <CircularProgress size={15} thickness={5} />}
            {status === 'finished' && <img alt="Checkmark" src={CheckmarkOrange} height={15} width={15} />}
            {status === 'error' && <img alt="Error" src={ErrorIcon} height={15} width={15} />}
            <Body2 color={hasStatus ? colors.primaryText : colors.gray1}>{text}</Body2>
        </div>
    );
}
