import Paper from '@material-ui/core/Paper/Paper';
import { makeStyles } from '@material-ui/core';
import { colors } from 'style/colors';
import Modal from '@material-ui/core/Modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import Logo from 'assets/icons/coach-logo-black.svg';
import Collapse from '@material-ui/core/Collapse/Collapse';
import Alert from '@material-ui/lab/Alert/Alert';
import { resetExportQuestionsAction, resetExportQuestionsProgressAction } from 'redux/actions/content.actions';
import { H3 } from 'style/typography/Heading';
import { Body1 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';

export function ExportQuestionsModal({ open, setOpen }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [done, setDone] = useState(false);

    const exportQuestionsState = useSelector(state => state.content.exportAllQuestions);
    const exportQuestionsProgressState = useSelector(state => state.content.exportAllQuestionsProgress);

    useEffect(() => {
        if (stateIsLoaded(exportQuestionsState)) {
            setDone(true);
        }
    }, [dispatch, exportQuestionsState]);

    function resetModalState() {
        dispatch(resetExportQuestionsAction());
        dispatch(resetExportQuestionsProgressAction());
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            className={classes.modal}
            onClose={() => {
                resetModalState();
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper style={{ overflow: 'scroll' }} className={`${classes.paper} d-flex flex-column`}>
                <H3 className="text-center mt-4">{t('Export all questions')}</H3>
                <Body1 color={colors.gray6} className="text-center mb-5">
                    {t('Export status')}
                </Body1>
                <div style={{ flex: 1 }}>
                    {!done && (
                        <div key={0} style={{ display: 'block', height: '100%' }} className="text-center">
                            <div style={{ height: '40vh' }} className="row justify-content-center align-items-center">
                                <div className="d-flex flex-column w-50 text-center">
                                    <h6>{t('Exporting...')}</h6>
                                    <div className="progress bg-white w-100">
                                        <div
                                            className="progress-bar bg-warning"
                                            role="progressbar"
                                            style={{ width: `${exportQuestionsProgressState?.data?.progress}%` }}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {exportQuestionsProgressState?.data?.progress}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {done && (
                        <div className={'d-flex flex-column h-100'}>
                            <div style={{ height: '100%' }} className="text-center d-flex flex-column align-items-center justify-content-center">
                                <img className={'mt-3'} src={Logo} width={70} height={70}></img>
                                <Collapse className={'w-50 mt-3'} in={done}>
                                    <Alert severity="success"> {t("All questions successfully exported!")}</Alert>
                                </Collapse>
                            </div>
                            <div className={'text-center'}>
                                <button onClick={() => setOpen(false)} style={{ color: colors.mainTheme }} className={'btn btn-dark px-5'}>
                                    {t("Okay")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </Paper>
        </Modal>
    );
}

const useStyles = makeStyles(theme => {
    return {
        paper: {
            position: 'absolute',
            width: '70%',
            backgroundColor: theme.palette.background.main,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            height: '90vh',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            textAlign: 'center',
        },
        alertFixPaper: {
            maxWidth: '2000px',
            width: '600px',
        },
    };
});
