import React from 'react';

import ModulesEmptyIcon from 'assets/icons/emptystate-modules.svg';
import { H7 } from 'style/typography/Heading';
import { useTranslation } from 'react-i18next';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';

import './TrainingModulesWrapper.css';

export default function EmptyStateModules() {
    const { t } = useTranslation();

    return (
        <div className="training-module-empty-state">
            <img src={ModulesEmptyIcon} height={70} width={70} alt="no-modules" />
            <H7 color={colors.dark} weight="light">
                {t('Start saving subtrees as modules')}
            </H7>
            <Body2 color={colors.gray6}>
                {t(
                    'You can save entire subtrees of your training and have them in this tab ready to be used (duplicated) in another training. To do this, hover on the information tooltip next to the topic/subtree you want to save, and click on `Save subtree`'
                )}
            </Body2>
        </div>
    );
}
