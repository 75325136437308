import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shortcutsToIcon, shortcutsToString, shortcutsToPath } from 'static/shortcuts';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Search from 'assets/icons/filter-search.svg';
import ShortcutIcon from 'assets/icons/shortcut.svg';

import './Header.css';
import InfoTooltip from 'components/elements/tooltips/InfoTooltip';

const SearchShortcuts = ({ onMouseEnter, onMouseLeave, hovering, setHovering }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');

    const ref = useRef();

    function handleClickOutside(event) {
        if (isOpen && event.target && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    function filterItems(items) {
        if (!search) return items;

        return items.filter(el => {
            return t(shortcutsToString[el])
                .toLowerCase()
                .includes(search.toLowerCase());
        });
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div ref={ref}>
            <div
                className="header--action-icon"
                onClick={() => setIsOpen(true)}
                onMouseEnter={() => setHovering('search')}
                onMouseLeave={() => setHovering('')}
            >
                <div className="header--shortcuts">
                    <img alt="search" src={Search} className="header--shortcuts" />
                </div>
                {hovering === 'search' && !isOpen && (
                    <InfoTooltip
                        visible={hovering === 'search'}
                        message={t('Search for shortcuts to sections of the Admin.')}
                        position={'right'}
                        width={300}
                        marginRelative={0}
                    />
                )}
            </div>
            <div className="header--shortcuts-picker" style={{ display: isOpen ? 'flex' : 'none' }}>
                <input
                    placeholder={t('Type to search shortcuts...')}
                    className="header--shortcuts-input"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                <div className="header--shortcuts-picker-items">
                    {filterItems(Object.keys(shortcutsToIcon)).map(key => {
                        return (
                            <div
                                className="header--shortcuts-picker-item"
                                onClick={() => {
                                    history.push(shortcutsToPath[key]);
                                    setIsOpen(false);
                                }}
                            >
                                <img src={ShortcutIcon} height={30} width={30} alt="shortcut" />
                                <Body2 color={colors.dark}>{t(shortcutsToString[key])}</Body2>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default React.memo(SearchShortcuts);
