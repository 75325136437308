import React from 'react';

import toast from 'react-hot-toast';
import { Body1, Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import i18n from 'i18next';

import ErrorIcon from 'assets/icons/error-icon.svg';
import CloseIcon from 'assets/icons/filter-close.svg';
import CheckIcon from 'assets/icons/checkmark.svg';
import InfoIcon from 'assets/icons/info-tooltip.svg';

import './CustomToast.css';

export const IconType = {
    SUCCESS: 'success',
    FAIL: 'fail',
    INFO: 'info',
};

export const CustomToast = ({ t, iconType = IconType.FAIL, title = 'Some error occured syncing data', message }) => {
    return (
        <div className={`custom-toast ${t.visible ? 'animate-enter' : 'animate-leave'}`}>
            <div className="custom-toast--title">
                {iconType === IconType.FAIL && <img src={ErrorIcon} height={20} width={20} alt="error" />}
                {iconType === IconType.SUCCESS && (
                    <div className="custom-toast--icon success">
                        <img src={CheckIcon} height={15} width={15} alt="check" />
                    </div>
                )}
                {iconType === IconType.INFO && (
                    <div className="custom-toast--icon info">
                        <img src={InfoIcon} alt="check" />
                    </div>
                )}
                <Body1 color={colors.dark}>{i18n.t(title)}</Body1>
                <img src={CloseIcon} height={20} width={20} onClick={() => toast.dismiss(t.id)} alt="close" className="custom-toast--close" />
            </div>
            <div className="custom-toast--inner">
                <Body2 color={colors.gray6}>{message}</Body2>
            </div>
        </div>
    );
};
