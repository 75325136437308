import React, { useEffect, useMemo } from 'react';

import PhoneMockup from 'assets/icons/phone-mockup-dark.svg';

import './LearnPreview.css';
import { useDispatch, useSelector } from 'react-redux';
import { getNodeKey } from 'services/utils/treeHelpers';
import { getFlatDataFromTree } from 'components/features/trees/SortableTree';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import parse from 'html-react-parser';
import { getTopicContentAction, resetGetTopicContentAction } from 'redux/actions/topics.actions';

import LeftAngle from 'assets/icons/angle-left-dark.svg';
import { Caption2 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { getCompanyInfoAction } from 'redux/actions/content.actions';
import { useTranslation } from 'react-i18next';

export default function LearnPreview({ selectedNode, setSelectedNode }) {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const singleTrainingState = useSelector(state => state.content.singleTraining);
    const companyInfoState = useSelector(state => state.content.companyInfo);
    const topicContentState = useSelector(state => state.content.topics.topicContent);

    const topicsInOrder = useMemo(
        () =>
            getFlatDataFromTree({
                treeData: singleTrainingState?.data?.treeStructure,
                getNodeKey,
                ignoreCollapsed: false,
            }),
        [singleTrainingState]
    );

    const currentLessonContent = topicContentState?.data?.lessonContent;

    const isFirst = selectedNode?.treeIndex === topicsInOrder[0]?.node?.treeIndex;
    const isLast = selectedNode?.treeIndex === topicsInOrder[topicsInOrder.length - 1]?.node?.treeIndex;

    const isTopicEmpty = !isFirst && !currentLessonContent && selectedNode?.children?.length === 0;
    const topicNameForTraining = topicsInOrder.find(el => el.node?.treeIndex === topicContentState.data?.treeIndex)?.node?.titleForTraining;

    function goBack() {
        let parentNodeOfCurrentTopic = topicsInOrder.find(el => el.node?.treeIndex === topicContentState.data?.treeIndex)?.parentNode;

        if (parentNodeOfCurrentTopic) {
            dispatch(resetGetTopicContentAction());

            setSelectedNode(parentNodeOfCurrentTopic);
            dispatch(getTopicContentAction(parentNodeOfCurrentTopic.treeIndex));
        } else {
            setSelectedNode(topicsInOrder[0].node);
            dispatch(getTopicContentAction(topicsInOrder[0].node.treeIndex));
        }
    }

    function getNext() {
        let indexOfCurrentNode = topicsInOrder.findIndex(el => el.node?.treeIndex === topicContentState.data?.treeIndex);

        if (indexOfCurrentNode && indexOfCurrentNode + 1 < topicsInOrder.length) {
            dispatch(resetGetTopicContentAction());

            let nextTopic = topicsInOrder[indexOfCurrentNode + 1].node;
            setSelectedNode(nextTopic);
            dispatch(getTopicContentAction(nextTopic.treeIndex));
        }
    }

    useEffect(() => {
        dispatch(resetGetTopicContentAction());
        dispatch(getCompanyInfoAction());
    }, []);

    useEffect(() => {
        if (stateIsLoaded(singleTrainingState)) {
            setSelectedNode(singleTrainingState.data.treeStructure[0]);
        }
    }, [singleTrainingState]);

    return (
        <div className="preview--phone">
            <img alt="Phone mockup" src={PhoneMockup} width={250}></img>
            <div className="preview--phone-inner">
                {!isFirst && (
                    <div className="preview--phone-header">
                        <img style={{ cursor: 'pointer' }} alt="Go Back" src={LeftAngle} height={20} width={20} onClick={goBack} />

                        <div className="preview--header-title">
                            <Caption2>{topicNameForTraining ?? ''}</Caption2>
                        </div>

                        <div></div>
                    </div>
                )}
                <div className="preview--content">
                    {isFirst && singleTrainingState.data?.learningSetInfo?.learnCoverImage && (
                        <div className="preview--logo-container">
                            <img alt="Logo" style={{ objectFit: 'cover' }} src={singleTrainingState.data?.learningSetInfo?.learnCoverImage} width={'100%'} />
                        </div>
                    )}
                    {currentLessonContent && currentLessonContent.startsWith('https://') && (
                        <div class="outer">
                            <iframe title="Lesson" src={currentLessonContent} width="190px" height="100%" frameBorder="0" />
                        </div>
                    )}
                    {currentLessonContent && !currentLessonContent.startsWith('https://') && (
                        <div className="preview--parsed">{parse(currentLessonContent ?? '')}</div>
                    )}
                    {selectedNode && selectedNode.children?.length > 0 && (
                        <Caption2 weight="medium" color={colors.gray6}>
                            {t(isFirst ? 'Categories' : currentLessonContent ? 'READ INSIDE' : 'CONTENT')}
                        </Caption2>
                    )}
                    {isFirst && topicsInOrder.length === 0 && (
                        <Caption2 color={colors.gray6} className="preview--no-categories">
                            {t('No categories in this training')}
                        </Caption2>
                    )}
                    {isTopicEmpty && (
                        <Caption2 color={colors.gray6} className="preview--no-content">
                            {t('No content in this topic')}
                        </Caption2>
                    )}
                    {selectedNode &&
                        (stateIsLoaded(topicContentState) || isFirst) &&
                        selectedNode.children?.map((topic, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`preview--topic-button ${isFirst ? 'home' : ''}`}
                                    onClick={() => {
                                        dispatch(resetGetTopicContentAction());
                                        dispatch(getTopicContentAction(topic.treeIndex));
                                        setSelectedNode(topic);
                                    }}
                                >
                                    <div style={{ maxWidth: '90%', overflow: 'hidden' }}>{topic.titleForTraining}</div>
                                    <img alg="Go to" src={LeftAngle} height={10} width={10} style={{ transform: 'rotateY(180deg)' }} />
                                </div>
                            );
                        })}
                    {stateIsLoaded(topicContentState) && !isFirst && !isLast && (
                        <div className="preview--next-button" onClick={getNext}>
                            {t('Next')}
                            <img alg="Go to" src={LeftAngle} height={10} width={10} style={{ transform: 'rotateY(180deg)', marginLeft: 3 }} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
