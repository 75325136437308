import React, { useEffect } from 'react';

import { Switch, Route } from 'react-router-dom';
import { routes } from './routes';
import Navbar from './local_components/navbar/Navbar';
import { GlobalCallHandler } from 'components/wrappers/GlobalCallHandlerWrapper';
import { Toaster } from 'react-hot-toast';
import { getGameQuestionTypeVariationsAction, getLatestUpdatesAction } from 'redux/actions/content.actions';
import { useDispatch } from 'react-redux';
import { getCompanyAction } from 'redux/actions/users.actions';

const Module = props => {
    const dispatch = useDispatch();

    const {
        match: { url: moduleUrl },
        history,
    } = props;

    const allRoutes =
        routes &&
        routes.map(({ title, path, Component, ...rest }) => (
            <Route history={history} key={title} name={title} path={`${moduleUrl}${path}`} render={props => <Component {...props} {...rest} />} />
        ));

    useEffect(() => {
        dispatch(getLatestUpdatesAction());
        dispatch(getCompanyAction());
        dispatch(getGameQuestionTypeVariationsAction());
    }, []);

    return (
        <>
            <div className="d-flex" style={{ height: '100%' }}>
                <Navbar history={history} />
                <div className={'ml-auto'} style={{ width: '100vw', height: '100%' }}>
                    <GlobalCallHandler>
                        <Switch>{allRoutes}</Switch>
                    </GlobalCallHandler>
                </div>
                {/* <Footer /> */}
            </div>
            <Toaster position={'bottom-center'} toastOptions={{ duration: 3000 }} />
        </>
    );
};

export default Module;
