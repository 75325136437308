import React from 'react';
import { Body1, Body2 } from 'style/typography/Body';

import { Caption1 } from 'style/typography/Caption';
import { useTranslation } from 'react-i18next';
import { colors } from 'style/colors';

import TrashIcon from 'assets/icons/icon-delete.svg';
import LabelIcon from 'assets/icons/icon-tag-label.svg';

import '../TagsTab.css';

export default function LabelsListContainer({ labels, currentNode, setCurrentNode, setNodeToBeDeleted }) {
    const { t } = useTranslation();

    return (
        <div className="labels-list--container">
            {labels.map(label => {
                let selected = currentNode?.labelId == label?.labelId;
                return (
                    <div className="labels-list--item-wrapper">
                        <div
                            className={`labels-list--item ${selected ? 'selected' : ''}`}
                            onClick={() => {
                                if (label?.labelId !== currentNode?.labelId) {
                                    setCurrentNode(label);
                                }
                            }}
                        >
                            <div className="labels-list--item-title">
                                <img src={LabelIcon} height={20} width={20} alt="label" />
                                <Body1
                                    weight={selected && 'medium'}
                                    style={{
                                        maxWidth: '28vw',
                                        width: '28vw',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    {label.label}
                                </Body1>
                            </div>
                            <div className="labels-list--item-meta">
                                <Body2 color={colors.gray6}>
                                    {label.tagsCount} {t('tags')}
                                </Body2>
                            </div>
                        </div>
                        <div className="labels-list--item-actions">
                            <img
                                src={TrashIcon}
                                height={20}
                                width={20}
                                alt="label"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setNodeToBeDeleted(label?.labelId)}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
