import React, { useEffect, useRef, useState } from 'react';
import PreviewContent from 'components/features/content_management/PreviewContent';
import GameHeader from 'containers/layout/module/pages/content/questions/question_editor/games_preview/GameHeader';
import { GameQuestionCenter } from 'style/styled/GameElements';
import { numbersStyles } from 'static/game_constants';
import { useContentInsertionQuestion, useNumericalAnswer, useRandomNumericalDigits } from 'services/content-management/insertion-control';

export default function NumbersPreview() {
    const { question } = useContentInsertionQuestion();

    const { answer } = useNumericalAnswer();

    const [typedDigits, setTypedDigits] = useState([]);
    const typedDigitsRef = useRef([]);

    const numbersInterval = useRef(null);

    const randomDigits = useRandomNumericalDigits();

    const randomDigitsRef = useRef(null);
    const [randomDigitsUsed, setRandomDigitsUsed] = useState([]);

    const [answerTyped, setAnswerTyped] = useState('');

    useEffect(() => {
        if (numbersInterval.current) {
            clearInterval(numbersInterval.current);
            numbersInterval.current = null;
        }
        setTypedDigits([]);
        typedDigitsRef.current = [];
        randomDigitsRef.current = randomDigits.map(d => ({ digit: d, isTyped: false }));
        setRandomDigitsUsed([...randomDigitsRef.current]);
        //eslint-disable-next-line
    }, [answer?.text]);

    const [digitsReset, setDigitsReset] = useState(0);

    useEffect(() => {
        if (!numbersInterval.current && answer?.text) {
            let tempDigits = String(answer?.text).split('');
            numbersInterval.current = setInterval(() => {
                if (tempDigits.length > 0) {
                    let digit = tempDigits.shift();
                    typedDigitsRef.current.push(digit);
                    setTypedDigits([...typedDigitsRef.current]);
                    let found = randomDigitsRef.current.find(d => d.digit === digit && !d.isTyped);
                    if (found) {
                        found.isTyped = true;
                        setRandomDigitsUsed([...randomDigitsRef.current]);
                    }
                } else {
                    clearInterval(numbersInterval.current);
                    numbersInterval.current = null;
                    setTimeout(() => {
                        setTypedDigits([]);
                        typedDigitsRef.current = [];
                        randomDigitsRef.current = randomDigits.map(d => ({ digit: d, isTyped: false }));
                        setRandomDigitsUsed([...randomDigitsRef.current]);
                        setDigitsReset(digitsReset + 1);
                    }, []);
                }
            }, 1000);
        }
        return () => clearInterval(numbersInterval.current);
        //eslint-disable-next-line
    }, [answer?.text, digitsReset]);

    useEffect(() => {
        setAnswerTyped(typedDigits.join(''));
    }, [typedDigits]);

    return (
        <PreviewContent game={'Numbers'}>
            <GameHeader />
            <GameQuestionCenter>{question || 'Question'}</GameQuestionCenter>
            <div style={{ position: 'absolute', bottom: '10%', width: '100%' }} className="d-flex flex-column align-items-center">
                <div className="d-flex flex-column align-items-center" style={{ bottom: '80px', position: 'absolute' }}>
                    {randomDigitsUsed.map((rd, ind) => (
                        <div
                            className={`sexagon-image-background-preview d-flex justify-content-center align-items-center ${
                                rd.isTyped ? 'digit-opacity' : ''
                            } ${String(answer?.text).length === 0 ? 'empty-hexagon' : ''}`}
                            style={numbersStyles[ind]}
                        >
                            {String(answer?.text).length === 0 ? '' : rd.digit}
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        flex: 1,
                        fontSize: '26px',
                        color: String(answer?.text).length === String(answerTyped).length ? '#febe10' : 'black',
                    }}
                >
                    {answerTyped}
                </div>
            </div>
        </PreviewContent>
    );
}
