import React from 'react';

import './CustomSwitch.css';

export default function CustomSwitch({ checked, onClick, disabled }) {
    return (
        <div className="custom-switch">
            <label class="switch">
                <input checked={checked} onChange={onClick} disabled={disabled} type="checkbox" />
                <span class="slider round"></span>
            </label>
        </div>
    );
}
