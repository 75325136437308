import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { useDispatch, useSelector } from 'react-redux';
import { Body1 } from 'style/typography/Body';

import File from 'assets/icons/topic-icon-paper.svg';
import Ellipsis from 'assets/icons/ellipsis.svg';
import { Subtitle2 } from 'style/typography/Subtitle';
import { initiateNodeRemovalAction } from 'redux/actions/trainings.actions';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';

export function Topic({ treeIndex, title, used, learnerTitle }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const singleTraining = useSelector(state => state.content.singleTraining.data);

    const initiateNodeRemoval = () => {
        dispatch(initiateNodeRemovalAction(singleTraining?.learningSetInfo?.setId, treeIndex));
    };

    return (
        <div style={used ? topicStyle.used : topicStyle.unused} className={'d-flex flex-row rounded px-2 py-2 align-items-center mt-3'}>
            <div className={'d-flex flex-row align-items-center'} style={{ width: '100%', gap: 10 }}>
                <img alt="File" style={{ height: 25, width: 25 }} color={colors.gray6} src={File} />
                <Body1 className={'mb-0'} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {title ? title : treeIndex}
                </Body1>
                {used && learnerTitle && (
                    <Subtitle2 style={{ fontSize: 12, marginLeft: 10 }} className={'mb-0'} color={colors.gray6}>
                        {t('(used as {{learnerTitle}})', { learnerTitle: learnerTitle })}
                    </Subtitle2>
                )}
            </div>
            <div className={'ml-auto d-flex flex-row align-items-center position-relative'}>
                <div role="button" className="dropleft mr-3">
                    <img alt="More" style={{ height: 20, width: 20 }} color={colors.descriptionText} src={Ellipsis} data-toggle="dropdown" />
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Link className="dropdown-item" to={`/content/topics?topicId=${treeIndex}`} target="_blank">
                            {t('Go to topic')}
                        </Link>
                        {used && (
                            <div className="dropdown-item" onClick={initiateNodeRemoval}>
                                {t('Remove from training')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

Topic.propTypes = {
    learnerTitle: PropTypes.any,
    title: PropTypes.any,
    treeIndex: PropTypes.any,
    used: PropTypes.any,
};

const topicStyle = {
    used: {
        borderLeft: `6px solid ${colors.mainTheme}`,
        width: '100%',
        height: '25%',
    },
    unused: {
        borderLeft: `6px solid ${colors.gray3}`,
        width: '100%',
        height: '50%',
    },
};
