import { userDeviceStatusOptions } from 'static/status_options';

export const userDeviceTableSubheader = ({ filtersState, activeFilters, dispatchFilters, resetBulkActions }) => [
    {
        id: 'content-search-input',
        type: 'value',
        value: filtersState.searchQuery,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, searchQuery: val });
        },
        style: { width: 200 },
        placeholder: 'Search',
        active: true,
    },
    {
        id: 'content-created-dropdown',
        type: 'daterangepicker',
        value: filtersState.dateRange,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, dateRange: val });
        },
        placeholder: 'Date range',
        clearOnBackspace: true,
        active: activeFilters.dateRange.active,
    },
    {
        id: 'content-status-dropdown',
        type: 'dropdown',
        value: filtersState.status,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, status: val });
        },
        placeholder: 'Status',
        clearOnBackspace: true,
        dropdownValues: userDeviceStatusOptions,
        active: activeFilters.status.active,
    },
];
