import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { createTheme, ThemeProvider } from '@mui/material';
import { colors } from 'style/colors';
const theme = createTheme({
    palette: {
        primary: {
            main: colors.mainTheme,
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    borderRadius: '5px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: '8px !important',
                    fontSize: 14,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: { border: 0 },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: colors.gray1,
                    height: 20,
                    width: 20
                },
            },
        }
    },
});
const CustomTimePicker = ({ time, setTime }) => {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                    value={time}
                    onChange={newValue => {
                        setTime(newValue);
                    }}
                    ampm={false}
                    renderInput={params => {
                        return <TextField {...params} />;
                    }}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default CustomTimePicker;
