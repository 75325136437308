import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useImageCategories } from 'services/content-management/insertion-control';
import CircularProgress from '@material-ui/core/CircularProgress';

import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import { validatorCategories } from 'services/validation-module/local-constants';
import CropperModal from 'components/features/content_management/cropping_module/CropperModal';

import { InputWithInfoPopUpWrapper } from './InputWithInfoPopUpWrapper';
import { ImageEditorProvider } from 'services/image_management/image-editor-management';
import { Body1 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import Add from 'assets/icons/icon-add.svg';
import Remove from 'assets/icons/icon-remove.svg';
import Delete from 'assets/icons/icon-delete.svg';
import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';
import CameraDark from 'assets/icons/camera-dark.svg';
import IndexComponent from 'components/elements/index_component/IndexComponent';
import PreviewImageInputCategories from './PreviewImageInputCategories';
import { useTranslation } from 'react-i18next';

const InputImageCategories = ({ value, index, images, showRemove }) => {
    const { updateAnswer: updateCategory, removeAnswer: removeCategory, addImage, removeImage } = useImageCategories();
    const [uploaderOpened, setUploaderOpened] = useState(false);
    const [openImageResizer, setOpenImageResizer] = useState(false);
    const [hasLoader, setHasLoader] = useState(false);

    const { t } = useTranslation();

    return (
        <div style={{ position: 'relative' }}>
            <div className="d-flex flex-row">
                <div className={'d-flex flex-row align-items-center'} style={{ margin: '10px 0', height: '60px', gap: '10px' }}>
                    <IndexComponent index={index + 1} />

                    <InputWithInfoPopUpWrapper index={index} answer={value}>
                        {({ stopInput, onChange }) => {
                            return (
                                <CustomTextField
                                    id={'question-input-options-' + index}
                                    type="text"
                                    placeholder={t('Value')}
                                    className="form-control"
                                    style={{ height: '40px' }}
                                    font={15}
                                    value={value}
                                    setValue={val => updateCategory({ index, element: { text: val } })}
                                />
                            );
                        }}
                    </InputWithInfoPopUpWrapper>
                    {showRemove && (
                        <div className="d-flex align-items-center" style={{ fontSize: '13px' }}>
                            <img alt="Remove" className="cursor-pointer" onClick={() => removeCategory(index)} height={20} width={20} src={Remove} />
                        </div>
                    )}
                    <div className="ml-2">
                        {!uploaderOpened && (
                            <img
                                alt="Arrow"
                                onClick={() => setUploaderOpened(true)}
                                className={'ml-2 cursor-pointer'}
                                height={15}
                                width={15}
                                src={AngleDown}
                            />
                        )}
                        {uploaderOpened && (
                            <img
                                alt="Arrow"
                                onClick={() => setUploaderOpened(false)}
                                className={'ml-2 cursor-pointer'}
                                height={15}
                                width={15}
                                src={AngleUp}
                            />
                        )}
                    </div>
                </div>
            </div>
            {uploaderOpened && (
                <div className="row mt-1 mb-1">
                    {images.length === 0 && (
                        <>
                            <div
                                id={'question-add-image-' + index}
                                onClick={() => {
                                    setOpenImageResizer(true);
                                }}
                                className="d-flex flex-column justify-content-center align-items-center"
                                style={{
                                    border: `1px solid ${colors.gray1}`,
                                    padding: 20,
                                    cursor: 'pointer',
                                    width: '70%',
                                    background: colors.background,
                                    marginLeft: 50,
                                }}
                            >
                                <img alt="Camera" src={CameraDark} width={25} height={25} color={colors.gray6} />
                                <Body1 className={'mt-2'} style={{ textAlign: 'center' }} color={colors.hray}>
                                    {t('Add images here')}
                                </Body1>
                            </div>
                        </>
                    )}
                    {images.length > 0 && (
                        <div className="d-flex flex-row align-items-center">
                            <div className="d-flex" style={{ marginLeft: '50px', flexWrap: 'wrap' }}>
                                {images.map((im, ind) => (
                                    <div key={`images-listed-${ind}`} className="mr-2 mt-1 mb-1">
                                        <PreviewImageInputCategories file={im?.urlRatio1x1} categoryIndex={index} imageIndex={ind} />
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex align-items-center ml-2">
                                <img
                                    alt="Add"
                                    className="cursor-pointer ml-3"
                                    onClick={() => setOpenImageResizer(true)}
                                    height={20}
                                    width={20}
                                    src={Add}
                                />
                                <img
                                    alt="Delete"
                                    onClick={() => {
                                        removeImage(index);
                                    }}
                                    className={'ml-3 cursor-pointer'}
                                    height={20}
                                    width={20}
                                    src={Delete}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
            {openImageResizer && (
                <>
                    <CircularProgress style={{ position: 'absolute', zIndex: 2 }} size={30} className={'text-info'} hidden={!hasLoader} />

                    {!hasLoader && (
                        <ImageEditorProvider index={index} setOpenImageResizer={setOpenImageResizer}>
                            <CropperModal
                                index={index}
                                addImage={addImage}
                                setOpenImageResizer={setOpenImageResizer}
                                setHasLoader={setHasLoader}
                            ></CropperModal>
                        </ImageEditorProvider>
                    )}
                </>
            )}
            <ValidationPopup category={validatorCategories.IMAGE_WITHOUT_VALUE} position="left" index={index} indexed />
            <ValidationPopup category={validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY} position="left" index={index} indexed />
        </div>
    );
};

InputImageCategories.propTypes = {
    images: PropTypes.any,
    index: PropTypes.number,
    showRemove: PropTypes.bool,
    value: PropTypes.any,
};

export default InputImageCategories;
