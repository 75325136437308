import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { OutlinedButton } from '../Button';
import { CustomOutlinedTextField } from '../inputs/CustomOutlinedTextField';
import { CustomSelect } from '../selector/CustomSelector';

const InputTooltip = ({ position = 'center', options, onMouseEnter, onMouseLeave, onClick, style = {} }) => {
    const [secondaryOptions, setSecondaryOptions] = useState(null);

    function getOptions(options) {
        return options?.map(el => {
            return (
                <div className="d-flex flex-column">
                    {el.text && !el.type && (
                        <Caption1
                            color={colors.gray6}
                            style={{ padding: '5px 0', cursor: 'pointer', ...style }}
                            onClick={onClick ? () => onClick(el) : el.onClick}
                        >
                            {el.text}
                        </Caption1>
                    )}
                    {el.type === 'link' && (
                        <Link to={el.linkTo}>
                            <Caption1 color={colors.gray6} style={{ padding: '5px 0', cursor: 'pointer', ...style }}>
                                {el.text}
                            </Caption1>
                        </Link>
                    )}
                    {el.type === 'info' && (
                        <div style={{ marginTop: 5 }}>
                            <Caption1 color={colors.gray6}>{el.infoTitle}</Caption1>
                            <Body2 color={colors.dark}>{el.infoValue}</Body2>
                        </div>
                    )}
                    {el.type === 'select' && <CustomSelect options={el.values} value={el.value} onChange={el.onChange} />}
                    {el.type === 'text' && <CustomOutlinedTextField value={el.value} setValue={el.onChange} />}
                    {el.type === 'divider' && (
                        <div style={{ width: '80%', height: '1px', borderBottom: `1px dashed ${colors.gray2}`, margin: '10px 10px' }}></div>
                    )}
                    {el.type === 'option' && (
                        <Body2
                            style={{ margin: '5px 10px', cursor: 'pointer' }}
                            onClick={() => (el.secondaryOptions ? setSecondaryOptions(el.secondaryOptions) : onClick ? onClick(el) : el.onClick())}
                        >
                            {el.label}
                        </Body2>
                    )}
                    {el.type === 'button' && (
                        <div style={{ paddingTop: '10px', width: '100%' }}>
                            <OutlinedButton text={el.label} onClick={onClick ? () => onClick(el) : el.onClick} />
                        </div>
                    )}
                </div>
            );
        });
    }

    let optionsElements = getOptions(options);
    let secondaryOptionsElements = getOptions(secondaryOptions);

    return (
        <div>
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{
                    position: 'absolute',
                    width: '150px',
                    maxHeight: '300px',
                    overflow: 'auto',
                    backgroundColor: colors.white,
                    color: 'white',
                    padding: '15px 10px',
                    left: position === 'center' ? '50%' : position === 'left' ? '10px' : 'unset',
                    right: position === 'right' ? '10px' : 'unset',
                    zIndex: 999,
                    transform: position === 'center' ? 'translateX(-50%)' : '',
                    borderRadius: '5px',
                    fontSize: '12px',
                    fontWeight: 'light',
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                }}
            >
                <div
                    style={{
                        width: '15px',
                        height: '15px',
                        transform: 'rotate(45deg) translateX(-50%)',
                        position: 'absolute',
                        left: '10%',
                        backgroundColor: colors.white,
                        top: '0',
                        zIndex: -1,
                        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    }}
                ></div>
                {optionsElements}
            </div>
            {secondaryOptionsElements && (
                <div
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={{
                        position: 'absolute',
                        width: '150px',
                        maxHeight: '300px',
                        overflow: 'visible',
                        backgroundColor: colors.white,
                        color: 'white',
                        padding: '15px 10px',
                        left: '160px',
                        zIndex: 998,
                        transform: position === 'center' ? 'translateX(-50%)' : '',
                        borderRadius: '5px',
                        fontSize: '12px',
                        fontWeight: 'light',
                        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    }}
                >
                    {secondaryOptionsElements}
                </div>
            )}
        </div>
    );
};

InputTooltip.propTypes = {
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    options: PropTypes.array,
    position: PropTypes.string,
    style: PropTypes.object,
};

export default InputTooltip;
