import React from 'react';
import { stateIsLoaded } from 'redux/core/stateHelpers';

export const usePagination = ({ paginatedState, currentPage, setCurrentPage }) => {
    const getPrevious = () => {
        if (!stateIsLoaded(paginatedState)) {
            return;
        }
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const getNext = () => {
        if (!stateIsLoaded(paginatedState)) {
            return;
        }
        if (currentPage < paginatedState.data.totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getPage = page => {
        setCurrentPage(page);
    };
    return {
        getPrevious,
        getNext,
        getPage,
    };
};
