import React, { useEffect, useMemo, useRef, useState } from 'react';
import TableEmptyState from 'components/features/data_table/table_helpers/TableEmptyState';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import CaretTable from 'assets/icons/caret-table.svg';

import { TableCellContent, TableHeader } from 'components/features/data_table/TableElements';
import { UserCircle } from 'components/elements/UserCircle';
import { Body2 } from 'style/typography/Body';
import CustomCheckboxForTable from 'components/elements/checkbox/CustomCheckboxForTable';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { colors } from 'style/colors';
import { ActionMenu } from 'components/features/data_table/table_helpers/ActionMenu';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PaginationComponent from 'components/features/data_table/pagination_component/PaginationComponent';
import { customStylesTable } from 'style/elements/table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDevicesPaginatedAction, resetFetchUserDevicesPaginatedAction } from 'redux/actions/users.actions';
import { useTableSelections } from 'services/custom-hooks/useTableSelections';
import { useFilters } from 'components/features/data_table/pagination_component/useFilters';
import { userDevicesInitialState } from 'static/filters';
import usePagination from '@mui/material/usePagination/usePagination';
import { Subheader } from 'components/features/data_table/subheader/Subheader';
import { userDeviceTableSubheader } from './table_components/UserDeviceTableSubheader';

import { linkBuilderFromQuery } from 'services/api/api_builders';
import { paramsToObject, setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import UserDeviceTableActions from './table_components/UserDeviceTableActions';
import { DeleteUserDeviceRequestModal } from './local_components/modals/DeleteUserDeviceRequestModal';

import './UserDeviceRequests.css';
import UserDeviceEmptyState from './UserDeviceEmptyState';
import { LoadingAnimation } from 'components/features/lottie/LoadingAnimation';

export default function UserDeviceRequests() {
    const { t } = useTranslation();

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const deviceRequestsPaginated = useSelector(state => state.content.deviceRequestsPaginated);
    const [dataLoading, setDataLoading] = useState(false);
    const [currentActionOption, setCurrentActionOption] = useState(false);
    const [deleteModalItem, setDeleteModalItem] = useState(false);

    const isInitialLoad = useRef(true);

    const {
        areAllOptionsFromThisPageSelected,
        selectedRows,
        setSelectedRows,
        addSelectedRow,
        deselectAll,
        removeSelectedRow,
        selectAll,
        allQuestionsSelected,
        setAllQuestionsSelected,
        filteredData,
        selectedRowsArr,
    } = useTableSelections({
        paginatedState: deviceRequestsPaginated,
        rowSelector: row => row.userId,
    });

    const { filtersState, activeFilters, dispatchFilters, updateFilterState } = useFilters({ initialFiltersState: userDevicesInitialState });

    const filters = useMemo(() => {
        return {
            searchQuery: filtersState.searchQuery ? encodeURIComponent(filtersState.searchQuery) : '',
            dateReceivedFrom: filtersState.dateRange ? Date.parse(filtersState.dateRange[0]) : null,
            dateReceivedTo: filtersState.dateRange ? Date.parse(filtersState.dateRange[1]) : null,
            resolved: filtersState.status,
            page: filtersState.page,
            size: filtersState.size,
            orderBy: filtersState.sortParam?.selector,
            ascending: filtersState.sortParam?.ascending,
        };
    }, [filtersState]);

    const { getPrevious, getNext, getPage } = usePagination({
        paginatedState: deviceRequestsPaginated,
        currentPage: filters.page,
        setCurrentPage: page => dispatchFilters({ page: page }),
    });

    const totalSelectedElements = allQuestionsSelected ? deviceRequestsPaginated.data.totalElements : selectedRowsArr.length;

    const handleSort = async (column, sortDirection) => {
        if (selectedRowsArr.length === 0) {
            dispatchFilters({ page: 0, sortParam: { selector: column.selector, ascending: sortDirection == 'asc' ? 'true' : 'false' } });
        }
    };

    function hasFilters() {
        return filters.searchQuery || filters.status || filters.dateRange;
    }

    function setFilters(stateFilters) {
        dispatchFilters({
            searchQuery: stateFilters.searchQuery,
            status: stateFilters.resolved,
            page: Number(stateFilters.page ?? 0),
            size: Number(stateFilters.size ?? 10),
            dateRange:
                stateFilters.dateReceivedFrom && stateFilters.dateReceivedTo
                    ? [new Date(parseInt(stateFilters.dateReceivedFrom)), new Date(parseInt(stateFilters.dateReceivedTo))]
                    : null,
            sortParam: stateFilters.orderBy && stateFilters.ascending ? { selector: stateFilters.orderBy, ascending: stateFilters.ascending } : null,
        });
    }

    useEffect(() => {
        let stateFilters;
        if (location?.state?.filters) {
            stateFilters = location?.state?.filters;
        }
        if (location?.search) {
            const query = new URLSearchParams(location.search);
            stateFilters = paramsToObject(query.entries());
        }
        if (stateFilters) {
            setFilters(stateFilters);
        }

        return () => dispatch(resetFetchUserDevicesPaginatedAction());
    }, []);

    useEffect(() => {
        setDataLoading(true);
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(
                    fetchUserDevicesPaginatedAction({
                        ...filters,
                        page: filters.page,
                    })
                );
                let search = linkBuilderFromQuery('', filters);
                window.history.replaceState('page2', 'Title', '/users/user-device-request' + search);
            },
            300,
            'fetchUserDevicesPaginatedActionUseEffect'
        );
        return () => setTimeoutLastExecute.removeCallback('fetchUserDevicesPaginatedActionUseEffect');
    }, [dispatch, filtersState]);

    useEffect(() => {
        if (stateIsLoaded(deviceRequestsPaginated)) {
            setDataLoading(false);
            isInitialLoad.current = false;
        } else {
            setDataLoading(true);
        }
    }, [deviceRequestsPaginated]);

    const Actions = () => (
        <div>
            <button className={'btn btn-outline-secondary border-black text-black'} onClick={() => {}}>
                {t('Deactivate')}
            </button>
        </div>
    );
    const actionButtons = row => [
        {
            name: t('View'),
            onClick: () => history.push(`/user-device-request/${row.userId}/view`),
            visible: true,
        },
        // {
        //     name: t('Approve request'),
        //     onClick: () => {},
        //     visible: row.status === 'Pending',
        // },
        // {
        //     name: t('Reject request'),
        //     onClick: () => {},
        //     visible: row.status === 'Pending',
        // },

        {
            name: t('Delete'),
            onClick: () => {
                setDeleteModalItem(row.userId);
            },
            visible: true,
        },
    ];

    const noItemsSelected = selectedRowsArr.length === 0;

    const columns = useMemo(
        () => [
            {
                name: <CustomCheckboxForTable checked={false} selectAll={() => {}} deselectAll={() => {}} />,
                width: '50px',
                cell: row => {
                    return (
                        <div style={{ height: '100%', width: '100%' }}>
                            <Checkbox
                                onClick={e => {
                                    if (!row.selected) {
                                        addSelectedRow(row);
                                    } else {
                                        removeSelectedRow(row);
                                        setAllQuestionsSelected(false);
                                    }
                                }}
                                checked={!!row.selected}
                            ></Checkbox>
                        </div>
                    );
                },
            },
            {
                name: noItemsSelected ? (
                    <TableHeader text={t('Name')} />
                ) : (
                    // <UserDeviceTableActions
                    //     currentActionOption={currentActionOption}
                    //     totalSelectedItems={totalSelectedElements}
                    //     // setIsTagModalOpen={setIsTagModalOpen}
                    //     // setIsRemoveTagModalOpen={setIsRemoveTagModalOpen}
                    //     // setIsRolesModalOpen={setIsRolesModalOpen}
                    //     // setIsRemoveRolesModalOpen={setIsRemoveRolesModalOpen}
                    // />
                    <div></div>
                ),
                selector: 'userFullName',
                sortable: noItemsSelected,
                allowOverflow: true,
                wrap: true,
                width: 'calc(24% - 48px)',
                fontWeight: '800w',
                cell: row => {
                    return (
                        <TableCellContent
                            to={{
                                pathname: `/user-device-request/${row.userId}/view`,
                                state: {
                                    filters: filters,
                                },
                            }}
                            elementBefore={
                                <div className="mr-2">
                                    <UserCircle
                                        className={'pt-2 pb-2'}
                                        name={row.userFullName?.split(' ')?.[0] ?? ''}
                                        lastName={row.userFullName?.split(' ')?.[1] ?? ''}
                                        bgColor={colors.gray4}
                                    />
                                </div>
                            }
                            text={
                                row.userFullName ? row.userFullName : <Body2 color={colors.gray6}>{row.isActive ? '' : t('Invite pending..')}</Body2>
                            }
                            isLoading={dataLoading}
                        />
                    );
                },
            },
            {
                name: noItemsSelected && <TableHeader text={t('E-Mail / Number')} />,
                selector: 'email',
                sortable: noItemsSelected,
                width: '22%',
                cell: row => (
                    <TableCellContent
                        to={{
                            pathname: `/user-device-request/${row.userId}/view`,
                            state: {
                                filters: filters,
                            },
                        }}
                        isLoading={dataLoading}
                        text={row.email ? row.email : `+${row.countryCode} ${row.phoneNumber}`}
                    />
                ),
            },
            {
                name: noItemsSelected && <TableHeader text={t('Requested device name')} />,
                selector: 'requestedDeviceName',
                sortable: noItemsSelected,
                width: '23%',
                cell: row => (
                    <TableCellContent
                        to={{
                            pathname: `/user-device-request/${row.userId}/view`,
                            state: {
                                filters: filters,
                            },
                        }}
                        navigationLink={`/user-device-request/${row.userId}/view`}
                        isLoading={dataLoading}
                        text={row.requestedDeviceName === 'Multiple requests' ? t(row.requestedDeviceName) : row.requestedDeviceName}
                    />
                ),
            },
            {
                name: noItemsSelected && <TableHeader text={t('Status')} />,
                selector: 'status',
                sortable: noItemsSelected,
                width: '10%',
                cell: row => (
                    <TableCellContent
                        to={{
                            pathname: `/user-device-request/${row.userId}/view`,
                            state: {
                                filters: filters,
                            },
                        }}
                        navigationLink={`/user-device-request/${row.userId}/view`}
                        isLoading={dataLoading}
                        text={t(row.status)}
                    />
                ),
            },
            {
                name: noItemsSelected && <TableHeader text={t('Date recieved')} />,
                selector: 'dateReceived',
                sortable: noItemsSelected,
                width: '12%',
                cell: row => (
                    <TableCellContent
                        to={{
                            pathname: `/user-device-request/${row.userId}/view`,
                            state: {
                                filters: filters,
                            },
                        }}
                        navigationLink={`/user-device-request/${row.userId}/view`}
                        isLoading={dataLoading}
                        text={row.dateReceived}
                    />
                ),
            },
            {
                name: noItemsSelected ? <TableHeader text={t('Actions')} /> : null,
                cell: row => <ActionMenu row={row} buttons={actionButtons(row)} />,
                allowOverflow: true,
                button: true,
            },
        ],
        [
            dataLoading,
            filteredData,
            selectedRows,
            areAllOptionsFromThisPageSelected,
            addSelectedRow,
            removeSelectedRow,
            // currentActionOption,
            selectAll,
        ]
    );

    if (!dataLoading && !hasFilters() && isInitialLoad.current) {
        return <LoadingAnimation />;
    }

    if (stateIsLoaded(deviceRequestsPaginated) && deviceRequestsPaginated.data?.content?.length == 0 && !hasFilters() && !dataLoading) {
        return <UserDeviceEmptyState />;
    }

    return (
        <div className="device-req--data-table" style={{ display: 'flex', flexDirection: 'column' }}>
            <DeleteUserDeviceRequestModal
                modalOpenAction={deleteModalItem !== false}
                setModalOpenAction={setDeleteModalItem}
                userIds={[deleteModalItem]}
                onSuccess={() => {
                    dispatch(
                        fetchUserDevicesPaginatedAction({
                            ...filters,
                            page: filters.page,
                        })
                    );
                }}
            />
            <DataTable
                contextActions={<Actions />}
                sortIcon={true ? <img alt="Sort" height={15} width={13} className="ml-2" src={CaretTable}></img> : <></>}
                columns={columns}
                onSort={handleSort}
                sortServer
                className="overflow-visible"
                data={deviceRequestsPaginated.data?.content ?? []}
                overflowY={true}
                overflowYOffset={'0px'}
                noDataComponent={<TableEmptyState />}
                defaultSortField="name"
                subHeader={true}
                paginationPerPage={100}
                onRowClicked={row => history.push(`/user-device-request/${row.userId}/view`)}
                pagination={true}
                selectableRows={false}
                highlightOnHover={true}
                subHeaderComponent={
                    <Subheader
                        inputs={userDeviceTableSubheader({
                            filtersState: filtersState,
                            activeFilters,
                            dispatchFilters,
                            resetBulkActions: () => {},
                        })}
                    />
                }
                subHeaderAlign={'left'}
                customStyles={customStylesTable}
                theme={'tableTheme'}
                selectableRowSelected={row => row.selected}
                noHeader={true}
                paginationComponent={_ => (
                    <PaginationComponent
                        getPrev={getPrevious}
                        getNext={getNext}
                        getPage={getPage}
                        currentPage={filters.page}
                        totalPages={deviceRequestsPaginated.data.totalPages}
                        totalItems={deviceRequestsPaginated?.data?.totalElements}
                        size={filters.size}
                        setSize={size => {
                            dispatchFilters({ page: 0, size: size });
                        }}
                    />
                )}
            ></DataTable>
        </div>
    );
}
