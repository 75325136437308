import React from 'react';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

export default function TagPill({ tag }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: colors.gray1,
                borderRadius: '100px',
                padding: '3px 10px',
                color: 'white',
            }}
        >
            <Body2 color={colors.white}>{tag.tag}</Body2>
        </div>
    );
}
