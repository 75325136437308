import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';

export function UserCircle({
    name,
    lastName,
    id,
    innerRef,
    dataToggle,
    height,
    width,
    borderConditionalVariable,
    borderColorActive,
    bgColor,
    divClasses,
    textColor,
    children,
    ...props
}) {
    const child = (
        <div>
            {name?.substr(0, 1).toUpperCase()}
            {lastName?.substr(0, 1).toUpperCase()}
        </div>
    );
    return (
        <div
            id={id}
            ref={innerRef ? innerRef : null}
            data-toggle={dataToggle ? dataToggle : ''}
            style={{
                height: height ? height : '32px',
                width: width ? width : '32px',
                border: `1px solid ${borderConditionalVariable ? borderColorActive : colors.gray1}`,
                backgroundColor: bgColor ? bgColor : colors.background,
                zIndex: 2,
                cursor: 'pointer',
            }}
            className={`${divClasses} rounded-circle d-flex pl-1 pr-1 justify-content-center align-items-center align-content-center`}
        >
            {props.tag ? (
                <props.tag weight="bold" color={textColor ? textColor : colors.gray1} className={'mb-0 p-0 font-weight-bold'}>
                    {child}
                </props.tag>
            ) : (
                <Caption1 weight="bold" color={textColor ? textColor : colors.gray1} className={'mb-0 p-0 font-weight-bold'}>
                    {child}
                </Caption1>
            )}
            {children}
        </div>
    );
}

UserCircle.propTypes = {
    id: PropTypes.any,
    bgColor: PropTypes.any,
    borderColorActive: PropTypes.any,
    borderConditionalVariable: PropTypes.any,
    children: PropTypes.any,
    dataToggle: PropTypes.any,
    divClasses: PropTypes.any,
    height: PropTypes.any,
    innerRef: PropTypes.any,
    lastName: PropTypes.string,
    name: PropTypes.string,
    tag: PropTypes.any,
    textColor: PropTypes.any,
    width: PropTypes.any,
};
