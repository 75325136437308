import React, { useState, useEffect, useRef } from 'react';
import PreviewContent from 'components/features/content_management/PreviewContent';
import GameHeader from 'containers/layout/module/pages/content/questions/question_editor/games_preview/GameHeader';
import { GameQuestionCenter } from 'style/styled/GameElements';
import { useContentInsertionQuestion, useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import { optionDefaults } from 'services/content-management/insertion-control/local-constants';
import { randomize } from 'services/utils/arrayHelpers';
import placeholder from 'assets/game-previews/compare-empty-pic.png';

export function PinpointPreview({ image }) {
    const { question } = useContentInsertionQuestion();
    const { answers: answersList } = useMultipleChoiceAnswers();

    const [answers, setAnswers] = useState([]);
    const previousAnswersLength = useRef(0);
    const randomAnswers = useRef(randomize(answersList, 3, optionDefaults.default));

    useEffect(() => {
        if (answers.length === 0) {
            let result = randomize(answersList);
            setAnswers(result);
            randomAnswers.current = result;
        } else if (previousAnswersLength.current !== answersList.length) {
            let result = randomize(answersList);
            setAnswers(result);
            randomAnswers.current = result;
        } else {
            let ansNew = randomAnswers.current;
            ansNew.forEach(an => {
                let item = answersList.find(a => a.id === an.id);
                if (item) {
                    an.text = item.text;
                    an.isCorrect = item.isCorrect;
                }
            });
            setAnswers([...randomAnswers.current]);
        }
        previousAnswersLength.current = answersList.length; //eslint-disable-next-line
    }, [answersList]);
    return (
        <PreviewContent game={'Pinpoint'}>
            <div style={{ flex: 1 }} className="d-flex flex-column">
                <GameHeader />
                <GameQuestionCenter>{question || 'Question'}</GameQuestionCenter>
                <div style={{ flex: 1 }} className="mx-3 d-flex flex-column justify-content-center">
                    <div style={{ flex: 1, marginTop: 10 }}>
                        <div className="d-flex flex-row align-items-center mt-3">
                            <img
                                alt="Placeholder"
                                src={image ? image.url : placeholder}
                                width={180}
                                height={120}
                                style={{ objectFit: 'cover', width: '100%' }}
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: 75 }}>
                        {answers.map((ans, index) => (
                            <div className={`race-cars-option mt-2 text-center ${ans.isCorrect ? 'active' : ''}`} key={`race-cars-option-${ans.id}`}>
                                {ans.text || `Option ${ans.id + 1} ${index}`}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </PreviewContent>
    );
}
