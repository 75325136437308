import React, { useEffect, useRef, useState } from 'react';

import { UploadImageDialog } from './UploadImageDialog';
import GalleryFolders from 'components/features/gallery/folders/GalleryFolders';
import NewFolderModal from 'components/features/gallery/folders/NewFolderModal';
import GallerySubheader from 'components/features/gallery/subheader/GallerySubheader';
import GalleryPreviewImages from 'components/features/gallery/GalleryPreviewImages';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { useImageLibraryHelper } from 'services/image_management/imageLibraryHelper';

import './Gallery.css';


export function GalleryTab({ index }) {
    const [folderModalOpen, setFolderModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const { treeData } = useImageEditor();

    const { saveTreeData } = useImageLibraryHelper();

    const toSave = useRef(false);

    useEffect(() => {
        if (toSave.current) {
            toSave.current = false;
            saveTreeData();
        }
    }, [treeData]);



    const onImagesUploadCallback = () => {
        setModalOpen(true);
    };

    return (
        <div>
            <div className="d-flex flex-row h-100 gallery--container">
                <GalleryFolders setModalOpen={setModalOpen} setFolderModalOpen={setFolderModalOpen} toSave={toSave} />

                <div className="d-flex col flex-column p-0" style={{ minWidth: 0 }}>
                    <GallerySubheader
                        index={index}
                        onImagesUploadCallback={onImagesUploadCallback}
                        showUploadImageButton={true}
                        setModalOpen={setModalOpen}
                    />
                    <GalleryPreviewImages />
                </div>

                <NewFolderModal modalOpen={folderModalOpen} setModalOpen={setFolderModalOpen} toSave={toSave} />
            </div>
            <UploadImageDialog modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
    );
}
