import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import NotificationPreview from './NotificationPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Body2 } from 'style/typography/Body';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Header } from 'containers/layout/module/local_components/header/Header';
import HoverSelectWithCustomComponent from 'components/elements/tooltips/HoverSelectWithCustomComponent';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { useStateWithErrors, validateInputs } from 'services/custom-hooks/useStateWithErrors';
import InputErrorHandlerWrapper from 'components/wrappers/InputErrorHandlerWrapper';
import {
    createNewNotificationAction,
    deleteNotificationAction,
    getSingleNotificationAction,
    resetDeleteNotificationAction,
    resetGetSingleNotificationAction,
    resetNotificationCreatedAction,
} from 'redux/actions/content.actions';
import toast from 'react-hot-toast';
import UserPickerDialog from '../UserPickerDialog';
import NotificationRecipients from 'assets/icons/icon-notifications-recipients.svg';
import { daysInWeekDefault, notificationTimeType, quickValuesOptions } from 'static/notification_constants';
import NotificationType from './NotificationType';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';

import './NotificationPreview.css';
import { DeleteNotificationModal } from './DeleteNotificationModal';

const CreateNotification = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [notificationName, setNotificationName] = useState('');
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationText, setNotificationText, errorTitleProps] = useStateWithErrors(useState(''), el => !!el, t('Must enter description'));

    const { notificationId } = useParams();

    const [userModalOpen, setUserModalOpen] = useState(false);
    const [showDescriptionParams, setShowDscriptionParams] = useState(false);
    const [showTitleParams, setShowTitleParams] = useState(false);
    const [allUsersSelectedData, setAllUsersSelectedData] = useState(false);
    const [toDelete, setToDelete] = useState(false);

    const notificationCreatedState = useSelector(state => state.content.createNotification);
    const singleNotificationState = useSelector(state => state.content.singleNotification);
    const deleteNotificationState = useSelector(state => state.content.deleteNotification);

    // const [dateForOneTimeNotification, setDateForOneTimeNotification] = useState(new Date());
    const [dateForOneTimeNotification, setDateForOneTimeNotification, dateForOneTimeNotificationProps] = useStateWithErrors(
        useState(new Date()),
        el => !!el,
        t('Must choose date for schedule')
    );
    const [timeOneTime, setTimeOneTime, errorTimeOneTimeProps] = useStateWithErrors(useState(null), el => !!el, t('Must choose time for schedule'));
    const [timeRecurring, setTimeRecurring, errorTimeRecurring] = useStateWithErrors(useState(null), el => !!el, t('Must choose time for schedule'));

    const [daysInWeek, setDaysInWeek, errorDaysInWeekProps] = useStateWithErrors(
        useState(daysInWeekDefault),
        el => el.find(el2 => el2.selected),
        t('Must select at least one day')
    );

    const [currentCronTypeValue, setCurrentCronTypeValue, errorsCronTypeProps] = useStateWithErrors(
        useState(false),
        el => !!el,
        t('Must schedule time for notification')
    );

    const cronBuilder = useRef(null);

    const [selectedUsers, setSelectedUsers, errorSelectedUsers] = useStateWithErrors(
        useState([]),
        el => {
            return el?.length > 0;
        },
        t('Must select at least one user')
    );

    const handleNotificationNameChange = val => {
        setNotificationName(val);
    };

    const handleNotificationTitleChange = val => {
        setNotificationTitle(val);
    };

    const handleNotificationTextChange = val => {
        setNotificationText(val);
    };

    const sendNotification = () => {
        if (
            validateInputs([errorTitleProps]) &&
            (allUsersSelectedData?.allUsersSelected || validateInputs([errorSelectedUsers])) &&
            validateInputs([errorsCronTypeProps])
        ) {
            const data = {
                notificationId: notificationId ?? -1,
                cron: cronBuilder.current,
                notificationType: notificationName,
                title: notificationTitle,
                body: notificationText,
                userIds: selectedUsers,
                allUsersSelected: allUsersSelectedData?.allUsersSelected,
                userFilters: allUsersSelectedData?.userFilters,
                notificationSendType: currentCronTypeValue?.value?.toUpperCase(),
            };
            if (
                currentCronTypeValue.value === 'one_time' &&
                validateInputs([errorTimeOneTimeProps]) &&
                validateInputs([dateForOneTimeNotificationProps])
            ) {
                dispatch(createNewNotificationAction(data));
            }
            if (currentCronTypeValue.value === 'recurring' && validateInputs([errorTimeRecurring]) && validateInputs([errorDaysInWeekProps])) {
                dispatch(createNewNotificationAction(data));
            }
        } else {
            // console.log('Invalid');
        }
    };

    function convertCronToTime(notificationSendType, cron) {
        let cronSplit = cron.split(' ');
        if (cronSplit.length !== 6) return;
        if (notificationSendType === 'ONE_TIME') {
            let dateForDay = new Date(`2023-${cronSplit[4]}-${cronSplit[3]}`);
            let dateForTime = new Date(`2023-04-03 ${cronSplit[2]}:${cronSplit[1]}`);
            setTimeOneTime(dateForTime);
            setDateForOneTimeNotification(dateForDay);
        } else if (notificationSendType === 'RECURRING') {
            let dateForTime = new Date(`2023-04-03 ${cronSplit[2]}:${cronSplit[1]}`);
            let daysInWeekCurrent = cronSplit[5].split(',');
            setTimeRecurring(dateForTime);
            setDaysInWeek(prevState => prevState.map(el => ({ ...el, selected: daysInWeekCurrent.includes(el.label) })));
        }
    }

    useEffect(() => {
        if (notificationId) {
            dispatch(getSingleNotificationAction(notificationId));
        }
        return () => {
            dispatch(resetGetSingleNotificationAction());
        };
    }, []);

    useEffect(() => {
        if (stateIsLoaded(deleteNotificationState)) {
            dispatch(resetDeleteNotificationAction());
            history.goBack();
            toast.success(t('Successfully deleted notification!'));
        }
    }, [deleteNotificationState]);

    useEffect(() => {
        if (stateIsLoaded(singleNotificationState) && notificationId) {
            setNotificationName(singleNotificationState.data.notificationType);
            setNotificationTitle(singleNotificationState.data.title);
            setNotificationText(singleNotificationState.data.body);
            setCurrentCronTypeValue(notificationTimeType.find(el => el.value === singleNotificationState.data.notificationSendType.toLowerCase()));
            setSelectedUsers(singleNotificationState?.data?.userIds);
            convertCronToTime(singleNotificationState?.data?.notificationSendType, singleNotificationState?.data?.cron);
        }
    }, [singleNotificationState]);

    useEffect(() => {
        if (stateIsLoaded(notificationCreatedState)) {
            dispatch(resetNotificationCreatedAction());
            history.goBack();
            toast.success(t('Successfully created notification!'));
        }
    }, [notificationCreatedState]);

    return (
        <Header
            stateDependencies={[singleNotificationState]}
            title={notificationId ? t(`Editing notification #{{notificationId}}`, { notificationId: notificationId }) : t(`Create notification`)}
        >
            <DeleteNotificationModal
                modalOpen={toDelete}
                setModalOpen={setToDelete}
                onAction={() => dispatch(deleteNotificationAction(notificationId))}
                onClose={() => setToDelete(false)}
            />
            <UserPickerDialog
                modalOpen={userModalOpen}
                setModalOpen={setUserModalOpen}
                onClose={() => setUserModalOpen(false)}
                onConfirm={(selectedUsersArray, currentAllUsersSelectedData) => {
                    setUserModalOpen(false);
                    setSelectedUsers(selectedUsersArray);
                    setAllUsersSelectedData(currentAllUsersSelectedData);
                }}
            />
            <div
                role="button"
                id="back-to-question-list"
                onClick={() => {
                    history.goBack();
                }}
                className="d-flex flex-row align-items-center w-50"
                style={{ margin: '20px 50px' }}
            >
                <FontAwesomeIcon color={colors.gray6} className={'mr-2'} icon={faArrowLeft} />
                <Body2 weight="medium" color={colors.gray6} className={'mb-0'}>
                    {t('Back to notifications')}
                </Body2>
            </div>
            <div className="notifications--container">
                <div className="notifications--details d-flex">
                    <div className="d-flex flex-column pt-3">
                        <div>
                            <div className="d-flex flex-row">
                                <Caption1 color={colors.gray6} weight="medium">
                                    {t('NAME')}
                                </Caption1>
                                <Caption1 color={colors.gray6} className="ml-1">
                                    {t('(optional)')}
                                </Caption1>
                            </div>
                            <CustomTextField
                                height={45}
                                width={450}
                                className="form-control"
                                font={15}
                                id="notificationName"
                                type="text"
                                value={notificationName}
                                placeholder={t('Insert name here...')}
                                setValue={handleNotificationNameChange}
                                style={{ marginTop: 10, marginBottom: 10 }}
                            />
                        </div>

                        <div className="notifications--input-container">
                            <div>
                                <div className="d-flex flex-row">
                                    <Caption1 color={colors.gray6} weight="medium">
                                        {t('TITLE')}
                                    </Caption1>
                                    <Caption1 color={colors.gray6} className="ml-1">
                                        {t('(optional)')}
                                    </Caption1>
                                </div>

                                <CustomTextField
                                    font={15}
                                    height={45}
                                    width={450}
                                    id="notificationTitle"
                                    type="text"
                                    value={notificationTitle}
                                    placeholder={t('Insert title here...')}
                                    setValue={handleNotificationTitleChange}
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                />
                            </div>
                            <div style={{ marginTop: 20, marginLeft: 10 }}>
                                <HoverSelectWithCustomComponent
                                    customComp={
                                        <Body2 color={colors.dark} weight="medium">
                                            {`<%>`}
                                        </Body2>
                                    }
                                    showDialog={showTitleParams}
                                    setShowDialog={setShowTitleParams}
                                    options={quickValuesOptions}
                                    onOptionClick={el => {
                                        setNotificationTitle(text => text + el.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="notifications--input-container">
                            <div>
                                <Caption1 color={colors.gray6} weight="medium">
                                    {t('NOTIFICATION TEXT')}
                                </Caption1>
                                <div style={{ marginTop: 10, marginBottom: 10 }}>
                                    <InputErrorHandlerWrapper errorProps={errorTitleProps}>
                                        <CustomTextField
                                            height={75}
                                            width={450}
                                            multiline={true}
                                            placeholder={t('Insert description here...')}
                                            id="notificationText"
                                            type="text"
                                            value={notificationText}
                                            setValue={handleNotificationTextChange}
                                        />
                                    </InputErrorHandlerWrapper>
                                </div>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <HoverSelectWithCustomComponent
                                    customComp={
                                        <Body2 color={colors.dark} weight="medium">
                                            {`<%>`}
                                        </Body2>
                                    }
                                    showDialog={showDescriptionParams}
                                    setShowDialog={setShowDscriptionParams}
                                    options={quickValuesOptions}
                                    onOptionClick={el => {
                                        setNotificationText(text => text + el.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="d-flex flex-row">
                            <div className="mb-3">
                                <Caption1 color={colors.gray6} weight="medium" style={{ marginBottom: 5 }}>
                                    {t('NOTIFICATION TYPE')}
                                </Caption1>
                                <InputErrorHandlerWrapper errorProps={errorsCronTypeProps}>
                                    <CustomSelect
                                        options={notificationTimeType}
                                        placeholder={t('Select type')}
                                        width={150}
                                        value={currentCronTypeValue}
                                        onChange={el => {
                                            setCurrentCronTypeValue(el);
                                        }}
                                    ></CustomSelect>
                                </InputErrorHandlerWrapper>
                            </div>
                            <NotificationType
                                timeOneTime={timeOneTime}
                                setTimeOneTime={setTimeOneTime}
                                timeRecurring={timeRecurring}
                                setTimeRecurring={setTimeRecurring}
                                daysInWeek={daysInWeek}
                                setDaysInWeek={setDaysInWeek}
                                errorDaysInWeekProps={errorDaysInWeekProps}
                                errorTimeOneTimeProps={errorTimeOneTimeProps}
                                errorTimeRecurring={errorTimeRecurring}
                                cronBuilder={cronBuilder}
                                currentCronTypeValue={currentCronTypeValue}
                                dateForOneTimeNotification={dateForOneTimeNotification}
                                setDateForOneTimeNotification={setDateForOneTimeNotification}
                                errorDateOneTimeNotification={dateForOneTimeNotificationProps}
                            />
                        </div>
                        <div style={{ width: 250 }}>
                            <Caption1 color={colors.gray6} weight="medium">
                                {t('TARGET USERS/RECIPIENTS')}
                            </Caption1>
                            <InputErrorHandlerWrapper errorProps={errorSelectedUsers}>
                                <DefaultButton
                                    style={{ width: 250, border: `solid 1px ${colors.gray2}`, height: 45, marginTop: 5 }}
                                    backgroundColor={colors.white}
                                    textColor={colors.dark}
                                    startIcon={<img alt="Recipients" src={NotificationRecipients} height={25} width={25} />}
                                    onClick={() => setUserModalOpen(true)}
                                >
                                    {t('Select recipients from list')}
                                </DefaultButton>
                            </InputErrorHandlerWrapper>
                        </div>
                        <Caption1 style={{ paddingTop: 10 }} color={colors.gray6}>
                            {t('{{usersSelected}} users selected', {
                                usersSelected: allUsersSelectedData?.allUsersCount ? allUsersSelectedData.allUsersCount : selectedUsers.length,
                            })}
                        </Caption1>
                        <div className="mt-4">
                            <DefaultButton textColor={colors.white} onClick={sendNotification}>
                                {notificationId ? t('Update notification') : t('Send notification')}
                            </DefaultButton>
                            {notificationId && (
                                <DefaultButton
                                    style={{ marginLeft: 10 }}
                                    textColor={colors.white}
                                    onClick={() => {
                                        setToDelete(true);
                                    }}
                                >
                                    {t('Delete notification')}
                                </DefaultButton>
                            )}
                            <OutlinedButton
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    history.replace('/internal/notifications');
                                }}
                            >
                                {t('Cancel')}
                            </OutlinedButton>
                        </div>
                    </div>
                </div>
                <div className="notifications--preview">
                    <NotificationPreview title={notificationTitle} text={notificationText} />
                </div>
            </div>
        </Header>
    );
};

export default CreateNotification;
