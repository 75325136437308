import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

export function CoveredMaterial({ totalQuestions, totalQuestionsCovered, totalQuestionsLeft }) {
    const { t } = useTranslation();

    return (
        <div className="row justify-content-center align-items center">
            <div className="col-9 d-flex flex-row border-bottom mt-5">
                <Body2 className="text-muted mb-1">{t('Total questions in all the trainings')}</Body2>
                <Body2 className="text-muted ml-auto mb-1">{totalQuestions}</Body2>
            </div>
            <div className="col-9 d-flex flex-row border-bottom mt-2">
                <Body2 className="mb-1" color={colors.mainTheme}>
                    {t('Covered questions')}
                </Body2>
                <Body2 className="ml-auto mb-1" color={colors.mainTheme}>
                    {totalQuestionsCovered}
                </Body2>
            </div>
            <div className="col-9 d-flex flex-row border-bottom mt-2">
                <Body2 className="text-muted mb-1" color={colors.mainTheme}>
                    {t('Questions left to cover')}
                </Body2>
                <Body2 className="text-muted ml-auto mb-1" color={colors.mainTheme}>
                    {totalQuestionsLeft}
                </Body2>
            </div>
        </div>
    );
}

CoveredMaterial.propTypes = {
    totalQuestions: PropTypes.any,
    totalQuestionsCovered: PropTypes.any,
    totalQuestionsLeft: PropTypes.any,
};
