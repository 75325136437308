import React, { useEffect, useRef, useState } from 'react';
import { Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { FILTERS_LIMIT } from 'static/filters';
import { useTranslation } from 'react-i18next';

import FiltersIcon from 'assets/icons/filter.svg';
import Add from 'assets/icons/icon-add.svg';
import AddDisabled from 'assets/icons/icon-add-disabled.svg';
import Remove from 'assets/icons/icon-remove.svg';

import './Subheader.css';

export default function AddFilters({ activeFilters, updateFilterState }) {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef();
    const buttonRef = useRef();

    const { t } = useTranslation();

    let activeFiltersCount = Object.keys(activeFilters).reduce((counter, el) => {
        if (activeFilters[el].active) counter++;
        return counter;
    }, 0);

    function handleClick(event) {
        if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
            return;
        }

        if (
            dropdownRef.current &&
            buttonRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target) &&
            isOpen
        ) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [isOpen]);

    return (
        <div>
            <div
                ref={buttonRef}
                className="subheader--add-filters"
                onClick={e => {
                    setIsOpen(prevState => !prevState);
                }}
            >
                <Body2 color={colors.gray6}>{t('Add Filters')}</Body2>
                <img src={FiltersIcon} height={15} width={15} alt="Filters" />
            </div>
            {isOpen && (
                <div className="subheader--add-filters-container" ref={dropdownRef}>
                    {Object.keys(activeFilters).map(key => {
                        let currentActive = activeFilters[key].active;
                        let disabled = !currentActive && activeFiltersCount >= FILTERS_LIMIT;

                        return (
                            <div className="subheader--add-filters-item">
                                <Body2 color={disabled ? colors.gray2 : colors.dark}>{t(activeFilters[key].label)}</Body2>
                                <img
                                    style={{ cursor: 'pointer' }}
                                    src={currentActive ? Remove : disabled ? AddDisabled : Add}
                                    alt={activeFilters[key] ? 'remove' : 'add'}
                                    height={20}
                                    width={20}
                                    onClick={() => (disabled ? {} : updateFilterState(key))}
                                />
                            </div>
                        );
                    })}
                    {activeFiltersCount >= FILTERS_LIMIT && (
                        <div className="subheader--add-filters-item">
                            <Caption1 color={colors.gray6}>{t('You can add a maximum of 4 filters at a time')}</Caption1>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
