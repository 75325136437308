import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RoleSettings, UsersList } from './users/RoleSettings';
import { Info } from './info/Info';
import { Settings } from './settings/Settings';

import { fetchUsersWithRoleAction, fetchUsersWithUnassignedRoleAction } from 'redux/actions/users.actions';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { updateRoleName } from 'redux/actions/roles.actions';

import { tabsSidemenuRoles } from 'static/tabs';
import SideMenu from 'components/elements/sidemenu/SideMenu';
import { useTranslation } from 'react-i18next';

import '../Roles.css';

export function SideMenuRoles({ hasData, currentNode, saveInitiated }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('settings');
    const [editingTitle, setEditingTitle] = useState(false);
    const [usersWithRole, setUsersWithRole] = useState([]);
    const [usersWithUnassignedRole, setUsersWithUnassignedRole] = useState([]);

    const [loadMoreTriggerUsersWithRole, setLoadMoreTriggerUsersWithRole] = useState(false);
    const [loadMoreTriggerUsersWithoutRole, setLoadMoreTriggerUsersWithoutRole] = useState(false);

    const [searchQueryUsersWithRole, setSearchQueryUsersWithRole] = useState('');
    const [searchQueryUsersWithoutRole, setSearchQueryUsersWithoutRole] = useState('');

    const usersWithRoleState = useSelector(state => state.userManagement.usersWithRole);
    const usersWithUnassignedRoleState = useSelector(state => state.userManagement.usersWithUnassignedRole);
    const modifiedUsersState = useSelector(state => state.userManagement.usersModifiedInRoles);
    const editUsersInBatchState = useSelector(state => state.userManagement.editUsersInBatch);

    useEffect(() => {
        let previousRoleId = usersWithRoleState?.data?.role;
        let currentPage = usersWithRoleState?.data?.page;
        let totalPages = usersWithRoleState?.data.totalPages;

        //different role
        if (previousRoleId !== currentNode.roleId) {
            dispatch(fetchUsersWithRoleAction(currentNode.roleId, searchQueryUsersWithRole, 0, false));
        } else if (totalPages === 0) {
            setUsersWithRole([]);
        } else if (currentPage + 1 < totalPages) {
            dispatch(fetchUsersWithRoleAction(currentNode.roleId, searchQueryUsersWithRole, currentPage + 1));
        }
    }, [currentNode.roleId, loadMoreTriggerUsersWithRole]);

    useEffect(() => {
        let currentPage = usersWithUnassignedRoleState?.data?.page;
        let totalPages = usersWithUnassignedRoleState?.data.totalPages;

        if (currentPage === undefined || totalPages === undefined || totalPages === 0) {
            dispatch(fetchUsersWithUnassignedRoleAction(searchQueryUsersWithoutRole, 0, false));
        } else if (currentPage + 1 < totalPages) {
            dispatch(fetchUsersWithUnassignedRoleAction(searchQueryUsersWithoutRole, currentPage + 1, false));
        }
    }, [loadMoreTriggerUsersWithoutRole]);

    useEffect(() => {
        if (stateIsLoaded(editUsersInBatchState)) {
            dispatch(fetchUsersWithRoleAction(currentNode.roleId, searchQueryUsersWithRole, 0, true));
            dispatch(fetchUsersWithUnassignedRoleAction(searchQueryUsersWithoutRole, 0, true));
        }
    }, [saveInitiated, editUsersInBatchState]);

    useEffect(() => {
        if (stateIsLoaded(usersWithRoleState)) {
            if (modifiedUsersState?.data !== undefined) {
                setUsersWithRole([
                    ...modifiedUsersState?.data?.filter(
                        u =>
                            u.companyRole?.roleId === currentNode.roleId &&
                            ((!u.name && !searchQueryUsersWithRole) || u.name?.toUpperCase()?.includes(searchQueryUsersWithRole.toUpperCase()))
                    ),
                    ...usersWithRoleState.data?.users,
                ]);
            } else {
                setUsersWithRole(usersWithRoleState.data?.users);
            }
        }
    }, [usersWithRoleState, modifiedUsersState]);

    useEffect(() => {
        if (stateIsLoaded(usersWithUnassignedRoleState)) {
            if (modifiedUsersState?.data !== undefined) {
                setUsersWithUnassignedRole([
                    ...modifiedUsersState?.data?.filter(
                        u =>
                            u.companyRole === null &&
                            ((!u.name && !searchQueryUsersWithRole) || u.name?.toUpperCase()?.includes(searchQueryUsersWithoutRole.toUpperCase()))
                    ),
                    ...usersWithUnassignedRoleState.data?.users,
                ]);
            } else {
                setUsersWithUnassignedRole(usersWithUnassignedRoleState.data?.users);
            }
        }
    }, [usersWithUnassignedRoleState, modifiedUsersState]);

    function setNewName(newName) {
        dispatch(updateRoleName(currentNode?.roleId, newName));
    }

    function validateName() {
        if (currentNode?.roleName.length <= 0) {
            dispatch(updateRoleName(currentNode?.roleId, 'New role'));
        }
    }

    let tabsMapping = {
        settings: (
            <RoleSettings
                setLoadMoreTriggerUsersWithoutRole={setLoadMoreTriggerUsersWithoutRole}
                setLoadMoreTriggerUsersWithRole={setLoadMoreTriggerUsersWithRole}
                loadMoreTriggerUsersWithRole={loadMoreTriggerUsersWithRole}
                loadMoreTriggerUsersWithoutRole={loadMoreTriggerUsersWithoutRole}
                usersWithRole={usersWithRole}
                usersWithUnassignedRole={usersWithUnassignedRole}
                currentNode={currentNode}
                searchQueryUsersWithRole={searchQueryUsersWithRole}
                searchQueryUsersWithoutRole={searchQueryUsersWithoutRole}
                setSearchQueryUsersWithRole={setSearchQueryUsersWithRole}
                setSearchQueryUsersWithoutRole={setSearchQueryUsersWithoutRole}
            />
        ),
        info: <Info currentNode={currentNode} />,
    };

    return (
        <SideMenu
            hasData={hasData}
            noDataText={t('No existing roles in this company')}
            isEditingInput={editingTitle}
            setEditingInput={setEditingTitle}
            inputKey={currentNode?.roleName}
            defaultValue={currentNode?.roleName}
            inputOnBlur={() => {
                setEditingTitle(false);
                validateName();
            }}
            inputOnChange={event => {
                setNewName(event.target.value);
            }}
            tabsSidemenu={tabsSidemenuRoles}
            tabsMapping={tabsMapping}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
    );
}

SideMenuRoles.propTypes = {
    currentNode: PropTypes.any,
    saveInitiated: PropTypes.any,
};
