import React, { useEffect } from 'react';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { H6 } from 'style/typography/Heading';

import { stateHasFailed } from 'redux/core/stateHelpers';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import SomethingWentWrongIcon from 'assets/icons/something-went-wrong.svg';

import './ErrorBoundaryComponent.css';

export function ErrorBoundaryComponent({ children, dependencies, key }) {
    return (
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} key={key}>
            {<IntermediateErrorBoundary dependencies={dependencies}>{children}</IntermediateErrorBoundary>}
        </ErrorBoundary>
    );
}

export function IntermediateErrorBoundary({ children, dependencies = [] }) {
    const { showBoundary, resetBoundary } = useErrorBoundary();
    const location = useLocation();

    useEffect(() => {
        resetBoundary();
    }, [location]);

    useEffect(() => {
        dependencies.forEach(dependency => {
            if (stateHasFailed(dependency)) {
                showBoundary('State not loaded: ' + JSON.stringify(dependency));
                return;
            }
        });
    }, [...dependencies]);

    return children;
}

export function ErrorBoundaryFallback() {
    const { t } = useTranslation();

    return (
        <div className="error-boundary" >
            <img src={SomethingWentWrongIcon} height={130} width={130} />
            <H6 color={colors.secoundaryText}>{t('Oops! Something went wrong.')}</H6>
            <Body2 style={{ width: '40%' }} color={colors.gray6}>
                {t("We're sorry, there was an error while trying to load this section. Please reload the page or come back a bit later.")}
            </Body2>
        </div>
    );
}
