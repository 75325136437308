import PropTypes from 'prop-types';
import React from 'react';

import { useImageEditor } from 'services/image_management/image-editor-management';
import PaginationComponent from 'components/features/data_table/pagination_component/PaginationComponent';

export function ImagePagination({ currentState }) {
    const { currentPage, setCurrentPage, paginationTotalPages, size, setSize } = useImageEditor();

    const getNextPage = () => {
        if (!currentState?.data) {
            return;
        }
        if (currentPage < currentState.data.totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getPrevPage = () => {
        if (!currentState?.data) {
            return;
        }
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        paginationTotalPages && (
            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', paddingLeft: '20px' }}>
                <PaginationComponent
                    getPrev={getPrevPage}
                    getNext={getNextPage}
                    getPage={page => {
                        setCurrentPage(page);
                    }}
                    currentPage={currentPage}
                    totalPages={currentState?.data?.totalPages}
                    totalItems={currentState?.data?.totalElements}
                    size={size}
                    setSize={size => {
                        setCurrentPage(0);
                        setSize(size);
                    }}
                />
            </div>
        )
    );
}

ImagePagination.propTypes = {
    currentState: PropTypes.any,
};
