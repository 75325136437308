import { applyMiddleware, createStore, compose } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { routerMiddleware } from 'react-router-redux';
import reducers from '../reducers';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import tokenMiddleware from './middleware/tokenMiddleware';

const middleware = applyMiddleware(tokenMiddleware, promise, thunk, routerMiddleware(createBrowserHistory()));

const persistConfig = {
    key: 'coach',
    storage,
    whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) || compose;

const store = createStore(persistedReducer, composeEnhancers(middleware));
const persistor = persistStore(store);

export default store;
export { persistor };
