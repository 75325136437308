import { actionTypes } from './local-actions';

import _ from 'lodash';

export const insertionControlReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.setIndex: {
            return { ...state, index: action.payload };
        }
        case actionTypes.setView: {
            return { ...state, view: action.payload };
        }
        case actionTypes.setImageLimit: {
            return { ...state, imageLimit: action.payload };
        }
        case actionTypes.setImageResizerOpener: {
            return { ...state, setOpenImageResizer: action.payload };
        }
        case actionTypes.setCurrentActivePreviewImage: {
            return { ...state, currentActivePreviewImage: action.payload };
        }
        case actionTypes.setImageFiles: {
            return { ...state, imageFiles: action.payload };
        }
        case actionTypes.setCurrentActiveIndex: {
            return { ...state, currentActiveIndex: action.payload };
        }
        case actionTypes.setAspectRatio: {
            return { ...state, aspectRatio: action.payload };
        }
        case actionTypes.setCropAndZoom: {
            let { file, crop, zoom, aspectRatio } = action.payload;
            if (crop) {
                file.crop[aspectRatio] = crop;
            }
            if (zoom) {
                file.zoom[aspectRatio] = zoom;
            }

            // setImageFiles({ index: index, files: imageFiles.files, filesBlob: [...imageFiles.filesBlob] });
            return {
                ...state,
                imageFiles: {
                    index: state.index,
                    files: state.imageFiles.files,
                    filesBlob: [...state.imageFiles.filesBlob],
                    isEditing: state.imageFiles.isEditing,
                },
            };
        }
        case actionTypes.setCroppedAreaPixels: {
            let { file, croppedAreaPixels, aspectRatio } = action.payload;

            file.croppedAreaPixels[aspectRatio] = croppedAreaPixels;

            // setImageFiles({ index: index, files: imageFiles.files, filesBlob: [...imageFiles.filesBlob] });
            return {
                ...state,
                imageFiles: {
                    index: state.index,
                    files: state.imageFiles.files,
                    filesBlob: [...state.imageFiles.filesBlob],
                    isEditing: state.imageFiles.isEditing,
                },
            };
        }
        case actionTypes.deleteCurrentImage: {
            state.imageFiles.filesBlob.splice(state.currentActiveIndex, 1);
            // setImageFiles(imageFiles);

            return { ...state, imageFiles: { ...state.imageFiles } };
        }
        case actionTypes.setTreeData: {
            return { ...state, treeData: action.payload };
        }
        case actionTypes.setCurrentTab: {
            return { ...state, currentTab: action.payload };
        }
        case actionTypes.setCurrentNode: {
            return { ...state, currentNode: action.payload };
        }
        case actionTypes.setCurrentPath: {
            return { ...state, currentPath: action.payload };
        }
        case actionTypes.setCurrentPage: {
            return { ...state, currentPage: action.payload };
        }
        case actionTypes.setPaginationTotalPages: {
            return { ...state, paginationTotalPages: action.payload };
        }
        case actionTypes.setGalleryImages: {
            return { ...state, galleryImages: action.payload };
        }
        case actionTypes.setFilteredImages: {
            return { ...state, filteredImages: action.payload };
        }
        case actionTypes.setSelectedImages: {
            return { ...state, selectedImages: action.payload };
        }
        case actionTypes.setImageSearchValue: {
            return { ...state, imageSearchValue: action.payload };
        }
        case actionTypes.setSize: {
            return { ...state, size: action.payload };
        }
        case actionTypes.setIsGrid: {
            return { ...state, isGrid: action.payload };
        }
        case actionTypes.setSortBy: {
            return { ...state, sortBy: action.payload };
        }
        case actionTypes.setAllImagesSelected: {
            return { ...state, allImagesSelected: action.payload };
        }
        case actionTypes.updateSelectedImage: {
            let selectedImages = _.cloneDeep(state.selectedImages);
            const index = selectedImages.findIndex(el => el.id == action.payload.fullObj.id);
            if (index > -1) {
                selectedImages.splice(index, 1);
            }
            if (action.payload.isSelected) {
                selectedImages.push(action.payload.fullObj);
            }
            return { ...state, selectedImages: selectedImages };
        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};
