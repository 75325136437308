import PropTypes from 'prop-types';
import React, { Component, useEffect, useMemo, useState } from 'react';
import { Topic } from './Topic';
import { DragSource } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

import './Topic.css';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { updateAllTopicsAction, updateTopicActionsType } from 'redux/actions/trainings.actions';
import { useTranslation } from 'react-i18next';

export function TopicsWrapper({ draggingNode, setInfo }) {
    const allTopics = useSelector(state => state.content.topics.allTopicsFromCompany.data);
    const localTopicChanges = useSelector(state => state.content.topics.localTopicChanges);
    const [filteredData, setFilteredData] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        setTimeoutLastExecute.addCallback(
            () => {
                let localTopics =
                    Object.keys(localTopicChanges.data)
                        .filter(key => {
                            let localTopic = localTopicChanges.data[key].treeData;
                            return (
                                localTopic.usedIn?.map(el => el.setId)?.includes(setInfo.setId) || localTopic.removedInSetIds?.includes(setInfo.setId)
                            );
                        })
                        .map(key => localTopicChanges.data[key].treeData) ?? [];

                setFilteredData(
                    [
                        ...allTopics.filter(el => {
                            if (localTopics.map(el => el.treeIndex).includes(el.treeIndex)) return false;

                            return (
                                el?.title
                                    .trim()
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                el?.titleForTraining
                                    .trim()
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            );
                        }),
                        ...localTopics,
                    ].sort((el1, el2) => {
                        if (
                            el1.usedIn &&
                            el1.usedIn.find(el => el.setId == setInfo.setId) &&
                            (!el1.removedInSetIds || !el1.removedInSetIds?.includes(setInfo.setId)) &&
                            !(el2.usedIn && el2.usedIn.find(el => el.setId == setInfo.setId))
                        ) {
                            return -1;
                        } else if (
                            el2.usedIn &&
                            el2.usedIn.find(el => el.setId == setInfo.setId) &&
                            (!el2.removedInSetIds || !el2.removedInSetIds?.includes(setInfo.setId)) &&
                            !(el1.usedIn && el1.usedIn.find(el => el.setId == setInfo.setId))
                        ) {
                            return 1;
                        } else {
                            if (el1.title < el2.title) {
                                return -1;
                            } else if (el1.title > el2.title) {
                                return 1;
                            } else return 0;
                        }
                    })
                );
            },

            250,
            'topicsTreeFitlering'
        );
    }, [allTopics, searchValue, localTopicChanges]);

    const totalUsedTopics = allTopics?.filter(el => el?.usedIn?.find(el => el.setId == setInfo?.setId)).length || 0;

    const externalNodeType = 'TOPIC';

    const externalNodeSpec = {
        // This needs to return an object with a property `node` in it.
        // Object rest spread is recommended to avoid side effects of
        // referencing the same object in different trees.
        beginDrag: componentProps => ({ node: { ...componentProps.node } }),
    };
    const externalNodeCollect = (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        // Add props via react-dnd APIs to enable more visual
        // customization of your component
        didDrop: monitor.didDrop(),
    });
    class externalNodeBaseComponent extends Component {
        render() {
            const { connectDragSource, didDrop, node } = this.props;
            if (node.usedIn && node.usedIn.find(el => el.setId == setInfo.setId) && !node.removedInSetIds?.includes(setInfo.setId)) {
                return (
                    <div key={node.treeIndex}>
                        <Topic
                            treeIndex={node.treeIndex}
                            used={true}
                            title={node.title}
                            questions={node.numberOfQuestions}
                            learnerTitle={node.titlesInTrainings?.[setInfo.setId]}
                        />
                    </div>
                );
            } else {
                if (didDrop) {
                    if (node.treeIndex === draggingNode?.treeIndex) {
                        dispatch(
                            updateAllTopicsAction(node.treeIndex, updateTopicActionsType.update, {
                                usedIn: [{ setId: setInfo?.setId }],
                                setId: setInfo?.setId,
                                learnersTitle: node.title,
                            })
                        );
                    }
                }
                return connectDragSource(
                    <div>
                        <Topic treeIndex={node.treeIndex} used={false} title={node.title} questions={node.numberOfQuestions} />
                    </div>,
                    { dropEffect: 'copy' }
                );
            }
        }
    }

    const TopicExternalComponent = DragSource(externalNodeType, externalNodeSpec, externalNodeCollect)(externalNodeBaseComponent);

    const topicsDom = useMemo(() => {
        return filteredData?.map((t, index) => {
            return <TopicExternalComponent key={'drag' + t.treeIndex} node={t} />;
        });
    }, [filteredData, draggingNode]);

    return (
        <div className="topics--container">
            <div>
                <CustomOutlinedTextField
                    isSearch={true}
                    value={searchValue}
                    setValue={setSearchValue}
                    placeholder={t('Search..')}
                ></CustomOutlinedTextField>
            </div>
            <div className={'d-flex flex-row mt-2'}>
                <div className={'d-flex flex-row align-items-end'}>
                    <div className={'d-flex flex-row pr-3 border-right'}>
                        <Caption1 className={'font-weight-bold mr-1'}>{allTopics.length}</Caption1>
                        <Caption1 color={colors.gray6}>{t('TOTAL')}</Caption1>
                    </div>
                    <div className={'d-flex flex-row pl-3'}>
                        <Caption1 className={'font-weight-bold mr-1'}>{totalUsedTopics}</Caption1>
                        <Caption1 color={colors.gray6}>{t('USED IN TRAINING')}</Caption1>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column topics--component">{topicsDom}</div>
        </div>
    );
}

TopicsWrapper.propTypes = {
    connectDragSource: PropTypes.func,
    didDrop: PropTypes.any,
    draggingNode: PropTypes.any,
    node: PropTypes.any,
    setInfo: PropTypes.any,
};
