import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useContentInsertionQuestion, useImageCategories } from 'services/content-management/insertion-control';
import { optionDefaults } from 'services/content-management/insertion-control/local-constants';
import { InputContentWrapper } from 'components/wrappers/InputContentWrapper';
import SortItInput from './SortItInput';
import SortItPreview from './SortItPreview';
import { initialize } from 'react-ga';

const AddQuestionSortIt = ({ game, id, filters }) => {
    const { setQuestion } = useContentInsertionQuestion();
    const { setOptions } = useImageCategories();

    const sortitInterval = useRef();

    const initializeQuestion = () => {
        setQuestion('');
        setOptions(
            Array(2)
                .fill(0)
                .map((_, index) => ({ ...optionDefaults.default, id: index }))
        );
    };

    useEffect(() => {
        initializeQuestion();
        //eslint-disable-next-line

        return () => clearInterval(sortitInterval.current);
    }, []);

    return (
        <div className="d-flex flex-row h-100 m-0">
            <InputContentWrapper id={id} filters={filters}>
                <div className="d-flex flex-row w-100 h-100">
                    <SortItInput game={game} id={id} filters={filters} initializeQuestion={initialize} />
                    <SortItPreview sortitInterval={sortitInterval} />
                </div>
            </InputContentWrapper>
        </div>
    );
};

AddQuestionSortIt.propTypes = {
    editIncomplete: PropTypes.any,
    filters: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
};

export default AddQuestionSortIt;
