import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { Body2 } from 'style/typography/Body';
import { convertFromFlatToTree } from 'services/utils/treeHelpers';

import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';
import Dropdown from 'assets/icons/filter-caret.svg';
import Close from 'assets/icons/filter-close.svg';

import './TopicDropdownSelectorTrainings.css';
import { getAllTrainingsAction } from 'redux/actions/trainings.actions';
import { useTranslation } from 'react-i18next';

export default function TopicDropdownSelectorTrainings({ value, onChange, width }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const allTrainingsState = useSelector(state => state.content.allTrainings);

    const [currentTraining, setCurrentTraining] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState({ path: null, value: null });

    const dropdownRef = useRef(null);
    const dropdownMenuRef = useRef(null);
    const closeRef = useRef(null);

    function handleClick(event) {
        if (dropdownMenuRef.current && dropdownMenuRef.current.contains(event.target)) {
            return;
        }
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
            setIsOpen(false);
        } else if (dropdownRef.current && !closeRef.current?.contains(event.target) && dropdownRef.current.contains(event.target)) {
            setIsOpen(prevData => !prevData);
        }
        setSearchValue('');
        setSearchResult({ path: null, value: null });
    }

    function findTopicInTrainings(searchString) {
        let learningSets = allTrainingsState.data.learningSets;
        let unusedTopics = allTrainingsState.data.unusedCategories;

        for (let i = 0; i < learningSets.length; i++) {
            let item = findItemInTree(learningSets[i].treeStructure, { path: [i], found: false }, searchString);
            if (item?.found)
                return {
                    ...item,
                    setId: learningSets[i].learningSetInfo?.setId,
                };
        }
        let item = findItemInTree(unusedTopics, { path: [learningSets.length], found: false }, searchString);
        if (item?.found)
            return {
                ...item,
                setId: 'unused',
            };
    }

    function findItemInTree(tree, path, searchString) {
        for (let i = 0; i < tree.length; i++) {
            if (tree[i].title?.toLowerCase()?.includes(searchString?.toLowerCase())) {
                path = {
                    path: [...path.path, i],
                    found: true,
                    value: tree[i].title,
                };
                return path;
            }
            if (tree[i]?.children?.length > 0) {
                let foundItem = findItemInTree(tree[i].children, { ...path, path: [...path.path, i] }, searchString);
                if (foundItem?.found) return foundItem;
            }
        }
        return null;
    }

    function onInputChange(e) {
        setIsOpen(true);
        setSearchValue(e.target.value);

        if (!e.target.value) {
            setSearchResult({ path: null, value: null });
            return;
        }

        let path = findTopicInTrainings(e.target.value);
        if (path) {
            setSearchResult(path);
        }
    }

    function getChildren(topicTreeItem) {
        return (
            <div key={topicTreeItem.treeIndex} style={{ padding: '0 15px' }}>
                <div
                    className="topic-dropdown-selector-trainings--topic"
                    style={{ border: `1px solid ${topicTreeItem.title == searchResult?.value ? colors.mainTheme : 'transparent'}` }}
                    onClick={() => {
                        setIsOpen(false);
                        onChange(topicTreeItem.treeIndex);
                        setSearchResult({ path: null, value: null });
                        setSearchValue('');
                    }}
                >
                    <Body2 color={value?.value == topicTreeItem.treeIndex ? colors.mainTheme : colors.primaryText}>
                        {`${topicTreeItem.title} ${topicTreeItem.children.length > 0 ? '(' + topicTreeItem.children.length + ')' : ''}`}
                    </Body2>
                </div>
                {topicTreeItem.children.map(el => getChildren(el))}
            </div>
        );
    }

    function emptyState() {
        onChange('');
        setSearchResult({ path: null, value: null });
        setSearchValue('');
    }

    useEffect(() => {
        if (searchResult.setId) {
            setCurrentTraining(searchResult.setId);
        }

        let foundItem = dropdownMenuRef.current;
        for (let i in searchResult.path) {
            foundItem = foundItem?.children[+searchResult.path[i] + 1];
        }
        if (foundItem) {
            dropdownMenuRef.current.scrollTop = foundItem.offsetTop - 60;
        }
    }, [searchResult]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [isOpen]);

    useEffect(() => {
        dispatch(getAllTrainingsAction());
    }, []);

    return (
        <div style={{ position: 'relative', zIndex: 10, width: width }}>
            <div ref={dropdownRef} className="topic-dropdown-selector-trainings--select">
                <div className="topic-dropdown-selector-trainings--title">
                    <input
                        value={(searchValue === '' && !isOpen ? value?.label : searchValue) ?? ''}
                        placeholder={t('Topic')}
                        onChange={onInputChange}
                        style={{ outline: 'none', border: 'none' }}
                    />
                </div>
                {value && <img alt="Close" ref={closeRef} className={'cursor-pointer'} src={Close} height={17} width={17} onClick={emptyState} />}
                {!value && <div style={{ height: '17px', width: '17px' }}></div>}
                <img alt="Arrow" className={'ml-1 cursor-pointer'} height={17} width={17} src={Dropdown} />
            </div>
            {isOpen && stateIsLoaded(allTrainingsState) && allTrainingsState.data && (
                <div ref={dropdownMenuRef} className="topic-dropdown-selector-trainings--menu">
                    <div style={{ paddingBottom: '5px' }}>
                        <Caption1 color={colors.gray6}>{t('Trainings')}</Caption1>
                    </div>
                    {allTrainingsState.data.learningSets.map(el => {
                        return (
                            <div key={el.setId}>
                                <div
                                    className="topic-dropdown-selector-trainings--training"
                                    onClick={() => {
                                        currentTraining === el.learningSetInfo?.setId
                                            ? setCurrentTraining(null)
                                            : setCurrentTraining(el.learningSetInfo?.setId);
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                                        <Body2 weight="medium">{el.learningSetInfo?.name}</Body2>
                                        <Caption1 weight="regular" color={colors.gray6}>
                                            ({el.categoryTree.length} {t('topics')})
                                        </Caption1>
                                    </div>
                                    <img
                                        alt="Arrow"
                                        className={'ml-2 cursor-pointer'}
                                        height={15}
                                        width={15}
                                        src={currentTraining === el.learningSetInfo?.setId ? AngleUp : AngleDown}
                                    />
                                </div>
                                {currentTraining === el.learningSetInfo?.setId &&
                                    el.categoryTree.length > 0 &&
                                    getChildren(convertFromFlatToTree(el.categoryTree)[0])}
                                {currentTraining === el.learningSetInfo?.setId && el.categoryTree?.length == 0 && (
                                    <div style={{ padding: '5px 20px' }}>
                                        <Caption1 color={colors.gray6}>{t('No topics here')}</Caption1>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <div
                        className="topic-dropdown-selector-trainings--training"
                        onClick={() => {
                            currentTraining == 'unused' ? setCurrentTraining(null) : setCurrentTraining('unused');
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <Body2 weight="medium">{t('Unused topics')}</Body2>
                            <Caption1 weight="regular" color={colors.gray6}>
                                ({allTrainingsState.data.unusedCategories?.length} {t('topics')})
                            </Caption1>
                        </div>
                        <img
                            alt="Arrow"
                            className={'ml-2 cursor-pointer'}
                            height={15}
                            width={15}
                            src={currentTraining === 'unused' ? AngleUp : AngleDown}
                        />
                    </div>
                    {currentTraining === 'unused' &&
                        allTrainingsState.data.unusedCategories?.map(topic => {
                            return (
                                <div
                                    key={topic.treeIndex}
                                    style={{
                                        padding: '5px 20px',
                                        cursor: 'pointer',
                                        border: `1px solid ${topic.title === searchResult?.value ? colors.mainTheme : 'transparent'}`,
                                    }}
                                    onClick={() => {
                                        onChange(topic.treeIndex);
                                        setIsOpen(false);
                                        setSearchResult({ path: null, value: null });
                                        setSearchValue('');
                                    }}
                                >
                                    <Body2 color={value?.value == topic.treeIndex ? colors.mainTheme : colors.primaryText}>{topic.title}</Body2>
                                </div>
                            );
                        })}
                    {currentTraining === 'unused' && allTrainingsState.data.unusedCategories?.length === 0 && (
                        <div className="topic-dropdown-selector-trainings--topic">
                            <Caption1 color={colors.gray6}>{t('No unused topics')}</Caption1>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

TopicDropdownSelectorTrainings.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    width: PropTypes.any,
};
