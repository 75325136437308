import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SkeletonLoader } from 'components/elements/SkeletonLoader';
import { Link } from 'react-router-dom';
import { getTextWidth } from 'services/utils/objectHelpers';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import NoTableValue from './NoTableValue';

export function TableHeader({ text }) {
    return (
        <Body2 weight="light" color={colors.gray1}>
            {text}
        </Body2>
    );
}

TableHeader.propTypes = {
    text: PropTypes.string,
};

export function TableCellContent({ row, text, elementBefore, elementAfter, cellFactor, isLoading, onClick, to: linkTo }) {
    const [cellWidth, setCellWidth] = useState(false);

    const padding = 30;

    useEffect(() => {
        if (document.getElementById('row-0')?.offsetWidth && cellFactor) {
            setCellWidth(document.getElementById('row-0')?.offsetWidth * cellFactor);
        }
    }, []);

    let questionText = text;
    if (cellWidth) {
        let textWidth = getTextWidth({ text: text, font: '16px Aktiv Grotesk' });
        if (textWidth > cellWidth) {
            while (getTextWidth({ text: questionText, font: '16px Aktiv Grotesk' }) > cellWidth - padding) {
                let questionCharactersLength = questionText.length;
                questionText = questionText.substring(0, questionCharactersLength - 4);
                questionText = questionText + '...';
            }
        }
    }

    if (isLoading || (cellFactor && !cellWidth)) {
        return <SkeletonLoader />;
    }

    let childItem = !text ? (
        <NoTableValue />
    ) : (
        <div
            title={text}
            onClick={() => {
                if (onClick) {
                    onClick(row);
                }
            }}
            className={'d-flex flex-row align-items-center w-100'}
        >
            {elementBefore}
            <Body2 color={colors.gray6} className={'m-0'}>
                {questionText}
            </Body2>
            {elementAfter}
        </div>
    );

    if (linkTo) {
        return (
            <Link to={linkTo} style={{ color: colors.gray6 }}>
                {childItem}
            </Link>
        );
    } else {
        return childItem;
    }
}

TableCellContent.propTypes = {
    cellFactor: PropTypes.any,
    elementAfter: PropTypes.any,
    elementBefore: PropTypes.any,
    isLoading: PropTypes.bool,
    linkTo: PropTypes.any,
    onClick: PropTypes.func,
    text: PropTypes.any,
};
