import React, { useState } from 'react';

import { Body1 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';
import InfoTooltip from 'components/elements/tooltips/InfoTooltip';

import InfoIcon from 'assets/icons/info-tooltip.svg';

import './TrainingSettings.css';

export default function TrainingSettingsItem({ title, child, visible = true }) {
    const { t } = useTranslation();

    const [hovering, setHovering] = useState(false);

    if (!visible) {
        return <div></div>;
    }

    return (
        <div className="training-settings--item">
            <div className="training-settings--item-title">
                <Body1 color={colors.dark}>{t(title)}</Body1>
                <div>
                    <img
                        src={InfoIcon}
                        height={18}
                        width={18}
                        alt="info"
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={() => setHovering(true)}
                        onMouseLeave={() => setHovering(false)}
                    />
                    {/* {hovering && (
                        <InfoTooltip
                            visible={hovering}
                            message={t(
                                '‘Count only active users’ shows if you would like to count only currently active users of the application in the general statistics and analytics.'
                            )}
                            position={'bottom'}
                        />
                    )} */}
                </div>
            </div>

            <div className="training-settings--item-child">{child}</div>
        </div>
    );
}
