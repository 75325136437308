import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import MultipleUsersInvite from './MultipleUsersInvite';
import UsersInvitationHeader from './UsersInvitationHeader';
import DialogTitle from '@material-ui/core/DialogTitle';
import { UploadUsers } from './UploadUsers';
import Paper from '@material-ui/core/Paper';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import 'style/users-upload.style.css';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import {
    showNextErrorAction,
    resetUsersInvitedAction,
    resetNextErrorAction,
    inviteMultipleAction,
    resetInviteMultipleUsersAction,
    inviteMultipleAdminsAction,
    resetInviteMultipleUsersAdminAction,
    resetAdminsInvitedAction,
    resetInviteUsersProgressAction,
} from 'redux/actions/users.actions';
import './InvitationModal.css';
import { H4, H5, H6 } from 'style/typography/Heading';
import { Body1 } from 'style/typography/Body';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { colors } from 'style/colors';
import MultipleUsersInviteAdmin from './MultipleUsersInviteAdmin';
import { UploadUsersFull } from './UploadUsersFull';
import { Trans, useTranslation } from 'react-i18next';
import MultipleUsersInviteNumber from './MultipleUsersInviteNumber';

const InvitationModal = ({ isInviting, onDoneInviting, getUsers }) => {
    const { t } = useTranslation();

    const classes = useStyles();
    const company = useSelector(state => state.userManagement.company);
    const [selectedTab, setSelectedTab] = useState(0);
    const [warningsVisible, setWarningsVisible] = useState(false);
    const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);
    const [isInviteManuallyButtonDisabled, setIsInviteManuallyButtonDisabled] = useState(true);

    const [numErrors, setNumErrors] = useState(0);
    const [nextError, setNextError] = useState(null);

    const usersInvited = useSelector(state => state.userManagement.usersInvited);
    const adminsInvited = useSelector(state => state.userManagement.adminsInvited);

    const inviteMultipleUsers = useSelector(state => state.userManagement.inviteMultipleUsers);
    const inviteMultipleAdmins = useSelector(state => state.userManagement.inviteMultipleUsersAdmin);

    const inviteUsersProgress = useSelector(state => state.userManagement.inviteUsersProgress);
    const [inviteMultiple, setInviteMultiple] = useState(inviteMultipleAdmins);

    const uploadUsersState = useSelector(state => state.userManagement.uploadUsers);

    const dispatch = useDispatch();

    const [parsedData, setParsedData] = useState(null);

    const [importedUsers, setImportedUsers] = useState(false);
    // const [userIsAdded, setUserIsAdded] = useState(false);

    const [showReplaceDataAlert, setShowCurrentDataAlert] = useState(false);
    const [temporaryParsedData, setTemporaryParsedData] = useState(null);

    const [showErrorBox, setShowErrorBox] = useState(false);
    const [showFixAlert, setShowFixAlert] = useState(false);

    // useEffect(() => {
    //     if (stateIsLoaded(usersInvitedManually)) {
    //         let allErrors = getErrors(usersInvitedManually.data);
    //         if (allErrors.length > 0) {
    //             setIsInviteManuallyButtonDisabled(true);
    //         } else setIsInviteManuallyButtonDisabled(false);
    //     }
    // }, [usersInvitedManually]);

    function checkErrors() {
        if (selectedTab === 0 || selectedTab === 1) {
            let err = null;
            let allErrors = getErrors(usersInvited.data);
            if (allErrors.length > 0) err = allErrors[0];
            setNextError(err);
            setNumErrors(allErrors.length);
            if (allErrors.length > 0) {
                setIsUploadButtonDisabled(true);
            } else {
                setIsUploadButtonDisabled(false);
            }
        } else if (selectedTab === 2) {
            let err = null;
            let allErrors = getErrors(adminsInvited.data);
            if (allErrors.length > 0) err = allErrors[0];
            setNextError(err);
            setNumErrors(allErrors.length);
            if (allErrors.length > 0) {
                setIsUploadButtonDisabled(true);
            } else {
                setIsUploadButtonDisabled(false);
            }
        } else {
            setNumErrors(0);
        }
    }

    const getErrors = data => {
        return data.reduce((acc, item) => {
            if (item.emailError) {
                acc.push({
                    id: item.formId,
                    info: {
                        type: 'email',
                        message: item.emailError.message,
                    },
                });
            }
            if (item.roleError) {
                acc.push({
                    id: item.formId,
                    info: {
                        type: 'role',
                        message: item.roleError.message,
                    },
                });
            }
            return acc;
        }, []);
    };

    const handleClose = () => {
        onDoneInviting();
        resetState();
    };

    const resetState = () => {
        setSelectedTab(0);
        setWarningsVisible(false);
        setNumErrors(0);
        setImportedUsers(false);
        setParsedData(null);
        setNextError(null);
        setTemporaryParsedData(null);
        setShowErrorBox(false);
        dispatch(resetUsersInvitedAction());
        dispatch(resetAdminsInvitedAction());

        dispatch(resetNextErrorAction());
    };

    const handleTabChange = tab => setSelectedTab(tab);

    const handleNextErrorShow = () => {
        if (nextError) {
            dispatch(showNextErrorAction(nextError));
        }
    };

    const handleSubmitUsers = () => {
        if (showErrorBox && numErrors > 0) {
            setShowFixAlert(true);
        } else {
            if (numErrors > 0) {
                setShowErrorBox(true);
            } else {
                if (selectedTab === 2) {
                    dispatch(inviteMultipleAdminsAction(adminsInvited.data.map(el => el.email)));
                } else {
                    dispatch(inviteMultipleAction(usersInvited.data));
                }
            }
        }
        // if()
        // dispatch(inviteMultipleAction(usersInvited.data));
        // handleClose();
    };

    const handleUsersChange = parsed => {
        setImportedUsers(true);
        if (usersInvited.data.length > 0) {
            let hasEmailValue = usersInvited.data?.map(d => d.email).some(e => e);
            let hasRoleValue = usersInvited.data?.map(d => d.role).some(e => e);
            if (hasEmailValue || hasRoleValue) {
                setShowCurrentDataAlert(true);
                setTemporaryParsedData(parsed);
            } else {
                setParsedData(parsed);
            }
        }
        setImportedUsers(false);
    };

    const handleMergeData = () => {
        setParsedData([...usersInvited.data.filter(d => d.email || d.role), ...temporaryParsedData]);
        setShowCurrentDataAlert(false);
    };

    const handleReplaceData = () => {
        setParsedData(temporaryParsedData);
        setShowCurrentDataAlert(false);
    };

    const handleConfirmUsersInvitation = () => {
        dispatch(resetInviteMultipleUsersAction());
        dispatch(resetInviteMultipleUsersAdminAction());
        dispatch(resetInviteUsersProgressAction());
        handleClose();
    };

    useEffect(() => {
        if (parsedData) setShowErrorBox(true);
    }, [parsedData]);

    useEffect(() => {
        checkErrors();
        if (
            stateIsLoaded(adminsInvited) ||
            stateIsLoaded(usersInvited) ||
            stateIsLoaded(inviteMultipleUsers) ||
            stateIsLoaded(inviteMultipleAdmins)
        ) {
            if (
                inviteMultipleUsers?.data?.successfulInvites != null ||
                usersInvited?.data?.successfulInvites != null ||
                inviteMultipleAdmins?.data?.successfulInvites != null
            ) {
                getUsers();
            }
        }
    }, [usersInvited, inviteMultipleUsers, adminsInvited, inviteMultipleAdmins]);

    useEffect(() => {
        checkErrors();
        if (selectedTab === 2) {
            setInviteMultiple(inviteMultipleAdmins);
        } else {
            setInviteMultiple(inviteMultipleUsers);
        }
    }, [selectedTab, inviteMultipleAdmins, inviteMultipleUsers]);

    // prerabotka spored tab da se koristi inviteMultipleUsers ili inviteMultipleAdmins. kool

    // console.log(authService.user, 'is authService.user');

    return (
        <div>
            <Modal
                open={isInviting && !(stateIsLoading(inviteMultiple) || stateIsLoaded(inviteMultiple))}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Paper className={`${classes.paper} d-flex flex-column`}>
                    <H4 className="text-center my-4">{t('Invite users')}</H4>
                    <UsersInvitationHeader
                        onTabChange={handleTabChange}
                        importTabHidden={importedUsers}
                        tabs={
                            company?.data?.id === 15
                                ? ['Invite by e-mail', 'Invite by number', 'Invite admin', 'Import list', 'Import and activate']
                                : ['Invite by e-mail', 'Invite by number', 'Invite admin', 'Import list']
                        }
                    />
                    <div style={{ overflow: 'auto', flex: 1 }}>
                        <div style={{ display: selectedTab === 0 ? 'block' : 'none' }} className="text-center">
                            <Body1 color={colors.gray6} className="mt-3">
                                {t('Type one or more e-mail addresses')}
                            </Body1>
                            <MultipleUsersInvite data={parsedData} hasRoles={true} />
                        </div>
                        <div style={{ display: selectedTab === 1 ? 'block' : 'none' }} className="text-center">
                            <Body1 color={colors.gray6} className="mt-3">
                                {t('Type one or more phone numbers')}
                            </Body1>
                            <MultipleUsersInviteNumber data={parsedData} hasRoles={true} />
                        </div>
                        <div style={{ display: selectedTab === 2 ? 'block' : 'none' }} className="text-center">
                            <Body1 color={colors.gray6} className="mt-3">
                                {t('Type one or more e-mail addresses')}
                            </Body1>
                            <MultipleUsersInviteAdmin />
                        </div>
                        <div style={{ display: selectedTab === 3 ? 'block' : 'none' }} className="text-center">
                            <Body1 color={colors.gray6} className="mt-3">
                                <Trans>{t('Make sure to upload <strong>.xlsx</strong> file and adhere to our format')}</Trans>
                            </Body1>
                            <UploadUsers onChangeUsers={handleUsersChange} />
                        </div>
                        <div style={{ display: selectedTab === 4 ? 'block' : 'none' }} className="text-center">
                            <Body1 color={colors.gray6} className="mt-3">
                                Make sure to upload <b>.xlsx</b> file and adhere to our format
                            </Body1>
                            <UploadUsersFull onChangeUsers={handleUsersChange} />
                        </div>
                    </div>
                    {showErrorBox && (numErrors > 0 || warningsVisible) && (
                        <div className="d-flex flex-column pb-4">
                            {numErrors > 0 && (
                                <div className="d-flex justify-content-center">
                                    <div className="user-upload-error-box">
                                        <span>
                                            {t('There are some errors in the data. Please fix them to continue')}
                                            <button className="cursor-pointer next-error-button" onClick={handleNextErrorShow}>
                                                {t('next error')}
                                            </button>
                                        </span>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                backgroundColor: '#fabebd',
                                                right: '-7px',
                                                top: '-7px',
                                                borderRadius: '100%',
                                                textAlign: 'center',
                                                padding: '2px 8px 1px',
                                            }}
                                        >
                                            {numErrors}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {warningsVisible && <div>{t('The excel file contains some warnings. Please fix them to continue')}</div>}
                        </div>
                    )}
                    <div className="d-flex justify-content-center">
                        <DefaultButton
                            id="users-send-invitation"
                            onClick={handleSubmitUsers}
                            disabled={
                                stateIsLoading(inviteMultipleUsers) ||
                                stateIsLoading(usersInvited) ||
                                stateIsLoading(inviteMultipleAdmins) ||
                                selectedTab === 2
                                    ? adminsInvited.data.length === 0 || !adminsInvited.data[0]?.email
                                    : usersInvited.data.length === 0 || (!usersInvited.data[0]?.email && !usersInvited.data[0]?.phoneNumber)
                            }
                            text={t('Send Invitation')}
                            className="mr-2"
                        />

                        <OutlinedButton id="users-cancel-modal" onClick={handleClose} text={t('Cancel')} className="ml-2" />
                    </div>
                    <Dialog
                        open={showReplaceDataAlert}
                        aria-labelledby="alert-dialog-title-users-invite"
                        aria-describedby="alert-dialog-description-users-invite"
                        disableBackdropClick
                    >
                        <DialogTitle id="alert-dialog-title-users-invite">{t('Existing user data')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-users-invite">
                                {t('You have existing users info. What do you want to do with the data?')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleReplaceData} color="scondary" variant="contained" className="action-button-decline">
                                {t('Replace data')}
                            </Button>
                            <Button onClick={handleMergeData} color="secondary" variant="contained" className="action-button-accept ml-3">
                                {t('Merge data')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={showFixAlert}
                        aria-labelledby="alert-dialog-fix-errors-to-continue"
                        aria-describedby="alert-dialog-fix-errors-to-continue"
                        disableBackdropClick
                        classes={{ paper: classes.alertFixPaper }}
                    >
                        <DialogTitle id="alert-dialog-fix-errors-to-continue">{t('There are some errors')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-fix-errors-to-continue">{t('Fix errors to continue.')}</DialogContentText>
                        </DialogContent>
                        <DialogActions className="d-flex justify-content-center">
                            <Button
                                onClick={() => setShowFixAlert(false)}
                                color="secondary"
                                variant="contained"
                                className="action-button-accept ml-3"
                            >
                                {t('OK')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Modal>
            <Modal
                open={stateIsLoading(inviteMultiple) || stateIsLoaded(inviteMultiple)}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Paper className={`${classes.paper} d-flex flex-column`}>
                    {(stateIsLoading(inviteMultipleUsers) || stateIsLoading(inviteMultipleAdmins)) && (
                        <div style={{ width: '65vw', height: '90vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                            {/*<Circular />*/}
                            <div className="d-flex flex-column w-50 text-center">
                                <h6>{t('Importing...')}</h6>
                                <div className="progress bg-white w-100">
                                    <div
                                        className="progress-bar bg-warning"
                                        role="progressbar"
                                        style={{ width: `${inviteUsersProgress?.data?.progress}%` }}
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        {inviteUsersProgress?.data?.progress}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {stateIsLoaded(inviteMultiple) && (
                        <div className="d-flex flex-column" style={{ flex: 1 }}>
                            <div style={{ flex: 1 }} className="d-flex flex-column justify-content-center">
                                <H4 id="user-invitation-sent" className="text-center my-4">
                                    {inviteMultiple.data.successfulInvites} {t('in total sent')}
                                </H4>
                                {inviteMultiple.data.failedInvites > 0 && (
                                    <div>
                                        <H5 className="text-center my-4">
                                            {inviteMultiple.data.failedInvites}{' '}
                                            {inviteMultiple.data.failedInvites === 1 ? t('user was not invited') : t('users were not invited')}
                                        </H5>

                                        {inviteMultiple.data?.noUserLicencesLeft === true && (
                                            <div className="continer-fluid" style={{ overflow: 'auto' }}>
                                                <div className="row justify-content-center">
                                                    <div className="col-4">
                                                        <H6 className="text-center">{t('No user licences left!')}</H6>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="continer-fluid" style={{ overflow: 'auto' }}>
                                            <div className="row justify-content-center">
                                                {inviteMultiple.data.existingEmails.map(email => (
                                                    <div className="col-4">
                                                        <H6 className="text-center">{email}</H6>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="d-flex justify-content-center">
                                <DefaultButton
                                    id="total-sent-button"
                                    // disabled={isUploadButtonDisabled}
                                    text={t('Continue')}
                                    onClick={handleConfirmUsersInvitation}
                                />
                            </div>
                        </div>
                    )}
                </Paper>
            </Modal>
        </div>
    );
};

const useStyles = makeStyles(theme => {
    return {
        paper: {
            position: 'absolute',
            width: '70vw',
            backgroundColor: theme.palette.background.main,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            height: '90vh',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            textAlign: 'center',
        },
        alertFixPaper: {
            maxWidth: '2000px',
            width: '600px',
        },
    };
});

export default InvitationModal;
