export const deleteStructureOnlyText =
    'You have chosen to delete only the training structure. This means that the tree list will be deleted, but the topics and questions that are already created will remain.';

export const deleteTopicsText =
    'You have chosen to delete the training structure and the topics. This means that the tree list will be deleted as well as all the separate topics that are in this training. The insights and user statistics will be affected by this change.';

export const deleteTopicsAndQuestionsText =
    'You have chosen to delete the training structure, the topics and the questions. This means that the tree list will be deleted as well as all the separate topics that are in this training, and all the questions that are connected to those topics. The insights and user statistics will be affected by this change.';

export const deleteTrainingTitlePerStep = ['Are you sure you want to delete this training?', 'Confirm Selection', ''];
