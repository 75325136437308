import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import Select from 'react-select';

import { Body1 } from 'style/typography/Body';
import Dropdown from 'assets/icons/filter-caret.svg';
import Close from 'assets/icons/filter-close.svg';

export default function ProfileSelectionSetting({ title, id, onChange, defaultValue, options, isClearable = true }) {
    const DropdownIndicator = props => {
        return <img alt="Dropdown" className="mr-2 cursor-pointer" src={Dropdown} height={17} width={17} />;
    };

    const ClearIndicator = props => {
        return <img alt="Close" className="mr-2 cursor-pointer" src={Close} height={17} width={17} onClick={props.clearValue} />;
    };

    return (
        <div className="d-flex flex-row align-items-center py-4" style={{ borderBottom: `solid 1px ${colors.disabledColor}` }}>
            <Body1 className={'mb-0'}>{title}</Body1>
            <div className={'ml-auto'}>
                <Select
                    id={id}
                    isClearable={isClearable}
                    styles={customStyles}
                    components={{
                        DropdownIndicator,
                        ClearIndicator,
                    }}
                    onChange={onChange}
                    defaultValue={defaultValue}
                    options={options}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: colors.mainTheme,
                        },
                    })}
                />
            </div>
        </div>
    );
}

ProfileSelectionSetting.propTypes = {
    defaultValue: PropTypes.any,
    id: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.any,
    title: PropTypes.any,
};

const customStyles = {
    control: styles => ({
        ...styles,
        border: `1px solid ${colors.gray2}`,
        width: '190px',
        boxShadow: 'none',
        '&:hover': {
            border: `1px solid ${colors.mainTheme}`,
        },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            '&:active': {
                backgroundColor: colors.mainTheme,
            },
            color: colors.dark,
            backgroundColor: isFocused ? colors.gray3 : 'white',
        };
    },
    indicatorSeparator: (provided, state) => ({}),
};
