import React, { useEffect, useState } from 'react';
import Icon from 'assets/icons/checkmark-img.svg';
import { resetForgotPasswordSetNewAction } from 'redux/actions/users.actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import '../ForgotPassword.css';
import { colors } from 'style/colors';
import { DefaultButton } from 'components/elements/Button';
import { Body2 } from 'style/typography/Body';
import { H7 } from 'style/typography/Heading';


export function ForgotPasswordSetNewSuccess({ history }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [locationKeys, setLocationKeys] = useState([]);

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key]);
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);
                } else {
                    setLocationKeys(keys => [location.key, ...keys]);

                    dispatch(resetForgotPasswordSetNewAction());
                }
            }
        });
    }, [dispatch, history, locationKeys]);

    return (
        <div className="forgot-password-screen">
            <img alt="logo" src={Icon} width={70} height={70}></img>
            <H7 style={{ marginTop: 30 }} color={colors.gray6}>
                {t('Password updated!')}
            </H7>
            <Body2 style={{ maxWidth: '35vw', margin: '30px 0' }}>
                {t('You have successfully reset your password.')}
            </Body2>
            <DefaultButton
                style={{ width: '150px' }}
                text={t('Continue to login')}
                onClick={() => {
                    dispatch(resetForgotPasswordSetNewAction());
                    history.replace('/login?newPassword=true');
                }}
                textColor="white"
            />
        </div>
    );
}
