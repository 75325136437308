export const INITIAL_STATE = {
    undoStack: [
        { action: '', state: {} }
    ],
    redoStack: [
        { action: '', state: {} }
    ],
};


export const MAX_STACK_SIZE = 20;