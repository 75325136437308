import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ALL_TRAININGS,
    COMPANY_UNIT_STRUCTURE,
    GET_ALL_TOPICS_FROM_COMPANY,
    GET_COMPANY_INFO,
    GET_DASHBOARD_STATISTICS,
    GET_FOLDER_STRUCTURE,
    GET_GAMES,
    GET_GAME_QUSTION_TYPE_VARIATIONS,
    GET_NEWS,
    TRAININGS_INFO,
} from 'redux/constants/content.constants';
import { GET_ALL_AUTH_ROLES, GET_ALL_COMPANY_ROLES, GET_COMPANY, GET_USERS } from 'redux/constants/users.constants';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { routes } from 'containers/layout/module/routes';

import { actionsMapper, startAppCollection } from 'redux/constants/collections';
import { stateIsLoading, stateIsNotInitialized } from 'redux/core/stateHelpers';

export const callInBulk = ({ dispatch, actions }) => {
    actions.forEach(action => {
        dispatch(actionsMapper[action.entity].call());
    });
};

const findMatch = (pathname, arr) => {
    let returnMatch = null;
    arr.forEach(el => {
        let match = matchPath(pathname, { path: '/' + el.path, exact: true, strict: true });
        if (match) {
            returnMatch = el;
            return;
        }
    });

    return returnMatch;
};

export const GlobalCallHandler = ({ children }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const fetched = useRef(false);

    const companyUnitStructure = useSelector(state => state.content.companyUnitStructure);
    const dashboardStats = useSelector(state => state.content.dashboardStats);
    const allTopicsFromCompany = useSelector(state => state.content.topics.allTopicsFromCompany);
    const allTrainings = useSelector(state => state.content.allTrainings);
    const companyInfo = useSelector(state => state.content.companyInfo);
    const topicsFolderStructure = useSelector(state => state.content.topics.topicsFolderStructure);
    const gameQuestionTypeVariations = useSelector(state => state.content.gameQuestionTypeVariations);
    const games = useSelector(state => state.content.games);
    const news = useSelector(state => state.content.news);
    const trainingsInfo = useSelector(state => state.content.trainingsInfo);
    const users = useSelector(state => state.userManagement.users);
    const roles = useSelector(state => state.userManagement.roles);
    const authRoles = useSelector(state => state.userManagement.authRoles);
    const company = useSelector(state => state.userManagement.company);

    const allStatesMap = {
        [GET_GAMES.entity]: games,
        [GET_FOLDER_STRUCTURE.entity]: topicsFolderStructure,
        [TRAININGS_INFO.entity]: trainingsInfo,
        [GET_COMPANY_INFO.entity]: companyInfo,
        [GET_NEWS.entity]: news,
        [GET_ALL_AUTH_ROLES.entity]: authRoles,
        [GET_ALL_COMPANY_ROLES.entity]: roles,
        [GET_ALL_TOPICS_FROM_COMPANY.entity]: allTopicsFromCompany,
        [GET_USERS.entity]: users,
        [GET_COMPANY.entity]: company,
        [ALL_TRAININGS.entity]: allTrainings,
        [COMPANY_UNIT_STRUCTURE.entity]: companyUnitStructure,
        [GET_GAME_QUSTION_TYPE_VARIATIONS.entity]: gameQuestionTypeVariations,
        [GET_DASHBOARD_STATISTICS.entity]: dashboardStats,
    };

    const allStates = startAppCollection.map(el => allStatesMap[el]);

    useEffect(() => {
        if (!fetched.current) {
            let match = findMatch(pathname, routes);
            let actionsToFinish = match?.dependencies;

            let finishedActions = 0;
            if (match && !match.excludeGlobalCalls) {
                startAppCollection.forEach(state => {
                    let containsAction = actionsToFinish && actionsToFinish?.some(el => el.entity == state.entity);
                    let stateIsProcessing = stateIsLoading(allStatesMap[state.entity]) || stateIsNotInitialized(allStatesMap[state.entity]);
                    if (containsAction && !stateIsProcessing) {
                        finishedActions += 1;
                    }
                });
                if (finishedActions === actionsToFinish?.length) {
                    let items = startAppCollection.filter(el => !match.dependencies.includes(el));
                    callInBulk({ dispatch, actions: items });
                    fetched.current = true;
                }
            }
        }
    }, [allStates, allStatesMap, dispatch, pathname]);

    useEffect(() => {
        if (!fetched.current) {
            let match = findMatch(pathname, routes);
            if (match) {
                callInBulk({ dispatch, actions: match?.dependencies || [] });
            } else {
                callInBulk({ dispatch, actions: startAppCollection });
                fetched.current = true;
            }
        }
    }, [dispatch, pathname]);
    return <>{children}</>;
};
