import React from 'react';

import { colors } from 'style/colors';
import { Body1 } from 'style/typography/Body';
import { useSelector } from 'react-redux';
import { Caption1 } from 'style/typography/Caption';
import { H5 } from 'style/typography/Heading';
import { Subtitle2 } from 'style/typography/Subtitle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

import TrainingsIconEmpty from 'assets/icons/dashboard-trainings-empty.svg';
import TrainingDashboardIcon from 'assets/icons/dashboard-training.svg';
import Carret from 'assets/icons/filter-caret.svg';
import StarIcon from 'assets/icons/dashboard-star.svg';

import './DashStatistics.css';

export default function TrainingStatisticsDashboard() {
    const history = useHistory();
    const { t } = useTranslation();

    const dashboardStatsState = useSelector(state => state.content.dashboardStats);

    if (!dashboardStatsState.data?.trainings) {
        return (
            <div className="dash-s--empty-container">
                <img alt="empty-icon" src={TrainingsIconEmpty} height={60} width={60} />
                <Body1 color={colors.gray6}>{t('Create your first training to see insights on the dashboard.')}</Body1>
            </div>
        );
    }

    return (
        <div className="dash-s--trainings-items">
            {dashboardStatsState.data?.trainings?.map(training => {
                return (
                    <div className="dash-s--trainings-item" onClick={() => history.replace(`/content/trainings?trainingId=${training.setId}`)}>
                        <div className="dash-s--trainings-item-title">
                            <img alt="trainings" src={TrainingDashboardIcon} height={60} width={60} />
                            <Subtitle2 style={{ overflow: 'hidden' }}>{training.setName}</Subtitle2>
                            <img alt="carret-right" className="carret" src={Carret} height={25} width={25} />
                        </div>
                        <div className="divider"></div>
                        <div className="dash-s--trainings-statistics-container">
                            <div className="dash-s--trainings-statistic">
                                <H5 color={colors.primaryText}>{training.allUsers.toString()}</H5>
                                <div className="label">
                                    <Caption1 color={colors.gray6}>{t('users in training')}</Caption1>
                                </div>
                            </div>
                            <div className="dash-s--trainings-statistic">
                                <H5 color={colors.primaryText}>{training.proficientUsers.toString()}</H5>
                                <div className="label">
                                    <img alt="star" src={StarIcon} height={15} width={15} />
                                    <Caption1 color={colors.gray6}>{t('proficient users')}</Caption1>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
