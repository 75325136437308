import React, { useState } from 'react';
import { colors } from 'style/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NewTrainingModal } from './NewTrainingModal';
import AddTrainings from 'assets/icons/icon-emptystate-trainings.svg';
import { useTranslation } from 'react-i18next';

export function EmptyTrainingState() {
    const [modalOpen, setModalOpen] = useState(false);

    const { t } = useTranslation();

    return (
        <div style={{ height: '100%' }} className={'d-flex flex-row align-items-center justify-content-center'}>
            <div className="d-flex flex-column align-items-center">
                <img style={{ width: 100, height: 100 }} src={AddTrainings} alt="" />
                <h4 style={{ color: colors.secoundaryText }}>{t('Start by adding a training')}</h4>
                <p className={'mb-0 mt-1'} style={{ color: colors.gray6 }}>
                    {t("Start building the trainings for your employees by adding your first one.")}
                </p>
                <button
                    id="content-page-add-new-training"
                    onClick={() => setModalOpen(true)}
                    type={'button'}
                    className="btn btn-dark d-flex flex-row align-items-center mt-4"
                    style={{ color: colors.mainTheme, width: 150 }}
                >
                    <FontAwesomeIcon icon={faPlus} className={'mr-auto'}></FontAwesomeIcon>
                    {t("Add training")}
                </button>
            </div>
            <NewTrainingModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
    );
}
