import React from 'react';

import { colors } from 'style/colors';
import { Body1, Body2 } from 'style/typography/Body';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Caption1 } from 'style/typography/Caption';
import { parseDate } from 'services/utils/stringHelpers';
import { useTranslation } from 'react-i18next';

import GameplaysIconEmpty from 'assets/icons/dashboard-gameplays-empty.svg';

import './DashStatistics.css';

export default function GameplaysStatisticsDashboard() {
    const { t } = useTranslation();

    const dashboardStatsState = useSelector(state => state.content.dashboardStats);

    if (!dashboardStatsState.data?.gameplays) {
        return (
            <div className="dash-s--empty-container">
                <img alt="empty-icon" src={GameplaysIconEmpty} height={60} width={60} />
                <Body1 color={colors.gray6}>
                    {t(
                        'As soon as a user finishes a gameplay, you will see details about it right here on the dashboard! It will be updated with the latest session logs.'
                    )}
                </Body1>
            </div>
        );
    }

    return (
        <div className="dash-s--gameplays-container">
            <div className="dash-s--gameplays-header">
                <Caption1 color={colors.gray1}>{t('user')}</Caption1>
                <Caption1 color={colors.gray1}>{t('timestamp')}</Caption1>
                <Caption1 color={colors.gray1}>{t('score')}</Caption1>
                <Caption1 color={colors.gray1}>{t('game')}</Caption1>
            </div>
            {dashboardStatsState.data?.gameplays?.slice(0, 10)?.map(gameplay => {
                return (
                    <div className="dash-s--gameplays-item">
                        <Link to={`/user/${gameplay.user.id}`}>
                            <Body2 className={'cursor-pointer'}>{gameplay.user.name + ' ' + gameplay.user.lastName}</Body2>
                        </Link>
                        <Caption1 className={'ml-2'} color={colors.gray6}>
                            {parseDate(new Date(gameplay.playStart))}{' '}
                        </Caption1>
                        <Body2 weight="bold" color={colors.primaryText}>
                            {gameplay.score.toString()}
                        </Body2>
                        <img
                            alt="game"
                            style={{ border: `1px solid ${colors.gray3}`, borderRadius: '100px', padding: '2px' }}
                            width={'35px'}
                            src={gameplay.game.gameIcon}
                        ></img>
                    </div>
                );
            })}
        </div>
    );
}
