import { combineReducers } from 'redux';
import { scroller } from './scrollerReducer';
import { auth } from './auth';
import { LOGOUT } from '../constants/auth.constants';
import { buildActionType } from '../actions/buildActionType';
import { ActionStatus } from '../core/ActionStatus';
import storage from 'redux-persist/lib/storage';

import userManagement from './usersReducer';
import content from './contentReducer';
import { basicReducer } from './basicReducer';

const appReducer = combineReducers({
    scroller,
    auth,
    userManagement,
    content,
    loggedOut: basicReducer(LOGOUT),
});

const rootReducer = (state, action) => {
    if (action.type === buildActionType(LOGOUT, ActionStatus.DONE)) {
        storage.removeItem('persist:coach');
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
