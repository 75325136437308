import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { H6 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';

import FeedbackEmptyIconPositive from 'assets/icons/emptystate-feedback-positive.svg';
import FeedbackEmptyIcon from 'assets/icons/emptystate-feedback-content.svg';

export function FeedbackemptyState({ type }) {
    const { t } = useTranslation();

    const title = type === 'positive' ? 'No positive feedback yet' : 'No comments or issues yet';

    const desc =
        type === 'positive'
            ? 'Your players haven’t left any positive feedback in the app yet. Once a player taps on the ‘happy’ button after a gameplay, it will be shown here in a list, so you can easily track all the positive experiences the users have.'
            : 'Your players haven’t left any feedback in the app yet. Once a player taps on the suitable button after a gameplay to leave a feedback, it will be shown and listed here so you can easily track and solve their issues or comments (regarding the content of the game/training/materials or the gameplay experience).';

    return (
        <div style={{ height: '100%' }} className={'d-flex flex-row align-items-center justify-content-center'}>
            <div className="d-flex flex-column align-items-center">
                <img style={{ width: 80, height: 80 }} src={type === 'positive' ? FeedbackEmptyIconPositive : FeedbackEmptyIcon} alt="" />

                <H6 color={colors.secoundaryText} className={'mt-4'}>
                    {t(title)}
                </H6>
                <Body2 className={'mb-0 mt-1'} color={colors.gray6} style={{ maxWidth: '40%', width: '40%', textAlign: 'center' }}>
                    {t(desc)}
                </Body2>
            </div>
        </div>
    );
}

FeedbackemptyState.propTypes = {
    type: PropTypes.string,
};
