import PropTypes from 'prop-types';
import React from 'react';

import Folder from 'assets/icons/icon-gallery-folder.svg';
import FolderOpen from 'assets/icons/icon-gallery-openfolder.svg';
import FolderWhite from 'assets/icons/icon-gallery-folder-white.svg';
import FolderOpenWhite from 'assets/icons/icon-gallery-openfolder-white.svg';
import Uncategorized from 'assets/icons/icon-gallery-uncategorized.svg';
import UncategorizedWhite from 'assets/icons/icon-gallery-uncategorized-white.svg';

import { useTranslation } from 'react-i18next';

import '../SortableTreeCustom.css';
import './SelectFolder.css';
import { Body2 } from 'style/typography/Body';
import { getNodePath } from 'services/utils/treeHelpers';
import { colors } from 'style/colors';
import SelectFolderTab from './SelectFolderTab';

export default function SelectFolder({ treeTemp, treeData, setCurrentNodeFolder, currentNodeFolder }) {
    const { t } = useTranslation();

    const setToUncategorized = () => {
        setCurrentNodeFolder(treeData[0]);
    };

    const getBreadcrumbsForNode = () => {
        let divs = [];

        if (!currentNodeFolder || !treeTemp) return divs;

        let nodePath = currentNodeFolder ? getNodePath(currentNodeFolder, treeTemp) : [];

        nodePath.forEach(treeIndex => {
            let item = treeTemp.find(el => el.node.treeIndex === treeIndex)?.node;
            if (!item) return;
            divs.push(<Body2 color={colors.gray1}>{'>'}</Body2>);
            divs.push(
                <Body2
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setCurrentNodeFolder(item);
                    }}
                >
                    {item.title}
                </Body2>
            );
        });
        return divs;
    };

    const getFolderPathForNode = () => {
        let divs = [];

        if (!treeTemp) return divs;

        let nodePath = currentNodeFolder ? getNodePath(currentNodeFolder, treeTemp) : [];
        if (nodePath.length < 4) {
            divs.push(
                <div className="column">
                    <SelectFolderTab
                        onClick={setToUncategorized}
                        iconWhite={UncategorizedWhite}
                        icon={Uncategorized}
                        text={'Uncategorized'}
                        selected={currentNodeFolder === treeData[0]}
                    />
                    {treeData[0].children?.map(el => {
                        return (
                            <SelectFolderTab
                                onClick={() => setCurrentNodeFolder(el)}
                                iconWhite={nodePath.includes(el.treeIndex) ? FolderOpenWhite : FolderWhite}
                                icon={nodePath.includes(el.treeIndex) ? FolderOpen : Folder}
                                text={el.title}
                                selected={currentNodeFolder === el}
                                isPrevious={nodePath.includes(el.treeIndex)}
                            />
                        );
                    })}
                </div>
            );
        }

        nodePath.slice(-4).forEach(treeIndex => {
            let items = treeTemp.find(el => el.node.treeIndex === treeIndex)?.node?.children;
            if (!items) return;

            divs.push(
                <div className="column">
                    {items?.map(el => {
                        return (
                            <SelectFolderTab
                                onClick={() => setCurrentNodeFolder(el)}
                                iconWhite={nodePath.includes(el.treeIndex) ? FolderOpenWhite : FolderWhite}
                                icon={nodePath.includes(el.treeIndex) ? FolderOpen : Folder}
                                text={el.title}
                                selected={currentNodeFolder === el}
                                isPrevious={nodePath.includes(el.treeIndex)}
                            />
                        );
                    })}
                </div>
            );
        });
        return divs;
    };

    return (
        <div className="select-folder">
            <div className="select-folder--header">
                <div className="select-folder--breadcrumbs">
                    <img src={Folder} height={20} width={20} />
                    <Body2>{t('Gallery')}</Body2>
                    {getBreadcrumbsForNode()}
                </div>
            </div>
            <div className="select-folder--columns">{getFolderPathForNode()}</div>
        </div>
    );
}

SelectFolder.propTypes = {
    currentNodeFolder: PropTypes.any,
    setCurrentNodeFolder: PropTypes.func,
    treeData: PropTypes.any,
    treeTemp: PropTypes.any,
};
