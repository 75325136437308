import React, { useMemo } from 'react';
import { UserCircle } from 'components/elements/UserCircle';
import { colors } from 'style/colors';
import { H6, H7 } from 'style/typography/Heading';
import { Body1, Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { useTranslation } from 'react-i18next';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useSelector } from 'react-redux';

export default function UserProfileHeader({ userData }) {
    const { t } = useTranslation();

    const rolesState = useSelector(state => state.userManagement.roles);

    function getRoleParsed(roleName) {
        if (roleName !== undefined && roleName !== null && roleName.length > 0) {
            var roleNameParsed = roleName?.toLowerCase().replace('role_', '');
            return roleNameParsed[0]?.toUpperCase() + roleNameParsed?.slice(1);
        }
        return '';
    }

    const userRoleConnectedSetName = useMemo(() => {
        if (stateIsLoaded(rolesState) && userData) {
            return rolesState.data.roles.find(el => el.roleId == userData?.companyRole?.roleId)?.connectedSetName;
        }
    }, [rolesState, userData]);

    return (
        <div
            style={{ backgroundColor: colors.white, margin: '0px 40px' }}
            className="w-100 rounded d-flex flex-row p-3 pr-5 align-items-center justify-content-around"
        >
            <div className="d-flex flex-row align-items-center justify-content-center ">
                <UserCircle
                    borderConditionalVariable={true}
                    borderColorActive={colors.gray5}
                    bgColor={colors.gray5}
                    textColor={colors.white}
                    className={'pt-2 pb-2'}
                    name={userData.name}
                    lastName={userData.lastName}
                    height={50}
                    width={50}
                    tag={H7}
                />
                <div className="d-flex flex-column text-left justify-content-center ml-4">
                    <H6 className={'mb-0'}>
                        {userData.name} {userData.lastName}
                    </H6>
                    <Body2 className={'text-muted'}>{userData.email}</Body2>
                </div>
            </div>

            <div className="d-flex flex-column text-left justify-content-center">
                <Caption1 weight="medium" color={colors.gray6}>
                    {t('STATUS')}
                </Caption1>
                <Body1 color={colors.dark} className={'mb-0'}>
                    {userData.isActive}
                </Body1>
            </div>
            <div className="d-flex flex-column text-left  justify-content-center">
                <Caption1 weight="medium" color={colors.gray6}>
                    {t('ROLE')}
                </Caption1>
                <Body1 color={userData.companyRole?.roleName ? colors.dark : colors.gray6} className={'mb-0'}>
                    {userData.companyRole?.roleName ? userData.companyRole?.roleName : t('No assigned role')}
                </Body1>
            </div>
            <div className="d-flex flex-column text-left justify-content-center">
                <Caption1 weight="medium" color={colors.gray6}>
                    {t('ACCESS LEVEL')}
                </Caption1>
                <Body1 color={userData.authRole ? colors.dark : colors.gray6} className={'mb-0'}>
                    {userData.authRole ? getRoleParsed(userData.authRole) : t('None')}
                </Body1>
            </div>
            {userRoleConnectedSetName && (
                <div className="d-flex flex-column text-left mr-5 justify-content-center">
                    <Caption1 weight="medium" color={colors.gray6}>
                        {t('ASSIGNED TRAINING')}
                    </Caption1>
                    <Body1 color={colors.dark} className={'mb-0'}>
                        {userRoleConnectedSetName}
                    </Body1>
                </div>
            )}
        </div>
    );
}
