import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

import './HorizontalMenuPicker.css';

function HorizontalMenuPicker({ firstElement, secondElement, firstElementSelected, setFirstElementSelected, disabled }) {
    return (
        <div className="h-menu-picker--container">
            <div className="h-menu-picker--item first" onClick={() => setFirstElementSelected(true)}>
                <Body2 style={{ paddingLeft: 5, textAlign: 'center' }} color={firstElementSelected ? colors.primaryText : colors.gray6}>
                    {firstElement}
                </Body2>
                <div className={`h-menu-picker--underline ${firstElementSelected ? 'selected' : ''}`}></div>
            </div>
            {!disabled && (
                <div className="h-menu-picker--item second" onClick={() => setFirstElementSelected(false)}>
                    <Body2 style={{ paddingLeft: 5, textAlign: 'center' }} color={!firstElementSelected ? colors.primaryText : colors.gray6}>
                        {secondElement}
                    </Body2>
                    <div className={`h-menu-picker--underline ${!firstElementSelected ? 'selected' : ''}`}></div>
                </div>
            )}
        </div>
    );
}

HorizontalMenuPicker.propTypes = {
    disabled: PropTypes.bool,
    firstElement: PropTypes.any,
    firstElementSelected: PropTypes.bool,
    secondElement: PropTypes.any,
    setFirstElementSelected: PropTypes.func,
};

export default HorizontalMenuPicker;
