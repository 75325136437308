import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { imageLink } from 'services/image_management/imageHandlers';
import { SessionStats } from './SessionStats';
import Badge from 'assets/icons/badge-icon.svg';
import { H2, H4 } from 'style/typography/Heading';
import { Caption1 } from 'style/typography/Caption';
import { Body2 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';

export function AchievementStatsWrapper({ lockedBadges, unlockedBadges, totalUnlockedBadges, totalBadges, sessions }) {
    const {t} = useTranslation();

    return (
        <div className={'row'}>
            <div style={{ height: '30%' }} className="col-6 d-flex flex-column pl-5 pr-3 mb-4 border-right">
                <div className="d-flex flex-row align-items-start mt-3">
                    <img alt="Badge" className={'mt-2'} style={{ width: 35, height: 35 }} src={Badge}></img>
                    <div className="d-flex flex-row align-items-center ml-3">
                        <div className="d-flex flex-row text-left justify-content-start">
                            <H2 weight="meidum" className={'mb-0 pb-0'} color={colors.mainTheme}>
                                {totalUnlockedBadges}
                            </H2>
                            <H4 className={'mb-0 mt-1 ml-1'}>/{totalBadges}</H4>
                        </div>
                        <Body2 color={colors.gray6} className={'mb-0 mt-1 ml-2'}>
                            {t("Badges unlocked")}
                        </Body2>
                    </div>
                </div>
                <div className="d-flex flex-column mt-3">
                    <Body2 color={colors.secoundaryText} className={'mb-0'}>
                        {t("Unlocked badges")}
                    </Body2>
                    <div className="row mt-2">
                        <div className="col-12">
                            {unlockedBadges.map(b => {
                                return <img className={'ml-1 mr-1'} style={{ width: 40, height: 45 }} src={imageLink(b.imageUrl)} alt="" />;
                            })}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column mt-3 mb-3">
                    <Body2 color={colors.secoundaryText} className={'mb-0'}>
                        {t("Locked badges")}
                    </Body2>
                    <div className="row mt-2">
                        <div className="col-12">
                            {lockedBadges.map(b => {
                                return <img className={'ml-1 mr-1'} style={{ width: 40, height: 45 }} src={imageLink(b.imageUrl)} alt="" />;
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 pl-5">
                {sessions.map((s, index) => {
                    return <SessionStats value={s} last={index === sessions.length - 1} />;
                })}
            </div>
        </div>
    );
}

AchievementStatsWrapper.propTypes = {
    lockedBadges: PropTypes.any,
    sessions: PropTypes.any,
    totalBadges: PropTypes.any,
    totalUnlockedBadges: PropTypes.any,
    unlockedBadges: PropTypes.any,
};
