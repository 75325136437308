import React from 'react';
import TopicDropdownSelectorTrainings from 'components/features/content_management/inputs/topic_dropdown_selector_trainings/TopicDropdownSelectorTrainings';
import { questionStatusOptions } from 'static/status_options';

export const questionTableSubheaders = ({
    filtersState,
    activeFilters,
    updateFilterState,
    dispatchFilters,
    constValuesState,
    createQuestions,
    exportQuestions,
    resetBulkActions,
}) => [
    {
        id: 'content-search-input',
        type: 'value',
        value: filtersState.searchQuery,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, searchQuery: val });
        },
        style: { width: 200 },
        placeholder: 'Search',
        active: true,
    },
    {
        id: 'content-created-dropdown',
        type: 'daterangepicker',
        value: filtersState.dateRange,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, dateRange: val });
        },
        placeholder: 'Created',
        clearOnBackspace: true,
        active: activeFilters.dateRange.active,
    },
    {
        id: 'content-training-dropdown',
        type: 'dropdown',
        value: filtersState.training,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, training: val });
        },
        placeholder: 'Training',
        clearOnBackspace: true,
        dropdownValues: constValuesState.trainings,
        active: activeFilters.training.active,
    },
    {
        id: 'content-topic-dropdown',
        type: 'custom',
        active: activeFilters.topic.active,
        child: (
            <TopicDropdownSelectorTrainings
                value={filtersState.topic !== null ? constValuesState.topics.filter(t => t.value === filtersState.topic)[0] : ''}
                onChange={topicId => {
                    resetBulkActions();
                    dispatchFilters({ topic: topicId });
                }}
                width={'150px'}
            />
        ),
    },
    {
        id: 'content-status-dropdown',
        type: 'dropdown',
        value: filtersState.status,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, status: val });
        },
        placeholder: 'Status',
        clearOnBackspace: true,
        dropdownValues: questionStatusOptions,
        active: activeFilters.status.active,
    },
    {
        id: 'content-game-dropdown',
        type: 'dropdown',
        value: filtersState.game,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, game: val });
        },
        placeholder: 'Game',
        clearOnBackspace: true,
        dropdownValues: constValuesState.gameValues,
        active: activeFilters.game.active,
    },
    { type: 'add-filters', active: true, activeFilters: activeFilters, updateFilterState: updateFilterState },
    {
        type: 'button',
        name: '+ Add new',
        onClick: createQuestions,
    },
    {
        type: 'button-secondary',
        name: 'Export',
        onClick: exportQuestions,
    },
];
