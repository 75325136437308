import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { colors } from 'style/colors';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import TagValueItem from './TagValueItem';
import { AddValueModal } from '../local_components/AddValueModal';
import { getLabelByIdAction, updateLabelsAction } from 'redux/actions/tags.actions';
import { EditTagDialog } from '../local_components/EditTagDialog';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { toast } from 'react-hot-toast';
import { resetUpdateLabelsAction } from 'redux/actions/tags.actions';
import { CircularProgress } from '@material-ui/core';
import SideMenu from 'components/elements/sidemenu/SideMenu';
import { useTranslation } from 'react-i18next';

import EmptyStateLabel from 'assets/icons/emptystate-label-notselected.svg';

import '../TagsTab.css';

export function SideMenuTags({ currentLabelId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [editingTitle, setEditingTitle] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editedTag, setEditedTag] = useState(null);

    const [editedName, setEditedName] = useState(null);
    const [updatedTags, setUpdatedTags] = useState({});

    const labelByIdState = useSelector(state => state.content.labelById);
    const updateLabelsState = useSelector(state => state.content.updateLabels);

    let currentLabel = labelByIdState.data;

    function updateLabel() {
        if (!editedName && Object.keys(updatedTags).length == 0) return;
        let updatedLabel = {
            ...labelByIdState.data,
            label: editedName ? editedName : labelByIdState.data.label,
            tags: labelByIdState.data.tags.map(el => {
                if (updatedTags?.[el.tagId]) return { ...el, tag: updatedTags?.[el.tagId] };
                return el;
            }),
        };
        dispatch(updateLabelsAction(updatedLabel));
    }

    function resetAllChanges() {
        setEditedName(null);
        setUpdatedTags({});
    }

    useEffect(() => {
        if (stateIsLoaded(updateLabelsState)) {
            toast.success(t('Successfully updated label'));
            dispatch(resetUpdateLabelsAction());
            resetAllChanges();
            dispatch(getLabelByIdAction(currentLabelId));
        }
    }, [updateLabelsState]);

    useEffect(() => {
        if (!currentLabelId) {
            return;
        }

        dispatch(getLabelByIdAction(currentLabelId));
        resetAllChanges();
    }, [currentLabelId]);

    let hasChanges = editedName || Object.keys(updatedTags).length > 0;

    let tabsMapping = {
        base: (
            <div>
                <div style={{ height: '65vh', maxHeight: '65vh', overflow: 'auto', marginBottom: 20 }}>
                    <AddValueModal label={currentLabel} modalOpen={isModalOpen} setModalOpen={setIsModalOpen} />
                    <div className="tags-side-menu--values">
                        <Caption1 className={'mb-2'} color={colors.gray6}>
                            {t('TAGS')}
                        </Caption1>
                        <Body2
                            className={'mb-2 cursor-pointer'}
                            color={colors.gray6}
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                        >
                            {t('+ Add new')}
                        </Body2>
                    </div>

                    {stateIsLoaded(labelByIdState) &&
                        labelByIdState.data.tags &&
                        labelByIdState.data.tags.map(el => {
                            return (
                                <TagValueItem
                                    currentLabel={currentLabel}
                                    tagValue={el}
                                    key={el.tagId}
                                    updatedTags={updatedTags}
                                    setEditedTag={setEditedTag}
                                />
                            );
                        })}
                    {stateIsLoaded(labelByIdState) && labelByIdState.data?.tags?.length === 0 && (
                        <div style={{ width: '80%', display: 'flex', flexDirection: 'column', paddingTop: '20px', alignItems: 'center' }}>
                            <Caption1 color={colors.gray6}>{t('No tags yet..')}</Caption1>
                        </div>
                    )}
                    <EditTagDialog
                        modalOpen={editedTag}
                        onModalClose={() => {
                            setEditedTag(null);
                        }}
                        editedTag={editedTag}
                        onAction={(tagId, tagName) => {
                            setUpdatedTags(prevData => {
                                return {
                                    ...prevData,
                                    [tagId]: tagName,
                                };
                            });
                            setEditedTag(null);
                        }}
                    />
                </div>
                <div className="d-flex flex-row align-items-center pb-3 ml-2">
                    <DefaultButton
                        id="tags-save-button"
                        disabled={!hasChanges || stateIsLoading(updateLabelsState)}
                        text={t('Save')}
                        textColor={colors.white}
                        className={'pl-4 pr-4'}
                        onClick={() => updateLabel()}
                    />
                    <OutlinedButton
                        id="tags-cancel-button"
                        disabled={!hasChanges || stateIsLoading(updateLabelsState)}
                        text={t('Cancel')}
                        className={'pl-4 pr-4 ml-2'}
                        onClick={resetAllChanges}
                    />
                    {stateIsLoading(updateLabelsState) === true && (
                        <CircularProgress className={'ml-2'} style={{ color: colors.mainTheme }} size={25} />
                    )}
                    {hasChanges && (
                        <Body2 className="ml-2" color={colors.gray6}>
                            {t('Changes not saved')}
                        </Body2>
                    )}
                </div>
            </div>
        ),
    };

    return (
        <SideMenu
            hasData={currentLabelId}
            noDataImg={EmptyStateLabel}
            noDataTitle={'Select a label to edit'}
            noDataText={'Currently, there is no label selected. Click on a label from the left panel list, and more info will be shown here.'}
            isEditingInput={editingTitle}
            setEditingInput={setEditingTitle}
            inputKey={currentLabel?.labelId}
            defaultValue={editedName != null ? editedName : currentLabel?.label}
            inputOnBlur={() => {
                setEditingTitle(false);
            }}
            inputOnChange={event => {
                setEditedName(event.target.value);
            }}
            tabsSidemenu={[]}
            tabsMapping={tabsMapping}
            activeTab={'base'}
            setActiveTab={() => {}}
        />
    );
}

SideMenuTags.propTypes = {
    currentLabelId: PropTypes.any,
};
