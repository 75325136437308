import requestAgent from '../requestAgent';
import { handleSuccess, handleError } from './utils';
import { LOGIN_API } from '../api_links';
import { constructQueryString } from 'services/utils/stringHelpers';
import { tokenHelper } from 'services/auth/tokenHelpers';

export const loginApiRequest = async (data, queryItems) => {
    const responseData = await requestAgent.post(`${LOGIN_API}?${constructQueryString(queryItems)}`, data, {
        username: 'Konceptiva',
        password: 'K0ncept1v@+100%',
    });

    if (responseData.status === 200) {
        return handleSuccess(responseData);
    } else {
        return handleError(responseData);
    }
};

export const refreshTokenRequest = async () => {
    let data = new FormData();
    const refreshToken = await tokenHelper.getRefreshToken();
    if (refreshToken) {
        data.append('refresh_token', refreshToken);

        const responseData = await requestAgent.post(LOGIN_API + '?grant_type=refresh_token', data, {
            username: 'Konceptiva',
            password: 'K0ncept1v@+100%',
        });

        if (responseData.status === 200) {
            return handleSuccess(responseData);
        }
    }
    return handleError();
};
