export const notificationSendType = [
    {
        value: 'ONE_TIME',
        label: 'One time',
    },
    {
        value: 'RECURRING',
        label: 'Recurring',
    },
];

export const notificationSendingStatus = [
    {
        value: 'SCHEDULED',
        label: 'Scheduled',
    },
    {
        value: 'SENT',
        label: 'Sent',
    },
];