import AktivGroteskRegular from 'assets/fonts/AktivGrotesk-Regular.otf';
import AktivGroteskLight from 'assets/fonts/AktivGrotesk-Light.otf';
import AktivGroteskMedium from 'assets/fonts/AktivGrotesk-Medium.otf';
import AktivGroteskBold from 'assets/fonts/AktivGrotesk-Bold.otf';

export const ag_regular = {
    fontFamily: 'Aktiv Grotesk',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('AktivGrotesk-Regular'),
      url(${AktivGroteskRegular}) format('opentype')
    `,
};

export const ag_light = {
    fontFamily: 'Aktiv Grotesk',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 200,
    src: `
      local('AktivGrotesk-Light'),
      url(${AktivGroteskLight}) format('opentype')
    `,
};

export const ag_medium = {
    fontFamily: 'Aktiv Grotesk',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 'bold',
    src: `
      local('AktivGrotesk-Medium'),
      url(${AktivGroteskMedium}) format('opentype')
    `,
};

export const ag_bold = {
    fontFamily: 'Aktiv Grotesk',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 900,
    src: `
      local('AktivGrotesk-Bold'),
      url(${AktivGroteskBold}) format('opentype')
    `,
};
