import axios from 'axios';
import * as https from 'https';
import { tokenHelper } from '../auth/tokenHelpers';

export const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT;
// export const API_ENDPOINT = 'http://localhost:8443';

let requestsLog = [];
//
const perform = async (method, url, data, headers, auth) => {
    if (!headers) {
        headers = {};
    }

    if (!headers['Content-Type']) {
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    let token = await tokenHelper.getToken();
    if (token) headers.Authorization = `Bearer ${token}`;

    const performFunc = () => {
        return axios({
            method: method,
            url: url,
            data: data,
            auth: auth,
            headers: headers,
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
            }),
        });
    };

    return performFunc()
        .then(res => {
            requestsLog.push({
                date: new Date().toUTCString(),
                urlRequested: url,
                requestType: method,
                requestData: data,
                wasSuccessful: true,
            });

            return res;
        })
        .catch(e => {
            requestsLog.push({
                date: new Date().toUTCString(),
                urlRequested: url,
                requestType: method,
                requestData: data,
                wasSuccessful: false,
                status: e.response?.status,
                error: e,
            });
            if (e?.response) {
                return e.response;
            } else {
                return {
                    successful: false,
                    status: 500,
                };
            }
        });
};

export default {
    get: (path, data, auth, headers, additionalUrls) => {
        if (additionalUrls && additionalUrls.length > 0) {
            let responses = [];
            additionalUrls.forEach(url => {
                responses.push(perform('GET', url + path, data, headers, auth));
            });
            responses.push(perform('GET', API_ENDPOINT + path, data, headers, auth));

            return responses;
        }

        return perform('GET', API_ENDPOINT + path, data, headers, auth);
    },
    post: (path, data, auth, headers, additionalUrls) => {
        if (additionalUrls && additionalUrls.length > 0) {
            let responses = [];
            additionalUrls.forEach(url => {
                responses.push(perform('POST', url + path, data, headers, auth));
            });
            responses.push(perform('POST', API_ENDPOINT + path, data, headers, auth));

            return responses;
        }

        return perform('POST', API_ENDPOINT + path, data, headers, auth);
    },
    delete: (path, data, auth, headers, additionalUrls) => {
        if (additionalUrls && additionalUrls.length > 0) {
            let responses = [];
            additionalUrls.forEach(url => {
                responses.push(perform('DELETE', url + path, data, headers, auth));
            });
            responses.push(perform('DELETE', API_ENDPOINT + path, data, headers, auth));

            return responses;
        }

        return perform('DELETE', API_ENDPOINT + path, data, headers, auth);
    },
};
