import React from 'react';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { isValidHttpUrl } from 'services/utils/linkHelpers';
import { colors } from 'style/colors';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { Caption1 } from 'style/typography/Caption';
import { OutlinedButton } from 'components/elements/Button';

import EyeOpen from 'assets/icons/eye-open.svg';
import GoToLink from 'assets/icons/icon-gotolink.svg';
import { Body2 } from 'style/typography/Body';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import { saveAssetApiRequest } from 'services/api/apiRequests/content';
import { useTranslation } from 'react-i18next';

export function ExternalLesson({ selectedNode, lessonContent, setLessonContent }) {
    const { t } = useTranslation();

    function fetchUrl() {}

    return (
        <div key={selectedNode?.nodeIdentifier} className={'d-flex flex-column mt-3'}>
            <div className={'d-flex flex-column'}>
                <div>
                    {!lessonContent && (
                        <FileUploader
                            dropzoneText={<Body2>{t('Drag and drop the file here or click to upload')}</Body2>}
                            acceptedFiles={['.docx', '.doc', '.ppt', '.pptx', '.pdf']}
                            filesLimit={1}
                            onChange={async file => {
                                if (file.length > 0) {
                                    const data = new FormData();
                                    data.append('file', file[0]);
                                    data.append('folder', 'lessons');
                                    data.append('replaceIfExists', false);

                                    let result = await saveAssetApiRequest(data);
                                    if (result?.data?.fullName) setLessonContent(result.data.fullName);

                                    // const reader = new FileReader();
                                    // reader.onloadend = async () => {
                                    //     let data = new FormData();
                                    //     data.set('file', file);
                                    //     data.set('folder', 'lessons');
                                    //     let result = await saveAssetApiRequest(data);
                                    //     if (result?.data?.fullName) setLessonContent(result.data.fullName);
                                    // };
                                    // reader.readAsDataURL(file[0]);
                                }
                            }}
                        />
                    )}
                </div>
                <Caption1 weight="medium" color={colors.gray6} className={'mb-1'}>
                    {t('LINK TO LESSON')}
                </Caption1>
                <div className="d-flex flex-row" style={{ justifyContent: 'space-between' }}>
                    <CustomOutlinedTextField
                        id="external-lesson-input-form"
                        width={'70%'}
                        value={lessonContent}
                        setValue={setLessonContent}
                    ></CustomOutlinedTextField>
                    {lessonContent != null && lessonContent !== '' && (
                        <OutlinedButton onClick={() => window.open(lessonContent, '_blank')}>
                            <img alt="Go To" className="mr-3" height={17} width={17} src={GoToLink} />
                            <Body2 color={colors.dark}>{t('Go to link')}</Body2>
                        </OutlinedButton>
                    )}
                </div>
            </div>

            <div style={{ maxHeight: '40vh', overflow: 'scroll' }} className="d-flex flex-column mt-3">
                <div className="d-flex flex-row align-items-center">
                    <Caption1 weight="medium" color={colors.gray6} className={'mr-2'}>
                        {t('PREVIEW')}
                    </Caption1>
                    <img alt="Preview" height={20} width={20} onClick={() => fetchUrl()} src={EyeOpen} />
                </div>
                {isValidHttpUrl(lessonContent) && lessonContent.length > 0 && <LinkPreview url={lessonContent} width="100%" />}
            </div>
        </div>
    );
}
