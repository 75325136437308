export const questionFiltersInitialState = {
    searchQuery: {
        value: '',
    },
    status: {
        value: '',
        active: true,
        label: 'Status',
    },
    game: {
        value: '',
        active: true,
        label: 'Game',
    },
    topic: {
        value: '',
        active: false,
        label: 'Topic',
    },
    training: {
        value: '',
        active: true,
        label: 'Training',
    },
    dateRange: {
        value: '',
        active: false,
        label: 'Date Range',
    },
    sortParam: {
        value: null,
    },
    page: {
        value: 0,
    },
    size: { value: 10 },
};

export const questionConstValuesInitialState = {
    topics: [],
    trainings: [],
    gameValues: [],
    gameNames: [],
};

export const notificationsFiltersInitialState = {
    searchQuery: {
        value: '',
    },
    page: {
        value: 0,
    },
    size: {
        value: 10,
    },
    status: {
        value: '',
        active: true,
        label: 'Status',
    },
    type: {
        value: '',
        active: true,
        label: 'Type',
    },
};

export const usersFiltersInitialState = {
    name: {
        value: '',
    },
    role: {
        value: '',
        active: true,
        label: 'Role',
    },
    status: {
        value: '',
        active: true,
        label: 'Status',
    },
    page: {
        value: 0,
    },
    size: {
        value: 10,
    },
    sortParam: {
        value: null,
    },
    tags: {
        value: [],
        active: false,
        label: 'Tags',
    },
};

export const addRolesInitialState = {
    searchQuery: {
        value: '',
    },
    trainingId: {
        value: '',
        active: true,
        label: 'Role',
    },
    page: {
        value: 0,
    },
    size: {
        value: 10,
    },
    sortParam: {
        value: null,
    },
};

export const newsFiltersInitialState = {
    searchQuery: {
        value: '',
    },
    validFrom: {
        value: '',
        active: true,
        label: 'Valid from',
    },
    validUntil: {
        value: '',
        active: true,
        label: 'Valid until',
    },
    status: {
        value: '',
        active: true,
        label: 'Status',
    },
    page: {
        value: 0,
    },
    size: {
        value: 10,
    },
    sortParam: { value: null },
};

export const feedbackFiltersInitialState = {
    searchQuery: {
        value: '',
    },
    game: {
        value: '',
        active: true,
        label: 'Game',
    },
    type: {
        value: '',
        active: true,
        label: 'Type',
    },
    status: {
        value: '',
        active: true,
        label: 'Status',
    },
    page: {
        value: 0,
    },
    size: {
        value: 10,
    },
    sortParam: {
        value: null,
    },
};

export const feedbackPositiveFiltersInitialState = {
    searchQuery: {
        value: '',
    },
    game: {
        value: '',
        active: true,
        label: 'Game',
    },
    page: {
        value: 0,
    },
    size: {
        value: 10,
    },
    sortParam: {
        value: null,
    },
};

export const userDevicesInitialState = {
    searchQuery: {
        value: '',
    },
    status: {
        value: '',
        active: true,
        label: 'Status of request',
    },
    dateRange: { value: '', active: true, label: 'Date range' },
    page: {
        value: 0,
    },
    size: {
        value: 10,
    },
    sortParam: {
        value: null,
    },
};

export const FILTERS_LIMIT = 4;
