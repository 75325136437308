import DataTable from 'react-data-table-component';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ActionMenu } from 'components/features/data_table/table_helpers/ActionMenu';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { getGamesAction } from 'redux/actions/content.actions';

import {
    deleteFeedbackAction,
    getFeedbackContentPaginatedAction,
    getFeedbackPositivePaginatedActon,
    resetDeleteFeedbackState,
    resetUpdateFeedbackState,
    updateFeedbackAction,
    resolveFeedbackBulkAction,
    unresolveFeedbackBulkAction,
    resetFeedbackBulkActions,
    deleteFeedbackBulkAction,
    preBulkActionFeedbacksAction,
    resetPreBulkActionFeedbacksAction,
    resetGetFeedbackPaginatedAction,
} from 'redux/actions/feedback.actions';

import { Subheader } from 'components/features/data_table/subheader/Subheader';

import { ModalDialog } from 'components/features/modal/ModalDialog';
import { FeedbackResolveDialog } from 'containers/layout/module/pages/internal_messaging/feedback/local_components/FeedbackResolveDialog';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { customStylesTable } from 'style/elements/table';
import { TableCellContent, TableHeader } from 'components/features/data_table/TableElements';
import CaretTable from 'assets/icons/caret-table.svg';

import Checkbox from 'components/elements/checkbox/Checkbox';
import PaginationComponent from 'components/features/data_table/pagination_component/PaginationComponent';
import { paramsToObject, removeEmptyPropsFromObject, setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { SkeletonLoader } from 'components/elements/SkeletonLoader';

import FeedbackIcon from 'assets/icons/feedback.svg';
import FeedbackEmptyIcon from 'assets/icons/feedback-empty.svg';
import FeedbackPositiveIcon from 'assets/icons/feedback-positive.svg';
import { linkBuilderFromQuery } from 'services/api/api_builders';
import { getTitleCase } from 'services/utils/stringHelpers';
import { useTableSelections } from 'services/custom-hooks/useTableSelections';
import CustomCheckboxForTable from 'components/elements/checkbox/CustomCheckboxForTable';
import { ModifyFeedbackModal } from './local_components/ModifyFeedbackModal';
import { feedbackFiltersInitialState, feedbackPositiveFiltersInitialState } from 'static/filters';
import { feedbackPositiveSubheaders, feedbackSubheaders } from './table_components/FeedbackTableSubheaders';
import { deleteFeedbackModalData } from './FeedbackModalData';
import { usePagination } from 'components/features/data_table/pagination_component/usePagination';
import { useTranslation } from 'react-i18next';
import FeedbackTableActions from './table_components/FeedbackTableActions';

import TableEmptyState from 'components/features/data_table/table_helpers/TableEmptyState';
import { useFilters } from 'components/features/data_table/pagination_component/useFilters';
import { FeedbackemptyState } from './FeedbackEmptyState';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import './Feedback.css';
import { LoadingAnimation } from 'components/features/lottie/LoadingAnimation';

export function Feedback() {
    const noCommentText = 'The user didn’t leave a comment.';
    const positiveCommentText = 'The user liked the experience.';

    const history = useHistory();
    const location = useLocation();
    const type = location.pathname.includes('/content-feedback') ? 'content' : 'positive';

    const feedbackPaginatedState = useSelector(state =>
        type === 'content' ? state.content.feedbackContentPaginated : state.content.feedbackPositivePaginated
    );
    const feedbackUpdatedState = useSelector(state => state.content.feedbackUpdated);
    const feedbackDeletedState = useSelector(state => state.content.feedbackDeleted);

    const resolveFeedbackBulkState = useSelector(state => state.content.resolveFeedbackBulk);
    const unresolveFeedbackBulkState = useSelector(state => state.content.unresolveFeedbackBulk);
    const deleteFeedbackBulkState = useSelector(state => state.content.deleteFeedbackBulk);

    const gamesState = useSelector(state => state.content.games);
    const [dataIsLoading, setDataIsLoading] = useState(false);

    const [feedbackData, setFeedbackData] = useState([]);
    const [feedbackToBeUpdated, setFeedbackToBeUpdated] = useState(null);

    const [gameValues, setGameValues] = useState([]);

    const [modifyFeedbacksModalOpen, setModifyFeedbacksModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [feedbackResolvedModalOpen, setFeedbackResolvedModalOpen] = useState(false);

    const [totalPages, setTotalPages] = useState(0);
    const [totalFeedbacks, setTotalFeedbacks] = useState(0);

    const isInitialLoad = useRef(true);

    const initialFilterState = type === 'positive' ? feedbackPositiveFiltersInitialState : feedbackFiltersInitialState;

    const {
        areAllOptionsFromThisPageSelected,
        setSelectedRows,
        selectedRows,
        addSelectedRow,
        removeSelectedRow,
        selectAll,
        deselectAll,
        allQuestionsSelected,
        setAllQuestionsSelected,
        filteredData,
        selectedRowsArr,
    } = useTableSelections({
        paginatedState: feedbackPaginatedState,
        rowSelector: row => row.feedbackId,
    });

    const { filtersState, activeFilters, dispatchFilters, updateFilterState, resetFilters } = useFilters({
        initialFiltersState: initialFilterState,
    });

    const filters = {
        searchQuery: filtersState.searchQuery ? encodeURIComponent(filtersState.searchQuery) : '',
        game: filtersState.game,
        type: filtersState.type,
        status: filtersState.status,
        page: filtersState.page,
        size: filtersState.size,
        orderBy: filtersState.sortParam?.selector,
        ascending: filtersState.sortParam?.ascending,
    };

    const { getPrevious, getNext, getPage } = usePagination({
        paginatedState: feedbackPaginatedState,
        currentPage: filters.page,
        setCurrentPage: page => dispatchFilters({ page: page }),
    });

    const [currentActionOption, setCurrentActionOption] = useState(null);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const totalFeedbacksSelected = allQuestionsSelected ? totalFeedbacks : selectedRowsArr.length;

    const viewFeedback = row => {
        history.push(`/internal/feedback/${row.feedbackId}/view`, { filters: filters, type: type });
    };

    const updateFeedback = row => {
        dispatch(updateFeedbackAction(row));
    };
    const deleteFeedback = row => {
        dispatch(deleteFeedbackAction(row));
    };

    function hasFilters() {
        return filters.searchQuery || filters.game || filters.tpye || filters.status;
    }

    const handleSort = async (column, sortDirection) => {
        dispatchFilters({ page: 0, sortParam: { selector: column.selector, ascending: sortDirection == 'asc' ? 'true' : 'false' } });
    };

    const resetBulkActions = useCallback(() => {
        if (Object.keys(selectedRows).length > 0) {
            setSelectedRows({});
        }
        setAllQuestionsSelected(false);
        setCurrentActionOption(false);
    }, [selectedRows]);

    function preCheckBulk() {
        dispatch(
            preBulkActionFeedbacksAction(
                selectedRowsArr.map(el => Number(el)),
                allQuestionsSelected ? { filters: removeEmptyPropsFromObject(filters) } : null
            )
        );
    }

    const actionButtons = row => [
        {
            name: t('View'),
            onClick: viewFeedback,
            visible: true,
        },
        {
            name: row.isResolved ? t('Unresolve') : t('Resolve'),
            onClick: () => {
                let data = {};
                row.modalData = row.isResolved
                    ? dispatch(unresolveFeedbackBulkAction([row.feedbackId], data))
                    : dispatch(resolveFeedbackBulkAction([row.feedbackId], data));
            },
            visible: true,
        },
        {
            name: t('Go to question'),
            onClick: () => {
                window.open(`/content/questions/update/${row.questionId}`);
            },
            visible: row.questionId,
        },

        {
            name: t('Delete'),
            onClick: () => {
                setFeedbackToBeUpdated(row);
                setDeleteModalOpen(true);
            },
            visible: true,
        },
    ];

    const columns = useMemo(
        () => [
            {
                name: <CustomCheckboxForTable checked={areAllOptionsFromThisPageSelected()} selectAll={selectAll} deselectAll={deselectAll} />,
                width: '50px',
                cell: row => {
                    return (
                        <div style={{ height: '100%', width: '100%' }}>
                            <Checkbox
                                onClick={e => {
                                    if (!row.selected) {
                                        addSelectedRow(row);
                                    } else {
                                        removeSelectedRow(row);
                                        setAllQuestionsSelected(false);
                                    }
                                }}
                                checked={!!row.selected}
                            ></Checkbox>
                        </div>
                    );
                },
            },
            {
                name:
                    selectedRowsArr.length === 0 ? (
                        <TableHeader text={t('Feedback')} />
                    ) : (
                        <FeedbackTableActions
                            currentActionOption={currentActionOption}
                            totalFeedbacksSelected={totalFeedbacksSelected}
                            onChange={option => {
                                preCheckBulk();
                                setCurrentActionOption(option);
                                setModifyFeedbacksModalOpen(true);
                            }}
                            type={type}
                        />
                    ),
                selector: 'feedbackText',
                sortable: selectedRowsArr.length === 0,
                wrap: true,
                allowOverflow: selectedRowsArr.length > 0,
                width: '20vw',
                cell: row => {
                    let isPositive = row.feedbackType == 'POSITIVE';

                    return (
                        <TableCellContent
                            to={{ pathname: `/internal/feedback/${row.feedbackId}/view`, state: { filters: filters, type: type } }}
                            isLoading={dataIsLoading}
                            elementBefore={
                                <img
                                    alt="Type"
                                    src={row.feedbackText ? FeedbackIcon : row.feedbackType == 'POSITIVE' ? FeedbackPositiveIcon : FeedbackEmptyIcon}
                                    width={20}
                                    height={20}
                                    className={'mr-2'}
                                />
                            }
                            text={
                                row.feedbackText
                                    ? row.feedbackText?.length > 100
                                        ? `${row.feedbackText.substring(0, 100)}...`
                                        : row.feedbackText
                                    : isPositive
                                    ? t(positiveCommentText)
                                    : t(noCommentText)
                            }
                        />
                    );
                },
            },
            {
                name: selectedRowsArr.length === 0 ? <TableHeader text={t('Type')} /> : null,
                selector: 'feedbackType',
                sortable: selectedRowsArr.length === 0,
                style: {
                    width: '5vw',
                },

                cell: row => (
                    <TableCellContent
                        to={{ pathname: `/internal/feedback/${row.feedbackId}/view`, state: { filters: filters, type: type } }}
                        isLoading={dataIsLoading}
                        text={getTitleCase(row.feedbackType)}
                    />
                ),
            },
            {
                name: selectedRowsArr.length === 0 ? <TableHeader text={t('Status')} /> : null,
                selector: 'feedbackStatus',
                sortable: selectedRowsArr.length === 0,
                style: {
                    width: '5vw',
                },
                omit: type === 'positive',
                cell: row => (
                    <TableCellContent
                        to={{ pathname: `/internal/feedback/${row.feedbackId}/view`, state: { filters: filters, type: type } }}
                        isLoading={dataIsLoading}
                        text={getTitleCase(row.status?.split(' ')[0])}
                    />
                ),
            },
            {
                name: selectedRowsArr.length === 0 ? <TableHeader text={t('Sent by')} /> : null,
                selector: 'userFullName',
                sortable: selectedRowsArr.length === 0,
                style: {
                    width: '3vw',
                },

                cell: row => (
                    <TableCellContent
                        to={{ pathname: `/internal/feedback/${row.feedbackId}/view`, state: { filters: filters, type: type } }}
                        isLoading={dataIsLoading}
                        text={row.user}
                    />
                ),
            },
            {
                name: selectedRowsArr.length === 0 ? <TableHeader text={t('Date sent')} /> : null,
                selector: 'dateSubmitted',
                sortable: selectedRowsArr.length === 0,
                style: {
                    width: '8vw',
                },
                cell: row => (
                    <TableCellContent
                        to={{ pathname: `/internal/feedback/${row.feedbackId}/view`, state: { filters: filters, type: type } }}
                        isLoading={dataIsLoading}
                        text={new Date(row.dateSubmitted).toDateString()}
                    />
                ),
            },
            {
                name: selectedRowsArr.length === 0 ? <TableHeader text={t('Game')} /> : null,
                selector: 'game',
                sortable: selectedRowsArr.length === 0,
                style: {
                    width: '1vw',
                },
                omit: type.length > 0 ? type !== 'game' : true,
                cell: row => (
                    <TableCellContent
                        to={{ pathname: `/internal/feedback/${row.feedbackId}/view`, state: { filters: filters, type: type } }}
                        isLoading={dataIsLoading}
                        text={row.gameName}
                    />
                ),
            },
            {
                name: selectedRowsArr.length === 0 ? <TableHeader text={t('Game')} /> : null,
                selector: 'game',
                sortable: selectedRowsArr.length === 0,
                style: {
                    width: '2vw',
                },
                cell: row =>
                    (dataIsLoading && <SkeletonLoader />) || (
                        <TableCellContent
                            to={{ pathname: `/internal/feedback/${row.feedbackId}/view`, state: { filters: filters, type: type } }}
                            text={row.gameName}
                        />
                    ),
            },
            {
                name: selectedRowsArr.length === 0 ? <TableHeader text={t('Actions')} /> : null,
                cell: row => <ActionMenu row={row} buttons={actionButtons(row)} />,
                allowOverflow: true,
                button: true,
            },
        ],
        [dataIsLoading, filteredData]
    );

    useEffect(() => {
        dispatch(getGamesAction());

        let stateFilters;
        if (location?.state?.filters) {
            stateFilters = location?.state?.filters;
        }
        if (location?.search) {
            const query = new URLSearchParams(location.search);
            stateFilters = paramsToObject(query.entries());
        }
        if (stateFilters) {
            dispatchFilters({
                searchQuery: stateFilters.searchQuery,
                page: Number(stateFilters.page ?? 0),
                status: stateFilters.status,
                game: stateFilters.game,
                type: stateFilters.type,
                size: Number(stateFilters.size ?? 10),
                sortParam:
                    stateFilters.orderBy && stateFilters.ascending ? { selector: stateFilters.orderBy, ascending: stateFilters.ascending } : null,
            });
        }

        return () => dispatch(resetGetFeedbackPaginatedAction());
    }, []);

    useEffect(() => {
        if (feedbackPaginatedState?.data?.totalElements && feedbackPaginatedState?.data?.totalPages) {
            setTotalFeedbacks(feedbackPaginatedState.data.totalElements);
            setTotalPages(feedbackPaginatedState.data.totalPages);
            isInitialLoad.current = false;
        }
    }, [feedbackPaginatedState]);

    useEffect(() => {
        if (stateIsLoaded(feedbackUpdatedState)) {
            setUpdateModalOpen(false);
            if (feedbackToBeUpdated.isResolved) {
                setFeedbackResolvedModalOpen(true);
            }
            dispatch(resetUpdateFeedbackState());
        }
    }, [dispatch, feedbackUpdatedState]);

    useEffect(() => {
        if (stateIsLoaded(gamesState)) {
            setGameValues(
                gamesState.data.map((el, i) => {
                    return { value: el.gameSlug, label: el.gameName };
                })
            );
        }
    }, [gamesState]);

    useEffect(() => {
        if (stateIsLoaded(feedbackDeletedState)) {
            dispatch(resetDeleteFeedbackState());
            setDeleteModalOpen(false);
            toast.success(t('Successfully deleted!'));
        }
    }, [dispatch, feedbackDeletedState]);

    useEffect(() => {
        if (stateIsLoaded(resolveFeedbackBulkState) || stateIsLoaded(unresolveFeedbackBulkState) || stateIsLoaded(deleteFeedbackBulkState)) {
            dispatch(resetFeedbackBulkActions());
            if (type === 'content') {
                dispatch(getFeedbackContentPaginatedAction({ ...filters }));
            } else if (type == 'positive') {
                dispatch(getFeedbackPositivePaginatedActon({ ...filters }));
            }
        }
    }, [resolveFeedbackBulkState, unresolveFeedbackBulkState, deleteFeedbackBulkState]);
    useEffect(() => {
        if (stateIsLoaded(feedbackPaginatedState)) {
            setFeedbackData(feedbackPaginatedState.data.content);
            setDataIsLoading(false);
        } else {
            setDataIsLoading(true);
        }
    }, [feedbackData.length, feedbackPaginatedState]);

    useEffect(() => {
        setDataIsLoading(true);
        let search = linkBuilderFromQuery('', filters);
        setTimeoutLastExecute.addCallback(
            () => {
                if (type === 'content') {
                    dispatch(getFeedbackContentPaginatedAction({ page: filters.page, ...filters }));
                } else if (type === 'positive') {
                    dispatch(getFeedbackPositivePaginatedActon({ page: filters.page, ...filters }));
                }
                history.push({
                    pathname: `/internal/${type}-feedback`,
                    search: search ? search : '',
                });
            },
            300,
            'getFeedbackPaginatedActionUseEffect'
        );
        return () => setTimeoutLastExecute.removeCallback('getFeedbackPaginatedActionUseEffect');
    }, [filtersState]);

    useEffect(() => {
        if (stateIsLoaded(resolveFeedbackBulkState) || stateIsLoaded(unresolveFeedbackBulkState) || stateIsLoaded(deleteFeedbackBulkState)) {
            toast.success(t('Successfully updated!'));
            resetBulkActions();
        }
    }, [resolveFeedbackBulkState, unresolveFeedbackBulkState, deleteFeedbackBulkState]);

    if (dataIsLoading && !hasFilters() && isInitialLoad.current) {
        return <LoadingAnimation />;
    }

    if (filteredData?.length === 0 && !hasFilters() && !dataIsLoading) {
        return <FeedbackemptyState type={type} />;
    }

    return (
        <div>
            <ModifyFeedbackModal
                bulkQuestionSize={totalFeedbacksSelected}
                modalOpen={modifyFeedbacksModalOpen}
                setModalOpen={setModifyFeedbacksModalOpen}
                currentActionString={currentActionOption?.value}
                onAction={() => {
                    let data = {};
                    if (allQuestionsSelected) {
                        data = { filters: removeEmptyPropsFromObject(filters) };
                    }
                    // eslint-disable-next-line default-case
                    switch (currentActionOption?.value) {
                        case 'resolve':
                            dispatch(
                                resolveFeedbackBulkAction(
                                    selectedRowsArr.map(el => Number(el)),
                                    data
                                )
                            );
                            break;
                        case 'unresolve':
                            dispatch(
                                unresolveFeedbackBulkAction(
                                    selectedRowsArr.map(el => Number(el)),
                                    data
                                )
                            );
                            break;
                        case 'delete':
                            dispatch(
                                deleteFeedbackBulkAction(
                                    selectedRowsArr.map(el => Number(el)),
                                    data
                                )
                            );
                            break;
                    }
                }}
                onClose={() => {
                    setCurrentActionOption(null);
                    dispatch(resetPreBulkActionFeedbacksAction());
                }}
                resolve={currentActionOption?.value === 'resolve'}
            />
            <ModalDialog
                title={feedbackToBeUpdated?.modalData?.title}
                modalOpen={updateModalOpen}
                setModalOpen={setUpdateModalOpen}
                onAction={() => updateFeedback(feedbackToBeUpdated, type)}
                buttons={feedbackToBeUpdated?.modalData?.buttons}
                text={feedbackToBeUpdated?.modalData?.text}
                hasCancel={true}
                state={feedbackUpdatedState}
                hasLoader={true}
            ></ModalDialog>
            <ModalDialog
                title={deleteFeedbackModalData().title}
                modalOpen={deleteModalOpen}
                setModalOpen={setDeleteModalOpen}
                onAction={() => deleteFeedback(feedbackToBeUpdated)}
                buttons={deleteFeedbackModalData().buttons}
                text={deleteFeedbackModalData().text}
                hasCancel={true}
                state={feedbackDeletedState}
                hasLoader={true}
            ></ModalDialog>
            <FeedbackResolveDialog
                modalOpen={feedbackResolvedModalOpen}
                setModalOpen={setFeedbackResolvedModalOpen}
                userName={feedbackToBeUpdated?.user.split(' ')[0]}
            ></FeedbackResolveDialog>
            <div className="feedback--data-table">
                <DataTable
                    sortIcon={<img height={15} width={13} className="ml-2" src={CaretTable}></img>}
                    columns={columns}
                    data={filteredData}
                    paginationPerPage={100}
                    overflowY={'visible'}
                    className="overflow-visible"
                    overflowYOffset={'0px'}
                    defaultSortField="dateSubmitted"
                    defaultSortAsc={false}
                    noDataComponent={<TableEmptyState />}
                    onSort={handleSort}
                    noHeader={true}
                    sortServer
                    subHeader={true}
                    onRowClicked={row => viewFeedback(row)}
                    pagination={true}
                    selectableRows={false}
                    highlightOnHover={true}
                    subHeaderComponent={
                        <Subheader
                            inputs={
                                type == 'content'
                                    ? feedbackSubheaders({
                                          filtersState,
                                          activeFilters,
                                          dispatchFilters,
                                          updateFilterState,
                                          gameValues,
                                          resetBulkActions,
                                      })
                                    : feedbackPositiveSubheaders({
                                          filtersState,
                                          activeFilters,
                                          dispatchFilters,
                                          updateFilterState,
                                          gameValues,
                                          resetBulkActions,
                                      })
                            }
                        />
                    }
                    subHeaderAlign={'left'}
                    customStyles={customStylesTable}
                    selectableRowSelected={row => row.selected}
                    theme={'tableTheme'}
                    paginationComponent={_ => (
                        <PaginationComponent
                            getPrev={getPrevious}
                            getNext={getNext}
                            getPage={getPage}
                            currentPage={filters.page}
                            totalPages={totalPages}
                            totalItems={totalFeedbacks}
                            size={filters.size}
                            setSize={size => {
                                dispatchFilters({ page: 0, size: size });
                            }}
                        />
                    )}
                />
            </div>
        </div>
    );
}
