import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { authService } from 'services/auth/auth';

const lngDet = new LanguageDetector();

export const getLanguageForUser = () => {
    const userId = authService?.user?.id;

    if (!userId) {
        return 'en';
    }

    const languages = JSON.parse(localStorage.getItem('language'));

    if (!languages) {
        return 'en';
    }

    const latestLanguage = languages['latest'];

    if (!latestLanguage) {
        return 'en';
    }

    return userId && languages[userId] ? languages[userId] : !userId && latestLanguage ? latestLanguage : 'en';
};

var customDetector = {
    name: 'customDetector',

    lookup(options) {
        return getLanguageForUser();
    },

    cacheUserLanguage(lng, options) {
        const userId = authService?.user?.id;
        const languages = JSON.parse(localStorage.getItem('language'));
        localStorage.setItem('language', JSON.stringify(languages ? { ...languages, [userId]: lng, latest: lng } : { [userId]: lng, latest: lng }));
    },
};

lngDet.addDetector(customDetector);

i18n.use(Backend)
    .use(lngDet)
    .use(initReactI18next)
    .init({
        // debug: process.env.NODE_ENV === 'development',
        fallbackLng: 'en',
        keySeparator: '>',
        nsSeparator: '§',
        detection: {
            order: ['customDetector'],
            caches: ['customDetector'],
        },
    });

export default i18n;
