import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'style/colors';
import { Body1, Body2 } from 'style/typography/Body';

import '../UserDeviceRequests.css';

export default function UserDeviceRequestInfoComponent({ itemTitle, value }) {
    const { t } = useTranslation();

    return (
        <div style={{ marginTop: 30 }}>
            <Body2 color={colors.primaryText}>{t(itemTitle)}</Body2>
            <Body1 color={colors.dark}>{value}</Body1>
        </div>
    );
}
