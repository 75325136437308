import React, { useState } from 'react';

import RolePickerDialog from './RolePickerDialog';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import EditOrangeIcon from 'assets/icons/icon-edit-orange.svg';
import RoleIcon from 'assets/icons/company-role.svg';

import '../TrainingSettings.css';

export default function TrainingRolesPicker() {
    const { t } = useTranslation();

    const rolesConnectedToSetState = useSelector(state => state.userManagement.rolesConnectedToSet);

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div>
            <div
                className="roles-picker--button"
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                <img src={RoleIcon} height={20} width={20} alt="edit" />
                <Trans>
                    <Body2 colors={colors.dark}>
                        {t('<strong>{{rolesCount}}</strong> roles assigned.', { rolesCount: rolesConnectedToSetState.data?.length })}
                    </Body2>
                </Trans>
                <img src={EditOrangeIcon} height={20} width={20} alt="edit" style={{ marginLeft: 'auto' }} />
                <Body2 color={colors.mainTheme}>{t('Edit')}</Body2>
            </div>
            {modalOpen && <RolePickerDialog modalOpen={modalOpen} setModalOpen={setModalOpen} onConfirm={() => {}} />}
        </div>
    );
}
