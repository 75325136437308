import React, { useEffect, useRef, useState } from 'react';
import ContentBlocksTableHeader from './ContentBlocksTableHeader';
import ContentBlocksTableCell from './ContentBlocksTableCell';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { OutlinedButton } from 'components/elements/Button';
import { downloadCSVDataFile, downloadOriginalDataFile } from './contentBlocksHelper';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import useContentBlocksState from './useContentBlocksState';
import _ from 'lodash';
import InputTooltip from 'components/elements/tooltips/InputTooltip';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { useContentBlock } from 'services/content-blocks-management/content-blocks';

import './ContentBlocksTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { getContentBlocksPaginated, resetSaveContentBlock, saveContentBlock } from 'redux/actions/content.actions';
import toast from 'react-hot-toast';
import { stateIsLoaded } from 'redux/core/stateHelpers';

export default function ContentBlocksTable() {
    const {
        undo: undoData,
        contentBlock,
        rowsToShow,
        setRowsToShow,
        selectedEdges,
        setFirstCell,
        setCurrentCell,
        addRow,
        columnWidths,
        resetState,
    } = useContentBlock();

    const { onJsonFileUploaded, onCSVFileUploaded, isInsideSelection, copyCells, setDeleteItems } = useContentBlocksState();

    const [isSelecting, setIsSelecting] = useState(false);
    const [showMoreActionsDialog, setShowMoreActionsDialog] = useState(false);

    const saveContentBlockState = useSelector(state => state.content.saveContentBlock);

    const fileUploadRef = useRef(null);

    const tableContainerData = useRef({
        scrollTop: 0,
        scrollLeft: 0,
        containerWidth: 400,
    });

    const dispatch = useDispatch();

    function onMouseUp() {
        setIsSelecting(false);
    }

    function setToShowDialog(toShow) {
        setTimeoutLastExecute.addCallback(
            () => {
                setShowMoreActionsDialog(toShow);
            },
            100,
            `showMoreActionsDialog`
        );
    }

    let onKeyDown = event => {
        switch (event.keyCode) {
            case 8:
                setDeleteItems(true);
            case 90:
                if (event.ctrlKey) {
                    undoData();
                }
        }
    };

    function onFileUploaded(e) {
        let file = e.target.files[0];
        if (file.type === 'application/json') {
            onJsonFileUploaded(file);
        } else if (file.type === 'text/csv') {
            onCSVFileUploaded(file);
        }
    }

    useEffect(() => {
        updateCells();
    }, [contentBlock.data.columns, columnWidths]);

    useEffect(() => {
        if (stateIsLoaded(saveContentBlockState)) {
            toast.success('Successfully saved!');
            dispatch(resetSaveContentBlock());
            dispatch(getContentBlocksPaginated({ page: 0, size: 10 }));
            resetState();
        }
    }, [saveContentBlockState]);

    function onTableScrollHandler(e) {
        tableContainerData.current = {
            scrollTop: e.currentTarget.scrollTop,
            scrollLeft: e.currentTarget.scrollLeft,
            width: e.currentTarget.offsetWidth,
        };
        updateCells();
    }

    function updateCells() {
        let topRowToShow = Math.floor(tableContainerData.current.scrollTop / 50);
        let containerWidth = tableContainerData.current.width;

        let leftOffset = 85;
        let leftCol, rightCol;
        let currentLeftOffset;

        let sumColWidths = 0;
        for (let i = 0; i < contentBlock.data.columns.length; i++) {
            sumColWidths += columnWidths[contentBlock.data.columns[i].key];
            if (sumColWidths >= tableContainerData.current.scrollLeft - leftOffset && leftCol == undefined) {
                leftCol = i == 0 ? i : i - 1;
                currentLeftOffset = sumColWidths;
            }

            if (sumColWidths > currentLeftOffset + containerWidth && rightCol == undefined) {
                rightCol = i + 1 > contentBlock.data.columns.length ? i : i + 1;
                break;
            }
        }
        if (true) {
            setRowsToShow({
                left: leftCol,
                right: rightCol ?? contentBlock.data.columns.length,
                top: topRowToShow >= 3 ? topRowToShow - 3 : 0,
                bottom: topRowToShow + 13 <= contentBlock.data.rows.length ? topRowToShow + 13 : contentBlock.data.rows.length,
            });
        }
    }

    function generateIndexCell(i) {
        return (
            <div className="cb--checkbox-cell" style={{ position: 'relative', height: 50, width: 35, textAlign: 'center' }}>
                <Body2 color={colors.gray6}>{i}</Body2>
            </div>
        );
    }

    function generateCheckBoxCell() {
        return (
            <div className="cb--checkbox-cell" style={{ position: 'relative', height: 50, width: 50 }}>
                <Checkbox onClick={_ => { }} checked={false}></Checkbox>
            </div>
        );
    }

    var generatedCellsList = () => {
        let tableCells = [];
        if (rowsToShow.right > contentBlock.data.columns.length) {
            return;
        }
        for (let i = rowsToShow.top; i < rowsToShow.bottom; i++) {
            for (let j = rowsToShow.left ?? 0; j < rowsToShow.right ?? contentBlock.data.columns.length; j++) {
                if (j == 0) {
                    tableCells.push(generateIndexCell(i));
                    tableCells.push(generateCheckBoxCell());
                }
                let currentColKey = contentBlock.data.columns[j].key;
                let currentColId = contentBlock.data.columns[j].id;
                let currentGeneratedColId = contentBlock.data.columns[j].generatedId;
                let currentColType = contentBlock.data.columns[j].type;
                let rowId = contentBlock.data.rows[i]?.rowId;
                let generatedRowId = contentBlock.data.rows[i]?.generatedRowId;
                let isInSelection = isInsideSelection(i, j);

                tableCells.push(
                    <ContentBlocksTableCell
                        key={`${i},${j}`}
                        index={`${i},${j}`}
                        row={i}
                        col={j}
                        colName={currentColKey}
                        colId={currentColId}
                        generatedColId={currentGeneratedColId}
                        rowId={rowId}
                        generatedRowId={generatedRowId}
                        onMouseDown={() => {
                            setFirstCell(`${i},${j}`);
                            setCurrentCell(`${i},${j}`);
                            setIsSelecting(true);
                        }}
                        onMouseEnter={_ => {
                            if (isSelecting) {
                                setCurrentCell(`${i},${j}`);
                            }
                        }}
                        onCopy={e => {
                            let isOneCellSelected =
                                selectedEdges.bottomEndIndx == selectedEdges.topEndIndx && selectedEdges.leftEndIndx == selectedEdges.rightEndIndx;
                            if (!isOneCellSelected) {
                                e.preventDefault();
                                copyCells();
                            }
                        }}
                        cellSelected={isInSelection}
                        cellValue={
                            contentBlock.data.rows[i]?.cells?.find(
                                el =>
                                    (currentColId && currentColId != -1 && el.colId == currentColId) ||
                                    (currentGeneratedColId && el.generatedColId == currentGeneratedColId)
                            )?.cellValue ?? ''
                        }
                        cellType={currentColType}
                    />
                );
            }
        }
        return tableCells;
    };

    useEffect(() => {
        window.addEventListener('mouseup', onMouseUp, false);
        window.addEventListener('keydown', onKeyDown, false);

        return () => {
            window.removeEventListener('mouseup', onMouseUp, false);
            window.removeEventListener('keydown', onKeyDown, false);
        };
    }, []);
    return (
        <>
            <div className="cb-header--top-container">
                <Body2 color={colors.gray6}>{`${contentBlock.data.rows.length} rows`}</Body2>
                <OutlinedButton text="Insert Item" onClick={addRow} />
                <input
                    type="file"
                    ref={fileUploadRef}
                    onChange={e => {
                        onFileUploaded(e);
                    }}
                    accept=".csv, .json"
                    style={{ display: 'none' }}
                />
                <div style={{ position: 'relative' }}>
                    <OutlinedButton text="More actions" onClick={() => setToShowDialog(true)} onMouseLeave={() => setToShowDialog(false)} />
                    {showMoreActionsDialog && (
                        <div style={{ top: -50, left: 0 }}>
                            <InputTooltip
                                visible={true}
                                onMouseEnter={() => setToShowDialog(true)}
                                onMouseLeave={() => setToShowDialog(false)}
                                position={'bottom'}
                                options={[
                                    {
                                        label: 'Import Table',
                                        value: 'Import Table',
                                        type: 'option',

                                        onClick: () => fileUploadRef.current.click(),
                                    },
                                    {
                                        value: 'Save Table',
                                        label: 'Save Table',
                                        type: 'option',

                                        // onClick: () => downloadOriginalDataFile(contentBlock.data),
                                        onClick: () => {
                                            dispatch(saveContentBlock(contentBlock));
                                        },
                                    },
                                    {
                                        type: 'divider',
                                    },
                                    {
                                        value: 'Import CSV',
                                        label: 'Import CSV',

                                        type: 'option',

                                        onClick: () => fileUploadRef.current.click(),
                                    },
                                    {
                                        value: 'Export CSV',
                                        type: 'option',
                                        label: 'Export CSV',

                                        onClick: () => downloadCSVDataFile(contentBlock.data),
                                    },
                                ]}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div
                className="cb-table--container prevent-select"
                onScroll={e => {
                    onTableScrollHandler(e);
                }}
            >
                <div style={{ height: `${50 * contentBlock.data.rows.length}px` }}>
                    <ContentBlocksTableHeader />
                    <div>
                        <div
                            style={{
                                height: `${rowsToShow.top == 0 ? 0 : rowsToShow.top * 50}px`,
                                backgroundColor: 'transparent',
                            }}
                        ></div>
                        <div
                            style={{
                                paddingLeft: `${rowsToShow.left != undefined
                                    ? Object.keys(columnWidths).reduce((acc, val, i) => {
                                        return i < rowsToShow.left ? acc + columnWidths[val] : acc;
                                    }, 0) + (rowsToShow.left > 0 ? 85 : 0)
                                    : 0
                                    }px`,
                                display: 'grid',
                                gridTemplateColumns: `repeat(${(rowsToShow.left != undefined
                                    ? rowsToShow.right - rowsToShow.left
                                    : contentBlock.data.columns.length) + (rowsToShow.left > 0 ? 0 : 2)},min-content)`,
                                gap: 0,
                            }}
                        >
                            {generatedCellsList()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
