import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body2 } from 'style/typography/Body';
import { getLabelsAction, resetRemoveUsersFromTagsAction } from 'redux/actions/tags.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { Caption1 } from 'style/typography/Caption';
import FeedbackIcon from 'assets/icons/icon-emptystate-tags.svg';
import { H8 } from 'style/typography/Heading';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function RemoveTagsBulk({ modalOpen, setModalOpen, onAction, onClose, bulkItemsCount, tagsInUsers, allUsersSelected }) {
    const labelsState = useSelector(state => state.content.labels);
    const tagsState = useSelector(state => state.content.tags);
    const removeUsersFromTagsState = useSelector(state => state.content.removeUsersFromTags);

    const [selectedTags, setSelectedTags] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    function selectTag(tagId) {
        let tagIsSelected = selectedTags.find(el => el === tagId);
        if (tagIsSelected) {
            setSelectedTags(prevData => prevData.filter(el => el !== tagId));
        } else {
            setSelectedTags(prevData => [...prevData, tagId]);
        }
    }

    useEffect(() => {
        dispatch(getLabelsAction());
    }, []);

    useEffect(() => {
        if (stateIsLoaded(removeUsersFromTagsState)) {
            dispatch(resetRemoveUsersFromTagsAction());
            setModalOpen(false);
            onClose(true);
        }
    }, [removeUsersFromTagsState]);

    let title = bulkItemsCount > 1 ? t('Remove tags from {{bulkItemsCount}} users', { bulkItemsCount: bulkItemsCount }) : t('Remove tags from user');

    const buttonsEmptyState = [
        {
            title: t('Remove tags'),
            onClick: () => {
                history.replace('/company/tags');
            },
        },
    ];

    const buttons = [
        {
            title: t('Remove tags'),
            color: colors.white,
        },
    ];

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onClose={onClose}
            onAction={() => {
                onAction(selectedTags);
            }}
            title={labelsState.data?.length > 0 ? title : ''}
            buttons={labelsState.data?.length > 0 ? buttons : buttonsEmptyState}
            hasCancel={true}
            alert={
                <div style={{ width: '80%' }}>
                    {labelsState.data?.length == 0 && (
                        <div style={{ paddingTop: 10, alignContent: 'center', textAlign: 'center' }}>
                            <img style={{ width: 80, height: 80 }} src={FeedbackIcon} alt="" />
                            <H8 color={colors.secoundaryText} className={'mt-4'}>
                                {t('No existing tags')}
                            </H8>
                            <Body2 className={'mt-3'} color={colors.gray6}>
                                {t('You can create labels and add tags inside the company tab.')}
                            </Body2>
                        </div>
                    )}
                    {labelsState.data?.length > 0 && (
                        <div>
                            <Body2>{t('Select the tags that you want to remove')}</Body2>
                            <div className="tagsModal--container">
                                {(allUsersSelected ? tagsState?.data : tagsInUsers).map(tag => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '5px',
                                                padding: '0px 5px',
                                                margin: '5px 0px',
                                            }}
                                        >
                                            <div style={{ position: 'relative', height: '20px', width: '20px' }}>
                                                <Checkbox
                                                    id={'check'}
                                                    onClick={() => selectTag(tag.tagId)}
                                                    checked={selectedTags.find(selTag => selTag === tag.tagId)}
                                                ></Checkbox>
                                            </div>
                                            <Body2>{tag.tag}</Body2>
                                        </div>
                                    );
                                })}
                            </div>
                            <div style={{ paddingTop: 10 }}>
                                <Caption1 color={colors.gray6}>{t('{{selectedTags}} selected tags', { selectedTags: selectedTags.length })}</Caption1>
                            </div>
                        </div>
                    )}
                </div>
            }
        ></ModalDialog>
    );
}

RemoveTagsBulk.propTypes = {
    bulkItemsCount: PropTypes.number,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
