import React, { useEffect, useState } from 'react';
import { DefaultButton } from 'components/elements/Button';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { colors } from 'style/colors';
import { AddLabelModal } from './local_components/AddLabelModal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLabelByIdAction, getLabelsAction, resetGetUsersWithTagAction, resetGetUsersWithoutTagWithIdAction } from 'redux/actions/tags.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { TagsEmptyState } from './local_components/TagsEmptyState';
import BulkSetTagsModal from './local_components/BulkSetTagsModal';
import { DeleteLabelDialog } from './local_components/DeleteLabelDialog';
import LabelsListContainer from './local_components/LabelsListContainer';
import { SideMenuTags } from './side_menu/SideMenuTags';

import { useTranslation } from 'react-i18next';

import './TagsTab.css';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { linkBuilderFromQuery } from 'services/api/api_builders';

export default function TagsTab() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
    const [currentNode, setCurrentNode] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [tagsData, setTagsData] = useState(null);
    const [labelToDelete, setLabelToDelete] = useState(null);

    const labelsState = useSelector(state => state.content.labels);
    const deleteLabelByIdState = useSelector(state => state.content.deleteLabelById);

    function deleteLabel(labelId) {
        if (currentNode?.labelId === labelId) {
            setCurrentNode(null);
        }
        dispatch(deleteLabelByIdAction(labelId));
    }

    useEffect(() => {
        if (stateIsLoaded(deleteLabelByIdState)) {
            dispatch(getLabelsAction());
        }
    }, [deleteLabelByIdState]);

    useEffect(() => {
        if (stateIsLoaded(labelsState)) {
            setTagsData(labelsState.data);
        }
    }, [labelsState]);

    useEffect(() => {
        dispatch(getLabelsAction());
    }, []);

    useEffect(() => {
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(
                    getLabelsAction({
                        searchQuery: searchValue,
                    })
                );
            },
            300,
            'getLabelsCallback'
        );
        return () => setTimeoutLastExecute.removeCallback('getLabelsCallback');
    }, [dispatch, searchValue]);

    return (
        <div style={{ height: '90vh' }}>
            {isBulkModalOpen && (
                <BulkSetTagsModal
                    isOpen={isBulkModalOpen}
                    handleClose={() => {
                        setIsBulkModalOpen(false);
                    }}
                    onUpload={() => {
                        setCurrentNode(null);
                        dispatch(resetGetUsersWithTagAction());
                        dispatch(resetGetUsersWithoutTagWithIdAction());
                    }}
                />
            )}
            <AddLabelModal modalOpen={isModalOpen} setModalOpen={setIsModalOpen} />
            <DeleteLabelDialog modalOpen={labelToDelete} setModalOpen={() => setLabelToDelete(null)} onAction={() => deleteLabel(labelToDelete)} />
            {tagsData?.length === 0 && !searchValue && <TagsEmptyState setAddLabelModalOpen={setIsModalOpen}></TagsEmptyState>}
            {(tagsData?.length > 0 || searchValue) && (
                <div className={'ml-4 h-100'}>
                    <div className="d-flex flex-row h-100">
                        <div style={{ backgroundColor: colors.gray3 }} className="col-6 px-0">
                            <div className="d-flex flex-row justify-content-between w-100" style={{ padding: 20 }}>
                                <div className="ml-4 mr-2">
                                    <CustomTextField
                                        id="name-roles"
                                        isSearch={true}
                                        value={searchValue}
                                        setValue={setSearchValue}
                                        placeholder={t('Name')}
                                    ></CustomTextField>
                                </div>

                                <div className="ml-auto">
                                    <DefaultButton
                                        id="add-label-button"
                                        text={t('Add label')}
                                        onClick={() => {
                                            setIsModalOpen(true);
                                        }}
                                    />
                                    <DefaultButton
                                        id="upload-user-labels-button"
                                        backgroundColor="white"
                                        textColor={colors.dark}
                                        className="ml-2 mr-5"
                                        text={t('Bulk set tags')}
                                        onClick={() => {
                                            setIsBulkModalOpen(true);
                                        }}
                                    />
                                </div>
                            </div>
                            <LabelsListContainer
                                labels={tagsData ?? []}
                                currentNode={currentNode}
                                setCurrentNode={setCurrentNode}
                                setNodeToBeDeleted={setLabelToDelete}
                            />
                        </div>
                        <SideMenuTags currentLabelId={currentNode?.labelId} />
                    </div>
                </div>
            )}
        </div>
    );
}
