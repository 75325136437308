import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useCompareVisualOptions } from 'services/content-management/insertion-control';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import { validatorCategories } from 'services/validation-module/local-constants';
import { InputWithInfoPopUpWrapper } from 'components/features/content_management/inputs/InputWithInfoPopUpWrapper';
import CropperModal from 'components/features/content_management/cropping_module/CropperModal';
import { ImageEditorProvider } from 'services/image_management/image-editor-management';
import { colors } from 'style/colors';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import Camera from 'assets/icons/camera.svg';
import Remove from 'assets/icons/icon-remove.svg';
import SelectionTooltip from 'components/elements/tooltips/SelectionTooltip';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { getNameFromLink, shortenString } from 'services/utils/stringHelpers';

import ReplaceImage from 'assets/icons/icon-replaceimage.svg';
import RemoveImage from 'assets/icons/icon-removeimage.svg';
import IndexComponent from 'components/elements/index_component/IndexComponent';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/elements/checkbox/Checkbox';

const InputOptionsCompare = ({ value, index, image, showRemove = true, compareCriteria }) => {
    const { t } = useTranslation();

    const {
        answers: options,
        insertAnswer: insertOption,
        updateAnswer: updateOption,
        removeAnswer: removeOption,
        replaceImage,
        removeImage,
    } = useCompareVisualOptions();
    const [openImageResizer, setOpenImageResizer] = useState(false);
    const [showImageDialog, setShowImageDialog] = useState(false);

    function addImages(index, images) {
        let currentIndex = index;
        images.forEach(el => {
            if (currentIndex >= options.length) {
                insertOption();
            }
            replaceImage(currentIndex, el);
            currentIndex += 1;
        });
    }

    function setToShowDialog(toShow) {
        setTimeoutLastExecute.addCallback(
            () => {
                setShowImageDialog(toShow);
            },
            100,
            `showDialogImage${index}`
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            <div className="d-flex flex-row">
                <div className={'d-flex flex-row align-items-center'} style={{ margin: '10px 0', height: '60px', gap: '10px' }}>
                    <IndexComponent index={index + 1} />
                    {image && (
                        <div className="d-flex flex-row align-items-center cursor-pointer" style={{ position: 'relative' }}>
                            <img
                                alt="Compare"
                                onMouseEnter={() => setToShowDialog(true)}
                                onMouseLeave={() => setToShowDialog(false)}
                                src={image}
                                width={80}
                                height={40}
                                id={'add-image-compare-' + index}
                            />
                            {showImageDialog && (
                                <SelectionTooltip
                                    visible={showImageDialog}
                                    onMouseEnter={() => setToShowDialog(true)}
                                    onMouseLeave={() => setToShowDialog(false)}
                                    position={'bottom'}
                                    options={[
                                        { text: shortenString(getNameFromLink(image), 20), type: 'title' },
                                        {
                                            text: t('Replace'),
                                            icon: ReplaceImage,
                                            type: 'button',
                                            onClick: () => {
                                                setOpenImageResizer(true);
                                                setToShowDialog(false);
                                            },
                                        },
                                        {
                                            text: t('Remove'),
                                            icon: RemoveImage,
                                            type: 'button',
                                            onClick: () => {
                                                removeImage(index);
                                                setToShowDialog(false);
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    )}
                    {!image && (
                        <>
                            <div
                                onClick={() => {
                                    setOpenImageResizer(true);
                                }}
                                style={{
                                    border: `1px solid ${colors.gray1}`,
                                    padding: 20,
                                    cursor: 'pointer',
                                    width: 80,
                                    height: 40,
                                    display: 'flex',
                                    backgroundColor: colors.gray2,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img alt="Camera" src={Camera} width={25} height={25} />
                            </div>
                        </>
                    )}

                    <InputWithInfoPopUpWrapper index={index} answer={value}>
                        {({ stopInput, onChange }) => {
                            if (compareCriteria?.value !== 'boolean') {
                                return (
                                    <CustomTextField
                                        id={'input-option-compare-' + index}
                                        type="text"
                                        className="form-control"
                                        font={15}
                                        placeholder="Value"
                                        style={{ height: '40px', width: '300px' }}
                                        value={value}
                                        setValue={val => updateOption({ index, element: { image, text: val } })}
                                    />
                                );
                            } else {
                                return (
                                    <div style={{ position: 'relative', height: '20px', width: '20px' }}>
                                        <Checkbox
                                            circular={true}
                                            onClick={val => updateOption({ index, element: { image, text: value && value != '0' ? '0' : '1' } })}
                                            checked={value && value != '0'}
                                        />
                                    </div>
                                );
                            }
                        }}
                    </InputWithInfoPopUpWrapper>
                </div>

                <div>
                    <>
                        {openImageResizer && (
                            <ImageEditorProvider index={index} setOpenImageResizer={setOpenImageResizer} imageLimit={1}>
                                <CropperModal
                                    index={index}
                                    addImage={replaceImage}
                                    addMultipleImages={addImages}
                                    setOpenImageResizer={setOpenImageResizer}
                                ></CropperModal>
                            </ImageEditorProvider>
                        )}
                    </>
                </div>
                {showRemove && (
                    <div className="d-flex align-items-center ml-2" style={{ fontSize: '13px' }}>
                        <img alt="Remove" className="cursor-pointer" onClick={() => removeOption(index)} height={20} width={20} src={Remove} />
                    </div>
                )}
            </div>
            <ValidationPopup category={validatorCategories.IMAGE_WITHOUT_VALUE} position="left" index={index} indexed />
            <ValidationPopup category={validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY} position="left" index={index} indexed />
        </div>
    );
};

InputOptionsCompare.propTypes = {
    image: PropTypes.any,
    index: PropTypes.number,
    showRemove: PropTypes.bool,
    value: PropTypes.any,
};

export default InputOptionsCompare;
