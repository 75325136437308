import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';
import UserDeviceRequestInfoComponent from './UserDeviceRequestInfoComponent';

import '../UserDeviceRequests.css';
import { useSelector } from 'react-redux';

export default function UserDeviceRequestInfo({ deviceRequest, history, setModalOpenAction, setDeleteModalOpen }) {
    const { t } = useTranslation();

    const deviceRequestsForUser = useSelector(state => state.content.deviceRequestsForUser).data;
    const isResolved = deviceRequestsForUser?.requests?.find(el => el.isResolved === false) === undefined;
    return (
        <div className="device-req--info">
            <UserDeviceRequestInfoComponent itemTitle="Sent by" value={deviceRequestsForUser?.userFullName} />
            <UserDeviceRequestInfoComponent
                itemTitle="E-Mail / Number"
                value={
                    deviceRequestsForUser.email
                        ? deviceRequestsForUser.email
                        : `+${deviceRequestsForUser.countryCode} ${deviceRequestsForUser.phoneNumber}`
                }
            />
            <UserDeviceRequestInfoComponent itemTitle="Role" value={deviceRequestsForUser?.role} />
            <UserDeviceRequestInfoComponent itemTitle="Learning set" value={deviceRequestsForUser?.learningSet} />
            {deviceRequestsForUser?.requests?.length === 1 && (
                <UserDeviceRequestInfoComponent itemTitle="Date" value={deviceRequestsForUser?.requests[0].dateReceived} />
            )}

            <div style={{ marginTop: '30px' }}>
                <Body2 color={colors.primaryText}>{t('Status')}</Body2>
                <div>
                    <Body2 className="device-req--info-badge">
                        <FontAwesomeIcon color={isResolved ? colors.mainTheme : colors.disabled} className={'mr-2'} icon={faCircle} size={'sm'} />
                        {isResolved ? t('Resolved') : t('Pending')}
                    </Body2>
                </div>
            </div>
        </div>
    );
}
