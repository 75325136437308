import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper/Paper';
import { colors } from 'style/colors';
import { makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { Body2 } from 'style/typography/Body';

import Close from 'assets/icons/filter-close.svg';
import CloseWhite from 'assets/icons/filter-close-white.svg';
import { Caption1 } from 'style/typography/Caption';
import { Subtitle2 } from 'style/typography/Subtitle';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getLabelsAction, updateLabelsAction } from 'redux/actions/tags.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';

import '../TagsTab.css';
import { useTranslation } from 'react-i18next';

export function AddLabelModal({ modalOpen, setModalOpen }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [labelName, setNewLabelName] = useState('');
    const [newTagValue, setNewTagValue] = useState('');
    const [tagValues, setTagValues] = useState([]);

    const updateLabelsState = useSelector(state => state.content.updateLabels);

    function saveLabel() {
        if (labelName.replaceAll(' ', '') === '') {
            return;
        }
        dispatch(
            updateLabelsAction({
                labelId: null,
                label: labelName,
                isRequired: false,
                tags: tagValues.map(el => {
                    return {
                        tagId: null,
                        tag: el,
                        labelId: -1,
                    };
                }),
            })
        );
    }

    function onTagAddValue() {
        if (tagValues.find(el => el === newTagValue) || newTagValue.replaceAll(' ', '') === '') {
            return;
        }
        setTagValues(prevData => {
            return [...prevData, newTagValue];
        });
        setNewTagValue('');
    }

    useEffect(() => {
        if (stateIsLoaded(updateLabelsState)) {
            setNewTagValue('');
            setNewLabelName('');
            setTagValues([]);
            dispatch(getLabelsAction());
            setModalOpen(false);
        }
    }, [updateLabelsState]);

    return (
        <Modal
            open={modalOpen}
            className={classes.modal}
            onClose={() => {
                setModalOpen(false);
                setNewTagValue('');
                setNewLabelName('');
                setTagValues([]);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper className={`${classes.paper} row tags--add-label-paper`}>
                <div className="col-12 d-flex flex-row p-3">
                    <div className="d-flex flex-column">
                        <Subtitle2 color={colors.dark} weight="medium">
                            {t('Add new label')}
                        </Subtitle2>
                        <Body2 className={'mt-1'} color={colors.dark}>
                            {t('Choose a general label name')}
                        </Body2>
                    </div>
                    <img
                        alt="Close"
                        className="cursor-pointer"
                        style={{ position: 'absolute', right: 20, top: 20 }}
                        src={Close}
                        height={20}
                        width={20}
                        onClick={() => setModalOpen(false)}
                    />
                </div>
                <div style={{ width: '95%', paddingLeft: '5%' }}>
                    <Caption1 color={colors.gray6}>{t('LABEL NAME')}</Caption1>
                    <CustomOutlinedTextField
                        id="new-topic-modal-title-input"
                        width={'100%'}
                        value={labelName}
                        setValue={setNewLabelName}
                        placeholder={t('Enter label name...')}
                    ></CustomOutlinedTextField>
                </div>
                <div style={{ width: '95%', paddingLeft: '5%', paddingTop: 10 }}>
                    <Caption1 color={colors.gray6}>{t('TAGS')}</Caption1>
                    <div className="tags--add-label-tag-input-container">
                        <CustomOutlinedTextField
                            id="new-topic-modal-title-input"
                            width={'100%'}
                            value={newTagValue}
                            setValue={setNewTagValue}
                            placeholder={t('Enter tag name...')}
                        ></CustomOutlinedTextField>
                        <OutlinedButton text={t('Add value')} onClick={onTagAddValue} />
                    </div>
                </div>
                <div
                    className="tags--add-label-tag-container"
                    style={{
                        justifyContent: !tagValues || tagValues.length == 0 ? 'center' : 'start',
                    }}
                >
                    {!tagValues || (tagValues.length == 0 && <Caption1 color={colors.gray6}>{t('No tags added..')}</Caption1>)}
                    {tagValues.length > 0 &&
                        tagValues.map(el => (
                            <div key={el} className="tags--label-pill">
                                <Body2 color={colors.white}>{el}</Body2>
                                <img
                                    alt="Close"
                                    className="cursor-pointer"
                                    style={{ marginLeft: '3px' }}
                                    src={CloseWhite}
                                    height={15}
                                    width={15}
                                    onClick={() => setTagValues(prevData => prevData.filter(elInner => elInner != el))}
                                />
                            </div>
                        ))}
                </div>
                <div style={{ margin: '10px 30px' }}>
                    <DefaultButton
                        text={t('Save label')}
                        onClick={() => {
                            saveLabel();
                        }}
                    />
                </div>
            </Paper>
        </Modal>
    );
}

AddLabelModal.propTypes = {
    modalOpen: PropTypes.any,
    setModalOpen: PropTypes.func,
};

const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: colors.gray4,
            border: `1px solid ${colors.gray3}`,
            boxShadow: theme.shadows[5],
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});
