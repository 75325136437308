import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import PhoneMockup from 'assets/icons/phone-mockup.svg';
import { Body2 } from 'style/typography/Body';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsNotInitialized } from 'redux/core/stateHelpers';
import { getAllGamesAction } from 'redux/actions/content.actions';
import { useTranslation } from 'react-i18next';

const PreviewContent = ({ children, game }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const gamesState = useSelector(state => state.content.allGames);

    const [gameIcon, setGameIcon] = useState('');

    useEffect(() => {
        if (stateIsNotInitialized(gamesState)) {
            dispatch(getAllGamesAction());
        } else if (stateIsLoaded(gamesState) && game) {
            setGameIcon(gamesState.data.filter(el => el.gameSlug === game?.toLowerCase().replace(' ', '-'))[0].gameIcon);
        }
    }, [gamesState]);

    return (
        <div style={{ height: '80%', width: '40%', position: 'absolute', right: 0, bottom: 0 }} className="pr-0 mt-4">
            <div className="preview-adding-question-wrapper p-4 h-100">
                <div>
                    <Caption1 color={colors.gray6}>{t('PREVIEW IN GAME')}</Caption1>
                </div>
                <div className="d-flex flex-row align-items-center mt-3">
                    <img alt="Game" width={'25px'} src={gameIcon}></img>
                    <Body2 className="ml-2" weight={'medium'}>
                        {t(game)}
                    </Body2>
                </div>
                <div className="mt-2 ml-2" style={{ position: 'relative' }}>
                    <div
                        className="preview-phone-wrapper d-flex flex-column"
                        id="preview-phone-wrapper-id"
                        style={{ position: 'absolute', overflow: 'hidden', flex: 1 }}
                    >
                        {children}
                    </div>
                    <img alt="Phone mockup" style={{ position: 'absolute' }} src={PhoneMockup} height={500} width={225}></img>
                </div>
            </div>
        </div>
    );
};

PreviewContent.propTypes = {
    children: PropTypes.any,
    game: PropTypes.any,
};

export default PreviewContent;
