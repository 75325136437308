import React from 'react';
import { Body1 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeNameSetFlag, updateRoleName } from 'redux/actions/roles.actions';

import RoleIcon from 'assets/icons/company-role.svg';
import UsersIcon from 'assets/icons/company-users-in-role.svg';
import TrashIcon from 'assets/icons/icon-delete.svg';
import { colors } from 'style/colors';

export default function RolesListContainer({ roles, currentNode, setCurrentNode, setNodeToBeDeleted }) {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    return (
        <div className="roles-list--container">
            {roles.map(role => {
                let selected = currentNode == role?.roleId;
                return (
                    <div className="roles-list--item-wrapper">
                        <div
                            className={`roles-list--item ${selected ? 'selected' : ''}`}
                            onClick={() => {
                                if (role?.roleId !== currentNode) {
                                    setCurrentNode(role.roleId);
                                }
                            }}
                        >
                            <div className="roles-list--item-title">
                                <img src={RoleIcon} height={25} width={25} alt="role" />
                                {role.nameSet === false && (
                                    <input
                                        className="structure-tree--title-input"
                                        size={role.roleName?.length}
                                        autoFocus={true}
                                        value={role.roleName || ''}
                                        placeholder={!role.nameSet ? t('enter name') : ''}
                                        onChange={event => {
                                            const name = event.target.value;
                                            dispatch(updateRoleName(role.roleId, name));
                                        }}
                                        style={{ maxWidth: '28vw', width: '28vw', overflow: 'hidden' }}
                                        onBlur={() => {
                                            if (!(role.roleName?.length > 0)) {
                                                dispatch(updateRoleName(role.roleId, 'New role'));
                                            }

                                            dispatch(removeNameSetFlag(role.roleId));
                                        }}
                                    />
                                )}
                                {(role.nameSet === true || role.nameSet === undefined) && (
                                    <Body1
                                        weight={selected && 'medium'}
                                        style={{
                                            maxWidth: '28vw',
                                            width: '28vw',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            overflowWrap: 'break-word',
                                        }}
                                    >
                                        {role.roleName}
                                    </Body1>
                                )}
                            </div>
                            <div className="roles-list--item-meta">
                                <Body1 weight="medium" color={colors.descriptionText}>
                                    {role.usersInRole}
                                </Body1>
                                <img src={UsersIcon} height={25} width={25} alt="users" />
                            </div>
                        </div>
                        <div className="roles-list--item-actions">
                            <img
                                src={TrashIcon}
                                height={20}
                                width={20}
                                alt="role"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setNodeToBeDeleted(role)}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
