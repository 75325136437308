import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { colors } from 'style/colors';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import InviteSuccessIcon from 'assets/icons/invite-success.svg';
import { reinviteUsersAction, resetReinviteUsersAction } from 'redux/actions/users.actions';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';

const modifyDevicesModalContent = {
    resendInvites: {
        icon: null,
        title: 'Resend invites to {{userCount}} users?',
        description:
            'These users already have a pending invite. Coach will send the selected users an email invite again, to remind them that they still haven’t activated their accounts.',
        button: 'Resend invites',
    },
    loading: {
        icon: null,
        title: 'Just a moment...',
        description: 'We are currently processing your request...',
    },
    success: {
        icon: InviteSuccessIcon,
        title: '{{userCount}} invites were sent again!',
        description: 'The users should check their mailboxes and follow the activation process.',
        button: 'Continue',
    },
};

export function ResendInvitesModal({ modalOpen, setModalOpen, usersList, allUsersSelected }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const reinviteUsersState = useSelector(state => state.userManagement.reinviteUsers);

    const [currentState, setCurrentState] = useState(stateIsLoading(reinviteUsersState) ? 'loading' : 'resendInvites');

    const resendInvites = () => {
        dispatch(reinviteUsersAction(usersList));
    };

    useEffect(() => {
        if (stateIsLoaded(reinviteUsersState)) {
            setCurrentState('success');
            dispatch(resetReinviteUsersAction());
        }
    }, [reinviteUsersState]);

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                if (currentState === 'resendInvites') {
                    setCurrentState('loading');
                    resendInvites();
                } else {
                    setModalOpen(false);
                }
            }}
            onClose={() => setModalOpen(false)}
            title={t(modifyDevicesModalContent[currentState].title, { userCount: usersList.length })}
            text={t(modifyDevicesModalContent[currentState].description)}
            buttons={
                modifyDevicesModalContent[currentState].button && [
                    {
                        title: t(modifyDevicesModalContent[currentState].button),
                        color: colors.white,
                        onAction: () => resendInvites(),
                    },
                ]
            }
            hasCancel={true}
            image={modifyDevicesModalContent[currentState].icon}
        ></ModalDialog>
    );
}

ResendInvitesModal.propTypes = {
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    setModalOpen: PropTypes.func,
};
