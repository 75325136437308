export const questionTypes = {
    CHECKBOXES: 'multiple_choice',
    CHECKBOXES_WITH_IMAGE: 'single_choice_with_image',
    IMAGE_CATEGORIES: 'categorize_visual',
    COMPARE_VISUAL: 'compare_visual',
    NUMERICAL: 'numerical_answer',
    ANSWER_TEXT: 'text_answer',
    POSITIONED: 'positioned',
    DEFAULT: 'default',
};

export const games = {
    RACE_CARS: 'RACE_CARS',
    TRAFFIC: 'TRAFFIC',
    SORT_IT: 'SORT-IT',
    NUMBERS: 'NUMBERS',
    RECALL: 'RECALL',
    COMPARE: 'COMPARE',
    SEQUENCE: 'SEQUENCE',
    PROGRESS: 'PROGRESS',
};

export const gameMaxInputChars = {
    'race-cars': {
        questionMaxInputChars: 80,
        questionWarningInputChars: 30,
        maxInputChars: 50,
        warningInputChars: 25,
        errorDesc: '',
    },
    traffic: {
        questionMaxInputChars: 80,
        questionWarningInputChars: 30,
        maxInputChars: 50,
        warningInputChars: 25,
        errorDesc: '',
    },
    'sort-it': {
        questionMaxInputChars: 80,
        questionWarningInputChars: 30,
        maxInputChars: 50,
        warningInputChars: 25,
        errorDesc: '',
    },
    numbers: {
        questionMaxInputChars: 80,
        questionWarningInputChars: 30,
        maxInputChars: 50,
        warningInputChars: 25,
        errorDesc: '',
    },
    recall: {
        questionMaxInputChars: 80,
        questionWarningInputChars: 30,
        maxInputChars: 50,
        warningInputChars: 25,
        errorDesc: '',
    },
    compare: {
        questionMaxInputChars: 80,
        questionWarningInputChars: 30,
        maxInputChars: 50,
        warningInputChars: 25,
        errorDesc: '',
    },
    sequence: {
        questionMaxInputChars: 80,
        questionWarningInputChars: 30,
        maxInputChars: 50,
        warningInputChars: 25,
        errorDesc: '',
    },
    pinpoint: {
        questionMaxInputChars: 80,
        questionWarningInputChars: 30,
        maxInputChars: 50,
        warningInputChars: 25,
        errorDesc: '',
    },
};

export const optionDefaults = {
    // [questionTypes.CHECKBOXES]: { text: '', isCorrect: false },
    // [questionTypes.ANSWER_TEXT]: { text: '' },
    // [questionTypes.IMAGE_CATEGORIES]: { text: '', images: [], croppedImages: [] },
    // [questionTypes.COMPARE_VISUAL]: { text: '', image: null },
    default: { text: '', isCorrect: false, images: [], croppedImages: [], image: null },
};

export const INITIAL_STATE = {
    question: '',
    options: [],
    indicativeIndex: 0,
    removedAnswers: [],
    extra: null,
    chosenCategory: null,
    dateAvailableFrom: null,
    status: null,
    chosenCategoryId: null,
    selectedTraining: null,
};
