import React from 'react';
import { Body1 } from 'style/typography/Body';

import Checkmark from 'assets/icons/checkmark.svg';

import './GenerationProgress.css';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';

export default function GenerationIndex({ title, index, status }) {
    const { t } = useTranslation();

    const checkmarkImage = <img alt="Check" src={Checkmark} height={20} width={20} />;

    const isFinished = status === 'finished';
    const isSelected = status === 'selected';
    return (
        <div className="progress-index--container">
            <Body1
                weight={isSelected ? 'bold' : 'regular'}
                color={isSelected ? colors.dark : colors.gray1}
                style={{ position: 'absolute', top: -30 }}
            >
                {t(title)}
            </Body1>
            <div className={`progress-index ${status}`}>{isFinished ? checkmarkImage : index}</div>
        </div>
    );
}
