export const actionTypes = {
    setQuestion: 'SET_QUESTION',
    setCurrentType: 'SET_CURRENT_TYPE',
    addImagesToCategory: 'ADD_IMAGES_TO_CATEGORY',
    addImageToOption: 'ADD_IMAGE_TO_OPTION',
    replaceImageInOption: 'REPLACE_IMAGE_IN_OPTION',
    removeImageFromOption: 'REMOVE_IMAGE_FROM_OPTION',
    removeSingleImageFromOption: 'REMOVE_SINGLE_IMAGE_FROM_OPTION',
    setNumericalAnswer: 'SET_NUMERICAL_ANSWER',
    updateOption: 'UPDATE_OPTION',
    insertOption: 'INSERT_OPTION',
    removeOption: 'REMOVE_OPTION',
    resetAnswers: 'RESET_ANSWERS',
    resetState: 'RESET_STATE',
    setOptions: 'SET_OPTIONS',
    resetCheckboxes: 'RESET_CHECKBOXES',
    updateState: 'UPDATE_STATE',
    setExtra: 'SET_EXTRA',
    setTrainingSet: 'SET_TRAINING_SET',
    setCategoryId: 'SET_CATEGORY_ID',
    setOptionsCriteria: 'SET_OPTIONS_CRITERIA',
    setDateAvailableFrom: 'SET_DATE_AVAILABLE_FROM',
    setStatus: 'SET_STATUS',
};
