import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'style/colors';

import { useDispatch, useSelector } from 'react-redux';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';

import { resetUpdateLocalTopicChanges, resetUpdateTopicsTreeAction } from 'redux/actions/topics.actions';
import { EmptyTrainingState } from 'containers/layout/module/pages/content/trainings/local_components/empty_training_state/EmptyTrainingState';
import { NewTrainingModal } from 'containers/layout/module/pages/content/trainings/local_components/empty_training_state/NewTrainingModal';
import { toast } from 'react-hot-toast';
import { getNodeKey } from 'services/utils/treeHelpers';
import { Circular } from 'components/features/data_table/Circular.js';
import {
    fetchSingleTrainingAction,
    getAllTrainingsAction,
    getSingleTrainingAction,
    removeSetFromLocalChangesAction,
    resetAllTrainingsAction,
    resetSingleTrainingAction,
    saveTrainingChanges,
} from 'redux/actions/trainings.actions';

import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { getRolesConnectedToSetAction, resetRoleChangesAction } from 'redux/actions/roles.actions';
import { getAllRoles } from 'redux/actions/roles.actions';
import { TrainingEditing } from './TrainingEditing';
import { SideMenuTraining } from './local_components/side_menu/SideMenuTraining';
import ConflictModal from 'components/features/modal/ConflictModal';
import { getFlatDataFromTree } from 'react-sortable-tree';
import { StatusCode } from 'redux/core/StatusCode';
import { useTranslation } from 'react-i18next';

import './Trainings.css';

export function Trainings() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const trainingsInfoState = useSelector(state => state.content.trainingsInfo);
    const singleTrainingState = useSelector(state => state.content.singleTraining);
    const updateTopicsTreeState = useSelector(state => state.content.updateTopicsTree);
    const allTopicsFromCompanyState = useSelector(state => state.content.topics.allTopicsFromCompany);
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);
    const allTrainingsState = useSelector(state => state.content.allTrainings);
    const allRolesState = useSelector(state => state.userManagement.roles);

    const [selectedTraining, setSelectedTraining] = useState(null);
    const [draggingNode, setDraggingNode] = useState(null);
    const [newTraining, setNewTraining] = useState('');
    const [selectedNode, setSelectedNode] = useState('');

    const [createNewTrainingSwitcher, setCreateNewTrainingSwitcher] = useState(false);
    const [createTrainingModalOpen, setCreateTrainingModalOpen] = useState(false);
    const [hasConflict, setHasConflict] = useState(false);
    const [resourceCurrentState, setResourceCurrentState] = useState(null);

    let currentSetId = singleTrainingState.data?.learningSetInfo?.setId ?? singleTrainingState.data?.learningSetInfo?.generatedSetId;

    let catTree = useMemo(() => {
        return getFlatDataFromTree({
            treeData: singleTrainingState.data.treeStructure,
            getNodeKey,
            ignoreCollapsed: false,
        }).map(el => (el.node ? { ...el.node, children: null } : { ...el, children: null }));
    }, [singleTrainingState.data.treeStructure]);

    useEffect(() => {
        dispatch(getAllTrainingsAction());
        dispatch(getAllRoles());

        if (location?.search) {
            const query = new URLSearchParams(location.search);

            let trainingId = query.get('trainingId');
            if (trainingId) {
                setSelectedTraining(Number(trainingId));
            }
        } else if (stateIsLoaded(singleTrainingState) && singleTrainingState.data?.learningSetInfo) {
            setSelectedTraining(currentSetId);
            history.push({
                pathname: `/content/trainings`,
                search: `?trainingId=${currentSetId}`,
            });
        }

        return () => {
            dispatch(resetSingleTrainingAction());
            dispatch(resetAllTrainingsAction());
            dispatch(resetUpdateLocalTopicChanges());
            dispatch(resetRoleChangesAction());
        };
    }, []);

    useEffect(() => {
        if (selectedTraining !== null && selectedTraining !== -1 && stateIsLoaded(allTrainingsState)) {
            let isTrainingNew = isNaN(selectedTraining);
            history.push({
                pathname: `/content/trainings`,
                search: `?trainingId=${selectedTraining}`,
            });
            if (!isTrainingNew) {
                dispatch(getSingleTrainingAction(selectedTraining));
                dispatch(getRolesConnectedToSetAction(selectedTraining));
            }
        } else {
            history.push({
                pathname: `/content/trainings`,
            });
            dispatch(resetSingleTrainingAction());
        }
    }, [selectedTraining, allTrainingsState]);

    useEffect(() => {
        if (stateIsLoaded(updateTopicsTreeState)) {
            toast.success(t('Successfully saved!'));
            dispatch(fetchSingleTrainingAction(updateTopicsTreeState.data.learningSetInfo.setId));
            dispatch(removeSetFromLocalChangesAction(updateTopicsTreeState.data.learningSetInfo.setId));
            setSelectedTraining(updateTopicsTreeState.data.learningSetInfo.setId);
            dispatch(resetUpdateTopicsTreeAction());
        } else if (stateHasFailed(updateTopicsTreeState)) {
            if (updateTopicsTreeState.responseStatus === StatusCode.CONFLICT) {
                setResourceCurrentState(updateTopicsTreeState.data);
                setHasConflict(true);
            }
            dispatch(resetUpdateTopicsTreeAction());
            toast.error(t('Changes have not been saved!'));
        }
    }, [updateTopicsTreeState]);

    if (stateIsLoading(singleTrainingState)) {
        return (
            <div style={{ height: '90vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                <Circular />
            </div>
        );
    }

    function changeTraining(setId) {
        setSelectedTraining(setId);
    }

    return (
        <div>
            <Prompt
                when={
                    ((stateIsLoaded(localTopicChangesState) && localTopicChangesState[currentSetId]) ||
                        singleTrainingState?.data?.learningSetInfo?.modified ||
                        allRolesState?.data?.rolesModifiedInTraining) ??
                    false
                }
                message={location => {
                    return location.pathname.includes('/trainings') ? true : t(`You have unsaved changes, are you sure you want to leave?`);
                }}
            />
            {hasConflict && (
                <ConflictModal
                    modalOpen={hasConflict}
                    setModalOpen={setHasConflict}
                    treeKeyName={'categoryTree'}
                    oldData={{
                        categoryTree: catTree,
                        learningSetInfo: singleTrainingState.data.learningSetInfo,
                        lastModified: singleTrainingState.data.lastModified,
                    }}
                    newData={resourceCurrentState}
                    forceSave={() => {
                        dispatch(saveTrainingChanges(true));
                    }}
                />
            )}
            <div key={selectedTraining} className={'d-flex flex-row'}>
                {stateIsLoading(allTopicsFromCompanyState) && (
                    <div
                        style={{ height: '100%', backgroundColor: colors.white }}
                        className={'col-6 d-flex flex-column align-items-center justify-content-center'}
                    >
                        <Circular />
                    </div>
                )}
                <TrainingEditing
                    setCreateNewTrainingSwitcher={setCreateNewTrainingSwitcher}
                    setCreateTrainingModalOpen={setCreateTrainingModalOpen}
                    changeTraining={changeTraining}
                    draggingNode={draggingNode}
                    setDraggingNode={setDraggingNode}
                    selectedTraining={selectedTraining}
                    setSelectedTraining={setSelectedTraining}
                    setSelectedNode={setSelectedNode}
                />
                {allTopicsFromCompanyState && (
                    <SideMenuTraining
                        selectedNode={selectedNode}
                        setSelectedNode={setSelectedNode}
                        draggingNode={draggingNode}
                        setDraggingNode={setDraggingNode}
                    />
                )}
            </div>
            {!createNewTrainingSwitcher && selectedTraining === null && stateIsLoaded(trainingsInfoState) && trainingsInfoState.data.length === 0 && (
                <EmptyTrainingState training={newTraining} setTraining={setNewTraining}></EmptyTrainingState>
            )}
            {createNewTrainingSwitcher && (
                <NewTrainingModal
                    training={newTraining}
                    modalOpen={createTrainingModalOpen}
                    setModalOpen={setCreateTrainingModalOpen}
                    setTraining={setId => changeTraining(setId)}
                />
            )}
        </div>
    );
}
