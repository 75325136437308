import ImageBlank1x1 from 'assets/icons/img-blank-1x1.png';
import ImageBlank2x1 from 'assets/icons/img-blank-2x1.png';
import ImageBlank4x3 from 'assets/icons/img-blank-4x3.png';
import { aspectRatios } from 'services/image_management/image-editor-management/local-constants';

export const aspectRatioNoImageMap = {
    [aspectRatios[0]]: ImageBlank1x1,
    [aspectRatios[1]]: ImageBlank2x1,
    [aspectRatios[2]]: ImageBlank4x3,
};
