import { palette } from 'style/theme/theme-constants';

export const buttons = {
    MuiButton: {
        root: {
            textTransform: 'none',
            '&.action-button-accept': {
                boxShadow: 'none',
                borderRadius: '2px',
                fontWeight: 'bold',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginLeft: '10px',
                marginRight: '10px',
                textTransform: 'none',
            },
            '&.action-button-decline': {
                boxShadow: 'none',
                borderRadius: '2px',
                fontWeight: 'bold',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginLeft: '10px',
                marginRight: '10px',
                background: palette.background.input,
                color: palette.description.main,
                border: `1px solid ${palette.border.separator}`,
                textTransform: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
            },
        },
        contained: {
            textTransform: 'none',
        },
    },
    MuiButtonBase: {
        root: {
            '&.action-button-accept': {
                boxShadow: 'none',
                borderRadius: '2px',
                fontWeight: 'bold',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginLeft: '10px',
                marginRight: '10px',
                textTransform: 'none',
            },
            '&.action-button-decline': {
                boxShadow: 'none',
                borderRadius: '2px',
                fontWeight: 'bold',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginLeft: '10px',
                marginRight: '10px',
                background: palette.background.input,
                color: palette.description.main,
                textTransform: 'none',
                border: `1px solid ${palette.border.separator}`,
                '&:hover': {
                    boxShadow: 'none',
                },
            },
            textTransform: 'none',
        },
    },
};
