export const locationToHelpCenterLink = {
    '/dashboard': 'dashboard-statistics-explained',
    '/content/trainings': 'creating-trainings',
    '/content/topics': 'editing-topics-in-trainings',
    '/content/questions': 'viewing-and-filtering-questions',
    '/content/questions/add/race-cars': 'creating-questions-for-racecars',
    '/content/questions/add/traffic': 'creating-questions-for-traffic',
    '/content/questions/add/sort-it': 'creating-questions-for-sortit',
    '/content/questions/add/compare': 'creating-questions-for-compare',
    '/content/questions/add/numbers': 'creating-questions-for-numbers',
    '/content/questions/add/recall': 'creating-questions-for-recall',
    '/content/questions/add/sequence': 'creating-questions-for-sequence',
    '/content/questions/add/pinpoint': 'creating-questions-for-pinpoint',
    '/content/questions/update/race-cars': 'creating-questions-for-racecars',
    '/content/questions/update/traffic': 'creating-questions-for-traffic',
    '/content/questions/update/sort-it': 'creating-questions-for-sortit',
    '/content/questions/update/compare': 'creating-questions-for-compare',
    '/content/questions/update/numbers': 'creating-questions-for-numbers',
    '/content/questions/update/recall': 'creating-questions-for-recall',
    '/content/questions/update/sequence': 'creating-questions-for-sequence',
    '/content/questions/update/pinpoint': 'creating-questions-for-pinpoint',
    '/content/gallery': 'image-upload',
    '/internal/notifications': 'notifications-editing',
    '/internal/notifications/create': 'creating-notifications',
    '/users/organization': 'user-tables',
    '/user': 'user-statistics-and-analytics',
    '/internal/content-feedback': 'viewing-users-feedback',
    '/internal/positive-feedback': 'viewing-users-feedback',
    '/internal/feedback/#/view': '/editing-and-resolving-comments',
    '/company/roles': 'editing-roles',
    '/company/tags': 'creating-labels-and-tags',
    '/company/info': 'company-level-settings',
    '/ai_training': 'generating-content-from-word-files',
};
