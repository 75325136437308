import React from 'react';
import ContentBlocksTable from '../content_blocks_table/ContentBlocksTable';

export default function ContentBlock() {
    return (
        <>
            <ContentBlocksTable />
        </>
    );
}
