import {
    ALL_TRAININGS,
    COMPANY_UNIT_STRUCTURE,
    GET_ALL_TOPICS_FROM_COMPANY,
    GET_COMPANY_INFO,
    GET_DASHBOARD_STATISTICS,
    GET_FEEDBACK,
    GET_FOLDER_STRUCTURE,
    GET_GAMES,
    GET_GAME_QUSTION_TYPE_VARIATIONS,
    GET_NEWS,
    TRAININGS_INFO,
} from 'redux/constants/content.constants';
import { GET_ALL_AUTH_ROLES, GET_ALL_COMPANY_ROLES, GET_COMPANY, GET_USERS } from 'redux/constants/users.constants';
import {
    companyUnitStructureAction,
    dashboardStatisticsAction,
    getCompanyInfoAction,
    getGameQuestionTypeVariationsAction,
    getGamesAction,
    getNewsAction,
} from 'redux/actions/content.actions';
import { getFeedbackContentPaginatedAction } from 'redux/actions/feedback.actions';
import { getAllAuthRolesAction, getCompanyAction } from 'redux/actions/users.actions';
import { getAllRoles } from '../actions/roles.actions';
import { getAllTrainingsAction, getTrainingsInfoAction } from 'redux/actions/trainings.actions';
import { getAllTopicsFromCompanyAction } from 'redux/actions/topics.actions';
import { getFolderStructure } from 'redux/actions/image.actions';

export const startAppCollection = [
    GET_GAMES,
    GET_FOLDER_STRUCTURE,
    GET_COMPANY_INFO,
    GET_ALL_AUTH_ROLES,
    GET_ALL_COMPANY_ROLES,
    GET_COMPANY,
    GET_GAME_QUSTION_TYPE_VARIATIONS,
];

export const companyCollection = [COMPANY_UNIT_STRUCTURE];

export const updateQuestionCollection = [GET_ALL_TOPICS_FROM_COMPANY, GET_FOLDER_STRUCTURE];

export const addQuestionCollection = [GET_FOLDER_STRUCTURE, GET_ALL_TOPICS_FROM_COMPANY];

export const contentCollection = [GET_GAMES];

export const dashboardCollection = [GET_DASHBOARD_STATISTICS];

export const feedbackCollection = [GET_FEEDBACK];

export const rolesCollection = [GET_ALL_AUTH_ROLES, GET_ALL_COMPANY_ROLES];

export const actionsMapper = {
    [GET_GAMES.entity]: getGamesAction,
    [GET_FOLDER_STRUCTURE.entity]: getFolderStructure,
    [TRAININGS_INFO.entity]: getTrainingsInfoAction,
    [GET_COMPANY_INFO.entity]: getCompanyInfoAction,
    [GET_NEWS.entity]: getNewsAction,
    [GET_ALL_AUTH_ROLES.entity]: getAllAuthRolesAction,
    [GET_ALL_COMPANY_ROLES.entity]: getAllRoles,
    [GET_ALL_TOPICS_FROM_COMPANY.entity]: getAllTopicsFromCompanyAction,
    [GET_COMPANY.entity]: getCompanyAction,
    [ALL_TRAININGS.entity]: getAllTrainingsAction,
    [GET_FEEDBACK.entity]: getFeedbackContentPaginatedAction,
    [COMPANY_UNIT_STRUCTURE.entity]: companyUnitStructureAction,
    [GET_GAME_QUSTION_TYPE_VARIATIONS.entity]: getGameQuestionTypeVariationsAction,
    [GET_DASHBOARD_STATISTICS.entity]: dashboardStatisticsAction,
};
