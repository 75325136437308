import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { Subheader } from 'components/features/data_table/subheader/Subheader';
import { ActionMenu } from 'components/features/data_table/table_helpers/ActionMenu';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNewsAction, getNewsPaginatedAction, resetDeleteNewsAction, resetGetNewsPaginatedAction } from 'redux/actions/content.actions';
import { Circular } from 'components/features/data_table/Circular';
import { toast } from 'react-hot-toast';
import CaretTable from 'assets/icons/caret-table.svg';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { customStylesTable } from 'style/elements/table';
import { TableCellContent, TableHeader } from 'components/features/data_table/TableElements';
import PaginationComponent from 'components/features/data_table/pagination_component/PaginationComponent';
import { paramsToObject, setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { linkBuilderFromQuery } from 'services/api/api_builders';
import { newsFiltersInitialState } from 'static/filters';
import { newsTableSubheaders } from './table_components/NewsTableSubheader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DeleteNewsModal from './local_components/DeleteNewsModal';
import NewsEmptyState from './local_components/NewsEmptyState';
import { formatDateOnly, truncateString } from 'services/utils/stringHelpers';
import { useTranslation } from 'react-i18next';
import TableEmptyState from 'components/features/data_table/table_helpers/TableEmptyState';
import { useFilters } from 'components/features/data_table/pagination_component/useFilters';

import NewsTableIcon from 'assets/icons/icon-news-table.svg';

import './News.css';
import { LoadingAnimation } from 'components/features/lottie/LoadingAnimation';

export function News() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    const newsState = useSelector(state => state.content.news);
    const newsPaginatedState = useSelector(state => state.content.newsPaginated);
    const deleteNewsState = useSelector(state => state.content.deleteNews);

    const [dataLoading, setDataLoading] = useState(true);
    const [deleteNewsItem, setDeleteNewsItem] = useState(null);

    const isInitialLoad = useRef(true);

    const { filtersState, activeFilters, dispatchFilters, updateFilterState } = useFilters({ initialFiltersState: newsFiltersInitialState });

    const filters = {
        searchQuery: filtersState.searchQuery,
        validFrom: filtersState.validFrom ? Date.parse(filtersState.validFrom) : null,
        validUntil: filtersState.validUntil ? Date.parse(filtersState.validUntil) : null,
        status: filtersState.status,
        size: filtersState.size,
        page: filtersState.page,
        orderBy: filtersState.sortParam?.selector,
        ascending: filtersState.sortParam?.ascending,
    };

    const getNewPage = page => {
        dispatch(resetGetNewsPaginatedAction());
        dispatch(getNewsPaginatedAction({ ...filters, page: page }));
        dispatchFilters({ page: page });
    };

    const getNextNewsPage = () => {
        if (!newsPaginatedState?.data) {
            return;
        }
        if (filtersState.page < newsPaginatedState.data.totalPages - 1) {
            dispatch(resetGetNewsPaginatedAction());
            dispatch(getNewsPaginatedAction({ ...filters, page: filtersState.page + 1 }));
            dispatchFilters({ page: filtersState.page + 1 });
        }
    };

    const getPrevNewsPage = () => {
        if (!newsPaginatedState?.data) {
            return;
        }
        if (filtersState.page > 0) {
            dispatch(resetGetNewsPaginatedAction());
            dispatch(getNewsPaginatedAction({ ...filters, page: filtersState.page - 1 }));
            dispatchFilters({ page: filtersState.page - 1 });
        }
    };

    const handleSort = async (column, sortDirection) => {
        dispatchFilters({ sortParam: { selector: column.selector, ascending: sortDirection == 'asc' ? 'true' : 'false' } });
    };

    function deleteNews() {
        dispatch(deleteNewsAction(deleteNewsItem));
    }

    function goToNews(newsId) {
        history.push({
            pathname: `/internal/news/edit/${newsId}`,
        });
    }

    function hasFilters() {
        return filters.searchQuery || filters.validFrom || filters.validUntil || filters.status;
    }

    function addNewPost() {
        history.push('/internal/news/create');
    }

    useEffect(() => {
        if (stateIsLoaded(deleteNewsState)) {
            toast.success(t('Successfully deleted!'));
            dispatch(resetDeleteNewsAction());
            dispatch(getNewsPaginatedAction({ ...filters, page: filtersState.page }));
            setDeleteNewsItem(null);
        } else if (stateHasFailed(deleteNewsState)) {
            toast.error(t('Changes have not been saved!'));
            setDeleteNewsItem(null);
        }
    }, [deleteNewsState]);

    useEffect(() => {
        let stateFilters;
        if (location?.state?.filters) {
            stateFilters = location?.state?.filters;
        }
        if (location?.search) {
            const query = new URLSearchParams(location.search);
            stateFilters = paramsToObject(query.entries());
        }
        if (stateFilters) {
            dispatchFilters({
                searchQuery: stateFilters.searchQuery,
                validFrom: stateFilters.validFrom ? new Date(+stateFilters.validFrom) : null,
                validUntil: stateFilters.validUntil ? new Date(+stateFilters.validUntil) : null,
                status: stateFilters.status,
                page: Number(stateFilters.page ?? 0),
                size: Number(stateFilters.size ?? 10),
                sortParam:
                    stateFilters.orderBy && stateFilters.ascending ? { selector: stateFilters.orderBy, ascending: stateFilters.ascending } : null,
            });
        }
    }, []);

    useEffect(() => {
        if (stateIsLoaded(newsPaginatedState)) {
            setDataLoading(false);
        }
    }, [newsPaginatedState]);

    useEffect(() => {
        setDataLoading(true);
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(
                    getNewsPaginatedAction({
                        ...filters,
                        page: filtersState.page,
                    })
                );
                let search = linkBuilderFromQuery('', filters);
                history.push({
                    pathname: `/internal/news`,
                    search: search ? search : '',
                });
                isInitialLoad.current = false;
            },
            500,
            'getNewsPaginatedActionUseEffect'
        );
        return () => setTimeoutLastExecute.removeCallback('getNewsPaginatedActionUseEffect');
    }, [filtersState]);

    const actionButtons = row => [
        {
            name: t('Edit'),
            onClick: () => {
                goToNews(row.newsId);
            },
            visible: true,
        },
        {
            name: t('Delete'),
            onClick: () => {
                setDeleteNewsItem(row.newsId);
            },
            visible: true,
        },
    ];

    const columns = useMemo(
        () => [
            {
                name: <TableHeader text={t('Title')} />,
                selector: 'title',
                sortable: 'true',
                wrap: true,
                width: '20vw',

                cell: row => (
                    <TableCellContent
                        onClick={() => {
                            goToNews(row.newsId, 30);
                        }}
                        elementBefore={<img src={NewsTableIcon} height={20} width={20} alt="news" style={{ marginRight: 5 }} />}
                        text={truncateString(row.title, 30)}
                        isLoading={dataLoading}
                    />
                ),
            },
            {
                name: <TableHeader text={t('Story')} />,
                selector: 'story',
                sortable: 'true',
                wrap: true,
                width: '20vw',

                cell: row => (
                    <TableCellContent
                        onClick={() => {
                            goToNews(row.newsId);
                        }}
                        text={truncateString(row.story, 30)}
                        isLoading={dataLoading}
                    />
                ),
            },
            {
                name: <TableHeader text={t('No. of slides')} />,
                selector: 'no.slides',
                sortable: false,
                wrap: true,
                width: '8vw',

                cell: row => (
                    <TableCellContent
                        onClick={() => {
                            goToNews(row.newsId);
                        }}
                        text={row.articlesCount}
                        isLoading={dataLoading}
                    />
                ),
            },
            {
                name: <TableHeader text={t('Valid for time period')} />,
                selector: 'validFrom',
                sortable: false,
                width: '15vw',
                cell: row => (
                    <TableCellContent
                        onClick={() => {
                            goToNews(row.newsId);
                        }}
                        text={formatDateOnly(row.validFrom) + ' - ' + formatDateOnly(row.validUntil)}
                        isLoading={dataLoading}
                    />
                ),
            },

            {
                name: <TableHeader text={t('Status')} />,
                selector: 'status',
                sortable: false,
                width: '10vw',
                cell: row => (
                    <TableCellContent
                        onClick={() => {
                            goToNews(row.newsId);
                        }}
                        text={t(row.status)}
                        isLoading={dataLoading}
                    />
                ),
            },
            {
                name: <TableHeader text={t('Actions')} />,
                allowOverflow: true,
                button: true,
                cell: row => <ActionMenu row={row} buttons={actionButtons(row)} />,
            },
        ],
        [dataLoading]
    );

    if (dataLoading && !hasFilters() && isInitialLoad.current) {
        return <LoadingAnimation />;
    }

    if ((!newsPaginatedState.data?.content || newsPaginatedState.data?.content.length === 0) && !hasFilters() && !dataLoading) {
        return <NewsEmptyState createNews={addNewPost} />;
    }

    return (
        <div className="news--data-table">
            {deleteNewsItem && (
                <DeleteNewsModal
                    deleteModalOpen={deleteNewsItem}
                    setDeleteModalOpen={_ => setDeleteNewsItem(null)}
                    onAction={deleteNews}
                    onClose={() => setDeleteNewsItem(null)}
                />
            )}
            <DataTable
                sortIcon={<img alt="sort" height={15} width={13} className="ml-2" src={CaretTable}></img>}
                selectableRowsComponent={React.forwardRef((props, ref) => (
                    <div ref={ref} style={{ height: '100%', width: '100%' }}>
                        <Checkbox onClick={props.onClick} checked={props.checked}></Checkbox>
                    </div>
                ))}
                columns={columns}
                data={newsPaginatedState.data?.content}
                onSort={handleSort}
                sortServer
                defaultSortField="user"
                progressComponent={<Circular />}
                progressPending={stateIsLoading(newsState)}
                subHeader={true}
                onRowClicked={row => {
                    goToNews(row.newsId);
                }}
                noDataComponent={<TableEmptyState />}
                pagination={true}
                selectableRows={true}
                highlightOnHover={true}
                subHeaderComponent={
                    <Subheader inputs={newsTableSubheaders({ filtersState, activeFilters, dispatchFilters, updateFilterState, addNewPost })} />
                }
                subHeaderAlign={'left'}
                noHeader={true}
                customStyles={customStylesTable}
                theme={'tableTheme'}
                paginationComponent={_ => (
                    <PaginationComponent
                        getPrev={getPrevNewsPage}
                        getNext={getNextNewsPage}
                        getPage={getNewPage}
                        currentPage={filters.page}
                        totalPages={newsPaginatedState.data?.totalPages}
                        totalItems={newsPaginatedState.data?.totalElements}
                        size={filters.size}
                        setSize={size => {
                            dispatchFilters({ page: 0, size: size });
                        }}
                    />
                )}
            />
        </div>
    );
}
