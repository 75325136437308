export const views = ['uploaded_images', 'edit_image', 'image_library', 'saving_image'];
export const aspectRatios = ['1/1', '2/1', '4/3'];
export const aspectRatiosOptions = [
    { label: '1 : 1', value: '1/1' },
    { label: '2 : 1', value: '2/1' },
    { label: '4 : 3', value: '4/3' },
];

export const sortOptions = [
    { label: 'Name', value: 'url_original' },
    { label: 'Date Uploaded', value: 'date_created' },
];

export const tabs = {
    all_images: 'All images',
    uncategorized: 'Uncategorized',
    folder: 'Folder',
};

export const INITIAL_STATE = {
    imageFiles: {
        files: [],
        filesBlob: [],
    },
    size: 20,
    currentTab: tabs.all_images,
    currentActiveIndex: 0,
    view: views[0],
    currentActivePreviewImage: false,
    aspectRatio: aspectRatios[0],
    index: 0,
    treeData: [],
    currentPage: 0,
    paginationTotalPages: false,
    selectedImages: [],
    imageSearchValue: '',
    isGrid: true,
    sortBy: 'date_created',
    allImagesSelected: false,
};

export const aspectRatioDictionary = {
    '1/1': 1 / 1,
    '2/1': 2 / 1,
    '4/3': 4.5 / 3,
};
