import React, { useState } from 'react';
import EmptyStateRoles from 'assets/icons/emptystate-roles.svg';
import { colors } from 'style/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NewRoleModal } from './NewRoleModal';
import { H6 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';

export function EmptyState({ newRole, setNewRole, createRole }) {
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div style={{ height: '100%' }} className={'d-flex flex-row align-items-center justify-content-center'}>
            <div className="d-flex flex-column align-items-center">
                <img style={{ width: 80, height: 80 }} src={EmptyStateRoles} alt="" />

                <H6 color={colors.secoundaryText} className={'mt-4'}>
                    {t('Add first company role')}
                </H6>
                <Body2 className={'mb-0 mt-1'} color={colors.gray6}>
                    {t('Start building your company roles by adding your first one')}
                </Body2>
                <button
                    onClick={() => setModalOpen(true)}
                    type={'button'}
                    className="btn btn-dark d-flex flex-row align-items-center mt-4"
                    style={{ color: colors.mainTheme, width: 120 }}
                >
                    <FontAwesomeIcon icon={faPlus} className={'mr-auto'}></FontAwesomeIcon>
                    {t('Add role')}
                </button>
            </div>
            <NewRoleModal role={newRole} setRole={setNewRole} modalOpen={modalOpen} setModalOpen={setModalOpen} createRole={createRole} />
        </div>
    );
}
