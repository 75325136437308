import React, { useReducer, useEffect, useMemo, useContext } from 'react';
import { validationStateReducer } from './local-reducer';
import { actionTypes } from './local-actions';
import { validationMessages, validatorCategories } from './local-constants';
import { useContentInsertionQuestion, useInsertionState } from 'services/content-management/insertion-control';
import { questionTypes } from 'services/content-management/insertion-control/local-constants';
import { useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const initialState = Object.values(validatorCategories).reduce(
    (acc, item) => ({
        ...acc,
        [item]: {
            isVisible: false,
            index: null,
            message: '',
        },
    }),
    {}
);

let a = {
    previewGamesQuestionNotEmpty: {
        isVisible: false,
        index: null,
        message: '',
    },
};

const ValidationContext = React.createContext();

export const ValidationContextProvider = ({ children, data }) => {
    const [state, dispatch] = useReducer(validationStateReducer, initialState);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return <ValidationContext.Provider value={contextValue}>{children}</ValidationContext.Provider>;
};

const useState = () => {
    const { state } = useContext(ValidationContext);
    return state;
};

const useDispatch = () => {
    const { dispatch } = useContext(ValidationContext);
    return dispatch;
};

export const useValidatorCategory = type => {
    const state = useState();
    return state[type];
};

export const useValidatorCategoryActions = () => {
    const dispatch = useDispatch();
    const state = useState();
    return {
        show: (category, message, index) => dispatch({ type: actionTypes.show, payload: { category, message, index } }),
        hide: category => {
            if (state[category].isVisible) dispatch({ type: actionTypes.hide, payload: { category } });
        },
    };
};

export const useValidator = () => {
    const { question } = useContentInsertionQuestion();
    const currentInsertionState = useInsertionState();
    const validationActions = useValidatorCategoryActions();
    const gameQuestionTypeVariations = useSelector(state => state.content.gameQuestionTypeVariations);
    const singleQuestion = useSelector(state => state.content.singleQuestion);

    let { t } = useTranslation();
    let { game } = useParams();

    game = game ?? singleQuestion.data.gameSlug;

    return {
        [validatorCategories.CHOSEN_CATEGORY]: {
            validate: () => {
                let validation = currentInsertionState.chosenCategoryId == null;
                if (validation) {
                    validationActions.show(validatorCategories.CHOSEN_CATEGORY, validationMessages[validatorCategories.CHOSEN_CATEGORY]);
                }
                return validation;
            },
        },
        [validatorCategories.PREVIEW_GAMES_QUESTION_NOT_EMPTY]: {
            validate: () => {
                let validation = String(question)?.length < 1;
                if (validation) {
                    validationActions.show(
                        validatorCategories.PREVIEW_GAMES_QUESTION_NOT_EMPTY,
                        validationMessages[validatorCategories.PREVIEW_GAMES_QUESTION_NOT_EMPTY]
                    );
                }
                return validation;
            },
        },
        [validatorCategories.NO_DUPLICATE_IMAGES]: {
            validate: () => {
                let allImagesUsed = [];
                currentInsertionState.options.forEach(option => option.images?.forEach(image => allImagesUsed.push(image.id)));

                let validation = allImagesUsed.length !== new Set(allImagesUsed).size;

                if (validation) {
                    validationActions.show(validatorCategories.NO_DUPLICATE_IMAGES, validationMessages[validatorCategories.NO_DUPLICATE_IMAGES]);
                }
                return validation;
            },
        },
        [validatorCategories.NO_DUPLICATE_ANSWERS]: {
            validate: () => {
                // check game
                if (currentInsertionState.currentType === questionTypes.COMPARE_VISUAL) {
                    return false;
                }
                let allAnswers = [];
                currentInsertionState.options.forEach(option => allAnswers.push(option.text));

                let validation = allAnswers.length !== new Set(allAnswers).size;

                if (validation) {
                    validationActions.show(validatorCategories.NO_DUPLICATE_ANSWERS, validationMessages[validatorCategories.NO_DUPLICATE_ANSWERS]);
                }
                return validation;
            },
        },
        [validatorCategories.NO_DUPLICATE_CATEGORIES]: {
            validate: () => {
                if (currentInsertionState.currentType === questionTypes.IMAGE_CATEGORIES) {
                    let allAnswers = [];
                    currentInsertionState.options.forEach(option => allAnswers.push(option.text));

                    let validation = allAnswers.length !== new Set(allAnswers).size;

                    if (validation) {
                        validationActions.show(
                            validatorCategories.NO_DUPLICATE_CATEGORIES,
                            validationMessages[validatorCategories.NO_DUPLICATE_CATEGORIES]
                        );
                    }
                    return validation;
                }
                return false;
            },
        },
        [validatorCategories.IMAGE_WITHOUT_VALUE]: {
            validate: () => {
                if (currentInsertionState.currentType === questionTypes.COMPARE_VISUAL) {
                    let index = currentInsertionState.options.findIndex(
                        option => (!option.text && option.images.length > 0) || (option.text && option.images.length === 0)
                    );
                    let bool = index !== undefined && index !== false && index !== null && index >= 0;
                    if (bool) {
                        validationActions.show(
                            validatorCategories.IMAGE_WITHOUT_VALUE,
                            validationMessages[validatorCategories.IMAGE_WITHOUT_VALUE],
                            index
                        );
                    }

                    return bool;
                } else if (currentInsertionState.currentType === questionTypes.IMAGE_CATEGORIES) {
                    let index = currentInsertionState.options.findIndex(
                        option => (!option.text && option.images.length > 0) || (option.text && option.images.length === 0)
                    );
                    let flag = index !== undefined && index !== false && index !== null && index >= 0;
                    if (flag) {
                        validationActions.show(
                            validatorCategories.IMAGE_WITHOUT_VALUE,
                            validationMessages[validatorCategories.IMAGE_WITHOUT_VALUE],
                            index
                        );
                    }
                    return flag;
                }
                return false;
            },
        },
        [validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY]: {
            validate: () => {
                if (currentInsertionState.currentType !== questionTypes.NUMERICAL) {
                    let index = currentInsertionState.options.findIndex(option => !option.text);
                    let flag = false;
                    if (index !== undefined && index !== false && index !== null && index >= 0) {
                        validationActions.show(
                            validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY,
                            validationMessages[validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY],
                            index
                        );
                        flag = true;
                    }

                    return flag;
                }
            },
        },
        [validatorCategories.SINGLE_ANSWER_NOT_EMPTY]: {
            validate: () => {
                if (currentInsertionState.currentType === questionTypes.NUMERICAL) {
                    let flag = !currentInsertionState.options[0].text;
                    if (flag) {
                        validationActions.show(
                            validatorCategories.SINGLE_ANSWER_NOT_EMPTY,
                            validationMessages[validatorCategories.SINGLE_ANSWER_NOT_EMPTY]
                        );
                    }

                    return flag;
                }
            },
        },
        [validatorCategories.CORRECT_ANSWER_REQUIRED]: {
            validate: () => {
                if (
                    currentInsertionState.currentType === questionTypes.CHECKBOXES ||
                    currentInsertionState.currentType === questionTypes.CHECKBOXES_WITH_IMAGE
                ) {
                    const item = currentInsertionState.options.find(option => option.isCorrect);
                    if (!item) {
                        validationActions.show(
                            validatorCategories.CORRECT_ANSWER_REQUIRED,
                            validationMessages[validatorCategories.CORRECT_ANSWER_REQUIRED]
                        );
                        return true;
                    }
                }
                return false;
            },
        },
        [validatorCategories.INCORRECT_ANSWER_REQUIRED]: {
            validate: () => {
                if (
                    currentInsertionState.currentType === questionTypes.CHECKBOXES ||
                    currentInsertionState.currentType === questionTypes.CHECKBOXES_WITH_IMAGE
                ) {
                    const item = currentInsertionState.options.find(option => !option.isCorrect);
                    if (!item) {
                        validationActions.show(
                            validatorCategories.INCORRECT_ANSWER_REQUIRED,
                            validationMessages[validatorCategories.INCORRECT_ANSWER_REQUIRED]
                        );
                        return true;
                    }
                }
                return false;
            },
        },
        [validatorCategories.COMPARING_CRITERIA_REQUIRED]: {
            validate: () => {
                if (currentInsertionState.currentType === questionTypes.COMPARE_VISUAL) {
                    if (!currentInsertionState.extra || !currentInsertionState.extra.criteria) {
                        validationActions.show(
                            validatorCategories.COMPARING_CRITERIA_REQUIRED,
                            validationMessages[validatorCategories.COMPARING_CRITERIA_REQUIRED]
                        );
                        return true;
                    }
                }
                return false;
            },
        },
        [validatorCategories.IMAGE_REQUIRED]: {
            validate: () => {
                if (currentInsertionState.currentType === questionTypes.CHECKBOXES_WITH_IMAGE) {
                    if (!currentInsertionState.extra || !currentInsertionState.extra.image) {
                        validationActions.show(validatorCategories.IMAGE_REQUIRED, validationMessages[validatorCategories.IMAGE_REQUIRED]);
                        return true;
                    }
                }
                return false;
            },
        },

        [validatorCategories.MIN_OPTIONS]: {
            validate: () => {
                if (stateIsLoaded(gameQuestionTypeVariations)) {
                    if (currentInsertionState.currentType === questionTypes.IMAGE_CATEGORIES) {
                        let flag =
                            currentInsertionState.options.flatMap(options => options.images).length <
                            gameQuestionTypeVariations?.data[game]?.minOptions;

                        if (flag) {
                            validationActions.show(
                                validatorCategories.MIN_OPTIONS,
                                validationMessages[validatorCategories.MIN_OPTIONS](gameQuestionTypeVariations?.data[game]?.minOptions, t)
                            );
                        }

                        return flag;
                    }
                    let flag = currentInsertionState.options.length < gameQuestionTypeVariations?.data[game]?.minOptions;

                    if (flag) {
                        validationActions.show(
                            validatorCategories.MIN_OPTIONS,
                            validationMessages[validatorCategories.MIN_OPTIONS](gameQuestionTypeVariations?.data[game]?.minOptions, t)
                        );
                    }
                    return flag;
                }
                return false;
            },
        },
        [validatorCategories.MAX_OPTIONS]: {
            validate: () => {
                if (stateIsLoaded(gameQuestionTypeVariations)) {
                    if (gameQuestionTypeVariations?.data[game]?.maxOptions < 0) return false;
                    let flag = currentInsertionState.options.length > gameQuestionTypeVariations?.data[game]?.maxOptions;
                    if (flag) {
                        validationActions.show(
                            validatorCategories.MAX_OPTIONS,
                            validationMessages[validatorCategories.MAX_OPTIONS](gameQuestionTypeVariations?.data[game]?.maxOptions, t)
                        );
                    }
                    return flag;
                }
                return false;
            },
        },
        [validatorCategories.MIN_CORRECT_OPTIONS]: {
            validate: () => {
                if (stateIsLoaded(gameQuestionTypeVariations)) {
                    if (!gameQuestionTypeVariations?.data[game]?.minCorrectOptions) return false;
                    let correctOptions = currentInsertionState.options.filter(el => el.isCorrect);
                    let flag = correctOptions.length < gameQuestionTypeVariations?.data[game]?.minCorrectOptions;
                    if (flag) {
                        validationActions.show(
                            validatorCategories.MIN_CORRECT_OPTIONS,
                            validationMessages[validatorCategories.MIN_CORRECT_OPTIONS](gameQuestionTypeVariations?.data[game]?.minCorrectOptions, t)
                        );
                    }
                    return flag;
                }
                return false;
            },
        },
        [validatorCategories.MIN_WRONG_OPTIONS]: {
            validate: () => {
                if (stateIsLoaded(gameQuestionTypeVariations)) {
                    if (!gameQuestionTypeVariations?.data[game]?.minWrongOptions) return false;
                    let wrongOptions = currentInsertionState.options.filter(el => !el.isCorrect);
                    let flag = wrongOptions.length < gameQuestionTypeVariations?.data[game]?.minWrongOptions;
                    if (flag) {
                        validationActions.show(
                            validatorCategories.MIN_WRONG_OPTIONS,
                            validationMessages[validatorCategories.MIN_WRONG_OPTIONS](gameQuestionTypeVariations?.data[game]?.minWrongOptions, t)
                        );
                    }
                    return flag;
                }
                return false;
            },
        },
    };
};
