import { ActionStatus } from '../core/ActionStatus';
import { buildActionType } from '../actions/buildActionType';
import { LOGOUT, NOT_AUTHORIZED, LOGIN, STORAGE_PERSIST } from '../constants/auth.constants';
import { StateStatus } from '../core/StateStatus';

const auth = (
    state = {
        data: null,
        status: StateStatus.NOT_INITIALIZED,
        error: null,
        persisted: false,
        loggedOut: false,
    },
    action
) => {
    switch (action.type) {
        case buildActionType(LOGIN, ActionStatus.START):
            return { status: StateStatus.LOADING, data: null, error: null, persisted: false };

        case buildActionType(LOGIN, ActionStatus.DONE):
            return {
                status: StateStatus.LOADED,
                data: { ...action.payload },
                persisted: false,
                error: null,
                version: +new Date(),
            };

        case buildActionType(STORAGE_PERSIST, ActionStatus.DONE):
            return { ...state, persisted: true, version: +new Date() };

        case buildActionType(LOGIN, ActionStatus.FAILED):
            return { ...state, persisted: false, status: StateStatus.ERROR, error: action.payload };
        case buildActionType(LOGIN, ActionStatus.RESET):
            return state;
        case buildActionType(LOGOUT, ActionStatus.DONE):
            state = null;
            return { status: StateStatus.NOT_INITIALIZED, data: null, error: null, persisted: false, loggedOut: true };
        case buildActionType(NOT_AUTHORIZED, ActionStatus.HANDLE):
            state = null;
            return { status: StateStatus.NOT_INITIALIZED, data: null, error: NOT_AUTHORIZED, persisted: false };
        case buildActionType(LOGIN, ActionStatus.REFRESH):
            return {
                ...state,
                data: { ...state, ...action.payload },
                persisted: false,
                status: StateStatus.REFRESHING,
            };
        default:
            return state;
    }
};

export { auth };
