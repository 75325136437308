import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { ContextDevTool } from 'react-context-devtool';
import { actionTypes } from './local-actions';
import { INITIAL_STATE } from './local-constants';
import { contentBlocksReducer } from './local-reducer';

const InsertionTemporaryContext = createContext();

export const ContentBlocksBuilderProvider = ({ children }) => {
    const [state, dispatch] = useReducer(contentBlocksReducer, INITIAL_STATE);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <InsertionTemporaryContext.Provider value={contextValue}>
            <>
                <ContextDevTool context={InsertionTemporaryContext} id="contentBlockBuilderContextId" displayName="Content blocks builder context" />
                {children}
            </>
        </InsertionTemporaryContext.Provider>
    );
};

const useState = () => {
    const { state } = useContext(InsertionTemporaryContext);
    return state;
};

const useDispatch = () => {
    const { dispatch } = useContext(InsertionTemporaryContext);
    return dispatch;
};

export const ContentBlocksBuilderConsumer = () => {
    return (
        <InsertionTemporaryContext.Consumer>
            {value => {
                return null;
            }}
        </InsertionTemporaryContext.Consumer>
    );
};

export const useContentBlocksBuilder = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        contentBlock: state.contentBlock,
        attributes: state.attributes,
        imageAttribute: state.imageAttribute,
        questionAttribute: state.questionAttribute,
        answerAttribute: state.answerAttribute,
        correctValuesMap: state.correctValuesMap,
        autoGenerateValue: state.autogenerateValue,
        comparatorCriteria: state.comparatorCriteria,
        comparatorCorrect: state.comparatorCorrect,
        isTemplate: state.isTemplate,
        setQuestionAttribute: questionAttribute => {
            dispatch({ type: actionTypes.setQuestionAttribute, payload: questionAttribute });
        },
        setAnswerAttribute: answerAttribute => {
            dispatch({ type: actionTypes.setAnswerAttribute, payload: answerAttribute });
        },
        setAttributes: attributesList => {
            dispatch({ type: actionTypes.setAttributes, payload: attributesList });
        },
        setImageAttribute: imageAttribute => {
            dispatch({ type: actionTypes.setImageAttribute, payload: imageAttribute })
        },
        setContentBlock: contentBlock => {
            dispatch({ type: actionTypes.setContentBlock, payload: contentBlock });
        },
        setQuestionAttribute: questionAttribute => {
            dispatch({ type: actionTypes.setQuestionAttribute, payload: questionAttribute });
        },
        setCorrectValuesMap: correctValuesMap => {
            dispatch({ type: actionTypes.setCorrectValuesMap, payload: correctValuesMap });
        },
        setAutogenerateValue: autogenerateValue => {
            dispatch({ type: actionTypes.setAutogenerateValue, payload: autogenerateValue });
        },
        setComparatorCriteria: comparatorCriteria => {
            dispatch({ type: actionTypes.setComparatorCriteria, payload: comparatorCriteria });
        },
        setComparatorCorrect: comparatorCorrect => {
            dispatch({ type: actionTypes.setComparatorCorrect, payload: comparatorCorrect });
        },
        setIsTemplate: isTemplate => {
            dispatch({ type: actionTypes.setIsTemplate, payload: isTemplate });
        },
        resetState: () => {
            dispatch({ type: actionTypes.resetState });
        },
        resetValues: () => {
            dispatch({ type: actionTypes.resetValues });
        },
    };
};

export const useContentBlockBuilderState = useState;
