import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React from 'react';
import DeleteIcon from 'assets/icons/icon-role-delete.svg';
import { useTranslation } from 'react-i18next';

export function DeleteTrainingModulesDialog({ modalOpen, setModalOpen, onAction, trainingModuleName }) {
    const { t } = useTranslation();

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                setModalOpen(false);
                onAction();
            }}
            title={t('Delete training module')}
            text={t('Are you sure you want to delete this training module: {{trainingModuleName}}?', { trainingModuleName: trainingModuleName })}
            buttons={[
                {
                    title: t('Delete'),
                    color: colors.white,
                },
            ]}
            hasCancel={true}
            image={DeleteIcon}
            imageSize={70}
        ></ModalDialog>
    );
}
DeleteTrainingModulesDialog.propTypes = {
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    setModalOpen: PropTypes.func,
    trainingModuleName: PropTypes.string,
};
