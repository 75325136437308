import React, { useState } from 'react';
import GenerateNewTraining from './local_components/GenerateNewTraining';
import { Header } from '../../local_components/header/Header';
import AIGenerateTraining from './generate_training/AIGenerateTraining';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function AITraining() {
    const [generateNew, setGenerateNew] = useState(false);

    const generatingContentProgressState = useSelector(state => state.content.generatingContentProgress);

    let isGeneratingContent = generateNew || generatingContentProgressState?.data?.currentProcess;

    const { t } = useTranslation();

    return (
        <Header title={'AI-Powered Training'}>
            {!isGeneratingContent && <GenerateNewTraining setGenerateNew={setGenerateNew} />}
            {isGeneratingContent && <AIGenerateTraining />}
        </Header>
    );
}
