import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from 'containers/layout/module/local_components/header/Header';
import { OrganizationUsers } from './organization_users/OrganizationUsers';
import UserDeviceRequests from './user_device_requests/UserDeviceRequests';
import { useSelector } from 'react-redux';

const Users = ({ history }) => {
    const { type } = useParams();
    const [activeTab, setActiveTab] = useState(type);

    const usersPaginatedState = useSelector(state => state.userManagement.usersPaginated);

    useEffect(() => {
        setActiveTab(type);
    }, [type]);

    function changeTab(tab) {
        history.replace(`/users/${tab}`);
    }

    const typesTabs = [
        {
            id: 'users-organization-users',
            type: 'organization',
            name: 'Organization users',
            stateDependencies: [usersPaginatedState],
        },
        {
            id: 'users-device-requests',
            type: 'user-device-request',
            name: 'User device requests',
        },
    ];

    return (
        <Header
            tabs={typesTabs}
            activeTab={activeTab}
            setActiveTab={changeTab}
            title={'Users'}
            stateDependencies={typesTabs.find(el => el.type === activeTab)?.stateDependencies}
        >
            {activeTab === 'organization' && <OrganizationUsers />}
            {activeTab === 'user-device-request' && <UserDeviceRequests />}
        </Header>
    );
};

export default Users;
