import { palette } from 'style/theme/theme-constants';
import themeOverrides from './overrides';
import { createTheme } from '@material-ui/core/styles';
export const theme = createTheme({
    palette,
    overrides: themeOverrides.overrides,
    typography: {
        fontFamily: 'Aktiv Grotesk',
        subtitle1: {
            fontWeight: 400,
            color: palette.description.main,
        },
    },
});
