import React, { Component, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SortableTree, { getFlatDataFromTree } from 'components/features/trees/SortableTree';
import { deleteTrainingModuleByIdAction, getTrainingModuleByIdAction, resetGetTrainingModuleByIdAction } from 'redux/actions/topics.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { convertFromFlatToTree, getNodeKey } from 'services/utils/treeHelpers';
import { DragSource } from 'react-dnd';
import { Body1 } from 'style/typography/Body';
import { DeleteTrainingModulesDialog } from './DeleteTrainingModulesDialog';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { useUndoRedo } from 'services/undo-wrapper-management';
import { addChildToTrainingTree } from 'redux/actions/trainings.actions';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';
import Thrash from 'assets/icons/icon-delete.svg';
import ModuleIcon from 'assets/icons/icon-list-module.svg';
import ErrorIcon from 'assets/icons/error-icon.svg';

import './TrainingModulesWrapper.css';

export default function TrainingModulesMenu() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const allTrainingModulesCompanyState = useSelector(state => state.content.allTrainingModulesCompany);
    const trainingModuleByIdState = useSelector(state => state.content.trainingModuleById);
    const setInfo = useSelector(state => state.content.singleTraining.data.learningSetInfo);
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);

    const data = useSelector(state => state.content.singleTraining.data.treeStructure);

    const singleTrainingState = useSelector(state => state.content.singleTraining);

    const [treeData, setTreeData] = useState([]);
    const [trainingModuleToDelete, setTrainingModuleToDelete] = useState(null);

    const [canAddToTree, setCanAddToTree] = useState(true);

    const { addToUndoStack, resetRedoStack } = useUndoRedo();

    let currentSetId = setInfo?.setId ?? setInfo?.generatedSetId;

    useEffect(() => {
        let flatData = getFlatDataFromTree({
            treeData: data,
            getNodeKey: node => (node.node ? node.node.treeIndex : node.treeIndex),
            ignoreCollapsed: false,
        });
        let indexesInTree = flatData.map(el => el.node.treeIndex);

        let treeIndexesInTrainingModule = trainingModuleByIdState?.data?.categoryAndParentIds?.map(el => el.treeIndex);
        if (treeIndexesInTrainingModule && treeIndexesInTrainingModule.length > 0 && data?.[0]) {
            const matchingNodes = treeIndexesInTrainingModule.filter(element => indexesInTree.includes(element));
            if (matchingNodes && matchingNodes.length > 0) {
                setCanAddToTree(false);
                return;
            }
        }
        setCanAddToTree(true);
    }, [data, trainingModuleByIdState, singleTrainingState]);

    useEffect(() => {
        if (stateIsLoaded(trainingModuleByIdState) && trainingModuleByIdState?.data) {
            let treeDataFromFlat = convertFromFlatToTree(trainingModuleByIdState.data.categoryAndParentIds);
            setTreeData(treeDataFromFlat);
        } else {
            setTreeData([]);
        }
    }, [trainingModuleByIdState]);

    const getTrainingModuleById = useCallback(
        trainingModuleId => {
            if (trainingModuleId == trainingModuleByIdState?.data?.trainingModuleId) {
                dispatch(resetGetTrainingModuleByIdAction());
            } else {
                dispatch(getTrainingModuleByIdAction(trainingModuleId));
            }
        },
        [trainingModuleByIdState]
    );

    function deleteTrainingModule(trainingModuleId) {
        dispatch(deleteTrainingModuleByIdAction(trainingModuleId));
    }

    const externalNodeType = 'TOPIC';

    const externalNodeSpec = {
        beginDrag: __ => ({ node: _.cloneDeep(treeData[0]), dropItem: 'module' }),
    };
    const externalNodeCollect = (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        didDrop: monitor.didDrop(),
    });

    function getSortableTree(treeData, trainingModuleId) {
        let size = getFlatDataFromTree({
            treeData: treeData,
            getNodeKey,
            ignoreCollapsed: false,
        })?.length;
        return (
            <div>
                <SortableTree
                    style={{ height: size ? size * 65 : '35vh', minHeight: size ? size * 65 : '35vh' }}
                    key={trainingModuleId}
                    onChange={() => {}}
                    treeData={treeData}
                    canDrop={false}
                    toggleChildrenVisibility={null}
                    canDrag={false}
                    generateNodeProps={({ node, path }) => {
                        return {
                            title: (
                                <div style={{ width: '250px', maxWidth: '250px' }}>
                                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{node.title}</div>
                                </div>
                            ),
                            toggleChildrenVisibility: false,
                        };
                    }}
                />
            </div>
        );
    }

    const renderItem = (connectDragPreview, node) => {
        return (
            <div className="training-module--item">
                {connectDragPreview(
                    <div className="training-module--item-title" onClick={() => getTrainingModuleById(node.trainingModuleId)}>
                        <div className="training-module--name">
                            <img src={ModuleIcon} height={20} width={20} />
                            <Body1> {node.trainingModuleName}</Body1>
                        </div>
                        <div>
                            <img
                                alt={t('Open')}
                                src={node.trainingModuleId == trainingModuleByIdState?.data?.trainingModuleId ? AngleUp : AngleDown}
                                height={15}
                                width={15}
                            />
                            <img
                                alt={t('Delete')}
                                src={Thrash}
                                height={17}
                                width={17}
                                style={{ margin: '0 15px 0 20px', cursor: 'pointer' }}
                                onClick={e => {
                                    e.stopPropagation();
                                    setTrainingModuleToDelete(node.trainingModuleId);
                                }}
                            />
                        </div>
                    </div>
                )}
                <div>
                    {treeData && treeData.length > 0 && node.trainingModuleId == trainingModuleByIdState?.data?.trainingModuleId && (
                        <div>
                            {!canAddToTree && (
                                <div className="training-module--tree">
                                    <div className="error">
                                        <img src={ErrorIcon} height={15} width={15} />
                                        <Caption1 color={colors.red}>
                                            {t("This training module can't be added to this tree because it already has some of the topics.")}
                                        </Caption1>
                                    </div>
                                    {getSortableTree(treeData, node.trainingModuleId)}
                                </div>
                            )}
                            {canAddToTree && getSortableTree(treeData, node.trainingModuleId)}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    class externalNodeBaseComponent extends Component {
        render() {
            const { connectDragSource, connectDragPreview, node, didDrop } = this.props;

            if (didDrop && node.trainingModuleId == trainingModuleByIdState?.data?.trainingModuleId) {
                let isTreeEmpty = data[0]?.children && data[0].children.length != 0;
                if (isTreeEmpty) {
                    resetRedoStack(currentSetId);
                    addToUndoStack(currentSetId, null, {
                        training: { treeStructure: _.cloneDeep(data) },
                        localTopics: _.cloneDeep(localTopicChangesState.data),
                    });

                    dispatch(addChildToTrainingTree([data[0].treeIndex ?? data[0].generatedTreeIndex], getNodeKey, '', false, { node: treeData[0] }));
                }
            }

            return (
                <div style={{ transform: ' translate3d(0, 0, 0) ' }}>
                    {canAddToTree && node.trainingModuleId == trainingModuleByIdState?.data?.trainingModuleId
                        ? connectDragSource(renderItem(connectDragPreview, node), { dropEffect: 'copy' })
                        : renderItem(connectDragPreview, node)}
                </div>
            );
        }
    }

    const TrainingModuleComponent = DragSource(externalNodeType, externalNodeSpec, externalNodeCollect)(externalNodeBaseComponent);
    const topicsDom = useMemo(() => {
        return allTrainingModulesCompanyState?.data?.map((el, index) => {
            return <TrainingModuleComponent key={'drag' + el.trainingModuleId} node={el} />;
        });
    }, [allTrainingModulesCompanyState, treeData, canAddToTree]);

    return (
        <div>
            <DeleteTrainingModulesDialog
                modalOpen={trainingModuleToDelete}
                setModalOpen={() => setTrainingModuleToDelete(null)}
                onAction={() => deleteTrainingModule(trainingModuleToDelete)}
                trainingModuleName={
                    allTrainingModulesCompanyState.data?.find(el => el.trainingModuleId === trainingModuleToDelete)?.trainingModuleName
                }
            />
            {topicsDom}
        </div>
    );
}
