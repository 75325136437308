import React, { useEffect, useState } from 'react';
import Icon from 'assets/icons/checkmark-img.svg';
import { useDispatch, useSelector } from 'react-redux';
import { resetForgotPasswordRequestAction } from 'redux/actions/users.actions';
import { useTranslation } from 'react-i18next';
import { H7 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { DefaultButton } from 'components/elements/Button';

import '../ForgotPassword.css';

export function ForgotPasswordRequestSuccess({ history }) {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const forgotPasswordRequestState = useSelector(state => state.userManagement.forgotPasswordRequest);

    const [locationKeys, setLocationKeys] = useState([]);

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key]);
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);

                    // Handle forward event
                } else {
                    setLocationKeys(keys => [location.key, ...keys]);

                    dispatch(resetForgotPasswordRequestAction());
                }
            }
        });
    }, [dispatch, history, locationKeys]);

    return (
        <div className="forgot-password-screen">
            <img alt="logo" src={Icon} width={70} height={70}></img>
            <H7 style={{ marginTop: 30 }} color={colors.gray6}>
                {t('Email sent!')}
            </H7>
            <Body2 style={{ maxWidth: '35vw', margin: '30px 0' }}>
                {t(
                    'A link to request your password has been sent to {{mail}}. Follow the instructions in the email to proceed. If the email doesn’t show up soon, check your spam folder or contact us for further help.',
                    { mail: forgotPasswordRequestState.data }
                )}
            </Body2>
            <DefaultButton
                style={{ width: '150px' }}
                text={t('Return to login')}
                onClick={() => {
                    dispatch(resetForgotPasswordRequestAction());
                    history.replace('/login');
                }}
                textColor="white"
            />
        </div>
    );
}
