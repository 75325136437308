import styled from 'styled-components';

export const GameQuestionLeft = styled.div`
    margin-top: 20%;
    margin-left: 1em;
    color: #6a6968;
    padding-right: 1em;
    font-size: 12px;
    overflow-wrap: break-word;
`;

export const GameQuestionCenter = styled.div`
    margin-top: 10%;
    text-align: center;
    color: #6a6968;
    font-size: 12px;
    padding: 0 15px;
`;
