import PropTypes from 'prop-types';
import React from 'react';
import EllipsisIcon from 'assets/icons/ellipsis.svg';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';

import 'style/colors.css';
import './ActionMenu.css';

export const ActionMenu = ({ buttons, row }) => (
    <div className="dropdown col-3 d-flex justify-content-center align-items-center">
        <img
            alt="Dropdown"
            src={EllipsisIcon}
            height={9}
            width={25}
            className={'dropdown-toggle text-secondary'}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ cursor: 'pointer' }}
        />
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {buttons.map((b, index) => {
                return (
                    b.visible && (
                        <Body2
                            key={b.name}
                            color={colors.primaryText}
                            onClick={() => b.onClick(row)}
                            className={'dropdown-item dropdownt btn-sm py-2 w-100'}
                        >
                            {b.name}
                        </Body2>
                    )
                );
            })}
        </div>
    </div>
);

ActionMenu.propTypes = {
    buttons: PropTypes.array,
    row: PropTypes.any,
};
