import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { stateIsLoading } from 'redux/core/stateHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { downloadGameTemplatesAction, uploadContentAction } from 'redux/actions/content.actions';
import { authService } from 'services/auth/auth';
import { useTranslation } from 'react-i18next';

export function UploadQuestion({ resetModalState }) {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const uploadContentState = useSelector(state => state.content.uploadContent);
    const uploadContentProgressState = useSelector(state => state.content.uploadContentProgress);
    const downloadTemplatesState = useSelector(state => state.content.gameTemplates);

    const [file, setFile] = useState(null);

    const handleFileChange = files => {
        if (files.length > 0) {
            setFile(files[0]);
        } else {
            setFile(null);
        }
    };

    const downloadTemplates = () => {
        dispatch(downloadGameTemplatesAction(authService.token));
        resetModalState();
    };

    const fileUpload = () => {
        if (file !== null) {
            dispatch(uploadContentAction(file));
        }
    };

    return (
        <div>
            {stateIsLoading(uploadContentState) && (
                <div style={{ height: '40vh' }} className="row justify-content-center align-items-center">
                    <div className="d-flex flex-column w-50 text-center">
                        <h6>{t("Importing...")}</h6>
                        <div className="progress bg-white w-100">
                            <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: `${uploadContentProgressState?.data?.progress}%` }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                {uploadContentProgressState?.data?.progress}%
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!stateIsLoading(uploadContentState) && (
                <div style={{ overflow: 'scroll' }} className="row">
                    <div className="col-12 mt-2 d-flex justify-content-center">
                        <Button
                            onClick={downloadTemplates}
                            className="download-template-users"
                            variant="contained"
                            disabled={stateIsLoading(downloadTemplatesState)}
                            color="secondary"
                            startIcon={<GetAppIcon />}
                        >
                            {t("Download all templates")}
                        </Button>

                        <Button
                            id="upload-questions-list-button"
                            disabled={stateIsLoading(uploadContentState) || file === null}
                            onClick={fileUpload}
                            className={'ml-2'}
                            variant="contained"
                            color="secondary"
                            style={{ textTransform: 'none' }}
                            startIcon={<PublishIcon />}
                        >
                            {t("Upload content")}
                        </Button>
                    </div>

                    <div className="col-12 mt-5">
                        <div className="mx-3" id="upload-area-button">
                            <FileUploader
                                dropzoneText={<Typography variant="subtitle1">{t("Drag and drop the file here or click to upload")}</Typography>}
                                acceptedFiles={['.xls', '.xlsx']}
                                filesLimit={1}
                                onChange={handleFileChange}
                                dropzoneClass="users-upload"
                            ></FileUploader>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

UploadQuestion.propTypes = {
    resetModalState: PropTypes.func,
};
