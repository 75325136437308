import PropTypes from 'prop-types';
import React, { useState } from 'react';
import errorIcon from 'assets/icons/error-icon.svg';
import warningIcon from 'assets/icons/warning-icon.svg';
import { useTranslation } from 'react-i18next';

const InfoPopUpBox = ({ type, description }) => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);
    return (
        <div className="d-flex flex-row pl-3 pr-3" style={{ position: 'relative' }}>
            {type === 'warning' && (
                <img
                    onMouseEnter={() => {
                        setVisible(true);
                    }}
                    onMouseLeave={() => {
                        setVisible(false);
                    }}
                    src={warningIcon}
                    style={{ width: 25, height: 25 }}
                    alt="Warning icon"
                ></img>
            )}
            {type === 'error' && (
                <img
                    onMouseEnter={() => {
                        setVisible(true);
                    }}
                    onMouseLeave={() => {
                        setVisible(false);
                    }}
                    src={errorIcon}
                    style={{ width: 25, height: 25 }}
                    alt="Warning icon"
                ></img>
            )}
            {visible && (
                <div
                    style={{
                        backgroundColor: 'rgba(0,0,0, 0.9)',
                        borderRadius: 5,
                        padding: 10,
                        marginLeft: 10,
                        color: 'white',
                        position: 'absolute',
                        left: 45,
                        top: -10,
                        width: 200,
                        zIndex: 99,
                    }}
                >
                    {t(description)}
                </div>
            )}
        </div>
    );
};

InfoPopUpBox.propTypes = {
    description: PropTypes.any,
    type: PropTypes.string,
};

export default InfoPopUpBox;
