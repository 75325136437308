import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/styles';
import { colors } from 'style/colors';
import { marks } from 'static/charts';
import { useTranslation } from 'react-i18next';

export function CustomSlider({ period, setPeriod }) {
    const { t } = useTranslation();

    const classes = useStyles();

    const [activePeriodName, setActivePeriodName] = useState('');

    function changePeriod(value) {
        setActivePeriodName(t(marks[value].name));
        setPeriod({
            value: value,
            selector: marks[value].selector,
        });
    }

    return (
        <div className={'d-flex flex-column align-items-center'}>
            <h6>{activePeriodName.length > 0 ? activePeriodName : t('Week')}</h6>
            <Slider
                value={period.value}
                classes={classes}
                defaultValue={period.value}
                getAriaValueText={value => {
                    return '';
                }}
                aria-labelledby="discrete-slider-always"
                step={null}
                min={0}
                max={4}
                marks={marks}
                valueLabelDisplay="off"
                onChange={(event, newValue) => changePeriod(newValue)}
            />
        </div>
    );
}

CustomSlider.propTypes = {
    period: PropTypes.any,
    setPeriod: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
    root: {
        color: colors.gray1,
        height: 3,
    },
    thumb: {
        height: 13,
        width: 13,
        color: colors.gray3,
        border: `1.5px solid ${colors.gray1}`,
    },
}));
