import React from 'react';
import InputContent from 'components/features/content_management/inputs/InputContent';
import { useAnswerTextOptions } from 'services/content-management/insertion-control';
import InputAnswerCheckboxes from 'components/features/content_management/inputs/InputAnswerCheckboxes';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import Add from 'assets/icons/icon-add.svg';
import { Body2 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';

export default function RecallInputs({ game, id, filters, initializeQuestion }) {
    const { answers: answersList, insertAnswer } = useAnswerTextOptions();

    const { t } = useTranslation();

    return (
        <InputContent initializeQuestion={initializeQuestion} game={game} id={id} filters={filters}>
            <div className="mt-4">
                <div>
                    <Caption1 color={colors.gray6}>{t('OPTIONS')}</Caption1>
                </div>
                {answersList.map((ans, index) => (
                    <InputAnswerCheckboxes
                        key={`input-answer-checkbox-${ans.id}`}
                        value={ans.text}
                        index={index}
                        isCorrect={ans.isCorrect}
                        showCorrectIncorrect={false}
                        showRemove={answersList.length > 3}
                    />
                ))}
                <div className="d-flex flex-row align-content-center mt-2 cursor-pointer" onClick={insertAnswer}>
                    <img alt="Add" height={20} width={20} src={Add} />
                    <Body2 id="add-option-button-compare" className="ml-2">
                        {t('Add New')}
                    </Body2>
                </div>
            </div>
        </InputContent>
    );
}
