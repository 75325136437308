import PropTypes from 'prop-types';
import React from 'react';
import { authService } from 'services/auth/auth';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { CustomDateRangePicker } from 'components/elements/daterangepicker/CustomDateRangePicker';

import RadioInput from 'components/elements/inputs/RadioInput';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { buttonActions } from 'static/news';
import { useTranslation } from 'react-i18next';

export default function CreateAndEditPostSettings({ articlesState, dispatchArticles }) {
    const { t } = useTranslation();
    const { newsId } = useParams();

    return (
        <div className="create-news--general-settings">
            {authService.user.authorities[0] === 'ROLE_SUPERADMIN' && (
                <div className="create-news--setting">
                    <Caption1 color={colors.gray6} weight="medium">
                        {t('TYPE OF NEWS POST')}
                    </Caption1>
                    <div className="create-news--settings-radio">
                        <RadioInput
                            value="local"
                            label={t('Local')}
                            name="type"
                            disabled={newsId}
                            checked={articlesState.type === 'local'}
                            onChange={() => {
                                dispatchArticles({ payload: { type: 'local', platform: 'APP' } });
                            }}
                        />
                        <RadioInput
                            value="global"
                            label={t('Global')}
                            name="type"
                            disabled={newsId}
                            checked={articlesState.type === 'global'}
                            onChange={() => {
                                dispatchArticles({ payload: { type: 'global' } });
                            }}
                        />
                    </div>
                    {articlesState.type === 'local' && (
                        <CustomTextField
                            value={articlesState.companyId}
                            setValue={val => {
                                dispatchArticles({ payload: { companyId: val } });
                            }}
                            placeholder={t('Insert Company ID here...')}
                            width={'47%'}
                        />
                    )}
                </div>
            )}
            {authService.user.authorities[0] === 'ROLE_SUPERADMIN' && (
                <div className="create-news--setting">
                    <Caption1 color={colors.gray6} weight="medium">
                        {t('TARGET USERS')}
                    </Caption1>
                    <div className="create-news--settings-radio">
                        <RadioInput
                            value="APP"
                            label={t('Coach App')}
                            name="target"
                            disabled={newsId}
                            checked={articlesState.platform === 'APP'}
                            onChange={() => {
                                dispatchArticles({ payload: { platform: 'APP' } });
                            }}
                        />
                        <RadioInput
                            value="WEB"
                            label={t('Admin Panel')}
                            name="target"
                            disabled={newsId}
                            checked={articlesState.platform === 'WEB'}
                            onChange={() => {
                                dispatchArticles({ payload: { platform: 'WEB', type: 'global' } });
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="create-news--setting">
                <Caption1 color={colors.gray6} weight="medium">
                    {t('AVAILABILITY')}
                </Caption1>
                <div>
                    <CustomDateRangePicker
                        border={true}
                        value={articlesState.availability}
                        format="dd/MM/yyyy"
                        minDate={new Date()}
                        onChange={value => {
                            dispatchArticles({ payload: { availability: value } });
                        }}
                        showDoubleView={true}
                    ></CustomDateRangePicker>
                </div>
            </div>
            {articlesState.platform !== 'WEB' && (
                <div className="create-news--setting">
                    <Caption1 color={colors.gray6} weight="medium">
                        {t('BUTTON TITLE (optional)')}
                    </Caption1>
                    <CustomTextField
                        value={articlesState.buttonTitle}
                        setValue={val => {
                            dispatchArticles({ payload: { buttonTitle: val } });
                        }}
                        placeholder={t('Insert button title...')}
                        width={'47%'}
                    />
                </div>
            )}
            {articlesState.platform !== 'WEB' && (
                <div className="create-news--setting">
                    <Caption1 color={colors.gray6} weight="medium">
                        {t('BUTTON ACTION (optional)')}
                    </Caption1>
                    <div>
                        <CustomSelect
                            options={buttonActions}
                            value={articlesState.buttonAction}
                            width={'47%'}
                            onChange={value => {
                                dispatchArticles({ payload: { buttonAction: value } });
                            }}
                        ></CustomSelect>
                    </div>
                </div>
            )}
        </div>
    );
}

CreateAndEditPostSettings.propTypes = {
    articlesState: PropTypes.object,
    dispatchArticles: PropTypes.func,
};
