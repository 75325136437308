import React from 'react';

import { colors } from 'style/colors';
import EmptyTraining from 'assets/icons/icon-emptystate-trainings.svg';
import { H6 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';

export function NoSelectedTrainingState() {
    const { t } = useTranslation();

    return (
        <div
            style={{ position: 'absolute', left: '30%', top: '30%', zIndex: '1' }}
            className={'d-flex flex-row align-items-center justify-content-center'}
        >
            <div className="d-flex flex-column align-items-center">
                <img style={{ width: 100, height: 100 }} src={EmptyTraining} alt="" />
                <H6 color={colors.secoundaryText} className={'mt-4'}>
                    {t('No training selected')}
                </H6>
                <Body2 className={'mb-0 mt-1'} color={colors.gray6}>
                    {t('Select an existing training or create a new one')}
                </Body2>
            </div>
        </div>
    );
}
