import React, { useEffect, useState } from 'react';

import AngleDown from 'assets/icons/angle-down.svg';
import Checkbox from './Checkbox';
import { colors } from 'style/colors';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

function CustomCheckboxForTable({ selectAll, checked, deselectAll }) {
    const { t } = useTranslation();

    let values = [
        { label: t('Select table'), value: 'select-table' },
        { label: t('Select all'), value: 'select-all' },
        { label: t('Deselect all'), value: 'deselect-all' },
    ];
    const [currentOption, setCurrentOption] = useState(false);

    useEffect(() => {
        if (!checked) {
            setCurrentOption(false);
        }
    }, [checked]);

    return (
        <div style={{ height: '100%', width: '100%', border: 0, cursor: 'pointer' }}>
            <div>
                <Checkbox
                    onClick={() => {
                        selectAll(false);
                        setCurrentOption(false);
                    }}
                    checked={checked}
                ></Checkbox>
                {!checked && (
                    <img
                        alt={'angle_img'}
                        style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
                        height={10}
                        width={10}
                        src={AngleDown}
                    />
                )}
            </div>

            {!checked && (
                <Select
                    isSearchable={false}
                    id="select-all-rows-select"
                    value={currentOption}
                    onChange={el => {
                        if (el.value === values[2].value) {
                            deselectAll();
                            return;
                        }
                        selectAll(el.value === values[1].value);
                        setCurrentOption(el);
                    }}
                    options={values}
                    styles={{
                        control: styles => ({
                            height: 20,
                            opacity: 0,
                        }),
                        menu: (styles, { isFocused }) => {
                            return {
                                ...styles,

                                position: 'absolute',
                                width: 150,
                                height: 70,
                            };
                        },
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            return {
                                ...styles,
                                '&:active': {
                                    backgroundColor: colors.mainTheme,
                                },
                                color: colors.dark,
                                backgroundColor: isFocused ? colors.gray3 : 'white',
                            };
                        },
                    }}
                />
            )}
        </div>
    );
}

export default CustomCheckboxForTable;
