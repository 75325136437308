import React from 'react';
import Text from './Text';
import { colors } from 'style/colors';

const Caption = ({ weight, tag, fontWeight, fontHeight, color = colors.dark, style, ...attr }) => {
    return Text(weight, captionTagDefaults[tag], fontWeight, fontHeight, color, style, attr);
};

const Caption1 = props => <Caption {...props} tag="caption1" />;
const Caption2 = props => <Caption {...props} tag="caption2" />;

const captionTagDefaults = {
    caption1: {
        fontSize: 12,
    },
    caption2: {
        fontSize: 10,
    },
};

export { Caption1, Caption2 };
