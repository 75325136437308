import Paper from '@material-ui/core/Paper/Paper';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from 'style/colors';
import Modal from '@material-ui/core/Modal';
import React, { useEffect, useState } from 'react';
import AddQuestionHeader from './local_components/header/AddQuestionHeader';
import { AddQuestionByGameChooser } from './add_by_game/AddQuestionByGameChooser';
import { UploadQuestion } from './upload_question/UploadQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import Logo from 'assets/icons/coach-logo-black.svg';
import Collapse from '@material-ui/core/Collapse/Collapse';
import Alert from '@material-ui/lab/Alert/Alert';
import { useHistory } from 'react-router-dom';
import { resetUploadContentProgressAction, resetUploadContentStateAction, setEditingQuestionsListAction } from 'redux/actions/content.actions';
import { H3 } from 'style/typography/Heading';
import { Body1 } from 'style/typography/Body';
import { Trans, useTranslation } from 'react-i18next';

export function AddQuestionModal({ open, setOpen }) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = useState(0);
    const [uploadDone, setUploadDone] = useState(false);

    const uploadContentState = useSelector(state => state.content.uploadContent);
    const handleTabChange = tab => setSelectedTab(tab);

    const tabs = ['By game', 'Import questions'];

    useEffect(() => {
        if (stateIsLoaded(uploadContentState)) {
            setUploadDone(true);
            if (uploadContentState.data.totalIncomplete > 0) {
                dispatch(setEditingQuestionsListAction(uploadContentState.data.incompleteQuestionIds));
            }
        }
    }, [dispatch, uploadContentState]);

    function editImported() {
        setOpen(false);
        dispatch(resetUploadContentStateAction());
        dispatch(resetUploadContentProgressAction());
        history.replace('/content/questions?filterLastIncomplete=true');
    }

    function uploadAnother() {
        dispatch(resetUploadContentStateAction());
        dispatch(resetUploadContentProgressAction());
        setUploadDone(false);
    }

    function resetModalState() {
        dispatch(resetUploadContentStateAction());
        dispatch(resetUploadContentProgressAction());
        setSelectedTab(0);
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            className={classes.modal}
            onClose={() => {
                resetModalState();
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper style={{ overflow: 'scroll' }} className={`${classes.paper} d-flex flex-column`}>
                <H3 className="text-center mt-4">{t('Add Question')}</H3>
                <Body1 color={colors.gray6} className="text-center mb-5">
                    {t('Select question format from the options bellow')}
                </Body1>
                <AddQuestionHeader onTabChange={handleTabChange} tabs={tabs} />
                <div style={{ flex: 1 }}>
                    {/* <div key={0} style={{ display: selectedTab === 0 ? 'block' : 'none', height: '100%' }} className="text-center">
                        <Body1 className="mt-3" color={colors.gray6}>By question type</Body1>
                    </div> */}
                    <div key={0} style={{ display: selectedTab === 0 ? 'block' : 'none', height: '100%' }} className="text-center">
                        <AddQuestionByGameChooser />
                    </div>
                    <div key={1} style={{ display: selectedTab === 1 && !uploadDone ? 'block' : 'none' }} className="text-center">
                        <Body1 color={colors.gray6} className="mt-3">
                            <Trans>{t('Make sure to upload <strong>.xlsx or .xls</strong> file and adhere to our format')}</Trans>
                        </Body1>
                        <UploadQuestion resetModalState={resetModalState} />
                    </div>
                    {uploadDone && selectedTab === 1 && (
                        <div style={{ height: '100%' }} className="text-center d-flex flex-column align-items-center justify-content-center">
                            <img className={'mt-3'} src={Logo} width={70} height={70}></img>
                            {uploadContentState.data.totalIncomplete > 0 && (
                                <h6 className={'mt-3'}>
                                    <Trans>
                                        {
                                            (t('<strong>{{questionsCount}}</strong> questions need to be edited'),
                                            { questionsCount: uploadContentState.data.totalIncomplete })
                                        }
                                    </Trans>
                                </h6>
                            )}
                            <Collapse className={'w-50 mt-3'} in={uploadDone}>
                                {uploadContentState.data.totalIncomplete > 0 && (
                                    <Alert severity="warning">
                                        {t(
                                            'There are missing data in some of the questions. Please proceed to the question editor in order to complete them.'
                                        )}
                                    </Alert>
                                )}
                                {uploadContentState.data.totalIncomplete === 0 && uploadContentState.data.totalSuccessful > 0 && (
                                    <Alert severity="success"> {t('All questions uploaded successfully!')}</Alert>
                                )}
                                {uploadContentState.data.totalIncomplete === 0 && uploadContentState.data.totalSuccessful === 0 && (
                                    <Alert severity="error">
                                        {t('There was an error with the content upload. The document was empty or corrupted.')}
                                    </Alert>
                                )}
                            </Collapse>
                            {uploadContentState.data.totalIncomplete === 0 && (
                                <div className={'mt-auto'}>
                                    <button onClick={uploadAnother} style={{ color: colors.mainTheme }} className={'btn btn-dark px-5'}>
                                        {t('Upload another')}
                                    </button>
                                </div>
                            )}
                            {uploadContentState.data.totalIncomplete > 0 && (
                                <div className={'mt-auto'}>
                                    <button onClick={editImported} style={{ color: colors.white }} className={'btn btn-dark px-5'}>
                                        {t('Edit imported questions')}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Paper>
        </Modal>
    );
}

const useStyles = makeStyles(theme => {
    return {
        paper: {
            position: 'absolute',
            width: '70%',
            backgroundColor: theme.palette.background.main,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            height: '90vh',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            textAlign: 'center',
        },
        alertFixPaper: {
            maxWidth: '2000px',
            width: '600px',
        },
    };
});
