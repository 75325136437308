import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function RequestLicencesModal({ modalOpen, setModalOpen, onClose, onAction }) {
    const { t } = useTranslation();

    const companyDetailsState = useSelector(state => state.userManagement.company);

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                setModalOpen(false);
                onAction();
            }}
            onClose={onClose}
            title={t('Request Licences')}
            text={t(
                'Currently you have {{licencesLeft}} of {{licences}} licences left. You can send us a mail on <strong>support@getcoach.app</strong> to request more licences for your company',
                { licencesLeft: companyDetailsState.data?.userLicencesLeft, licences: companyDetailsState.data?.totalUserLicences }
            )}
            buttons={[
                {
                    title: t('Open mail'),
                    color: colors.white,
                },
            ]}
            width={500}
            hasCancel={true}
        ></ModalDialog>
    );
}

RequestLicencesModal.propTypes = {
    modalOpen: PropTypes.any,
    setModalOpen: PropTypes.func,
};
