import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper/Paper';
import { colors } from 'style/colors';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { Caption1 } from 'style/typography/Caption';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { Subtitle1 } from 'style/typography/Subtitle';
import { stateIsLoaded } from 'redux/core/stateHelpers';

import Close from 'assets/icons/filter-close.svg';
import CustomModal from 'components/features/modal/CustomModal.js/CustomModal';
import { useTranslation } from 'react-i18next';

export function EditTagDialog({ modalOpen, onModalClose, editedTag, onAction }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [tagName, setTagName] = useState(null);
    const [currentTag, setCurrentTag] = useState(null);

    const labelByIdState = useSelector(state => state.content.labelById);

    useEffect(() => {
        if (stateIsLoaded(labelByIdState)) {
            setCurrentTag(labelByIdState.data.tags.find(el => el.tagId == editedTag));
        }
    }, [labelByIdState, editedTag]);

    return (
        <CustomModal
            modalOpen={modalOpen}
            setModalOpen={_ => {
                onModalClose();
                setTagName(null);
            }}
        >
            <Paper style={{ width: '40vw', border: `solid 1px ${colors.gray4}` }} className={`${classes.paper}`}>
                <div className="col-12 d-flex flex-row p-3">
                    <div className="d-flex flex-column">
                        <Subtitle1 weight="medium">{t('Edit tag')}</Subtitle1>
                    </div>
                    <img
                        alt="Close"
                        onClick={() => {
                            onModalClose();
                            setTagName(null);
                        }}
                        className={'ml-auto cursor-pointer'}
                        height={17}
                        width={17}
                        src={Close}
                    />
                </div>
                <div className={'col-12 d-flex flex-column'}>
                    <Caption1 color={colors.gray6} className={'mr-2'}>
                        {t('TAG NAME')}
                    </Caption1>
                    <CustomOutlinedTextField
                        width={'100%'}
                        value={(tagName != null ? tagName : currentTag?.tag) ?? ''}
                        setValue={setTagName}
                        placeholder={t('Enter tag name...')}
                    ></CustomOutlinedTextField>
                </div>
                <div className={'col-12 pr-3 pl-3 d-flex flex-row justify-content-center mt-4 mb-3'}>
                    <DefaultButton
                        id="edit-tag-save-button"
                        text="Update"
                        onClick={() => {
                            onAction(editedTag, tagName);
                            setTagName(null);
                        }}
                        className={'mr-1'}
                    />

                    <OutlinedButton
                        id="edit-tag-cancel-button"
                        text={t('Cancel')}
                        onClick={() => {
                            onModalClose();
                            setTagName(null);
                        }}
                        className={'ml-1'}
                    />
                </div>
            </Paper>
        </CustomModal>
    );
}

EditTagDialog.propTypes = {
    editedTag: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onModalClose: PropTypes.func,
};

const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: colors.gray4,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
        },
    };
});
