import { SCROLL_FULL_ZONE, SCROLL_NOT_VISIBLE_BACK, SCROLL_STICKY_ZONE, SCROLL_VISIBLE_BACK } from '../constants';
import { StateStatus } from '../core/StateStatus';
import { ActionStatus } from '../core/ActionStatus';
import { buildActionType } from '../actions/buildActionType';

const scroller = (
    state = {
        status: StateStatus.LOADED,
        isHeaderStick: false,
        isBackToTopVisible: false,
    },
    action
) => {
    switch (action.type) {
        case buildActionType(SCROLL_STICKY_ZONE, ActionStatus.DONE):
            return { ...state, status: StateStatus.LOADED, isHeaderStick: true };
        case buildActionType(SCROLL_FULL_ZONE, ActionStatus.DONE):
            return { ...state, status: StateStatus.LOADED, isHeaderStick: false };
        case buildActionType(SCROLL_VISIBLE_BACK, ActionStatus.DONE):
            return { ...state, status: StateStatus.LOADED, isBackToTopVisible: true };
        case buildActionType(SCROLL_NOT_VISIBLE_BACK, ActionStatus.DONE):
            return { ...state, status: StateStatus.LOADED, isBackToTopVisible: false };
        default:
            return state;
    }
};

export { scroller };
