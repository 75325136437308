import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { asyncForEach } from 'services/utils/arrayHelpers';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import getCroppedImg from 'services/image_management/cropImage';
import UsersInvitationHeader from 'containers/not_logged_in_pages/invite_users_flow/UsersInvitationHeader';
import { UploaderSpace } from 'components/features/content_management/cropping_module/UploaderSpace';
import { InfoSideBar } from 'components/features/content_management/cropping_module/InfoSideBar';
import { palette } from 'style/theme/theme-constants';
import { ImageLibrarySpace } from 'components/features/content_management/cropping_module/ImageLibrarySpace';
import { SaveImages } from 'components/features/content_management/cropping_module/SaveImages';
import { ContainedButton } from 'components/elements/Button';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { aspectRatios, views } from 'services/image_management/image-editor-management/local-constants';
import { H7 } from 'style/typography/Heading';
import { resizeFile, resizeFileAndReduceQuality } from 'services/image_management/imageHandlers';

import Close from 'assets/icons/filter-close.svg';
import Camera from 'assets/icons/camera-dark.svg';
import { useTranslation } from 'react-i18next';

import './CroppingModule.css';

export function AddImageComponent({ addImage, index, addMultipleImages, onImageUploaded }) {
    const { t } = useTranslation();

    const { imageFiles, view, setImageFiles, setView, setOpenImageResizer, imageLimit } = useImageEditor();
    const [haveUploadedImages, setHaveUploadedImages] = useState(imageFiles.filesBlob.length > 0);
    const [openSaveImages, setOpenSaveImages] = useState(false);

    const onImagesUploadChange = async files => {
        // Promise.all(
        //     files.map(
        //         async e =>
        //             await resizeFileAndReduceQuality(e).then(resizedFile => {
        //                 return new File([resizedFile], e.name, { type: e.type });
        //             })
        //     )
        // ).then(async resizedFiles => {
        //     if (resizedFiles.length > 0) {
        if (files.length > 0) {
            let filesBlob = [];

            await asyncForEach(files, async file => {
                filesBlob.push({
                    url: URL.createObjectURL(file),
                    name: file.name,
                    lastModified: Date.now(),
                    size: file.size,
                    crop: {},
                    zoom: {},
                    croppedImage: {},
                    croppedAreaPixels: {},
                });
            });

            setHaveUploadedImages(true);
            setImageFiles({ index: index, files: files, filesBlob: filesBlob });
        }
        // });
    };

    const onImagesUploadCallback = () => {
        setHaveUploadedImages(true);
    };

    const saveImages = async () => {
        let croppedImgFiles = [];

        await asyncForEach(imageFiles.filesBlob, async (file, index) => {
            file.croppedImage = {};
            await asyncForEach(aspectRatios, async aspectRatio => {
                let croppedUrl = await getCroppedImg(file.url, file.croppedAreaPixels[aspectRatio]);
                file.croppedImage[aspectRatio] = await fetch(croppedUrl)
                    .then(r => r.blob())
                    .then(blobFile => {
                        // resizeFile(blobFile).then(resizedFile => {
                        return new File([blobFile], 'a_r_' + aspectRatio.replace('/', 'x') + '_' + file.name, { type: blobFile.type });
                        // })
                    });
            });
            let original = await fetch(file.url)
                .then(r => r.blob())
                .then(blobFile => {
                    return new File([blobFile], file.name, { type: blobFile.type });
                });
            file.croppedImage['original'] = original;
            file.croppedImage['id'] = file['id'];
            croppedImgFiles.push(file.croppedImage);
        });

        return croppedImgFiles;
    };

    useEffect(() => {
        if (imageFiles.filesBlob.length <= 0) {
            setHaveUploadedImages(false);
        } else {
            setHaveUploadedImages(true);
        }
    }, [imageFiles]);

    return (
        <>
            {!openSaveImages && (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ paddingTop: 10, marginLeft: 30 }}>
                            <H7 className="my-2" color={palette.secondary.main} weight="medium">
                                {t('Add image')}
                            </H7>
                        </div>
                        <img
                            alt="Close"
                            className="cursor-pointer"
                            style={{ position: 'absolute', right: 20, top: 20 }}
                            src={Close}
                            height={20}
                            width={20}
                            onClick={() => {
                                setOpenImageResizer(false);
                            }}
                        />
                        <UsersInvitationHeader
                            position={'left'}
                            onTabChange={value => {
                                if (value === 0) {
                                    setView(views[0]);
                                } else if (value === 1) {
                                    setView(views[2]);
                                }
                            }}
                            tabs={['Upload images', 'Image library']}
                        />
                    </div>

                    <div className="add-image-component--container">
                        <div style={{ display: 'flex', flex: 1, minWidth: 0 }}>
                            {view !== views[2] && (
                                <>
                                    {!haveUploadedImages && (
                                        <FileUploader
                                            dropzoneText={t('Drag and drop the images here')}
                                            acceptedFiles={['.jpeg', '.jpg', '.png']}
                                            filesLimit={imageLimit ? imageLimit : 100}
                                            Icon={() => <img alt="Camera" src={Camera} height={40} width={40} />}
                                            onChange={onImagesUploadChange}
                                            dropzoneClass="content-insertion-image-dropzone"
                                            dropzoneParagraphClass="content-insertion-image-dropzone-paragraph"
                                        ></FileUploader>
                                    )}
                                    {haveUploadedImages && (
                                        <>
                                            <UploaderSpace />
                                        </>
                                    )}

                                    {haveUploadedImages && <InfoSideBar />}
                                </>
                            )}
                            {view === views[2] && (
                                <ImageLibrarySpace
                                    addImage={addImage}
                                    addMultipleImages={addMultipleImages}
                                    onImageUploaded={onImageUploaded}
                                    onImagesUploadCallback={onImagesUploadCallback}
                                    index={index}
                                />
                            )}
                        </div>

                        {view === views[0] && haveUploadedImages && (
                            <div className="add-image-component--next">
                                <ContainedButton
                                    style={{ position: 'absolute', left: 0, bottom: 0, background: 'black', color: 'white', margin: 20 }}
                                    onClick={() => {
                                        setOpenSaveImages(true);
                                    }}
                                >
                                    {t('Next')}
                                </ContainedButton>
                            </div>
                        )}
                    </div>
                </>
            )}
            {openSaveImages && (
                <SaveImages
                    setOpenSaveImages={setOpenSaveImages}
                    setModalOpen={setOpenImageResizer}
                    saveImages={saveImages}
                    addImage={addImage}
                    addMultipleImages={addMultipleImages}
                    index={index}
                />
            )}
        </>
    );
}

AddImageComponent.propTypes = {
    addImage: PropTypes.func,
    addMultipleImages: PropTypes.func,
    index: PropTypes.any,
    onImageUploaded: PropTypes.func,
};
