import { useMemo, useReducer } from 'react';
import { FILTERS_LIMIT } from 'static/filters';

export const useFilters = ({ initialFiltersState }) => {
    const [filtersStateInner, dispatchFiltersInner] = useReducer((state, action) => {
        if (action.reset) {
            return { ...action.payload };
        }

        return { ...state, ...action };
    }, initialFiltersState);

    const dispatchFilters = filterValues => {
        let activeFilters = 0;

        let newFilterValues = Object.keys(filterValues).reduce((obj, filter) => {
            const isModifyable = initialFiltersState[filter]?.active !== undefined;
            obj[filter] = { ...filtersStateInner[filter], value: filterValues[filter] };

            if (isModifyable) {
                activeFilters += !!filterValues[filter] ? 1 : 0;
                obj[filter].active = !!filterValues[filter] ? !!filterValues[filter] : filtersStateInner[filter]?.active;
            }

            return obj;
        }, {});

        //Add more visible filters if there are less than 3 active
        Object.keys(newFilterValues).forEach(key => {
            const isModifyable = newFilterValues[key]?.active !== undefined;
            if (isModifyable && newFilterValues[key].active === false && FILTERS_LIMIT - activeFilters > 0) {
                newFilterValues[key].active = initialFiltersState[key]?.active ?? false;
                activeFilters += initialFiltersState[key].active ? 1 : 0;
            }
        });

        dispatchFiltersInner(newFilterValues);
    };

    const updateFilterState = key => {
        dispatchFiltersInner({
            ...filtersStateInner,
            [key]: { ...filtersStateInner[key], active: !filtersStateInner[key].active, value: initialFiltersState[key].value },
        });
    };

    const resetFilters = toState => {
        dispatchFiltersInner({ payload: toState ? toState : initialFiltersState, reset: true });
    };

    const filtersState = useMemo(() => {
        return Object.keys(filtersStateInner)?.reduce((acc, curr) => {
            acc[curr] = filtersStateInner[curr].value;
            return acc;
        }, {});
    }, [filtersStateInner]);

    const activeFilters = useMemo(() => {
        return Object.keys(filtersStateInner)
            ?.filter(el => filtersStateInner[el]['active'] !== undefined)
            ?.reduce((acc, curr) => {
                acc[curr] = {
                    active: filtersStateInner[curr].active,
                    label: filtersStateInner[curr].label,
                };
                return acc;
            }, {});
    }, [filtersStateInner]);

    return {
        filtersState,
        activeFilters,
        dispatchFilters,
        updateFilterState,
        resetFilters,
    };
};
