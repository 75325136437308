import IconTopicPickerSelected from 'assets/icons/icon-topicpicker-selected.svg';

export const contentActionsOptions = t => [
    { value: 'resolve', label: t('Resolve selected'), image: IconTopicPickerSelected },
    { value: 'unresolve', label: t('Unresolve selected'), image: IconTopicPickerSelected },
    { value: 'delete', label: t('Delete selected'), image: IconTopicPickerSelected },
];

export const typesTabs = [
    {
        id: 'feedback-content',
        type: 'content',
        name: 'Content',
    },
    {
        id: 'feedback-positive',
        type: 'positive',
        name: 'Positive',
    },
];
