import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { getTitleCase } from 'services/utils/stringHelpers';
import FeedbackViewInfoComponent from './FeedbackViewInfoComponent';
import { useTranslation } from 'react-i18next';

export default function FeedbackViewInfo({ feedback, history, setUpdateModalOpen, setDeleteModalOpen }) {
    const { t } = useTranslation();

    return (
        <div style={{ background: colors.gray2, height: '90vh' }} className="col-6 pt-3 pl-4 pr-4 pb-0 d-flex flex-column">
            <FeedbackViewInfoComponent itemTitle="Sent by" value={feedback.user} />
            <FeedbackViewInfoComponent itemTitle="Date sent" value={feedback.dateSubmitted} />
            {feedback.feedbackType.toLowerCase() === 'game' && <FeedbackViewInfoComponent itemTitle="Game" value={feedback.gameName} />}
            <FeedbackViewInfoComponent itemTitle="Learning set" value={feedback.learningSet} />
            {feedback.feedbackType.toLowerCase() === 'content' && <FeedbackViewInfoComponent itemTitle="Question id" value={feedback.questionId} />}
            {feedback.isResolved && feedback.resolverComment && (
                <FeedbackViewInfoComponent itemTitle="Resolver comment" value={feedback.resolverComment} />
            )}
            <FeedbackViewInfoComponent itemTitle="Feedback type" value={getTitleCase(feedback.feedbackType)} />
            {feedback.feedbackType != 'POSITIVE' && (
                <div className="mt-4">
                    <Body2 color={colors.primaryText}>{t('Status')}</Body2>
                    <div>
                        <Body2 className="badge badge-pill badge-light p-2 text-secondary mt-1">
                            <FontAwesomeIcon color={feedback.isResolved ? 'gray' : colors.mainTheme} className={'mr-1'} icon={faCircle} size={'sm'} />
                            {feedback.isResolved ? t('Resolved') : t('Unresolved')}
                        </Body2>
                    </div>
                </div>
            )}
        </div>
    );
}
