import { palette } from 'style/theme/theme-constants';

export const lists = {
    MuiListItem: {
        root: {
            '&:not(:last-child)': {
                borderBottom: `1px solid ${palette.border.separator}`,
            },
            '&.navigation-menu-item:not(:last-child)': {
                borderStyle: 'none',
            },
            '&.navigation-menu-item': {
                color: palette.menu.inactive,
            },
            '&.navigation-menu-item.active': {
                color: palette.menu.active,
            },
        },
        button: {
            '&.navigation-menu-item:hover': {
                backgroundColor: '#1F1F1F',
            },
        },
    },
};
