import React from 'react';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { H4 } from 'style/typography/Heading';

import Confetti from 'assets/icons/confetti.svg';
import CheckmarkOrange from 'assets/icons/check-orange.svg';
import { useSelector } from 'react-redux';
import { Subtitle2 } from 'style/typography/Subtitle';

import './GenerationTabs.css';
import { Trans, useTranslation } from 'react-i18next';

export default function PreviewTraining({ title }) {
    const generatingContentProgressState = useSelector(state => state.content.generatingContentProgress);

    const completedData = generatingContentProgressState?.data?.completedData;

    const { t } = useTranslation();

    return (
        <div className="generation-tabs--container">
            <div className="generation-tabs--items">
                <div className="generation-tabs--preview-title">
                    <img alt="Confetti" src={Confetti} width={'22%'} />
                    <H4 color={colors.dark} weight="light" style={{ padding: '20px 0' }}>
                        <Trans>
                            {t('Your new training {{trainingTitle}} <br/> was successfully generated!', {
                                trainingTitle: completedData?.trainingTitle,
                            })}
                        </Trans>
                    </H4>
                    <img alt="Confetti" src={Confetti} width={'22%'} style={{ transform: 'rotateY(180deg) rotateX(180deg)' }} />
                </div>
                <Body2 color={colors.dark} weight="light">
                    <Trans>
                        {t(
                            'You just saved countless of hours by utilizing AI to generate your training! <br /> Now, feel free to go over, preview and edit your training, the topics and questions in it, <br /> in order to polish it and deploy it to the players.'
                        )}
                    </Trans>
                </Body2>

                <div className="generation-tabs--preview-status">
                    <div className="generation-tabs--preview-success">
                        <div className="generation-tabs--preview-success-icon">
                            <img alt="Checkmark" src={CheckmarkOrange} height={20} width={20} />
                        </div>
                        <Subtitle2 weight="light" color={colors.dark}>
                            <Trans>
                                {t('<strong>{{generatedQuestionsCount}} questions </strong> successfully generated!', {
                                    generatedQuestionsCount: completedData?.generatedQuestionsCount,
                                })}
                            </Trans>
                        </Subtitle2>
                    </div>
                    <div className="generation-tabs--preview-success">
                        <div className="generation-tabs--preview-success-icon">
                            <img alt="Checkmark" src={CheckmarkOrange} height={20} width={20} />
                        </div>
                        <Subtitle2 weight="light" color={colors.dark}>
                            <Trans>
                                {t('<strong>{{generatedTopicsCount}} topics </strong> successfully generated!', {
                                    generatedTopicsCount: completedData?.generatedTopicsCount,
                                })}
                            </Trans>
                        </Subtitle2>
                    </div>
                </div>
            </div>
        </div>
    );
}
