import { ModalDialog } from 'components/features/modal/ModalDialog';
import FeedbackIcon from 'assets/icons/icon-emptystate-tags.svg';
import { H8 } from 'style/typography/Heading';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { resetLabelByIdAction } from 'redux/actions/tags.actions';
import AngleUp from 'assets/icons/angle-up.svg';
import AngleDown from 'assets/icons/angle-down.svg';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { Caption1 } from 'style/typography/Caption';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Dropdown from 'assets/icons/filter-caret.svg';
import Close from 'assets/icons/filter-close.svg';
import {
    editModifiedUsersInBatchAction,
    editUsersRoleInBatchAction, resetEditUsersRoleInBatchAction,
} from '../../../../../../../../redux/actions/users.actions';
import { toast } from 'react-hot-toast';
import { stateIsLoaded } from '../../../../../../../../redux/core/stateHelpers';

const DropdownIndicator = props => {
    return <img alt="Dropdown" className="mr-2 cursor-pointer" src={Dropdown} height={17} width={17} />;
};

const ClearIndicator = props => {
    return <img alt="Clear" className="mr-2 cursor-pointer" src={Close} height={17} width={17} onClick={props.clearValue} />;
};

export const AddRoleModal = ({modalOpen, setModalOpen,usersList, allUsersSelected}) => {
    const rolesState = useSelector(state => state.userManagement.roles);
    const editUsersRoleInBatchState = useSelector(state => state.userManagement.editUsersRoleInBatch);

    const [selectedRole,setSelectedRole] = useState(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const onAction = () => {
        if ((usersList != null && Object.keys(usersList)?.length > 0) || allUsersSelected){
            let users = Object.values(usersList).map(u => u.id);
            dispatch(editUsersRoleInBatchAction({
                userIds: users,
                roleId: selectedRole.roleId,
                allSelected: allUsersSelected ? allUsersSelected : false,
                removeRole: false
            }));
        }
    }

    useEffect(() => {
        if (stateIsLoaded(editUsersRoleInBatchState)){
            dispatch(resetEditUsersRoleInBatchAction());
            setModalOpen(false);
            toast.success(t('Successfully saved!'));
            setTimeout(() => window.location.reload(), 1000);
        }
    },[editUsersRoleInBatchState])

    const buttonsEmptyState = [
        {
            title: t('Create role'),
            onClick: () => {
                history.replace('/company/roles');
            },
        },
    ];

    const buttons = [
        {
            title: t('Add role'),
            color: colors.white,
            onAction: () => onAction(),
        },
    ];


    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onClose={() => setModalOpen(false)}
            onAction={() => {
                onAction();
            }}
            title={rolesState.data?.roles?.length > 0 ? 'Add role to users' : ''}
            buttons={rolesState.data?.roles?.length > 0 ? buttons : buttonsEmptyState}
            hasCancel={true}
            alert={
                <div style={{ width: '100%' }}>
                    {rolesState.data?.roles?.length === 0 && (
                        <div style={{ paddingTop: 10, alignContent: 'center', textAlign: 'center' }}>
                            <img style={{ width: 80, height: 80 }} src={FeedbackIcon} alt="" />
                            <H8 color={colors.secoundaryText} className={'mt-4'}>
                                {t('No existing tags')}
                            </H8>
                            <Body2 className={'mt-3'} color={colors.gray6}>
                                {t('You can create labels and add tags inside the company tab.')}
                            </Body2>
                        </div>
                    )}
                    {rolesState.data?.roles?.length > 0 && (
                        <div>
                            <Body2>{t('Select the role that you want to add')}</Body2>
                            <div className="roles modal--container pt-3">
                                        <Select
                                            value={selectedRole ? {
                                                value: selectedRole,
                                                label: selectedRole?.roleName
                                            } : null}
                                            isClearable
                                            components={{ DropdownIndicator, ClearIndicator }}
                                            arrowRenderer={() => {}}
                                            styles={customStyles}
                                            onChange={option => setSelectedRole(option ? option.value : null)}
                                            placeholder={t('Choose role')}
                                            options={rolesState?.data?.roles.map(r => (
                                                {
                                                    value: r,
                                                    label: r?.roleName
                                                 }
                                            ))}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    neutral50: colors.disabled,
                                                },
                                            })}
                                        />
                            </div>
                        </div>
                    )}
                </div>
            }
        ></ModalDialog>
    );
}


const customStyles = {
    menu: styles => ({
        ...styles,
        zIndex: 1,
    }),
    control: styles => ({
        ...styles,
        border: '1px solid white',
        boxShadow: 'none',
        zIndex: 1,
        '&:hover': {
            border: `1px solid ${colors.mainTheme}`,
        },
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            '&:active': {
                backgroundColor: colors.mainTheme,
            },
            zIndex: 1,
            color: colors.dark,
            backgroundColor: isFocused ? colors.gray3 : 'white',
        };
    },
    indicatorSeparator: (provided, state) => ({}),
};
