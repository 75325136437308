import React, { useEffect, useState } from 'react';
import './TopicsTreeStyle.css';
import Topics from 'assets/icons/topic-icon-questions.svg';
import Info from 'assets/icons/info.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllQuestionsFromCategory } from 'redux/actions/content.actions';
import WarningIcon from 'assets/icons/warning-icon.png';

import { EmptyState } from './EmptyState';
import ReactModal from 'react-modal';
import { colors } from 'style/colors';
import './TopicsTreeStyle.css';
import Delete from 'assets/icons/icon-delete.svg';
import Add from 'assets/icons/icon-add.svg';
import Edit from 'assets/icons/icon-edit.svg';
import Subcategories from 'assets/icons/icon-subcategories.svg';
import { TopicSettingsDialog } from './TopicSettingsDialog';
import SortableTree, { changeNodeAtPath } from 'components/features/trees/SortableTree';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import {
    addChildToTrainingTree,
    initiateNodeRemovalAction,
    setSaveTrainingValidationAction,
    updateAllTopicsAction,
    updateSingleTrainingAction,
    updateTopicActionsType,
} from 'redux/actions/trainings.actions';
import InputTooltip from 'components/elements/tooltips/InputTooltip';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { useDrop } from 'react-dnd';
import ControlledInput from 'components/elements/inputs/ControlledInput';
import { RemoveTopicFromTreeDialog } from './RemoveTopicFromTreeDialog';
import { getTopicContentAction, saveTrainingModuleLearningSetAction } from 'redux/actions/topics.actions';
import { useUndoRedo } from 'services/undo-wrapper-management';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { flatten } from 'services/utils/treeHelpers';

ReactModal.setAppElement('#root');

export function TopicsTree({ setDraggingNode, setSelectedNode }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const data = useSelector(state => state.content.singleTraining.data.treeStructure);
    const setInfo = useSelector(state => state.content.singleTraining.data.learningSetInfo);
    const saveTrainingTreeValidation = useSelector(state => state.content.saveTrainingTreeValidation);
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);

    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(null);
    const [topicSettingsModalOpen, setTopicSettingsModalOpen] = useState(false);
    const [removeTopicModalOpen, setRemoveTopicModalOpen] = useState(false);
    const [editedNode, setEditedNode] = useState(null);
    const [editedNodePath, setEditedNodePath] = useState(null);
    const [nodeToDelete, setNodeToDelete] = useState(null);
    const [showInfoNode, setShowInfoNode] = useState(null);

    const { addToUndoStack, resetRedoStack } = useUndoRedo();

    let currentSetId = setInfo?.setId ?? setInfo?.generatedSetId;

    const [__, drop] = useDrop(
        () => ({
            accept: 'TOPIC',
            drop: (item, ___) => {
                resetRedoStack(currentSetId);
                addToUndoStack(currentSetId, null, {
                    training: { treeStructure: _.cloneDeep(data) },
                    localTopics: _.cloneDeep(localTopicChangesState.data),
                });

                dispatch(addChildToTrainingTree([data[0].treeIndex ?? data[0].generatedTreeIndex], getNodeKey, '', false, { node: item.node }));
            },
        }),
        [data]
    );

    const [searchParams, setSearchParams] = useState({
        searchString: '',
        searchFocusIndex: 0,
        searchFoundCount: null,
    });

    const getNodeKey = function(node) {
        let nodeCurr = node.node ? node.node : node;
        return nodeCurr.treeIndex ?? nodeCurr.generatedTreeIndex;
    };

    const { searchString, searchFocusIndex } = searchParams;

    const closeModal = () => {
        dispatch(setSaveTrainingValidationAction(false, false));
    };

    function onTopicSettingsModalClose() {
        setEditedNode(null);
        setTopicSettingsModalOpen(false);
    }

    function setToShowInfoNode(toShow, nodeId) {
        setTimeoutLastExecute.addCallback(
            () => {
                setShowInfoNode(toShow ? nodeId : null);
            },
            100,
            `showInfoNode${nodeId}`
        );
    }

    function onNodeDelete(node) {
        if (node?.children && node?.children?.length > 0) {
            setNodeToDelete({ setId: setInfo.setId, treeIndex: node.treeIndex });
            setRemoveTopicModalOpen(true);
        } else {
            resetRedoStack(currentSetId);
            addToUndoStack(currentSetId, null, {
                training: { treeStructure: _.cloneDeep(data) },
                localTopics: _.cloneDeep(localTopicChangesState.data),
            });
            dispatch(initiateNodeRemovalAction(setInfo.setId, node.treeIndex, false));
        }
    }

    function onInputChange(title, node, path) {
        let newChildren = changeNodeAtPath({
            treeData: data[0].children,
            path: path,
            newNode: node.isNew ? { ...node, titleForTraining: title, title: title } : { ...node, titleForTraining: title },
            getNodeKey,
        });

        dispatch(
            updateSingleTrainingAction(
                {
                    treeStructure: [{ ...data[0], children: newChildren }],
                },
                400
            )
        );

        dispatch(
            updateAllTopicsAction(node.treeIndex, updateTopicActionsType.update, {
                setId: setInfo.setId,
                title: node.title,
                learnersTitle: node.isNew ? title : title,
                isNew: node.isNew,
            })
        );
    }

    useEffect(() => {
        if (currentCategoryIndex) {
            dispatch(getAllQuestionsFromCategory(currentCategoryIndex));
        }
    }, [currentCategoryIndex, dispatch]);

    if (data?.length == 0 || data?.[0]?.children?.length == 0) {
        return (
            <div ref={drop}>
                <EmptyState />
            </div>
        );
    }

    const nodePropsProvider = ({ node, path }) => {
        return {
            onClick: () => {
                if (!isNaN(node.treeIndex)) {
                    setSelectedNode(node);
                    dispatch(getTopicContentAction(node.treeIndex));
                }
            },
            buttons: [
                <div className="row topics-t--node-btn-wrapper col-9">
                    <div className="row">
                        <div className="topics-t--node-btn col-2">
                            <img
                                onClick={() => {
                                    setEditedNode(node);
                                    setEditedNodePath(path);
                                    setTopicSettingsModalOpen(true);
                                }}
                                src={Edit}
                                alt={'edit'}
                            />
                        </div>
                        <div className="topics-t--node-btn col-3">
                            <img onClick={() => onNodeDelete(node)} src={Delete} alt={'delete'} />
                        </div>

                        <div className="topics-t--node-btn col-3">
                            <img
                                onClick={() => {
                                    dispatch(addChildToTrainingTree(path, getNodeKey, '', false, null));
                                }}
                                src={Add}
                                alt={'add'}
                            />
                        </div>
                    </div>
                </div>,
            ],
            title: (
                <div className={'d-flex flex-row align-content-center'}>
                    <div className="topics-t--title">
                        <ControlledInput
                            className={'inputHover'}
                            value={node.titleForTraining || ''}
                            placeholder={node.isNew ? t('Enter category name') : ''}
                            style={{ width: 150 }}
                            onChange={event => {
                                const title = event.target.value;
                                onInputChange(title, node, path);
                            }}
                        />
                    </div>
                    <div style={{ position: 'relative', left: 0, marginTop: 5 }} className="d-flex flex-row col-5">
                        <div
                            style={node.children?.length > 0 ? { opacity: 1 } : { opacity: 0 }}
                            className={'mr-3 d-flex flex-row align-items-center topics-t--title-meta'}
                        >
                            <img src={Subcategories} className={'mr-1'} alt={'subc'} height={20} width={20} />
                            <p className={'text-muted mb-0'}>{node?.children?.length}</p>
                        </div>
                        <div className={'d-flex flex-row align-items-center topics-t--title-meta'}>
                            <img src={Topics} alt={'topics'} height={20} width={20} />
                            <p className={'text-muted mb-0 font-weight-bold ml-1'}>{node?.numberOfQuestions > 0 ? node.numberOfQuestions : 0}</p>
                        </div>
                        <div className={'d-flex flex-row align-items-center topics-t--title-meta ml-3 cursor-pointer'}>
                            <img
                                src={Info}
                                alt={'info'}
                                height={20}
                                width={20}
                                onMouseEnter={() => setToShowInfoNode(true, node.treeIndex)}
                                onMouseLeave={() => setToShowInfoNode(false, node.treeIndex)}
                            />
                            {showInfoNode == node.treeIndex && (
                                <div style={{ position: 'absolute', top: 25 }}>
                                    <InputTooltip
                                        visible={showInfoNode === node.treeIndex}
                                        onMouseEnter={() => setToShowInfoNode(true, node.treeIndex)}
                                        onMouseLeave={() => setToShowInfoNode(false, node.treeIndex)}
                                        position={'bottom'}
                                        options={[
                                            {
                                                type: 'info',
                                                infoTitle: t('Original topic title'),
                                                infoValue: node.title,
                                            },

                                            node.dateAvailableFrom > 0 && {
                                                type: 'info',
                                                infoTitle: t('Available from'),
                                                infoValue: new Date(node.dateAvailableFrom).toDateString(),
                                            },
                                            {
                                                type: 'divider',
                                            },
                                            {
                                                type: 'link',
                                                linkTo: `/content/topics?topicId=${node?.treeIndex}`,
                                                text: t('Go to topic'),
                                                style: { color: colors.mainTheme },
                                            },
                                            {
                                                onClick: () => {
                                                    dispatch(saveTrainingModuleLearningSetAction(currentSetId, node?.treeIndex));
                                                    setToShowInfoNode(false, node.treeIndex);
                                                },
                                                text: t('Save subtree'),
                                                style: { color: colors.mainTheme },
                                            },
                                        ]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ),
        };
    };
    return (
        <div className={'row'}>
            <div style={{ backgroundColor: colors.gray3 }} className="col-12 px-5 py-2">
                <ModalDialog
                    modalOpen={saveTrainingTreeValidation?.data?.title || false}
                    setModalOpen={open => {
                        if (!open) {
                            closeModal();
                        }
                    }}
                    onAction={() => {
                        closeModal();
                    }}
                    title={saveTrainingTreeValidation?.data?.title}
                    text={saveTrainingTreeValidation?.data?.text}
                    buttons={[
                        {
                            title: t('OK'),
                            color: colors.white,
                        },
                    ]}
                    hasCancel={false}
                    image={WarningIcon}
                ></ModalDialog>
                <TopicSettingsDialog
                    modalOpen={topicSettingsModalOpen}
                    setModalOpen={setTopicSettingsModalOpen}
                    node={editedNode}
                    onModalClose={onTopicSettingsModalClose}
                    trainingId={currentSetId}
                    data={data}
                    setData={updateSingleTrainingAction}
                    path={editedNodePath}
                />
                {nodeToDelete !== null && nodeToDelete !== undefined && (
                    <RemoveTopicFromTreeDialog
                        modalOpen={removeTopicModalOpen}
                        setModalOpen={setRemoveTopicModalOpen}
                        topicToRemove={nodeToDelete}
                        onTopicRemoved={() => {
                            resetRedoStack(currentSetId);
                            addToUndoStack(currentSetId, null, {
                                training: { treeStructure: _.cloneDeep(data) },
                                localTopics: _.cloneDeep(localTopicChangesState.data),
                            });
                        }}
                    ></RemoveTopicFromTreeDialog>
                )}
                <div style={{ height: '56vh' }}>
                    <SortableTree
                        style={{ backgroundColor: colors.gray3, maxHeight: '65vh' }}
                        treeData={data?.[0]?.children || []}
                        onChange={_ => {}}
                        onVisibilityToggle={state => {
                            let treeCurrent = _.cloneDeep([...data]);
                            treeCurrent[0].children = state.treeData;
                            resetRedoStack(currentSetId);
                            addToUndoStack(currentSetId, null, {
                                training: { treeStructure: _.cloneDeep(data) },
                                localTopics: _.cloneDeep(localTopicChangesState.data),
                            });
                            dispatch(updateSingleTrainingAction({ treeStructure: treeCurrent }));
                        }}
                        onMoveNode={state => {
                            let treeCurrent = _.cloneDeep([...data]);
                            treeCurrent[0].children = state.treeData;

                            let newTreeWithUpdatedParent = changeNodeAtPath({
                                treeData: treeCurrent,
                                path: [treeCurrent[0].treeIndex ?? treeCurrent[0].generatedTreeIndex, ...state.path],
                                newNode: { ...state.node, parentNode: { treeIndex: state.nextParentNode?.treeIndex ?? treeCurrent[0].treeIndex } },
                                getNodeKey,
                            });

                            resetRedoStack(currentSetId);
                            addToUndoStack(currentSetId, null, {
                                training: { treeStructure: _.cloneDeep(data) },
                                localTopics: _.cloneDeep(localTopicChangesState.data),
                            });

                            dispatch(updateSingleTrainingAction({ treeStructure: newTreeWithUpdatedParent }));
                        }}
                        dndType={'TOPIC'}
                        getNodeKey={getNodeKey}
                        onDragStateChanged={state => {
                            if (state.isDragging) {
                                if (state.draggedNode.usedIn && !state.draggedNode.usedIn.find(el => el.setId === currentSetId)) {
                                    state.draggedNode.titleForTraining = state.draggedNode.title;
                                }
                                if (state.draggedNode.children && state.draggedNode.children.length > 0) {
                                    let allNodes = flatten(state.draggedNode.children);
                                    allNodes.forEach(nod => {
                                        nod.titleForTraining = nod.titleForTraining ?? nod.title;
                                    });
                                    state.draggedNode.titleForTraining = state.draggedNode.title;
                                }
                            }

                            return state.isDragging ? setDraggingNode(state.draggedNode) : '';
                        }}
                        searchQuery={searchString}
                        searchFocusOffset={searchFocusIndex}
                        generateNodeProps={({ node, path, ...rest }) => {
                            return nodePropsProvider({ node, path });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
