import React, { useState } from 'react';
import { OutlinedButton } from 'components/elements/Button';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { generateContentForTopicAction, resetGenerateContentForTopicAction } from 'redux/actions/content.actions';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { colors } from 'style/colors';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Select from 'react-select';
import Dropdown from 'assets/icons/filter-caret.svg';
import Close from 'assets/icons/filter-close.svg';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { languages } from 'static/language';

export const GenerateContent = ({ selectedNode }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const generateContentForTopicState = useSelector(state => state.content.generateContentForTopic);

    const [modalOpen, setModalOpen] = useState(false);
    const [chosenLanguage, setChosenLanguage] = useState(null);
    const [checkedGames, setCheckedGames] = useState({
        'race-cars': {
            selected: true,
            title: 'Race cars'
        },
        traffic: {
            selected: true,
            title: 'Traffic'
        },
        numbers: {
            selected: true,
            title: 'Numbers'
        },
    });

    const handleCheckChange = (key, value) => {
        setCheckedGames({
            ...checkedGames,
            [key]: {
                ...checkedGames[key],
                selected: value
            },
        });
    };

    const DropdownIndicator = props => {
        return <img alt="Dropdown" className="mr-2 cursor-pointer" src={Dropdown} height={17} width={17} />;
    };

    const ClearIndicator = props => {
        return <img alt="Clear" className="mr-2 cursor-pointer" src={Close} height={17} width={17} onClick={props.clearValue} />;
    };

    return (
        <div className={'ml-auto d-flex align-items-center'}>
            <OutlinedButton
                id="topic-generate-questions-button"
                text={t('Generate questions')}
                disabled={stateIsLoading(generateContentForTopicState)}
                onClick={() => {
                    setModalOpen(true);
                }}
            />
            <ModalDialog
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onClose={() => {
                    dispatch(resetGenerateContentForTopicAction());
                }}
                onAction={() => {
                    setModalOpen(false);
                    dispatch(resetGenerateContentForTopicAction());
                }}
                text={t('Total questions created: {{createdQuestions}} | Total incomplete: {{totalIncomplete}}', {
                    createdQuestions: generateContentForTopicState?.data?.totalSuccessful?.toString(),
                    totalIncomplete: generateContentForTopicState?.data?.totalIncomplete?.toString(),
                })}
                contentComponent={
                    <div style={{ height: 400 }} className={'d-flex flex-column w-100'}>
                        <h4>{t('Generate content')}</h4>
                        {!stateIsLoading(generateContentForTopicState) && !stateIsLoaded(generateContentForTopicState) && (
                            <div className={'d-flex flex-column align-items-center mt-4 h-100 p-3'}>
                                <div style={{ width: '200px' }}>
                                    <Select
                                        value={chosenLanguage ? languages.filter(option => option.value === chosenLanguage) : null}
                                        isClearable
                                        components={{ DropdownIndicator, ClearIndicator }}
                                        arrowRenderer={() => {}}
                                        styles={customStyles}
                                        onChange={option => setChosenLanguage(option ? option.value : null)}
                                        placeholder={t('Choose language')}
                                        options={languages}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                neutral50: colors.disabled,
                                            },
                                        })}
                                    />
                                </div>
                                <Box sx={{ display: 'flex', width: 200, marginTop: 3 }}>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormLabel component="legend">{t('Generate for games:')}</FormLabel>
                                        <FormGroup className={'mt-2'}>
                                            {Object.keys(checkedGames).map(key => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={() => handleCheckChange(key, !checkedGames[key].selected)}
                                                            checked={checkedGames[key].selected}
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: colors.mainTheme,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={checkedGames[key].title}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                                <button
                                    disabled={
                                        !Object.keys(checkedGames).map(key => checkedGames[key].selected).includes(true) ||
                                        chosenLanguage === null ||
                                        stateIsLoading(generateContentForTopicState)
                                    }
                                    onClick={() =>
                                        dispatch(
                                            generateContentForTopicAction(
                                                selectedNode.treeIndex,
                                                chosenLanguage,
                                                Object.keys(checkedGames)
                                                    .filter(key => checkedGames[key].selected === true)
                                                    .map(key => key)
                                            )
                                        )
                                    }
                                    style={{ color: colors.mainTheme }}
                                    className={'btn btn-dark px-5 mt-auto'}
                                >
                                    {t('Generate')}
                                </button>
                            </div>
                        )}
                        {stateIsLoading(generateContentForTopicState) && (
                            <div className={'d-flex flex-column align-items-center mt-4 h-100 p-3'}>
                                <CircularProgress thickness={2} size={50} />
                                <p className={'mt-3 text-muted'}>{t('generating...')}</p>
                            </div>
                        )}
                        {stateIsLoaded(generateContentForTopicState) && (
                            <div className={'d-flex flex-column align-items-center mt-4 h-100 p-3'}>
                                <p>
                                    {t('Total questions created: {{createdQuestions}} | Total incomplete: {{totalIncomplete}}', {
                                        createdQuestions: generateContentForTopicState?.data?.totalSuccessful?.toString(),
                                        totalIncomplete: generateContentForTopicState?.data?.totalIncomplete?.toString(),
                                    })}
                                </p>
                                <button
                                    onClick={() => setModalOpen(false)}
                                    style={{ color: colors.mainTheme }}
                                    className={'btn btn-dark px-5 mt-auto'}
                                >
                                    {t("Okay")}
                                </button>
                            </div>
                        )}
                    </div>
                }
                hasCancel={false}
            ></ModalDialog>
        </div>
    );
};

const customStyles = {
    menu: styles => ({
        ...styles,
        zIndex: 1,
    }),
    control: styles => ({
        ...styles,
        border: '1px solid white',
        boxShadow: 'none',
        zIndex: 1,
        '&:hover': {
            border: `1px solid ${colors.mainTheme}`,
        },
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            '&:active': {
                backgroundColor: colors.mainTheme,
            },
            zIndex: 1,
            color: colors.dark,
            backgroundColor: isFocused ? colors.gray3 : 'white',
        };
    },
    indicatorSeparator: (provided, state) => ({}),
};
