import { actionTypes } from './local-actions';
import { HISTORY_LIMIT, INITIAL_STATE } from './local-constants';
import _ from 'lodash';
import { getColWidthByType } from 'static/content_blocks';
import { create_UUID } from 'services/utils/stringHelpers';


export const contentBlocksReducer = (state, action) => {
    function setHistoryStateWrapper(stateNew) {
        let dataCopy = _.cloneDeep(state.contentBlock.data);
        let historyCopy = _.cloneDeep(state.history);

        if (historyCopy.length == HISTORY_LIMIT) {
            historyCopy = historyCopy.slice(1, historyCopy.length);
        }

        historyCopy = [...state.history, dataCopy];
        return { ...stateNew, history: historyCopy };
    }


    switch (action.type) {
        case actionTypes.setContentBlock: {
            return { ...state, contentBlock: action.payload };
        }
        case actionTypes.setRowsToShow: {
            return { ...state, rowsToShow: action.payload };
        }
        case actionTypes.setColumnWidths: {
            return { ...state, columnWidths: action.payload };
        }
        case actionTypes.setSelectedEdges: {
            return { ...state, selectedEdges: action.payload };
        }
        case actionTypes.setFirstCell: {
            return { ...state, firstCell: action.payload };
        }
        case actionTypes.setCurrentCell: {
            return { ...state, currentCell: action.payload };
        }
        case actionTypes.resetState: {
            return INITIAL_STATE;
        }
        case actionTypes.setHistory: {
            return { ...state, history: action.payload };
        }
        case actionTypes.setState: {
            return setHistoryStateWrapper({ ...state, contentBlock: { ...state.contentBlock, data: action.payload }, });
        }
        case actionTypes.undo: {
            if (state.history.length > 0) {
                let prevState = state.history[state.history.length - 1];
                return { ...state, contentBlock: { ...state.contentBlock, data: prevState }, history: state.history.slice(0, -1) };
            } else return state;
        }
        case actionTypes.updateColumn: {
            let columns = _.cloneDeep(state.contentBlock.data.columns);
            let columnWidths = _.cloneDeep(state.columnWidths);

            let newColKey = action.payload.name.toLowerCase().replace(" ", "_");
            let oldKey;

            columns = columns.map(el => {
                if ((el.id != -1 && el.id == action.payload.colId) || (el.generatedId != null && el.generatedId == action.payload.generatedColId)) {
                    oldKey = el.key;
                    return { ...el, name: action.payload.name, key: newColKey, type: action.payload.type }
                }
                return el;
            })

            if (oldKey && oldKey != newColKey) {
                columnWidths[newColKey] = columnWidths[oldKey];
                delete columnWidths[oldKey];
            }


            return {
                ...state,
                contentBlock: {
                    ...state.contentBlock,
                    data: {
                        ...state.contentBlock.data,
                        columns: columns,
                    },
                },
                columnWidths: columnWidths,
            }
        }
        case actionTypes.updateCell: {
            let updatedRows = state.contentBlock.data.rows.map((el, i) => {
                if ((el.rowId != null && el.rowId == action.payload.rowId) || (el.generatedRowId != null && el.generatedRowId == action.payload.generatedRowId)) {
                    let cellExists = el.cells.find(el => (action.payload.colId != -1 && el.colId == action.payload.colId) || (action.payload.generatedColId != null && el.generatedColId == action.payload.generatedColId));
                    if (!cellExists) {
                        return {
                            ...el,
                            cells: [...el.cells, {
                                generatedId: create_UUID(),
                                colId: action.payload.colId,
                                generatedColId: action.payload.generatedColId,
                                rowOrder: el.rowId,
                                generatedRowOrder: el.generatedRowId,
                                cellValue: action.payload.val,
                            }]
                        }
                    }
                    return {
                        ...el,
                        cells: el.cells.map(celEl => {
                            if ((action.payload.colId != -1 && celEl.colId == action.payload.colId) || (action.payload.generatedColId != null && celEl.generatedColId == action.payload.generatedColId)) {
                                return { ...celEl, cellValue: action.payload.val }
                            } return celEl;
                        })
                    }

                } else return el;
            })
            return setHistoryStateWrapper({ ...state, contentBlock: { ...state.contentBlock, data: { ...state.contentBlock.data, rows: updatedRows } } });
        }
        case actionTypes.addRow: {
            let rowUID = create_UUID();
            return setHistoryStateWrapper({
                ...state,
                contentBlock: {
                    ...state.contentBlock, data: {
                        ...state.contentBlock.data, rows: [...state.contentBlock.data.rows, {
                            cells: state.contentBlock.data.columns.map(el => {
                                return {
                                    generatedId: create_UUID(),
                                    colId: el.id,
                                    generatedColId: el.generatedId,
                                    generatedRowOrder: rowUID,
                                    cellValue: "",
                                }
                            }), rowOrder: 33, generatedRowId: rowUID
                        }]
                    }
                },
                rowsToShow: {
                    ...state.rowsToShow,
                    bottom:
                        state.rowsToShow.bottom == state.contentBlock.data.rows.length && (state.rowsToShow.bottom - state.rowsToShow.top <= 16) ? state.rowsToShow.bottom + 1 : state.rowsToShow.bottom,
                },
            });
        }
        case actionTypes.addColumn: {
            let colUid = create_UUID();
            return setHistoryStateWrapper({
                ...state,
                contentBlock: {
                    ...state.contentBlock,
                    data: {
                        ...state.contentBlock.data,
                        columns: [
                            ...state.contentBlock.data.columns,
                            {
                                id: -1,
                                generatedId: colUid,
                                key: action.payload.colName,
                                name: action.payload.colName,
                                type: action.payload.colType,
                            },
                        ],
                    },
                },
                columnWidths: { ...state.columnWidths, [action.payload.colName]: getColWidthByType(action.payload.colType) },
            });
        }
        case actionTypes.deleteColumn: {
            let columnWidthsNew = _.cloneDeep(state.columnWidths);
            delete columnWidthsNew[action.payload.key]

            return setHistoryStateWrapper({
                ...state,
                contentBlock: {
                    ...state.contentBlock,
                    data: {
                        columns: state.contentBlock.data.columns.filter(el => el.id != action.payload.id),
                        rows: state.contentBlock.data.rows.map(el => {
                            delete el[action.payload.id];
                            return el;
                        }),
                        deleteColumnsIds: state.contentBlock.data.deleteColumnsIds ? [...state.contentBlock.data.deleteColumnsIds, action.payload.id] : [action.payload.id],
                    },
                },
                columnWidths: columnWidthsNew,
            });
        }
        case actionTypes.pasteMultipleValuesInRow: {
            let elements = action.payload.e ? action.payload.e.clipboardData.getData('Text').split('\n') : action.payload.value.split('\n');

            let dataTemp = _.cloneDeep(state.contentBlock.data);
            let row = action.payload.row;
            let col = action.payload.col;

            for (let i = 0; i < elements.length; i++) {
                if (row + i >= dataTemp.rows.length) {
                    dataTemp.rows = [...dataTemp.rows, {}];
                }

                let colElements = elements[i].split('\t');

                if (colElements.length <= 1 && elements.length <= 1) {
                    return state;
                }
                if (action.payload.e) {
                    action.payload.e.preventDefault();
                }

                for (let j = 0; j < colElements.length; j++) {
                    if (col + j >= dataTemp.columns.length) {
                        break;
                    }

                    let colId = dataTemp.columns[col + j].id;
                    let generatedColId = dataTemp.columns[col + j].generatedId;

                    dataTemp = {
                        columns: dataTemp.columns,
                        rows: dataTemp.rows.map((el, indx) => {
                            if (indx == row + i) {
                                let currentCell = el.cells.find(el => (el.colId != -1 && el.colId == colId) || (el.generatedColId != null && el.generatedColId == generatedColId));
                                if (currentCell) {
                                    currentCell.cellValue = colElements[j];
                                } else return {
                                    ...el,
                                    cells: [...el.cells, {
                                        generatedId: create_UUID(),
                                        colId: colId,
                                        generatedColId: generatedColId,
                                        rowOrder: el.rowId,
                                        generatedRowOrder: el.generatedRowId,
                                        cellValue: colElements[j],
                                    }]

                                }
                            }
                            return el;
                        }),
                    };
                }
            }
            return setHistoryStateWrapper({
                ...state,
                contentBlock: {
                    ...state.contentBlock,
                    data: {
                        ...state.contentBlock.data,
                        columns: dataTemp.columns,
                        rows: dataTemp.rows,
                    },
                },
            });
        }
        case actionTypes.setColWidthTo: {
            return {
                ...state,
                columnWidths: {
                    ...state.columnWidths,
                    [action.payload.key]: action.payload.val,
                },
            };
        }
        case actionTypes.addImages: {
            let dataTemp = _.cloneDeep(state.contentBlock.data);

            for (let i = 0; i < action.payload.images.length; i++) {
                dataTemp.rows = dataTemp.rows.map((el, indx) => {
                    if (indx == action.payload.row + i) el.cells.find(el => ((el.colId == action.payload.colName) || (el.generatedColId == action.payload.colName))).cellValue = action.payload.images[i]['urlRatio1x1'];
                    return el;
                });
            }
            return setHistoryStateWrapper({
                ...state,
                contentBlock: {
                    ...state.contentBlock,
                    data: {
                        ...state.contentBlock.data,
                        columns: dataTemp.columns,
                        rows: dataTemp.rows,
                    },
                },
            });
        }
        case actionTypes.removeImage: {
            let dataTemp = _.cloneDeep(state.contentBlock.data);

            dataTemp.rows = dataTemp.rows.map((el, indx) => {
                if (indx == action.payload.row) el.cells.find(el => (el.colId == action.payload.colName) || (el.generatedColId == action.payload.colName)).cellValue = '';
                return el;
            });
            return setHistoryStateWrapper({
                ...state,
                contentBlock: {
                    ...state.contentBlock,
                    data: {
                        ...state.contentBlock.data,
                        columns: dataTemp.columns,
                        rows: dataTemp.rows,
                    },
                },

            });
        }

        default: {
            throw new Error(`Unhandled action ${action.type}`);
        }
    }
};
