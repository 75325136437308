import React, { useState } from 'react';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { updateFeedbackResolverComment } from 'redux/actions/feedback.actions';
import { useTranslation } from 'react-i18next';

export function FeedbackResolveDialogContent({ text }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const feedbackViewState = useSelector(state => state.content.feedbackView);

    const [comment, setComment] = useState(feedbackViewState?.data?.resolverComment ? feedbackViewState?.data?.resolverComment : '');

    return (
        <div>
            <p>{t(text)}</p>
            <textarea
                value={comment}
                onChange={event => {
                    const value = event.target.value;
                    setComment(value);

                    setTimeoutLastExecute.addCallback(
                        () => {
                            dispatch(updateFeedbackResolverComment(value));
                        },
                        50,
                        `feedbackComment`
                    );
                }}
                cols={50}
                rows={3}
                style={{ marginTop: 10 }}
                placeholder={t('Write comment...')}
            />
        </div>
    );
}
