export const contentBlockColumnTypes = [
    {
        value: 'text',
        label: 'Textual',
    },
    {
        value: 'numerical',
        label: 'Numerical',
    },
    {
        value: 'categorical',
        label: 'Categorical',
    },
    {
        value: 'image',
        label: 'Images',
    },
];

export function getColWidthByType(colType) {
    switch (colType) {
        case 'text':
            return 200;
        case 'numerical':
            return 100;
        case 'image':
            return 80;
        default:
            return 200;
    }
}
