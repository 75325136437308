import React from 'react';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { H4 } from 'style/typography/Heading';
import GenerationStatus from './generation_status/GenerationStatus';
import { Trans, useTranslation } from 'react-i18next';

export default function CheckAndGenerate() {
    const { t } = useTranslation();

    return (
        <div className="generation-tabs--container">
            <div className="generation-tabs--items">
                <H4 color={colors.dark} weight="light" style={{ padding: '20px 0' }}>
                    {t('Now, let us do our magic')}
                </H4>
                <Body2 color={colors.dark} weight="light">
                    <Trans>
                        {t(
                            'The process of converting your file to a new training may take a little while, <br /> and it can run in the background. We will notify you when it is ready.'
                        )}
                    </Trans>
                </Body2>
                <GenerationStatus />
            </div>
        </div>
    );
}
