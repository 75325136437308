import { tokenHelper } from 'services/auth/tokenHelpers';
import { refreshProviderToken } from 'redux/actions/auth.actions';
import { LOGIN, LOGOUT, STORAGE_PERSIST } from 'redux/constants/auth.constants';
import { nonAuthRequiredConsts } from 'redux/constants/users.constants';

export default function tokenMiddleware({ dispatch, getState }) {
    return next => async action => {
        let goNext = false;
        nonAuthRequiredConsts.forEach(c => {
            let actionStr = String(action);
            if (action.type) {
                actionStr = action.type;
            }

            if (actionStr.includes(c)) {
                goNext = true;
            }
        });
        if (goNext) {
            return next(action);
        }
        if (
            (action.type && action.type.includes('persist')) ||
            (action.type && action.type.includes(LOGIN.entity)) ||
            (action.type && action.type.includes(STORAGE_PERSIST.entity)) ||
            (action.type && action.type.includes(LOGOUT.entity))
        ) {
            return next(action);
        }

        tokenHelper.isAccessTokenExpired().then(async flag => {
            const store = getState();

            if (flag && !store.auth?.data?.fetchingToken) {
                refreshProviderToken(dispatch).then(() => {
                    return next(action);
                });
            } else if (flag && store.auth?.data?.fetchingToken) {
                try {
                    store.auth.data.fetchingToken.then(() => {
                        return next(action);
                    });
                } catch (e) {
                    refreshProviderToken(dispatch).then(() => {
                        return next(action);
                    });
                }
            } else {
                return next(action);
            }
        });
    };
}
