export const colors = {
    mainTheme: '#FEBE10', // zolta
    mainThemeFaded: 'rgb(254, 249, 237)', // zolta
    background: 'rgb(244, 244, 243)',
    pauseScreenBackground: '#323232', //'rgba(50, 50, 50, 0.8)',
    dark: '#323232',
    darkWithOpacity1: 'rgba(50, 50, 50, 0.55)', // Vrz zoltata, sometimes
    darkWithOpacity2: 'rgba(50, 50, 50, 0.15)', // Vrz zoltata posvetla, sometimes
    disabled: '#C9C9C8',
    primaryText: '#5c5b5a',
    secoundaryText: '#6b6968',
    descriptionText: '#8F8E8D',
    red: '#C1272D',
    errorRed: 'rgb(246, 67, 55)',
    gray1: '#B0B0AF', // games unselected, how to play incons/shapes, dividers
    gray2: '#E0DFDF',
    gray3: 'rgb(235, 235, 235)',
    gray4: 'rgb(249, 249, 249)',
    gray5: 'rgb(224, 224, 224)',
    gray6: 'rgb(143, 143, 143)',
    underlineColor: 'rgb(146, 146, 146)',
    disabledColor: 'rgb(208, 208, 208)',
    white: '#ffffff',
    transparent: 'rgba(50, 50, 50, 0)',
    white_transparent: 'rgba(255, 255, 255, 0.5)',
    success: 'rgb(75, 201, 101)',
};

function createColorStyles(type) {
    let obj = {};
    for (let color in colors) {
        if (colors.hasOwnProperty(color)) {
            if (type === 'background') {
                obj[`${color}`] = { backgroundColor: `${colors[color]}` };
            } else if (type === 'color') {
                obj[`${color}`] = { color: `${colors[color]}` };
            }
        }
    }
    return obj;
}

// export const backgroundColorStyles = StyleSheet.create(createColorStyles('background'));

// export const textColorStyles = StyleSheet.create(createColorStyles('color'));
