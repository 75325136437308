export const fullUserObject = {
    name: '',
    lastName: '',
    isActive: '',
    company: '',
    email: '',
    companyRole: {},
    status: '',
};

export const defaultUserData = {
    name: '',
    lastName: '',
    status: '',
    companyRole: '',
    department: '',
    authRole: '',
    email: '',
    trainings: [],
    totalGameplays: -1,
};

export const defaultUserDataEditing = {
    name: '',
    lastName: '',
    status: '',
    companyRole: '',
    authRole:'',
    department: '',
    email: '',
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
    trainings: [],
    totalGameplays: -1,
};
