import React, { useCallback, useEffect, useState } from 'react';
import { OutlinedButton } from 'components/elements/Button';
import { monthNames } from 'static/months';

import AngleLeft from 'assets/icons/angle-left.svg';
import AngleRight from 'assets/icons/angle-right.svg';
import { useDispatch, useSelector } from 'react-redux';
import { downloadActiveUsersForMonthAction, getActiveUsersCountForMonthAction } from 'redux/actions/users.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { H6 } from 'style/typography/Heading';

import ExportIcon from 'assets/icons/export-button.svg';
import { useTranslation } from 'react-i18next';

export default function ActiveUsersPerMonth() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activeUsersCountForMonthState = useSelector(state => state.userManagement.activeUsersCountForMonth);

    const [dateRange, setDateRange] = useState([]);
    const [offset, setOffset] = useState(0);
    const [activeUsers, setActiveUsers] = useState(0);

    function getFirstAndLastDayOfMonth(offset) {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth() + offset, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1 + offset, 0, 23, 59, 59);

        if (lastDay > date) {
            lastDay = date;
        }

        if (firstDay && lastDay) {
            dispatch(getActiveUsersCountForMonthAction(firstDay.getTime(), lastDay.getTime()));
        }
        setDateRange([firstDay, lastDay]);
    }

    const isFirstMonth = useCallback(() => {
        var date = new Date();
        var prevMonth = new Date(date.getFullYear(), date.getMonth() + offset - 1, 1);
        if (prevMonth.getFullYear() < date.getFullYear()) return true;

        return false;
    }, [offset]);

    const getPreviousMonth = () => {
        if (isFirstMonth()) return;
        setOffset(prevState => prevState - 1);
        getFirstAndLastDayOfMonth(offset - 1);
    };

    const getNextMonth = useCallback(() => {
        var date = new Date();
        if (dateRange.length == 0 || dateRange[1].getMonth() == date.getMonth()) return;

        setOffset(prevState => prevState + 1);
        getFirstAndLastDayOfMonth(offset + 1);
    }, [dateRange]);

    const exportUsersForDateRange = useCallback(() => {
        dispatch(downloadActiveUsersForMonthAction(dateRange[0].getTime(), dateRange[1].getTime()));
    }, [dateRange]);

    useEffect(() => {
        if (dateRange.length > 0) {
            dispatch(getActiveUsersCountForMonthAction(dateRange[0].getTime(), dateRange[1].getTime()));
        }
        getFirstAndLastDayOfMonth(offset);
    }, []);

    useEffect(() => {
        if (stateIsLoaded(activeUsersCountForMonthState)) {
            setActiveUsers(activeUsersCountForMonthState.data.count);
        }
    }, [activeUsersCountForMonthState]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', margin: '10px 0' }}>
            <div>
                <H6 color={colors.dark}>{activeUsers}</H6>
                <Caption1 color={colors.gray6}>{t('active in month')} </Caption1>
            </div>
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <img
                        alt="Previous"
                        src={AngleLeft}
                        height={20}
                        width={20}
                        style={{ cursor: 'pointer', userSelect: 'none' }}
                        onClick={getPreviousMonth}
                    />
                    {dateRange.length > 0 && (
                        <Caption1
                            style={{
                                width: '90px',
                                maxWidth: '90px',
                            }}
                            color={colors.gray6}
                        >
                            {`${dateRange[0].getDate()} ${monthNames[dateRange[0].getMonth()]} - ${dateRange[1].getDate()} ${
                                monthNames[dateRange[1].getMonth()]
                            }`}
                        </Caption1>
                    )}
                    <img
                        alt="Next"
                        src={AngleRight}
                        height={20}
                        width={20}
                        style={{ cursor: 'pointer', userSelect: 'none' }}
                        onClick={getNextMonth}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '12px' }}>
                    <OutlinedButton onClick={exportUsersForDateRange}>
                        <Caption1 color={colors.gray6}>{t('Export users')}</Caption1>
                        <img alt="Expot" src={ExportIcon} height={20} width={20} style={{ paddingLeft: '5px' }} />
                    </OutlinedButton>
                </div>
            </div>
        </div>
    );
}
