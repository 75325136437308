export const feedbackTypeOptions = [
    {
        value: 'CONTENT',
        label: 'Content',
    },
    {
        value: 'GAME',
        label: 'Game',
    },
    {
        value: 'OTHER',
        label: 'Other',
    },
];
export const newsStatusOptions = [
    {
        value: 'Active',
        label: 'Active',
    },
    {
        value: 'Scheduled',
        label: 'Scheduled',
    },
    {
        value: 'Expired',
        label: 'Expired',
    },
];

export const userStatusOptions = [
    {
        value: 'active',
        label: 'Active',
    },
    {
        value: 'inactive',
        label: 'Inactive',
    },
    {
        value: 'pending',
        label: 'Pending',
    },
];

export const feedbackStatusOptions = [
    {
        value: 'true',
        label: 'Resolved',
    },
    {
        value: 'false',
        label: 'Unresolved',
    },
];

export const questionStatusOptions = [
    {
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        value: 'INACTIVE',
        label: 'Inactive',
    },
    {
        value: 'DRAFT',
        label: 'Draft',
    },
];

export const userDeviceStatusOptions = [
    {
        value: 'true',
        label: 'Resolved',
    },
    {
        value: 'false',
        label: 'Pending',
    },
];
