import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { truncateString } from 'services/utils/stringHelpers';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import './LoaderInfoBox.css';

import AIIcon from 'assets/icons/ai-training-wand-orange.svg';
import Close from 'assets/icons/filter-close.svg';
import { useTranslation } from 'react-i18next';

export default function LoaderInfoBox({ onMouseEnter, onMouseLeave, onClose }) {
    const history = useHistory();
    const { t } = useTranslation();

    const generatingContentProgressState = useSelector(state => state.content.generatingContentProgress);

    const currentGeneratingContentProgress = generatingContentProgressState?.data?.progress;
    const currentGeneratingContentStatus = generatingContentProgressState?.data?.currentProcess;
    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="loader-box--container">
            <div className="loader-box--arrow"></div>
            <div style={{ padding: 5 }}>
                <div className="loader-box--title">
                    <Body2 weight="medium" color={colors.dark}>
                        {t('Ongoing Process')}
                    </Body2>
                    <img
                        alt="Close"
                        src={Close}
                        height={18}
                        width={18}
                        style={{ position: 'absolute', right: 5, cursor: 'pointer' }}
                        onClick={onClose}
                    />
                </div>
                <div className="loader-box--status-container">
                    <Caption1 color={colors.dark}>{truncateString(t(currentGeneratingContentStatus), 30, '...')}</Caption1>
                    <div className="loader-box--status">
                        <div className="loader-box--status-fill" style={{ width: `${currentGeneratingContentProgress}%` }}></div>
                    </div>
                    <Caption1 weight="medium" color={colors.dark}>
                        {currentGeneratingContentProgress}%
                    </Caption1>
                </div>
                <div className="loader-box--button" onClick={() => history.replace('/ai_training')}>
                    <img alt="Wand" src={AIIcon} height={18} width={18} />
                    <Caption1 color={colors.dark}>{t('Go to the AI section')}</Caption1>
                </div>
            </div>
        </div>
    );
}

LoaderInfoBox.propTypes = {
    marginTop: PropTypes.number,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    options: PropTypes.array,
    position: PropTypes.string,
};
