import { Action } from '../core/Action';

// ====== TRAINING STATES ====== //
export const UPDATE_TRAINING_SETTINGS = { entity: 'UPDATE_TRAINING_SETTINGS', action: Action.UPDATE };
export const TRAININGS_INFO = { entity: 'TRAININGS_INFO', action: Action.GET_MANY };
export const SINGLE_TRAINING = { entity: 'SINGLE_TRAINING', action: Action.GET };
export const ALL_TRAININGS = { entity: 'ALL_TRAININGS', action: Action.GET };
export const TRAINING_TREE_SAVING_ERRORS = { entity: 'TRAINING_TREE_SAVING_ERRORS', action: Action.HANDLE };
export const CREATE_NEW_TRAINING = { entity: 'CREATE_NEW_TRAINING', action: Action.CREATE };
export const SAVE_TRAINING_MODULE_LEARNING_SET = { entity: 'SAVE_TRAINING_MODULE_LEARNING_SET', action: Action.UPDATE };
export const GET_ALL_TRAINING_MODULES_COMPANY = { entity: 'GET_ALL_TRAINING_MODULES_COMPANY', action: Action.GET_MANY };
export const GET_TRAINING_MODULE_COMPANY = { entity: 'GET_TRAINING_MODULE_COMPANY', action: Action.GET };
export const DELETE_TRAINING_MODULE = { entity: 'DELETE_TRAINING_MODULE', action: Action.GET };
export const INITIATE_NODE_REMOVAL = { entity: 'INITIATE_NODE_REMOVAL', action: Action.HANDLE };
export const UPLOADING_TRAINING_PROGRESS = { entity: 'UPLOADING_TRAINING_PROGRESS', action: Action.GET };
export const UPLOAD_TRAINING = { entity: 'UPLOAD_TRAINING', action: Action.HANDLE };

// ====== TOPIC STATES ====== //
export const GET_ALL_TOPICS_FROM_COMPANY = { entity: 'ALL_TOPICS_FROM_COMPANY', action: Action.GET_MANY };
export const GET_ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO = { entity: 'ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO', action: Action.GET_MANY };
export const HANDLE_OLD_ALL_TOPICS_FROM_COMPANY = { entity: 'OLD_ALL_TOPICS_FROM_COMPANY', action: Action.HANDLE };
export const HANDLE_TOPICS_TREE = { entity: 'TOPICS_TREE', action: Action.HANDLE };
export const UPDATE_TOPICS_TREE = { entity: 'UPDATE_TOPICS_TREE', action: Action.UPDATE };
export const UPDATE_TOPIC = { entity: 'UPDATE_TOPIC', action: Action.UPDATE };
export const UPDATE_MULTIPLE_TOPICS = { entity: 'UPDATE_MULTIPLE_TOPICS', action: Action.UPDATE };
export const LOCAL_TOPICS_FOLDER_STRUCTURE_CHANGES = { entity: 'TOPICS_FOLDER_STRUCTURE', action: Action.UPDATE };
export const GET_SINGLE_TOPIC_STATS = { entity: 'GET_TRAINING_STATISTICS', action: Action.GET };
export const GET_TOPICS_FOLDER_STRUCTURE = { entity: 'GET_TOPICS_FOLDER_STRUCTURE', action: Action.GET_MANY };
export const HANDLE_OLD_TOPICS_FOLDER_STRUCTURE = { entity: 'TOPICS_FOLDER_STRUCTURE', action: Action.HANDLE };
export const GET_TOPIC_CONTENT = { entity: 'GET_TOPIC_CONTENT', aciton: Action.GET_MANY };
export const GET_LOCAL_TOPIC_CHANGES = { entity: 'GET_LOCAL_TOPIC_CHANGES', aciton: Action.GET_MANY };
export const DELETE_TRAINING = { entity: 'DELETE_TRAINING', action: Action.DELETE };
export const TOPICS_FROM_TRAINING = { entity: 'TOPICS_FROM_TRAINING', action: Action.GET_MANY };
export const UPDATE_TOPICS_FOLDER_STRUCTURE = { entity: 'UPDATE_TOPICS_FOLDER_STRUCTURE', action: Action.UPDATE };
export const UPDATE_TOPIC_SETTINGS = { entity: 'UPDATE_TOPIC_SETTINGS', action: Action.UPDATE };
export const UPDATE_SAVED_TOPIC_INDEX = { entity: 'UPDATE_SAVED_TOPIC_INDEX', action: Action.UPDATE };

// ====== QUESTIONS STATES ====== //
export const GET_QUESTIONS_FROM_COMPANY = { entity: 'GET_QUESTIONS_FROM_COMPANY', action: Action.GET_MANY };
export const GET_MINIMIZED_QUESTIONS_FROM_COMPANY = { entity: 'GET_MINIMIZED_QUESTIONS_FROM_COMPANY', action: Action.GET_MANY };
export const GET_QUESTIONS_PAGINATED = { entity: 'GET_QUESTIONS_PAGINATED', action: Action.GET_MANY };

export const GET_QUESTIONS_INDEXES = { entity: 'GET_QUESTIONS_INDEXES', action: Action.GET_MANY };

export const GET_QUESTIONS_FOR_CATEGORY = { entity: 'GET_QUESTIONS_FOR_CATEGORY', action: Action.HANDLE };
export const CHANGE_QUESTION_STATUS = { entity: 'CHANGE_QUESTION_STATUS', action: Action.HANDLE };
export const REMOVE_QUESTION_FROM_TOPIC = { entity: 'REMOVE_QUESTION_FROM_TOPIC', action: Action.DELETE };
export const GET_QUESTION = { entity: 'GET_QUESTION', action: Action.GET };
export const INSERT_QUESTION = { entity: 'INSERT_QUESTION', action: Action.CREATE };
export const UPDATE_MULTIPLE_QUESTIONS_TOPICS = { entity: 'UPDATE_MULTIPLE_QUESTIONS_TOPICS', action: Action.UPDATE };
export const EDITING_QUESTIONS_LIST = { entity: 'EDITING_QUESTIONS_LIST', action: Action.HANDLE };
export const EDIT_QUESTIONS_BULK = { entity: 'EDIT_QUESTIONS_BULK', action: Action.UPDATE };
export const PRE_BULK_ACTION_QUESTIONS = { entity: 'PRE_BULK_ACTION_QUESTIONS', action: Action.GET };
export const GET_QUESTIONS_USED_IN_TOPIC = { entity: 'GET_QUESTIONS_USED_IN_TOPIC', aciton: Action.GET_MANY };
export const GET_QUESTIONS_UNUSED_IN_TOPIC = { entity: 'GET_QUESTIONS_UNUSED_IN_TOPIC', aciton: Action.GET_MANY };
export const GET_QUESTIONS_UNUSED_IN_COMPANY = { entity: 'GET_QUESTIONS_UNUSED_IN_COMPANY', aciton: Action.GET_MANY };
export const UPLOAD_CONTENT = { entity: 'UPLOAD_CONTENT', action: Action.HANDLE };
export const UPLOAD_CONTENT_PROGRESS = { entity: 'UPLOAD_CONTENT_PROGRESS', action: Action.HANDLE };
export const CONTENT_ACTIVE_TAB = { entity: 'CONTENT_ACTIVE_TAB', action: Action.HANDLE };
export const EXPORT_QUESTIONS_PROGRESS = { entity: 'EXPORT_QUESTIONS_PROGRESS', action: Action.GET };

// ====== IMAGE STATES ====== //
export const DELETE_IMAGE_FOLDER = { entity: 'DELETE_IMAGE_FOLDER', action: Action.DELETE };
export const MOVE_IMAGES_TO_FOLDER = { entity: 'MOVE_IMAGES_TO_FOLDER', action: Action.UPDATE };
export const POST_IMAGE = { entity: 'POST_IMAGE', action: Action.CREATE };
export const SAVE_IMAGE_TO_FOLDER = { entity: 'SAVE_IMAGE_TO_FOLDER', action: Action.CREATE };
export const UPDATE_IMAGE = { entity: 'UPDATE_IMAGE', action: Action.CREATE };
export const GET_IMAGES_FROM_FOLDER = { entity: 'GET_IMAGES_FROM_FOLDER', action: Action.HANDLE };
export const GET_ALL_IMAGES = { entity: 'GET_ALL_IMAGES', action: Action.GET_MANY };
export const GET_IMAGES_COUNT = { entity: 'GET_IMAGES_COUNT', action: Action.GET_MANY };
export const GET_UNCATEGORIZED_IMAGES = { entity: 'GET_UNCATEGORIZED_IMAGES', action: Action.GET_MANY };
export const IMAGES_DELETE_SOFT = { entity: 'IMAGES_DELETE_SOFT', action: Action.GET_MANY };
export const IMAGES_DELETE_FORCE = { entity: 'IMAGES_DELETE_FORCE', action: Action.GET_MANY };
export const SAVE_IMAGES_TO_FOLDER = { entity: 'SAVE_IMAGES_TO_FOLDER', action: Action.CREATE };
export const GET_FOLDER_STRUCTURE = { entity: 'GET_FOLDER_STRUCTURE', action: Action.HANDLE };
export const SAVE_FOLDER_STRUCTURE = { entity: 'SAVE_FOLDER_STRUCTURE', action: Action.UPDATE };

// ====== USER STATES ====== //
export const HANDLE_USERS_INVITE = { entity: 'HANDLE_USERS_INVITE', action: Action.HANDLE };
export const HANDLE_USERS_INVITE_MANUAL = { entity: 'HANDLE_USERS_INVITE_MANUAL', action: Action.HANDLE };

// ====== FEEDBACK STATES ====== //
export const GET_FEEDBACK = { entity: 'GET_FEEDBACK', action: Action.GET_MANY };
export const GET_FEEDBACK_CONTENT_PAGINATED = { entity: 'GET_FEEDBACK_CONTENT_PAGINATED', action: Action.GET_MANY };
export const GET_FEEDBACK_POSITIVE_PAGINATED = { entity: 'GET_FEEDBACK_POSITIVE_PAGINATED', action: Action.GET_MANY };
export const UPDATE_FEEDBACK = { entity: 'UPDATE_FEEDBACK', action: Action.HANDLE };
export const DELETE_FEEDBACK = { entity: 'DELETE_FEEDBACK', action: Action.DELETE };
export const RESOLVE_FEEDBACK_BULK = { entity: 'RESOLVE_FEEDBACK_BULK', action: Action.UPDATE };
export const UNRESOLVE_FEEDBACK_BULK = { entity: 'UNRESOLVE_FEEDBACK_BULK', action: Action.UPDATE };
export const DELETE_FEEDBACK_BULK = { entity: 'DELETE_FEEDBACK_BULK', action: Action.DELETE };
export const GET_SPECIFIC_FEEDBACK = { entity: 'GET_SPECIFIC_FEEDBACK', action: Action.GET };
export const PRE_BULK_ACTION_FEEDBACK = { entity: 'PRE_BULK_ACTION_FEEDBACK', action: Action.GET };

// ====== STATISTIC STATES ====== //
export const GET_DASHBOARD_STATISTICS = { entity: 'GET_DASHBOARD_STATISTICS', action: Action.HANDLE };
export const GET_GLOBAL_STATISTICS = { entity: 'GET_GLOBAL_STATISTICS', action: Action.HANDLE };
export const GET_TRAINING_STATISTICS = { entity: 'GET_TRAINING_STATISTICS', action: Action.GET_MANY };

// ====== TAGS STATES ====== //
export const UPDATE_LABELS = { entity: 'UPDATE_LABELS', action: Action.UPDATE };
export const GET_TAGS = { entity: 'GET_TAGS', action: Action.GET };
export const UPLOAD_USER_TAGS = { entity: 'UPLOAD_USER_TAGS', action: Action.CREATE };
export const UPLOAD_USER_TAGS_PROGRESS = { entity: 'UPLOAD_USER_TAGS_PROGRESS', action: Action.HANDLE };
export const CREATE_TAG = { entity: 'CREATE_TAG', action: Action.UPDATE };
export const GET_LABELS = { entity: 'GET_LABELS', action: Action.GET };
export const GET_LABEL_BY_ID = { entity: 'GET_LABEL_BY_ID', action: Action.GET };
export const DELETE_LABEL_BY_ID = { entity: 'DELETE_LABEL_BY_ID', action: Action.UPDATE };
export const DELETE_TAG_BY_ID = { entity: 'DELETE_TAG_BY_ID', action: Action.UPDATE };
export const GET_USERS_WITH_TAG = { entity: 'GET_USERS_WITH_TAG', action: Action.GET };
export const GET_USERS_WITHOUT_TAG_WITH_ID = { entity: 'GET_USERS_WITHOUT_TAG_WITH_ID', action: Action.GET };
export const ADD_USER_TO_TAG = { entity: 'ADD_USER_TO_TAG', action: Action.POST };
export const REMOVE_USER_FROM_TAG = { entity: 'REMOVE_USER_FROM_TAG', action: Action.Action };
export const ADD_USERS_TO_TAGS = { entity: 'ADD_USERS_TO_TAGS', actions: Action.POST };
export const REMOVE_USERS_FROM_TAGS = { entity: 'REMOVE_USERS_FROM_TAGS', actions: Action.POST };

// ====== NOTIFICATION STATES ====== //
export const GET_ALL_NOTIFICATIONS_PAGINATED = { entity: 'GET_ALL_NOTIFICATIONS_PAGINATED', action: Action.GET };
export const CREATE_NEW_NOTIFICATION = { entity: 'CREATE_NEW_NOTIFICATION', action: Action.CREATE };
export const GET_SINGLE_NOTIFICATION = { entity: 'GET_SINGLE_NOTIFICATION', action: Action.GET };
export const DELETE_NOTIFICATION = { entity: 'DELETE_NOTIFICATION', action: Action.DELETE };

// ====== CONTENT BLOCK STATES ====== //
export const SAVE_CONTENT_BLOCK = { entity: 'SAVE_CONTENT_BLOCK', action: Action.UPDATE };
export const DELETE_CONTENT_BLOCK_BY_ID = { entity: 'DELETE_CONTENT_BLOCK_BY_ID', action: Action.UPDATE };
export const GET_CONTENT_BLOCKS_PAGINATED = { entity: 'GET_CONTENT_BLOCKS_PAGINATED', action: Action.GET_MANY };
export const GET_CONTENT_BLOCK_BY_ID = { entity: 'GET_CONTENT_BLOCK_BY_ID', action: Action.GET_MANY };

// ====== GAMES STATES ====== //
export const GET_GAMES = { entity: 'GET_GAMES', action: Action.GET_MANY };
export const GET_ALL_GAMES = { entity: 'GET_ALL_GAMES', action: Action.GET_MANY };
export const GET_GAME_QUSTION_TYPE_VARIATIONS = { entity: 'GET_GAME_QUSTION_TYPE_VARIATIONS', action: Action.HANDLE };
export const DOWNLOAD_GAME_TEMPLATES = { entity: 'DOWNLOAD_GAME_TEMPLATES', action: Action.GET_MANY };
export const EXPORT_GAME_QUESTIONS = { entity: 'EXPORT_GAME_QUESTIONS', action: Action.GET };
export const EXPORT_ALL_QUESTIONS = { entity: 'EXPORT_ALL_QUESTIONS', action: Action.GET };

// ====== NEWS STATES ====== //
export const GET_NEWS = { entity: 'GET_NEWS', action: Action.GET };
export const GET_NEWS_PAGINATED = { entity: 'GET_NEWS_PAGINATED', action: Action.GET };
export const GET_SPECIFIC_NEWS = { entity: 'GET_SPECIFIC_NEWS', action: Action.GET };
export const DELETE_NEWS = { entity: 'DELETE_NEWS', action: Action.DELETE };
export const SAVE_NEWS = { entity: 'SAVE_NEWS', action: Action.CREATE };
export const GET_LATEST_UPDATES = { entity: 'GET_LATEST_UPDATES', action: Action.GET };
export const SET_LATEST_UPDATES_SEEN = { entity: 'SET_LATEST_UPDATES_SEEN', action: Action.UPDATE };

// ===== COMPANY STATES ====== //
export const UPDATE_COMPANY_GLOBAL_CONFIGURATION = { entity: 'UPDATE_COMPANY_GLOBAL_CONFIGURATION', action: Action.UPDATE };
export const COMPANY_UNIT_STRUCTURE = { entity: 'COMPANY_UNIT_STRUCTURE', action: Action.UPDATE };
export const GET_COMPANY_INFO = { entity: 'GET_COMPANY_INFO', action: Action.GET };
export const CREATE_COMPANY_WITH_CODE = { entity: 'CREATE_COMPANY_WITH_CODE', action: Action.HANDLE };

// ===== GENERATE CONTENT STATES ===== //
export const GENERATE_CONTENT = { entity: 'GENERATE_CONTENT', action: Action.CREATE };
export const GENERATING_CONTENT_PROGRESS = { entity: 'GENERATING_CONTENT_PROGRESS', action: Action.GET };
export const GENERATE_CONTENT_FOR_TOPIC = { entity: 'GENERATE_CONTENT_FOR_TOPIC', action: Action.CREATE };
