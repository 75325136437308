import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import { colors } from 'style/colors';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoading } from 'redux/core/stateHelpers';
import { changeNodeAtPath } from 'react-sortable-tree';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { Caption1 } from 'style/typography/Caption';
import { Body2 } from 'style/typography/Body';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { updateAllTopicsAction, updateSingleTrainingAction, updateTopicActionsType } from 'redux/actions/trainings.actions';
import { CustomDatePicker } from 'components/elements/datepicker/CustomDatePicker';
import { Subtitle1 } from 'style/typography/Subtitle';

import _ from 'lodash';

import Close from 'assets/icons/filter-close.svg';
import CustomModal from 'components/features/modal/CustomModal.js/CustomModal';
import { useTranslation } from 'react-i18next';

export function TopicSettingsDialog({ modalOpen, trainingId, onModalClose, data, path, node }) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const updateTopicSettingsState = useSelector(state => state.content.topics.updateTopicSettings);

    const [newTitle, setNewTitle] = useState('');
    const [newAvailableFrom, setNewAvailableFrom] = useState('');

    useEffect(() => {
        setNewTitle(node?.titleForTraining);
        setNewAvailableFrom(node?.dateAvailableFrom ? new Date(node.dateAvailableFrom) : '');
    }, [node]);

    const updateTopic = () => {
        const getNodeKey = function(node) {
            return node.node ? node.node.treeIndex : node.treeIndex;
        };

        let dataClone = _.cloneDeep(data);

        dataClone[0].children = changeNodeAtPath({
            treeData: data[0].children,
            path: path,
            newNode: {
                ...node,
                titleForTraining: newTitle,
                title: node.isNew ? newTitle : node.title,
                dateAvailableFrom: newAvailableFrom ? newAvailableFrom.getTime() : null,
            },
            getNodeKey,
        });

        dispatch(
            updateSingleTrainingAction(
                {
                    treeStructure: dataClone,
                },
                400
            )
        );

        if (node.isNew)
            dispatch(
                updateAllTopicsAction(node.treeIndex, updateTopicActionsType.update, {
                    setId: trainingId,
                    title: newTitle,
                    learnersTitle: newTitle,
                })
            );
        else {
            dispatch(
                updateAllTopicsAction(node.treeIndex, updateTopicActionsType.update, {
                    setId: trainingId,
                    learnersTitle: newTitle,
                })
            );
        }

        onModalClose();
    };

    return (
        <CustomModal modalOpen={modalOpen} setModalOpen={_ => onModalClose()}>
            <Paper style={{ width: '40vw', border: `solid 1px ${colors.gray4}` }} className={`${classes.paper}`}>
                <div className="col-12 d-flex flex-row p-3">
                    <div className="d-flex flex-column">
                        <Subtitle1 weight="medium">{t('Settings for topic')}</Subtitle1>
                    </div>
                    <img
                        alt={t('Close')}
                        onClick={() => {
                            onModalClose();
                        }}
                        className={'ml-auto cursor-pointer'}
                        height={17}
                        width={17}
                        src={Close}
                    />
                </div>
                <div className={'col-12 d-flex flex-column'}>
                    <Caption1 color={colors.gray6} className={'mr-2'}>
                        {t('TOPIC TITLE')}
                    </Caption1>
                    <CustomOutlinedTextField
                        width={'100%'}
                        value={newTitle}
                        setValue={setNewTitle}
                        placeholder={t('Enter title...')}
                    ></CustomOutlinedTextField>
                </div>
                <div style={{ width: '100%' }} className="mt-3 col-12">
                    <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-column">
                            <Caption1 color={colors.gray6} className={'mr-2'}>
                                {t('AVAILABLE FROM')}
                            </Caption1>
                            <CustomDatePicker value={newAvailableFrom} onChange={setNewAvailableFrom}></CustomDatePicker>
                        </div>
                        <div className="d-flex flex-column ml-3">
                            <Caption1 color={colors.gray6} className={'mr-2'}>
                                {t('AVAILABLE UNTIL')}
                            </Caption1>
                            <Body2 className={'mt-2'}>{t('End of training')}</Body2>
                        </div>
                    </div>
                </div>
                <div className={'col-12 pr-3 pl-3 d-flex flex-row justify-content-center mt-4 mb-3'}>
                    <DefaultButton
                        id="settings-topic-save-button"
                        text={t('Save Changes')}
                        onClick={updateTopic}
                        disabled={stateIsLoading(updateTopicSettingsState)}
                        className={'mr-1'}
                    />

                    <OutlinedButton
                        id="settings-topic-cancel-button"
                        text={t('Cancel')}
                        onClick={() => {
                            onModalClose();
                        }}
                        disabled={stateIsLoading(updateTopicSettingsState)}
                        className={'ml-1'}
                    />
                    {stateIsLoading(updateTopicSettingsState) && (
                        <CircularProgress className={'ml-2'} style={{ color: colors.mainTheme }} size={25} />
                    )}
                </div>
            </Paper>
        </CustomModal>
    );
}

TopicSettingsDialog.propTypes = {
    data: PropTypes.any,
    modalOpen: PropTypes.any,
    node: PropTypes.any,
    onModalClose: PropTypes.func,
    path: PropTypes.any,
    trainingId: PropTypes.any,
};
const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: colors.gray4,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
        },
    };
});
