import React, { useEffect, useState } from 'react';
import 'style/content.style.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AddQuestionTraffic from './games_questions/Traffic/Traffic';
import { ContentInsertionConsumer, ContentInsertionProvider } from 'services/content-management/insertion-control';
import AddQuestionSortIt from './games_questions/SortIt/SortIt';
import AddQuestionCompare from './games_questions/Compare/Compare';
import AddQuestionNumbers from './games_questions/Numbers/Numbers';
import AddQuestionRecall from './games_questions/Recall/Recall';
import AddQuestionRaceCars from './games_questions/RaceCars/RaceCars';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionById } from 'redux/actions/content.actions';
import { stateHasFailed, stateIsLoaded, stateIsNotReady } from 'redux/core/stateHelpers';
import { Header } from 'containers/layout/module/local_components/header/Header';
import AddQuestionSequence from './games_questions/Sequence/Sequence';
import AddQuestionPinpoint from './games_questions/Pinpoint/Pinpoint';
import { gameQuestionTypeMapper } from 'static/game_constants';
import { Circular } from 'components/features/data_table/Circular';
import '../Questions.css';
import { ContentBlocksBuilderConsumer, ContentBlocksBuilderProvider } from 'services/content-blocks-management/content-blocks-builder';
import { useTranslation } from 'react-i18next';

const UpdateQuestion = () => {
    const { t } = useTranslation();

    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const [questionData, setQuestionData] = useState(null);
    const singleQuestion = useSelector(state => state.content.singleQuestion);
    const categories = useSelector(state => state.content.categories);
    useEffect(() => {
        if (params.questionId) {
            setQuestionData(null);
            dispatch(getQuestionById(params.questionId));
        }
    }, [dispatch, params.questionId]);

    useEffect(() => {
        if (stateIsLoaded(singleQuestion)) {
            let questionState = {
                question: singleQuestion.data.question,
                options: singleQuestion.data.answersList,
                chosenCategoryId: singleQuestion.data.categoryId,
                chosenCategory: singleQuestion.data.category,
                extra: {
                    image: singleQuestion.data.extra.image,
                    criteria: singleQuestion.data.extra.criteria,
                },
                dateAvailableFromMills: new Date(singleQuestion.data.dateAvailableFromMills),
                status: singleQuestion.data.status,
                lastModified: singleQuestion.data.lastModified,
            };
            setQuestionData(questionState);
        }
        if (stateHasFailed(singleQuestion)) {
            history.replace('/content/questions');
        }
    }, [categories, singleQuestion]);

    if (stateIsNotReady(singleQuestion)) {
        return (
            <div className="questions--loader-container">
                <Circular />
            </div>
        );
    }
    let game = singleQuestion.data.gameSlug;
    let gameIcon = singleQuestion.data.gameIcon;

    return (
        <Header title={t(`Question #{{questionId}}`, { questionId: params.questionId })} stateDependencies={[singleQuestion]}>
            <ContentInsertionProvider currentType={gameQuestionTypeMapper[game]} data={questionData}>
                <ContentBlocksBuilderProvider>
                    <div style={{ maxWidth: '100%' }}>
                        {game === 'traffic' && (
                            <AddQuestionTraffic game={game} gameIcon={gameIcon} id={params.questionId} filters={location?.state?.filters} />
                        )}
                        {game === 'sort-it' && (
                            <AddQuestionSortIt game={game} gameIcon={gameIcon} id={params.questionId} filters={location?.state?.filters} />
                        )}
                        {game === 'compare' && (
                            <AddQuestionCompare game={game} gameIcon={gameIcon} id={params.questionId} filters={location?.state?.filters} />
                        )}
                        {game === 'numbers' && (
                            <AddQuestionNumbers game={game} gameIcon={gameIcon} id={params.questionId} filters={location?.state?.filters} />
                        )}
                        {game === 'recall' && (
                            <AddQuestionRecall game={game} gameIcon={gameIcon} id={params.questionId} filters={location?.state?.filters} />
                        )}
                        {game === 'race-cars' && (
                            <AddQuestionRaceCars game={game} gameIcon={gameIcon} id={params.questionId} filters={location?.state?.filters} />
                        )}
                        {game === 'sequence' && (
                            <AddQuestionSequence game={game} gameIcon={gameIcon} id={params.questionId} filters={location?.state?.filters} />
                        )}
                        {game === 'pinpoint' && (
                            <AddQuestionPinpoint game={game} gameIcon={gameIcon} id={params.questionId} filters={location?.state?.filters} />
                        )}
                        <ContentInsertionConsumer />
                        <ContentBlocksBuilderConsumer />
                    </div>
                </ContentBlocksBuilderProvider>
            </ContentInsertionProvider>
        </Header>
    );
};

export default UpdateQuestion;
