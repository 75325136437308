import React from 'react';
import Lottie from 'react-lottie';

import cubeAnimation from './animation_data/CubeAnimation.json';

export function LoadingAnimation() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cubeAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}>
            <Lottie options={defaultOptions} height={400} width={400} />
        </div>
    );
}
