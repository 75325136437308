import { ListItem } from '@material-ui/core';
import { trim } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import '../Navbar.css';

const MenuLink = ({ id, children, to, className, images, menuIcon: MenuIcon }) => {
    const { pathname } = useLocation();
    const pathnameSlug = trim(pathname, '/').split('/')[0] === trim(to, '/').split('/')[0];

    return (
        <Link id={id} to={to} className={className + ' navbar--menulink d-flex flex-column align-items-center justify-content-center '}>
            <ListItem button className="justify-content-center navigation-menu-item">
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    {/* <img className='navbar--menu-img' src={pathnameSlug ? images.active : images.inactive} alt="coach admin" /> */}
                    <MenuIcon
                        style={{
                            fill: 'none',
                            stroke: pathnameSlug ? colors.mainTheme : colors.gray6,
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                        }}
                        className="navbar--menu-img"
                    />
                    <Body2
                        weight={pathnameSlug ? 'regular' : 'light'}
                        color={pathnameSlug ? colors.white : colors.gray6}
                        className={`mt-1 ${pathnameSlug ? 'active' : ''}`}
                    >
                        {children}
                    </Body2>
                </div>
            </ListItem>
        </Link>
    );
};

export default MenuLink;
