import { feedbackStatusOptions, feedbackTypeOptions } from 'static/status_options';

export const feedbackPositiveSubheaders = ({ filtersState, dispatchFilters, updateFilterState, activeFilters, gameValues, resetBulkActions }) => [
    {
        id: 'feedback-search-input',
        type: 'value',
        value: filtersState.searchQuery,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, searchQuery: val });
        },
        placeholder: 'Search',
        active: true,
    },
    {
        id: 'feedback-game-dropdown',
        type: 'dropdown',
        value: filtersState.game,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, game: val });
        },
        placeholder: 'Game',
        clearOnBackspace: true,
        dropdownValues: gameValues,
        active: activeFilters.game?.active,
    },
    { type: 'add-filters', active: true, activeFilters: activeFilters, updateFilterState: updateFilterState },
];

export const feedbackSubheaders = ({ filtersState, dispatchFilters, activeFilters, updateFilterState, gameValues, resetBulkActions }) => [
    {
        id: 'feedback-search-input',
        type: 'value',
        value: filtersState.searchQuery,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, searchQuery: val });
        },
        placeholder: 'Search',
        active: true,
    },
    {
        id: 'feedback-type-dropdown',
        type: 'dropdown',
        value: filtersState.type,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, type: val });
        },
        placeholder: 'Type',
        clearOnBackspace: true,
        dropdownValues: feedbackTypeOptions,
        active: activeFilters.type?.active,
    },
    {
        id: 'feedback-status-dropdown',
        type: 'dropdown',
        value: filtersState.status,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, status: val });
        },
        placeholder: 'Status',
        clearOnBackspace: true,
        dropdownValues: feedbackStatusOptions,
        active: activeFilters.status?.active,
    },
    {
        id: 'feedback-game-dropdown',
        type: 'dropdown',
        value: filtersState.game,
        setValue: val => {
            resetBulkActions();
            dispatchFilters({ page: 0, game: val });
        },
        placeholder: 'Game',
        clearOnBackspace: true,
        dropdownValues: gameValues,
        active: activeFilters.game?.active,
    },
    { type: 'add-filters', active: true, activeFilters: activeFilters, updateFilterState: updateFilterState },
];
