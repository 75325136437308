import React from 'react';
import Interceptor from './containers';
import { ThemeProvider } from 'style/theme/theme-provider';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { theme } from 'style/theme/mui-theme';
import { ValidationContextProvider } from 'services/validation-module';
import { Suspense } from 'react';

import './index.css';
import 'style/colors.css';
import 'style/users-upload.style.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function App() {
    return (
        <div className="App">
            <ThemeProvider>
                <ValidationContextProvider>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline />
                        <Interceptor />
                    </MuiThemeProvider>
                </ValidationContextProvider>
            </ThemeProvider>
        </div>
    );
}

export default function WrappedApp() {
    return (
        <Suspense fallback="">
            <App />
        </Suspense>
    );
}
