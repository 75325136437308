import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core';
import { aspectRatioDictionary } from 'services/image_management/image-editor-management/local-constants';

import './CroppingModule.css';

export default function CroppingComponent({ style, preview, file, croppedAreaPixels, setCroppedAreaPixels, aspectRatio, hovering, hasSlider }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [hasCreated, setHasCreated] = useState(false);

    const [onInteractionValue, setOnIteractionValue] = useState(0);

    const onCropComplete = useCallback(
        (__, croppedAreaPixels) => {
            if (!isNaN(croppedAreaPixels.width) && !isNaN(croppedAreaPixels.height)) setCroppedAreaPixels(file, croppedAreaPixels, aspectRatio);
            setHasCreated(true);
        },
        [aspectRatio, file, setCroppedAreaPixels]
    );

    const onInteractionEnd = useCallback(() => {
        setOnIteractionValue(onInteractionValue + 1);
    }, [onInteractionValue]);

    return (
        <div className="cropping-component--container" style={{ ...style }}>
            <div className="crop-container cropping-component--cropper" style={{ position: preview ? 'auto' : 'relative' }}>
                <Cropper
                    initialCroppedAreaPixels={croppedAreaPixels[aspectRatio]}
                    image={file.url}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspectRatio ? aspectRatioDictionary[aspectRatio] : 1}
                    style={{
                        cropAreaStyle: {
                            transition: '0.2s',
                            opacity: hovering ? 0.6 : 0.85,
                            color: preview ? (hovering ? 'rgba(0,0,0, 0.8)' : 'white') : 'rgba(0, 0, 0, 0.8)',
                            border: preview ? '1px dashed rgba(125,125,125,0.5)' : '1px solid rgba(255,255,255,0.5)',
                        },
                    }}
                    onCropChange={cropEv => {
                        if (!preview || !hasCreated) {
                            setCrop(cropEv);
                        }
                    }}
                    onCropComplete={onCropComplete}
                    onZoomChange={zoomEv => {
                        if (!preview || !hasCreated) {
                            setZoom(zoomEv);
                        }
                    }}
                    onInteractionEnd={onInteractionEnd}
                />
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                {hasSlider && <Slider value={zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(e, zoom) => setZoom(zoom)} />}
            </div>
        </div>
    );
}

CroppingComponent.propTypes = {
    aspectRatio: PropTypes.any,
    croppedAreaPixels: PropTypes.any,
    file: PropTypes.any,
    hasSlider: PropTypes.bool,
    hovering: PropTypes.bool,
    preview: PropTypes.bool,
    setCroppedAreaPixels: PropTypes.func,
    style: PropTypes.any,
};
