import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateOnly } from 'services/utils/stringHelpers';
import { colors } from 'style/colors';
import { Body1 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';

export function Info({ currentNode }) {
    const { t } = useTranslation();

    return (
        <div className={'d-flex flex-column p-3 mt-2'} style={{ width: '90%' }}>
            {currentNode?.createdBy && (
                <div className={'d-flex flex-column'}>
                    <Caption1 color={colors.gray6} className={'mb-0'}>
                        {t('CREATED BY')}
                    </Caption1>
                    <Body1 color={colors.dark} className={'mb-0 mt-1'}>
                        {currentNode?.createdBy}
                    </Body1>
                </div>
            )}
            {currentNode?.connectedUnits?.length > 0 && (
                <div className={'d-flex flex-column mt-3'}>
                    <Caption1 color={colors.gray6} className={'mb-0'}>
                        {t('ORGANIZATIONAL UNITS')}
                    </Caption1>
                    <ul>
                        {currentNode?.connectedUnits?.map(el => {
                            return <li>{el}</li>;
                        })}
                    </ul>
                </div>
            )}
            {currentNode?.timeCreated && (
                <div className={'d-flex flex-column mt-3'}>
                    <Caption1 color={colors.gray6} className={'mb-0'}>
                        {t('DATE CREATED')}
                    </Caption1>
                    <div className={'d-flex flex-row mt-1'}>
                        <Body1 color={colors.dark} className={'mb-0'}>
                            {formatDateOnly(currentNode?.dateCreated)}
                        </Body1>
                    </div>
                </div>
            )}
            {currentNode?.timeModified && (
                <div className={'d-flex flex-column mt-3'}>
                    <Caption1 color={colors.gray6} className={'mb-0'}>
                        {t('LAST MODIFIED')}
                    </Caption1>
                    <div className={'d-flex flex-row mt-1'}>
                        <Body1 color={colors.dark} className={'mb-0'}>
                            {formatDateOnly(currentNode?.dateModified)}
                        </Body1>
                    </div>
                </div>
            )}
        </div>
    );
}
