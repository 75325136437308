import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputContent from 'components/features/content_management/inputs/InputContent';
import { useContentInsertionQuestion, useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import InputAnswerCheckboxes from 'components/features/content_management/inputs/InputAnswerCheckboxes';
import { optionDefaults } from 'services/content-management/insertion-control/local-constants';
import { validatorCategories } from 'services/validation-module/local-constants';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import CropperModal from 'components/features/content_management/cropping_module/CropperModal';
import { ImageEditorProvider } from 'services/image_management/image-editor-management';
import { Body1, Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import Delete from 'assets/icons/icon-delete.svg';
import CameraDark from 'assets/icons/camera-dark.svg';
import { useTranslation } from 'react-i18next';

export function PinpointInputs({ adding, game, id, filters, image, removeImage }) {
    const { setQuestion } = useContentInsertionQuestion();
    const { answers: answersList, insertAnswer, setOptions, setExtra } = useMultipleChoiceAnswers();

    const [openImageResizer, setOpenImageResizer] = useState(false);

    const {t} = useTranslation();

    const initializeQuestion = () => {
        if (adding) {
            setOptions(
                Array(3)
                    .fill(0)
                    .map((_, index) => ({ ...optionDefaults.default, id: index }))
            );
            setQuestion('');
        }
    };
    useEffect(() => {
        initializeQuestion();
    }, []);

    return (
        <InputContent initializeQuestion={initializeQuestion} game={game} id={id} filters={filters}>
            <div className="mt-2">
                <div className="mt-3">
                    <Caption1 color={colors.gray6}>{t("IMAGE")}</Caption1>
                </div>
                <div style={{ height: '100%', display: 'block',position:'relative' }}>
                    {openImageResizer && (
                        <ImageEditorProvider setOpenImageResizer={setOpenImageResizer} imageLimit={1}>
                            <CropperModal
                                index={0}
                                addImage={(__, image) => {
                                    setExtra({ image: image });
                                }}
                                setOpenImageResizer={setOpenImageResizer}
                            ></CropperModal>
                        </ImageEditorProvider>
                    )}

                    {!image && (
                        <div
                            onClick={() => {
                                setOpenImageResizer(true);
                            }}
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{
                                border: `1px solid ${colors.gray1}`,
                                padding: 20,
                                cursor: 'pointer',
                                width: '230px',
                                height: '115px',
                                background: colors.background,
                            }}
                        >
                            <img alt="Camera" src={CameraDark} width={25} height={25} color={colors.gray6} />
                            <Body1 className={'mt-2'} style={{ textAlign: 'center' }} color={colors.gray6}>
                                {t("Add an image here")}
                            </Body1>
                        </div>
                    )}
                    {image && (
                        <div className="d-flex flex-row align-items-center">
                            <img alt="Placeholder" src={image.url} style={{ objectFit: 'cover' }} width={230} height={115} />
                            <img alt="Delete" onClick={removeImage} className={'ml-2 cursor-pointer'} height={20} width={20} src={Delete} />
                        </div>
                    )}
                    <ValidationPopup category={validatorCategories.IMAGE_REQUIRED} position="left" />
                </div>
                <div className="mt-3">
                    <Caption1 color={colors.gray6}>{t("OPTIONS")}</Caption1>
                </div>
                <div>
                    {answersList.map((ans, index) => (
                        <InputAnswerCheckboxes
                            key={`input-answer-checkbox-${ans.id}`}
                            value={ans.text}
                            index={index}
                            isCorrect={ans.isCorrect}
                            showCorrectIncorrect={true}
                            showRemove={answersList.length > 3}
                            onlyOneOption={true}
                        />
                    ))}
                </div>
                {answersList.length < 3 && (
                    <Body2 className="mt-3 cursor-pointer" onClick={insertAnswer}>
                        {t("+ Add")}
                    </Body2>
                )}
                <ValidationPopup category={validatorCategories.CORRECT_ANSWER_REQUIRED} position="right" />
            </div>
        </InputContent>
    );
}

PinpointInputs.propTypes = {
    adding: PropTypes.any,
    filters: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
    image: PropTypes.any,
    removeImage: PropTypes.func,
};
