import React from 'react';
import Alert from '@material-ui/lab/Alert/Alert';
import { colors } from 'style/colors';

export const deactivationModalData = {
    title: 'Confirm deactivation',
    alert: (
        <Alert className={'mt-2 mb-2'} severity="warning">
            Please read this carefully before deactivating a user.
        </Alert>
    ),
    buttons: [
        {
            title: 'Deactivate user',
            color: colors.white,
        },
    ],
    text: `The user {{user}} will no longer be able to login to the Coach app. If the user is an administrator they will automatically lose their admin role. The user and their past statistics won’t appear in the user profile, the their data won’t be taken into account in the global analytics. If you want to re-activate this user again in the future, the analytic data will start from scratch again.`,
    hasCancel: true,
};

export const activationModalData = {
    title: 'Activate this user',
    buttons: [
        {
            title: 'Activate user',
            color: colors.white,
        },
    ],
    text:
        'The user {{user}} will be granted access to the Coach app again, and their statistics will be reset so the data can be collected from zero. If the user held an admin role at deactivation, you may want to grant them that role once again, from their profile settings.',
    hasCancel: true,
};
