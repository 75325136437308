export const userAnalyticsDefault = {
    coachScore: 0,
    coachScoreImprovement: '',
    coachScorePercentile: '',
    firstRowStats: [],
    secondRowStats: [],
    knowledgeMap: [],
    totalQuestions: '',
    totalQuestionsCovered: '',
    totalQuestionsLeft: '',
    pieData: [],
    knowledgeOverTimeData: [],
    lockedBadges: [],
    unlockedBadges: [],
    totalBadges: 0,
    totalUnlockedBadges: 0,
    sessions: [],
};

export const trainingAnalyticsDefault = {
    pieData: [],
    totalQuestionsCovered: '',
    totalQuestionsLeft: '',
    totalQuestions: '',
    progressOverTimeData: [],
    accuracyInCategories: [],
    knowledgeMap: [],
    progressOverTimeChildrenCategories: [],
};


export const analyticType={
    ACCURACY:'accuracy',
    PROGRESS:'progress',
    OVER_TIME:'over_time'
}