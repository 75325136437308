import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Question } from './Question';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { getQuestionsUnusedInCompanyAction } from 'redux/actions/content.actions';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';
import { OutlinedButton } from 'components/elements/Button';
import { getQuestionsUnusedInTopicAction, getQuestionsUsedInTopicAction } from 'redux/actions/topics.actions';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { useTranslation } from 'react-i18next';

import './Questions.css';

export function QuestionsList({ allQuestions, setAllQuestions, selectedNode, setSelectedNode, currentTopic }) {
    const { t } = useTranslation();

    const questionsUsedInTopicState = useSelector(state => state.content.topics.questionsUsedInTopic);
    const questionsUnusedInTopicState = useSelector(state => state.content.topics.questionsUnusedInTopic);
    const questionsUnusedInCompanyState = useSelector(state => state.content.topics.questionsUnusedInCompany);
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);

    const [showUsedQuestions, setShowUsedQuestions] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [usedQuestions, setUsedQuestions] = useState([]);
    const [unusedQuestions, setUnusedQuestions] = useState([]);
    const [unusedQuestionsCompany, setUnusedQuestionsCompany] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPagesUnused, setTotalPagesUnused] = useState(0);
    const [currentPageUnused, setCurrentPageUnused] = useState(0);
    const [totalPagesUnusedCompany, setTotalPagesUnusedCompany] = useState(0);
    const [currentPageUnusedCompany, setCurrentPageUnusedCompany] = useState(0);

    const [showUnusedQuestions, setShowUnusedQuestions] = useState(false);
    const [showUnusedQuestionsCompany, setShowUnusedQuestionsCompany] = useState(false);

    const dispatch = useDispatch();

    function showMoreUsedQuestions() {
        if (
            stateIsLoaded(questionsUsedInTopicState) &&
            questionsUsedInTopicState.data &&
            questionsUsedInTopicState.data.number + 1 < questionsUsedInTopicState.data.totalPages
        ) {
            setCurrentPage(questionsUsedInTopicState.data.number + 1);
            dispatch(
                getQuestionsUsedInTopicAction(selectedNode.treeIndex, { page: questionsUsedInTopicState.data.number + 1, size: 10, searchValue: '' })
            );
        }
    }

    function showMoreUnusedQuestions() {
        if (
            stateIsLoaded(questionsUnusedInTopicState) &&
            questionsUnusedInTopicState.data &&
            questionsUnusedInTopicState.data.number + 1 < questionsUnusedInTopicState.data.totalPages
        ) {
            setCurrentPageUnused(questionsUnusedInTopicState.data.number + 1);
            dispatch(
                getQuestionsUnusedInTopicAction(selectedNode.treeIndex, {
                    page: questionsUnusedInTopicState.data.number + 1,
                    size: 10,
                    searchValue: '',
                })
            );
        }
    }
    function showMoreUnusedCompanyQuestions() {
        if (
            stateIsLoaded(questionsUnusedInCompanyState) &&
            questionsUnusedInCompanyState.data &&
            questionsUnusedInCompanyState.data.number + 1 < questionsUnusedInCompanyState.data.totalPages
        ) {
            setCurrentPageUnusedCompany(questionsUnusedInCompanyState.data.number + 1);
            dispatch(
                getQuestionsUnusedInCompanyAction(selectedNode.treeIndex, {
                    page: questionsUnusedInCompanyState.data.number + 1,
                    size: 10,
                    searchValue: '',
                })
            );
        }
    }

    useEffect(() => {
        if (stateIsLoaded(questionsUsedInTopicState) && questionsUsedInTopicState.data) {
            setTotalPages(questionsUsedInTopicState.data.totalPages);
            setUsedQuestions(questionsUsedInTopicState.data.content);
        }
    }, [questionsUsedInTopicState]);

    useEffect(() => {
        if (stateIsLoaded(questionsUnusedInTopicState) && questionsUnusedInTopicState.data) {
            setUnusedQuestions(questionsUnusedInTopicState.data.content);
            setTotalPagesUnused(questionsUnusedInTopicState.data.totalPages);
        }
    }, [questionsUnusedInTopicState]);

    useEffect(() => {
        if (stateIsLoaded(questionsUnusedInCompanyState) && questionsUnusedInCompanyState.data) {
            setUnusedQuestionsCompany(questionsUnusedInCompanyState.data.content);
            setTotalPagesUnusedCompany(questionsUnusedInCompanyState.data.totalPages);
        }
    }, [questionsUnusedInCompanyState]);

    useEffect(() => {
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(getQuestionsUsedInTopicAction(selectedNode.treeIndex, { page: 0, size: 10, searchQuery: searchValue }));
                dispatch(getQuestionsUnusedInTopicAction(selectedNode.treeIndex, { page: 0, size: 10, searchQuery: searchValue }));
                dispatch(getQuestionsUnusedInCompanyAction(selectedNode.treeIndex, { page: 0, size: 10, searchQuery: searchValue }));
            },
            500,
            'getQuestionsInTopic'
        );
        return () => setTimeoutLastExecute.removeCallback('getQuestionsInTopic');
    }, [searchValue]);

    useEffect(() => {
        dispatch(getQuestionsUnusedInCompanyAction(selectedNode.treeIndex, { page: 0, size: 10, searchQuery: '' }));
        dispatch(getQuestionsUnusedInTopicAction(selectedNode.treeIndex, { page: 0, size: 10, searchQuery: '' }));
        dispatch(getQuestionsUsedInTopicAction(selectedNode.treeIndex, { page: 0, size: 10, searchQuery: '' }));
    }, [localTopicChangesState]);

    return (
        <div style={{ maxHeight: '65vh', overflow: 'auto', width: '90%' }} className="mt-2">
            <div className={'d-flex flex-column'}>
                <div className={'d-flex flex-row'}>
                    <CustomOutlinedTextField
                        id="search-in-questions"
                        isSearch={true}
                        value={searchValue}
                        setValue={setSearchValue}
                        placeholder={t('Search...')}
                    ></CustomOutlinedTextField>
                </div>
                <div className="d-flex flex-row mt-2">
                    <div className="questions--checkbox-container">
                        <div style={{ position: 'relative', height: '20px', width: '20px' }}>
                            <Checkbox id={'check'} onClick={() => setShowUsedQuestions(!showUsedQuestions)} checked={showUsedQuestions}></Checkbox>
                        </div>
                        <Body2 color={colors.primaryText}>{t('QUESTIONS USED IN TOPIC')}</Body2>
                        <Caption1 weight="medium" color={colors.gray6}>
                            ({questionsUsedInTopicState.data ? questionsUsedInTopicState.data.localItemCount : 0})
                        </Caption1>
                    </div>
                </div>
                {showUsedQuestions &&
                    usedQuestions?.map(q => (
                        <Question
                            usedQuestions={usedQuestions}
                            setUsedQuestions={setUsedQuestions}
                            selectedNode={selectedNode}
                            setSelectedNode={setSelectedNode}
                            key={q.questionId}
                            question={q}
                            used={true}
                            setAllQuestions={setAllQuestions}
                            currentTopic={currentTopic}
                        />
                    ))}
                {currentPage + 1 < totalPages && (
                    <div style={{ width: '150px', alignSelf: 'center' }}>
                        <OutlinedButton className={'my-2 cursor-pointer'} onClick={showMoreUsedQuestions}>
                            <Body2 color={colors.primaryText} weight="medium">
                                {t('Show more')}
                            </Body2>
                        </OutlinedButton>
                    </div>
                )}
            </div>
            <div className="d-flex flex-column">
                <div
                    className="d-flex flex-row mt-4 cursor-pointer"
                    onClick={() => {
                        setShowUnusedQuestionsCompany(prevState => !prevState);
                    }}
                >
                    <Caption1 color={colors.descriptionText} weight="medium" className={'mr-1'}>
                        {t('UNUSED QUESTIONS')}
                    </Caption1>
                    <Caption1 color={colors.descriptionText} weight="medium">
                        ({questionsUnusedInCompanyState.data ? questionsUnusedInCompanyState.data.localItemCount : 0})
                    </Caption1>
                    <img alt="Show" height={15} width={15} className={'ml-2'} src={showUnusedQuestionsCompany ? AngleUp : AngleDown} />
                </div>
                {showUnusedQuestionsCompany &&
                    unusedQuestionsCompany.map(q => (
                        <Question
                            allQuestions={allQuestions}
                            setAllQuestions={setAllQuestions}
                            usedQuestions={usedQuestions}
                            setUsedQuestions={setUsedQuestions}
                            selectedNode={selectedNode}
                            setSelectedNode={setSelectedNode}
                            key={q.questionId}
                            question={q}
                            used={false}
                            currentTopic={currentTopic}
                        />
                    ))}
                {showUnusedQuestionsCompany && currentPageUnusedCompany + 1 < totalPagesUnusedCompany && (
                    <div style={{ width: '150px', alignSelf: 'center' }}>
                        <OutlinedButton className={'my-2 cursor-pointer'} onClick={showMoreUnusedCompanyQuestions}>
                            <Body2 color={colors.primaryText} weight="medium">
                                {t('Show more')}
                            </Body2>
                        </OutlinedButton>
                    </div>
                )}
                <div
                    className="d-flex flex-row mt-4 cursor-pointer"
                    onClick={() => {
                        setShowUnusedQuestions(prevState => !prevState);
                    }}
                >
                    <Caption1 color={colors.gray6} weight="medium" className={'mr-1'}>
                        {t('QUESTIONS USED IN OTHER TOPICS')}
                    </Caption1>
                    <Caption1 color={colors.gray6} weight="medium">
                        ({questionsUnusedInTopicState.data ? questionsUnusedInTopicState.data.localItemCount : 0})
                    </Caption1>
                    <img alt="Show" height={15} width={15} className={'ml-2'} src={showUnusedQuestions ? AngleUp : AngleDown} />
                </div>
                {showUnusedQuestions &&
                    unusedQuestions.map(q => (
                        <Question
                            allQuestions={allQuestions}
                            setAllQuestions={setAllQuestions}
                            setUsedQuestions={setUsedQuestions}
                            selectedNode={selectedNode}
                            setSelectedNode={setSelectedNode}
                            key={q.questionId}
                            question={q}
                            used={false}
                            currentTopic={currentTopic}
                        />
                    ))}
                {showUnusedQuestions && currentPageUnused + 1 < totalPagesUnused && (
                    <div style={{ width: '150px', alignSelf: 'center' }}>
                        <OutlinedButton className={'my-2 cursor-pointer'} onClick={showMoreUnusedQuestions}>
                            <Body2 color={colors.primaryText} weight="medium">
                                {t('Show more')}
                            </Body2>
                        </OutlinedButton>
                    </div>
                )}
            </div>
        </div>
    );
}

QuestionsList.propTypes = {
    allQuestions: PropTypes.any,
    currentTopic: PropTypes.any,
    selectedNode: PropTypes.any,
    setAllQuestions: PropTypes.func,
    setSelectedNode: PropTypes.func,
};
