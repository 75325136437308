import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import PreviewContent from 'components/features/content_management/PreviewContent';
import GameHeader from 'containers/layout/module/pages/content/questions/question_editor/games_preview/GameHeader';
import { GameQuestionCenter } from 'style/styled/GameElements';
import leftArrow from 'assets/game-previews/sortit-arrow-left.png';
import rightArrow from 'assets/game-previews/sortit-arrow-right.png';
import downArrow from 'assets/game-previews/sortit-arrow-down.png';
import leftArrowYellow from 'assets/game-previews/yellow-arrow-left.png';
import rightArrowYellow from 'assets/game-previews/yellow-arrow-right.png';
import downArrowYellow from 'assets/game-previews/yellow-arrow-down.png';
import SortItCategory from './SortiItCategory';
import SortItCardReel from './SortItCardReel';
import { randomize } from 'services/utils/arrayHelpers';
import { useContentInsertionQuestion, useImageCategories } from 'services/content-management/insertion-control';
import { optionDefaults, questionTypes } from 'services/content-management/insertion-control/local-constants';

export default function SortItPreview({ sortitInterval }) {
    const { question } = useContentInsertionQuestion();
    const { answers: categories } = useImageCategories();

    const [leftImageCategory, setLeftImageCategory] = useState(null);
    const [rightImageCategory, setRightImageCategory] = useState(null);

    const [answerType, setAnswerType] = useState(false);

    const [randomImages, setRandomImages] = useState([]);

    const previousAllImagesLength = useRef(0);
    const randomImageseRef = useRef([]);

    const [randomCategories, setRandomCategories] = useState([]);
    const previousAnswersLength = useRef(0);

    const randomAnswers = useRef(randomize(categories, 2, optionDefaults[questionTypes.IMAGE_CATEGORIES]));

    function clearIntervalSortit() {
        if (sortitInterval.current) {
            clearInterval(sortitInterval.current);
            sortitInterval.current = null;
        }
    }

    useEffect(() => {
        if (categories.length === 0) {
            return;
        }
        if (randomCategories.length === 0 || previousAnswersLength.current !== categories.length) {
            let result = randomize(categories, 2, optionDefaults[questionTypes.IMAGE_CATEGORIES]);
            setRandomCategories(result);
            randomAnswers.current = result;
        } else {
            let ansNew = randomAnswers.current;
            ansNew.forEach(an => {
                let item = categories.find(a => a.id === an.id);
                if (item) {
                    an.text = item.text;
                    an.image = item.image;
                }
            });
            setRandomCategories([...randomAnswers.current]);
        }
        previousAnswersLength.current = categories.length;
    }, [categories, randomCategories.length]);

    useEffect(() => {
        let allImages = categories.reduce((acc, cat) => {
            const croppedImgs = cat.images ? cat.images.map(im => ({ image: im, category: cat.text })) : [];
            acc = [...acc, ...croppedImgs];
            return acc;
        }, []);

        if (randomImages.length === 0 || previousAllImagesLength.current !== allImages.length) {
            clearIntervalSortit();
            let result = randomize(allImages, 7, { image: null, category: '' });
            setRandomImages(result);
            randomImageseRef.current = result;
        }
        previousAllImagesLength.current = allImages.length;
        //eslint-disable-next-line
    }, [categories]);

    useEffect(() => {
        if (!sortitInterval.current && randomImages[0]?.image) {
            sortitInterval.current = setInterval(() => {
                let el = randomImages[0];
                let allImages = categories.reduce((acc, cat) => {
                    acc = [...acc, ...cat.images.map(im => ({ image: im, category: cat.text }))];
                    return acc;
                }, []);
                let newElement = randomize(allImages, 1)[0];
                let ri = randomImages;
                ri.shift();
                ri.push(newElement);
                setRandomImages([...ri]);
                if (randomCategories[0]?.images?.includes(el?.image)) {
                    setLeftImageCategory(el?.image);
                    setAnswerType('Left');
                    setTimeout(() => {
                        setAnswerType(false);
                    }, 500);
                } else if (randomCategories[1]?.images?.includes(el?.image)) {
                    setRightImageCategory(el?.image);
                    setAnswerType('Right');
                    setTimeout(() => {
                        setAnswerType(false);
                    }, 500);
                } else {
                    setAnswerType('Down');
                    setTimeout(() => {
                        setAnswerType(false);
                    }, 500);
                }
            }, 2000);
        }
    }, [categories, randomCategories, randomImages]);

    return (
        <PreviewContent game={'Sort It'}>
            <GameHeader />
            <GameQuestionCenter>{question || 'Question'}</GameQuestionCenter>
            <div className="d-flex justify-content-between px-4 pt-2">
                <div>
                    {randomCategories.map(
                        (cat, index) =>
                            index === 0 && (
                                <div className="mt-3" key={`sort-it-category-left-${cat.id}`}>
                                    <SortItCategory index={0} image={leftImageCategory?.urlRatio1x1}>
                                        {cat.text || 'Option 1'}
                                    </SortItCategory>
                                </div>
                            )
                    )}
                </div>
                <div>
                    {randomCategories.map(
                        (cat, index) =>
                            index === 1 && (
                                <div className="mt-3" key={`sort-it-category-right-${cat.id}`}>
                                    <SortItCategory index={1} image={rightImageCategory?.urlRatio1x1}>
                                        {cat.text || 'Option 2'}
                                    </SortItCategory>
                                </div>
                            )
                    )}
                </div>
            </div>
            {answerType !== 'Left' && (
                <img src={leftArrow} style={{ width: '20px', position: 'absolute', left: '20px', bottom: '80px' }} alt="left-arrow" />
            )}
            {answerType === 'Left' && (
                <img src={leftArrowYellow} style={{ width: '20px', position: 'absolute', left: '20px', bottom: '80px' }} alt="left-arrow" />
            )}
            {answerType !== 'Right' && (
                <img src={rightArrow} style={{ width: '20px', position: 'absolute', right: '20px', bottom: '80px' }} alt="right-arrow" />
            )}
            {answerType === 'Right' && (
                <img src={rightArrowYellow} style={{ width: '20px', position: 'absolute', right: '20px', bottom: '80px' }} alt="right-arrow" />
            )}
            {answerType !== 'Down' && (
                <img
                    src={downArrow}
                    style={{ width: '30px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: '15px' }}
                    alt="right-arrow"
                />
            )}
            {answerType === 'Down' && (
                <img
                    src={downArrowYellow}
                    style={{ width: '30px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: '15px' }}
                    alt="right-arrow"
                />
            )}

            <div style={{ position: 'absolute', bottom: '10%', left: '50%' }}>
                <SortItCardReel randomImages={randomImages} />
            </div>
        </PreviewContent>
    );
}

SortItPreview.propTypes = {
    sortitInterval: PropTypes.any,
};
