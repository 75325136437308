import React from 'react';
import PreviewContent from 'components/features/content_management/PreviewContent';
import GameHeader from 'containers/layout/module/pages/content/questions/question_editor/games_preview/GameHeader';
import { GameQuestionLeft } from 'style/styled/GameElements';
import { useContentInsertionQuestion, useAnswerTextOptions } from 'services/content-management/insertion-control';
import Keyboard from 'assets/game-previews/recall-keyboard.png';
import RecallOptionsView from './RecallOptionsView';

export default function RecallPreview() {
    const { question } = useContentInsertionQuestion();
    const { answers: answersList } = useAnswerTextOptions();

    return (
        <PreviewContent game={'Recall'}>
            <div className="d-flex flex-column" style={{ height: '100%' }}>
                <GameHeader />
                <GameQuestionLeft>{question || 'Question'}</GameQuestionLeft>
                <div style={{ flex: 1, overflow: 'hidden', paddingTop: '10px', paddingLeft: '1em' }} className="d-flex">
                    <RecallOptionsView answers={answersList} />
                </div>
                <div style={{}}>
                    <img src={Keyboard} className="w-100" alt="recall keyboard" />
                </div>
            </div>
        </PreviewContent>
    );
}
