import requestAgent from '../requestAgent';
import { handleSuccess, handleError } from './utils';
import {
    ACTIVATE_USER_API,
    CHANGE_PASSWORD_API,
    CHANGE_PASSWORD_FOR_USER_API,
    DEACTIVATE_USER_API,
    DELETE_DEVICE_REQUESTS_BULK_API,
    DELETE_USER_API,
    DOWNLOAD_TEMPLATE_API,
    DOWNLOAD_USER_TEMPLATE_FULL_API,
    EDIT_USER_API,
    EDIT_USER_IN_BATCH_API,
    EDIT_USERS_ROLE_IN_BATCH_API,
    FETCH_AUTH_ROLES_API,
    FETCH_COMPANY_ROLES_API,
    FETCH_COMPANY_ROLES_PAGINATED_API,
    FETCH_ROLES_CONNECTED_TO_SET_API,
    FETCH_SINGLE_COMPANY_ROLE_API,
    FETCH_SINGLE_USER_API,
    FETCH_USERS_API,
    FETCH_USERS_PAGINATED_API,
    FETCH_USERS_WITH_ROLE_API,
    FETCH_USERS_WITH_UNASSIGNED_ROLE_API,
    FORGOT_PASSWORD_REQUEST_API,
    FORGOT_PASSWORD_SET_NEW_API,
    GENERATE_MULTIPLE_USERS_FULL_API,
    GET_ACTIVE_USERS_COUNT_FOR_MONTH_API,
    GET_ACTIVE_USERS_FOR_MONTH_API,
    GET_COMPANY_API,
    GET_DEVICE_REQUESTS_FOR_USER_API,
    GET_DEVICE_REQUESTS_PAGINATED_API,
    INVITE_MULTIPLE_ADMINS_API,
    INVITE_MULTIPLE_USERS_API,
    INVITE_USER_API,
    REGISTER_ADMINISTRATOR_API,
    REINVITE_USERS_API,
    RESOLVE_DEVICE_REQUESTS_BULK_API,
    SAVE_ROLES_NEW_API,
    UPLOAD_USERS_API,
    USER_BATCH_ACTION_API,
} from '../api_links';
import { StatusCode } from 'redux/core/StatusCode';
import { deleteApiRequest, getApiRequest, postApiRequest } from './requests';
import { linkBuilderFromQuery } from '../api_builders';
import { convertArrayToQueryVariables } from '../../utils/linkHelpers';
import { tokenHelper } from 'services/auth/tokenHelpers';

export const fetchUsersApiRequest = async () => {
    return getApiRequest(FETCH_USERS_API);
};

export const fetchUsersPaginatedApiRequest = queryData => {
    let api = linkBuilderFromQuery(FETCH_USERS_PAGINATED_API, queryData);
    return getApiRequest(api);
};
export const fetchUsersWithUnassignedRoleApiRequest = async (ignoreIds, searchQuery, page) => {
    return getApiRequest(
        FETCH_USERS_WITH_UNASSIGNED_ROLE_API(ignoreIds !== undefined ? convertArrayToQueryVariables(ignoreIds) : '', searchQuery, page)
    );
};

export const fetchUsersWithRoleApiRequest = async (roleId, ignoreIds, searchQuery, page) => {
    return getApiRequest(
        FETCH_USERS_WITH_ROLE_API(roleId, searchQuery, ignoreIds !== undefined ? convertArrayToQueryVariables(ignoreIds) : '', page)
    );
};

export const saveAllRolesNewApiRequest = async data => {
    const responseData = await requestAgent.post(SAVE_ROLES_NEW_API, data, null, { 'Content-Type': 'application/json' });

    if (responseData.status <= StatusCode.NO_CONTENT) {
        return handleSuccess(responseData);
    } else {
        return handleError(responseData);
    }
};

export const fetchSingleUserApiRequest = async id => {
    return getApiRequest(FETCH_SINGLE_USER_API(id));
};

export const fetchSingleRoleApiRequest = async id => {
    return getApiRequest(FETCH_SINGLE_COMPANY_ROLE_API(id));
};

export const deleteUserApiRequest = async id => {
    return deleteApiRequest(DELETE_USER_API(id));
};

export const inviteUserApiRequest = async data => {
    return postApiRequest(INVITE_USER_API, data);
};

export const reInviteUsersApiRequest = async data => {
    return postApiRequest(REINVITE_USERS_API, data);
};

export const deactivateUserApiRequest = async id => {
    return postApiRequest(DEACTIVATE_USER_API(id));
};

export const activateUserApiRequest = async id => {
    return postApiRequest(ACTIVATE_USER_API(id));
};

export const editUserApiRequest = async (data, id) => {
    return postApiRequest(EDIT_USER_API(id), data);
};

export const editUsersInBatchApiRequest = async data => {
    return postApiRequest(EDIT_USER_IN_BATCH_API, data);
};

export const editUsersRoleInBatchApiRequest = async data => {
    return postApiRequest(EDIT_USERS_ROLE_IN_BATCH_API, data);
};

export const userBatchActionApiRequest = async data => {
    return postApiRequest(USER_BATCH_ACTION_API, data);
};
export const fetchRolesApiRequest = async () => {
    return getApiRequest(FETCH_COMPANY_ROLES_API);
};
export const fetchRolesConnectedToSetApiRequest = async setId => {
    return getApiRequest(FETCH_ROLES_CONNECTED_TO_SET_API(setId));
};
export const getRolesPaginatedApiRequest = async queryData => {
    let api = linkBuilderFromQuery(FETCH_COMPANY_ROLES_PAGINATED_API, queryData);
    return getApiRequest(api);
};

export const fetchAuthRolesApiRequest = async () => {
    return getApiRequest(FETCH_AUTH_ROLES_API);
};

export const getCompanyApiRequest = async () => {
    return getApiRequest(GET_COMPANY_API);
};

export const inviteMultipleApiRequest = async users => {
    return postApiRequest(INVITE_MULTIPLE_USERS_API, users);
};

export const inviteMultipleAdminsApiRequest = async users => {
    return postApiRequest(INVITE_MULTIPLE_ADMINS_API, users);
};

export const uploadUsersApiRequest = async file => {
    let data = new FormData();
    data.set('name', 'jas');
    data.set('file', file);

    return postApiRequest(UPLOAD_USERS_API, data);
};

export const getActiveUsersCountForMonthApiRequest = async (from, to) => {
    let api = linkBuilderFromQuery(GET_ACTIVE_USERS_COUNT_FOR_MONTH_API, { from, to });
    return getApiRequest(api);
};

export const downloadActiveUsersForMonthApiRequest = async (from, to) => {
    let token = await tokenHelper.getToken();
    let headers = {};
    if (token) {
        headers = {
            Authorization: 'Bearer ' + token,
        };
    }
    const fetchParams = {
        method: 'GET',
        headers: headers,
    };

    let api = linkBuilderFromQuery(GET_ACTIVE_USERS_FOR_MONTH_API, { from, to });
    const response = await fetch(api, fetchParams);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    document.body.appendChild(link);

    let fromDate = new Date(from).toDateString();
    let toDate = new Date(to).toDateString();

    link.download = `users_active_${fromDate}_${toDate}.xlsx`;
    link.href = url;
    link.click();
};

export const downloadTemplateApiRequest = async token => {
    const headers = {
        Authorization: 'Bearer ' + token,
    };
    const fetchParams = {
        method: 'GET',
        headers: headers,
    };
    const response = await fetch(DOWNLOAD_TEMPLATE_API, fetchParams);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    document.body.appendChild(link);

    link.download = 'response.xlsx';
    link.href = url;
    link.click();
};
export const downloadUsersFullTemplateApiRequest = async token => {
    const headers = {
        Authorization: 'Bearer ' + token,
    };
    const fetchParams = {
        method: 'GET',
        headers: headers,
    };
    const response = await fetch(DOWNLOAD_USER_TEMPLATE_FULL_API, fetchParams);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    document.body.appendChild(link);

    link.download = 'response.xlsx';
    link.href = url;
    link.click();
};

export const uploadUsersFullApiRequest = async (token, file) => {
    const headers = {
        Authorization: 'Bearer ' + token,
    };
    const data = new FormData();
    data.append('file', file);
    const fetchParams = {
        method: 'POST',
        headers: headers,
        body: data,
    };
    const response = await fetch(GENERATE_MULTIPLE_USERS_FULL_API, fetchParams);
    if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.download = 'Users Generated.xlsx';
        link.href = url;
        link.click();
    } else {
        alert('Upload not successful', response.statusText);
    }
};

export const registerAdministratorApiRequest = async data => {
    return postApiRequest(REGISTER_ADMINISTRATOR_API(), data);
};
export const forgotPasswordRequestApiRequest = async data => {
    return postApiRequest(FORGOT_PASSWORD_REQUEST_API(), data);
};
export const forgotPasswordSetNewApiRequest = async data => {
    return postApiRequest(FORGOT_PASSWORD_SET_NEW_API(), data);
};
export const changePasswordApiRequest = async data => {
    let formData = new FormData();
    formData.set('oldPassword', data.oldPassword);
    formData.set('password', data.newPassword);
    return postApiRequest(CHANGE_PASSWORD_API, formData);
};

export const changePasswordForUserApiRequest = async (id, data) => {
    let formData = new FormData();
    formData.set('password', data.newPassword);
    return postApiRequest(CHANGE_PASSWORD_FOR_USER_API(id), formData);
};

// User devices
export const fetchUserDevicesPaginatedApiRequest = queryData => {
    let api = linkBuilderFromQuery(GET_DEVICE_REQUESTS_PAGINATED_API, queryData);
    return getApiRequest(api);
};
export const resolveDeviceRequestsBulkApiRequest = data => {
    return postApiRequest(RESOLVE_DEVICE_REQUESTS_BULK_API, data);
};
export const deleteDeviceRequestsBulkApiRequest = data => {
    return postApiRequest(DELETE_DEVICE_REQUESTS_BULK_API, data);
};
export const getDeviceRequestsForUserApiRequest = userId => {
    return getApiRequest(GET_DEVICE_REQUESTS_FOR_USER_API(userId));
};
