import React from 'react';
import { DefaultButton } from 'components/elements/Button';
import { colors } from 'style/colors';
import { Body1, Body2 } from 'style/typography/Body';
import { H4 } from 'style/typography/Heading';
import { FileUploader } from 'components/features/forms/form/FileUploader';

import { formatSizeUnits } from 'services/utils/fileHelpers';
import { Caption1 } from 'style/typography/Caption';
import WordIcon from 'assets/icons/word-icon.svg';
import UploadWord from 'assets/icons/upload-word.svg';
import { Trans, useTranslation } from 'react-i18next';
import Checkbox from 'components/elements/checkbox/Checkbox';

import './GenerationTabs.css';
import { gameIconsMap } from 'static/game_constants';

export default function UploadFile({ file, setFile, setTitle, checkedGames, setCheckedGames }) {
    const { t } = useTranslation();

    const handleFileChange = files => {
        if (files.length > 0) {
            setFile(files[0]);
            setTitle(files[0]?.name?.substr(0, files[0].name.lastIndexOf('.')) ?? '');
        }
    };

    const handleCheckChange = (key, value) => {
        setCheckedGames({
            ...checkedGames,
            [key]: {
                ...checkedGames[key],
                selected: value,
            },
        });
    };

    return (
        <div className="generation-tabs--container">
            <div className="generation-tabs--items">
                <H4 color={colors.dark} weight="light" style={{ padding: '20px 0' }}>
                    {t('Upload your .docx file with the training content')}
                </H4>
                <Body2 color={colors.dark} weight="light">
                    <Trans>
                        {t(
                            'In order for all the magic to begin, you must upload your own <strong>.docx file</strong> which contains the contents of the new training you wish to create. <br/> <br/> We strongly recommend that you download our example file and create or adjust your file in a similar structure and text formatting (with each heading corresponding to a topic), so the training can be created more seamlessly.'
                        )}
                    </Trans>
                </Body2>
                {/* <DefaultButton backgroundColor={colors.gray6} style={{ margin: '30px 0' }}>
                    <img alt="Word" src={WordIcon} height={30} width={30} style={{ paddingRight: 10 }} />
                    <Body2 color={colors.white}>{t('download example file')}</Body2>
                </DefaultButton> */}
                {!file && (
                    <div style={{ marginTop: 20 }}>
                        <FileUploader
                            dropzoneText={<Body2>{t('Drag and drop the file here or click to upload')}</Body2>}
                            acceptedFiles={['.docx', '.doc']}
                            filesLimit={1}
                            maxFileSize={50000000}
                            onChange={handleFileChange}
                            Icon={() => <img alt="Upload word" src={UploadWord} height={50} width={50} />}
                            dropzoneClass="file-uploader"
                        ></FileUploader>
                    </div>
                )}
                {file && (
                    <div className="upload-file--details">
                        <div className="upload-file--file-details">
                            <Body1 weight="bold" color={colors.primaryText}>
                                {file.name}
                            </Body1>
                            <div className="upload-file--metadata">
                                <Caption1 color={colors.gray6}>{new Date(file.lastModified).toDateString()}</Caption1>
                                <div className="divider"></div>
                                <Caption1 color={colors.gray6}>{formatSizeUnits(file.size)}</Caption1>
                            </div>
                        </div>
                        <div className="upload-file--game">
                            <Caption1 color={colors.gray6}>{t('TRAINING FOR GAMES')}</Caption1>
                            {Object.keys(checkedGames).map(key => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                        <div style={{ position: 'relative', height: '20px', width: '20px' }}>
                                            <Checkbox
                                                onClick={() => {
                                                    handleCheckChange(key, !checkedGames[key].selected);
                                                }}
                                                checked={checkedGames[key].selected}
                                            ></Checkbox>
                                        </div>
                                        <Body2 weight="medium" color={colors.gray6}>
                                            {t(key.charAt(0).toUpperCase() + key.replace('-', ' ').slice(1))}
                                        </Body2>
                                        <img alt={key} height={25} width={25} src={gameIconsMap[key]} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
