import React from 'react';
import { DefaultButton } from 'components/elements/Button';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { Subtitle1 } from 'style/typography/Subtitle';
import { useDispatch, useSelector } from 'react-redux';
import { addChildToTrainingTree } from 'redux/actions/trainings.actions';
import { useUndoRedo } from 'services/undo-wrapper-management';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import './TopicsTreeStyle.css';

export function EmptyState() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const treeData = useSelector(state => state.content.singleTraining.data);
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);

    const { addToUndoStack, resetRedoStack } = useUndoRedo();

    const getNodeKey = function(node) {
        let nodeCurr = node.node ? node.node : node;
        return nodeCurr.treeIndex ?? nodeCurr.generatedTreeIndex;
    };

    let currentSetId = treeData.learningSetInfo?.setId ?? treeData.learningSetInfo?.generatedSetId;

    function onAddCategory() {
        resetRedoStack(currentSetId);
        addToUndoStack(currentSetId, null, {
            training: { treeStructure: _.cloneDeep(treeData.treeStructure) },
            localTopics: _.cloneDeep(localTopicChangesState.data),
        });

        dispatch(
            addChildToTrainingTree(
                [treeData.treeStructure[0]?.treeIndex ?? treeData.treeStructure[0]?.generatedTreeIndex],
                getNodeKey,
                '',
                false,
                null
            )
        );
    }
    return (
        <div className="topics-tree--empty-state">
            <Subtitle1 color={colors.gray6}>{t('Add first category')}</Subtitle1>
            <Caption1 color={colors.gray6}>{t('Start building the category tree by adding the first one')}</Caption1>
            <br />
            <DefaultButton className={'mt-4'} onClick={onAddCategory} text={t('Add Category')} />
        </div>
    );
}
