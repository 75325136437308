import { combineReducers } from 'redux';
import { basicReducer } from './basicReducer';
import {
    GET_ALL_TOPICS_FROM_COMPANY,
    GET_QUESTION,
    GET_QUESTIONS_FROM_COMPANY,
    INSERT_QUESTION,
    HANDLE_USERS_INVITE,
    HANDLE_USERS_INVITE_MANUAL,
    GET_FOLDER_STRUCTURE,
    GET_QUESTIONS_FOR_CATEGORY,
    SAVE_NEWS,
    GET_IMAGES_FROM_FOLDER,
    GET_FEEDBACK,
    UPDATE_FEEDBACK,
    GET_SPECIFIC_FEEDBACK,
    DELETE_FEEDBACK,
    GET_NEWS,
    GET_SPECIFIC_NEWS,
    DELETE_NEWS,
    GET_GLOBAL_STATISTICS,
    GET_TRAINING_STATISTICS,
    GET_GAMES,
    DOWNLOAD_GAME_TEMPLATES,
    UPLOAD_CONTENT,
    EDITING_QUESTIONS_LIST,
    TRAININGS_INFO,
    TOPICS_FROM_TRAINING,
    SINGLE_TRAINING,
    INITIATE_NODE_REMOVAL,
    CREATE_NEW_TRAINING,
    UPDATE_TOPICS_TREE,
    UPDATE_TRAINING_SETTINGS,
    GET_TOPICS_FOLDER_STRUCTURE,
    UPDATE_TOPICS_FOLDER_STRUCTURE,
    GET_ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO,
    UPDATE_TOPIC_SETTINGS,
    COMPANY_UNIT_STRUCTURE,
    UPDATE_MULTIPLE_TOPICS,
    GET_COMPANY_INFO,
    UPDATE_COMPANY_GLOBAL_CONFIGURATION,
    REMOVE_QUESTION_FROM_TOPIC,
    GET_DASHBOARD_STATISTICS,
    ALL_TRAININGS,
    EXPORT_GAME_QUESTIONS,
    GET_GAME_QUSTION_TYPE_VARIATIONS,
    SAVE_FOLDER_STRUCTURE,
    DELETE_IMAGE_FOLDER,
    GET_TOPIC_CONTENT,
    GET_QUESTIONS_USED_IN_TOPIC,
    GET_QUESTIONS_UNUSED_IN_TOPIC,
    GET_QUESTIONS_UNUSED_IN_COMPANY,
    TRAINING_TREE_SAVING_ERRORS,
    GET_QUESTIONS_PAGINATED,
    GET_NEWS_PAGINATED,
    GET_LOCAL_TOPIC_CHANGES,
    UPDATE_MULTIPLE_QUESTIONS_TOPICS,
    GET_FEEDBACK_CONTENT_PAGINATED,
    GET_FEEDBACK_POSITIVE_PAGINATED,
    LOCAL_TOPICS_FOLDER_STRUCTURE_CHANGES,
    HANDLE_TOPICS_TREE,
    HANDLE_OLD_TOPICS_FOLDER_STRUCTURE,
    HANDLE_OLD_ALL_TOPICS_FROM_COMPANY,
    GET_UNCATEGORIZED_IMAGES,
    GET_ALL_IMAGES,
    EDIT_QUESTIONS_BULK,
    GET_SINGLE_TOPIC_STATS,
    UPDATE_LABELS,
    GET_TAGS,
    CREATE_TAG,
    GET_LABELS,
    GET_LABEL_BY_ID,
    DELETE_LABEL_BY_ID,
    GET_USERS_WITH_TAG,
    GET_USERS_WITHOUT_TAG_WITH_ID,
    ADD_USER_TO_TAG,
    REMOVE_USER_FROM_TAG,
    ADD_USERS_TO_TAGS,
    GET_ALL_NOTIFICATIONS_PAGINATED,
    CREATE_NEW_NOTIFICATION,
    SAVE_CONTENT_BLOCK,
    GET_CONTENT_BLOCKS_PAGINATED,
    GET_CONTENT_BLOCK_BY_ID,
    DELETE_CONTENT_BLOCK_BY_ID,
    UPLOAD_CONTENT_PROGRESS,
    RESOLVE_FEEDBACK_BULK,
    UNRESOLVE_FEEDBACK_BULK,
    UPLOAD_USER_TAGS,
    UPLOAD_USER_TAGS_PROGRESS,
    UPDATE_SAVED_TOPIC_INDEX,
    DELETE_FEEDBACK_BULK,
    MOVE_IMAGES_TO_FOLDER,
    GET_ALL_TRAINING_MODULES_COMPANY,
    GET_TRAINING_MODULE_COMPANY,
    SAVE_TRAINING_MODULE_LEARNING_SET,
    DELETE_TRAINING_MODULE,
    DELETE_TAG_BY_ID,
    IMAGES_DELETE_SOFT,
    IMAGES_DELETE_FORCE,
    GENERATING_CONTENT_PROGRESS,
    GENERATE_CONTENT,
    GENERATE_CONTENT_FOR_TOPIC,
    UPLOADING_TRAINING_PROGRESS,
    DELETE_TRAINING,
    UPLOAD_TRAINING,
    EXPORT_QUESTIONS_PROGRESS,
    EXPORT_ALL_QUESTIONS,
    REMOVE_USERS_FROM_TAGS,
    GET_SINGLE_NOTIFICATION,
    DELETE_NOTIFICATION,
    GET_LATEST_UPDATES,
    SET_LATEST_UPDATES_SEEN,
    GET_ALL_GAMES,
    PRE_BULK_ACTION_QUESTIONS,
    PRE_BULK_ACTION_FEEDBACK,
    GET_IMAGES_COUNT,
    GET_QUESTIONS_INDEXES,
} from '../constants/content.constants';
import { CREATE_COMPANY_WITH_CODE } from '../constants/content.constants';
import {
    DELETE_DEVICE_REQUESTS_BULK,
    GET_DEVICE_REQUESTS_FOR_USER,
    GET_DEVICE_REQUESTS_PAGINATED,
    RESOLVE_DEVICE_REQUESTS_BULK,
} from 'redux/constants/users.constants';

export default combineReducers({
    singleQuestion: basicReducer(GET_QUESTION),
    questionsPaginated: basicReducer(GET_QUESTIONS_PAGINATED),
    questionIndexes: basicReducer(GET_QUESTIONS_INDEXES),

    questionInsertion: basicReducer(INSERT_QUESTION),
    // nextUserUploadError: basicReducer(HANDLE_NEXT_ERROR),
    // usersInvited: basicReducer(HANDLE_USERS_INVITE),
    // usersInvitedManually: basicReducer(HANDLE_USERS_INVITE_MANUAL),
    folderStructure: basicReducer(GET_FOLDER_STRUCTURE),
    updateFolderStructure: basicReducer(SAVE_FOLDER_STRUCTURE),
    deleteSingleFolder: basicReducer(DELETE_IMAGE_FOLDER),
    // questionsForCategory: basicReducer(GET_QUESTIONS_FOR_CATEGORY),
    createNews: basicReducer(SAVE_NEWS),
    imagesFromFolder: basicReducer(GET_IMAGES_FROM_FOLDER),
    moveImagesToFolder: basicReducer(MOVE_IMAGES_TO_FOLDER),
    allImages: basicReducer(GET_ALL_IMAGES),
    imagesCount: basicReducer(GET_IMAGES_COUNT),
    uncategorizedImages: basicReducer(GET_UNCATEGORIZED_IMAGES),
    allFeedback: basicReducer(GET_FEEDBACK),
    feedbackContentPaginated: basicReducer(GET_FEEDBACK_CONTENT_PAGINATED),
    feedbackPositivePaginated: basicReducer(GET_FEEDBACK_POSITIVE_PAGINATED),
    feedbackUpdated: basicReducer(UPDATE_FEEDBACK),
    feedbackDeleted: basicReducer(DELETE_FEEDBACK),
    feedbackView: basicReducer(GET_SPECIFIC_FEEDBACK),
    resolveFeedbackBulk: basicReducer(RESOLVE_FEEDBACK_BULK),
    unresolveFeedbackBulk: basicReducer(UNRESOLVE_FEEDBACK_BULK),
    deleteFeedbackBulk: basicReducer(DELETE_FEEDBACK_BULK),
    preBulkActionFeedbacks: basicReducer(PRE_BULK_ACTION_FEEDBACK),
    news: basicReducer(GET_NEWS),
    newsPaginated: basicReducer(GET_NEWS_PAGINATED),
    editingNews: basicReducer(GET_SPECIFIC_NEWS),
    deleteNews: basicReducer(DELETE_NEWS),
    latestUpdates: basicReducer(GET_LATEST_UPDATES),
    setLatestUpdatesSeen: basicReducer(SET_LATEST_UPDATES_SEEN),
    dashboardStats: basicReducer(GET_DASHBOARD_STATISTICS),
    globalStats: basicReducer(GET_GLOBAL_STATISTICS),
    trainingStats: basicReducer(GET_TRAINING_STATISTICS),
    singleTopicStats: basicReducer(GET_SINGLE_TOPIC_STATS),
    games: basicReducer(GET_GAMES),
    allGames: basicReducer(GET_ALL_GAMES),
    gameTemplates: basicReducer(DOWNLOAD_GAME_TEMPLATES),
    exportGameQuestions: basicReducer(EXPORT_GAME_QUESTIONS),
    exportAllQuestions: basicReducer(EXPORT_ALL_QUESTIONS),
    exportAllQuestionsProgress: basicReducer(EXPORT_QUESTIONS_PROGRESS),
    uploadContent: basicReducer(UPLOAD_CONTENT),
    uploadContentProgress: basicReducer(UPLOAD_CONTENT_PROGRESS),
    editingQuestionsList: basicReducer(EDITING_QUESTIONS_LIST),
    trainingsInfo: basicReducer(TRAININGS_INFO),
    singleTraining: basicReducer(SINGLE_TRAINING),
    createTraining: basicReducer(CREATE_NEW_TRAINING),
    updateTopicsTree: basicReducer(UPDATE_TOPICS_TREE),
    updateTrainingSettings: basicReducer(UPDATE_TRAINING_SETTINGS),
    uploadingTrainingProgress: basicReducer(UPLOADING_TRAINING_PROGRESS),
    uploadTraining: basicReducer(UPLOAD_TRAINING),
    topicsFromTraining: basicReducer(TOPICS_FROM_TRAINING),
    initiateNodeRemoval: basicReducer(INITIATE_NODE_REMOVAL),
    companyUnitStructure: basicReducer(COMPANY_UNIT_STRUCTURE),
    companyInfo: basicReducer(GET_COMPANY_INFO),
    updateCompanyGlobalConfig: basicReducer(UPDATE_COMPANY_GLOBAL_CONFIGURATION),
    allTrainings: basicReducer(ALL_TRAININGS),
    gameQuestionTypeVariations: basicReducer(GET_GAME_QUSTION_TYPE_VARIATIONS),
    saveTrainingTreeValidation: basicReducer(TRAINING_TREE_SAVING_ERRORS),
    deleteTraining: basicReducer(DELETE_TRAINING),
    editQuestionsBulk: basicReducer(EDIT_QUESTIONS_BULK),
    preBulkActionQuestions: basicReducer(PRE_BULK_ACTION_QUESTIONS),
    notificationsPaginated: basicReducer(GET_ALL_NOTIFICATIONS_PAGINATED),
    createNotification: basicReducer(CREATE_NEW_NOTIFICATION),
    singleNotification: basicReducer(GET_SINGLE_NOTIFICATION),
    deleteNotification: basicReducer(DELETE_NOTIFICATION),
    topics: combineReducers({
        topicsTree: basicReducer(HANDLE_TOPICS_TREE),
        allTopicsFromCompany: basicReducer(GET_ALL_TOPICS_FROM_COMPANY),
        oldAllTopicsFromCompany: basicReducer(HANDLE_OLD_ALL_TOPICS_FROM_COMPANY),
        // allTopicsFromCompanyWithAdditionalInfo: basicReducer(GET_ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO),
        topicsFolderStructure: basicReducer(GET_TOPICS_FOLDER_STRUCTURE),
        oldTopicsFolderStructure: basicReducer(HANDLE_OLD_TOPICS_FOLDER_STRUCTURE),
        topicContent: basicReducer(GET_TOPIC_CONTENT),
        localTopicChanges: basicReducer(GET_LOCAL_TOPIC_CHANGES),
        questionsUsedInTopic: basicReducer(GET_QUESTIONS_USED_IN_TOPIC),
        questionsUnusedInTopic: basicReducer(GET_QUESTIONS_UNUSED_IN_TOPIC),
        questionsUnusedInCompany: basicReducer(GET_QUESTIONS_UNUSED_IN_COMPANY),
        updateTopicsFolderStructure: basicReducer(UPDATE_TOPICS_FOLDER_STRUCTURE),
        updateTopicSettings: basicReducer(UPDATE_TOPIC_SETTINGS),
        updateMultipleTopics: basicReducer(UPDATE_MULTIPLE_TOPICS),
        updatMultipleQuestionsTopics: basicReducer(UPDATE_MULTIPLE_QUESTIONS_TOPICS),
        removeQuestionFromTopic: basicReducer(REMOVE_QUESTION_FROM_TOPIC),
        hasTopicsFolderStructureLocalChanges: basicReducer(LOCAL_TOPICS_FOLDER_STRUCTURE_CHANGES),
        savedTopicIndexes: basicReducer(UPDATE_SAVED_TOPIC_INDEX),
    }),
    updateLabels: basicReducer(UPDATE_LABELS),
    tags: basicReducer(GET_TAGS),
    labels: basicReducer(GET_LABELS),
    uploadUserTags: basicReducer(UPLOAD_USER_TAGS),
    uploadUserTagsProgress: basicReducer(UPLOAD_USER_TAGS_PROGRESS),
    createTag: basicReducer(CREATE_TAG),
    labelById: basicReducer(GET_LABEL_BY_ID),
    deleteLabelById: basicReducer(DELETE_LABEL_BY_ID),
    deleteTagById: basicReducer(DELETE_TAG_BY_ID),
    usersWithTag: basicReducer(GET_USERS_WITH_TAG),
    usersWithoutTagWithId: basicReducer(GET_USERS_WITHOUT_TAG_WITH_ID),
    addUserToTag: basicReducer(ADD_USER_TO_TAG),
    removeUserFromTag: basicReducer(REMOVE_USER_FROM_TAG),
    addUsersToTags: basicReducer(ADD_USERS_TO_TAGS),
    removeUsersFromTags: basicReducer(REMOVE_USERS_FROM_TAGS),
    saveContentBlock: basicReducer(SAVE_CONTENT_BLOCK),
    contentBlocksPaginated: basicReducer(GET_CONTENT_BLOCKS_PAGINATED),
    contentBlockById: basicReducer(GET_CONTENT_BLOCK_BY_ID),
    deleteContentBlock: basicReducer(DELETE_CONTENT_BLOCK_BY_ID),
    allTrainingModulesCompany: basicReducer(GET_ALL_TRAINING_MODULES_COMPANY),
    trainingModuleById: basicReducer(GET_TRAINING_MODULE_COMPANY),
    saveTrainingModule: basicReducer(SAVE_TRAINING_MODULE_LEARNING_SET),
    deleteTrainingModule: basicReducer(DELETE_TRAINING_MODULE),
    imagesDeleteSoft: basicReducer(IMAGES_DELETE_SOFT),
    imagesDeleteForce: basicReducer(IMAGES_DELETE_FORCE),
    generatingContentProgress: basicReducer(GENERATING_CONTENT_PROGRESS),
    generateContent: basicReducer(GENERATE_CONTENT),
    generateContentForTopic: basicReducer(GENERATE_CONTENT_FOR_TOPIC),
    createCompanyWithCode: basicReducer(CREATE_COMPANY_WITH_CODE),
    deviceRequestsPaginated: basicReducer(GET_DEVICE_REQUESTS_PAGINATED),
    resolveDeviceRequests: basicReducer(RESOLVE_DEVICE_REQUESTS_BULK),
    deleteDeviceRequests: basicReducer(DELETE_DEVICE_REQUESTS_BULK),
    deviceRequestsForUser: basicReducer(GET_DEVICE_REQUESTS_FOR_USER),
});
