import { el } from 'date-fns/locale';
import {
    games,
    contentBlockConstraintsByGame,
    compareCriterias,
    valueCriterias,
    numberCriterias,
} from 'services/content-blocks-management/content-blocks-builder/local-constants';

export function getUniqueCorrectAnswers(data, generateBy, answerAttribute, imageAttribute) {
    let correctAnswers = {};
    for (let i = 0; i < data.rows.length; i++) {
        let currentRow = data.rows[i];

        let currentRowGenerateByValue = currentRow.cells.find(el => el.colId == generateBy.id)?.cellValue;
        let currentRowAnswerValue = currentRow.cells.find(el => el.colId == answerAttribute.id)?.cellValue;
        let currentRowImageValue;
        if (imageAttribute) {
            currentRowImageValue = currentRow.cells.find(el => el.colId == imageAttribute.id)?.cellValue;
        }

        if (!currentRowGenerateByValue || !currentRowAnswerValue || (imageAttribute && !currentRowImageValue)) {
            continue;
        }

        let currentRowGenerateByValueItems = currentRowGenerateByValue.split(';');
        let currentRowAnswerValueItems = currentRowAnswerValue.split(';');

        for (let iCounter = 0; iCounter < currentRowGenerateByValueItems.length; iCounter++) {
            for (let jCounter = 0; jCounter < currentRowAnswerValueItems.length; jCounter++) {
                if (!currentRowGenerateByValueItems[iCounter] || !currentRowAnswerValueItems[jCounter]) {
                    continue;
                }

                let attributeExists = correctAnswers[currentRowGenerateByValueItems[iCounter]] != null;

                if (attributeExists) {
                    let answerIsAdded = correctAnswers[currentRowGenerateByValueItems[iCounter]].answers.find(
                        el => el.value == currentRowAnswerValueItems[jCounter]
                    );
                    if (!answerIsAdded) {
                        correctAnswers[currentRowGenerateByValueItems[iCounter]].answers = [
                            ...correctAnswers[currentRowGenerateByValueItems[iCounter]].answers,
                            { image: currentRowImageValue, value: currentRowAnswerValueItems[jCounter] },
                        ];
                    }
                } else {
                    correctAnswers[currentRowGenerateByValueItems[iCounter]] = {
                        image: currentRowImageValue,
                        answers: [{ image: currentRowImageValue, value: currentRowAnswerValueItems[jCounter] }],
                    };
                }
            }
        }
    }
    return correctAnswers;
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export function getAllUniqueValues(data, column) {
    let allItems = data.rows.flatMap(el => el.cells.find(el => el.colId == column.id)?.cellValue?.split(';'));
    return [...new Set(allItems)].filter(el => el != null && el?.replace(' ', '') != '');
}

export function getIncorrectValues(data, column, correctValues, count) {
    let allUniqueValues = getAllUniqueValues(data, column);

    let allIncorrectValues = allUniqueValues.filter(el => !correctValues.find(elInside => elInside.value == el));

    let shuffledIncorrectValues = shuffleArray(allIncorrectValues);

    return shuffledIncorrectValues.slice(0, count);
}

export function generateAnswers(game, contentBlockData, correctValuesMap, answerAttribute, autoGenerateValue, comparatorCriteria, comparatorCorrect) {
    if ([games.TRAFFIC, games.RACE_CARS, games.RECALL, games.PINPOINT, games.NUMBERS].includes(game)) {
        return generateMultipleChoiceAnswers(game, contentBlockData, correctValuesMap, answerAttribute, autoGenerateValue);
    } else if (games.COMPARE == game) {
        return generateCompareAnswers(
            game,
            contentBlockData,
            correctValuesMap,
            answerAttribute,
            autoGenerateValue,
            comparatorCriteria,
            comparatorCorrect
        );
    } else if (games.SORT_IT == game) {
        return generateSortitAnswers(game, contentBlockData, correctValuesMap, answerAttribute, autoGenerateValue);
    }
}

export function generateExtras(game, correctValuesMap, autoGenerateValue, criteria, correctCriteria) {
    if (game === games.PINPOINT) {
        let image = correctValuesMap[autoGenerateValue].answers[0].image;
        let imageObject = {
            urlOriginal: image,
            urlRatio1x1: image,
            urlRatio2x1: image,
            urlRation4x3: image,
        };
        return { image: imageObject, criteria: null };
    } else if (game === games.COMPARE) {
        if (criteria.value === compareCriterias.VALUE) {
            return { image: null, criteria: correctCriteria.value };
        } else {
            return { image: null, criteria: valueCriterias.BOOLEAN };
        }
    }
}

export function generateQuestionsContentBlock(
    game,
    question,
    contentBlockData,
    correctValuesMap,
    questionAttribute,
    answerAttribute,
    autoGenerateValue,
    comparatorCriteria,
    comparatorCorrect
) {
    if ([games.TRAFFIC, games.RACE_CARS, games.RECALL, games.SORT_IT, games.NUMBERS].includes(game)) {
        return generateMultipleChoiceQuestions(
            game,
            question,
            contentBlockData,
            correctValuesMap,
            questionAttribute,
            answerAttribute,
            autoGenerateValue,
            false
        );
    } else if (games.PINPOINT == game) {
        return generateMultipleChoiceQuestions(
            game,
            question,
            contentBlockData,
            correctValuesMap,
            questionAttribute,
            answerAttribute,
            autoGenerateValue,
            true
        );
    } else if (games.COMPARE === game) {
        return generateCompareQuestions(
            game,
            question,
            contentBlockData,
            correctValuesMap,
            questionAttribute,
            answerAttribute,
            autoGenerateValue,
            comparatorCriteria,
            comparatorCorrect,
            false
        );
    }
}

function generateMultipleChoiceAnswers(game, contentBlockData, correctValuesMap, answerAttribute, autoGenerateValue, hasImage) {
    let answers = [];

    let constraintByGame = contentBlockConstraintsByGame[game];

    for (let indx = 0; indx < correctValuesMap[autoGenerateValue].answers.length; indx++) {
        if (indx >= constraintByGame.maximumCorrectAnswers) {
            break;
        }
        answers.push({ index: indx, element: { text: correctValuesMap[autoGenerateValue].answers[indx].value, isCorrect: true } });
    }

    if (constraintByGame.maximimFalseAnswers == 0) {
        return answers;
    }

    let incorrectValues = getIncorrectValues(
        contentBlockData,
        answerAttribute,
        correctValuesMap[autoGenerateValue].answers,
        constraintByGame.maximimFalseAnswers
    );

    let incorrectAnswersIndexOffset = answers.length;
    incorrectValues.forEach((valIncorrect, indx) => {
        answers.push({ index: indx + incorrectAnswersIndexOffset, element: { text: valIncorrect, isCorrect: false } });
    });

    let minimumInputs = constraintByGame.minimumCorrectAnswers + constraintByGame.minimumFalseAnswers;
    if (answers.length < minimumInputs) {
        let toFillCount = minimumInputs - answers.length;
        let counter = 0;
        answers.push(...Array(toFillCount).fill({ index: answers.length + counter++, element: { text: '', isCorrect: false } }));
    }
    return answers;
}

function generateSortitAnswers(game, contentBlockData, correctValuesMap, answerAttribute, autoGenerateValue, hasImage) {
    let answers = [];

    let constraintByGame = contentBlockConstraintsByGame[game];
    let questionCounter = 0;
    for (let indx = 0; indx < Object.keys(correctValuesMap).length; indx++) {
        let currentValue = Object.keys(correctValuesMap)[indx];
        let imagesValues = correctValuesMap[currentValue].answers.map(el => {
            return {
                urlOriginal: el.value,
                urlRatio1x1: el.value,
                urlRatio2x1: el.value,
                urlRation4x3: el.value,
            };
        });
        if (imagesValues.length < constraintByGame.minimumCorrectAnswers) {
            continue;
        }
        answers.push({ index: questionCounter, element: { text: currentValue, images: imagesValues } });
        questionCounter++;
    }
    return answers;
}

function generateCompareAnswers(game, contentBlockData, correctValuesMap, answerAttribute, autoGenerateValue, comparatorCriteria, comparatorCorrect) {
    let answers = [];
    let constraintByGame = contentBlockConstraintsByGame[game];

    for (let indx = 0; indx < Object.keys(correctValuesMap).length; indx++) {
        let imageValue = Object.keys(correctValuesMap)[indx];
        let imageObject = {
            urlOriginal: imageValue,
            urlRatio1x1: imageValue,
            urlRatio2x1: imageValue,
            urlRation4x3: imageValue,
        };
        let answerValue = correctValuesMap[imageValue].answers[0].value;
        let valueItem = '';
        if (comparatorCriteria.value == compareCriterias.TEXT) {
            valueItem = answerValue.includes(comparatorCorrect) ? 1 : 0;
        } else if (comparatorCriteria.value == compareCriterias.NUMBER) {
            valueItem = valuePassesNumberCriteria(parseInt(answerValue), comparatorCorrect) ? 1 : 0;
        } else {
            valueItem = answerValue;
        }
        answers.push({ index: indx, element: { text: valueItem.toString(), images: [imageObject], isCorrect: true } });
    }
    return answers;
}

function valuePassesNumberCriteria(value, comparatorCorrect) {
    let passesFirst = false;
    let passesSecond = false;

    if (comparatorCorrect.from.value === numberCriterias.LARGER_THAN) {
        if (comparatorCorrect.fromValue < value) passesFirst = true;
    } else if (comparatorCorrect.from.value === numberCriterias.LARGER_THAN_AND_EQUAL_TO) {
        if (comparatorCorrect.fromValue <= value) passesFirst = true;
    }
    if (comparatorCorrect.to.value === numberCriterias.SMALLER_THAN) {
        if (value < comparatorCorrect.toValue) passesSecond = true;
    } else if (comparatorCorrect.to.value === numberCriterias.SMALLER_THAN_AND_EQUAL_TO) {
        if (value <= comparatorCorrect.toValue) passesSecond = true;
    }
    if (comparatorCorrect.criteria === 'OR') return passesFirst || passesSecond;
    else if (comparatorCorrect.criteria === 'AND') return passesFirst && passesSecond;
}

function generateCompareQuestions(
    game,
    question,
    contentBlockData,
    correctValuesMap,
    questionAttribute,
    answerAttribute,
    autogenerateValue,
    comparatorCriteria,
    comparatorCorrect,
    hasImage
) {
    let generatedQuestions = [];

    let constraintByGame = contentBlockConstraintsByGame[game];
    let questionVarItems = Object.keys(correctValuesMap);

    let maximumCorrectPerQuestion = contentBlockConstraintsByGame[game].maximumCorrectAnswers;
    let answers = [];

    for (let i = 0; i < questionVarItems.length; i++) {
        let questionVarValue = questionVarItems[i];

        if (correctValuesMap[questionVarValue].answers.length < constraintByGame.minimumCorrectAnswers) {
            continue;
        }
        let questionObject = {};
        questionObject['question'] = questionVarValue;

        if (comparatorCriteria.value === compareCriterias.VALUE) {
            questionObject['comparatorCriteria'] = comparatorCorrect.value;
        } else {
            questionObject['comparatorCriteria'] = valueCriterias.BOOLEAN;
        }

        let answerValue = correctValuesMap[questionVarValue].answers[0].value;
        let valueItem = '';

        if (comparatorCriteria.value == compareCriterias.TEXT) {
            valueItem = answerValue.includes(comparatorCorrect) ? 1 : 0;
        } else if (comparatorCriteria.value == compareCriterias.NUMBER) {
            valueItem = valuePassesNumberCriteria(parseInt(answerValue), comparatorCorrect) ? 1 : 0;
        } else {
            valueItem = answerValue;
        }

        answers.push({
            answer: questionVarValue,
            value: valueItem,
        });
    }
    generatedQuestions.push({ question: question, answers: answers });

    return generatedQuestions;
}

function generateMultipleChoiceQuestions(
    game,
    question,
    contentBlockData,
    correctValuesMap,
    questionAttribute,
    answerAttribute,
    autoGenerateValue,
    hasImage
) {
    let generatedQuestions = [];

    let constraintByGame = contentBlockConstraintsByGame[game];
    let questionVarItems = Object.keys(correctValuesMap);

    let maximumCorrectPerQuestion = contentBlockConstraintsByGame[game].maximumCorrectAnswers;

    for (let i = 0; i < questionVarItems.length; i++) {
        let questionVarValue = questionVarItems[i];

        if (correctValuesMap[questionVarValue].answers.length < constraintByGame.minimumCorrectAnswers) {
            continue;
        }

        for (let j = 0; j < correctValuesMap[questionVarValue].answers.length; j += maximumCorrectPerQuestion) {
            let questionObject = {};
            let answers = [];
            questionObject['question'] = question.replace('<%' + questionAttribute.value + '%>', questionVarValue);

            let toIndex =
                correctValuesMap[questionVarValue].answers.length < maximumCorrectPerQuestion + j
                    ? correctValuesMap[questionVarValue].answers.length
                    : maximumCorrectPerQuestion + j;
            for (let indx = j; indx < toIndex; indx++) {
                answers.push({
                    answer: correctValuesMap[questionVarValue].answers[indx].value,
                    correct: true,
                });
            }

            let incorrectValues = getIncorrectValues(
                contentBlockData,
                answerAttribute,
                correctValuesMap[questionVarValue].answers,
                constraintByGame.minimumFalseAnswers
            );
            incorrectValues.forEach((valIncorrect, indx) => {
                answers.push({
                    answer: valIncorrect,
                    correct: false,
                });
            });
            questionObject['answers'] = answers;

            if (hasImage) {
                questionObject['image'] = correctValuesMap[questionVarValue].image;
            }

            generatedQuestions.push(questionObject);
        }
    }
    return generatedQuestions;
}
