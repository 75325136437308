export const toDestructedObject = array => {
    if (array.some(item => typeof item !== 'object')) {
        throw new Error('Array does not contain objects');
    }
    return array.reduce((acc, x) => ({ ...acc, ...x }), {});
};

export const randomize = (array, c, defaultElement, repeat = true) => {
    if (!array) {
        throw new Error('No array');
    }
    let count = c || array.length;
    let result = new Array(count);
    if (array.length === 0) {
        result = result.fill(defaultElement);
    } else {
        while (array.length < count) {
            if (repeat) array = [...array, ...array];
            else array = [...array, defaultElement];
        }
        let len = array.length,
            taken = new Array(len),
            n = count;
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = array[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
    }

    return [...result];
};

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function contains(a, obj) {
    var i = a.length;
    while (i--) {
        if (a[i] === obj) {
            return true;
        }
    }
    return false;
}

export function containsId(a, id, idIdentifier) {
    var i = a.length;
    while (i--) {
        if (a[i][idIdentifier] === id) {
            return true;
        }
    }
    return false;
}

export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
}

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function shuffle(array) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}
