import TrainingIcon from 'assets/icons/icon-emptystate-trainings.svg';
import TopicIcon from 'assets/icons/select-topic.svg';
import QuestionsIcon from 'assets/icons/topic-icon-questions.svg';
import GalleryIcon from 'assets/icons/icon-gallery-allimgs.svg';
import UsersIcon from 'assets/icons/users-icon.svg';
import FeedbackIcon from 'assets/icons/feedback.svg';
import NewsIcon from 'assets/icons/icon-news-table.svg';
import CreateNewsIcon from 'assets/icons/icon-emptystate-news.svg';
import NotificationIcon from 'assets/icons/icon-notifications-recipients.svg';
import CreateNotificationIcon from 'assets/icons/emptystate-notification.svg';
import RolesIcon from 'assets/icons/company-role.svg';
import TagsIcon from 'assets/icons/icon-emptystate-tags.svg';
import InfoIcon from 'assets/icons/company-unit.svg';
import AiIcon from 'assets/icons/ai-training-wand-orange.svg';

import Traffic from 'assets/icons/game-icon-traffic.svg';
import Racecars from 'assets/icons/game-icon-race-cars.svg';
import SortIt from 'assets/icons/game-icon-sort-it.svg';
import Compare from 'assets/icons/game-icon-compare.svg';
import Recall from 'assets/icons/game-icon-recall.svg';
import Numbers from 'assets/icons/game-icon-numbers.svg';
import Pinpoint from 'assets/icons/game-icon-pinpoint.svg';
import Sequence from 'assets/icons/game-icon-sequence.svg';

export const shortcutsToIcon = {
    goToTrainings: TrainingIcon,
    goToTopics: TopicIcon,
    goToQuestions: QuestionsIcon,
    goToGallery: GalleryIcon,
    goToUsers: UsersIcon,
    goToFeedback: FeedbackIcon,
    goToNews: NewsIcon,
    createNews: CreateNewsIcon,
    goToNotification: NotificationIcon,
    createNotification: CreateNotificationIcon,
    goToRoles: RolesIcon,
    goToTags: TagsIcon,
    goToInfo: InfoIcon,
    goToAi: AiIcon,
    createTrafficQuestion: Traffic,
    createRacecarsQuestion: Racecars,
    createSortItQuestion: SortIt,
    createCompareQuestion: Compare,
    createRecallQuestion: Recall,
    createNumbersQuestion: Numbers,
    createPinpointQuestion: Pinpoint,
    createSequenceQuestion: Sequence,
};

export const shortcutsToString = {
    goToTrainings: 'Go to trainings',
    goToTopics: 'Go to topics',
    goToQuestions: 'Go to questions',
    goToGallery: 'Go to gallery',
    goToUsers: 'Go to users',
    goToFeedback: 'Go to feedback',
    goToNews: 'Go to news',
    createNews: 'Create news',
    goToNotification: 'Go to notifications',
    createNotification: 'Create notification',
    goToRoles: 'Go to roles',
    goToTags: 'Go to tags',
    goToInfo: 'Go to comp. info',
    goToAi: 'Go to ai generator',
    createTrafficQuestion: 'Create question - Traffic',
    createRacecarsQuestion: 'Create question - Race Cars',
    createSortItQuestion: 'Create question - Sort It',
    createCompareQuestion: 'Create question - Compare',
    createRecallQuestion: 'Create question - Recall',
    createNumbersQuestion: 'Create question - Numbers',
    createPinpointQuestion: 'Create question - Pinpoint',
    createSequenceQuestion: 'Create question - Sequence',
};

export const shortcutsToPath = {
    goToTrainings: '/content/trainings',
    goToTopics: '/content/topics',
    goToQuestions: '/content/questions',
    goToGallery: '/content/gallery',
    goToUsers: '/users/organization',
    goToFeedback: '/internal/content-feedback',
    goToNews: '/internal/news',
    createNews: '/internal/news/create',
    goToNotification: '/internal/notifications',
    createNotification: '/internal/notifications/create',
    goToRoles: '/company/roles',
    goToTags: '/company/tags',
    goToInfo: '/company/info',
    goToAi: '/ai_training',
    createTrafficQuestion: '/content/questions/add/traffic',
    createRacecarsQuestion: '/content/questions/add/race-cars',
    createSortItQuestion: '/content/questions/add/sort-it',
    createCompareQuestion: '/content/questions/add/compare',
    createRecallQuestion: '/content/questions/add/recall',
    createNumbersQuestion: '/content/questions/add/numbers',
    createPinpointQuestion: '/content/questions/add/pinpoint',
    createSequenceQuestion: '/content/questions/add/sequence',
};
