import PropTypes from 'prop-types';
import React from 'react';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import { useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import { validatorCategories } from 'services/validation-module/local-constants';
import { InputWithInfoPopUpWrapper } from 'components/features/content_management/inputs/InputWithInfoPopUpWrapper';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { colors } from 'style/colors';
import Remove from 'assets/icons/icon-remove.svg';
import { Caption1 } from 'style/typography/Caption';
import IndexComponent from 'components/elements/index_component/IndexComponent';
import { useTranslation } from 'react-i18next';

const InputAnswerCheckboxes = ({ handleOnPaste, value, index, isCorrect, showCorrectIncorrect = true, showRemove = true, onlyOneOption = false }) => {
    const { updateAnswer, removeAnswer, resetAllIncorrect } = useMultipleChoiceAnswers();
    const { t } = useTranslation();

    const handleCheck = () => {
        if (onlyOneOption) {
            resetAllIncorrect();
        }
        updateAnswer({ index, element: { text: value, isCorrect: !isCorrect } });
    };

    return (
        <div style={{ position: 'relative' }}>
            <div className="d-flex flex-row">
                <div className={'d-flex flex-row align-items-center'} style={{ margin: '5px 0', height: '60px', gap: '10px' }}>
                    <IndexComponent index={index + 1} />
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <InputWithInfoPopUpWrapper index={index} answer={value}>
                            {({ stopInput, onChange }) => {
                                return (
                                    <CustomTextField
                                        id={'input-option-text-' + index}
                                        type="text"
                                        className="form-control"
                                        placeholder={t('Value')}
                                        width={350}
                                        value={value}
                                        font={15}
                                        onPaste={e => {
                                            if (handleOnPaste) {
                                                handleOnPaste(e, index);
                                            }
                                        }}
                                        setValue={val => updateAnswer({ index, element: { text: val, isCorrect } })}
                                        style={{ marginRight: 10 }}
                                    />
                                );
                            }}
                        </InputWithInfoPopUpWrapper>
                    </div>
                </div>
                {showRemove && (
                    <div className="d-flex align-items-center" style={{ fontSize: '13px' }}>
                        <img alt="Remove" className="cursor-pointer" onClick={() => removeAnswer(index)} height={20} width={20} src={Remove} />
                    </div>
                )}
                {showCorrectIncorrect && (
                    <div className="d-flex align-items-center ml-3">
                        <div style={{ position: 'relative', height: '20px', width: '20px' }}>
                            <Checkbox circular={true} id={'games-correct-checkbox-' + index} onClick={handleCheck} checked={isCorrect}></Checkbox>
                        </div>
                        <Caption1 color={colors.gray6} className="ml-3">
                            {isCorrect ? t('Correct') : t('Incorrect')}
                        </Caption1>
                    </div>
                )}
            </div>
            <ValidationPopup category={validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY} position="center" index={index} indexed />
        </div>
    );
};

InputAnswerCheckboxes.propTypes = {
    handleOnPaste: PropTypes.func,
    index: PropTypes.number,
    isCorrect: PropTypes.bool,
    onlyOneOption: PropTypes.bool,
    showCorrectIncorrect: PropTypes.bool,
    showRemove: PropTypes.bool,
    value: PropTypes.any,
};

export default InputAnswerCheckboxes;
