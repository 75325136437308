import SelectionTooltip from 'components/elements/tooltips/SelectionTooltip';
import CropperModal from 'components/features/content_management/cropping_module/CropperModal';
import React, { useEffect, useState } from 'react';
import { ImageEditorProvider } from 'services/image_management/image-editor-management';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { colors } from 'style/colors';
import _ from 'lodash';

import Camera from 'assets/icons/camera.svg';
import ReplaceImage from 'assets/icons/icon-replaceimage.svg';
import RemoveImage from 'assets/icons/icon-removeimage.svg';
import { useContentBlock } from 'services/content-blocks-management/content-blocks';
import { create_UUID } from 'services/utils/stringHelpers';

export default function ContentBlocksTableCell({ index, row, col, colName, colId, generatedColId, rowId, generatedRowId, onMouseDown, onMouseEnter, onCopy, cellSelected, cellValue, cellType }) {
    const [inputValue, setInputValue] = useState(cellValue);
    const [isImageResizerOpen, setIsImageResizerOpen] = useState(false);
    const [showImageDialog, setShowImageDialog] = useState(false);

    useEffect(() => {
        setInputValue(cellValue);
    }, [cellValue]);

    function setToShowDialog(toShow) {
        setTimeoutLastExecute.addCallback(
            () => {
                setShowImageDialog(toShow);
            },
            50,
            `showImageCellDialog${index}`
        );
    }

    const { setState: setData, contentBlock, pasteMultipleValuesInRow, addImages, removeImage, columnWidths, selectedEdges,updateCell } = useContentBlock();

    var borders = {
        top: row == selectedEdges.topEndIndx && cellSelected,
        bottom: row == selectedEdges.bottomEndIndx && cellSelected,
        left: col == selectedEdges.leftEndIndx && cellSelected,
        right: col == selectedEdges.rightEndIndx && cellSelected,
    };

    return (
        <div>
            {cellType != 'image' && (
                <div>
                    <input
                        value={inputValue != '' ? inputValue : ''}
                        style={{
                            width: columnWidths[colName],
                            height: '50px',
                            backgroundColor: cellSelected ? colors.gray4 : 'white',
                            borderLeft: borders.left ? `dashed 1.5px ${colors.mainTheme}` : `solid 0.5px ${colors.gray3}`,
                            borderRight: borders.right ? `dashed 1.5px ${colors.mainTheme}` : `solid 0.5px ${colors.gray3}`,
                            borderTop: borders.top ? `dashed 1.5px ${colors.mainTheme}` : `solid 0.5px ${colors.gray3}`,
                            borderBottom: borders.bottom ? `dashed 1.5px ${colors.mainTheme}` : `solid 0.5px ${colors.gray3}`,
                            paddingLeft: !borders.left ? '6px' : '5px',
                            paddingTop: !borders.top ? '1px' : '0px',
                            paddingBottom: !borders.bottom ? '1px' : '0px',
                            paddingRight: !borders.right ? '1px' : '0px',
                            outline: 'none',
                            cursor: 'pointer',
                        }}
                        onMouseDown={onMouseDown}
                        onMouseEnter={onMouseEnter}
                        onPaste={e => {
                            e.persist();
                            pasteMultipleValuesInRow(row, col, e, null);
                        }}
                        onCopy={onCopy}
                        onChange={e => {
                            let val = e.target.value;
                            setInputValue(e.target.value);
                            setTimeoutLastExecute.addCallback(
                                () => {
                                    updateCell(val, rowId, generatedRowId, colId, generatedColId);
                                },
                                500,
                                `cellChangeValue-${index}`
                            );
                        }}
                    />
                </div>
            )}
            {cellValue && cellType == 'image' && (
                <img
                    onMouseEnter={() => setToShowDialog(true)}
                    onMouseLeave={() => setToShowDialog(false)}
                    src={cellValue}
                    style={{ objectFit: 'contain', backgroundColor: colors.gray2 }}
                    height={50}
                    width={columnWidths[colName]}
                />
            )}

            {!cellValue && cellType == 'image' && (
                <div
                    onClick={() => {
                        setIsImageResizerOpen(true);
                    }}
                    style={{
                        border: `1px solid ${colors.gray1}`,
                        padding: 20,
                        cursor: 'pointer',
                        width: columnWidths[colName],
                        height: 50,
                        display: 'flex',
                        backgroundColor: colors.gray2,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img src={Camera} width={25} height={25} />
                </div>
            )}
            {isImageResizerOpen && (
                <ImageEditorProvider index={'index'} setOpenImageResizer={setIsImageResizerOpen} imageLimit={1}>
                    <CropperModal
                        index={'index'}
                        onImageUploaded={images => {
                            addImages(images, row, colId);
                        }}
                        setOpenImageResizer={() => { }}
                        setHasLoader={() => { }}
                    ></CropperModal>
                </ImageEditorProvider>
            )}
            {cellValue && showImageDialog && (
                <div style={{ transform: 'translateX(-25%)', overflow: 'visible' }}>
                    <SelectionTooltip
                        visible={showImageDialog}
                        onMouseEnter={() => setToShowDialog(true)}
                        onMouseLeave={() => setToShowDialog(false)}
                        position={'bottom'}
                        marginTop={10}
                        options={[
                            {
                                text: 'Replace',
                                icon: ReplaceImage,
                                type:"button",
                                onClick: () => {
                                    setIsImageResizerOpen(true);
                                    setToShowDialog(false);
                                },
                            },
                            {
                                text: 'Remove',
                                icon: RemoveImage,
                                type:"button",
                                onClick: () => {
                                    removeImage(row, colId);
                                    setToShowDialog(false);
                                },
                            },
                        ]}
                    />
                </div>
            )}
        </div>
    );
}
