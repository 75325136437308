import { useEffect, useState } from 'react';
import _ from 'lodash';
import { getColWidthByType } from 'static/content_blocks';
import { useContentBlock } from 'services/content-blocks-management/content-blocks';
import { create_UUID } from 'services/utils/stringHelpers';
import { useDispatch } from 'react-redux';
import { saveContentBlock } from 'redux/actions/content.actions';

export default function useContentBlocksState() {
    const {
        contentBlock,
        setRowsToShow,
        columnWidths,
        setColumnWidths,
        selectedEdges,
        setSelectedEdges,
        firstCell,
        currentCell,
        setState,
    } = useContentBlock();

    const [deleteItems, setDeleteItems] = useState(false);

    const dispatch = useDispatch();

    function getContentBlockDataFormated(data) {
        let columnsOrderCurrent = 0;
        let cellsId = 0;
        let rowOrderCurrent = 1;

        let tempColToUUID = {};

        return {
            columns: data.columns.map(el => {
                let uniqueUUIDCol = create_UUID();
                tempColToUUID[el.key] = uniqueUUIDCol;
                return {
                    generatedId: uniqueUUIDCol,
                    colOrder: columnsOrderCurrent++,
                    blockId: 1,
                    type: el.type,
                    key: el.key,
                    name: el.value,
                }
            }), rows: data.rows.map(el => {
                let uniqueUUIDRow = create_UUID();
                let items = Object.keys(el).map(elObj => {
                    let uniqueUUIDCell = create_UUID();
                    return {
                        generatedId: uniqueUUIDCell,
                        generatedColId: tempColToUUID[elObj],
                        generatedRowId: uniqueUUIDRow,
                        cellValue: el[elObj],
                    }
                })
                return {
                    generatedRowId: uniqueUUIDRow, rowOrder: rowOrderCurrent++, cells: items
                };
            }),
        }
    }

    var onJsonFileUploaded = file => {
        const reader = new FileReader();
        reader.onload = reader => {
            let contents = reader.target.result;
            let contentsObject = JSON.parse(contents);
            let data = getContentBlockDataFormated(contentsObject);
            dispatch(saveContentBlock({ name: "Springwell", data: data, id: -1 }));
            // setColumnWidths({});
            // setState(contentsObject);
        };
        reader.readAsText(file);
    };
    var onCSVFileUploaded = file => {
        const reader = new FileReader();
        reader.onload = reader => {
            let contents = reader.target.result;
            let rows = contents.split(/\n(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            let columns = [];
            let rowsItems = [];
            for (let i = 0; i < rows.length; i++) {
                let itemsInRow = rows[i].split(/;(?=(?:(?:[^"]*"){2})*[^"]*$)/);

                let rowUuid = create_UUID();
                let currentRowObject = {};
                let currentRowCells = [];
                for (let j = 0; j < itemsInRow.length; j++) {
                    let cellValueFormated = itemsInRow[j];
                    let toRemove = cellValueFormated.substring(0, 1) === '"' && cellValueFormated.substring(cellValueFormated.length - 1) === '"';
                    if (toRemove) {
                        cellValueFormated = cellValueFormated.substring(1, cellValueFormated.length - 1);
                    }
                    if (i == 0) {
                        columns.push({
                            generatedId: create_UUID(),
                            key: itemsInRow[j].toLowerCase().replace(' ', '_'),
                            colOrder: 0,
                            name: itemsInRow[j],
                            type: 'text',
                        });
                    } else {
                        let colId = columns[j].generatedId;
                        currentRowCells.push({
                            generatedId: create_UUID(),
                            generatedColId: colId,
                            generatedRowOrder: rowUuid,
                            cellValue: cellValueFormated,
                        });
                    }
                }
                if (i == 0) continue;
                
                currentRowObject = {
                    generatedRowId: rowUuid,
                    rowOrder: 0,
                    cells: currentRowCells
                }
                if (Object.keys(currentRowObject).length > 0) {
                    rowsItems.push(currentRowObject);
                }
            }
            setColumnWidths({});
            setState({ columns: columns, rows: rowsItems });
        };
        reader.readAsText(file);
    };

    let isInsideSelection = (row, col) => {
        if (currentCell != null && firstCell != null) {
            let passesRow = selectedEdges.topEndIndx <= row && selectedEdges.bottomEndIndx >= row;
            let passesCol = selectedEdges.leftEndIndx <= col && selectedEdges.rightEndIndx >= col;
            return passesRow && passesCol;
        }
        return false;
    };

    let copyCells = () => {
        let stringToCopy = '';
        for (let i = selectedEdges.topEndIndx; i <= selectedEdges.bottomEndIndx; i++) {
            for (let j = selectedEdges.leftEndIndx; j <= selectedEdges.rightEndIndx; j++) {
                let currentColId = contentBlock.data.columns[j].id;
                stringToCopy += contentBlock.data.rows[i].cells.find(el => el.colId == currentColId)?.cellValue ?? '';
                if (j != selectedEdges.rightEndIndx) {
                    stringToCopy += '\t';
                }
            }
            if (i != selectedEdges.bottomEndIndx) {
                stringToCopy += '\n';
            }
        }
        navigator.clipboard.writeText(stringToCopy);
    };

    useEffect(() => {
        if (Object.keys(columnWidths).length === 0) {
            let colWidths = {};
            for (let i = 0; i < contentBlock.data.columns.length; i++) {
                colWidths[contentBlock.data.columns[i].key] = getColWidthByType(contentBlock.data.columns[i].type);
            }
            setColumnWidths(colWidths);
        }
    }, [contentBlock.data]);

    useEffect(() => {
        let isOneCellSelected = selectedEdges.bottomEndIndx == selectedEdges.topEndIndx && selectedEdges.leftEndIndx == selectedEdges.rightEndIndx;
        if (deleteItems && !isOneCellSelected) {
            let newData = _.cloneDeep(contentBlock.data);

            for (let i = selectedEdges.topEndIndx; i <= selectedEdges.bottomEndIndx; i++) {
                for (let j = selectedEdges.leftEndIndx; j <= selectedEdges.rightEndIndx; j++) {
                    let currentColId = newData.columns[j].id;
                    let foundCell = newData.rows[i].cells.find(el => el.colId == currentColId)

                    if (foundCell) {
                        newData.rows[i].cells.find(el => el.colId == currentColId).cellValue = '';
                    }
                }
            }
            setState({
                columns: newData.columns,
                rows: [...newData.rows],
            });
        }

        setTimeout(() => {
            setDeleteItems(false);
        }, 10);
    }, [deleteItems]);

    useEffect(() => {
        if (firstCell == null || currentCell == null) {
            return;
        }

        let firstCellVal = firstCell.split(',').map(el => parseInt(el));
        let secondCellVal = currentCell.split(',').map(el => parseInt(el));

        setSelectedEdges({
            leftEndIndx: firstCellVal[1] >= secondCellVal[1] ? secondCellVal[1] : firstCellVal[1],
            rightEndIndx: firstCellVal[1] >= secondCellVal[1] ? firstCellVal[1] : secondCellVal[1],
            topEndIndx: firstCellVal[0] >= secondCellVal[0] ? secondCellVal[0] : firstCellVal[0],
            bottomEndIndx: firstCellVal[0] >= secondCellVal[0] ? firstCellVal[0] : secondCellVal[0],
        });
    }, [firstCell, currentCell]);

    return {
        onJsonFileUploaded: onJsonFileUploaded,
        onCSVFileUploaded: onCSVFileUploaded,
        isInsideSelection: isInsideSelection,
        copyCells: copyCells,
        deleteItems: deleteItems,
        setDeleteItems: setDeleteItems,
    };
}
