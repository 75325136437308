import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useContentInsertionQuestion } from 'services/content-management/insertion-control';
import PreviewContent from 'components/features/content_management/PreviewContent';
import GameHeader from '../../games_preview/GameHeader';
import { GameQuestionCenter } from 'style/styled/GameElements';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { shuffle } from 'lodash';

import './Sequence.css';

export default function SequencePreview({ answers, setAnswers, answersList, sequenceInterval }) {
    const { question } = useContentInsertionQuestion();
    const [randomArr, setRandomArr] = useState([]);
    const lastRandomArr = useRef(null);
    const [pressGo, setPressGo] = useState(false);

    useEffect(() => {
        if (pressGo) {
            setTimeout(() => {
                setPressGo(false);
                setRandomArr(shuffle(answersList.map((el, index) => index)));
            }, 500);
        }
    }, [answersList, pressGo]);

    useEffect(() => {
        let sorted = answersList.sort((a, b) => {
            return a?.position - b?.position;
        });
        setAnswers([...sorted]);

        setRandomArr(shuffle(answersList.map((el, index) => index)));
    }, [answersList]);

    useEffect(() => {
        lastRandomArr.current = randomArr;
        if (!sequenceInterval.current) {
            sequenceInterval.current = setInterval(() => {
                let good = true;
                for (let i = 0; i < lastRandomArr.current.length; i++) {
                    if (lastRandomArr.current[i] != i) {
                        let indexOfTmp = lastRandomArr.current.indexOf(i);

                        let beforeChange = JSON.parse(JSON.stringify(lastRandomArr.current));
                        setTimeout(() => {
                            for (let j = indexOfTmp - 1; j >= i; j--) {
                                beforeChange[j + 1] = beforeChange[j];
                            }
                            beforeChange[i] = i;
                            setRandomArr([...beforeChange]);
                        }, 500);
                        good = false;
                        break;
                    }
                }
                if (good) {
                    clearInterval(sequenceInterval.current);
                    sequenceInterval.current = false;
                    lastRandomArr.current = false;
                    setPressGo(true);
                }
            }, 2000);
        }

        //eslint-disable-next-line
    }, [randomArr]);

    return (
        <PreviewContent game={'Sequence'}>
            <div style={{ flex: 1 }} className="d-flex flex-column">
                <GameHeader />
                <GameQuestionCenter>{question || 'Question'}</GameQuestionCenter>
                <div style={{ flex: 1 }} className="mx-3 d-flex flex-column justify-content-center">
                    <div style={{ marginBottom: 50, height: 180, overflow: 'none', position: 'relative' }}>
                        {answers.map((ans, index) => (
                            <div
                                className={`d-flex flex-row mt-2 text-center align-items-center pt-3 pb-3 ${
                                    ans.isCorrect ? 'active' : ''
                                } sequence--drag-preview-container`}
                                key={`race-cars-option-${ans.id}`}
                                style={{ bottom: (answers.length - randomArr.indexOf(index)) * 40 - answers.length * 10 }}
                            >
                                <DragHandleIcon></DragHandleIcon>

                                <div>{ans.text || `Option ${ans.id + 1} ${index}`}</div>
                            </div>
                        ))}
                    </div>
                    <div style={{ marginBottom: 25 }} className="d-flex justify-content-center">
                        <div className="d-flex justify-content-center align-items-center sequence--go-button" style={{ opacity: pressGo ? 0.5 : 1 }}>
                            <div style={{ color: 'white' }}>GO!</div>
                        </div>
                    </div>
                    {pressGo && <div className="sequence--correct-container">Is correct!</div>}
                </div>
            </div>
        </PreviewContent>
    );
}

SequencePreview.propTypes = {
    answers: PropTypes.array,
    answersList: PropTypes.array,
    sequenceInterval: PropTypes.any,
    setAnswers: PropTypes.func,
};
