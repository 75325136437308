import React, { useState, useEffect, useRef } from 'react';
import PreviewContent from 'components/features/content_management/PreviewContent';
import GameHeader from 'containers/layout/module/pages/content/questions/question_editor/games_preview/GameHeader';
import { GameQuestionCenter } from 'style/styled/GameElements';
import { useContentInsertionQuestion, useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import Cars from 'assets/game-previews/racecars-road.png';
import { optionDefaults } from 'services/content-management/insertion-control/local-constants';
import { randomize } from 'services/utils/arrayHelpers';
import { Caption2 } from 'style/typography/Caption';

export default function RaceCarsPreview() {
    const { question } = useContentInsertionQuestion();
    const { answers: answersList } = useMultipleChoiceAnswers();

    const [answers, setAnswers] = useState([]);

    const previousAnswersLength = useRef(0);
    const randomAnswers = useRef(randomize(answersList, 3, optionDefaults.default));

    useEffect(() => {
        if (answers.length === 0) {
            let result = randomize(answersList);
            setAnswers(result);
            randomAnswers.current = result;
        } else if (previousAnswersLength.current !== answersList.length) {
            let result = randomize(answersList);
            setAnswers(result);
            randomAnswers.current = result;
        } else {
            let ansNew = randomAnswers.current;
            ansNew.forEach(an => {
                let item = answersList.find(a => a.id === an.id);
                if (item) {
                    an.text = item.text;
                    an.isCorrect = item.isCorrect;
                }
            });
            setAnswers([...randomAnswers.current]);
        }
        previousAnswersLength.current = answersList.length;
        //eslint-disable-next-line
    }, [answersList]);

    return (
        <PreviewContent game={'Race Cars'}>
            <div className="d-flex flex-column" style={{ height: '100%' }}>
                <GameHeader />
                <div className="d-flex justify-content-center mt-2">
                    <img src={Cars} style={{ width: '30%' }} alt="race cars road" />
                </div>
                <GameQuestionCenter>{question || 'Question'}</GameQuestionCenter>
                <div style={{ flex: 1 }} className="mx-3 d-flex flex-column justify-content-center">
                    <div>
                        {answers.map((ans, index) => (
                            <Caption2 className={`race-cars-option mt-2 text-center ${ans.isCorrect ? 'active' : ''}`} key={`race-cars-option-${ans.id}`}>
                                {ans.text || `Option ${ans.id + 1} ${index}`}
                            </Caption2>
                        ))}
                    </div>
                </div>
            </div>
        </PreviewContent>
    );
}
