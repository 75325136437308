import React, { useEffect, useState } from 'react';
import { colors } from 'style/colors';
import { languageToIcon, languageToString } from 'static/language';
import { useTranslation } from 'react-i18next';
import { Body2 } from 'style/typography/Body';

import CaretIcon from 'assets/icons/filter-caret.svg';

import './Header.css';

const LanguagePicker = () => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    function handleClickOutside(event) {
        if (isOpen && event.target && !(event.target?.id === 'language-picker')) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div>
            <div className="header--language" onClick={() => setIsOpen(true)}>
                <Body2 color={colors.gray6} weight="bold">
                    {i18n.resolvedLanguage?.toUpperCase()}
                </Body2>
                <img src={CaretIcon} height={17} width={17} />
            </div>
            <div className="header--language-picker" style={{ display: isOpen ? 'flex' : 'none' }}>
                {Object.keys(languageToIcon).map(key => {
                    return (
                        <div
                            className="header--language-picker-item"
                            id="language-picker"
                            onClick={() => {
                                i18n.changeLanguage(key);
                                setIsOpen(false);
                            }}
                        >
                            <img src={languageToIcon[key]} height={30} width={30} alt="flag" /> {languageToString[key]}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default React.memo(LanguagePicker);
