import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useThemePalette } from 'style/theme/consumers';
import { CSSTransitionGroup } from 'react-transition-group';
import ErrorTooltip from 'components/elements/tooltips/ErrorTooltip';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';

const InvitationFormMultiple = ({ formObj, usersInvited, onChangeEmail, onChangeRole, company, onRemoveRow, showRemove, hasRoles }) => {
    const { t } = useTranslation();

    const roles = useSelector(state => state?.userManagement?.roles?.data?.roles);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState('l');
    const [showEmailError, setShowEmailError] = useState(false);
    const [showRoleError, setShowRoleError] = useState(false);

    const [roleValue, setRoleValue] = useState('');

    const emailRef = useRef();
    const roleRef = useRef();
    const companyRoleRef = useRef();

    const palette = useThemePalette();

    const nextError = useSelector(state => state.userManagement.nextUserUploadError);

    const handleChangeEmail = event => {
        onChangeEmail(formObj, event.target.value);
        setShowEmailError(false);
    };

    const handleChangeRole = event => {
        onChangeRole(formObj, event.target.value);
        setShowRoleError(false);
    };

    useEffect(() => {
        if (stateIsLoaded(nextError)) {
            if (formObj.formId === nextError.data.id) {
                setErrorMessage(nextError.data.info.message);
                if (nextError.data.info.type === 'email') {
                    emailRef.current.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    });
                    setShowEmailError(true);
                }
                if (nextError.data.info.type === 'role' && roleRef.current) {
                    roleRef.current.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    });
                    setShowRoleError(true);
                }
            }
        }
        //eslint-disable-next-line
    }, [nextError]);

    useEffect(() => {
        if (showEmailError) {
            setShowEmailError(false);
        }
        if (showRoleError) {
            setShowRoleError(false);
        }
        //eslint-disable-next-line
    }, [usersInvited]);

    return (
        <div className="col-12" style={{ zIndex: 99 - formObj.formId }}>
            <div className="row d-flex flex-row align-items-center">
                <div className={`upload-users-email-wrapper mx-2`} data-tip={errorMessage} ref={emailRef} style={{ position: 'relative', flex: 5 }}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id={'import-role-email-' + formObj.formId}
                            error={showEmailError}
                            label={t('Email')}
                            value={formObj.email || ''}
                            onChange={handleChangeEmail}
                            variant="filled"
                            size="small"
                        />
                    </FormControl>
                    <CSSTransitionGroup transitionName="tooltip" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                        {showEmailError && <ErrorTooltip message={errorMessage} />}
                    </CSSTransitionGroup>
                </div>
                {hasRoles && (
                    <div className="mx-2" style={{ flex: 4, position: 'relative' }} ref={roleRef}>
                        <FormControl className={classes.formControl} variant="filled" margin="dense" id={'invite-label-model-' + formObj.formId}>
                            <InputLabel>{t('Role')}</InputLabel>
                            <Select
                                label={t('Role')}
                                labelId="invite-role-label"
                                value={formObj.role || ''}
                                onChange={handleChangeRole}
                                error={showRoleError}
                            >
                                {roles?.map(r => {
                                    return (
                                        <MenuItem id={'invite-users-' + r.roleName} key={r.roleName} value={r.roleName || ''}>
                                            {r.roleName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <CSSTransitionGroup transitionName="tooltip" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                            {showRoleError && <ErrorTooltip message={errorMessage} />}
                        </CSSTransitionGroup>
                    </div>
                )}
                {showRemove && (
                    <div className="mx-2 text-center" id="remove-user-role">
                        <Caption1 color={colors.gray6} className="cursor-pointer" onClick={() => onRemoveRow(formObj.formId)}>
                            {t('Remove')}
                        </Caption1>
                    </div>
                )}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    input: {
        backgroundColor: 'white',
    },
}));

export default React.memo(InvitationFormMultiple);
