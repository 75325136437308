import React from 'react';

import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { useDispatch } from 'react-redux';
import { authService } from 'services/auth/auth';
import CloseWhite from 'assets/icons/filter-close-white.svg';
import { Body1, Body2 } from 'style/typography/Body';
import { SetTagsBulkModal } from '../../../organization_users/local_components/modals/SetTagsModal';
import { addUsersToTagsAction, removeUserFromTagAction } from 'redux/actions/tags.actions';
import { useTranslation } from 'react-i18next';
import { OutlinedButton } from 'components/elements/Button';

export default function ProfileTagsSetting({ isTagModalOpen, setIsTagModalOpen, tags, id }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div>
            {isTagModalOpen && (
                <SetTagsBulkModal
                    modalOpen={isTagModalOpen}
                    setModalOpen={setIsTagModalOpen}
                    onAction={selectedTags => {
                        if (selectedTags.length > 0) {
                            dispatch(addUsersToTagsAction([id.toString()], selectedTags, false));
                        }
                    }}
                    allUsersSelected={false}
                    usersIdsList={[authService.user.id]}
                    onClose={success => {}}
                    bulkItemsCount={1}
                />
            )}
            <div className="py-4" style={{ borderBottom: `solid 1px ${colors.disabledColor}` }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Body1 className="mb-2">{t('Tags')}</Body1>
                    <OutlinedButton onClick={() => setIsTagModalOpen(true)} text={t('+ Add Tags')} />
                </div>
                <div style={{ display: 'flex', flexDirections: 'row', flexWrap: 'wrap', gap: '5px' }}>
                    {!tags || (tags.length == 0 && <Body2 color={colors.gray6}>{t("User doesn't have any tags")}</Body2>)}
                    {tags.length > 0 &&
                        tags.map(el => (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    backgroundColor: colors.gray1,
                                    borderRadius: '100px',
                                    padding: '3px 10px',
                                    color: 'white',
                                }}
                            >
                                <Body2 color={colors.white}>{el.tag}</Body2>
                                <img
                                    alt="Close"
                                    className="cursor-pointer"
                                    style={{ marginLeft: '3px' }}
                                    src={CloseWhite}
                                    height={15}
                                    width={15}
                                    onClick={() => {
                                        dispatch(removeUserFromTagAction(el.tagId, id));
                                    }}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

ProfileTagsSetting.propTypes = {
    id: PropTypes.any,
    isTagModalOpen: PropTypes.any,
    setIsTagModalOpen: PropTypes.func,
    tags: PropTypes.any,
};
