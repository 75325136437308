import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { useTranslation } from 'react-i18next';
import { Body1, Body2 } from 'style/typography/Body';
import { useDispatch, useSelector } from 'react-redux';
import { setLatestNewsSeenAction } from 'redux/actions/content.actions';
import { stateIsLoaded, stateIsNotInitialized } from 'redux/core/stateHelpers';
import CaretIcon from 'assets/icons/filter-caret.svg';

import './Header.css';
import { H8 } from 'style/typography/Heading';

export function NewsPopupModal({ modalOpen, setModalOpen, onAction, onClose }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [currentArticle, setCurrentArticle] = useState(0);
    const [currentNews, setCurrentNews] = useState(0);

    const latestUpdatesState = useSelector(state => state.content.latestUpdates);

    const hasSeenAll = stateIsNotInitialized(latestUpdatesState) || (stateIsLoaded(latestUpdatesState) && latestUpdatesState?.data?.length === 0);
    const article = latestUpdatesState?.data?.[currentNews]?.articles?.[currentArticle];

    const buttons = [
        {
            title: hasSeenAll ? t('To help center') : t('Read more'),
            color: colors.white,
        },
    ];

    const articleCount = latestUpdatesState?.data?.[currentNews]?.articles.length;
    const newsCount = latestUpdatesState?.data.length;

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onClose={() => {
                if (!hasSeenAll) {
                    dispatch(setLatestNewsSeenAction());
                }
            }}
            onAction={() => {
                if (!hasSeenAll) {
                    dispatch(setLatestNewsSeenAction());
                }
                window.open('https://www.getcoach.app/help-center-release-notes/', '_blank');
                setModalOpen(false);
            }}
            width={hasSeenAll ? '40vw' : '55vw'}
            title={null}
            buttons={buttons}
            hasCancel={true}
            alert={
                <div className="header--news-popup-container">
                    <H8 weight="medium">
                        {hasSeenAll
                            ? t('All caught up!')
                            : t("Here is what's new in the Admin Panel ({{currentNews}} of {{newsCount}})", {
                                  currentNews: currentNews + 1,
                                  newsCount: newsCount,
                              })}
                    </H8>
                    {!hasSeenAll && (
                        <div>
                            <div className="version">
                                <Body2 weight="medium" color={colors.gray6}>
                                    {article?.title}
                                </Body2>
                                <Body2 weight="light" color={colors.gray6}>
                                    {t('(article {{currentArticle}} of {{articleCount}})', {
                                        currentArticle: currentArticle + 1,
                                        articleCount: articleCount,
                                    })}
                                </Body2>
                            </div>
                            <div className="header--news-popup-articles">
                                <img
                                    alt="caret"
                                    src={CaretIcon}
                                    height={30}
                                    width={30}
                                    style={{ transform: 'rotateZ(90deg)', cursor: 'pointer', userSelect: 'none' }}
                                    onClick={() => {
                                        if (currentArticle > 0) {
                                            setCurrentArticle(prevState => prevState - 1);
                                        } else if (currentNews > 0) {
                                            setCurrentArticle(latestUpdatesState?.data?.[currentNews - 1]?.articles.length - 1);
                                            setCurrentNews(prevState => prevState - 1);
                                        }
                                    }}
                                />
                                <div className="header--news-popup-story">
                                    <Body1 style={{ whiteSpace: 'pre-line' }}>{article?.story}</Body1>
                                    {article?.image && <img src={article.image} width={'80%'} style={{ objectFit: 'cover', marginTop: 20 }} />}
                                </div>
                                <img
                                    alt="caret"
                                    src={CaretIcon}
                                    height={30}
                                    width={30}
                                    style={{ transform: 'rotateZ(-90deg)', cursor: 'pointer', userSelect: 'none' }}
                                    onClick={() => {
                                        if (currentArticle < articleCount - 1) {
                                            setCurrentArticle(prevState => prevState + 1);
                                        } else if (currentNews < newsCount - 1) {
                                            setCurrentArticle(0);
                                            setCurrentNews(prevState => prevState + 1);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {hasSeenAll && (
                        <Body1 color={colors.gray6} style={{ marginTop: 20 }}>
                            {t('To see the previous updates, visit our help center.')}
                        </Body1>
                    )}
                </div>
            }
        ></ModalDialog>
    );
}

NewsPopupModal.propTypes = {
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
