import PropTypes from 'prop-types';
import React from 'react';
import InputContent from 'components/features/content_management/inputs/InputContent';
import InputImageCategories from 'components/features/content_management/inputs/InputImageCategories';
import { Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import Add from 'assets/icons/icon-add.svg';
import { useImageCategories } from 'services/content-management/insertion-control';
import { useTranslation } from 'react-i18next';

export default function SortItInput({ game, id, filters, initializeQuestion }) {
    const {t} = useTranslation();

    const { answers: categories, insertAnswer: insertCategory } = useImageCategories();

    return (
        <InputContent initializeQuestion={initializeQuestion} game={game} id={id} filters={filters}>
            <div className="mt-4">
                <div>
                    <Caption1 color={colors.gray6}>{t("OPTIONS")}</Caption1>
                </div>
                {categories.map((cat, index) => {
                    return (
                        <InputImageCategories
                            id="question-sort-it"
                            key={`input-image-categories-${cat.id}`}
                            value={cat.text}
                            index={index}
                            images={cat?.images ? [...cat?.images?.map(el => el)] : []}
                            showRemove={categories.length > 2}
                        />
                    );
                })}
                {categories.length < 7 && (
                    <div className="d-flex flex-row align-content-center my-4 cursor-pointer" onClick={insertCategory}>
                        <img alt="Add" height={20} width={20} src={Add} />
                        <Body2 id="add-option-button-compare" className="ml-2">
                            {t("Add New")}
                        </Body2>
                    </div>
                )}
            </div>
        </InputContent>
    );
}

SortItInput.propTypes = {
    filters: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
    initializeQuestion: PropTypes.func,
};
