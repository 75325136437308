import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { getFlatDataFromTree, getTreeFromFlatData } from 'components/features/trees/SortableTree';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import {
    getAllImagesAction,
    getImagesFromFolderAction,
    getUncategorizedImagesAction,
    resetSaveImageToFolder,
    saveImageToFolder,
    updateImageAction,
} from 'redux/actions/image.actions';
import { Button } from '@material-ui/core';
import { colors } from 'style/colors';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { Body2 } from 'style/typography/Body';
import { H8 } from 'style/typography/Heading';
import { ContainedButton } from 'components/elements/Button';
import { Circular } from 'components/features/data_table/Circular';
import { tabs, views } from 'services/image_management/image-editor-management/local-constants';
import SelectFolder from './select_folder/SelectFolder';
import { defaultNode } from 'static/default_nodes';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'assets/icons/filter-close.svg';

import _ from 'lodash';

import './SortableTreeCustom.css';

export function SaveImages({ addImage, setOpenSaveImages, saveImages, index, setModalOpen }) {
    const { t } = useTranslation();

    const { setView, imageFiles, setImageFiles, currentPage, currentNode, currentTab, imageSearchValue, size, sortBy } = useImageEditor();

    const [treeData, setTreeData] = useState([]);
    const loadedImagesRef = useRef(0);

    const [currentNodeFolder, setCurrentNodeFolder] = useState(null);
    const folderStructure = useSelector(state => state.content.folderStructure);
    const [loader, setLoader] = useState(false);
    const [treeTemp, setTreeTemp] = useState(null);
    const dispatch = useDispatch();

    const getNodeKey = function(node) {
        return node.treeIndex;
    };
    function getParentKeyNew(node) {
        return node.parentNode ? node.parentNode.treeIndex : -1;
    }

    function convertFromFlatToTree(data) {
        let myFinalResult = [];

        data.forEach((value, index) => {
            let node = {
                ...defaultNode,
                treeIndex: value.treeIndex,
                title: value.title,
                parentNode: value.parentNode,
                new: value.main,
                main: value.main,
                imageList: value.imageList,
            };
            if (node.parentNode.treeIndex === null) {
                node.parentNode.treeIndex = 0;
            }
            myFinalResult.push(node);
        });

        let myTree = getTreeFromFlatData({
            flatData: myFinalResult,
            getKey: getNodeKey,
            getParentKey: getParentKeyNew,
            rootKey: '0',
        });

        setTreeData(myTree);
    }

    if (stateIsLoaded(folderStructure) && treeData.length === 0 && folderStructure.data.length > 0) {
        convertFromFlatToTree(folderStructure.data);
    }

    function resetStates() {
        setModalOpen(false);
        setLoader(false);
        setOpenSaveImages(false);
        setImageFiles({ index: index, files: [], filesBlob: [] });
        dispatch(resetSaveImageToFolder());
    }

    function onUploadImageCallback(croppedImgFiles, el) {
        loadedImagesRef.current += 1;

        if (loadedImagesRef.current == croppedImgFiles.length) {
            resetStates();

            if (currentTab === tabs.folder && currentNode) {
                dispatch(
                    getImagesFromFolderAction({
                        folderId: currentNode.treeIndex,
                        page: currentPage,
                        size: size,
                        searchQuery: imageSearchValue,
                        sortBy: sortBy,
                    })
                );
            } else if (currentTab === tabs.all_images) {
                dispatch(getAllImagesAction({ page: currentPage, size: size, searchQuery: imageSearchValue, sortBy: sortBy }));
            } else if (currentTab === tabs.uncategorized) {
                dispatch(getUncategorizedImagesAction({ page: currentPage, size: size, searchQuery: imageSearchValue, sortBy: sortBy }));
            }

            if (addImage) {
                addImage(index, el.data);
            }
        }
    }

    async function saveAndUploadImages() {
        if (currentNodeFolder.treeIndex) {
            let croppedImgFiles = await saveImages();
            setLoader(true);
            croppedImgFiles.forEach(img => {
                if (img['id']) {
                    dispatch(
                        updateImageAction(currentNodeFolder.treeIndex, img['original'], img['1/1'], img['2/1'], img['4/3'], img['id'], el => {
                            onUploadImageCallback(croppedImgFiles, el);
                        })
                    );
                } else {
                    dispatch(
                        saveImageToFolder(currentNodeFolder.treeIndex, img['original'], img['1/1'], img['2/1'], img['4/3'], el => {
                            onUploadImageCallback(croppedImgFiles, el);
                        })
                    );
                }
            });
        }
    }

    const setToUncategorized = () => {
        setCurrentNodeFolder(treeData[0]);
    };

    useEffect(() => {
        setTreeTemp(
            getFlatDataFromTree({
                ignoreCollapsed: false,
                getNodeKey: getNodeKey,
                treeData: treeData,
            })?.slice(1)
        );
    }, [treeData]);

    useEffect(() => {
        setToUncategorized();
    }, []);

    return (
        <div className="d-flex flex-column" style={{ flex: 1, overflow: 'hidden' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ position: 'relative', left: 20, top: 20 }}>
                    <Button
                        disabled={loader}
                        onClick={() => {
                            setOpenSaveImages(false);
                            setView(views[0]);
                        }}
                        style={{ textTransform: 'none' }}
                    >
                        <Body2 weight="medium" color={colors.gray6}>
                            {t('Go back')}
                        </Body2>
                    </Button>
                    <H8 className="my-2 ml-2" weight="medium">
                        {t('Save Images')}
                    </H8>
                </div>
                <Button
                    style={{ position: 'absolute', right: 20, top: 20 }}
                    disabled={loader}
                    onClick={() => {
                        setModalOpen(false);
                    }}
                >
                    <img src={CloseIcon} height={25} width={25} />
                </Button>
            </div>
            <div style={{ paddingTop: 20, marginLeft: 30 }}>
                <Body2>{t('Choose folder to save images')}</Body2>
            </div>

            {imageFiles.isEditing && (
                <Body2 style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }} color={colors.gray6}>
                    {t('Images will be replaced at their current location')}
                </Body2>
            )}

            {!imageFiles.isEditing && (
                <div style={{ height: '100%', width: '100%', marginTop: 30 }}>
                    {treeData.length == 0 && (
                        <div>
                            {t('You have not created a folder yet. You can create it in the')}
                            <span
                                onClick={() => {
                                    setOpenSaveImages(false);

                                    setView(views[2]);
                                }}
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }}
                            >
                                {t('image library')}
                            </span>
                            {t('section.')}
                        </div>
                    )}
                    {!loader && (
                        <>
                            <SelectFolder
                                treeTemp={treeTemp}
                                treeData={treeData}
                                setCurrentNodeFolder={setCurrentNodeFolder}
                                currentNodeFolder={currentNodeFolder}
                            />
                        </>
                    )}

                    <div>{loader && <Circular />}</div>
                </div>
            )}
            <div>
                <div className="save-images--button-container">
                    <ContainedButton
                        disabled={!currentNodeFolder}
                        style={{ position: 'absolute', left: 0, bottom: 0, margin: 20 }}
                        onClick={saveAndUploadImages}
                    >
                        {t('Save images')}
                    </ContainedButton>
                </div>
            </div>
        </div>
    );
}

SaveImages.propTypes = {
    addImage: PropTypes.func,
    index: PropTypes.any,
    saveImages: PropTypes.func,
    setModalOpen: PropTypes.func,
    setOpenSaveImages: PropTypes.func,
};
