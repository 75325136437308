import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Questions } from './questions/Questions';
import { Header } from 'containers/layout/module/local_components/header/Header';
import { Trainings } from './trainings/Trainings';
import { Topics } from './topics/Topics';
import { GalleryTab } from './gallery/Gallery';
import { ImageEditorProvider } from 'services/image_management/image-editor-management';
import ContentBlocksTab from './content_blocks/ContentBlocksTab';
import { ContentBlocksProvider, ContentBlocksConsumer } from 'services/content-blocks-management/content-blocks';
import { UndoRedoProvider } from 'services/undo-wrapper-management';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Content = ({ history }) => {
    const { type } = useParams();
    const [activeTab, setActiveTab] = useState(type);

    const { t } = useTranslation();
    

    const allTrainingsState = useSelector(state => state.content.allTrainings);
    const allTopicsFromCompanyState = useSelector(state => state.content.topics.allTopicsFromCompany);
    const topicsFolderStructureState = useSelector(state => state.content.topics.topicsFolderStructure);

    useEffect(() => {
        setActiveTab(type);
    }, [type]);

    function changeTab(tab) {
        history.replace(`/content/${tab}`);
    }

    const typesTabs = [
        {
            id: 'content-trainings',
            type: 'trainings',
            name: t('Trainings'),
            stateDependencies: [allTrainingsState],
        },
        {
            id: 'content-topics',
            type: 'topics',
            name: t('Topics'),
            stateDependencies: [allTopicsFromCompanyState,topicsFolderStructureState],
        },
        {
            id: 'content-questions',
            type: 'questions',
            name: t('Questions'),
            stateDependencies: [],
        },
        // disabled for now
        // {
        //     type: 'content-blocks',
        //     name: 'Content Blocks',
        // },
        {
            type: 'gallery',
            name: t('Gallery'),
        },
    ];

    return (
        <Header
            tabs={typesTabs}
            activeTab={activeTab}
            setActiveTab={changeTab}
            title={'Content'}
            stateDependencies={typesTabs.find(el => el.type === activeTab)?.stateDependencies}
        >
            {activeTab === 'trainings' && (
                <UndoRedoProvider>
                    <Trainings />
                </UndoRedoProvider>
            )}
            {activeTab === 'topics' && <Topics />}
            {activeTab === 'questions' && <Questions />}
            {activeTab === 'gallery' && (
                <ImageEditorProvider>
                    <GalleryTab />
                </ImageEditorProvider>
            )}
            {activeTab === 'content-blocks' && (
                <ContentBlocksProvider>
                    <ContentBlocksTab />
                    <ContentBlocksConsumer />
                </ContentBlocksProvider>
            )}
        </Header>
    );
};

export default Content;
