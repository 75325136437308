import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { ContextDevTool } from 'react-context-devtool';
import { actionTypes } from './local-actions';
import { INITIAL_STATE } from './local-constants';
import { contentBlocksReducer } from './local-reducer';

const InsertionTemporaryContext = createContext();

export const ContentBlocksProvider = ({ children }) => {
    const [state, dispatch] = useReducer(contentBlocksReducer, INITIAL_STATE);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <InsertionTemporaryContext.Provider value={contextValue}>
            <>
                <ContextDevTool context={InsertionTemporaryContext} id="contentBlockContextId" displayName="Content blocks context" />
                {children}
            </>
        </InsertionTemporaryContext.Provider>
    );
};

const useState = () => {
    const { state } = useContext(InsertionTemporaryContext);
    return state;
};

const useDispatch = () => {
    const { dispatch } = useContext(InsertionTemporaryContext);
    return dispatch;
};

export const ContentBlocksConsumer = () => {
    return (
        <InsertionTemporaryContext.Consumer>
            {value => {
                return null;
            }}
        </InsertionTemporaryContext.Consumer>
    );
};

export const useContentBlock = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        contentBlock: state.contentBlock,
        rowsToShow: state.rowsToShow,
        columnWidths: state.columnWidths,
        selectedEdges: state.selectedEdges,
        firstCell: state.firstCell,
        currentCell: state.currentCell,
        history: state.history,
        setContentBlock: contentBlock => {
            dispatch({ type: actionTypes.setContentBlock, payload: contentBlock });
        },
        setRowsToShow: rowsToShow => {
            dispatch({ type: actionTypes.setRowsToShow, payload: rowsToShow });
        },
        setColumnWidths: columnWidths => {
            dispatch({ type: actionTypes.setColumnWidths, payload: columnWidths });
        },
        setSelectedEdges: selectedEdges => {
            dispatch({ type: actionTypes.setSelectedEdges, payload: selectedEdges });
        },
        setFirstCell: firstCell => {
            dispatch({ type: actionTypes.setFirstCell, payload: firstCell });
        },
        setCurrentCell: currentCell => {
            dispatch({ type: actionTypes.setCurrentCell, payload: currentCell });
        },
        resetState: () => {
            dispatch({ type: actionTypes.resetState });
        },
        setHistory: history => {
            dispatch({ type: actionTypes.setHistory, payload: history });
        },
        setState: val => {
            dispatch({ type: actionTypes.setState, payload: val });
        },
        undo: () => {
            dispatch({ type: actionTypes.undo });
        },
        updateColumn: (colId, generatedColId, name, type) => {
            dispatch({ type: actionTypes.updateColumn, payload: { colId: colId, generatedColId: generatedColId, name: name, type: type } })
        },
        updateCell: (val, rowId, generatedRowId, colId, generatedColId) => {
            dispatch({ type: actionTypes.updateCell, payload: { val: val, rowId: rowId, generatedRowId: generatedRowId, colId: colId, generatedColId: generatedColId } })
        },
        addRow: () => {
            dispatch({ type: actionTypes.addRow });
        },
        addColumn: (colName, colType) => {
            dispatch({ type: actionTypes.addColumn, payload: { colName: colName, colType: colType } });
        },
        deleteColumn: (colId, colKey) => {
            dispatch({ type: actionTypes.deleteColumn, payload: { id: colId, key: colKey } });
        },
        setColWidthTo: (key, val) => {
            dispatch({ type: actionTypes.setColWidthTo, payload: { key: key, val: val } });
        },
        pasteMultipleValuesInRow: (row, col, e, value) => {
            dispatch({ type: actionTypes.pasteMultipleValuesInRow, payload: { row: row, col: col, e: e, value: value } });
        },
        addImages: (images, row, colName) => {
            dispatch({ type: actionTypes.addImages, payload: { images: images, row: row, colName: colName } });
        },
        removeImage: (row, colName) => {
            dispatch({ type: actionTypes.removeImage, payload: { row: row, colName: colName } });
        },
    };
};

export const useContentBlockState = useState;
