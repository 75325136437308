import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { colors } from 'style/colors';
import { UserCircle } from 'components/elements/UserCircle';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Body1, Body2 } from 'style/typography/Body';

import Profile from 'assets/icons/profile.svg';
import Logout from 'assets/icons/logout.svg';

function HeaderMenu(props) {
    const history = useHistory();
    const dropdownRef = useRef(null);
    const { t } = useTranslation();

    const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);

    const companyDetailsState = useSelector(state => state.userManagement.company);

    function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && accountDropdownOpen) {
            setAccountDropdownOpen(false);
        } else if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
            setAccountDropdownOpen(!accountDropdownOpen);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [accountDropdownOpen]);

    return (
        <UserCircle
            id="user-circle"
            innerRef={dropdownRef}
            dataToggle={'dropdown'}
            height={36}
            width={36}
            tag={Body1}
            name={props.userInfo?.name}
            lastName={props.userInfo?.lastName}
            borderColorActive={colors.mainTheme}
            borderConditionalVariable={props.accountDropdownOpen}
            divClasses={'ml-auto mb-2'}
            bgColor={colors.gray4}
        >
            <div className="dropdown-menu mr-4 mt-2" aria-labelledby="dropdownMenuButton" style={{ width: '230px' }}>
                <div className="pl-3 mt-2 pt-1">
                    <Body1 weight="medium" className={'mb-1'}>
                        {props.userInfo?.name} {props?.userInfo?.lastName}
                    </Body1>
                    <Body2 color={colors.gray6}>
                        <Trans>
                            {t('Company: <strong>{{company}}</strong>', {
                                company: companyDetailsState.data?.name,
                            })}
                        </Trans>
                    </Body2>
                    <Body2 style={{ marginTop: 5 }} color={colors.gray6}>
                        <Trans>
                            {t('Licences left: <strong>{{licencesLeft}}</strong> of <strong>{{licences}}</strong>', {
                                licencesLeft: companyDetailsState.data?.userLicencesLeft,
                                licences: companyDetailsState.data?.totalUserLicences,
                            })}
                        </Trans>
                    </Body2>
                </div>
                <div className="dropdown-divider"></div>
                <div
                    className="dropdown-item pl-3"
                    onMouseDown={() => {
                        history.push(`/user/${props.userInfo.id}`);
                    }}
                >
                    <img alt="Profile" className={'float-left mr-2'} height={20} width={20} src={Profile} />
                    <Body1 weight="light">{t('My profile')}</Body1>
                </div>
                <div className="dropdown-divider"></div>
                {/* <div className="dropdown-item pl-3">
                    <img alt="Terms" className={'float-left mr-2'} height={20} width={20} src={Terms} />
                    <Body1 weight="light">{t('Terms of use')}</Body1>
                </div> */}
                <div
                    onMouseDown={() => {
                        props.setLogoutModalOpen(true);
                    }}
                    className="dropdown-item pl-3"
                >
                    <img alt="Logout" className={'float-left mr-2'} height={20} width={20} src={Logout} />
                    <Body1 weight="light">{t('Logout')}</Body1>
                </div>
            </div>
        </UserCircle>
    );
}

export default HeaderMenu;
