import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React from 'react';
import DeleteIcon from 'assets/icons/icon-delete-role.png';
import { useTranslation } from 'react-i18next';

export function DeleteTagDialog({ modalOpen, setModalOpen, onAction }) {
    const {t} = useTranslation();

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                setModalOpen(false);
                onAction();
            }}
            title={t('Delete tag')}
            text={t("If you choose to delete this tag, it will be removed from all the users that it's assigned to.")}
            buttons={[
                {
                    title: t('Delete anyway'),
                    color: colors.white,
                },
            ]}
            hasCancel={true}
            image={DeleteIcon}
        ></ModalDialog>
    );
}
DeleteTagDialog.propTypes = {
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    setModalOpen: PropTypes.func,
};
