import PropTypes from 'prop-types';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { getNodeKey } from 'services/utils/treeHelpers';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { useImageLibraryHelper } from 'services/image_management/imageLibraryHelper';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { Body2 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';

import '../Gallery.css';

export default function NewFolderModal({ modalOpen, setModalOpen, toSave }) {
    const { t } = useTranslation();
    const [newFolderName, setNewFolderName] = useState('');

    const { addChild } = useImageLibraryHelper();
    const { currentPath } = useImageEditor();

    return (
        <Modal open={modalOpen} className="new-folder-modal">
            <div className="d-flex justify-content-center align-items-center new-folder-modal--container">
                <div className="d-flex flex-column p-2">
                    <Body2 className="py-2"> {t('Folder name')} </Body2>
                    <CustomOutlinedTextField className="p-2 mb-4" name={'Folder name'} setValue={setNewFolderName} />
                    <div className="d-flex justify-content-around py-2 mt-2">
                        <DefaultButton
                            className="mr-2"
                            onClick={() => {
                                toSave.current = true;
                                addChild(currentPath, getNodeKey, newFolderName, false);
                                setModalOpen(false);
                            }}
                        >
                            {t('Save')}
                        </DefaultButton>
                        <OutlinedButton
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t('Cancel')}
                        </OutlinedButton>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

NewFolderModal.propTypes = {
    modalOpen: PropTypes.any,
    setModalOpen: PropTypes.func,
    toSave: PropTypes.any,
};
