import PropTypes from 'prop-types';
import React from 'react';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionsPaginatedAction } from 'redux/actions/content.actions';
import { questionActionsOptions } from 'static/question_constants';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { useTranslation } from 'react-i18next';

export default function QuestionTableActions({
    currentActionOption,
    setCurrentActionOption,
    setModalOpened,
    handleRowClick,
    selectedRows,
    allQuestionsSelected,
    selectedRowsArr,
    preCheckBulk,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const paginatedQuestionsState = useSelector(state => state.content.questionsPaginated);

    const totalSelectedQuestions = allQuestionsSelected ? paginatedQuestionsState.data.totalElements : selectedRowsArr.length;

    return (
        <div className="d-flex flex-d-row justify-content-center align-items-center">
            <div className="d-flex flex-row align-items-center">
                <Caption1 style={{ fontSize: 15 }} weight={'bold'}>
                    {totalSelectedQuestions}
                </Caption1>
                <Caption1 color={colors.gray6} style={{ padding: '10px 10px', borderRight: `1px solid ${colors.gray1}` }}>
                    {t('questions selected')}
                </Caption1>
            </div>
            <div className="d-flex flex-d-row align-items-center" style={{ marginLeft: 20, width: 500 }}>
                <CustomSelect
                    id="add-questions-category"
                    width={200}
                    isClearable={false}
                    value={null}
                    onChange={option => {
                        preCheckBulk();
                        if (option.value != questionActionsOptions(t)[3].value) {
                            setCurrentActionOption(option);
                            setModalOpened(option.value);
                        } else {
                            dispatch(setQuestionsPaginatedAction(selectedRowsArr.map(el => Number(el))));
                            let row = selectedRows[selectedRowsArr[0]];
                            handleRowClick(row);
                        }
                    }}
                    options={questionActionsOptions(t)}
                    placeholder={t('Select action')}
                    style={{ width: 200, flex: 1 }}
                />
            </div>
        </div>
    );
}

QuestionTableActions.propTypes = {
    allQuestionsSelected: PropTypes.any,
    currentActionOption: PropTypes.any,
    handleRowClick: PropTypes.func,
    selectedRows: PropTypes.any,
    selectedRowsArr: PropTypes.array,
    setCurrentActionOption: PropTypes.func,
    setModalOpened: PropTypes.func,
};
