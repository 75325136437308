import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Animate } from 'react-move';

function AnimatedProgressProvider({ repeat, duration, valueEnd, easingFunction, valueStart = 0, ...props }) {
    const interval = useRef();
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        if (repeat) {
            interval.current = window.setInterval(() => {
                setIsAnimated(!isAnimated);
            }, duration * 1000);
        } else {
            setIsAnimated(!isAnimated);
        }
        return () => {
            window.clearInterval(interval.current);
        };
    }, []);

    return (
        <Animate
            start={() => ({
                value: valueStart,
            })}
            update={() => ({
                value: [isAnimated ? valueEnd : valueStart],
                timing: {
                    duration: duration * 1000,
                    ease: easingFunction,
                },
            })}
        >
            {({ value }) => props.children(value)}
        </Animate>
    );
}

AnimatedProgressProvider.propTypes = {
    duration: PropTypes.number,
    easingFunction: PropTypes.any,
    repeat: PropTypes.any,
    valueEnd: PropTypes.any,
    valueStart: PropTypes.number,
};

export default AnimatedProgressProvider;
