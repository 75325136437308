import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useContentInsertionQuestion } from 'services/content-management/insertion-control';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import { validatorCategories } from 'services/validation-module/local-constants';
import InfoPopUpBox from 'components/features/content_management/InfoPopUpBox';
import { InputWithInfoPopUpWrapper } from './InputWithInfoPopUpWrapper';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { Body2 } from 'style/typography/Body';
import { useContentBlocksBuilder } from 'services/content-blocks-management/content-blocks-builder';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import HoverSelectWithCustomComponent from 'components/elements/tooltips/HoverSelectWithCustomComponent';
import { useTranslation } from 'react-i18next';

const InputQuestion = ({ maxInputChars }) => {
    const { t } = useTranslation();

    const { question, setQuestion } = useContentInsertionQuestion();

    const [showDialog, setShowDialog] = useState(false);

    const { contentBlock, setQuestionAttribute, setAnswerAttribute, setCorrectValuesMap } = useContentBlocksBuilder();

    function setToShowDialog(toShow) {
        setTimeoutLastExecute.addCallback(
            () => {
                setShowDialog(toShow);
            },
            50,
            `showVarPickerDialog`
        );
    }

    return (
        <>
            <InputWithInfoPopUpWrapper question={question}>
                {({ stopInput, onChange }) => {
                    return (
                        <div className={'w-100 mt-3 mb-3 d-flex flex-column'}>
                            <Caption1 color={colors.gray6}>{t('QUESTION')}</Caption1>
                            <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'center',
                                        alignItems: 'center',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <CustomTextField
                                        type="text"
                                        id="question-text"
                                        className="form-control"
                                        value={question}
                                        width={'100%'}
                                        placeholder={t('Insert question here..')}
                                        height={45}
                                        font={17}
                                        setValue={setQuestion}
                                    />
                                    {contentBlock && (
                                        <HoverSelectWithCustomComponent
                                            customComp={
                                                <Body2 color={colors.white} weight="medium">
                                                    {'<%>'}
                                                </Body2>
                                            }
                                            showDialog={showDialog}
                                            setShowDialog={setToShowDialog}
                                            onOptionClick={el => {
                                                setQuestionAttribute(null);
                                                setAnswerAttribute(null);
                                                setCorrectValuesMap({});
                                                setQuestion(question + `<%${el.value}%>`);
                                            }}
                                            options={contentBlock?.data?.columns.map(el => {
                                                return {
                                                    value: el.key,
                                                    label: el.name,
                                                    type: 'option',
                                                };
                                            })}
                                        />
                                    )}
                                </div>
                                <ValidationPopup category={validatorCategories.PREVIEW_GAMES_QUESTION_NOT_EMPTY} position="left" />
                                {(maxInputChars && question.length > maxInputChars) || (true && <InfoPopUpBox></InfoPopUpBox>)}
                            </div>
                        </div>
                    );
                }}
            </InputWithInfoPopUpWrapper>
        </>
    );
};

InputQuestion.propTypes = {
    maxInputChars: PropTypes.number,
};

export default InputQuestion;
