import PropTypes from 'prop-types';
import React from 'react';
import { Caption1, Caption2 } from 'style/typography/Caption';
import NotificationPlaceholder from 'assets/icons/illustration-mockup-notifications.svg';
import { colors } from 'style/colors';

import './NotificationPreview.css';
import { useTranslation } from 'react-i18next';

const NotificationPreview = ({ title, text }) => {
    const { t } = useTranslation();

    return (
        <div className="notification-preview">
            <Caption1 color={colors.gray6} weight="medium">
                {t('PREVIEW')}
            </Caption1>
            <div className="phone-preview">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={NotificationPlaceholder} alt="Notification" style={{ width: '370px', height: '200px' }} />
                </div>
                <div className="notification-popup">
                    <Caption1 weight="medium" style={{ height: '140px', maxHeight: '100px', overflow: 'hidden' }}>
                        {title || t('Notification title')}
                    </Caption1>
                    <Caption2 style={{ height: '200px', maxHeight: '200px', overflow: 'hidden' }}>{text || t('Notification text')}</Caption2>
                </div>
            </div>
        </div>
    );
};

NotificationPreview.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
};

export default NotificationPreview;
