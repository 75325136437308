import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import Paper from '@material-ui/core/Paper/Paper';
import { makeStyles } from '@material-ui/core';
import { stateIsLoading } from 'redux/core/stateHelpers';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';

import Close from 'assets/icons/filter-close.svg';
import { Body1 } from 'style/typography/Body';
import { H8 } from 'style/typography/Heading';
import { Trans, useTranslation } from 'react-i18next';

export function ModalDialogContent({
    onClose,
    onAction,
    title,
    contentComponent,
    text,
    buttons,
    hasCancel,
    image,
    alert,
    state,
    hasLoader,
    imageSize = 50,
    width = '30vw',
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    if (state && hasLoader && stateIsLoading(state)) {
        return (
            <Paper style={{ width: width, minWidth: width, minHeight: '30vh' }} className={`${classes.paper} row pt-3`}>
                <div style={{ height: '28vh', width: width }} className={'d-flex flex-row align-items-center justify-content-center'}>
                    <CircularProgress style={{ color: colors.mainTheme }} size={50} />
                </div>
            </Paper>
        );
    }

    return (
        <Paper style={{ width: width }} className={`${classes.paper} row pt-3`}>
            {/*<div className={'row'}>*/}
            <div className="col-12 d-flex flex-column">
                <img src={Close} className="ml-auto cursor-pointer" style={{ height: '20px', width: '20px' }} alt="close" onClick={onClose} />
            </div>
            <div className="col-12 pr-3 pl-3 justify-content-start mt-4">
                <div className="d-flex flex-column text-center align-items-center px-4">
                    {image && <img className={'mb-3'} height={imageSize} width={imageSize} src={image} alt="" />}
                    <H8 weight="medium" color={colors.dark}>
                        {t(title)}
                    </H8>
                    {alert}
                    {contentComponent || (
                        <Body1 className={'mt-4'}>
                            <Trans>{t(text)}</Trans>
                        </Body1>
                    )}
                </div>
            </div>
            <div className={'d-flex flex-row align-content-center mt-5 mb-3 ml-auto mr-auto'} style={{ height: '35px', gap: '10px' }}>
                {buttons?.map(button => (
                    <DefaultButton
                        textColor={button.color}
                        text={t(button.title)}
                        loading={state ? stateIsLoading(state) : false}
                        onClick={button.onClick ? button.onClick : onAction}
                        id="resolve-feedback-button"
                        hoverColor={colors.dark}
                        disabled={button.disabled}
                    />
                ))}

                {hasCancel && <OutlinedButton onClick={onClose} text={t('Cancel')} id="cancel-feedback-button" />}
            </div>
        </Paper>
    );
}

ModalDialogContent.propTypes = {
    alert: PropTypes.any,
    buttons: PropTypes.array,
    contentComponent: PropTypes.any,
    hasCancel: PropTypes.bool,
    hasLoader: PropTypes.bool,
    image: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    state: PropTypes.any,
    text: PropTypes.any,
    title: PropTypes.any,
    width: PropTypes.string,
};
const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: colors.gray4,
            border: `solid 1px ${colors.gray4}`,
            boxShadow: theme.shadows[5],
        },
    };
});
