import { useTranslation } from 'react-i18next';
import { ModalDialog } from '../../../../../../../../components/features/modal/ModalDialog';
import { colors } from '../../../../../../../../style/colors';
import WarningIcon from '../../../../../../../../assets/icons/warning-icon.png';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
    editUsersRoleInBatchAction,
    resetEditUsersRoleInBatchAction,
} from '../../../../../../../../redux/actions/users.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { stateIsLoaded } from '../../../../../../../../redux/core/stateHelpers';
import { toast } from 'react-hot-toast';

export function RemoveRoleFromUsersDialog({ modalOpen, setModalOpen,usersList, allUsersSelected}) {
    const editUsersRoleInBatchState = useSelector(state => state.userManagement.editUsersRoleInBatch);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const onAction = () => {
        if ((usersList != null && Object.keys(usersList)?.length > 0) || allUsersSelected){
            let users = Object.values(usersList).map(u => u.id);
            dispatch(editUsersRoleInBatchAction({
                userIds: users,
                roleId: null,
                allSelected: allUsersSelected ? allUsersSelected : false,
                removeRole: true
            }));
        }
    }

    useEffect(() => {
        if (stateIsLoaded(editUsersRoleInBatchState)){
            dispatch(resetEditUsersRoleInBatchAction());
            setModalOpen(false);
            toast.success(t('Successfully saved!'));
            setTimeout(() => window.location.reload(), 1000);
        }
    },[editUsersRoleInBatchState])


    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                onAction();
            }}
            onClose={() => setModalOpen(false)}
            title={t('Remove role from users')}
            text={t(
                'If you choose to remove the role from the users, the users will lose access to the assigned training and the Coach app will be unusable.'
            )}
            buttons={[
                {
                    title: t('Remove anyway'),
                    color: colors.white,
                    onAction: () => onAction()
                },
            ]}
            hasCancel={true}
            image={WarningIcon}
        ></ModalDialog>
    );
}

RemoveRoleFromUsersDialog.propTypes = {
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    setModalOpen: PropTypes.func,
};
