import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import Folder from 'assets/icons/icon-gallery-folder.svg';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function DeleteFolderDialog({ modalOpen, setModalOpen, onAction, folderId }) {
    const { t } = useTranslation();

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                setModalOpen(false);
                folderId ? onAction(folderId) : onAction();
            }}
            title={t('Delete folder')}
            text={t('If you choose to delete the folder, all images contained in it will be moved to the root folder.')}
            buttons={[
                {
                    title: t('Delete anyway'),
                    color: colors.white,
                },
            ]}
            hasCancel={true}
            image={Folder}
        ></ModalDialog>
    );
}

DeleteFolderDialog.propTypes = {
    folderId: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    setModalOpen: PropTypes.func,
};
