import IconTopicPickerSelected from 'assets/icons/icon-topicpicker-selected.svg';

export const userActionsOptions = t => [
    { value: 'add_tags', label: t('Add tags'), image: IconTopicPickerSelected },
    { value: 'remove_tags', label: t('Remove tags'), image: IconTopicPickerSelected },
    { value: 'add_role', label: t('Add role'), image: IconTopicPickerSelected },
    { value: 'remove_role', label: t('Remove role'), image: IconTopicPickerSelected },
];

export const userActionsOptionsWithResend = t => [
    { value: 'add_tags', label: t('Add tags'), image: IconTopicPickerSelected },
    { value: 'remove_tags', label: t('Remove tags'), image: IconTopicPickerSelected },
    { value: 'add_role', label: t('Add role'), image: IconTopicPickerSelected },
    { value: 'remove_role', label: t('Remove role'), image: IconTopicPickerSelected },
    { value: 'resend_invites', label: t('Resend invites'), image: IconTopicPickerSelected },
];
export const userDevicesActionsOptions = t => [
    { value: 'approve_requests', label: t('Approve requests'), image: IconTopicPickerSelected },
    { value: 'reject_requests', label: t('Reject requests'), image: IconTopicPickerSelected },
    { value: 'Delete', label: t('Delete'), image: IconTopicPickerSelected },
];
