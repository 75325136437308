import React from 'react';
import { useNumericalAnswer } from 'services/content-management/insertion-control';
import { InputWithInfoPopUpWrapper } from 'components/features/content_management/inputs/InputWithInfoPopUpWrapper';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';

const InputNumericalANswer = () => {
    const { t } = useTranslation();

    const { answer, setAnswer } = useNumericalAnswer();
    return (
        <>
            <Caption1 color={colors.gray6}>ANSWER</Caption1>

            <InputWithInfoPopUpWrapper answer={answer?.text}>
                {({ stopInput, onChange }) => {
                    return (
                        <CustomTextField
                            type="text"
                            id="numerical-answer-text"
                            className="form-control"
                            maxLength={7}
                            placeholder={t('Value')}
                            font={15}
                            value={answer?.text}
                            setValue={setAnswer}
                        />
                    );
                }}
            </InputWithInfoPopUpWrapper>
        </>
    );
};

export default InputNumericalANswer;
