import React, { useEffect, useState } from 'react';
import { Info } from './info/Info';
import { Content } from './content/ContentForLesson';
import { QuestionsList } from './questions/QuestionsList';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';

import { updateLocalTopicChanges } from 'redux/actions/topics.actions';
import { useTranslation } from 'react-i18next';
import { tabsSidemenuTopics } from 'static/tabs';

import SelectTopic from 'assets/icons/select-topic.svg';

import SideMenu from 'components/elements/sidemenu/SideMenu';

export function SideMenuTopics({ node, allQuestions, setAllQuestions, setSelectedNode, editingTitle, setEditingTitle, setNewName }) {
    const { t } = useTranslation();

    const topicContentState = useSelector(state => state.content.topics.topicContent);
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);

    const [activeTab, setActiveTab] = useState('content');
    const [currentTopicData, setCurrentTopicData] = useState('');
    const [name, setName] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (!node) return;

        let data = {};
        if (stateIsLoaded(topicContentState)) {
            data = topicContentState.data;
        }
        if (stateIsLoaded(localTopicChangesState) && localTopicChangesState.data[node.treeIndex]) {
            data = { ...data, ...localTopicChangesState.data[node.treeIndex].treeData };
        }
        setCurrentTopicData(data);
    }, [topicContentState, localTopicChangesState, node]);

    useEffect(() => {
        if (currentTopicData) {
            setName(currentTopicData.title);
        }
    }, [currentTopicData]);

    let tabsMapping = {
        info: (
            <Info
                availableFrom={currentTopicData.dateAvailableFrom}
                availableTo={currentTopicData.dateAvailableTo}
                createdBy={currentTopicData.createdBy}
                dateCreated={currentTopicData.dateCreated}
                timeCreated={currentTopicData.timeCreated}
                topicTitle={currentTopicData.title}
                usedIn={currentTopicData.usedIn}
            />
        ),
        questions: (
            <QuestionsList
                selectedNode={node}
                setSelectedNode={setSelectedNode}
                allQuestions={allQuestions}
                setAllQuestions={setAllQuestions}
                currentTopic={currentTopicData}
            />
        ),
        content: <Content setSelectedNode={setSelectedNode} selectedNode={node} />,
    };

    return (
        <SideMenu
            hasData={node}
            noDataImg={SelectTopic}
            noDataTitle={'Select a topic to edit'}
            noDataText={'Currently there is no topic selected. Click on a topic from the left panel, and more info will be shown here.'}
            isEditingInput={editingTitle}
            setEditingInput={setEditingTitle}
            inputKey={name}
            defaultValue={name || ''}
            inputOnBlur={() => {
                dispatch(updateLocalTopicChanges(currentTopicData, { title: name }, []));
                setEditingTitle(false);
            }}
            inputOnChange={event => {
                setName(event.target.value);
            }}
            captionText={t('Created by {{createdBy}}', { createdBy: currentTopicData?.createdBy })}
            tabsSidemenu={tabsSidemenuTopics}
            tabsMapping={tabsMapping}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
    );
}
