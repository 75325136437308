import React, { useCallback, useEffect, useState } from 'react';
import { stateIsLoaded } from 'redux/core/stateHelpers';

export const useTableSelections = ({ paginatedState, rowSelector }) => {
    const [selectedRows, setSelectedRows] = React.useState({});
    const [allQuestionsSelected, setAllQuestionsSelected] = React.useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const areAllOptionsFromThisPageSelected = useCallback(() => {
        let flag = true;
        filteredData.forEach(el => {
            flag = flag && el.selected;
        });
        return flag;
    }, [filteredData]);

    const addSelectedRow = useCallback(
        row => {
            selectedRows[rowSelector(row)] = row;
            setSelectedRows({ ...selectedRows });
        },
        [selectedRows]
    );

    const removeSelectedRow = useCallback(
        row => {
            delete selectedRows[rowSelector(row)];
            setSelectedRows({ ...selectedRows });
        },
        [selectedRows]
    );

    const selectAll = useCallback(
        el => {
            setAllQuestionsSelected(!!el);
            if (areAllOptionsFromThisPageSelected()) {
                filteredData.forEach(element => {
                    removeSelectedRow(element);
                });
            } else {
                filteredData.forEach(element => {
                    addSelectedRow(element);
                });
            }
        },
        [filteredData, areAllOptionsFromThisPageSelected, addSelectedRow, removeSelectedRow]
    );

    const deselectAll = () => {
        setSelectedRows({});
    };

    useEffect(() => {
        if (stateIsLoaded(paginatedState)) {
            setFilteredData(
                paginatedState?.data?.content.map(el => {
                    if (selectedRows[rowSelector(el)] || allQuestionsSelected) {
                        el.selected = true;
                    } else {
                        el.selected = false;
                    }
                    return el;
                })
            );
        }
    }, [paginatedState, selectedRows, allQuestionsSelected]);
    const selectedRowsArr = Object.keys(selectedRows);

    return {
        areAllOptionsFromThisPageSelected,
        selectedRows,
        setSelectedRows,
        addSelectedRow,
        removeSelectedRow,
        selectAll,
        deselectAll,
        allQuestionsSelected,
        setAllQuestionsSelected,
        filteredData,
        setFilteredData,
        selectedRowsArr,
    };
};
