import { LOGIN, LOGOUT, STORAGE_PERSIST } from '../constants/auth.constants';
import { ActionStatus } from '../core/ActionStatus';
import { persistor } from '../config/store';

import { buildActionType } from './buildActionType';
import { loginApiRequest } from 'services/api/apiRequests';
import { refreshTokenRequest } from 'services/api/apiRequests/login';

const passwordLoginAction = (email, password) => async dispatch => {
    dispatch({ type: buildActionType(LOGIN, ActionStatus.START) });

    let data = new FormData();
    data.set('username', email);
    data.set('password', password);

    const response = await loginApiRequest(data, [{ name: 'grant_type', value: 'password' }]);

    if (response.success === true) {
        response.data.token_creation = Date.now();
        dispatch({
            type: buildActionType(LOGIN, ActionStatus.DONE),
            payload: response.data,
        });
        await persistor.flush();
        dispatch({ type: buildActionType(STORAGE_PERSIST, ActionStatus.DONE) });
    } else {
        dispatch({
            type: buildActionType(LOGIN, ActionStatus.FAILED),
            payload: response?.status,
        });
    }
};

const refreshProviderToken = async dispatch => {
    const tokenPromise = refreshTokenRequest();
    tokenPromise
        .then(async response => {
            response.data.token_creation = Date.now();
            response.data.fetchingToken = false;
            dispatch({
                type: buildActionType(LOGIN, ActionStatus.DONE),
                payload: { ...response.data },
            });
            await persistor.flush();
            dispatch({ type: buildActionType(STORAGE_PERSIST, ActionStatus.DONE) });
            return Promise.resolve();
        })
        .catch(async err => {
            await localStorage.removeItem('persist:coach');
            dispatch({ type: buildActionType(LOGOUT, ActionStatus.DONE) });
            return Promise.reject();
        });

    dispatch({
        type: buildActionType(LOGIN, ActionStatus.REFRESH),
        payload: {
            fetchingToken: tokenPromise,
        },
    });
    return tokenPromise;
};

const tokenLoginAction = tokenInfo => async dispatch => {
    dispatch({
        type: buildActionType(LOGIN, ActionStatus.DONE),
        payload: tokenInfo,
    });
    await persistor.flush();
    dispatch({ type: buildActionType(STORAGE_PERSIST, ActionStatus.DONE) });
};

const logout = () => async dispatch => dispatch({ type: buildActionType(LOGOUT, ActionStatus.DONE) });

const authReset = () => async dispatch => dispatch({ type: buildActionType(LOGIN, ActionStatus.RESET) });

const resetLogout = () => async dispatch => dispatch({ type: buildActionType(LOGOUT, ActionStatus.RESET) });

export { passwordLoginAction, logout, authReset, resetLogout, tokenLoginAction, refreshProviderToken };
