import { CustomFolder } from 'components/features/content_management/cropping_module/CustomFolder';
import { ImagePagination } from 'components/features/content_management/cropping_module/ImagePagination';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { H5 } from 'style/typography/Heading';

import EmptyFolder from 'assets/icons/icon-gallery-openfolder.svg';
import { tabs } from 'services/image_management/image-editor-management/local-constants';

import CustomGalleryGrid from './custom_gallery_grid/CustomGalleryGrid';
import { useTranslation } from 'react-i18next';
import CustomGalleryList from './custom_gallery_list/CustomGalleryList';
import { LoadingAnimation } from '../lottie/LoadingAnimation';
import { ErrorBoundaryComponent } from '../error_handling/error_boundary/ErrorBoundaryComponent';


import './Gallery.css';

export default function GalleryPreviewImages() {
    const { t } = useTranslation();
    const [currentState, setCurrentState] = useState(null);

    const currentImagesFromFolder = useSelector(state => state.content.imagesFromFolder);
    const allImages = useSelector(state => state.content.allImages);
    const uncategorizedImages = useSelector(state => state.content.uncategorizedImages);

    const {
        treeData,
        setTreeData,
        currentNode,
        setCurrentNode,
        filteredImages,
        currentTab,
        updateSelectedImage,
        isGrid,
        allImagesSelected,
        setAllImagesSelected,
        setSelectedImages,
    } = useImageEditor();

    const onImageSelect = image => {
        if (allImagesSelected) {
            setSelectedImages([]);
            setAllImagesSelected(false);
            image.isSelected = true;
        } else {
            image.isSelected = !image.isSelected;
        }
        updateSelectedImage(image);
    };

    useEffect(() => {
        if (currentTab === tabs.all_images) {
            setCurrentState(allImages);
        } else if (currentTab === tabs.uncategorized) {
            setCurrentState(uncategorizedImages);
        } else if (currentTab === tabs.folder) {
            setCurrentState(currentImagesFromFolder);
        }
    }, [currentTab, currentImagesFromFolder, allImages, uncategorizedImages]);

    return (
        <div className="d-flex flex-column" style={{ flex: 1, minHeight: 0 }}>
            <ErrorBoundaryComponent dependencies={[currentImagesFromFolder, allImages, uncategorizedImages]}>
                <div style={{ overflow: 'scroll' }}>
                    <div style={{ display: 'flex', flex: 'row' }}>
                        {currentTab == tabs.folder &&
                            currentNode &&
                            currentNode?.children?.map(folder => {
                                return (
                                    <CustomFolder
                                        setCurrentNode={setCurrentNode}
                                        currentNode={currentNode}
                                        folder={folder}
                                        setTreeData={setTreeData}
                                        treeData={treeData}
                                    />
                                );
                            })}
                    </div>
                </div>
                <div className="gallery--grid-container">
                    {currentState && stateIsLoaded(currentState) && currentState.data.totalPages != 0 && filteredImages?.length > 0 && (
                        <div>
                            {isGrid && <CustomGalleryGrid onSelect={onImageSelect} images={filteredImages} />}
                            {!isGrid && <CustomGalleryList onSelect={onImageSelect} images={filteredImages} />}
                        </div>
                    )}
                    {currentState && stateIsLoaded(currentState) && currentState.data.totalPages == 0 && (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10%' }}>
                            <img alt="Empty folder" src={EmptyFolder} height={100} width={100} />
                            <H5 weight="light" className="my-2">
                                {t('Folder is empty')}
                            </H5>
                            <Caption1 color={colors.gray6}>
                                {t('The currently selected folder seems to be empty. Upload images in the upper-right corner!')}
                            </Caption1>
                        </div>
                    )}
                    {currentState && !stateIsLoaded(currentState) && <LoadingAnimation />}
                </div>

                {currentState && stateIsLoaded(currentState) && currentState.data.totalPages != 0 && <ImagePagination currentState={currentState} />}
            </ErrorBoundaryComponent>
        </div>
    );
}
