import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { activateUserAction, deactivateUserAction, fetchUsersPaginatedAction } from 'redux/actions/users.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import InvitationModal from 'containers/not_logged_in_pages/invite_users_flow/InvitationModal';
import { Subheader } from 'components/features/data_table/subheader/Subheader';
import { colors } from 'style/colors';
import { UserCircle } from 'components/elements/UserCircle';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import Alert from '@material-ui/lab/Alert/Alert';
import { toast } from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import CaretTable from 'assets/icons/caret-table.svg';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { TableCellContent, TableHeader } from 'components/features/data_table/TableElements';
import { customStylesTable } from 'style/elements/table';
import PaginationComponent from 'components/features/data_table/pagination_component/PaginationComponent';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { linkBuilderFromQuery } from 'services/api/api_builders';
import { useTableSelections } from 'services/custom-hooks/useTableSelections';
import CustomCheckboxForTable from 'components/elements/checkbox/CustomCheckboxForTable';
import { SetTagsBulkModal } from './local_components/modals/SetTagsModal';
import { usePagination } from 'components/features/data_table/pagination_component/usePagination';
import { addUsersToTagsAction, getTagsAction, removeUsersFromTagsAction } from 'redux/actions/tags.actions';
import { SkeletonLoader } from 'components/elements/SkeletonLoader';
import { usersFiltersInitialState } from 'static/filters';
import { usersTableSubheaders } from './table_components/UsersTableSubheaders';
import TagPill from './local_components/TagPill';
import UsersTableActions from './table_components/UsersTableActions';
import { getAllRoles } from 'redux/actions/roles.actions';

import { useTranslation } from 'react-i18next';
import { RemoveTagsBulk } from './local_components/modals/RemoveTagsModal';
import TableEmptyState from 'components/features/data_table/table_helpers/TableEmptyState';
import { AddRoleModal } from './local_components/modals/AddRoleModal';
import { RemoveRoleFromUsersDialog } from './local_components/modals/RemoveRoleFromUsersDialog';
import { useFilters } from 'components/features/data_table/pagination_component/useFilters';
import { Body2 } from 'style/typography/Body';
import { ResendInvitesModal } from './local_components/modals/ResendInvitesModal';

import './OrganizationUsers.css';
import NoTableValue from 'components/features/data_table/NoTableValue';
import { LoadingAnimation } from 'components/features/lottie/LoadingAnimation';

export function OrganizationUsers() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [filteredData, setFilteredData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [tagOptions, setTagOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);

    const [isTagModalOpen, setIsTagModalOpen] = useState(false);
    const [isRemoveTagModalOpen, setIsRemoveTagModalOpen] = useState(false);

    const [isRolesModalOpen, setIsRolesModalOpen] = useState(false);
    const [isRemoveRolesModalOpen, setIsRemoveRolesModalOpen] = useState(false);
    const [isResendInvitesModalOpen, setIsResendInvitesModalOpen] = useState(false);

    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const [activateModalOpen, setActivateModalOpen] = useState(false);
    const [userToBeUpdated, setUserToBeUpdated] = useState(null);
    const [inviting, setInviting] = useState(false);
    
    const rolesState = useSelector(state => state.userManagement.roles);
    const deactivateUsers = useSelector(state => state.userManagement.deactivateUser);
    const activateUsers = useSelector(state => state.userManagement.activateUser);
    const deleteUsers = useSelector(state => state.userManagement.deleteUser);
    const usersPaginatedState = useSelector(state => state.userManagement.usersPaginated);
    const tagsState = useSelector(state => state.content.tags);

    const [totalItems, setTotalItems] = useState(0);
    const [currentActionOption, setCurrentActionOption] = useState(false);

    const isInitialLoad = useRef(true);

    const location = useLocation();
    const { t } = useTranslation();

    const {
        areAllOptionsFromThisPageSelected,
        selectedRows,
        setSelectedRows,
        deselectAll,
        selectAll,
        addSelectedRow,
        removeSelectedRow,
        setAllQuestionsSelected,
        allQuestionsSelected,
    } = useTableSelections({
        paginatedState: usersPaginatedState,
        rowSelector: row => row.id,
    });

    const { filtersState, activeFilters, dispatchFilters, updateFilterState } = useFilters({ initialFiltersState: usersFiltersInitialState });

    const filters = {
        name: filtersState.name,
        role: filtersState.role,
        status: filtersState.status,
        page: filtersState.page,
        size: filtersState.size,
        tags: filtersState.tags.map(el => el.value),
        orderBy: filtersState.sortParam?.selector,
        ascending: filtersState.sortParam?.ascending,
    };
    const { getPrevious, getNext, getPage } = usePagination({
        paginatedState: usersPaginatedState,
        currentPage: filters.page,
        setCurrentPage: page => dispatchFilters({ page: page }),
    });

    let selectedRowsKeys = Object.keys(selectedRows);
    const totalSelectedItems = allQuestionsSelected ? totalItems : selectedRowsKeys.length;

    const columns = useMemo(
        () => [
            {
                name: <CustomCheckboxForTable checked={areAllOptionsFromThisPageSelected()} selectAll={selectAll} deselectAll={deselectAll} />,
                width: '50px',
                cell: row => {
                    return (
                        <div style={{ height: '100%', width: '100%' }}>
                            <Checkbox
                                onClick={e => {
                                    if (!row.selected) {
                                        addSelectedRow(row);
                                    } else {
                                        removeSelectedRow(row);
                                        setAllQuestionsSelected(false);
                                    }
                                }}
                                checked={!!row.selected}
                            ></Checkbox>
                        </div>
                    );
                },
            },
            {
                name:
                    selectedRowsKeys.length === 0 ? (
                        <TableHeader text={t('Name')} />
                    ) : (
                        <UsersTableActions
                            currentActionOption={currentActionOption}
                            totalSelectedItems={totalSelectedItems}
                            setIsTagModalOpen={setIsTagModalOpen}
                            setIsRemoveTagModalOpen={setIsRemoveTagModalOpen}
                            setIsRolesModalOpen={setIsRolesModalOpen}
                            setIsRemoveRolesModalOpen={setIsRemoveRolesModalOpen}
                            setIsResendInvitesModalOpen={setIsResendInvitesModalOpen}
                            filters={filters}
                        />
                    ),
                selector: 'name',
                sortable: selectedRowsKeys.length === 0,
                allowOverflow: true,
                wrap: true,
                width: 'calc(25% - 48px)',
                fontWeight: '800w',
                cell: row => {
                    return (
                        <TableCellContent
                            to={{
                                pathname: `/user/${row.id}`,
                                state: {
                                    filters: filters,
                                },
                            }}
                            elementBefore={
                                <div className="mr-2">
                                    <UserCircle className={'pt-2 pb-2'} name={row.name ?? ''} lastName={row.lastName ?? ''} bgColor={colors.gray4} />
                                </div>
                            }
                            text={row.name ? row.name : <Body2 color={colors.gray6}>{row.isActive ? '' : t('Invite pending..')}</Body2>}
                            isLoading={dataLoading}
                        />
                    );
                },
            },
            {
                name: selectedRowsKeys.length === 0 && <TableHeader text={t('E-Mail / Number')} />,
                selector: 'email',
                sortable: selectedRowsKeys.length === 0,
                width: '25%',
                cell: row => (
                    <TableCellContent
                        to={{
                            pathname: `/user/${row.id}`,
                            state: {
                                filters: filters,
                            },
                        }}
                        isLoading={dataLoading}
                        text={row.email ? row.email : `+${row.countryCode} ${row.phoneNumber}`}
                    />
                ),
            },
            {
                name: selectedRowsKeys.length === 0 && <TableHeader text={t('Tags')} />,
                width: '23%',
                cell: row => renderTags(row.tags),
                allowOverflow: true,
                wrap: true,
            },
            {
                name: selectedRowsKeys.length === 0 && <TableHeader text={t('Role')} />,
                selector: 'roleName',
                sortable: selectedRowsKeys.length === 0,
                width: '13%',
                cell: row => (
                    <TableCellContent
                        to={{
                            pathname: `/user/${row.id}`,
                            state: {
                                filters: filters,
                            },
                        }}
                        isLoading={dataLoading}
                        text={row.roleName}
                    />
                ),
            },
            {
                name: selectedRowsKeys.length === 0 && <TableHeader text={t('Access level')} />,
                selector: 'authRole',
                sortable: selectedRowsKeys.length === 0,
                width: '13%',
                cell: row => (
                    <TableCellContent
                        to={{
                            pathname: `/user/${row.id}`,
                            state: {
                                filters: filters,
                            },
                        }}
                        navigationLink={`/user/${row.id}`}
                        isLoading={dataLoading}
                        text={getRoleParsed(row.authRole)}
                    />
                ),
            },
        ],
        [
            dataLoading,
            filteredData,
            selectedRows,
            areAllOptionsFromThisPageSelected,
            addSelectedRow,
            removeSelectedRow,
            currentActionOption,
            selectAll,
        ]
    );

    const Actions = () => (
        <div>
            <button className={'btn btn-outline-secondary border-black text-black'} onClick={deactivateUser}>
                {t('Deactivate')}
            </button>
        </div>
    );

    function navigateToUser(row) {
        history.push(`/user/${row.id}`, {
            filters: filters,
        });
    }

    function getUsers() {
        dispatch(fetchUsersPaginatedAction({ ...filters, page: 0 }));
        dispatchFilters({ page: 0 });
    }

    const handleSort = async (column, sortDirection) => {
        dispatchFilters({ page: 0, sortParam: { selector: column.selector, ascending: sortDirection == 'asc' ? 'true' : 'false' } });
    };

    function renderTags(tags) {
        if (dataLoading) {
            return <SkeletonLoader />;
        }
        if (!tags || tags.length === 0) return <NoTableValue />;
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', flexWrap: 'wrap', padding: '10px 0' }}>
                {tags.map(tag => (
                    <TagPill tag={tag} />
                ))}
            </div>
        );
    }

    function getRoleParsed(roleName) {
        if (roleName != undefined && roleName != null && roleName.length > 0) {
            var roleName = roleName?.toLowerCase().replace('role_', '');
            return roleName[0]?.toUpperCase() + roleName?.slice(1);
        }
        return '';
    }

    function deactivateUser() {
        if (userToBeUpdated !== null) {
            dispatch(deactivateUserAction(userToBeUpdated.id));
        }
    }

    function activateUser() {
        if (userToBeUpdated !== null) {
            dispatch(activateUserAction(userToBeUpdated.id));
        }
    }

    function getUsersPaginated() {
        let users = [];

        if (stateIsLoaded(usersPaginatedState)) {
            try {
                usersPaginatedState.data.content.forEach(el => {
                    let divider = el.name && el.lastName ? ' ' : '';
                    let userName = (el.name ? el.name : '') + divider + (el.lastName ? el.lastName : '');

                    let user = {
                        active: el.active,
                        authRole: el.authRole,
                        company: el.company,
                        roleName: el.companyRole ? el.companyRole.roleName : '',
                        tags: el.tags,
                        roleId: el.companyRole ? el.companyRole.roleId : 0,
                        email: el.email,
                        globalRank: el.globalRank,
                        id: el.id,
                        isActive: el.isActive,
                        lastName: el.lastName,
                        name: userName,
                        status: el.status,
                        phoneNumber: el.phoneNumber,
                        countryCode: el.countryCode,
                    };
                    users.push(user);
                });
            } catch (e) {}
        }
        return users;
    }

    function filterData() {
        let users = getUsersPaginated();
        setFilteredData(
            users.filter(el => {
                let result = true;

                if (selectedRows[el.id] || allQuestionsSelected) {
                    el.selected = true;
                } else {
                    el.selected = false;
                }

                return result;
            })
        );
        setDataLoading(false);
    }

    const resetBulkActions = useCallback(() => {
        if (Object.keys(selectedRows).length > 0) {
            setSelectedRows({});
        }
        setAllQuestionsSelected(false);
        setCurrentActionOption(false);
    }, [selectedRows]);

    const tagsFromSelectedUsers = useMemo(() => {
        let allTagsInUsers = {};
        Object.keys(selectedRows).forEach(key => {
            let currentTags = selectedRows[key].tags;
            currentTags.forEach(tag => {
                if (!(tag.tagId in allTagsInUsers)) {
                    allTagsInUsers[tag.tagId] = tag;
                }
            });
        });
        return Object.values(allTagsInUsers);
    }, [selectedRows]);

    function hasFilters() {
        return filters.name || filters.role || filters.status || filters.tags.length > 0;
    }

    useEffect(() => {
        dispatch(getTagsAction());
        dispatch(getAllRoles());

        if (location?.search) {
            const query = new URLSearchParams(location.search);

            dispatchFilters({
                name: query.get('name'),
                status: query.get('status'),
                role: Number(query.get('role')),
                size: Number(query.get('size') ?? 10),
                page: Number(query.get('page') ?? 0),
                sortParam: { selector: query.get('orderBy'), ascending: query.get('ascending') },
            });
        }
    }, []);

    useEffect(() => {
        if (stateIsLoaded(tagsState) && tagsState.data) {
            let labelMap = {};

            for (let tagIndx in tagsState.data) {
                let tag = tagsState.data[tagIndx];
                if (!(tag.label in labelMap)) {
                    labelMap[tag.label] = [];
                }
                labelMap[tag.label].push({ label: tag.tag, value: tag.tagId });
            }
            let formatedTagOptions = [];

            for (let labelIndx in Object.keys(labelMap)) {
                let label = Object.keys(labelMap)[labelIndx];
                formatedTagOptions.push({
                    label: label,
                    options: labelMap[label],
                });
            }
            setTagOptions(formatedTagOptions);

            if (location?.search) {
                const query = new URLSearchParams(location.search);
                let tagIds = query
                    ?.get('tags')
                    ?.split(',')
                    .filter(el => el);
                if (tagIds && tagIds.length > 0) {
                    let filterValTag = tagsState.data
                        .filter(el => tagIds.includes(el.tagId.toString()))
                        .map(el => {
                            return { label: el.tag, value: el.tagId };
                        });
                    dispatchFilters({ tags: filterValTag });
                }
            }
        }
    }, [tagsState]);

    useEffect(() => {
        if (stateIsLoaded(rolesState)) {
            setRoleOptions(
                rolesState.data.roles.map(el => {
                    return {
                        label: el.roleName,
                        value: +el.roleId,
                    };
                })
            );
        }
    }, [rolesState]);

    useEffect(() => {
        if (stateIsLoaded(usersPaginatedState)) {
            filterData();
            setTotalItems(usersPaginatedState.data.totalElements);
            isInitialLoad.current = false;
        }
    }, [usersPaginatedState, selectedRows, allQuestionsSelected]);

    useEffect(() => {
        if (stateIsLoaded(deactivateUsers) || stateIsLoaded(deleteUsers) || stateIsLoaded(activateUsers)) {
            setDeactivateModalOpen(false);
            setActivateModalOpen(false);
            toast.success(t('Successfully saved!'));
            setTimeout(() => window.location.reload(), 2000);
        }
    }, [deactivateUsers, activateUsers, deleteUsers]);

    useEffect(() => {
        setDataLoading(true);
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(
                    fetchUsersPaginatedAction({
                        ...filters,
                    })
                );
                let search = linkBuilderFromQuery('', filters);
                history.push({
                    pathname: `/users/organization`,
                    search: search ? search : '',
                });
            },
            300,
            'fetchUsersPaginatedActionUseEffect'
        );
        return () => setTimeoutLastExecute.removeCallback('fetchUsersPaginatedActionUseEffect');
    }, [filtersState]);

    if (dataLoading && !hasFilters() && isInitialLoad.current) {
        return <LoadingAnimation />;
    }

    return (
        <>
            <ModalDialog
                title={t('Confirm deactivation')}
                modalOpen={deactivateModalOpen}
                setModalOpen={setDeactivateModalOpen}
                onAction={() => deactivateUser()}
                alert={
                    <Alert className={'mt-2 mb-2'} severity="warning">
                        {t('Please read this carefully before deactivating a user.')}
                    </Alert>
                }
                buttons={[
                    {
                        title: 'Deactivate user',
                        color: colors.white,
                    },
                ]}
                onClose={() => setUserToBeUpdated(null)}
                text={t(
                    `The user {{user}} will no longer be able to login to the Coach app. If the user is an administrator they will automatically lose their admin role. The user and their past statistics won’t appear in the user profile, the their data won’t be taken into account in the global analytics. If you want to re-activate this user again in the future, the analytic data will start from scratch again.`,
                    { user: userToBeUpdated?.name }
                )}
                hasCancel={true}
                state={deactivateUsers}
                hasLoader={true}
            ></ModalDialog>
            <ModalDialog
                title={t('Activate this user')}
                modalOpen={activateModalOpen}
                setModalOpen={setActivateModalOpen}
                onAction={() => activateUser()}
                buttons={[
                    {
                        title: t('Activate user'),
                        color: colors.white,
                    },
                ]}
                text={t(
                    'The user {{user}} will be granted access to the Coach app again, and their statistics will be reset so the data can be collected from zero. If the user held an admin role at deactivation, you may want to grant them that role once again, from their profile settings.',
                    { user: userToBeUpdated?.name }
                )}
                onClose={() => setUserToBeUpdated(null)}
                hasCancel={true}
                state={activateUsers}
                hasLoader={true}
            ></ModalDialog>
            {isTagModalOpen && (
                <SetTagsBulkModal
                    modalOpen={isTagModalOpen}
                    setModalOpen={setIsTagModalOpen}
                    onAction={selectedTags => {
                        if (selectedTags.length > 0) {
                            dispatch(addUsersToTagsAction(selectedRowsKeys, selectedTags, allQuestionsSelected));
                        }
                    }}
                    allUsersSelected={allQuestionsSelected}
                    usersIdsList={selectedRowsKeys}
                    onClose={success => {
                        if (success) {
                            toast.success(t('Successfully saved tags!'));
                            setSelectedRows({});
                            setAllQuestionsSelected(false);
                            dispatch(fetchUsersPaginatedAction(filters));
                        }
                    }}
                    bulkItemsCount={totalSelectedItems}
                />
            )}
            {isRemoveTagModalOpen && (
                <RemoveTagsBulk
                    modalOpen={isRemoveTagModalOpen}
                    setModalOpen={setIsRemoveTagModalOpen}
                    onAction={selectedTags => {
                        if (selectedTags.length > 0) {
                            dispatch(removeUsersFromTagsAction(selectedRowsKeys, selectedTags, allQuestionsSelected));
                        }
                    }}
                    allUsersSelected={allQuestionsSelected}
                    usersIdsList={selectedRowsKeys}
                    tagsInUsers={tagsFromSelectedUsers}
                    onClose={success => {
                        if (success) {
                            toast.success(t('Successfully removed tags!'));
                            setSelectedRows({});
                            setAllQuestionsSelected(false);
                            dispatch(fetchUsersPaginatedAction(filters));
                        }
                    }}
                    bulkItemsCount={totalSelectedItems}
                />
            )}
            {isRolesModalOpen && (
                <AddRoleModal
                    modalOpen={isRolesModalOpen}
                    setModalOpen={setIsRolesModalOpen}
                    allUsersSelected={allQuestionsSelected}
                    usersList={selectedRows}
                    bulkItemsCount={totalSelectedItems}
                />
            )}
            {isRemoveRolesModalOpen && (
                <RemoveRoleFromUsersDialog
                    modalOpen={isRemoveRolesModalOpen}
                    setModalOpen={setIsRemoveRolesModalOpen}
                    allUsersSelected={allQuestionsSelected}
                    usersList={selectedRows}
                    bulkItemsCount={totalSelectedItems}
                />
            )}
            {isResendInvitesModalOpen && (
                <ResendInvitesModal
                    modalOpen={isResendInvitesModalOpen}
                    setModalOpen={setIsResendInvitesModalOpen}
                    allUsersSelected={allQuestionsSelected}
                    usersList={selectedRowsKeys}
                />
            )}
            <InvitationModal getUsers={getUsers} isInviting={inviting} onDoneInviting={() => setInviting(false)} />

            <div className="org-users--data-table" style={{ display: 'flex', flexDirection: 'column' }}>
                <DataTable
                    contextActions={<Actions />}
                    sortIcon={selectedRowsKeys.length === 0 ? <img alt="Sort" height={15} width={13} className="ml-2" src={CaretTable}></img> : <></>}
                    columns={columns}
                    onSort={handleSort}
                    sortServer
                    className="overflow-visible"
                    data={filteredData}
                    overflowY={true}
                    overflowYOffset={'0px'}
                    noDataComponent={<TableEmptyState />}
                    defaultSortField="name"
                    subHeader={true}
                    paginationPerPage={100}
                    onRowClicked={row => navigateToUser(row)}
                    pagination={true}
                    selectableRows={false}
                    highlightOnHover={true}
                    subHeaderComponent={
                        <Subheader
                            inputs={usersTableSubheaders({
                                filtersState,
                                activeFilters,
                                dispatchFilters,
                                updateFilterState,
                                tagOptions,
                                roleOptions,
                                setInviting,
                                resetBulkActions,
                            })}
                        />
                    }
                    subHeaderAlign={'left'}
                    customStyles={customStylesTable}
                    theme={'tableTheme'}
                    selectableRowSelected={row => row.selected}
                    noHeader={true}
                    paginationComponent={_ => (
                        <PaginationComponent
                            getPrev={getPrevious}
                            getNext={getNext}
                            getPage={getPage}
                            currentPage={filters.page}
                            totalPages={usersPaginatedState.data.totalPages}
                            totalItems={usersPaginatedState?.data?.totalElements}
                            size={filters.size}
                            setSize={size => {
                                dispatchFilters({ page: 0, size: size });
                            }}
                        />
                    )}
                ></DataTable>
            </div>
        </>
    );
}
