import React, { useState } from 'react';

import './ContentBlocksBuilder.css';

import { Caption1, Caption2 } from 'style/typography/Caption';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { Subtitle2 } from 'style/typography/Subtitle';
import { colors } from 'style/colors';
import { useContentBlocksBuilder } from 'services/content-blocks-management/content-blocks-builder';

import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';
import { contentBlockConstraintsByGame, games } from 'services/content-blocks-management/content-blocks-builder/local-constants';
import { useParams } from 'react-router-dom';

function ContentBlockValuesListItem({ element, index, picker, questionAttributeType, answerAttributeType, hasImage }) {
    const { game } = useParams();

    const { autoGenerateValue, setAutogenerateValue, correctValuesMap } = useContentBlocksBuilder();

    const [showItems, setShowItems] = useState(false);

    let correctValuesForElement = correctValuesMap[element].answers;
    let constraintsByGame = contentBlockConstraintsByGame[game];

    let canSelect = correctValuesForElement.length >= constraintsByGame.minimumCorrectAnswers;
    return (
        <>
            <div className="cb-builder--answers-attribute">
                <div className="cb-builder--answers-index">
                    <div>{index + 1}</div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {questionAttributeType === "image" && <img style={{ margin: '10px 5px' }} src={element} height={50} width={50}></img>}
                    {questionAttributeType !== "image" && <Subtitle2 style={{ maxWidth: '80%' }} weight="medium">{element}</Subtitle2>}
                    <Caption1 style={{ padding: '0px 5px' }} color={colors.darkWithOpacity1}>{`(${correctValuesForElement.length} item${correctValuesForElement.length > 1 ? "s" : ""})`}</Caption1>

                    <img onClick={() => setShowItems(prevValue => !prevValue)} height={15} width={15} src={showItems ? AngleUp : AngleDown} />
                </div>
                {picker && <div style={{ position: 'relative' }}>
                    {canSelect && <Checkbox
                        circular={true}
                        onClick={() => {
                            setAutogenerateValue(element);
                        }}
                        checked={autoGenerateValue == element}
                    ></Checkbox>}
                    {
                        !canSelect && <Caption2 style={{ padding: '0px 5px' }} color={colors.darkWithOpacity1}>{"Not applicable"}</Caption2>
                    }
                </div>}
            </div>
            {showItems &&
                correctValuesForElement.map(elAnswer => {
                    if (answerAttributeType === "image") {
                        return <img style={{ margin: '10px 5px' }} src={elAnswer.value} height={50} width={50}></img>
                    } else {
                        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="cb-builder--answers-list">
                            {hasImage && <img style={{ margin: '10px 5px' }} src={elAnswer.image} height={30} width={30}></img>}
                            <Caption1 color={colors.gray6} >
                                {elAnswer.value}
                            </Caption1>
                        </div>
                    }
                }
                )
            }
        </>
    );
}





export default function ContentBlocksValuesList() {
    const { game } = useParams();
    const { correctValuesMap } = useContentBlocksBuilder();

    if (!correctValuesMap) return <div></div>;
    return (
        <div className="cb-builder--answers">
            {(game === games.TRAFFIC || game === games.RACE_CARS || game === games.RECALL || game === games.NUMBERS) &&
                Object.keys(correctValuesMap).map((el, index) =>
                    <ContentBlockValuesListItem element={el} index={index} picker={true} />
                )
            }
            {game === games.PINPOINT &&
                Object.keys(correctValuesMap).map((el, index) =>
                    <ContentBlockValuesListItem element={el} index={index} picker={true} hasImage={true} />
                )
            }
            {game === games.SORT_IT &&
                Object.keys(correctValuesMap).map((el, index) =>
                    <ContentBlockValuesListItem element={el} index={index} picker={true} answerAttributeType={"image"} />
                )
            }
            {game === games.COMPARE &&
                Object.keys(correctValuesMap).map((el, index) =>
                    <ContentBlockValuesListItem element={el} index={index} picker={true} questionAttributeType={"image"} />
                )
            }
        </div>
    );
}
