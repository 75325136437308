import React, { useReducer, useEffect, useMemo, useContext } from 'react';
import { actionTypes } from './local-actions';
import { optionDefaults, INITIAL_STATE } from './local-constants';
import { randomize } from 'services/utils/arrayHelpers';
import _ from 'lodash';
import { ContextDevTool } from 'react-context-devtool';
import { insertionControlReducer } from './local-reducer';

const InsertionTemporaryContext = React.createContext();

export const ImageEditorProvider = ({ children, index, imageLimit, setOpenImageResizer }) => {
    // let reducerState = _.cloneDeep(initialState);
    let reducerState = INITIAL_STATE;

    const [state, dispatch] = useReducer(insertionControlReducer, reducerState);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    useEffect(() => {
        dispatch({
            type: actionTypes.setIndex,
            payload: index,
        });
    }, [index]);

    useEffect(() => {
        dispatch({
            type: actionTypes.setImageLimit,
            payload: imageLimit,
        });
    }, [imageLimit]);

    useEffect(() => {
        dispatch({
            type: actionTypes.setImageResizerOpener,
            payload: setOpenImageResizer,
        });
    }, [setOpenImageResizer]);

    return (
        <InsertionTemporaryContext.Provider value={contextValue}>
            <>
                <ContextDevTool context={InsertionTemporaryContext} id="addImageContextId" displayName="Add image context" />
                {children}
            </>
        </InsertionTemporaryContext.Provider>
    );
};

export const ContentInsertionConsumer = () => {
    return (
        <InsertionTemporaryContext.Consumer>
            {values => {
                if (window._REACT_CONTEXT_DEVTOOL) {
                    window._REACT_CONTEXT_DEVTOOL({ id: 'addImageContextId', displayName: 'Add image consumer', values });
                }
                return null;
            }}
        </InsertionTemporaryContext.Consumer>
    );
};

const useState = () => {
    const { state } = useContext(InsertionTemporaryContext);
    return state;
};

const useDispatch = () => {
    const { dispatch } = useContext(InsertionTemporaryContext);
    return dispatch;
};

export const useImageEditor = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        imageFiles: state.imageFiles,
        view: state.view,
        currentActivePreviewImage: state.currentActivePreviewImage,
        aspectRatio: state.aspectRatio,
        setOpenImageResizer: state.setOpenImageResizer,
        imageLimit: state.imageLimit,
        currentActiveIndex: state.currentActiveIndex,
        treeData: state.treeData,
        currentTab: state.currentTab,
        currentNode: state.currentNode,
        currentPath: state.currentPath,
        currentPage: state.currentPage,
        paginationTotalPages: state.paginationTotalPages,
        galleryImages: state.galleryImages,
        filteredImages: state.filteredImages,
        selectedImages: state.selectedImages,
        imageSearchValue: state.imageSearchValue,
        size: state.size,
        isGrid: state.isGrid,
        sortBy: state.sortBy,
        allImagesSelected: state.allImagesSelected,
        setImageFiles: payload => dispatch({ type: actionTypes.setImageFiles, payload: payload }),
        setCurrentActiveIndex: payload => dispatch({ type: actionTypes.setCurrentActiveIndex, payload: payload }),
        setAspectRatio: payload => dispatch({ type: actionTypes.setAspectRatio, payload: payload }),
        setCropAndZoom: payload => dispatch({ type: actionTypes.setCropAndZoom, payload: payload }),
        setCroppedAreaPixels: (file, croppedAreaPixels, aspectRatio) =>
            dispatch({
                type: actionTypes.setCroppedAreaPixels,
                payload: {
                    file: file,
                    croppedAreaPixels: croppedAreaPixels,
                    aspectRatio: aspectRatio,
                },
            }),
        deleteCurrentImage: payload => dispatch({ type: actionTypes.deleteCurrentImage, payload: payload }),
        setCurrentActivePreviewImage: payload => dispatch({ type: actionTypes.setCurrentActivePreviewImage, payload: payload }),
        setView: payload => dispatch({ type: actionTypes.setView, payload: payload }),
        setCurrentTab: payload => dispatch({ type: actionTypes.setCurrentTab, payload: payload }),
        setTreeData: payload => dispatch({ type: actionTypes.setTreeData, payload: payload }),
        setCurrentNode: payload => dispatch({ type: actionTypes.setCurrentNode, payload: payload }),
        setCurrentPath: payload => dispatch({ type: actionTypes.setCurrentPath, payload: payload }),
        setCurrentPage: payload => dispatch({ type: actionTypes.setCurrentPage, payload: payload }),
        setPaginationTotalPages: payload => dispatch({ type: actionTypes.setPaginationTotalPages, payload: payload }),
        setGalleryImages: payload => dispatch({ type: actionTypes.setGalleryImages, payload: payload }),
        setFilteredImages: payload => dispatch({ type: actionTypes.setFilteredImages, payload: payload }),
        setSelectedImages: payload => dispatch({ type: actionTypes.setSelectedImages, payload: payload }),
        setImageSearchValue: payload => dispatch({ type: actionTypes.setImageSearchValue, payload: payload }),
        setSize: payload => dispatch({ type: actionTypes.setSize, payload: payload }),
        updateSelectedImage: payload => dispatch({ type: actionTypes.updateSelectedImage, payload: payload }),
        setIsGrid: payload => dispatch({ type: actionTypes.setIsGrid, payload: payload }),
        setSortBy: payload => dispatch({ type: actionTypes.setSortBy, payload: payload }),
        setAllImagesSelected: payload => dispatch({ type: actionTypes.setAllImagesSelected, payload: payload }),
    };
};
