import { create_UUID } from 'services/utils/stringHelpers';

export const buttonActions = [
    {
        label: 'Open learn',
        value: 'open_learn',
    },
];

export const initialNewsState = {
    availability: '',
    type: '',
    platform: '',
    buttonTitle: '',
    buttonAction: '',
    removedArticleIds: [],
    articles: [{ id: create_UUID(), title: '', story: '', img: '' }],
};

export const initialArticleState = { id: create_UUID(), title: '', story: '', img: '' };
