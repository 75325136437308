import React from 'react';
import Text from './Text';
import { colors } from 'style/colors';

const Subtitle = ({ weight, tag, fontWeight, fontHeight, color = colors.dark, style, ...attr }) => {
    return Text(weight, subtitleTagDefaults[tag], fontWeight, fontHeight, color, style, attr);
};

const Subtitle1 = props => <Subtitle {...props} tag="subtitle1" />;
const Subtitle2 = props => <Subtitle {...props} tag="subtitle2" />;

const subtitleTagDefaults = {
    subtitle1: {
        fontSize: 20,
    },
    subtitle2: {
        fontSize: 18,
    },
};

export { Subtitle1, Subtitle2 };
