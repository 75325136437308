import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FeedbackTextBox } from 'containers/layout/module/pages/internal_messaging/feedback/local_components/FeedbackTextBox';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { Body2 } from 'style/typography/Body';
import { Subtitle2 } from 'style/typography/Subtitle';
import { linkBuilderFromQuery } from 'services/api/api_builders';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from 'components/elements/Button';

import GoToLink from 'assets/icons/icon-gotolink.svg';
import Ellipsis from 'assets/icons/ellipsis.svg';

export default function FeedbackViewPreview({ feedback, history, setUpdateModalOpen, setDeleteModalOpen }) {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="col-6 d-flex flex-column border-right pt-3 pl-4 pr-4 pb-0 ml-4">
            <div className={'d-flex flex-row'}>
                <div
                    id="back-to-feedbacks-button"
                    role="button"
                    onClick={() => {
                        let type = location?.state?.type;
                        history.replace({
                            pathname: type ? `/internal/${type}-feedback` : '/internal/content-feedack',
                            search: location?.state?.filters ? linkBuilderFromQuery('', location?.state?.filters) : '',
                        });
                    }}
                    className="d-flex flex-row align-items-center ml-2"
                >
                    <FontAwesomeIcon color={'gray'} className={'mr-2'} icon={faArrowLeft} />
                    <Body2 color={colors.gray6} className={'mb-0'}>
                        {t('Back to feedback list')}
                    </Body2>
                </div>
                <div className={'ml-auto d-flex flex-row align-items-center'}>
                    {feedback.feedbackType !== 'POSITIVE' && (
                        <DefaultButton
                            id="resolve-unresolve-button-modal"
                            text={feedback.isResolved ? t('Unresolve issue') : t('Resolve issue')}
                            className={'mr-3'}
                            onClick={() => setUpdateModalOpen(true)}
                        />
                    )}
                    <DefaultButton
                        id="resolve-unresolve-button-modal"
                        text={t('Delete issue')}
                        backgroundColor={colors.white}
                        textColor={colors.dark}
                        onClick={() => setDeleteModalOpen(true)}
                    />
                </div>
            </div>

            {feedback.feedbackType.toLowerCase() === 'content' && (
                <div className={'mt-5'}>
                    <Caption1 weight="medium" color={colors.descriptionText}>
                        {t('QUESTION')}
                    </Caption1>
                    <div className="d-flex flex-row pt-2">
                        <Subtitle2>{feedback.questionText}</Subtitle2>
                        <Link to={`/content/questions/update/${feedback.questionId}`} target="_blank">
                            <img alt="Go to" style={{ cursor: 'pointer', marginLeft: '10px' }} src={GoToLink} height={20} width={20} />
                        </Link>
                    </div>
                </div>
            )}
            <div className={'mt-5'}>
                <Caption1 weight="medium" color={colors.descriptionText}>
                    {t('FEEDBACK')}
                </Caption1>
                <FeedbackTextBox user={feedback.user.split(' ')[0]} text={feedback.feedbackText} type={feedback.feedbackType}></FeedbackTextBox>
            </div>
        </div>
    );
}
