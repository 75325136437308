import PropTypes from 'prop-types';
import React from 'react';
import { useThemePalette } from 'style/theme/consumers';

const ErrorTooltip = ({ message, position = 'center', arrowAtBottom = false }) => {
    const arrowStyles = arrowAtBottom ? { bottom: '0', rotate: '180deg' } : { top: 0 };

    const palette = useThemePalette();
    return (
        <div
            style={{
                position: 'absolute',
                backgroundColor: palette.secondary.main,
                color: 'white',
                padding: '5px 15px',
                left: position === 'center' ? '50%' : position === 'left' ? '10px' : 'unset',
                right: position === 'right' ? '10px' : 'unset',
                zIndex: 999,
                transform: position === 'center' ? 'translateX(-50%)' : '',
                borderRadius: '5px',
                fontWeight: 'bold',
                minWidth: 100,
            }}
        >
            <div
                style={{
                    width: '20px',
                    height: '20px',
                    transform: 'rotate(45deg) translateX(-50%)',
                    position: 'absolute',
                    backgroundColor: palette.secondary.main,
                    bottom: '0',
                    rotate: '180deg',
                    left: '50%',
                    zIndex: -1,
                    ...arrowStyles,
                }}
            ></div>
            {message}
        </div>
    );
};

ErrorTooltip.propTypes = {
    message: PropTypes.any,
    position: PropTypes.string,
};

export default ErrorTooltip;
