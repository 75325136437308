import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { useDispatch } from 'react-redux';
import { imagesDeleteSoftAction } from 'redux/actions/image.actions';
import { useTranslation } from 'react-i18next';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { tabs } from 'services/image_management/image-editor-management/local-constants';

export function AreYouSureDeleteModal({ modalOpen, setModalOpen, selectedIds }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { imageSearchValue, allImagesSelected, currentTab, currentNode } = useImageEditor();

    const getFolder = () => {
        if (currentTab === tabs.all_images) {
            return 'all';
        } else if (currentTab === tabs.uncategorized) {
            return 'uncategorized';
        } else if (currentTab === tabs.folder) {
            return currentNode.treeIndex;
        }
    };

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onClose={() => {}}
            onAction={() => {
                setModalOpen(false);
            }}
            title={t('Are you sure you want to delete selected images?')}
            text={t('Deleting the images will permanently remove them from the gallery')}
            buttons={[
                {
                    title: t('Sure'),
                    color: colors.white,
                    onClick: () => {
                        let folder = getFolder();
                        dispatch(
                            imagesDeleteSoftAction({
                                images: selectedIds,
                                data: {
                                    allSelected: allImagesSelected,
                                    folderId: !isNaN(Number(folder)) ? folder : null,
                                    folderAll: folder === 'all',
                                    folderUncategorized: folder === 'uncategorized',
                                    filters: { searchQuery: imageSearchValue },
                                },
                            })
                        );
                        setModalOpen(false);
                    },
                },
                {
                    title: t('Cancel'),
                    color: colors.white,
                },
            ]}
            hasCancel={false}
        ></ModalDialog>
    );
}

AreYouSureDeleteModal.propTypes = {
    modalOpen: PropTypes.any,
    selectedIds: PropTypes.any,
    setModalOpen: PropTypes.func,
};
