import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ResizableBox } from 'react-resizable';
import { colors } from 'style/colors';
import { useImageEditor } from 'services/image_management/image-editor-management';

import { OutlinedButton } from 'components/elements/Button';

import NewFolder from 'assets/icons/icon-gallery-newfolder.svg';
import AllImages from 'assets/icons/icon-gallery-allimgs.svg';
import AllImagesWhite from 'assets/icons/icon-gallery-allimgs-white.svg';
import Uncategorized from 'assets/icons/icon-gallery-uncategorized.svg';
import UncategorizedWhite from 'assets/icons/icon-gallery-uncategorized-white.svg';

import { Caption1 } from 'style/typography/Caption';
import GalleryFolderTab from './GalleryFolderTab';
import {
    getAllImagesAction,
    getFolderStructure,
    getImagesCountAction,
    getImagesFromFolderAction,
    getUncategorizedImagesAction,
    resetDeleteFolderAction,
    resetSaveFolersAction,
} from 'redux/actions/image.actions';
import { useDispatch, useSelector } from 'react-redux';
import { tabs } from 'services/image_management/image-editor-management/local-constants';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { convertFromFlatToTree, getNodeKey } from 'services/utils/treeHelpers';
import GalleryFoldersTree from './GalleryFoldersTree';
import { useImageLibraryHelper } from 'services/image_management/imageLibraryHelper';

import '../Gallery.css';

export default function GalleryFolders({ setFolderModalOpen, toSave }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        currentNode,
        currentPage,
        setCurrentPage,
        size,
        setCurrentTab,
        currentTab,
        imageSearchValue,
        sortBy,
        setAllImagesSelected,
        setSelectedImages,
        setTreeData,
        setCurrentNode,
        setCurrentPath,
        galleryImages,
        aspectRatio,
        selectedImages,
    } = useImageEditor();

    const { updateGalleryImages, updateFolderStructure, filterGalleryImages } = useImageLibraryHelper();

    const [currentWidth, setCurrentWidth] = useState(300);
    const folderStructure = useSelector(state => state.content.folderStructure);
    const updateFolderStructureState = useSelector(state => state.content.updateFolderStructure);
    const deleteSingleFolderState = useSelector(state => state.content.deleteSingleFolder);
    const imagesCountState = useSelector(state => state.content.imagesCount);
    const allImages = useSelector(state => state.content.allImages);
    const uncategorizedImages = useSelector(state => state.content.uncategorizedImages);

    function onResize(event, { element, size, handle }) {
        setCurrentWidth(size.width);
    }

    function getImagesAction(filtersChanged, searchQuery) {
        if (currentTab === tabs.all_images) {
            dispatch(
                getAllImagesAction({
                    page: filtersChanged ? 0 : currentPage,
                    size: size,
                    searchQuery: searchQuery ?? imageSearchValue,
                    sortBy: sortBy,
                })
            );
        } else if (currentTab === tabs.uncategorized) {
            dispatch(
                getUncategorizedImagesAction({
                    page: filtersChanged ? 0 : currentPage,
                    size: size,
                    searchQuery: searchQuery ?? imageSearchValue,
                    sortBy: sortBy,
                })
            );
        } else if (currentTab === tabs.folder) {
            dispatch(
                getImagesFromFolderAction({
                    folderId: currentNode.treeIndex,
                    page: filtersChanged ? 0 : currentPage,
                    size: size,
                    searchQuery: searchQuery ?? imageSearchValue,
                    sortBy: sortBy,
                })
            );
        }
    }

    useEffect(() => {
        dispatch(getImagesCountAction());
    }, []);

    useEffect(() => {
        if (stateIsLoaded(folderStructure)) {
            updateFolderStructure();
        }
    }, [folderStructure]);

    useEffect(() => {
        if (stateIsLoaded(updateFolderStructureState)) {
            if (updateFolderStructureState.data?.length > 0) {
                let treeDataFromFlat = convertFromFlatToTree(updateFolderStructureState.data);
                setTreeData(treeDataFromFlat);
            }
            dispatch(getFolderStructure());
            resetSaveFolersAction();
        }
    }, [updateFolderStructureState]);

    useEffect(() => {
        if (stateIsLoaded(deleteSingleFolderState) && deleteSingleFolderState.data?.length > 0) {
            let treeDataFromFlat = convertFromFlatToTree(deleteSingleFolderState.data);
            setTreeData(treeDataFromFlat);
            setCurrentNode(treeDataFromFlat[0]);
            setCurrentPath(getNodeKey(treeDataFromFlat[0]));
        }
    }, [deleteSingleFolderState]);

    useEffect(() => {
        if (stateIsLoaded(deleteSingleFolderState)) {
            toast.success(t('Successfuly deleted folder'));
            dispatch(getImagesCountAction());
            dispatch(resetDeleteFolderAction());
            dispatch(getFolderStructure());
            setCurrentTab(tabs.all_images);
            dispatch(getAllImagesAction({ page: currentPage, size: size, searchQuery: imageSearchValue, sortBy: sortBy }));
        }
    }, [deleteSingleFolderState]);

    useEffect(() => {
        getImagesAction(false, null);
    }, [currentPage, size]);

    useEffect(() => {
        if (currentTab === tabs.all_images) updateGalleryImages();
    }, [allImages,aspectRatio]);

    useEffect(() => {
        if (currentTab === tabs.uncategorized) updateGalleryImages();
    }, [uncategorizedImages,aspectRatio]);



    useEffect(() => {
        if (galleryImages) {
            filterGalleryImages();
        }
    }, [galleryImages, selectedImages]);

    return (
        <ResizableBox
            className="custom-box box"
            width={currentWidth}
            height={Infinity}
            onResize={onResize}
            style={{ padding: '10px 20px 10px 30px' }}
            minConstraints={[300, 100]}
            maxConstraints={[600, Infinity]}
            axis="x"
        >
            <div className="d-flex flex-column h-100 w-100">
                <div className="d-flex flex-row align-items-center">
                    <Caption1 weight="medium" color={colors.gray6}>
                        {t('FOLDERS')}
                    </Caption1>
                    <OutlinedButton
                        className="ml-auto"
                        onClick={() => {
                            setFolderModalOpen(true);
                        }}
                    >
                        <img alt="Create new folder" src={NewFolder} style={{ width: 20, height: 20, marginRight: 10 }} />
                        {t('New folder')}
                    </OutlinedButton>
                </div>
                <GalleryFolderTab
                    onClick={() => {
                        dispatch(getAllImagesAction({ page: 0, size: size, searchQuery: imageSearchValue, sortBy: sortBy }));
                        setCurrentTab(tabs.all_images);
                        setCurrentPage(0);
                        setSelectedImages([]);
                        setAllImagesSelected(false);
                    }}
                    iconWhite={AllImagesWhite}
                    icon={AllImages}
                    text={t('All Images')}
                    selected={currentTab === tabs.all_images}
                    count={imagesCountState.data?.allCount}
                />
                <GalleryFolderTab
                    onClick={() => {
                        dispatch(getUncategorizedImagesAction({ page: 0, size: size, searchQuery: imageSearchValue, sortBy: sortBy }));
                        setCurrentTab(tabs.uncategorized);
                        setCurrentPage(0);
                        setSelectedImages([]);
                        setAllImagesSelected(false);
                    }}
                    iconWhite={UncategorizedWhite}
                    icon={Uncategorized}
                    text={t('Uncategorized')}
                    selected={currentTab === tabs.uncategorized}
                    count={imagesCountState.data?.uncategorizedCount}
                />
                <GalleryFoldersTree toSave={toSave} />
            </div>
        </ResizableBox>
    );
}

GalleryFolders.propTypes = {
    setFolderModalOpen: PropTypes.func,
    toSave: PropTypes.any,
};
