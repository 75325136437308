import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SockJsClient from 'react-stomp';
import { API_ENDPOINT } from 'services/api/requestAgent';

import { tokenHelper } from 'services/auth/tokenHelpers';
import { updateUsersInviteProgressAction } from '../../redux/actions/users.actions';
import { updateUserTagsProgressAction } from '../../redux/actions/tags.actions';
import { updateGeneratingContentProgressAction, updateUploadContentProgressAction } from 'redux/actions/content.actions';
import { updateQuestionsExportProgressAction, updateTrainingProgressAction } from 'redux/actions/trainings.actions';

export default function SocketsWrapper({ children }) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const token = useMemo(() => {
        return auth?.data?.access_token;
    }, [auth?.data?.access_token]);
    const [isExpired, setIsExpired] = useState(true);
    const sockJsRef = useRef(null);

    const identifierToActionMapper = {
        'users/invite': req => dispatch(updateUsersInviteProgressAction(req)),
        'content/upload': req => dispatch(updateUploadContentProgressAction(req)),
        'tags/upload': req => dispatch(updateUserTagsProgressAction(req)),
        'content/generate': req => dispatch(updateGeneratingContentProgressAction(req)),
        'training/upload': req => dispatch(updateTrainingProgressAction(req)),
        'questions/export': req => dispatch(updateQuestionsExportProgressAction(req)),
    };
    useEffect(() => {
        tokenHelper.isAccessTokenExpired().then(expired => {
            setIsExpired(expired);
        });
    }, [token]);

    return (
        <>
            {token && !isExpired && (
                <SockJsClient
                    url={`${API_ENDPOINT}/stomp`}
                    heartbeatIncoming={20000}
                    heartbeatOutgoing={0}
                    headers={{
                        Authorization: 'Bearer ' + token,
                    }}
                    // subscribeHeaders={customHeaders}
                    onConnect={con_msg => {
                        // console.log('WEBSOCKET CONNECTED');
                    }}
                    autoReconnect={true}
                    getRetryInterval={() => {
                        return 10000;
                    }}
                    onConnectFailure={con => {
                        // console.log('socket failed', con);
                    }}
                    // debug={true}
                    topics={[
                        '/topic/message',
                        '/queue/user/' + tokenHelper.parseJwt(token).id + '/content/upload',
                        '/queue/user/' + tokenHelper.parseJwt(token).id + '/tags/upload',
                        '/queue/user/' + tokenHelper.parseJwt(token).id + '/users/invite',
                        '/queue/user/' + tokenHelper.parseJwt(token).id + '/content/generate',
                        '/queue/user/' + tokenHelper.parseJwt(token).id + '/training/upload',
                        '/queue/user/' + tokenHelper.parseJwt(token).id + '/questions/export',
                    ]}
                    onMessage={msg => {
                        identifierToActionMapper[msg.identifier](msg);
                    }}
                    ref={sockJsRef}
                />
            )}
            {children}
        </>
    );
}
