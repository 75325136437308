import { StatusCode } from "redux/core/StatusCode";
import requestAgent from "../requestAgent";
import { handleError, handleSuccess } from "./utils";

export const getApiRequest = async (api) => {
    const responseData = await requestAgent.get(api, null, null);
    if (responseData.status <= StatusCode.NO_CONTENT) {
        return handleSuccess(responseData);
    } else {
        return handleError(responseData);
    }
}

export const postApiRequest = async (api, data) => {
    const responseData = await requestAgent.post(api, data, null, { 'Content-Type': 'application/json' });

    if (responseData.status <= StatusCode.NO_CONTENT) {
        return handleSuccess(responseData);
    } else {
        return handleError(responseData);
    }
}

export const deleteApiRequest = async (api) => {
    const responseData = await requestAgent.delete(api);

    if (responseData.status <= StatusCode.NO_CONTENT) {
        return handleSuccess(responseData);
    } else {
        return handleError(responseData);
    }
}