import PropTypes from 'prop-types';
import React from 'react';
import { gameMaxInputChars } from 'services/content-management/insertion-control/local-constants';
import InfoPopUpBox from 'components/features/content_management/InfoPopUpBox';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const InputWithInfoPopUpWrapper = ({ children, answer, question }) => {
    const singleQuestion = useSelector(state => state.content.singleQuestion);

    let { game } = useParams();

    game = game ?? singleQuestion.data.gameSlug;

    let stopInput = gameMaxInputChars[game].maxInputChars <= answer?.length;
    let warningInput = gameMaxInputChars[game].warningInputChars <= answer?.length;
    if (question) {
        stopInput = gameMaxInputChars[game].questionMaxInputChars <= question?.length;
        warningInput = gameMaxInputChars[game].questionWarningInputChars <= question?.length;
    }

    const onChange = (e, fn) => {
        if (question) {
            if (e.target.value.length <= gameMaxInputChars[game].questionMaxInputChars) {
                fn();
            }
            return;
        }
        if (e.target.value.length <= gameMaxInputChars[game].maxInputChars) {
            fn();
        }
    };

    return (
        <div className={'d-flex flex-row align-items-center'}>
            {children({ stopInput, onChange })}
            {!warningInput && !stopInput && (
                <div
                    style={{
                        height: 25,
                        width: 57,
                    }}
                ></div>
            )}
            {warningInput && !stopInput && (
                <InfoPopUpBox
                    type="warning"
                    description={
                        gameMaxInputChars[game].desc ||
                        'You have reached the optimal text length for this field, if you continue to type the text will be harder to read on the game interface.'
                    }
                ></InfoPopUpBox>
            )}
            {stopInput && (
                <InfoPopUpBox
                    type="error"
                    description={gameMaxInputChars[game].desc || 'You have reached the max text length for this field.'}
                ></InfoPopUpBox>
            )}
        </div>
    );
};

InputWithInfoPopUpWrapper.propTypes = {
    answer: PropTypes.any,
    children: PropTypes.any,
    question: PropTypes.any,
};
