import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

export default function TableEmptyState() {
    const {t} = useTranslation();
    
    return (
        <Body2 style={{ padding: '20px 0' }} color={colors.gray1}>
            {t('There are no records to display')}
        </Body2>
    );
}
