import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { asyncForEach } from 'services/utils/arrayHelpers';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import getCroppedImg from 'services/image_management/cropImage';
import { UploaderSpace } from 'components/features/content_management/cropping_module/UploaderSpace';
import { InfoSideBar } from 'components/features/content_management/cropping_module/InfoSideBar';
import { palette } from 'style/theme/theme-constants';
import { SaveImages } from 'components/features/content_management/cropping_module/SaveImages';
import { DefaultButton } from 'components/elements/Button';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { aspectRatios, views } from 'services/image_management/image-editor-management/local-constants';
import { H7 } from 'style/typography/Heading';
import { resizeFile, resizeFileAndReduceQuality } from 'services/image_management/imageHandlers';
import { EditorSpace } from 'components/features/content_management/cropping_module/EditorSpace';

import Close from 'assets/icons/filter-close.svg';
import Camera from 'assets/icons/camera-dark.svg';

import './Gallery.css';
import { useTranslation } from 'react-i18next';

export function UploadImageDialog({ modalOpen, setModalOpen, index }) {
    const { imageFiles, view, setImageFiles, imageLimit, setCurrentActivePreviewImage, currentActiveIndex } = useImageEditor();
    const [openSaveImages, setOpenSaveImages] = useState(false);

    const { t } = useTranslation();

    const onImagesUploadChange = async files => {
        if (files.length > 0) {
            let filesBlob = [];

            await asyncForEach(files, async file => {
                filesBlob.push({
                    url: URL.createObjectURL(file),
                    name: file.name,
                    lastModified: Date.now(),
                    size: file.size,
                    crop: {},
                    zoom: {},
                    croppedImage: {},
                    croppedAreaPixels: {},
                });
            });

            setImageFiles({ index: index, files: files, filesBlob: filesBlob });
        }
    };

    const saveImages = async () => {
        let croppedImgFiles = [];

        await asyncForEach(imageFiles.filesBlob, async (file, index) => {
            file.croppedImage = {};
            await asyncForEach(aspectRatios, async aspectRatio => {
                let croppedUrl = await getCroppedImg(file.url, file.croppedAreaPixels[aspectRatio]);
                file.croppedImage[aspectRatio] = await fetch(croppedUrl)
                    .then(r => r.blob())
                    .then(blobFile => {
                        // resizeFile(blobFile).then(resizedFile => {
                        return new File([blobFile], 'a_r_' + aspectRatio.replace('/', 'x') + '_' + file.name, { type: blobFile.type });
                        // })
                    });
            });
            let original = await fetch(file.url)
                .then(r => r.blob())
                .then(blobFile => {
                    return new File([blobFile], file.name, { type: blobFile.type });
                });
            file.croppedImage['original'] = original;
            file.croppedImage['id'] = file['id'];
            croppedImgFiles.push(file.croppedImage);
        });

        return croppedImgFiles;
    };

    useEffect(() => {
        setCurrentActivePreviewImage(imageFiles.filesBlob[currentActiveIndex]);
    }, [currentActiveIndex, imageFiles]);

    return (
        <div className="gallery--modal-background" style={{ display: modalOpen ? 'flex' : 'none' }}>
            <div className="gallery--modal-container">
                {view === views[1] && <EditorSpace />}
                {view !== views[1] && !openSaveImages && (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ paddingTop: 10, marginLeft: 30 }}>
                                <H7 className="my-2" color={palette.secondary.main} weight="medium">
                                    {t('Add image')}
                                </H7>
                            </div>
                            <img
                                alt="Close"
                                style={{ cursor: 'pointer', position: 'absolute', right: 20, top: 20 }}
                                src={Close}
                                height={20}
                                width={20}
                                onClick={() => {
                                    setModalOpen(false);
                                    setImageFiles({ index: index, files: [], filesBlob: [] });
                                }}
                            />
                        </div>

                        <div className="gallery--modal-body">
                            {imageFiles.filesBlob.length <= 0 && (
                                <FileUploader
                                    dropzoneText={t('Drag and drop the images here')}
                                    acceptedFiles={['.jpeg', '.jpg', '.png']}
                                    filesLimit={imageLimit ? imageLimit : 100}
                                    onChange={onImagesUploadChange}
                                    Icon={() => <img alt="Camera" src={Camera} height={40} width={40} />}
                                    dropzoneClass="content-insertion-image-dropzone"
                                    dropzoneParagraphClass="content-insertion-image-dropzone-paragraph"
                                ></FileUploader>
                            )}
                            {imageFiles.filesBlob.length > 0 && (
                                <div className="gallery--modal-has-files">
                                    <UploaderSpace />
                                    {(view === views[0] || view === views[1]) && (
                                        <div className="dialog--save-button-container">
                                            <DefaultButton
                                                style={{ position: 'absolute', left: 0, bottom: 0, margin: 20 }}
                                                onClick={() => {
                                                    setOpenSaveImages(true);
                                                }}
                                            >
                                                {t('Next')}
                                            </DefaultButton>
                                        </div>
                                    )}
                                </div>
                            )}

                            {imageFiles.filesBlob.length > 0 && <InfoSideBar />}
                        </div>
                    </>
                )}
                {openSaveImages && (
                    <SaveImages setOpenSaveImages={setOpenSaveImages} setModalOpen={setModalOpen} saveImages={saveImages} index={index} />
                )}
            </div>
        </div>
    );
}

UploadImageDialog.propTypes = {
    index: PropTypes.any,
    modalOpen: PropTypes.any,
    setModalOpen: PropTypes.func,
};
