import React, { useEffect, useState } from 'react';
import { colors } from 'style/colors';
import { TopicsFolderStructure } from './local_components/topics_folder_structure/TopicsFolderStructure';
import { useDispatch, useSelector } from 'react-redux';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { getQuestionsUnusedInCompanyAction, resetUpdateMultipleQuestionsAction } from 'redux/actions/content.actions';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { EmptyState } from './local_components/topics_folder_structure/empty_state/EmptyState';
import { toast } from 'react-hot-toast';

import { Circular } from 'components/features/data_table/Circular';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { Body2 } from 'style/typography/Body';

import IconNewFolder from 'assets/icons/icon-new-folder.svg';
import IconNewTopic from 'assets/icons/icon-new-topic.svg';

import './Topics.css';

import {
    addChildToTopicsTree,
    getAllTopicsFromCompanyAction,
    getQuestionsUnusedInTopicAction,
    getQuestionsUsedInTopicAction,
    getTopicContentAction,
    getTopicsFolderStructureAction,
    resetFolderStructureChanges,
    resetTopicChanges,
    resetUpdateLocalTopicChanges,
    resetUpdateMultipleTopicsAction,
    resetUpdateTopicsFolderStructureAction,
    saveTopicLocalChanges,
    setTopicsTree,
    updateQuestions,
} from 'redux/actions/topics.actions';
import { Prompt, useLocation } from 'react-router-dom';
import { SideMenuTopics } from './local_components/side_menu/SideMenuTopics';
import { StatusCode } from 'redux/core/StatusCode';
import ConflictModal from 'components/features/modal/ConflictModal';
import { useTranslation } from 'react-i18next';

export function Topics({}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    const topicsFolderStructureState = useSelector(state => state.content.topics.topicsFolderStructure);
    const allTopicsFromCompanyState = useSelector(state => state.content.topics.allTopicsFromCompany);
    const updateTopicsFolderStructureState = useSelector(state => state.content.topics.updateTopicsFolderStructure);
    const updateMultipleQuestionsTopicState = useSelector(state => state.content.topics.updatMultipleQuestionsTopics);
    const updateMultipleTopicsState = useSelector(state => state.content.topics.updateMultipleTopics);
    const hasTopicsFolderStructureLocalChangesState = useSelector(state => state.content.topics.hasTopicsFolderStructureLocalChanges);
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);
    const topicsTreeState = useSelector(state => state.content.topics.topicsTree);

    const [topicsFolderStructure, setTopicsFolderStructure] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [currentNode, setCurrentNode] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [saveInitiated, setSaveInitiated] = useState({ initiated: false, force: false });
    const [editingTitle, setEditingTitle] = useState(false);
    const [newTopicTitle, setNewTopicTitle] = useState('');
    const [hasConflict, setHasConflict] = useState(false);
    const [resourceCurrentState, setResourceCurrentState] = useState(null);

    useEffect(() => {
        if (location?.search) {
            const query = new URLSearchParams(location.search);

            let topicId = query.get('topicId');
            if (topicId) {
                let node = allTopicsFromCompanyState?.data?.find(el => topicId == el.treeIndex);

                if (node && !node.generatedTreeIndex) {
                    setCurrentNode(node);
                    dispatch(getTopicContentAction(topicId));
                } else {
                    node = localTopicChangesState?.data[topicId];
                    setCurrentNode(node?.treeData);
                }
            }
        }
    }, [allTopicsFromCompanyState.data]);

    useEffect(() => {
        if (stateIsLoaded(updateMultipleQuestionsTopicState)) {
            dispatch(resetUpdateMultipleQuestionsAction());
            dispatch(resetUpdateLocalTopicChanges());
        }
    }, [dispatch, updateMultipleQuestionsTopicState]);

    useEffect(() => {
        if (stateIsLoaded(updateTopicsFolderStructureState) && saveInitiated.initiated === true) {
            if (stateIsLoaded(localTopicChangesState)) {
                dispatch(saveTopicLocalChanges(saveInitiated.force));
            } else {
                toast.success(t('Successfully saved!'));
                dispatch(resetUpdateTopicsFolderStructureAction());
                setSaveInitiated({ initiated: false, force: false });
            }
        } else if (stateHasFailed(updateTopicsFolderStructureState)) {
            toast.error(t('Changes have not been saved!'));
            setSaveInitiated({ initiated: false, force: false });
        }
    }, [dispatch, saveInitiated, updateTopicsFolderStructureState]);

    useEffect(() => {
        if (stateIsLoaded(updateMultipleTopicsState)) {
            toast.success(t('Successfully saved!'));
            dispatch(updateQuestions());
            dispatch(resetUpdateTopicsFolderStructureAction());
            dispatch(resetUpdateMultipleTopicsAction());
            dispatch(resetUpdateLocalTopicChanges());
            setCurrentNode(null);
            setSaveInitiated({ initiated: false, force: false });
        } else if (stateHasFailed(updateMultipleTopicsState)) {
            if (updateMultipleTopicsState.responseStatus === StatusCode.CONFLICT) {
                setResourceCurrentState(updateMultipleTopicsState.data);
                setHasConflict(true);
            }
            toast.error(t('Changes have not been saved!'));
            dispatch(resetUpdateTopicsFolderStructureAction());
            dispatch(resetUpdateMultipleTopicsAction());
            setSaveInitiated({ initiated: false, force: false });
        }
    }, [dispatch, updateMultipleTopicsState]);

    const resetAllChanges = () => {
        dispatch(resetTopicChanges());
        dispatch(resetFolderStructureChanges());
        setCurrentNode(null);
    };

    function setNewName(newName) {
        setCurrentNode({
            ...currentNode,
            title: newName,
        });
    }

    useEffect(() => {
        if (
            stateIsLoaded(allTopicsFromCompanyState) &&
            stateIsLoaded(topicsFolderStructureState) &&
            !stateIsLoading(updateTopicsFolderStructureState)
        ) {
            dispatch(setTopicsTree(null, searchValue));
        }
    }, [dispatch, topicsFolderStructureState, allTopicsFromCompanyState, searchValue]);

    useEffect(() => {
        if (currentNode && !currentNode.generatedTreeIndex) {
            dispatch(getTopicContentAction(currentNode.treeIndex));
            dispatch(getQuestionsUsedInTopicAction(currentNode.treeIndex, { page: 0, size: 10, searchValue: '' }));
            dispatch(getQuestionsUnusedInTopicAction(currentNode.treeIndex, { page: 0, size: 10, searchValue: '' }));
            dispatch(getQuestionsUnusedInCompanyAction(currentNode.treeIndex, { page: 0, size: 10, searchValue: '' }));
        }
    }, [dispatch, currentNode]);

    useEffect(() => {
        dispatch(getTopicsFolderStructureAction());
        dispatch(getAllTopicsFromCompanyAction());

        return () => {
            dispatch(resetUpdateLocalTopicChanges());
            dispatch(resetTopicChanges());
            dispatch(resetFolderStructureChanges());
        };
    }, []);

    if (
        stateIsLoaded(allTopicsFromCompanyState) &&
        !searchValue &&
        allTopicsFromCompanyState.data?.length > 0 &&
        topicsTreeState?.data?.length === 0
    ) {
        return (
            <div style={{ height: '100vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                <Circular />
            </div>
        );
    }

    if (stateIsLoaded(allTopicsFromCompanyState) && allTopicsFromCompanyState.data?.length === 0 && topicsFolderStructure.length === 0) {
        return <EmptyState topic={newTopicTitle} setCurrentNode={setCurrentNode} setTopic={setNewTopicTitle}></EmptyState>;
    }

    return (
        <div style={{ height: '90vh' }} className="d-flex flex-row">
            <Prompt
                when={stateIsLoaded(localTopicChangesState) || hasTopicsFolderStructureLocalChangesState?.data === true}
                message={location => {
                    return location.pathname.includes('/topics') ? true : t(`You have unsaved changes, are you sure you want to leave?`);
                }}
            />
            {hasConflict && (
                <ConflictModal
                    modalOpen={hasConflict}
                    setModalOpen={setHasConflict}
                    oldData={localTopicChangesState.data}
                    newData={resourceCurrentState}
                    forceSave={() => {
                        setSaveInitiated({ initiated: true, force: true });
                    }}
                />
            )}
            <div style={{ height: '100%', backgroundColor: colors.gray3 }} className="col-6 flex-column">
                <div style={{ width: '40vw' }} className="d-flex flex-row p-4 ml-3">
                    <CustomTextField
                        isSearch={true}
                        id="search-input-in-topics"
                        value={searchValue}
                        setValue={setSearchValue}
                        placeholder={t('Search for topics...')}
                    ></CustomTextField>
                    <div className="dropleft ml-auto">
                        <div>
                            <DefaultButton
                                id="add-new-folder-topic"
                                disabled={saveInitiated.initiated === true}
                                className={'pl-2 pr-2'}
                                onClick={() => dispatch(addChildToTopicsTree('New folder', false, setCurrentNode))}
                                backgroundColor={colors.white}
                                textColor={colors.gray6}
                            >
                                <img alt="New folder" src={IconNewFolder} height={25} width={25} />
                                <Body2 className={'ml-2'} color={colors.gray6}>
                                    {t('New folder')}
                                </Body2>
                            </DefaultButton>
                            <DefaultButton
                                id="add-new-topic"
                                disabled={saveInitiated.initiated === true}
                                text="New topic"
                                className={'pl-2 pr-2 ml-2'}
                                backgroundColor={colors.white}
                                textColor={colors.gray6}
                                onClick={() => dispatch(addChildToTopicsTree('New Topic', true, setCurrentNode))}
                            >
                                <img alt="New topic" src={IconNewTopic} height={25} width={25} />
                                <Body2 className={'ml-2'} color={colors.gray6}>
                                    {t('New topic')}
                                </Body2>
                            </DefaultButton>
                        </div>
                    </div>
                </div>

                <TopicsFolderStructure
                    currentNode={currentNode}
                    setCurrentNode={setCurrentNode}
                    data={topicsTreeState?.data}
                    saveInitiated={saveInitiated}
                    setSaveInitiated={setSaveInitiated}
                    search={''}
                    emptyTree={topicsTreeState?.data?.length < 2}
                />

                <div className="d-flex flex-row align-items-center mt-5 ml-5">
                    <DefaultButton
                        id="topic-save-button"
                        disabled={
                            (!stateIsLoaded(localTopicChangesState) && hasTopicsFolderStructureLocalChangesState?.data !== true) ||
                            saveInitiated.initiated === true
                        }
                        text={t('Save')}
                        textColor={colors.white}
                        className={'pl-4 pr-4'}
                        onClick={() => setSaveInitiated({ initiated: true, force: false })}
                    />
                    <OutlinedButton
                        id="topic-cancel-button"
                        disabled={
                            (!stateIsLoaded(localTopicChangesState) && hasTopicsFolderStructureLocalChangesState?.data !== true) ||
                            saveInitiated.initiated === true
                        }
                        text={t('Cancel')}
                        className={'pl-4 pr-4 ml-2'}
                        onClick={resetAllChanges}
                    />
                    {saveInitiated.initiated === true && <CircularProgress className={'ml-2'} style={{ color: colors.mainTheme }} size={25} />}
                    {(stateIsLoaded(localTopicChangesState) || hasTopicsFolderStructureLocalChangesState?.data === true) && (
                        <Body2 className="ml-2" color={colors.gray6}>
                            {t('Changes not saved')}
                        </Body2>
                    )}
                </div>
            </div>
            <SideMenuTopics
                allQuestions={allQuestions}
                setAllQuestions={setAllQuestions}
                node={currentNode}
                setSelectedNode={setCurrentNode}
                editingTitle={editingTitle}
                setEditingTitle={setEditingTitle}
                setNewName={setNewName}
            />
        </div>
    );
}
