import React, { useEffect, useState } from 'react';

import { Header } from '../../local_components/header/Header';
import DashboardStatisticContainer from './local_components/DashboardStatisticContainer';
import { infoStrings } from 'static/info_strings';
import UsersStatisticsDashboard from './local_components/statistics_components/UsersStatisticsDashboard';
import { dashboardStatisticsAction, resetDashboardStatisticsAction } from 'redux/actions/content.actions';
import { useDispatch, useSelector } from 'react-redux';
import GamesStatisticsDashboard from './local_components/statistics_components/GamesStatisticsDashboard';
import GameplaysStatisticsDashboard from './local_components/statistics_components/GameplaysStatisticsDashboard';
import QuestionsStatisticsDashboard from './local_components/statistics_components/QuestionsStatisticsDashboard';
import TrainingStatisticsDashboard from './local_components/statistics_components/TrainingStatisticsDashboard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

import './Dashboard.css';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { LoadingAnimation } from 'components/features/lottie/LoadingAnimation';

export default function Dashboard() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const dashboardStatsState = useSelector(state => state.content.dashboardStats);

    // useEffect(() => {
    //     dispatch(dashboardStatisticsAction());
    //
    //     return () => dispatch(resetDashboardStatisticsAction());
    // }, []);

    const [hovering, setHovering] = useState('');
    return (
        <Header title={'Dashboard'} stateDependencies={[dashboardStatsState]}>
            {!stateIsLoaded(dashboardStatsState) && <LoadingAnimation />}
            {stateIsLoaded(dashboardStatsState) && (
                <div className="dashboard--container">
                    <div className="column">
                        <DashboardStatisticContainer
                            title={t('Users')}
                            infoString={infoStrings.users}
                            setHovering={setHovering}
                            hovering={hovering}
                            child={<UsersStatisticsDashboard />}
                            onSeeMoreClick={() => {
                                history.push('/users/organization');
                            }}
                        />
                        <DashboardStatisticContainer
                            title={t('Questions')}
                            infoString={infoStrings.questions}
                            setHovering={setHovering}
                            hovering={hovering}
                            child={<QuestionsStatisticsDashboard />}
                            onSeeMoreClick={() => {
                                history.push('/content/questions');
                            }}
                        />
                    </div>
                    <div className="column">
                        <DashboardStatisticContainer
                            title={t('Games')}
                            infoString={infoStrings.games}
                            setHovering={setHovering}
                            hovering={hovering}
                            child={<GamesStatisticsDashboard />}
                        />
                        <DashboardStatisticContainer
                            title={t('Trainings')}
                            infoString={infoStrings.trainings}
                            setHovering={setHovering}
                            hovering={hovering}
                            maxHeight="300px"
                            child={<TrainingStatisticsDashboard />}
                            onSeeMoreClick={() => {
                                history.push('/content/trainings');
                            }}
                        />
                    </div>
                    <div className="column">
                        <DashboardStatisticContainer
                            title={t('Gameplays')}
                            maxHeight="75vh"
                            infoString={infoStrings.gameplays}
                            setHovering={setHovering}
                            hovering={hovering}
                            child={<GameplaysStatisticsDashboard />}
                        />
                    </div>
                </div>
            )}
        </Header>
    );
}
