import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'style/colors';
import {
    activateUserAction,
    deactivateUserAction,
    editUserAction,
    getSingleUserAction,
    resetActivateUserAction,
    resetChangePasswordForUserAction,
    resetDeactivateUserAction,
    resetEditUserAction,
} from 'redux/actions/users.actions';
import { useDispatch, useSelector } from 'react-redux';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { useHistory } from 'react-router-dom';
import { authService } from 'services/auth/auth';
import { toast } from 'react-hot-toast';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { Body1 } from 'style/typography/Body';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { resetAddUsersToTagsAction, resetRemoveUserFromTagAction } from 'redux/actions/tags.actions';

import { defaultUserDataEditing } from 'static/user_constants';
import { activationModalData, deactivationModalData } from './profileSettingsModalData';
import ProfileTagsSetting from './fields/ProfileTagsSetting';
import ProfileSelectionSetting from './fields/ProfileSelectionSetting';
import ProfilePasswordSetting from './fields/ProfilePasswordSetting';
import { languagesApp } from 'static/language';
import { useTranslation } from 'react-i18next';
import ProfileResendInviteSetting from './fields/ProfileResendInviteSetting';

import _ from 'lodash';

export function ProfileSettingsWrapper({ id, userData, dispatchUserData, userRoles, accessLevels, tags }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const editUserState = useSelector(state => state.userManagement.editUser);
    const deactivateUserState = useSelector(state => state.userManagement.deactivateUser);
    const activateUserState = useSelector(state => state.userManagement.activateUser);
    const changePasswordState = useSelector(state => state.userManagement.changePassword);
    const removeUserFromTagState = useSelector(state => state.content.removeUserFromTag);

    const [isTagModalOpen, setIsTagModalOpen] = useState(false);
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const addUsersToTagsState = useSelector(state => state.content.addUsersToTags);
    const [activateModalOpen, setActivateModalOpen] = useState(false);

    const [editedUserData, dispatchEditedUser] = useReducer(
        (state, action) => {
            return { ...state, ...action };
        },
        { ...defaultUserDataEditing, name: _.cloneDeep(userData.name), lastName: _.cloneDeep(userData.lastName) }
    );

    const hasChanges =
        editedUserData.companyRole ||
        editedUserData.department ||
        editedUserData.activeLanguage ||
        editedUserData.authRole.length > 0 ||
        editedUserData.name?.length > 0 ||
        editedUserData.lastName?.length > 0;

    const onSave = () => {
        if (hasChanges) {
            dispatch(resetEditUserAction());

            const userProfile = {
                name: editedUserData.name?.length > 0 ? editedUserData.name : userData.name,
                lastName: editedUserData.lastName?.length > 0 ? editedUserData.lastName : userData.lastName,
                authRole: editedUserData.authRole.length > 0 ? editedUserData.authRole : userData.authRole,
                department: editedUserData.department != null ? editedUserData.department : userData.department,
                activeLanguage: editedUserData.activeLanguage != null ? editedUserData.activeLanguage : userData.activeLanguage,
                companyRole: {
                    roleId: editedUserData.companyRole.roleId ? parseInt(editedUserData.companyRole.roleId) : parseInt(userData.companyRole?.roleId),
                },
            };
            dispatch(editUserAction(userProfile, id));
        }
    };

    function deactivateUser() {
        dispatch(deactivateUserAction(id));
    }

    function activateUser() {
        dispatch(activateUserAction(id));
    }

    const onCancel = () => {
        history.goBack();
    };

    useEffect(() => {
        if (stateIsLoaded(addUsersToTagsState) || stateIsLoaded(removeUserFromTagState)) {
            dispatch(resetAddUsersToTagsAction());
            dispatch(resetRemoveUserFromTagAction());
            setIsTagModalOpen(false);
            dispatch(getSingleUserAction(id));
            toast.success(t('Successfully saved tags!'));
        }
    }, [addUsersToTagsState, removeUserFromTagState]);

    useEffect(() => {
        if (stateIsLoaded(changePasswordState) && changePasswordState?.data?.isSuccessful === true) {
            toast.success(t('Password successfully changed!'));
            dispatch(resetChangePasswordForUserAction());
        } else if (stateIsLoaded(changePasswordState) && changePasswordState?.data?.isSuccessful === false) {
            toast.error(t(changePasswordState.data.message));
            dispatch(resetChangePasswordForUserAction());
        } else if (stateHasFailed(changePasswordState)) {
            toast.error(t(changePasswordState.errors.message));
            dispatch(resetChangePasswordForUserAction());
        }
    }, [changePasswordState, dispatch, history]);

    useEffect(() => {
        if (stateIsLoaded(editUserState)) {
            toast.success(t('Successfully saved!'));
            dispatch(resetEditUserAction());
            dispatch(getSingleUserAction(id));
        } else if (stateHasFailed(editUserState)) {
            toast.error(t('Changes have not been saved!'));
            dispatch(resetEditUserAction());
        }
    }, [editUserState]);

    useEffect(() => {
        if (stateIsLoaded(deactivateUserState)) {
            dispatchUserData({ status: 'Inactive', authRole: '' });
            setDeactivateModalOpen(false);
            toast.success(t('Successfully deactivated!'));
            dispatch(resetDeactivateUserAction());
        } else if (stateHasFailed(deactivateUserState)) {
            setDeactivateModalOpen(false);
            toast.error(t('Deactivation has not been successful!'));
            dispatch(resetDeactivateUserAction());
        }
    }, [deactivateUserState]);

    useEffect(() => {
        if (stateIsLoaded(activateUserState)) {
            dispatchUserData({ status: 'Active', authRole: 'ROLE_USER' });
            setActivateModalOpen(false);
            toast.success(t('Successfully activated!'));
            dispatch(resetActivateUserAction());
        } else if (stateHasFailed(activateUserState)) {
            setActivateModalOpen(false);
            toast.error(t('Activation has not been successful!'));
            dispatch(resetActivateUserAction());
        }
    }, [activateUserState, deactivateUserState]);

    return (
        <div className="col-12 mt-5">
            <ModalDialog
                title={t(deactivationModalData.title)}
                modalOpen={deactivateModalOpen}
                setModalOpen={setDeactivateModalOpen}
                onAction={() => deactivateUser()}
                alert={deactivationModalData.alert}
                buttons={deactivationModalData.buttons}
                text={t(deactivationModalData.text, { user: userData.name })}
                hasCancel={true}
            ></ModalDialog>
            <ModalDialog
                title={activationModalData.title}
                modalOpen={activateModalOpen}
                setModalOpen={setActivateModalOpen}
                onAction={() => activateUser()}
                buttons={activationModalData.buttons}
                text={t(activationModalData.text, { user: userData.name })}
                hasCancel={true}
            ></ModalDialog>
            <div
                style={{
                    width: '40%',
                    maxWidth: '40%',
                    minWidth: '40%',
                    marginLeft: '30%',
                }}
            >
                <div className={'d-flex flex-row py-4 w-100'} style={{ borderBottom: `solid 1px ${colors.disabledColor}` }}>
                    <div className="d-flex flex-column w-100">
                        <Body1 className="mb-2">{t('First Name')}</Body1>
                        <CustomTextField
                            width={'100%'}
                            id="first-name-input"
                            value={editedUserData.name}
                            setValue={val => dispatchEditedUser({ name: val })}
                            font={16}
                            visibilityIcon={false}
                        />
                    </div>
                    <div className="d-flex flex-column ml-2 w-100">
                        <Body1 className="mb-2">{t('Last Name')}</Body1>
                        <CustomTextField
                            width={'100%'}
                            id="second-name-input"
                            value={editedUserData.lastName}
                            setValue={val => dispatchEditedUser({ lastName: val })}
                            font={16}
                            visibilityIcon={false}
                        />
                    </div>
                </div>
                <ProfilePasswordSetting id={id} editedUserData={editedUserData} dispatchEditedUser={dispatchEditedUser} />
                <div className="d-flex flex-row align-items-center py-4" style={{ borderBottom: `solid 1px ${colors.disabledColor}` }}>
                    <Body1 className={'mb-0'}>{t('User Status')}</Body1>
                    <div className="ml-auto mr-4 d-flex flex-row align-items-center">
                        <FontAwesomeIcon
                            className={'ml-auto mr-1'}
                            color={userData.isActive === 'Active' ? colors.mainTheme : colors.gray1}
                            icon={faCircle}
                            size={'xs'}
                        ></FontAwesomeIcon>
                        <Body1 className={'font-weight-bold mb-0'}>{t(userData.isActive)}</Body1>
                    </div>
                    {authService.user.id != id && (
                        <OutlinedButton
                            text={userData.isActive === 'Active' ? t('Deactivate') : t('Activate')}
                            onClick={() => (userData.isActive === 'Active' ? setDeactivateModalOpen(true) : setActivateModalOpen(true))}
                        />
                    )}
                </div>
                <ProfileSelectionSetting
                    title={t('User Role')}
                    id="user-role-dropdown"
                    onChange={option =>
                        dispatchEditedUser({
                            companyRole: option
                                ? {
                                      roleId: option.value,
                                      roleName: option.label,
                                  }
                                : '',
                        })
                    }
                    defaultValue={{
                        value: userData.companyRole?.roleId,
                        label: userData.companyRole?.roleName,
                    }}
                    options={userRoles}
                />
                <ProfileSelectionSetting
                    title={t('User language')}
                    id="user-role-dropdown"
                    onChange={option => dispatchEditedUser({ activeLanguage: option ? option.value : '' })}
                    defaultValue={languagesApp.find(l => l.value === userData.activeLanguage)}
                    options={languagesApp}
                    isClearable={false}
                />
                {authService.user.id != id && (
                    <ProfileSelectionSetting
                        title={t('Access Level')}
                        id="access-level-dropdown"
                        onChange={option => dispatchEditedUser({ authRole: option ? option.value : '' })}
                        defaultValue={accessLevels.find(el => el.value == userData.authRole)}
                        options={accessLevels}
                    />
                )}

                <ProfileTagsSetting isTagModalOpen={isTagModalOpen} setIsTagModalOpen={setIsTagModalOpen} tags={tags} id={id} />
                {userData.status === 'pending' && <ProfileResendInviteSetting />}

                <div className={'d-flex flex-row mt-1 pt-5 align-items-center'}>
                    <DefaultButton
                        id="update-profile-button"
                        onClick={onSave}
                        text={t('Update Profile')}
                        textColor={colors.white}
                        disabled={stateIsLoading(editUserState)}
                    />
                    <OutlinedButton
                        id="cancel-profile-button"
                        onClick={onCancel}
                        text={t('Cancel')}
                        textColor={colors.dark}
                        disabled={stateIsLoading(editUserState)}
                        className={'ml-3'}
                    />
                    {stateIsLoading(editUserState) && <CircularProgress className={'ml-2'} style={{ color: colors.mainTheme }} size={25} />}
                </div>
            </div>
        </div>
    );
}

ProfileSettingsWrapper.propTypes = {
    accessLevels: PropTypes.any,
    dispatchUserData: PropTypes.func,
    id: PropTypes.any,
    tags: PropTypes.any,
    userData: PropTypes.any,
    userRoles: PropTypes.any,
};
