import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';
import { Body2 } from 'style/typography/Body';

import Folder from 'assets/icons/topic-icon-folder.svg';
import File from 'assets/icons/topic-icon-paper.svg';

import './TopicDropdownSelector.css';

export default function TopicDropdownSelectorItem({ item, onChange, setIsOpen, value }) {
    const [itemOpen, setItemOpen] = useState(false);

    return (
        <div>
            <div
                className="topic-dropdown-selector--item"
                onClick={e => {
                    if (!item.topic) {
                        setItemOpen(prevState => !prevState);
                    } else {
                        onChange(item.treeIndex);
                        setIsOpen(false);
                    }
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                    <img alt="File" src={!item.topic ? Folder : File} height={18} width={18}></img>
                    <Body2 weight={item.topic ? 'regular' : 'medium'} color={value.value == item.treeIndex ? colors.mainTheme : colors.primaryText}>
                        {item.title}
                    </Body2>
                </div>
                {item.children?.length > 0 && (
                    <img alt="Arrow" className={'ml-2 cursor-pointer'} height={15} width={15} src={itemOpen ? AngleUp : AngleDown} />
                )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {itemOpen &&
                    item.children?.length > 0 &&
                    item.children?.map(el => {
                        return (
                            <div style={{ paddingLeft: '10px' }}>
                                <TopicDropdownSelectorItem item={el} onChange={onChange} setIsOpen={setIsOpen} value={value} />
                            </div>
                        );
                    })}
                {itemOpen && item.children?.length == 0 && (
                    <div style={{ padding: '5px 20px' }}>
                        <Caption1 color={colors.gray6}>No topics here</Caption1>
                    </div>
                )}
            </div>
        </div>
    );
}

TopicDropdownSelectorItem.propTypes = {
    item: PropTypes.any,
    onChange: PropTypes.func,
    setIsOpen: PropTypes.func,
    value: PropTypes.any,
};
