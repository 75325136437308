import {
    GET_ALL_TOPICS_FROM_COMPANY_API,
    GET_QUESTIONS_PAGINATED_API,
    GET_SINGLE_QUESTION_API,
    INSERT_QUESTION_API,
    GET_FOLDER_STRUCTURE_API,
    SAVE_FOLDERS_API,
    UPLOAD_IMAGE,
    UPDATE_TOPIC_API,
    SAVE_IMAGE_TO_FOLDER_API,
    GET_ALL_QUESTIONS_FOR_CATEGORY_API,
    SAVE_ASSET_API,
    SAVE_NEWS_API,
    GET_IMAGES_FROM_FOLDER_API,
    UPDATE_FEEDBACK_API,
    DELETE_FEEDBACK_API,
    GET_SPECIFIC_FEEDBACK_API,
    GET_NEWS_API,
    GET_SPECIFIC_NEWS_API,
    DELETE_NEWS_API,
    GLOBAL_STATISTICS_API,
    TRAINING_STATISTICS_API,
    GET_GAMES_API,
    UPLOAD_CONTENT_API,
    TRAININGS_INFO_API,
    SINGLE_TRAINING_API,
    CREATE_NEW_TRAINING_API,
    UPDATE_TOPICS_TREE_API,
    UPDATE_TRAINING_SETTINGS_API,
    GET_TOPICS_FOLDER_STRUCTURE_API,
    UPDATE_MULTIPLE_TOPICS_API,
    UPDATE_TOPICS_FOLDER_STRUCTURE_API,
    GET_ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO_API,
    GET_HTML_API,
    UPDATE_TOPIC_SETTINGS_API,
    COMPANY_UNIT_STRUCTURE_API,
    GET_COMPANY_INFO_API,
    UPDATE_COMPANY_GLOBAL_CONFIG_API,
    ALL_TRAININGS_API,
    GET_GAMES_QUESTION_TYPE_VARIATIONS_API,
    DELETE_IMAGE_FOLDER_API,
    GET_TOPIC_CONTENT_API,
    QUESTIONS_USED_IN_TOPIC_API,
    QUESTIONS_UNUSED_IN_TOPIC_API,
    GET_NEWS_PAGINATED_API,
    GET_FEEDBACK_CONTENT_PAGINATED_API,
    GET_FEEDBACK_POSITIVE_PAGINATED_API,
    QUESTIONS_UNUSED_IN_COMPANY_API,
    UPDATE_MULTIPLE_QUESTIONS_TOPICS_API,
    UPDATE_IMAGE_API,
    GET_ALL_IMAGES_API,
    GET_UNCATEGORIZED_IMAGES_API,
    UPDATE_QUESTIONS_BULK_API,
    SINGLE_TOPIC_STATS_API,
    UPDATE_LABELS_API,
    GET_TAGS_API,
    GET_LABELS_API,
    CREATE_TAG_API,
    GET_LABEL_BY_ID_API,
    DELETE_LABEL_BY_ID_API,
    GET_USERS_WITH_TAG_API,
    GET_USERS_WITHOUT_TAG_WITH_ID_API,
    ADD_USER_TO_TAG_API,
    REMOVE_USER_FROM_TAG_API,
    ADD_USERS_TO_TAGS_API,
    ALL_NOTIFICATIONS_PAGINATED_API,
    GET_SINGLE_NOTIFICATION_API,
    POST_NEW_NOTIFICATION_API,
    SAVE_CONTENT_BLOCK_API,
    GET_CONTENT_BLOCKS_PAGINATED_API,
    GET_CONTENT_BLOCKS_BY_ID_API,
    DELETE_CONTENT_BLOCKS_BY_ID_API,
    RESOLVE_FEEDBACK_BULK_API,
    UNRESOLVE_FEEDBACK_BULK_API,
    DOWNLOAD_USER_TAGS_TEMPLATE_API,
    UPLOAD_USER_TAGS_API,
    DELETE_FEEDBACK_BULK_API,
    MOVE_IMAGES_TO_FOLDER_API,
    SAVE_TRAINING_MODULE_LEARNING_SET_API,
    GET_ALL_TRAINING_MODULES_COMPANY_API,
    GET_TRAINING_MODULE_COMPANY_API,
    DELETE_TRAINING_MODULE_API,
    DELETE_TAG_BY_ID_API,
    IMAGES_DELETE_SOFT_API,
    IMAGES_DELETE_FORCE_API,
    GENERATE_CONTENT_API,
    GENERATE_CONTENT_FOR_TOPIC_API,
    UPLOAD_TRAINING_API,
    DELETE_TRAINING_API,
    CREATE_COMPANY_WITH_CODE_API,
    REMOVE_USERS_FROM_TAGS_API,
    DELETE_NOTIFICATION_API,
    GET_LATEST_UPDATES_API,
    SET_LATEST_UPDATES_SEEN_API,
    GET_ALL_GAMES_API,
    PRE_BULK_ACTION_QUESTIONS_API,
    PRE_BULK_ACTION_FEEDBACKS_API,
    GET_IMAGES_COUNT_API,
    GET_QUESTIONS_INDEXES_API,
} from '../api_links';
import { DASHBOARD_STATISTICS_API } from 'services/api/api_links';
import { deleteApiRequest, getApiRequest, postApiRequest } from './requests';
import { linkBuilderFromQuery } from '../api_builders';

import { tokenHelper } from 'services/auth/tokenHelpers';

export const getAllTopicsFromCompanyApiRequest = async () => {
    return getApiRequest(GET_ALL_TOPICS_FROM_COMPANY_API);
};

export const updateTopicsTreeApiRequest = async treeData => {
    return postApiRequest(UPDATE_TOPICS_TREE_API, treeData);
};

export const updateTrainingSettingsApiRequest = async data => {
    return postApiRequest(UPDATE_TRAINING_SETTINGS_API(data.setId), data);
};

export const createNewTrainingApiRequest = async data => {
    return postApiRequest(CREATE_NEW_TRAINING_API, data);
};

export const companyUnitStructureApiRequest = async () => {
    return getApiRequest(COMPANY_UNIT_STRUCTURE_API);
};

export const updateSingleTopicApiRequest = async (data, id) => {
    return postApiRequest(UPDATE_TOPIC_API(id), data);
};

export const updateMultipleTopicsApiRequest = async data => {
    return postApiRequest(UPDATE_MULTIPLE_TOPICS_API, data);
};

export const getQuestionsPaginatedApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_QUESTIONS_PAGINATED_API, queryData);
    return getApiRequest(api);
};

export const getQuestionsIndexesApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_QUESTIONS_INDEXES_API, queryData);
    return getApiRequest(api);
};

export const getContentBlocksPaginatedApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_CONTENT_BLOCKS_PAGINATED_API, queryData);
    return getApiRequest(api);
};

export const getContentBlockByIdApiRequest = async id => {
    return getApiRequest(GET_CONTENT_BLOCKS_BY_ID_API(id));
};

export const updateMultipleQuestionsTopicsApiRequest = async data => {
    return postApiRequest(UPDATE_MULTIPLE_QUESTIONS_TOPICS_API, data);
};

export const getQuestionByIdApiRequest = async id => {
    return getApiRequest(GET_SINGLE_QUESTION_API(id));
};

export const getFolderStructureApiRequest = async () => {
    return getApiRequest(GET_FOLDER_STRUCTURE_API);
};

export const getImagesFromFolderApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_IMAGES_FROM_FOLDER_API(), queryData);
    return getApiRequest(api);
};

export const getAllImagesApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_ALL_IMAGES_API(), queryData);
    return getApiRequest(api);
};

export const getImagesCountApiRequest = async () => {
    return getApiRequest(GET_IMAGES_COUNT_API());
};
export const getUncategorizedImagesApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_UNCATEGORIZED_IMAGES_API(), queryData);
    return getApiRequest(api);
};

export const getAllQuestionsFromCategoryApiRequest = async categoryId => {
    return getApiRequest(GET_ALL_QUESTIONS_FOR_CATEGORY_API(categoryId));
};

export const insertQuestionApiRequest = async data => {
    return postApiRequest(INSERT_QUESTION_API, data);
};

export const deleteFolderApiRequest = async id => {
    return deleteApiRequest(DELETE_IMAGE_FOLDER_API(id));
};

export const moveImagesToFolderApiRequest = async (imageIds, folderId) => {
    return postApiRequest(MOVE_IMAGES_TO_FOLDER_API(folderId), imageIds);
};

export const saveFoldersApiRequest = async treeData => {
    return postApiRequest(SAVE_FOLDERS_API, treeData);
};

export const editQuestionsBulkApiRequest = async (action, questionIds, data) => {
    return postApiRequest(UPDATE_QUESTIONS_BULK_API(action, questionIds), data);
};

export const preBulkActionQuestionsApiRequest = async (questionIds, data) => {
    return postApiRequest(PRE_BULK_ACTION_QUESTIONS_API(questionIds), data);
};

export const uploadImageApiRequest = async data => {
    return postApiRequest(UPLOAD_IMAGE, data);
};

export const saveImageToFolderApiRequest = async data => {
    return postApiRequest(SAVE_IMAGE_TO_FOLDER_API, data);
};

export const updateImageApiRequest = async data => {
    return postApiRequest(UPDATE_IMAGE_API, data);
};

export const saveAssetApiRequest = async data => {
    return postApiRequest(SAVE_ASSET_API, data);
};

export const saveNewsApiRequest = async (data, type, companyId) => {
    return postApiRequest(SAVE_NEWS_API(type, companyId), data);
};

export const getFeedbackContentApiRequest = async () => {
    return getApiRequest(GET_FEEDBACK_CONTENT_PAGINATED_API());
};
export const getFeedbackPositiveApiRequest = async () => {
    return getApiRequest(GET_FEEDBACK_POSITIVE_PAGINATED_API());
};

export const getFeedbackContentPaginatedApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_FEEDBACK_CONTENT_PAGINATED_API(), queryData);
    return getApiRequest(api);
};
export const getFeedbackPositivePaginatedApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_FEEDBACK_POSITIVE_PAGINATED_API(), queryData);
    return getApiRequest(api);
};

export const updateFeedbackApiRequest = async (feedbackId, resolverComment) => {
    return postApiRequest(UPDATE_FEEDBACK_API(feedbackId, resolverComment));
};

export const deleteFeedbackApiRequest = async feedbackId => {
    return deleteApiRequest(DELETE_FEEDBACK_API(feedbackId));
};
export const getSpecificFeedbackApiRequest = async feedbackId => {
    return getApiRequest(GET_SPECIFIC_FEEDBACK_API(feedbackId));
};

export const resolveFeedbackBulkApiRequest = async (feedbackIds, data) => {
    return postApiRequest(RESOLVE_FEEDBACK_BULK_API(feedbackIds), data);
};

export const unresolveFeedbackBulkApiRequest = async (feedbackIds, data) => {
    return postApiRequest(UNRESOLVE_FEEDBACK_BULK_API(feedbackIds), data);
};

export const deleteFeedbackBulkApiRequest = async (feedbackIds, data) => {
    return postApiRequest(DELETE_FEEDBACK_BULK_API(feedbackIds), data);
};

export const preBulkActionFeedbacksApiRequest = async (feedbackIds, data) => {
    return postApiRequest(PRE_BULK_ACTION_FEEDBACKS_API(feedbackIds), data);
};

export const getNewsApiRequest = async () => {
    return getApiRequest(GET_NEWS_API());
};

export const getLatestUpdatesApiRequest = async () => {
    return getApiRequest(GET_LATEST_UPDATES_API);
};

export const setLatestNewsSeenApiRequest = async () => {
    return postApiRequest(SET_LATEST_UPDATES_SEEN_API);
};

export const getNewsPaginatedApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_NEWS_PAGINATED_API(), queryData);
    return getApiRequest(api);
};
export const getSpecificNewsApiRequest = async newsId => {
    return getApiRequest(GET_SPECIFIC_NEWS_API(newsId));
};
export const deleteNewsApiRequest = async newsId => {
    return deleteApiRequest(DELETE_NEWS_API(newsId));
};

export const dashboardStatisticsApiRequest = async () => {
    return getApiRequest(DASHBOARD_STATISTICS_API);
};

export const globalStatisticsApiRequest = async userId => {
    return getApiRequest(GLOBAL_STATISTICS_API(userId));
};

export const trainingStatisticsApiRequest = async userId => {
    return getApiRequest(TRAINING_STATISTICS_API(userId));
};

export const singleTopicStatsApiRequest = async (userId, topicId) => {
    return getApiRequest(SINGLE_TOPIC_STATS_API(userId, topicId));
};

export const getGamesApiRequest = async () => {
    return getApiRequest(GET_GAMES_API);
};

export const getAllGamesApiRequest = async () => {
    return getApiRequest(GET_ALL_GAMES_API);
};

export const getGameQuestionTypeVariationsRequest = async () => {
    return getApiRequest(GET_GAMES_QUESTION_TYPE_VARIATIONS_API);
};

export const uploadContentApiRequest = async (file, gameSlug) => {
    let data = new FormData();
    data.set('file', file);
    data.set('gameSlug', gameSlug);
    return postApiRequest(UPLOAD_CONTENT_API, data);
};

export const uploadTrainingApiRequest = async file => {
    let data = new FormData();
    data.set('file', file);
    return postApiRequest(UPLOAD_TRAINING_API, data);
};

export const updateTopicSettingsApiRequest = async (trainingId, topicId, title, availableFrom) => {
    let data = new FormData();
    data.set('title', title);
    data.set('availableFrom', availableFrom);
    return postApiRequest(UPDATE_TOPIC_SETTINGS_API(trainingId, topicId), data);
};

export const trainingsInfoApiRequest = async () => {
    return getApiRequest(TRAININGS_INFO_API);
};

export const singleTrainingApiRequest = async id => {
    return getApiRequest(SINGLE_TRAINING_API(id));
};

export const deleteTrainingApiRequest = async (trainingId, data) => {
    return postApiRequest(DELETE_TRAINING_API(trainingId), data);
};

export const allTrainingsApiRequest = async id => {
    return getApiRequest(ALL_TRAININGS_API());
};

export const topicsFolderStructureApiRequest = async () => {
    return getApiRequest(GET_TOPICS_FOLDER_STRUCTURE_API);
};

export const topicContentApiRequest = async id => {
    return getApiRequest(GET_TOPIC_CONTENT_API(id));
};

export const saveTrainingModuleLearningSetApiRequest = async (setId, trainingModuleRootTopicId) => {
    let data = new FormData();
    data.set('setId', setId);
    data.set('rootTopicId', trainingModuleRootTopicId);
    return postApiRequest(SAVE_TRAINING_MODULE_LEARNING_SET_API(), data);
};

export const getAllTrainingModulesInCompanyApiRequest = async () => {
    return getApiRequest(GET_ALL_TRAINING_MODULES_COMPANY_API());
};

export const getTrainingModuleApiRequest = async trainingModuleId => {
    return getApiRequest(GET_TRAINING_MODULE_COMPANY_API(trainingModuleId));
};

export const deleteTrainingModuleApiRequest = async trainingModuleId => {
    return postApiRequest(DELETE_TRAINING_MODULE_API(trainingModuleId));
};

export const getQuestionsUsedInTopicApiRequest = async (id, queryData) => {
    // return getApiRequest(QUESTIONS_USED_IN_TOPIC_API(id, page, size, searchQuery));
    let api = linkBuilderFromQuery(QUESTIONS_USED_IN_TOPIC_API(id), queryData);
    return getApiRequest(api);
};

export const getQuestionsUnusedInTopicApiRequest = async (id, queryData) => {
    let api = linkBuilderFromQuery(QUESTIONS_UNUSED_IN_TOPIC_API(id), queryData);
    return getApiRequest(api);
};
export const getQuestionsUnusedInCompanyApiRequest = async (id, queryData) => {
    let api = linkBuilderFromQuery(QUESTIONS_UNUSED_IN_COMPANY_API(id), queryData);
    return getApiRequest(api);
};

export const updateTopicsFolderStructureApiRequest = async data => {
    return postApiRequest(UPDATE_TOPICS_FOLDER_STRUCTURE_API, data);
};

export const getAllTopicsFromCompanyWithAdditionalInfoApiRequest = async () => {
    return getApiRequest(GET_ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO_API);
};

export const getCompanyInfoApiRequest = async () => {
    return getApiRequest(GET_COMPANY_INFO_API);
};

export const updateCompanyGlobalConfigApiRequest = async data => {
    return postApiRequest(UPDATE_COMPANY_GLOBAL_CONFIG_API, data);
};

export const createCompanyWithCodeApiRequest = async data => {
    return postApiRequest(CREATE_COMPANY_WITH_CODE_API, data);
};

export const getHtmlApiRequest = async link => {
    let data = new FormData();
    data.set('url', link);
    return postApiRequest(GET_HTML_API, data);
};

export const getAllNotificationsPaginatedApiRequest = async queryData => {
    let api = linkBuilderFromQuery(ALL_NOTIFICATIONS_PAGINATED_API, queryData);
    return getApiRequest(api);
};

export const getSingleNotificationApiRequest = async id => {
    return getApiRequest(GET_SINGLE_NOTIFICATION_API(id));
};
export const createNewNotificationApiRequest = async data => {
    return postApiRequest(POST_NEW_NOTIFICATION_API, data);
};
export const deleteNotificationApiRequest = async id => {
    return deleteApiRequest(DELETE_NOTIFICATION_API(id));
};
export const saveContentBlockApiRequest = async data => {
    return postApiRequest(SAVE_CONTENT_BLOCK_API, data);
};

export const deleteContentBlockByIdApiRequest = async id => {
    return postApiRequest(DELETE_CONTENT_BLOCKS_BY_ID_API(id));
};

export const imagesDeleteSoftApiRequest = async data => {
    return postApiRequest(IMAGES_DELETE_SOFT_API, data);
};

export const imagesDeleteForceApiRequest = async data => {
    return postApiRequest(IMAGES_DELETE_FORCE_API, data);
};

export const updateLabelsApiRequest = async data => {
    return postApiRequest(UPDATE_LABELS_API, data);
};

export const getTagsApiRequest = async () => {
    return getApiRequest(GET_TAGS_API);
};

export const getLabelsApiRequest = async queryData => {
    let api = linkBuilderFromQuery(GET_LABELS_API, queryData);
    return getApiRequest(api);
};

export const uploadUserTagsApiRequest = async file => {
    let data = new FormData();
    data.set('file', file);

    return postApiRequest(UPLOAD_USER_TAGS_API, data);
};

export const downloadUserTagsTemplate = async () => {
    let token = await tokenHelper.getToken();
    let headers = {};
    if (token) {
        headers = {
            Authorization: 'Bearer ' + token,
        };
    }
    const fetchParams = {
        method: 'GET',
        headers: headers,
    };
    const response = await fetch(DOWNLOAD_USER_TAGS_TEMPLATE_API, fetchParams);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    document.body.appendChild(link);

    link.download = 'user_tags_template.xlsx';
    link.href = url;
    link.click();
};

export const createTagApiRequest = async data => {
    return postApiRequest(CREATE_TAG_API, data);
};

export const getLabelByIdApiRequest = async id => {
    return getApiRequest(GET_LABEL_BY_ID_API(id));
};

export const deleteLabelByIdApiRequest = async id => {
    return postApiRequest(DELETE_LABEL_BY_ID_API(id));
};

export const deleteTagByIdApiRequest = async id => {
    return postApiRequest(DELETE_TAG_BY_ID_API(id));
};

export const getUsersWithTagApiRequest = async (id, queryData) => {
    let api = linkBuilderFromQuery(GET_USERS_WITH_TAG_API(id), queryData);
    return getApiRequest(api);
};

export const getUsersWithoutTagWithIdApiRequest = async (id, queryData) => {
    let api = linkBuilderFromQuery(GET_USERS_WITHOUT_TAG_WITH_ID_API(id), queryData);
    return getApiRequest(api);
};

export const addUserToTagApiRequest = async (tagId, userId) => {
    let api = linkBuilderFromQuery(ADD_USER_TO_TAG_API(tagId), { userId: userId });
    return postApiRequest(api);
};

export const removeUserFromTagApiRequest = async (tagId, userId) => {
    let api = linkBuilderFromQuery(REMOVE_USER_FROM_TAG_API(tagId), { userId: userId });
    return postApiRequest(api);
};

export const addUsersToTagsApiRequest = async (users, tags, allUsersSelected) => {
    let api = linkBuilderFromQuery(ADD_USERS_TO_TAGS_API, { allUsersSelected: allUsersSelected });
    return postApiRequest(api, { users: users, tags: tags });
};

export const removeUsersFromTagsApiRequest = async (users, tags, allUsersSelected) => {
    let api = linkBuilderFromQuery(REMOVE_USERS_FROM_TAGS_API, { allUsersSelected: allUsersSelected });
    return postApiRequest(api, { users: users, tags: tags });
};

export const generateContentFromFileApiRequest = async (file, title, trainingId, language, gamesToGenerateFor) => {
    let data = new FormData();
    data.set('file', file);
    data.set('title', title);
    data.set('lang', language);
    data.set('gamesToGenerateFor', gamesToGenerateFor);
    if (trainingId) {
        data.set('trainingId', trainingId);
    }

    return postApiRequest(GENERATE_CONTENT_API, data);
};

export const generateContentForTopicApiRequest = async (topicId, language, chosenGames) => {
    let data = new FormData();
    data.set('categoryId', topicId);
    data.set('language', language);
    data.set('gamesToGenerateFor', chosenGames);

    return postApiRequest(GENERATE_CONTENT_FOR_TOPIC_API, data);
};
