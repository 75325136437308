import React, { useReducer, useContext, useMemo, useEffect } from 'react';
import { actionTypes } from './local-actions';
import { INITIAL_STATE } from './local-constants';
import _ from 'lodash';
import { ContextDevTool } from 'react-context-devtool';
import { insertionControlReducer } from './local-reducer';

const InsertionTemporaryContext = React.createContext();

export const UndoRedoProvider = ({ children }) => {
    let reducerState = INITIAL_STATE;

    const [state, dispatch] = useReducer(insertionControlReducer, reducerState);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);


    return (
        <InsertionTemporaryContext.Provider value={contextValue}>
            <>
                <ContextDevTool context={InsertionTemporaryContext} id="addUndoRedoContextId" displayName="Add undo/redo context" />
                {children}
            </>
        </InsertionTemporaryContext.Provider>
    );
};

export const ContentInsertionConsumer = () => {
    return (
        <InsertionTemporaryContext.Consumer>
            {values => {
                if (window._REACT_CONTEXT_DEVTOOL) {
                    window._REACT_CONTEXT_DEVTOOL({ id: 'addUndoRedoContextId', displayName: 'Add undo/redo consumer', values });
                }
                return null;
            }}
        </InsertionTemporaryContext.Consumer>
    );
};

const useState = () => {
    const { state } = useContext(InsertionTemporaryContext);
    return state;
};

const useDispatch = () => {
    const { dispatch } = useContext(InsertionTemporaryContext);
    return dispatch;
};

export const useUndoRedo = () => {
    const state = useState();
    const dispatch = useDispatch();

    return {
        undoStack: state.undoStack,
        redoStack: state.redoStack,
        addToUndoStack: (location, actionType, state) => dispatch({ type: actionTypes.addToUndoStack, payload: { location: location, actionType: actionType, state: state } }),
        removeFromUndoStack: (location, currentState) => dispatch({ type: actionTypes.removeFromUndoStack, payload: { location, currentState } }),
        addToRedoStack: (location, actionType, state) => dispatch({ type: actionTypes.addToRedoStack, payload: { location: location, actionType: actionType, state: state } }),
        removeFromRedoStack: (location, currentState) => dispatch({ type: actionTypes.removeFromRedoStack, payload: { location, currentState } }),
        resetRedoStack: (location) => dispatch({ type: actionTypes.resetRedoStack, payload: { location } }),
        resetStacks: (location) => dispatch({ type: actionTypes.resetStacks, payload: location })
    };
};
