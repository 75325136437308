import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Body2 } from 'style/typography/Body';
import { CustomDatePicker } from 'components/elements/datepicker/CustomDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CustomTimePicker from './CustomTimePicker';
import yellowCheckboxReminder from 'assets/icons/reminders-yellow-checkbox.png';
import circleEmptyfIcon from 'assets/icons/swipercircle.png';
import InputErrorHandlerWrapper from 'components/wrappers/InputErrorHandlerWrapper';

import './NotificationPreview.css';
import { useTranslation } from 'react-i18next';

export default function NotificationType({
    timeOneTime,
    setTimeOneTime,
    timeRecurring,
    setTimeRecurring,
    daysInWeek,
    setDaysInWeek,
    errorDaysInWeekProps,
    errorTimeOneTimeProps,
    errorTimeRecurring,
    cronBuilder,
    currentCronTypeValue,
    dateForOneTimeNotification,
    setDateForOneTimeNotification,
    errorDateOneTimeNotification,
}) {
    const { t } = useTranslation();

    useEffect(() => {
        let isDate = Object.prototype.toString.call(timeOneTime) === '[object Date]';

        cronBuilder.current = `0 ${timeOneTime ? (isDate ? timeOneTime.getMinutes() : timeOneTime.$m) : 0} ${
            timeOneTime ? (isDate ? timeOneTime.getHours() : timeOneTime?.$H) : 0
        } ${dateForOneTimeNotification?.getDate()} ${dateForOneTimeNotification?.getMonth() + 1} ?`;
    }, [dateForOneTimeNotification, timeOneTime]);

    useEffect(() => {
        let isDate = Object.prototype.toString.call(timeRecurring) === '[object Date]';

        cronBuilder.current = `0 ${timeRecurring ? (isDate ? timeRecurring.getMinutes() : timeRecurring.$m) : 0} ${
            timeRecurring ? (isDate ? timeRecurring.getHours() : timeRecurring?.$H) : 0
        } * * ${daysInWeek
            .filter(el => el.selected)
            .map(el => el.label)
            .join(',') || '*'}`;
    }, [timeRecurring, daysInWeek]);

    return (
        <div className="notifications--date-container">
            {currentCronTypeValue?.value == 'one_time' && (
                <div className="d-flex flex-row" style={{ width: '370px', marginLeft: '10px' }}>
                    <InputErrorHandlerWrapper errorProps={errorDateOneTimeNotification}>
                        <CustomDatePicker
                            value={dateForOneTimeNotification}
                            onChange={date => {
                                setDateForOneTimeNotification(date);
                            }}
                            border={false}
                            minDate={new Date()}
                        />
                    </InputErrorHandlerWrapper>
                    <div style={{ marginLeft: '10px' }}>
                        <InputErrorHandlerWrapper errorProps={errorTimeOneTimeProps}>
                            <CustomTimePicker time={timeOneTime} setTime={setTimeOneTime} />
                        </InputErrorHandlerWrapper>
                    </div>
                </div>
            )}

            {currentCronTypeValue?.value == 'recurring' && (
                <div className="mx-4 mt-2">
                    <div className="d-flex flex-row">
                        <InputErrorHandlerWrapper errorProps={errorDaysInWeekProps}>
                            <div className="d-flex">
                                {daysInWeek.map(el => {
                                    return (
                                        <div
                                            className="d-flex flex-column day-in-week"
                                            style={{ width: 40, height: 40, cursor: 'pointer' }}
                                            onClick={() => {
                                                el.selected = !el.selected;
                                                setDaysInWeek([...daysInWeek]);
                                            }}
                                        >
                                            <div className="d-flex">{t(el.label)} </div>
                                            <img
                                                src={el.selected ? yellowCheckboxReminder : circleEmptyfIcon}
                                                alt={el.selected}
                                                style={{ width: 20, height: 20 }}
                                            ></img>
                                        </div>
                                    );
                                })}
                            </div>
                        </InputErrorHandlerWrapper>
                    </div>
                    <div className="d-flex align-items-center mt-3 mb-2">
                        <Body2 style={{ marginRight: '15px' }}>{t('at')}</Body2>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <InputErrorHandlerWrapper errorProps={errorTimeRecurring}>
                                <CustomTimePicker time={timeRecurring} setTime={setTimeRecurring} />
                            </InputErrorHandlerWrapper>
                        </LocalizationProvider>
                    </div>
                </div>
            )}
        </div>
    );
}

NotificationType.propTypes = {
    cronBuilder: PropTypes.any,
    currentCronTypeValue: PropTypes.any,
    daysInWeek: PropTypes.any,
    errorDaysInWeekProps: PropTypes.any,
    errorTimeOneTimeProps: PropTypes.any,
    errorTimeRecurring: PropTypes.any,
    setDaysInWeek: PropTypes.func,
    setTimeOneTime: PropTypes.func,
    setTimeRecurring: PropTypes.func,
    timeOneTime: PropTypes.any,
    timeRecurring: PropTypes.any,
};
