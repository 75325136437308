export const quickValuesOptions = [
    { type: 'option', label: 'User name', value: '<%userName%>' },
    { type: 'option', label: 'Coach score', value: '<%globalCoachScore%>' },
    { type: 'option', label: 'Race cars wins', value: '<%raceCarsWins%>' },
    { type: 'option', label: 'Total gameplays', value: '<%totalGameplays%>' },
];
export const daysInWeekDefault = [
    {
        label: 'Mon',
        selected: false,
    },
    {
        label: 'Tue',
        selected: false,
    },
    {
        label: 'Wed',
        selected: false,
    },
    {
        label: 'Thu',
        selected: false,
    },
    {
        label: 'Fri',
        selected: false,
    },
    {
        label: 'Sat',
        selected: false,
    },
    {
        label: 'Sun',
        selected: false,
    },
];

export const notificationTimeType = [
    { value: 'one_time', label: 'One time' },
    { value: 'recurring', label: 'Recurring' },
];
