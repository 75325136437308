import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { AddImageComponent } from './AddImageComponent';
import { EditorSpace } from './EditorSpace';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { views } from 'services/image_management/image-editor-management/local-constants';

import './CroppingModule.css';

export default function CropperModal({ index, addImage, addMultipleImages, onImageUploaded }) {
    const { imageFiles, currentActiveIndex, view, setCurrentActivePreviewImage } = useImageEditor();

    useEffect(() => {
        setCurrentActivePreviewImage(imageFiles.filesBlob[currentActiveIndex]);
    }, [currentActiveIndex, imageFiles]);

    return (
        <div className="cropper-modal--container">
            <div className="cropper-modal--container-inner">
                {view !== views[1] && (
                    <AddImageComponent
                        addImage={addImage}
                        onImageUploaded={onImageUploaded}
                        addMultipleImages={addMultipleImages}
                        index={index}
                    ></AddImageComponent>
                )}
                {view === views[1] && <EditorSpace></EditorSpace>}
            </div>
        </div>
    );
}

CropperModal.propTypes = {
    addImage: PropTypes.func,
    addMultipleImages: PropTypes.func,
    index: PropTypes.any,
    onImageUploaded: PropTypes.func,
};
