import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UserRow } from './UserRow';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';
import { OutlinedButton } from 'components/elements/Button';
import { Circular } from 'components/features/data_table/Circular';
import { RemoveRoleDialog } from './RemoveRoleDialog';
import {
    addRoleToUserAction,
    fetchUsersWithRoleAction,
    fetchUsersWithUnassignedRoleAction,
    removeRoleFromUserAction,
} from 'redux/actions/users.actions';
import { useTranslation } from 'react-i18next';
import { Caption1 } from 'style/typography/Caption';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import Ellipsis from 'assets/icons/ellipsis.svg';
import ConnectedTraining from 'assets/icons/company-connectedtraining.svg';
import { RemoveTrainingDialog } from './RemoveTrainingDialog';
import { updateTrainingConnection } from 'redux/actions/roles.actions';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';

export function RoleSettings({
    currentNode,
    usersWithRole,
    usersWithUnassignedRole,
    loadMoreTriggerUsersWithoutRole,
    setLoadMoreTriggerUsersWithoutRole,
    loadMoreTriggerUsersWithRole,
    setLoadMoreTriggerUsersWithRole,
    setSearchQueryUsersWithRole,
    setSearchQueryUsersWithoutRole,
    searchQueryUsersWithRole,
    searchQueryUsersWithoutRole,
}) {
    const usersWithRoleState = useSelector(state => state.userManagement.usersWithRole);
    const usersWithUnassignedRoleState = useSelector(state => state.userManagement.usersWithUnassignedRole);

    const [usersShown, setUsersShown] = useState(true);
    const [allUsersShown, setAllUsersShown] = useState(true);
    const [removeUserId, setRemoveUserId] = useState(null);
    const [removeTrainingModalOpen, setRemoveTrainingModalOpen] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function getUsersWithRole(val) {
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(fetchUsersWithRoleAction(currentNode.roleId, val, 0, true));
            },
            300,
            'getUsersWithRole'
        );
        return () => setTimeoutLastExecute.removeCallback('getUsersWithRole');
    }

    function getUsersWithoutRole(val) {
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(fetchUsersWithUnassignedRoleAction(val, 0, true));
            },
            300,
            'getUsersWithoutRole'
        );
        return () => setTimeoutLastExecute.removeCallback('getUsersWithoutRole');
    }

    const hasMoreUnassignedUsersToShow = !(usersWithUnassignedRoleState?.data?.page + 1 >= usersWithUnassignedRoleState?.data?.totalPages);
    const hasMoreUsersWithRoleToShow = !(usersWithRoleState?.data?.page + 1 >= usersWithRoleState?.data?.totalPages);

    return (
        <div key={currentNode} style={{ maxHeight: '65vh', overflow: 'scroll', width: '90%' }} className={'d-flex flex-column'}>
            <div className="d-flex flex-column mt-3">
                <Caption1 className={'mb-0'} color={colors.gray6}>
                    {t('CONNECTED TRAINING')}
                </Caption1>
                <div
                    style={{ border: `0.5px solid ${colors.disabledColor}`, width: '95%' }}
                    className="d-flex flex-row p-2 rounded align-items-center mt-2"
                >
                    <img alt="Training" src={ConnectedTraining} height={18} width={18} />
                    <Link
                        style={{ pointerEvents: currentNode?.connectedSet ? 'auto' : 'none' }}
                        to={'/content/trainings?trainingId=' + currentNode.connectedSet}
                        target="_blank"
                    >
                        <Body2 weight="medium" color={colors.dark} className={'mb-0 ml-2 cursor-pointer'}>
                            {!currentNode?.connectedSetName ? t('No connected training') : currentNode?.connectedSetName}
                        </Body2>
                    </Link>
                    {currentNode?.connectedSetName && (
                        <div className="dropleft ml-auto cursor-pointer">
                            <img
                                alt="Show more"
                                style={{ height: 17, width: 17 }}
                                color={colors.descriptionText}
                                src={Ellipsis}
                                data-toggle="dropdown"
                            />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to={'/content/trainings?trainingId=' + currentNode.connectedSet} target="_blank">
                                    {t('Open in new tab')}
                                </Link>
                                <div
                                    className="dropdown-item"
                                    onClick={() => {
                                        setRemoveTrainingModalOpen(currentNode?.connectedSet !== null);
                                    }}
                                >
                                    {t('Remove training')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <RemoveTrainingDialog
                modalOpen={removeTrainingModalOpen}
                setModalOpen={setRemoveTrainingModalOpen}
                onAction={() => dispatch(updateTrainingConnection(currentNode.roleId, null, '', false))}
            />
            <div className={'d-flex flex-column mt-4'} style={{ maxWidth: '95%' }}>
                <div className="d-flex flex-row align-items-center">
                    <Body2 color={colors.gray6} className={'mr-1 mb-0'}>
                        {t('Users with this role ({{usersCount}})', { usersCount: usersWithRoleState.data?.totalElements })}
                    </Body2>

                    <img
                        alt="Show more"
                        height={15}
                        width={15}
                        className={'ml-2 cursor-pointer'}
                        onClick={() => setUsersShown(!usersShown)}
                        src={usersShown ? AngleUp : AngleDown}
                    />
                </div>
                <div style={{ margin: '10px 0' }}>
                    <CustomTextField
                        key={'searchUsersWithRole'}
                        placeholder={t('Search')}
                        value={searchQueryUsersWithRole}
                        setValue={val => {
                            setSearchQueryUsersWithRole(val);
                            getUsersWithRole(val);
                        }}
                        style={{
                            border: `1px solid ${colors.gray2}`,
                        }}
                        isSearch={true}
                        width={'100%'}
                    />
                </div>
                <div style={{ maxHeight: '25vh', overflow: 'scroll' }} className={'d-flex flex-column mt-1'}>
                    {usersShown &&
                        usersWithRole?.map((u, index) => {
                            return (
                                <div>
                                    <RemoveRoleDialog
                                        modalOpen={removeUserId === u.id}
                                        setModalOpen={setRemoveUserId}
                                        onAction={() => {
                                            dispatch(removeRoleFromUserAction(removeUserId));
                                            setRemoveUserId(null);
                                        }}
                                    />
                                    <UserRow
                                        user={u}
                                        first={index === 0}
                                        isAssigned={true}
                                        currentNode={currentNode}
                                        onAdd={() => {
                                            dispatch(addRoleToUserAction(currentNode.roleId, u.id));
                                        }}
                                        onRemove={() => {
                                            setRemoveUserId(u.id);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    {usersShown && usersWithRole?.length > 0 && (
                        <div className={'mt-1 d-flex flex-column'}>
                            {stateIsLoading(usersWithRoleState) && (
                                <div style={{ height: '25vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                                    <Circular />
                                </div>
                            )}
                            {stateIsLoaded(usersWithRoleState) && hasMoreUsersWithRoleToShow && (
                                <div style={{ width: '150px', alignSelf: 'center' }}>
                                    <OutlinedButton
                                        className={'my-2 cursor-pointer'}
                                        onClick={() => setLoadMoreTriggerUsersWithRole(!loadMoreTriggerUsersWithRole)}
                                    >
                                        <Body2 color={colors.primaryText} weight="medium">
                                            {t('Show more')}
                                        </Body2>
                                    </OutlinedButton>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className={'d-flex flex-column mt-3'} style={{ maxWidth: '95%' }}>
                <div className="d-flex flex-row align-items-center">
                    <Body2 color={colors.gray6} className={'mr-1 mb-0'}>
                        {t('Users without role ({{usersCount}})', { usersCount: usersWithUnassignedRoleState.data?.totalElements })}
                    </Body2>
                    <img
                        alt="Show more"
                        height={15}
                        width={15}
                        className={'ml-2 cursor-pointer'}
                        onClick={() => setAllUsersShown(!allUsersShown)}
                        src={allUsersShown ? AngleUp : AngleDown}
                    />
                </div>
                <div style={{ margin: '10px 0' }}>
                    <CustomTextField
                        key={'searchUsersWithRole'}
                        placeholder={t('Search')}
                        value={searchQueryUsersWithoutRole}
                        setValue={val => {
                            setSearchQueryUsersWithoutRole(val);
                            getUsersWithoutRole(val);
                        }}
                        style={{
                            border: `1px solid ${colors.gray2}`,
                        }}
                        isSearch={true}
                        width={'100%'}
                    />
                </div>
                <div key={usersWithUnassignedRole.length} style={{ maxHeight: '25vh', overflow: 'scroll' }} className={'d-flex flex-column mt-1'}>
                    {allUsersShown &&
                        usersWithUnassignedRole?.map((u, index) => {
                            return (
                                <UserRow
                                    user={u}
                                    first={index === 0}
                                    isAssigned={false}
                                    currentNode={currentNode}
                                    onAdd={() => {
                                        dispatch(addRoleToUserAction(currentNode.roleId, u.id));
                                    }}
                                    onRemove={() => {
                                        setRemoveUserId(u.id);
                                    }}
                                />
                            );
                        })}
                    {allUsersShown && usersWithUnassignedRole?.length > 0 && (
                        <div className={'mt-1 text-center d-flex flex-column'}>
                            {stateIsLoading(usersWithUnassignedRoleState) && (
                                <div style={{ height: '25vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                                    <Circular />
                                </div>
                            )}
                            {stateIsLoaded(usersWithUnassignedRoleState) && hasMoreUnassignedUsersToShow && (
                                <div style={{ width: '150px', alignSelf: 'center' }}>
                                    <OutlinedButton
                                        className={'my-2 cursor-pointer'}
                                        onClick={() => setLoadMoreTriggerUsersWithoutRole(!loadMoreTriggerUsersWithoutRole)}
                                    >
                                        <Body2 color={colors.primaryText} weight="medium">
                                            {t('Show more')}
                                        </Body2>
                                    </OutlinedButton>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

RoleSettings.propTypes = {
    currentNode: PropTypes.any,
    loadMoreTriggerUsersWithRole: PropTypes.bool,
    loadMoreTriggerUsersWithoutRole: PropTypes.bool,
    setLoadMoreTriggerUsersWithRole: PropTypes.func,
    setLoadMoreTriggerUsersWithoutRole: PropTypes.func,
    usersWithRole: PropTypes.array,
    usersWithUnassignedRole: PropTypes.array,
};
