import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { getNameFromLink } from 'services/utils/stringHelpers';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import AngleLeft from 'assets/icons/angle-left-white.svg';

export function PreviewImageDialog({ setModalOpen, currentImageId, filteredImages }) {
    const [currentIndex, setCurrentIndex] = useState(null);

    const elRef = useRef(null);

    useEffect(() => {
        setCurrentIndex(filteredImages.findIndex(el => el.fullObj.id === currentImageId));

        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    function getNext() {
        if (currentIndex + 1 < filteredImages.length) {
            setCurrentIndex(prevData => prevData + 1);
        }
    }

    function getPrev() {
        if (currentIndex > 0) {
            setCurrentIndex(prevData => prevData - 1);
        }
    }

    function handleClick(event) {
        if (elRef.current && !elRef.current.contains(event.target)) {
            setModalOpen(false);
        }
    }

    return (
        <div className="preview-image-dialog--container">
            {currentIndex != null && (
                <div ref={elRef} onClick={() => {}} className="preview-image-dialog--image-wrap">
                    <img
                        alt="Previous"
                        style={{ cursor: 'pointer', opacity: currentIndex > 0 ? '1' : '0' }}
                        onClick={() => getPrev()}
                        height={40}
                        src={AngleLeft}
                    ></img>
                    <div>
                        <img alt="Preview" className="preview-image-dialog--image" src={filteredImages[currentIndex].src}></img>
                        <div className="preview-image-dialog--label">
                            <Caption1 color={colors.white}>{getNameFromLink(filteredImages[currentIndex].src.split('?')[0])}</Caption1>
                        </div>
                    </div>
                    <img
                        alt="Next"
                        style={{ cursor: 'pointer', opacity: currentIndex + 1 < filteredImages.length ? '1' : '0', transform: 'rotateY(180deg)' }}
                        onClick={() => getNext()}
                        height={40}
                        src={AngleLeft}
                    ></img>
                </div>
            )}
        </div>
    );
}

PreviewImageDialog.propTypes = {
    currentImageId: PropTypes.any,
    filteredImages: PropTypes.array,
    setModalOpen: PropTypes.func,
};
