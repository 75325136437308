import React, { useState } from 'react';
import InputContent from 'components/features/content_management/inputs/InputContent';
import { useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import { validatorCategories } from 'services/validation-module/local-constants';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import InputAnswerWithPosition from 'components/features/content_management/inputs/InputAnswerWithPosition';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { Body2 } from 'style/typography/Body';
import Add from 'assets/icons/icon-add.svg';
import Remove from 'assets/icons/icon-remove.svg';
import { reorder } from 'services/utils/arrayHelpers';

import './Sequence.css';
import { useTranslation } from 'react-i18next';

const queryAttr = 'data-droppable-item-something';

export default function SequenceInputs({ answers, initializeQuestion, game, id, filters }) {
    const { t } = useTranslation();

    const { insertAnswer, setOptions, removeAnswer } = useMultipleChoiceAnswers();

    const [placeholderProps, setPlaceholderProps] = useState({});

    const onDragEnd = result => {
        // dropped outside the list
        setPlaceholderProps({});

        if (!result.destination) {
            return;
        }

        let items = reorder(answers, result.source.index, result.destination.index);
        items.forEach((el, index) => {
            el.position = index + 1;
        });
        setOptions([...items]);
    };

    const getDraggedDom = draggableId => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    const handleDragStart = event => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
                const style = curr?.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr?.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY: clientY + 240,
            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft) + 15,
        });
    };

    const handleDragUpdate = event => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;
        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];

        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [...childrenArray.slice(0, destinationIndex), movedItem, ...childrenArray.slice(destinationIndex + 1)];

        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr?.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr?.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY: clientY + 240,
            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft) + 15,
        });
    };

    return (
        <InputContent initializeQuestion={initializeQuestion} game={game} id={id} filters={filters}>
            <div className="mt-2" style={{ position: 'relative' }}>
                <div>
                    <Caption1 color={colors.gray6}>{t('OPTIONS')}</Caption1>
                </div>
                <div>
                    <DragDropContext onDragUpdate={handleDragUpdate} onDragStart={handleDragStart} onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided1, other1) => {
                                return (
                                    <div {...provided1.droppableProps} ref={provided1.innerRef}>
                                        {answers.map((ans, index) => {
                                            return (
                                                <Draggable key={ans.id} draggableId={ans.id + ''} index={index}>
                                                    {(provided, other) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    style={getItemStyle(other.isDragging, provided.draggableProps.style)}
                                                                    data-droppable-item-something={index}
                                                                >
                                                                    <div className="d-flex flex-row align-items-center">
                                                                        <InputAnswerWithPosition
                                                                            key={`input-answer-checkbox-${ans.id}`}
                                                                            value={ans.text}
                                                                            index={index}
                                                                            position={ans.position}
                                                                            draggableHandleProps={provided.dragHandleProps}
                                                                        />
                                                                        <img
                                                                            alt="Remove"
                                                                            className="cursor-pointer ml-2"
                                                                            onClick={() => removeAnswer(index)}
                                                                            height={20}
                                                                            width={20}
                                                                            src={Remove}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {provided1.placeholder}
                                        {provided1.placeholder && other1.isDraggingOver && (
                                            <div
                                                className="placeholder"
                                                style={{
                                                    top: placeholderProps.clientY,
                                                    left: placeholderProps.clientX,
                                                    height: placeholderProps.clientHeight,
                                                    width: placeholderProps.clientWidth,
                                                }}
                                            />
                                        )}
                                        {/* <CustomPlaceholder snapshot={snapshot} /> */}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </DragDropContext>
                </div>
                {answers.length < 9 && (
                    <div className="d-flex flex-row align-content-center mt-2" onClick={insertAnswer}>
                        <img alt="Add" height={20} width={20} src={Add} />
                        <Body2 id="add-option-button-compare" className="cursor-pointer ml-2">
                            {t('Add New')}
                        </Body2>
                    </div>
                )}
                <ValidationPopup category={validatorCategories.CORRECT_ANSWER_REQUIRED} position="right" />
            </div>
        </InputContent>
    );
}

const grid = 15;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,
    ...draggableStyle,
});
