// import AsyncStorage from '@react-native-community/async-storage';

export const tokenHelper = {
    getToken: async function() {
        let persist = await localStorage.getItem('persist:coach');

        if (persist) {
            try {
                let access_token = JSON.parse(JSON.parse(persist).auth).data.access_token;
                return access_token;
            } catch {
                return false;
            }
        } else {
            return false;
        }
    },
    getRefreshToken: async function() {
        let persist = await localStorage.getItem('persist:coach');
        if (persist) {
            try {
                let refresh_token = JSON.parse(JSON.parse(persist).auth).data.refresh_token;
                return refresh_token;
            } catch {
                return false;
            }
        } else {
            return false;
        }
    },
    isAccessTokenExpired: async function() {
        let persist = await localStorage.getItem('persist:coach');

        if (persist) {
            try {
                let data = JSON.parse(JSON.parse(persist).auth).data;

                if (data) {
                    let expires_in = data.expires_in;
                    let token_creation = data.token_creation;
                    let date_now = Date.now();

                    return (date_now - token_creation) / 1000 + expires_in * 0.05 > expires_in;
                } else {
                    return true;
                }
            } catch (message) {
                console.warn('Catch exception parsing persist:coach', message);
                return false;
            }
        } else {
            return false;
        }
    },
    sendTokenToUnity: async function() {
        let token = await this.getToken();
        if (token) {
        }
        return token;
    },
    parseJwt: function(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    },
};
