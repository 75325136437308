import React from 'react';
import PropTypes from 'prop-types';
import { UserCircle } from 'components/elements/UserCircle';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { Body2 } from 'style/typography/Body';

import Add from 'assets/icons/icon-add.svg';
import Remove from 'assets/icons/icon-remove.svg';
import { useTranslation } from 'react-i18next';

export function UserRow({ user, first, onAdd, onRemove, isAssigned, checkActive = true }) {
    const { t } = useTranslation();

    return (
        <div
            key={user.id}
            style={{ borderBottom: `1px solid ${colors.disabledColor}`, padding: 10 }}
            className={`d-flex flex-row align-items-center`}
        >
            <UserCircle name={user?.name} lastName={user?.lastName} width={25} height={25} font={12} bgColor={colors.gray4} />
            <Body2 className={'ml-2 mb-0'} color={!checkActive || user.status === 'active' ? colors.dark : colors.gray6}>
                {!checkActive || user.status === 'active' ? `${user?.name} ${user?.lastName}` : user.email}
            </Body2>
            <div className={'ml-auto d-flex flex-row align-items-center'}>
                <Caption1 color={colors.gray6} className={'mb-0'}>
                    {!checkActive || user?.status === 'active' ? t('Active user') : t('Inactive user')}
                </Caption1>
                <div className="dropleft ml-2 cursor-pointer">
                    <img
                        alt={isAssigned ? t('Remove') : t('Add')}
                        onClick={() => (isAssigned ? onRemove() : onAdd())}
                        style={{ height: 20, width: 20 }}
                        color={colors.descriptionText}
                        src={isAssigned ? Remove : Add}
                    />
                </div>
            </div>
        </div>
    );
}

UserRow.propTypes = {
    first: PropTypes.any,
    isAssigned: PropTypes.any,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    user: PropTypes.any,
};
