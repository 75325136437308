import { palette } from 'style/theme/theme-constants';

export const tabs = {
    MuiTab: {
        root: {
            '&.Mui-selected': {
                outlineColor: palette.primary.main,
                outline: 'none',
                '& .MuiTypography-subtitle1': {
                    color: palette.secondary.main,
                },
            },
            textTransform: 'none',
            '& .MuiTypography-subtitle1': {
                fontWeight: 'bold',
                fontSize: '18px',
                color: palette.tabs.deselected,
            },
            margin: '0 20px',
        },
    },
    MuiTabs: {
        indicator: {
            height: '3px',
        },
    },
};
