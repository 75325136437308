import React from 'react';
import PropTypes from 'prop-types';
import PreviewContent from 'components/features/content_management/PreviewContent';
import GameHeader from 'containers/layout/module/pages/content/questions/question_editor/games_preview/GameHeader';
import { GameQuestionCenter } from 'style/styled/GameElements';
import { useContentInsertionQuestion } from 'services/content-management/insertion-control';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import placeholder from 'assets/game-previews/compare-empty-pic.png';

export default function ComparePreview({ images, correctOption }) {
    const { question } = useContentInsertionQuestion();

    return (
        <PreviewContent game={'Compare'}>
            <GameHeader />
            <GameQuestionCenter>{question || 'Question'}</GameQuestionCenter>
            <div style={{ position: 'absolute', bottom: '10%', width: '100%' }} className="d-flex flex-column align-items-center">
                <div style={{ width: '80%', height: '100px', backgroundColor: '#dadcdd', position: 'relative' }}>
                    {(images[0] && <CompareCardPreview file={images[0].urlRatio2x1} index={1} />) || (
                        <img
                            id={`compare-visual-card-preview-image-placeholder-left`}
                            src={placeholder}
                            alt="preview"
                            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                        />
                    )}
                    {correctOption === 'TOP' && (
                        <div className="compare-correct-overlay d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faCheck} style={{ fontSize: '20px', color: 'white' }} />
                        </div>
                    )}
                </div>
                <div
                    style={{ width: '80%', backgroundColor: correctOption === 'BOTH' ? '#febe10' : 'black', color: 'white' }}
                    className="my-3 text-center py-1"
                >
                    BOTH
                </div>
                <div style={{ width: '80%', height: '100px', backgroundColor: '#dadcdd', position: 'relative' }}>
                    {(images[1] && <CompareCardPreview file={images[1].urlRatio2x1} index={2} />) || (
                        <img
                            id={`compare-visual-card-preview-image-placeholder-right`}
                            src={placeholder}
                            alt="preview"
                            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                        />
                    )}
                    {correctOption === 'BOTTOM' && (
                        <div className="compare-correct-overlay d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faCheck} style={{ fontSize: '20px', color: 'white' }} />
                        </div>
                    )}
                </div>
            </div>
        </PreviewContent>
    );
}

ComparePreview.propTypes = {
    correctOption: PropTypes.string,
    images: PropTypes.array,
};

const CompareCardPreview = ({ file, index }) => {
    return (
        <img
            id={`compare-visual-card-preview-image-${index}`}
            src={file}
            alt="preview"
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
        />
    );
};
