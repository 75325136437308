import Icon from 'assets/icons/coach-logo-black.svg';
import { colors } from 'style/colors';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordSetNewAction } from 'redux/actions/users.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useTranslation } from 'react-i18next';
import { H7 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { AnimatedTextField } from 'components/elements/inputs/AnimatedTextField';
import { DefaultButton } from 'components/elements/Button';

import '../ForgotPassword.css';

export function ForgotPasswordSetNew({ history }) {
    const { code } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [validationMessage, setValidationMessage] = useState(false);
    const forgotPasswordSetNewState = useSelector(state => state.userManagement.forgotPasswordSetNew);

    function saveNewPassword() {
        var regexPasswordValidator = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        let passwordValid = regexPasswordValidator.test(password);

        if (password.length < 6 || repeatPassword.length < 6) {
            setPasswordError(true);
            setValidationMessage(t('Password must be at least 6 characters.'));
        } else if (password !== repeatPassword) {
            setPasswordError(true);
            setValidationMessage(t("Passwords don't match."));
        } else if (!passwordValid) {
            setValidationMessage(t('Password must contain at least one upper case character, lower case character, a number and a special symbol'));
            setPasswordError(true);
        } else {
            dispatch(
                forgotPasswordSetNewAction({
                    password: password,
                    code: code,
                })
            );
        }
    }

    useEffect(() => {
        if (stateIsLoaded(forgotPasswordSetNewState)) {
            if (forgotPasswordSetNewState.data?.isSuccessful) {
                history.push('/users/forgot/set_new/success');
            } else {
                setPasswordError(true);
                setValidationMessage(forgotPasswordSetNewState.data?.message);
            }
        }
    }, [forgotPasswordSetNewState, history]);
    return (
        <div className="forgot-password-screen">
            <img alt="logo" src={Icon} width={70} height={70}></img>
            <H7 style={{ marginTop: 30 }} color={colors.gray6}>
                {t('Reset account password')}
            </H7>
            <Body2 style={{ maxWidth: '30vw' }}>{t('Enter your new password and you’re good to go!')}</Body2>
            <div className="forgot-password-screen--form">
                <AnimatedTextField
                    label={t('Password')}
                    type="password"
                    onFocus={() => {
                        setPasswordError(false);
                        setValidationMessage(null);
                    }}
                    value={password}
                    setValue={val => setPassword(val)}
                />
                <AnimatedTextField
                    label={t('Repeat password')}
                    type="password"
                    onFocus={() => {
                        setPasswordError(false);
                        setValidationMessage(null);
                    }}
                    value={repeatPassword}
                    setValue={val => setRepeatPassword(val)}
                />
                <Collapse style={{ marginTop: 20 }} in={passwordError}>
                    <Alert severity="error">{validationMessage}</Alert>
                </Collapse>
            </div>
            <DefaultButton
                style={{ marginTop: 20, width: '200px' }}
                text={t('Save new password')}
                onClick={() => saveNewPassword()}
                textColor="white"
            />
        </div>
    );
}
