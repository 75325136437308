import { notificationSendType, notificationSendingStatus } from 'static/notifications';

export const notificationTableSubheaders = ({ filtersState, activeFilters, updateFilterState, dispatchFilters, onAddNew }) => [
    {
        id: 'notifications-search-title',
        type: 'value',
        value: filtersState.searchQuery,
        setValue: title => dispatchFilters({ searchQuery: title }),
        placeholder: 'Search',
        active: true,
    },
    {
        id: 'notifications-search-status',
        type: 'dropdown',
        value: filtersState.status,
        setValue: status => dispatchFilters({ status: status }),
        placeholder: 'Status',
        dropdownValues: notificationSendingStatus,
        active: activeFilters.status.active,
    },
    {
        id: 'notifications-search-type',
        type: 'dropdown',
        value: filtersState.type,
        setValue: type => dispatchFilters({ type: type }),
        placeholder: 'Type',
        dropdownValues: notificationSendType,
        active: activeFilters.type.active,
    },
    { type: 'add-filters', active: true, activeFilters: activeFilters, updateFilterState: updateFilterState },
    {
        id: 'notifications-search-add-new',
        type: 'button',
        name: 'Add new',
        onClick: onAddNew,
    },
];
