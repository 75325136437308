import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React, { useState } from 'react';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { questionStatusOptions } from 'static/status_options';
import { CustomDatePicker } from 'components/elements/datepicker/CustomDatePicker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';

export function ChangeStatusBulkModal({ modalOpen, setModalOpen, onAction, onClose, bulkQuestionSize }) {
    const [currentActionOption, setCurrentActionOption] = useState();
    const [dateAvailableFrom, setDateAvailableFrom] = useState(false);

    const preBulkActionQuestionsState = useSelector(state => state.content.preBulkActionQuestions);

    const { t } = useTranslation();

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onClose={onClose}
            onAction={() => {
                setModalOpen(false);
                onAction({ currentActionOption, dateAvailableFrom });
            }}
            title={t('Change status for {{bulkQuestionSize}} questions', { bulkQuestionSize: preBulkActionQuestionsState?.data?.itemsCount })}
            text={t(
                `Select the status you want to set for your chosen questions. Keep in mind that questions cannot be set to ‘Active’ if they don’t have all the necessary fields in the question editor filled. The system will notify you of the number of questions that cannot their status.`
            )}
            buttons={[
                {
                    title: t('Change status'),
                    color: colors.white,
                    disabled: !stateIsLoaded(preBulkActionQuestionsState),
                },
            ]}
            hasCancel={true}
            alert={
                <>
                    <CustomSelect
                        id="add-questions-category"
                        value={currentActionOption}
                        onChange={option => {
                            setCurrentActionOption(option);
                        }}
                        width={200}
                        options={questionStatusOptions}
                        placeholder={t('Select action')}
                    />
                    {currentActionOption?.value === 'INACTIVE' && (
                        <div style={{ marginTop: 20 }}>
                            <CustomDatePicker value={dateAvailableFrom} onChange={date => setDateAvailableFrom(date ? date : null)} border={false} />
                        </div>
                    )}
                </>
            }
        ></ModalDialog>
    );
}

ChangeStatusBulkModal.propTypes = {
    bulkQuestionSize: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
