import React from 'react';
import { colors } from 'style/colors';

export default function IndexComponent({ index }) {
    return (
        <div
            style={{
                color: colors.gray1,
                borderRadius: '100px',
                backgroundColor: colors.white,
                lineHeight: '14px',
                padding: '10px',
                fontSize: '18px',
                height: '30px',
                width: '30px',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            }}
        >
            <div>{index}</div>
        </div>
    );
}
