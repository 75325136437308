import React from 'react';

import Select from 'react-select';
import { colors } from 'style/colors';
import PropTypes from 'prop-types';

import Dropdown from 'assets/icons/filter-caret.svg';
import Close from 'assets/icons/filter-close.svg';

export function CustomSelect({
    id,
    value,
    onChange,
    options,
    placeholder,
    style,
    width,
    iconSelected,
    iconNotSelected,
    isClearable = true,
    backgroundColor,
}) {
    const ValueContainer = ({ children, ...props }) => {
        return (
            <div className="d-flex flex-row align-items-center">
                {iconSelected && value != '' && value && (
                    <img alt="IconSelected" height={20} width={20} style={{ marginLeft: '15px' }} src={iconSelected} />
                )}
                {iconNotSelected && (value == '' || !value) && (
                    <img alt="IconNotSelected" height={20} width={20} style={{ marginLeft: '15px' }} src={iconNotSelected} />
                )}
                <div style={{ marginLeft: '10px' }}>{children}</div>
            </div>
        );
    };

    const DropdownIndicator = props => {
        return <img alt="Dropdown" className="mr-2 cursor-pointer" src={Dropdown} height={17} width={17} />;
    };

    const ClearIndicator = props => {
        return <img alt="Close" className="mr-2 cursor-pointer" src={Close} height={17} width={17} onClick={props.clearValue} />;
    };

    const formatOptionLabel = ({ label, image }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {image && <img alt="Label" src={image} style={{ width: 15, height: 15, marginLeft: 5, marginRight: 5, color: '#ccc' }}></img>}
                <div>{label}</div>
            </div>
        );
    };

    return (
        <Select
            id={id}
            value={value}
            onChange={onChange}
            isClearable={isClearable}
            components={{
                IndicatorSeparator: () => null,
                ValueContainer,
                DropdownIndicator,
                ClearIndicator,
            }}
            formatOptionLabel={formatOptionLabel}
            options={options}
            placeholder={placeholder}
            style={style}
            styles={{
                control: styles => ({
                    ...styles,
                    border: `1px solid ${colors.gray2}`,
                    height: '40px',
                    backgroundColor: backgroundColor ?? colors.background,
                    boxShadow: 'none',
                    '&:hover': {
                        border: `1px solid ${colors.mainTheme}`,
                    },
                    width: width,
                    cursor: 'pointer',
                }),
                menu: styles => ({
                    ...styles,
                    width: width,
                    zIndex: 10,
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    return {
                        ...styles,
                        '&:active': {
                            backgroundColor: colors.mainTheme,
                        },
                        color: colors.dark,
                        backgroundColor: isFocused ? colors.gray3 : 'white',
                        zIndex: 10,
                    };
                },

                indicatorSeparator: (provided, state) => ({}),
            }}
        />
    );
}

CustomSelect.propTypes = {
    iconNotSelected: PropTypes.any,
    iconSelected: PropTypes.any,
    id: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.any,
    placeholder: PropTypes.any,
    style: PropTypes.any,
    value: PropTypes.any,
    width: PropTypes.any,
};
