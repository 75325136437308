import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { Subtitle2 } from 'style/typography/Subtitle';
import { useTranslation } from 'react-i18next';

const AddQuestionHeader = ({ onTabChange, tabs, position }) => {
    const {t} = useTranslation();

    const [value, setValue] = React.useState(0);
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onTabChange(newValue);
    };

    return (
        <Paper className={classes.paper}>
            <Tabs
                classes={{ root: classes.tabs, flexContainer: position === 'left' ? classes.leftTabsFlexContainer : classes.tabsFlexContainer }}
                value={value}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs &&
                    tabs.map(el => {
                        return <Tab id={el.replace(/\s+/g, '-').toLowerCase()} label={<Subtitle2 weight="medium">{t(el)}</Subtitle2>} />;
                    })}
            </Tabs>
        </Paper>
    );
};

AddQuestionHeader.propTypes = {
    onTabChange: PropTypes.func,
    position: PropTypes.string,
    tabs: PropTypes.array,
};

const useStyles = makeStyles(theme => {
    return {
        paper: {
            boxShadow: 'none',
            borderBottom: `1px solid ${theme.palette.border.separator}`,
            backgroundColor: theme.palette.background.main,
        },
        tabs: {},
        tabsFlexContainer: {
            justifyContent: 'center',
        },
        leftTabsFlexContainer: {
            justifyContent: 'left',
        },
    };
});

export default AddQuestionHeader;
