import React from 'react';
import { useTranslation } from 'react-i18next';
import 'style/games-preview.style.css';

const GameHeader = () => {
    const { t } = useTranslation();

    return (
        <div className="py-2 px-3 d-flex">
            <div style={{ flex: 1 }} className="d-flex justify-content-start">
                <div className="d-flex flex-column align-items-center">
                    <div className="games-header-level-label">{t('Level')}</div>
                    <div className="games-header-level-info-wrapper">
                        <span className="games-header-current-level-label">3</span>
                        <span className="games-header-total-level-label">/5</span>
                    </div>
                </div>
            </div>
            <div style={{ flex: 1 }} className="d-flex flex-column align-items-center">
                <div className="games-header-level-label">{t('Score')}</div>
                <div className="games-header-score-label">240</div>
            </div>
            <div style={{ flex: 1 }} className="d-flex justify-content-end">
                <div className="d-flex flex-column align-items-center">
                    <div className="games-header-level-label">{t('Time')}</div>
                    <div className="games-header-score-label">1:27</div>
                </div>
            </div>
        </div>
    );
};

export default GameHeader;
