import PropTypes from 'prop-types';
import React from 'react';
import { Body1, Body2 } from 'style/typography/Body';

import Info from 'assets/icons/info-tooltip.svg';
import { colors } from 'style/colors';
import InfoTooltip from 'components/elements/tooltips/InfoTooltip';
import { useTranslation } from 'react-i18next';

export default function DashboardStatisticContainer({ title, setHovering, hovering, infoString, child, onSeeMoreClick, maxHeight = 'auto' }) {
    const { t } = useTranslation();

    return (
        <div className="dashboard--statistic-container">
            <div className="dashboard--statistic-container-title">
                <Body1 weight="bold" color={colors.primaryText}>
                    {title}
                </Body1>
                <div>
                    <img
                        style={{ cursor: 'pointer' }}
                        alt="info"
                        src={Info}
                        height={20}
                        width={20}
                        onMouseEnter={() => setHovering(title)}
                        onMouseLeave={() => setHovering('')}
                    />
                    {hovering === title && <InfoTooltip visible={hovering === title} message={t(infoString)} position={'bottom'} />}
                </div>
                {onSeeMoreClick && (
                    <Body2 onClick={onSeeMoreClick} style={{ marginLeft: 'auto', cursor: 'pointer' }} color={colors.mainTheme}>
                        {t('See more')}
                    </Body2>
                )}
            </div>
            <div className="divider"></div>
            <div className="dashboard--statistic-child" style={{ maxHeight: maxHeight }}>
                {child && child}
            </div>
        </div>
    );
}

DashboardStatisticContainer.propTypes = {
    child: PropTypes.any,
    hovering: PropTypes.any,
    infoString: PropTypes.string,
    onSeeMoreClick: PropTypes.func,
    setHovering: PropTypes.func,
    title: PropTypes.string,
};
