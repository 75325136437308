import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
    addUserToTagAction,
    deleteTagByIdAction,
    getLabelByIdAction,
    getUsersWithoutTagWithIdAction,
    getUsersWithTagAction,
    removeUserFromTagAction,
    resetDeleteTagByIdAction,
    resetGetUsersWithoutTagWithIdAction,
    resetGetUsersWithTagAction,
    resetUpdateUserInTag,
} from 'redux/actions/tags.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { UserRow } from '../../roles/side_menu/users/UserRow';
import { OutlinedButton } from 'components/elements/Button';
import { DeleteTagDialog } from '../local_components/DeleteTagDialog';

import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';
import Trash from 'assets/icons/icon-delete.svg';
import Edit from 'assets/icons/icon-edit.svg';

import '../TagsTab.css';
import { useTranslation } from 'react-i18next';

export default function TagValueItem({ currentLabel, tagValue, setEditedTag, updatedTags }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [searchValueAssigned, setSearchValueAssigned] = useState('');
    const [searchValueUnassigned, setSearchValueUnassigned] = useState('');
    const [deleteTagDialogOpen, setDeleteTagDialogOpen] = useState('');

    const usersWithTagState = useSelector(state => state.content.usersWithTag);
    const usersWithoutTagWithIdState = useSelector(state => state.content.usersWithoutTagWithId);
    const addUserToTagState = useSelector(state => state.content.addUserToTag);
    const removeUserFromTagState = useSelector(state => state.content.removeUserFromTag);
    const deleteTagState = useSelector(state => state.content.deleteTagById);

    function addUserToTag(userId) {
        dispatch(addUserToTagAction(tagValue.tagId, userId));
    }

    function removeUserFromTag(userId) {
        dispatch(removeUserFromTagAction(tagValue.tagId, userId));
    }

    function getUsersWithTag() {
        if (stateIsLoaded(usersWithTagState) && usersWithTagState.data.tagId === tagValue.tagId) {
            dispatch(resetGetUsersWithTagAction());
            dispatch(resetGetUsersWithoutTagWithIdAction());
        } else {
            dispatch(getUsersWithTagAction(tagValue.tagId, { searchQuery: searchValueAssigned, page: 0, size: 10 }));
            dispatch(getUsersWithoutTagWithIdAction(tagValue.tagId, { searchQuery: searchValueUnassigned, page: 0, size: 10 }));
        }
    }

    function valueUpdatedWithoutTag(val) {
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(getUsersWithoutTagWithIdAction(tagValue.tagId, { searchQuery: val, page: 0, size: 10 }));
            },
            750,
            'getUsersWithoutTagPaginatedActionUseEffect'
        );
        return () => setTimeoutLastExecute.removeCallback('getUsersWithoutTagPaginatedActionUseEffect');
    }

    function valueUpdatedWithTag(val) {
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(getUsersWithTagAction(tagValue.tagId, { searchQuery: val, page: 0, size: 10 }));
            },
            750,
            'getUsersWithTagPaginatedActionUseEffect'
        );
        return () => setTimeoutLastExecute.removeCallback('getUsersWithTagPaginatedActionUseEffect');
    }

    function deleteTag() {
        dispatch(deleteTagByIdAction(tagValue.tagId));
    }

    function getMoreUsersWithTag() {
        dispatch(
            getUsersWithTagAction(tagValue.tagId, {
                searchQuery: searchValueAssigned,
                page: +usersWithTagState.data.page + 1,
                size: 10,
            })
        );
    }

    function getMoreUsersWithoutTag() {
        dispatch(
            getUsersWithoutTagWithIdAction(tagValue.tagId, {
                searchQuery: searchValueUnassigned,
                page: +usersWithoutTagWithIdState.data.page + 1,
                size: 10,
            })
        );
    }

    useEffect(() => {
        if ((stateIsLoaded(addUserToTagState) || stateIsLoaded(removeUserFromTagState)) && usersWithTagState?.data?.tagId === tagValue.tagId) {
            dispatch(getUsersWithTagAction(tagValue.tagId, { searchQuery: searchValueUnassigned, page: 0, size: 10 }));
            dispatch(getUsersWithoutTagWithIdAction(tagValue.tagId, { searchQuery: searchValueUnassigned, page: 0, size: 10 }));
            dispatch(resetUpdateUserInTag());
        }
    }, [addUserToTagState, removeUserFromTagState]);

    useEffect(() => {
        if (stateIsLoaded(deleteTagState)) {
            dispatch(getLabelByIdAction(currentLabel.labelId));
            dispatch(resetDeleteTagByIdAction());
        }
    }, [deleteTagState]);

    return (
        <div>
            <DeleteTagDialog modalOpen={deleteTagDialogOpen} setModalOpen={setDeleteTagDialogOpen} onAction={deleteTag} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div className="tags--value-item" onClick={() => getUsersWithTag()}>
                    <div className="tags--value-item-title">
                        <Body2 style={{ textOverflow: 'ellipsis', overflowWrap: 'break-word', overflow: 'hidden' }}>
                            {updatedTags?.[tagValue.tagId] ? updatedTags?.[tagValue.tagId] : tagValue.tag}
                        </Body2>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img
                            alt="Show more"
                            height={15}
                            width={15}
                            style={{ cursor: 'pointer' }}
                            src={stateIsLoaded(usersWithTagState) && usersWithTagState.data.tagId === tagValue.tagId ? AngleUp : AngleDown}
                        />
                        <img
                            alt="Edit"
                            height={15}
                            width={15}
                            style={{ cursor: 'pointer', marginLeft: 10, marginBottom: 5 }}
                            onClick={e => {
                                e.stopPropagation();
                                setEditedTag(tagValue.tagId);
                            }}
                            src={Edit}
                        />
                    </div>
                </div>

                <img
                    alt="Delete"
                    height={15}
                    width={15}
                    style={{ cursor: 'pointer', marginLeft: 3, marginBottom: 15 }}
                    onClick={() => setDeleteTagDialogOpen(true)}
                    src={Trash}
                />
            </div>
            {stateIsLoaded(usersWithTagState) && usersWithTagState.data.tagId === tagValue.tagId && (
                <div className="tags--value-item-body">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Caption1 className={'mb-2'} color={colors.gray6}>
                            {t('USERS IN TAG')}
                        </Caption1>
                        <Caption1 color={colors.gray6} style={{ marginLeft: 5 }}>{`  (${usersWithTagState.data.totalUsers} ${t('users')})`}</Caption1>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <CustomOutlinedTextField
                            key={'searchValueAssigned'}
                            placeholder={t('Search')}
                            value={searchValueAssigned}
                            setValue={val => {
                                setSearchValueAssigned(val);
                                valueUpdatedWithTag(val);
                            }}
                            isSearch={true}
                            width={'100%'}
                        />
                    </div>
                    {stateIsLoaded(usersWithTagState) &&
                        usersWithTagState.data?.['users'] &&
                        usersWithTagState.data?.['users'].map((el, index) => {
                            return (
                                <UserRow
                                    user={{ name: el.firstName, lastName: el.lastName }}
                                    first={index === 0}
                                    isAssigned={true}
                                    checkActive={false}
                                    onRemove={() => {
                                        removeUserFromTag(el.userId);
                                    }}
                                />
                            );
                        })}

                    <div className="tags--value-item-empty-container">
                        {stateIsLoaded(usersWithTagState) && usersWithTagState.data?.['users']?.length == 0 && (
                            <Caption1 color={colors.gray6}>{t('No users')}</Caption1>
                        )}
                        {stateIsLoaded(usersWithTagState) && !(usersWithTagState?.data?.page + 1 >= usersWithTagState?.data?.totalPages) && (
                            <OutlinedButton className={'my-2 cursor-pointer'} onClick={getMoreUsersWithTag}>
                                <Body2 color={colors.primaryText} weight="medium">
                                    {t('Show more')}
                                </Body2>
                            </OutlinedButton>
                        )}
                    </div>
                    <Caption1 className={'my-2'} color={colors.gray6}>
                        {t('OTHER USERS')}
                    </Caption1>
                    <div style={{ marginBottom: '10px' }}>
                        <CustomOutlinedTextField
                            key={'searchValueUnassigned'}
                            placeholder={t('Search')}
                            value={searchValueUnassigned}
                            setValue={val => {
                                setSearchValueUnassigned(val);
                                valueUpdatedWithoutTag(val);
                            }}
                            isSearch={true}
                            width={'100%'}
                        />
                    </div>
                    {stateIsLoaded(usersWithoutTagWithIdState) &&
                        usersWithoutTagWithIdState.data?.['users'] &&
                        usersWithoutTagWithIdState.data?.['users'].map((el, index) => {
                            return (
                                <UserRow
                                    key={tagValue.tagId + el.userId}
                                    user={{ name: el.firstName, lastName: el.lastName }}
                                    first={index === 0}
                                    isAssigned={false}
                                    checkActive={false}
                                    onAdd={() => {
                                        addUserToTag(el.userId);
                                    }}
                                />
                            );
                        })}

                    <div className="tags--value-item-empty-container">
                        {stateIsLoaded(usersWithoutTagWithIdState) && usersWithoutTagWithIdState.data?.['users'].length === 0 && (
                            <Caption1 color={colors.gray6}>{t('No users')}</Caption1>
                        )}
                        {stateIsLoaded(usersWithoutTagWithIdState) &&
                            !(usersWithoutTagWithIdState?.data?.page + 1 >= usersWithoutTagWithIdState?.data?.totalPages) && (
                                <OutlinedButton className={'my-2 cursor-pointer'} onClick={getMoreUsersWithoutTag}>
                                    <Body2 color={colors.primaryText} weight="medium">
                                        {t('Show more')}
                                    </Body2>
                                </OutlinedButton>
                            )}
                    </div>
                </div>
            )}
        </div>
    );
}

TagValueItem.propTypes = {
    currentLabel: PropTypes.any,
    setEditedTag: PropTypes.func,
    tagValue: PropTypes.any,
    updatedTags: PropTypes.any,
};
