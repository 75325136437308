import { ApiExecutor } from './ApiExecutor';
import {
    updateFeedbackApiRequest,
    deleteFeedbackApiRequest,
    getSpecificFeedbackApiRequest,
    getFeedbackContentPaginatedApiRequest,
    getFeedbackPositiveApiRequest,
    resolveFeedbackBulkApiRequest,
    unresolveFeedbackBulkApiRequest,
    deleteFeedbackBulkApiRequest,
    getFeedbackPositivePaginatedApiRequest,
    preBulkActionFeedbacksApiRequest,
} from 'services/api/apiRequests/content';

import {
    GET_FEEDBACK,
    UPDATE_FEEDBACK,
    DELETE_FEEDBACK,
    GET_SPECIFIC_FEEDBACK,
    GET_FEEDBACK_CONTENT_PAGINATED,
    GET_FEEDBACK_POSITIVE_PAGINATED,
    RESOLVE_FEEDBACK_BULK,
    UNRESOLVE_FEEDBACK_BULK,
    DELETE_FEEDBACK_BULK,
    PRE_BULK_ACTION_FEEDBACK,
} from '../constants/content.constants';

import { buildActionType } from './buildActionType';
import { ActionStatus } from '../core/ActionStatus';
import _ from 'lodash';

export const getFeedbackContentPaginatedAction = queryData => async dispatch => {
    await dispatch(ApiExecutor(getFeedbackContentPaginatedApiRequest(queryData), GET_FEEDBACK_CONTENT_PAGINATED));
};
export const getFeedbackPositivePaginatedActon = queryData => async dispatch => {
    await dispatch(ApiExecutor(getFeedbackPositivePaginatedApiRequest(queryData), GET_FEEDBACK_POSITIVE_PAGINATED));
};

export const resetGetFeedbackPaginatedAction = () => async dispatch => {
    await dispatch({ type: buildActionType(GET_FEEDBACK_CONTENT_PAGINATED, ActionStatus.RESET) });
    await dispatch({ type: buildActionType(GET_FEEDBACK_POSITIVE_PAGINATED, ActionStatus.RESET) });
};

export const updateFeedbackAction = feedback => async (dispatch, getState) => {
    let state = _.cloneDeep(getState().content.allFeedback);

    dispatch({ type: buildActionType(UPDATE_FEEDBACK, ActionStatus.START) });

    let response = await updateFeedbackApiRequest(feedback.feedbackId, !feedback.isResolved ? feedback.resolverComment : '');

    if (response.success) {
        dispatch({
            type: buildActionType(UPDATE_FEEDBACK, ActionStatus.DONE),
            payload: response.data,
        });

        let allFeedback = state.data;
        let index = allFeedback.indexOf(feedback);

        feedback.isResolved = !feedback.isResolved;
        feedback.status = feedback.isResolved ? 'Resolved ' + new Date().toISOString().slice(0, 10) : 'Unresolved';
        feedback.resolverComment = feedback.isResolved ? feedback.resolverComment : '';
        allFeedback[index] = feedback;

        await dispatch({
            type: buildActionType(GET_FEEDBACK, ActionStatus.DONE),
            payload: [...allFeedback],
        });
    }
};

export const resolveFeedbackBulkAction = (feedbackIds, data) => async dispatch => {
    await dispatch(ApiExecutor(resolveFeedbackBulkApiRequest(feedbackIds, data), RESOLVE_FEEDBACK_BULK));
};

export const unresolveFeedbackBulkAction = (feedbackIds, data) => async dispatch => {
    await dispatch(ApiExecutor(unresolveFeedbackBulkApiRequest(feedbackIds, data), UNRESOLVE_FEEDBACK_BULK));
};

export const deleteFeedbackBulkAction = (feedbackIds, data) => async dispatch => {
    await dispatch(ApiExecutor(deleteFeedbackBulkApiRequest(feedbackIds, data), DELETE_FEEDBACK_BULK));
};

export const resetFeedbackBulkActions = () => async dispatch => {
    dispatch({
        type: buildActionType(RESOLVE_FEEDBACK_BULK, ActionStatus.RESET),
    });
    dispatch({
        type: buildActionType(UNRESOLVE_FEEDBACK_BULK, ActionStatus.RESET),
    });
    dispatch({
        type: buildActionType(DELETE_FEEDBACK_BULK, ActionStatus.RESET),
    });
};

export const deleteFeedbackAction = feedback => async (dispatch, getState) => {
    let state = _.cloneDeep(getState().content.allFeedback);

    dispatch({ type: buildActionType(DELETE_FEEDBACK, ActionStatus.START) });

    let response = await deleteFeedbackApiRequest(feedback.feedbackId);

    if (response.success) {
        dispatch({ type: buildActionType(DELETE_FEEDBACK, ActionStatus.DONE) });

        let allFeedback = state.data.filter(f => f.feedbackId !== feedback.feedbackId);

        await dispatch({
            type: buildActionType(GET_FEEDBACK, ActionStatus.DONE),
            payload: [...allFeedback],
        });
    }
};

export const getSpecificFeedbackAction = feedbackId => async dispatch => {
    await dispatch(ApiExecutor(getSpecificFeedbackApiRequest(feedbackId), GET_SPECIFIC_FEEDBACK));
};

export const resetUpdateFeedbackState = () => async dispatch => {
    await dispatch({ type: buildActionType(UPDATE_FEEDBACK, ActionStatus.RESET) });
};
export const resetDeleteFeedbackState = () => async dispatch => {
    await dispatch({ type: buildActionType(DELETE_FEEDBACK, ActionStatus.RESET) });
};

export const updateFeedbackResolverComment = data => async (dispatch, getState) => {
    const state = getState().content?.feedbackView?.data;

    await dispatch({
        type: buildActionType(GET_SPECIFIC_FEEDBACK, ActionStatus.DONE),
        payload: {
            ...state,
            resolverComment: data,
        },
    });
};

export const preBulkActionFeedbacksAction = (feedbackIds, data) => async dispatch => {
    await dispatch(ApiExecutor(preBulkActionFeedbacksApiRequest(feedbackIds, data), PRE_BULK_ACTION_FEEDBACK));
};
export const resetPreBulkActionFeedbacksAction = () => async dispatch => {
    await dispatch({ type: buildActionType(PRE_BULK_ACTION_FEEDBACK, ActionStatus.RESET) });
};
