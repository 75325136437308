import React, { useState } from 'react';

import { colors } from 'style/colors';
import { Body1, Body2 } from 'style/typography/Body';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Caption1 } from 'style/typography/Caption';

import GamesIconEmpty from 'assets/icons/dashboard-games-empty.svg';

import './DashStatistics.css';

export default function GamesStatisticsDashboard() {
    const { t } = useTranslation();

    const dashboardStatsState = useSelector(state => state.content.dashboardStats);

    const [gamePage, setGamePage] = useState(0);

    if (!dashboardStatsState.data?.gameplayCountsByGame) {
        return (
            <div className="dash-s--empty-container">
                <img alt="empty-icon" src={GamesIconEmpty} height={60} width={60} />
                <Body1 color={colors.gray6}>
                    {t(
                        'To see detailed insights on the number of questions, their accuracy and performance, start creating or import them in the editor.'
                    )}
                </Body1>
            </div>
        );
    }

    return (
        <div className="dash-s--games-container">
            <div className="dash-s--games-switcher">
                <div className={gamePage === 0 ? 'dash-s--games-tab-selected' : 'dash-s--games-tab-not-selected'} onClick={() => setGamePage(0)}>
                    <Body2 color={gamePage === 0 ? colors.dark : colors.gray6}>{t('Most played')}</Body2>
                </div>
                <div className={gamePage === 1 ? 'dash-s--games-tab-selected' : 'dash-s--games-tab-not-selected'} onClick={() => setGamePage(1)}>
                    <Body2 color={gamePage === 1 ? colors.dark : colors.gray6}>{t('Hardest games')}</Body2>
                </div>
            </div>
            <div className="dash-s--games-items">
                <div className="dash-s--games-header">
                    <Caption1 color={colors.gray1}>{t('game')}</Caption1>
                    <Caption1 color={colors.gray1}>{gamePage === 0 ? t('game plays') : t('game fails')}</Caption1>
                </div>
                {gamePage === 0 &&
                    dashboardStatsState.data?.gameplayCountsByGame.slice(0, 4)?.map(gameplay => {
                        return (
                            <div className="dash-s--games-item">
                                <img
                                    alt={'game-icon'}
                                    style={{ border: `1px solid ${colors.gray3}`, borderRadius: '100px', padding: '2px' }}
                                    width={'35px'}
                                    src={gameplay.gameIcon}
                                ></img>
                                <div className="dash-s--games-slug">
                                    <Body1 color={colors.primaryText}>
                                        {t(gameplay.gameSlug.charAt(0).toUpperCase() + gameplay.gameSlug.replace('-', ' ').slice(1))}
                                    </Body1>
                                    <div className="dash-s--games-indicator">
                                        <div
                                            className="dash-s--games-indicator-fill"
                                            style={{
                                                maxWidth:
                                                    Math.round(
                                                        (gameplay.gamePlays / dashboardStatsState.data.gameplayCountsByGame[0].gamePlays) * 100
                                                    ) + '%',
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <Body2 weight="bold" color={colors.primaryText}>
                                    {gameplay.gamePlays.toString()}
                                </Body2>
                            </div>
                        );
                    })}
                {gamePage === 1 &&
                    dashboardStatsState.data?.failRatesByGame.slice(0, 4)?.map(failRate => {
                        return (
                            <div className="dash-s--games-item">
                                <img
                                    alt={'game-icon'}
                                    style={{ border: `1px solid ${colors.gray3}`, borderRadius: '100px', padding: '2px' }}
                                    width={'35px'}
                                    src={failRate.gameIcon}
                                ></img>
                                <div className="dash-s--games-slug">
                                    <Body1 color={colors.primaryText}>
                                        {t(failRate.gameSlug.charAt(0).toUpperCase() + failRate.gameSlug.replace('-', ' ').slice(1))}
                                    </Body1>
                                    <div className="dash-s--games-indicator">
                                        <div
                                            className="dash-s--games-indicator-fill"
                                            style={{
                                                maxWidth: Math.round(failRate.incorrectPercentage * 100) + '%',
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <Body2 weight="bold" color={colors.primaryText}>
                                    {Math.round(failRate.incorrectPercentage * 100) + '%'}
                                </Body2>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
