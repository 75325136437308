import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import PropTypes from 'prop-types';

import Calendar from 'assets/icons/filter-date.svg';
import Close from 'assets/icons/filter-close.svg';

import './CustomDateRangePicker.css';
function CustomDateRangePicker({ border, value, format, onChange, showDoubleView, className, minDate = null }) {
    return (
        <DateRangePicker
            className={border ? 'border_calendar ' : '' + className}
            value={value}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            format={format}
            showLeadingZeros={true}
            onChange={onChange}
            minDate={minDate}
            showDoubleView={showDoubleView}
            calendarIcon={<img alt="Calendar" src={Calendar} height={20} width={20} />}
            clearIcon={<img alt="Calendar" src={Close} height={20} width={20} />}
        ></DateRangePicker>
    );
}

CustomDateRangePicker.propTypes = {
    border: PropTypes.bool,
    value: PropTypes.any,
    format: PropTypes.any,
    onChnage: PropTypes.func,
    showDoubleView: PropTypes.bool,
};

export { CustomDateRangePicker };
