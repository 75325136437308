import React from 'react';

import UserDeviceEmptyIcon from 'assets/icons/emptystate-device-requests.svg';
import { H6 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';

export default function UserDeviceEmptyState() {
    const { t } = useTranslation();
    return (
        <div className="user-device-requests--empty">
            <img src={UserDeviceEmptyIcon} height={80} width={80} />
            <H6 color={colors.secoundaryText} className={'mt-4'}>
                {t('No device requests yet')}
            </H6>
            <Body2 style={{ width: '40%', margin: '10px 0' }} color={colors.gray6}>
                {t(
                    'You haven’t received any device requests yet. Once a user in the app requests to log in from another different device, you will be able to see the request here and manage it accordingly (with the approve or reject buttons).'
                )}
            </Body2>
        </div>
    );
}
