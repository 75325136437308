import React from 'react';
import ContentLoader from 'react-content-loader';

export const SkeletonLoader = props => {
    const random = Math.random() * (1 - 0.7) + 0.7;
    return (
        <ContentLoader height={40} width={'100%'} speed={props?.speed || 1} {...props}>
            <rect x="0" y="13" rx="6" ry="6" width={random * 100 + '%'} height="12" />
        </ContentLoader>
    );
};
