import React, { useState } from 'react';

import { OutlinedButton } from 'components/elements/Button';
import { Body1, Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { ResendInviteUserModal } from '../../local_widgets/ResendInviteUser';
import { formatDateOnly } from 'services/utils/stringHelpers';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import '../ProfileSettings.css';

export default function ProfileResendInviteSetting() {
    const { t } = useTranslation();

    const editUser = useSelector(state => state.userManagement.editingUser);

    const [isResendInvitesModalOpen, setIsResendInvitesModalOpen] = useState(false);

    return (
        <div className="profile-invite-setting">
            {isResendInvitesModalOpen && <ResendInviteUserModal modalOpen={isResendInvitesModalOpen} setModalOpen={setIsResendInvitesModalOpen} />}
            <div className="profile-invite-setting--label">
                <Body1>{t('Resend invite')}</Body1>
                <Body2 color={colors.gray6}>
                    <Trans>
                        {t('Invite sent on <strong>{{lastInvite}}</strong>', { lastInvite: formatDateOnly(new Date(editUser.data.dateInvited)) })}
                    </Trans>
                </Body2>
            </div>
            <OutlinedButton text={t('Resend Invite')} onClick={() => setIsResendInvitesModalOpen(true)} />
        </div>
    );
}
