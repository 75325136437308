import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { colors } from 'style/colors';
import { Body1 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';

export function Info() {
    const setInfo = useSelector(state => state.content.singleTraining.data.learningSetInfo);

    const {t} = useTranslation();

    return (
        <div>
            {setInfo?.createdBy && (
                <div className={'d-flex flex-column mt-4'}>
                    <Caption1 color={colors.gray6} weight="medium">
                        {t("CREATED BY")}
                    </Caption1>
                    <Body1 color={colors.dark}>{setInfo?.createdBy}</Body1>
                </div>
            )}
            {setInfo?.dateCreated && (
                <div className={'d-flex flex-column mt-3'}>
                    <Caption1 color={colors.gray6} weight="medium">
                        {t("DATE CREATED")}
                    </Caption1>
                    <div className="d-flex flex-row">
                        <Body1 color={colors.dark}>{setInfo.dateCreated}</Body1>
                        <Body1 color={colors.descriptionText} className={'ml-1'}>
                            {setInfo.timeCreated}
                        </Body1>
                    </div>
                </div>
            )}
            {setInfo?.lastModifiedDate && (
                <div className={'d-flex flex-column mt-3'}>
                    <Caption1 color={colors.gray6} weight="medium">
                        {t("LAST MODIFIED")}
                    </Caption1>
                    <div className="d-flex flex-row">
                        <Body1 color={colors.dark}>{setInfo?.lastModifiedDate}</Body1>
                        <Body1 color={colors.descriptionText} className={'ml-1'}>
                            {setInfo?.lastModifiedTime}
                        </Body1>
                    </div>
                </div>
            )}
            {/* {setInfo?.questions && (
                <div className={'d-flex flex-column mt-3'}>
                    <Caption1 color={colors.gray6} weight="medium">
                        TOTAL QUESTIONS IN TRAINING
                    </Caption1>
                    <Body1 color={colors.dark}>{setInfo?.questions}</Body1>
                </div>
            )}
            {setInfo?.topics && (
                <div className={'d-flex flex-column mt-3'}>
                    <Caption1 color={colors.gray6} weight="medium">
                        TOTAL TOPICS
                    </Caption1>
                    <Body1 color={colors.dark}>{setInfo?.topics}</Body1>
                </div>
            )} */}
        </div>
    );
}
