import React, { useEffect, useRef, useState } from 'react';

import SortableTree from 'react-sortable-tree';
import { colors } from 'style/colors';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { useDispatch, useSelector } from 'react-redux';
import Folder from 'assets/icons/topic-icon-folder.svg';
import File from 'assets/icons/topic-icon-paper.svg';
import Thrash from 'assets/icons/icon-delete.svg';
import { TreeErrorDialog } from 'components/features/trees/TreeErrorDialog.js';
import { Body1 } from 'style/typography/Body';
import {
    getTopicContentAction,
    moveNode,
    removeNode,
    saveTopicsFolderStructure,
    setTopicsTree,
    updateNodeExpanded,
    updateNodeTitle,
} from 'redux/actions/topics.actions';
import { changeExpand } from 'services/utils/treeHelpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ArrowDown from 'assets/icons/arrow-down.svg';

import './TopicsFolderStructure.css';

export function TopicsFolderStructure({ data, search, currentNode, setCurrentNode, saveInitiated, emptyTree, setSaveInitiated }) {
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [searchResult, setSearchResult] = useState(null);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');
    const [searchParams, setSearchParams] = useState({
        searchString: '',
        searchFocusIndex: 0,
        searchFoundCount: null,
    });

    const newInputRef = useRef(null);
    const searchResultRef = useRef(null);

    const { searchString, searchFocusIndex } = searchParams;

    function handleNodeClick(e, nodeObj, node, path) {
        if (e.target.tagName.toUpperCase() === 'INPUT') {
            return;
        }
        if (nodeObj.topic) {
            if (!nodeObj.generatedTreeIndex || nodeObj.generatedTreeIndex != nodeObj.treeIndex) {
                dispatch(getTopicContentAction(nodeObj.treeIndex));
            }
            setCurrentNode(nodeObj);
            history.push({
                pathname: `/content/topics`,
                search: `?topicId=${nodeObj.treeIndex}`,
            });
        } else {
            changeExpand(
                treeData => dispatch(setTopicsTree(treeData, null)),
                data,
                node,
                path,
                (node, expanded) => dispatch(updateNodeExpanded(node, expanded))
            );
        }
    }

    useEffect(() => {
        if (saveInitiated.initiated === true) {
            if (data.length > 0) {
                dispatch(saveTopicsFolderStructure(data));
            } else {
                setErrorDialogMessage(t('No data found in tree!'));
                setErrorDialogOpen(true);
                setSaveInitiated({ initiated: false, force: false });
            }
        }
    }, [saveInitiated]);

    useEffect(() => {
        setSearchParams({
            ...searchParams,
            searchString: search,
        });
    }, [search]);

    useEffect(() => {
        if (newInputRef !== null && newInputRef.current !== null) {
            newInputRef.current.focus();
        }
    }, [newInputRef.current]);

    const customSearchMethod = ({ node, searchQuery }) => searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

    const nodePropsProvider = (node, path, treeIndex) => {
        let nodeObj = node;

        if (localTopicChangesState?.data[node.treeIndex]?.treeData !== undefined) {
            nodeObj = localTopicChangesState?.data[node.treeIndex]?.treeData;
        }

        let isCurrent = nodeObj.treeIndex === currentNode?.treeIndex;

        let nodeHeight = Math.ceil((nodeObj?.title?.length ?? 40) / 40) * 40;
        return {
            style: {
                background: isCurrent ? colors.mainThemeFaded : colors.gray3,
                border:
                    (search.length > 0 && searchResult?.treeIndex === nodeObj.treeIndex) || isCurrent
                        ? `1px solid ${colors.mainTheme}`
                        : '1px solid transparent',
                width: '90%',
                maxWidth: '90%',
                height: nodeHeight,
                borderRadius: '3px',
                paddingTop: 3,
                paddingBottom: 3,
                cursor: 'pointer',
                wordWrap: 'break-word',
                minWidth: 0,
                whiteSpace: 'initial',
                alignItems: 'center',
            },
            onClick: e => {
                handleNodeClick(e, nodeObj, node, path);
            },
            buttons: [
                <div
                    ref={nodeObj.treeIndex === searchResult?.treeIndex ? searchResultRef : null}
                    style={{ minWidth: 100, position: 'relative', left: 50 }}
                    className="d-flex flex-row col-9"
                >
                    <img
                        alt="Delete"
                        id={'delete-' + treeIndex}
                        style={{ width: 17, height: 17 }}
                        key={nodeObj.treeIndex}
                        onClick={event => {
                            event.stopPropagation();

                            if (nodeObj.topic && currentNode && currentNode.treeIndex == nodeObj.treeIndex) {
                                history.push({
                                    pathname: `/content/topics`,
                                });
                                setCurrentNode(null);
                            }
                            dispatch(removeNode(nodeObj));
                        }}
                        src={Thrash}
                    />
                </div>,
            ],
            icons: [
                null,
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', left: !nodeObj.topic ? -15 : 0 }}>
                    {!nodeObj.topic && (
                        <img
                            alt="arrow"
                            src={ArrowDown}
                            height={20}
                            width={20}
                            style={{ transform: `rotateZ(${nodeObj.expanded ? '0deg' : '-90deg'})` }}
                        />
                    )}
                    <img
                        alt={!nodeObj.topic ? 'Folder' : 'File'}
                        src={!nodeObj.topic ? Folder : File}
                        style={
                            nodeObj.topic
                                ? {
                                      width: 40,
                                      height: nodeHeight,
                                      marginRight: 10,
                                      marginLeft: -5,
                                      paddingLeft: 15,
                                      borderLeft: `solid 1px ${colors.gray2}`,
                                  }
                                : { width: 20, height: nodeHeight, marginLeft: 10, marginRight: 0 }
                        }
                    />
                </div>,
            ],
            title: (
                <div
                    style={{ position: 'relative', left: -6, width: '100%', maxWidth: '100%' }}
                    className={'ml-2 align-items-center'}
                    id={'row-folder-structure-' + treeIndex}
                >
                    <div>
                        {nodeObj.topic === true && (
                            <Body1 weight={isCurrent ? 'bold' : 'regular'} color={colors.dark} className={'mb-0'}>
                                {nodeObj.title ? nodeObj.title : ''}
                            </Body1>
                        )}
                        {nodeObj.topic !== true && (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 3, alignItems: 'center' }}>
                                <input
                                    key={nodeObj.treeIndex}
                                    id="input-text-topics"
                                    style={{
                                        fontSize: 16,
                                        height: '90%',
                                        border: 0,
                                        outline: 0,
                                        backgroundColor: colors.transparent,
                                        color: colors.dark,
                                        position: 'relative',
                                        left: -5,
                                    }}
                                    ref={path[0] === 0 ? newInputRef : null}
                                    size={nodeObj.title?.length ? nodeObj.title.length : 0}
                                    defaultValue={nodeObj.title ? nodeObj.title : ''}
                                    placeholder={nodeObj.isNew ? t('enter name') : ''}
                                    onChange={event => {
                                        let title = event.target.value;
                                        dispatch(updateNodeTitle(node, title));
                                    }}
                                />
                                <Body1 color={colors.gray6} style={{ position: 'relative', left: -2 }}>
                                    ({nodeObj?.children?.length})
                                </Body1>
                            </div>
                        )}
                    </div>
                </div>
            ),
        };
    };

    return (
        <div id={'topics-folder-structure'} style={{ height: '65vh', marginTop: 2, marginBottom: 3 }}>
            <TreeErrorDialog modalOpen={errorDialogOpen} setModalOpen={setErrorDialogOpen} message={errorDialogMessage} />
            <SortableTree
                style={{
                    maxHeight: '65vh',
                    overflow: 'scroll',
                    marginBottom: 3,
                    marginTop: 3,
                    paddingLeft: 25,
                    maxWidth: '35vw',
                    overflowX: 'hidden',
                }}
                className="ml-3"
                rowHeight={node => {
                    let titleSize = 1;
                    if (localTopicChangesState?.data[node.node.treeIndex]?.treeData !== undefined) {
                        titleSize = localTopicChangesState?.data[node.node.treeIndex]?.treeData.title?.length;
                    } else {
                        titleSize = node.node?.title?.length;
                    }
                    return Math.ceil((titleSize ?? 4) / 40) * 40;
                }}
                treeData={data}
                onChange={treeData => {
                    dispatch(setTopicsTree(treeData, null));
                }}
                onMoveNode={data => dispatch(moveNode(data))}
                isVirtualized={false}
                searchMethod={customSearchMethod}
                searchQuery={searchString}
                searchFinishCallback={matches => {
                    setSearchParams({
                        searchFoundCount: matches.length,
                        searchFocusIndex: matches.length > 0 ? searchFocusIndex % matches.length : 0,
                    });

                    if (matches.length > 0) {
                        setSearchResult(matches[0].node);

                        if (searchResultRef !== null && searchResultRef.current !== null) {
                            searchResultRef.current.scrollIntoView({ block: 'start' });
                        }
                    }
                }}
                theme={FileExplorerTheme}
                canNodeHaveChildren={node => node.topic !== true}
                expandable={true}
                getNodeKey={node => {
                    let currentNode = node.node ? node.node : node;
                    return currentNode.treeIndex ? currentNode.treeIndex : currentNode.generatedTreeIndex;
                }}
                generateNodeProps={({ node, path, treeIndex }) => {
                    return nodePropsProvider(node, path, treeIndex);
                }}
            />
        </div>
    );
}
