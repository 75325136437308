import PropTypes from 'prop-types';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React from 'react';
import { useSelector } from 'react-redux';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';

export default function DeleteNewsModal({ deleteModalOpen, setDeleteModalOpen, onAction, onClose }) {
    const { t } = useTranslation();
    const deleteNewsState = useSelector(state => state.content.deleteNews);

    return (
        <ModalDialog
            title={t('Delete this news post?')}
            modalOpen={deleteModalOpen}
            setModalOpen={setDeleteModalOpen}
            onAction={onAction}
            onClose={onClose}
            buttons={[
                {
                    title: t('Delete'),
                    color: colors.white,
                },
            ]}
            text={t(
                "If you delete this news post, you won't be able to see it again. If the post is active at the time of deletion, the inteded users may not be able to see it anymore once you delete it."
            )}
            hasCancel={true}
            state={deleteNewsState}
        ></ModalDialog>
    );
}

DeleteNewsModal.propTypes = {
    deleteModalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setDeleteModalOpen: PropTypes.func,
};
