import React from 'react';
import TrafficAnswer from './TrafficAnswer';

export default function TrafficLines({ answers }) {
    const lineTop = answers.slice(0, 7);
    const lineCenter = answers.slice(7, 14);
    const lineBottom = answers.slice(14, 21);

    return (
        <>
            <div
                className="d-flex traffic-top-line"
                id="traffic-top-line-id"
                style={{ flexWrap: 'nowrap', transform: 'translateX(calc(-80% + 220px))' }}
            >
                {lineTop?.map((ans, index) => (
                    <TrafficAnswer key={`top-line-${index}-${ans?.id}`} id={`top-line-${index}`} correct={ans?.isCorrect}>
                        {ans?.text || 'OPTION'}
                    </TrafficAnswer>
                ))}
            </div>
            <div className="d-flex mt-2 traffic-center-line" style={{ flexWrap: 'nowrap', transform: 'translateX(0)' }}>
                {lineCenter?.map((ans, index) => (
                    <TrafficAnswer key={`center-line-${index}-${ans?.id}`} id={`center-line-${index}`} correct={ans?.isCorrect}>
                        {ans?.text || 'OPTION'}
                    </TrafficAnswer>
                ))}
            </div>
            <div className="d-flex mt-2 traffic-bottom-line" style={{ flexWrap: 'nowrap', transform: 'translateX(calc(-80% + 220px))' }}>
                {lineBottom?.map((ans, index) => (
                    <TrafficAnswer key={`bottom-line-${index}-${ans?.id}`} id={`bottom-line-${index}`} correct={ans?.isCorrect}>
                        {ans?.text || 'OPTION'}
                    </TrafficAnswer>
                ))}
            </div>
        </>
    );
}
