export const actionTypes = {
    setImageFiles: 'SET_IMAGE_FILES',
    setCurrentActiveIndex: 'SET_CURRENT_ACTIVE_INDEX',
    setAspectRatio: 'SET_ASPECT_RATIO',
    setCropAndZoom: 'SET_CROP_AND_ZOOM',
    setCroppedAreaPixels: 'SET_CROPPED_AREA_PIXELS',
    setCroppedImage: 'SET_CROPPED_IMAGE',
    deleteCurrentImage: 'DELETE_CURRENT_IMAGE',
    setIndex: 'SET_INDEX',
    setView: 'SET_VIEW',
    setCurrentActivePreviewImage: 'SET_CURRENT_ACTIVE_PREVIEW_IMAGE',
    setImageLimit: 'SET_IMAGE_LIMIT',
    setImageResizerOpener: 'SET_IMAGE_RESIZER_OPENER',
    setCurrentTab: 'SET_TAB',
    setTreeData: 'SET_TREE_DATA',
    setCurrentNode: 'SET_CURRENT_NODE',
    setCurrentPath: 'SET_CURRENT_PATH',
    setPaginationTotalPages: 'SET_PAGINATION_TOTAL_PAGES',
    setCurrentPage: 'SET_CURRENT_PAGE',
    setGalleryImages: 'SET_GALLERY_IMAGES',
    setFilteredImages: 'SET_FILTERED_IMAGES',
    setSelectedImages: 'SET_SELECTED_IMAGES',
    setImageSearchValue: 'SET_IMAGE_SEARCH_VALUE',
    setSize: 'SET_SIZE',
    updateSelectedImage: 'UPDATE_SELECTED_IMAGE',
    setIsGrid: 'SET_IS_GRID',
    setSortBy: 'SET_SORT_BY',
    setAllImagesSelected: 'SET_ALL_IMAGES_SELECTED',
};
