import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

export default function NoTableValue() {
    const { t } = useTranslation();

    return (
        <Body2
            style={{
                padding: '3px 10px',
                borderRadius: 5,
                backgroundColor: colors.background,
            }}
            color={colors.gray1}
        >
            {t('Not Set_')}
        </Body2>
    );
}
