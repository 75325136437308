import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import GalleryFolders from 'components/features/gallery/folders/GalleryFolders';
import NewFolderModal from 'components/features/gallery/folders/NewFolderModal';
import GalleryPreviewImages from 'components/features/gallery/GalleryPreviewImages';
import GallerySubheader from 'components/features/gallery/subheader/GallerySubheader';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { useImageLibraryHelper } from 'services/image_management/imageLibraryHelper';

import './SortableTreeCustom.css';

export function ImageLibrarySpace({ addImage, addMultipleImages, onImageUploaded, onImagesUploadCallback, index }) {
    const { saveTree } = useImageLibraryHelper();

    const [folderModalOpen, setFolderModalOpen] = useState(false);
    const { treeData } = useImageEditor();

    const toSave = useRef(false);

    useEffect(() => {
        if (toSave.current) {
            toSave.current = false;
            saveTree();
        }
    }, [treeData]);

    return (
        <div className="d-flex flex-row" style={{ minHeight: '65vh', maxHeight: '65vh', flex: 1, minWidth: 0 }}>
            <GalleryFolders setFolderModalOpen={setFolderModalOpen} toSave={toSave} />

            <div className="d-flex col flex-column p-0" style={{ minWidth: 0 }}>
                <GallerySubheader
                    index={index}
                    onImagesUploadCallback={onImagesUploadCallback}
                    showSelectImagesButton={true}
                    addImage={addImage}
                    addMultipleImages={addMultipleImages}
                    onImageUploaded={onImageUploaded}
                    setFolderModalOpen={setFolderModalOpen}
                />
                <GalleryPreviewImages />
            </div>

            <NewFolderModal modalOpen={folderModalOpen} setModalOpen={setFolderModalOpen} toSave={toSave} />
        </div>
    );
}

ImageLibrarySpace.propTypes = {
    addImage: PropTypes.func,
    addMultipleImages: PropTypes.func,
    index: PropTypes.any,
    onImageUploaded: PropTypes.func,
    onImagesUploadCallback: PropTypes.func,
};
