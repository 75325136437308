import React from 'react';
import PropTypes from 'prop-types';
import FeedbackIcon from 'assets/icons/feedback.svg';
import FeedbackEmptyIcon from 'assets/icons/feedback-empty.svg';
import FeedbackPositiveIcon from 'assets/icons/feedback-positive.svg';
import { colors } from 'style/colors';
import { Body1 } from 'style/typography/Body';
import { useTranslation } from 'react-i18next';

export function FeedbackTextBox({ user, text, type }) {
    const { t } = useTranslation();

    return (
        <div className={'bg-white d-flex flex-column mt-2 p-3'} style={{ borderRadius: '10px' }}>
            <Body1 color={colors.primaryText} className={'text-secondary'}>
                <img
                    alt="Type"
                    src={text ? FeedbackIcon : type == 'POSITIVE' ? FeedbackPositiveIcon : FeedbackEmptyIcon}
                    width={20}
                    height={20}
                    className={'mr-2'}
                />
                {text
                    ? text
                    : type == 'POSITIVE'
                    ? t(`{{user}} liked the experience with their gameplay!`, { user: user })
                    : t(`{{user}} didn't leave a comment for this issue`, { user: user })}
            </Body1>
        </div>
    );
}

FeedbackTextBox.propTypes = {
    text: PropTypes.any,
    type: PropTypes.string,
    user: PropTypes.any,
};
