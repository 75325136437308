import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { useDispatch, useSelector } from 'react-redux';
import { downloadTemplateAction, uploadUsersAction, uploadUserTemplateDoneAction } from 'redux/actions/users.actions';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import readXlsxFile from 'read-excel-file';
import { parseExcelRows } from 'services/utils/parsers';
import { Typography } from '@material-ui/core';
import MultipleUsersInvite from './MultipleUsersInvite';
import { useTranslation } from 'react-i18next';

export const UploadUsers = ({ onChangeUsers }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authState = useSelector(state => state.auth);
    const uploadUsersState = useSelector(state => state.userManagement.uploadUsers);
    const [uploadDone, setUploadDone] = useState(false);
    const [failedInvites, setFailedInvites] = useState([]);
    const [file, setFile] = useState('');

    const [parsedUsers, setParsedUsers] = useState(null);

    useEffect(() => {
        if (file) {
            readXlsxFile(file).then(rows => {
                const parsed = parseExcelRows(rows);

                if (parsed.length > 0) {
                    // setParsedUsers(parsed);
                    dispatch(uploadUserTemplateDoneAction());
                    if (onChangeUsers) onChangeUsers(parsed);
                }
            });
            setFile('');
        }

        //eslint-disable-next-line
    }, [file]);

    function fileUpload() {
        setUploadDone(false);
        setFailedInvites([]);
        dispatch(uploadUsersAction(file));
        setFile('');
    }
    function downloadTemplate() {
        dispatch(downloadTemplateAction(authState.data.access_token));
    }
    if (stateIsLoaded(uploadUsersState) && !uploadDone) {
        if (!uploadDone) {
            setUploadDone(true);
            setFailedInvites(uploadUsersState.data);
        }
    }

    const handleFileChange = files => {
        if (files.length > 0) {
            setFile(files[0]);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 30 }}>
            <div className="col-8 mt-2 d-flex justify-content-center">
                <Button
                    onClick={downloadTemplate}
                    className="download-template-users"
                    variant="contained"
                    color="secondary"
                    startIcon={<GetAppIcon />}
                >
                    {t('Download template')}
                </Button>
                {stateIsLoading(uploadUsersState) && (
                    <CircularProgress style={{ position: 'absolute', zIndex: 2, marginLeft: 10 }} size={35} className={'text-info'} />
                )}
                <Button
                    disabled={stateIsLoading(uploadUsersState) || file.length < 1}
                    onClick={fileUpload}
                    className={'ml-2'}
                    style={{ textTransform: 'none' }}
                    variant="contained"
                    color="secondary"
                    startIcon={<PublishIcon />}
                >
                    {t('Upload users')}
                </Button>
            </div>
            {failedInvites?.length > 0 && (
                <div className="col-6 mt-4">
                    <h4>{t('Failed invites:')} </h4>
                    <div id={'results'}>
                        {failedInvites.map(fi => {
                            return (
                                <p>
                                    {fi.email + ' | '}
                                    <strong>{fi.errorMessage}</strong>
                                </p>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className="col-12 pt-2">
                <div style={{ display: file ? 'none' : 'block' }} className="mx-3">
                    <FileUploader
                        dropzoneText={<Typography variant="subtitle1">{t('Drag and drop the file here or click to upload')}</Typography>}
                        acceptedFiles={['.xlsx']}
                        filesLimit={1}
                        // onChange={file => setFile(file)}
                        // onDrop={files => setFile(files[0])}
                        onChange={handleFileChange}
                        dropzoneClass="users-upload"
                    ></FileUploader>
                </div>
                {file && parsedUsers?.length > 0 && (
                    <div>
                        <MultipleUsersInvite data={parsedUsers} internalSubmit={false} />
                    </div>
                )}
            </div>
        </div>
    );
};
