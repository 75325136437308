import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import { imagesDeleteForceAction, resetImagesDeleteSoft } from 'redux/actions/image.actions';
import { useTranslation } from 'react-i18next';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { tabs } from 'services/image_management/image-editor-management/local-constants';

export function ResultsFromSoftDeleteModal({ modalOpen, setModalOpen, selectedIds, onAction }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const imagesDeleteSoftState = useSelector(state => state.content.imagesDeleteSoft);

    const { imageSearchValue, allImagesSelected, currentTab, currentNode } = useImageEditor();

    const getFolder = () => {
        if (currentTab === tabs.all_images) {
            return 'all';
        } else if (currentTab === tabs.uncategorized) {
            return 'uncategorized';
        } else if (currentTab === tabs.folder) {
            return currentNode.treeIndex;
        }
    };

    return (
        <>
            {imagesDeleteSoftState.data.length == 0 && (
                <ModalDialog
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    onClose={() => {}}
                    onAction={() => {
                        dispatch(resetImagesDeleteSoft());
                        setModalOpen(false);
                        onAction();
                    }}
                    title={t('Success')}
                    text={t('Your images have been successfully deleted')}
                    buttons={[
                        {
                            title: t('Got it'),
                            color: colors.white,
                        },
                    ]}
                    hasCancel={false}
                ></ModalDialog>
            )}

            {imagesDeleteSoftState.data.length > 0 && (
                <ModalDialog
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    onClose={() => {}}
                    onAction={() => {
                        dispatch(resetImagesDeleteSoft());
                        setModalOpen(false);
                        onAction();
                    }}
                    title={t('Images are referenced in questions!')}
                    contentComponent={
                        <div>
                            <div>
                                <p>
                                    {t(
                                        'We found {{imageCount}} references to questions when trying to delete your images. Deleting will mean that the questions will be put to draft. Questions affected are:',
                                        { imageCount: imagesDeleteSoftState.data.length }
                                    )}
                                </p>
                            </div>
                            {imagesDeleteSoftState.data.map(el => (
                                <div>
                                    <a target="_blank" href={`/content/questions/update/${el.questionId}`}>
                                        <p>
                                            {el.questionId}: {el.questionText}
                                        </p>
                                    </a>
                                </div>
                            ))}
                            <div>
                                <p>Are you sure you want to force delete the images?</p>
                            </div>
                        </div>
                    }
                    buttons={[
                        {
                            title: t('Delete and put questions to draft'),
                            color: colors.white,
                            onClick: () => {
                                let folder = getFolder();

                                dispatch(resetImagesDeleteSoft());
                                dispatch(
                                    imagesDeleteForceAction({
                                        images: selectedIds,
                                        data: {
                                            allSelected: allImagesSelected,
                                            folderId: !isNaN(Number(folder)) ? folder : null,
                                            folderAll: folder === 'all',
                                            folderUncategorized: folder === 'uncategorized',
                                            filters: { searchQuery: imageSearchValue },
                                        },
                                    })
                                );
                                setModalOpen(false);
                                onAction();
                            },
                        },
                        {
                            title: t('Cancel'),
                            color: colors.white,
                        },
                    ]}
                    hasCancel={false}
                ></ModalDialog>
            )}
        </>
    );
}

ResultsFromSoftDeleteModal.propTypes = {
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    selectedIds: PropTypes.any,
    setModalOpen: PropTypes.func,
};
