import PaginationComponent from 'components/features/data_table/pagination_component/PaginationComponent';
import { usePagination } from 'components/features/data_table/pagination_component/usePagination';
import { Subheader } from 'components/features/data_table/subheader/Subheader';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllNotificationsAction, resetGetAllNotificationsAction } from 'redux/actions/content.actions';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { customStylesTable } from 'style/elements/table';
import { NotificationsEmptyState } from './NotificationsEmptyState';
import { monthNames } from 'static/months';
import { TableCellContent, TableHeader } from 'components/features/data_table/TableElements';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { notificationsFiltersInitialState } from 'static/filters';
import { notificationTableSubheaders } from './table_components/NotificationTableSubheaders';
import { useTranslation } from 'react-i18next';
import { useFilters } from 'components/features/data_table/pagination_component/useFilters';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { linkBuilderFromQuery } from 'services/api/api_builders';
import { LoadingAnimation } from 'components/features/lottie/LoadingAnimation';

export const Notifications = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const { t } = useTranslation();

    const [dataIsLoading, setDataIsLoading] = useState(false);
    const isInitialLoad = useRef(true);

    const notificationsPaginatedState = useSelector(state => state.content.notificationsPaginated);

    const { filtersState, activeFilters, dispatchFilters, updateFilterState } = useFilters({ initialFiltersState: notificationsFiltersInitialState });

    const filters = useMemo(() => {
        return {
            searchQuery: filtersState.searchQuery,
            page: filtersState.currentPage ?? 0,
            size: filtersState.size ?? 10,
            status: filtersState.status,
            type: filtersState.type,
            fromDate: filtersState.date ? Date.parse(filtersState.date[0]) : null,
            toDate: filtersState.date ? Date.parse(filtersState.date[1]) : null,
        };
    }, [filtersState]);

    const { getPrevious, getNext, getPage } = usePagination({
        paginatedState: notificationsPaginatedState,
        currentPage: filters.page,
        setCurrentPage: page => dispatchFilters({ page: page }),
    });

    const columns = [
        {
            name: <TableHeader text={t('Notification name')} />,
            selector: 'notificationType',
            width: '15%',
            sortable: false,
            wrap: true,
            cell: row => (
                <TableCellContent
                    to={{
                        pathname: `/internal/notifications/${row.notificationId}/edit`,
                    }}
                    isLoading={dataIsLoading}
                    text={row.notificationType.length > 40 ? row.notificationType.substring(0, 40) + '...' : row.notificationType}
                />
            ),
        },
        {
            name: <TableHeader text={t('Title')} />,
            selector: 'title',
            width: '15%',
            sortable: false,
            wrap: true,
            cell: row => (
                <TableCellContent
                    to={{
                        pathname: `/internal/notifications/${row.notificationId}/edit`,
                    }}
                    isLoading={dataIsLoading}
                    text={row.title.length > 40 ? row.title.substring(0, 40) + '...' : row.title}
                />
            ),
        },
        {
            name: <TableHeader text={t('Text')} />,
            selector: 'body',
            width: '25%',
            sortable: false,
            wrap: true,
            cell: row => (
                <TableCellContent
                    to={{
                        pathname: `/internal/notifications/${row.notificationId}/edit`,
                    }}
                    isLoading={dataIsLoading}
                    text={row.body.length > 70 ? row.body.substring(0, 70) + '...' : row.body}
                />
            ),
        },
        {
            name: <TableHeader text={t('Type')} />,
            selector: 'notificationSendType',
            width: '10%',
            sortable: false,
            wrap: true,
            cell: row => (
                <TableCellContent
                    to={{
                        pathname: `/internal/notifications/${row.notificationId}/edit`,
                    }}
                    isLoading={dataIsLoading}
                    text={row.notificationSendType}
                />
            ),
        },

        {
            name: <TableHeader text={t('No. of recipients')} />,
            selector: 'numberOfRecipients',
            width: '8%',
            sortable: false,
            wrap: true,
            cell: row => <TableCellContent isLoading={dataIsLoading} text={row.numberOfRecipients} />,
        },
        {
            name: <TableHeader text={t('Status')} />,
            selector: 'status',
            width: '12%',
            sortable: false,
            wrap: true,
            cell: row => (
                <TableCellContent
                    to={{
                        pathname: `/internal/notifications/${row.notificationId}/edit`,
                    }}
                    isLoading={dataIsLoading}
                    text={row.status}
                />
            ),
        },
        {
            name: <TableHeader text={t('Date')} />,
            selector: 'sendingDate',
            width: '15%',
            sortable: false,
            wrap: true,
            cell: row => {
                let dateString = '';

                if (row.notificationSendType === 'RECURRING') {
                    dateString = row.sendingDate;
                } else {
                    let date = new Date(row.sendingDate.split(' ')[0]);
                    dateString = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
                }
                return (
                    <TableCellContent
                        to={{
                            pathname: `/internal/notifications/${row.notificationId}/edit`,
                        }}
                        isLoading={stateIsLoading(notificationsPaginatedState)}
                        text={dateString}
                    />
                );
            },
        },
    ];

    function hasFilters() {
        return filters.searchQuery || filters.status || filters.type || filters.date;
    }

    useEffect(() => {
        if (location?.search) {
            const query = new URLSearchParams(location.search);

            dispatchFilters({
                searchQuery: query.get('searchQuery'),
                status: query.get('status'),
                type: query.get('type'),
                size: Number(query.get('size') ?? 10),
                page: Number(query.get('page') ?? 0),
                sortParam: { selector: query.get('orderBy'), ascending: query.get('ascending') },
            });
        }

        return () => dispatch(resetGetAllNotificationsAction());
    }, []);

    useEffect(() => {
        setDataIsLoading(true);
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(getAllNotificationsAction({ ...filters }));
                let search = linkBuilderFromQuery('', filters);
                history.push({
                    pathname: `/internal/notifications`,
                    search: search ? search : '',
                });
            },
            300,
            'fetchNotificationsUseEffect'
        );
        return () => setTimeoutLastExecute.removeCallback('fetchNotificationsUseEffect');
    }, [filtersState]);

    useEffect(() => {
        if (stateIsLoaded(notificationsPaginatedState)) {
            setDataIsLoading(false);
            isInitialLoad.current = false;
        }
    }, [notificationsPaginatedState]);

    if (dataIsLoading && !hasFilters() && isInitialLoad.current) {
        return <LoadingAnimation />;
    }

    if (stateIsLoaded(notificationsPaginatedState) && notificationsPaginatedState?.data?.content.length === 0 && !hasFilters()) {
        return (
            <div style={{ height: '90vh' }}>
                <NotificationsEmptyState onClick={() => history.push('/internal/notifications/create')} />
            </div>
        );
    }

    return (
        <div className="custom-data-table" style={{ height: '90vh' }}>
            <DataTable
                columns={columns}
                sortServer
                data={notificationsPaginatedState?.data?.content}
                overflowY={'visible'}
                overflowYOffset={'0px'}
                defaultSortField="name"
                subHeader={true}
                onRowClicked={row => {
                    history.push(`/internal/notifications/${row.notificationId}/edit`);
                }}
                paginationPerPage={100}
                pagination={true}
                selectableRows={false}
                highlightOnHover={true}
                subHeaderComponent={
                    <Subheader
                        inputs={notificationTableSubheaders({
                            filtersState,
                            activeFilters,
                            dispatchFilters,
                            updateFilterState,
                            onAddNew: () => history.push('/internal/notifications/create'),
                        })}
                    />
                }
                subHeaderAlign={'left'}
                customStyles={customStylesTable}
                theme={'tableTheme'}
                selectableRowSelected={row => row.selected}
                noHeader={true}
                paginationComponent={_ => (
                    <PaginationComponent
                        getPrev={getPrevious}
                        getNext={getNext}
                        getPage={getPage}
                        currentPage={filters.page}
                        totalPages={notificationsPaginatedState.data.totalPages}
                        totalItems={notificationsPaginatedState?.data?.totalElements}
                        size={filters.size}
                        setSize={size => {
                            dispatchFilters({ page: 0, size: size });
                        }}
                    />
                )}
            ></DataTable>
        </div>
    );
};
