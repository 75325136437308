import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ResponsiveContainer } from 'recharts';
import { CustomLineChart } from './CustomLineChart';
import { CustomSlider } from './CustomSlider';
import { Subtitle1 } from 'style/typography/Subtitle';
import { marks, xAxis } from 'static/charts';
import UserStatisticsEmptyState from 'containers/layout/module/pages/users/user_profile/local_widgets/UserStatisticsEmptyState';
import { analyticType } from 'static/analytic_constants';

export function KnowledgeOverTimeWrapper({ data, switchable, isEmpty = false }) {
    const [period, setPeriod] = useState({
        value: 0,
        selector: 'WEEK',
    });
    const [activeCategory, setActiveCategory] = useState({
        index: 0,
        id: -1,
        name: '',
    });
    const [activeData, setActiveData] = useState([]);

    function changeCategory(next) {
        if (next) {
            if (activeCategory.index + 1 >= data.length) {
                setActiveCategory({
                    index: 0,
                    id: data[0].category.id,
                    name: data[0].category.name,
                });
            } else {
                setActiveCategory({
                    index: activeCategory.index + 1,
                    id: data[activeCategory.index + 1].category.id,
                    name: data[activeCategory.index + 1].category.name,
                });
            }
        } else {
            if (activeCategory.index - 1 < 0) {
                setActiveCategory({
                    index: data.length - 1,
                    id: data[data.length - 1].category.id,
                    name: data[data.length - 1].category.name,
                });
            } else {
                setActiveCategory({
                    index: activeCategory.index - 1,
                    id: data[activeCategory.index - 1].category.id,
                    name: data[activeCategory.index - 1].category.name,
                });
            }
        }
    }

    useEffect(() => {
        if (activeCategory.id !== -1 && data.find(e => e.category.id === activeCategory.id) !== undefined) {
            setActiveData(
                data
                    .find(e => e.category.id === activeCategory.id)
                    .accuracies[period.selector]?.map((value, index) => {
                        return {
                            time: xAxis[period.selector][index],
                            value: value,
                        };
                    })
            );
        } else {
            if (data.length > 0) {
                setActiveCategory({
                    index: 0,
                    id: data[0].category.id,
                    name: data[0].category.name,
                });
                setActiveData(
                    data[0].accuracies[period.selector]?.map((value, index) => {
                        return {
                            time: xAxis[period.selector][index],
                            value: value,
                        };
                    })
                );
            }
        }
    }, [activeCategory.id, data, period.selector]);

    useEffect(() => {
        if (activeCategory.id === -1 && data.length > 0) {
            setActiveCategory({
                index: 0,
                id: data[0].category.id,
                name: data[0].category.name,
            });
        }
    }, [data, activeCategory.id]);

    function isProgressOverTimeZero() {
        if (data.length > 1) {
            return false;
        }
        let category = data.find(e => e.category.id === activeCategory?.id);
        if (category) {
            for (let selectorIndx in marks.map(el => el.selector)) {
                for (let value in category.accuracies[marks[selectorIndx].selector]) {
                    if (category.accuracies[marks[selectorIndx].selector][value]) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    if (isProgressOverTimeZero()) {
        return <UserStatisticsEmptyState type={analyticType.OVER_TIME} />;
    }

    return (
        <div style={{ height: '35vh' }} className="row justify-content-center">
            <div className="mt-2 text-center" style={{ width: '60%' }}>
                <Subtitle1>{activeCategory.name}</Subtitle1>
            </div>
            <div className="col-10 justify-content-center text-center d-flex flex-row align-items-center">
                {switchable && data.length > 0 && (
                    <FontAwesomeIcon
                        role="button"
                        size={'2x'}
                        color={'gray'}
                        className={'mr-3'}
                        icon={faChevronLeft}
                        onClick={() => changeCategory(false)}
                    />
                )}
                <ResponsiveContainer className={'w-100 h-100 d-flex flex-row justify-content-center mr-3'}>
                    <CustomLineChart data={activeData} />
                </ResponsiveContainer>
                {switchable && data.length > 0 && (
                    <FontAwesomeIcon
                        role="button"
                        size={'2x'}
                        color={'gray'}
                        className={'ml-auto'}
                        icon={faChevronRight}
                        onClick={() => changeCategory(true)}
                    />
                )}
            </div>
            <div className="col-4 text-center" style={{ marginTop: 30 }}>
                <CustomSlider period={period} setPeriod={setPeriod} />
            </div>
        </div>
    );
}

KnowledgeOverTimeWrapper.propTypes = {
    data: PropTypes.any,
    switchable: PropTypes.any,
};
