// import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { palette } from 'style/theme/theme-constants';
import { buttons } from './buttons';
import { ag_bold, ag_light, ag_medium, ag_regular } from './fonts';
import { inputs } from './inputs';
import { lists } from './lists';
import { tabs } from './tabs';
import { uploadUsersOverrides } from './uploadUsers';

export default createTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [ag_regular, ag_light, ag_medium, ag_bold],
            },
        },
        MuiPaper: {
            root: {
                '&.navigation-menu': {
                    backgroundColor: palette.secondary.main,
                    borderRadius: 0,
                },
            },
        },
        MuiTypography: {
            root: {
                '&.navigation-menu-item-text': {
                    color: palette.menu.inactive,
                },
                '&.navigation-menu-item-text.active': {
                    color: palette.menu.active,
                },
            },
        },

        ...buttons,
        ...uploadUsersOverrides,
        ...tabs,
        ...inputs,
        ...lists,
    },
});
