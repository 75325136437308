import React from 'react';
import ErrorTooltip from 'components/elements/tooltips/ErrorTooltip';
import { CSSTransitionGroup } from 'react-transition-group';
import { useValidatorCategory } from 'services/validation-module';
import { useTranslation } from 'react-i18next';

const ValidationPopup = ({ category, position, indexed = false, index, arrowAtBottom = false }) => {
    const categoryInfo = useValidatorCategory(category);

    const { t } = useTranslation();

    const showByIndex = indexed ? index === categoryInfo.index : true;
    return (
        <CSSTransitionGroup transitionName="tooltip" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
            {categoryInfo.isVisible && showByIndex && <ErrorTooltip message={t(categoryInfo.message)} position={position} arrowAtBottom={arrowAtBottom} />}
        </CSSTransitionGroup>
    );
};

export default ValidationPopup;
