import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsLoading, stateIsNotInitialized } from 'redux/core/stateHelpers';
import { modifyDevicesModalContent } from './ModifyDevicesModalContent';
import { Body2 } from 'style/typography/Body';
import { getDeviceRequestsForUserAction, resetResolveDeviceRequestsBulkAction, resolveDeviceRequestsBulkAction } from 'redux/actions/users.actions';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

export function ModifyUserDeviceRequestModal({ modalOpenAction, setModalOpenAction, onAction, onClose, deviceIds }) {
    const { id } = useParams();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const resolveDeviceRequestsState = useSelector(state => state.content.resolveDeviceRequests);

    const contentItem = modifyDevicesModalContent[modalOpenAction + (stateIsLoaded(resolveDeviceRequestsState) ? '-success' : '')];
    return (
        <ModalDialog
            modalOpen={modalOpenAction}
            setModalOpen={setModalOpenAction}
            onAction={() => {
                if (stateIsNotInitialized(resolveDeviceRequestsState)) {
                    dispatch(resolveDeviceRequestsBulkAction({ deviceIds: deviceIds, approve: modalOpenAction === 'approve' }));
                } else {
                    dispatch(getDeviceRequestsForUserAction(id));
                    setModalOpenAction(null);
                }
            }}
            image={contentItem?.icon}
            onClose={() => {
                if (onClose) {
                    onClose();
                }
                if (stateIsLoaded(resolveDeviceRequestsState)) {
                    dispatch(getDeviceRequestsForUserAction(id));
                    dispatch(resetResolveDeviceRequestsBulkAction());
                }
            }}
            title={t(contentItem?.title)}
            imageSize={70}
            text={stateIsLoading(resolveDeviceRequestsState) ? 'Loading' : contentItem?.description}
            buttons={[
                {
                    title: t(contentItem?.button),
                    color: colors.white,
                    disabled: stateIsLoading(resolveDeviceRequestsState),
                },
            ]}
            hasCancel={true}
        ></ModalDialog>
    );
}

ModifyUserDeviceRequestModal.propTypes = {
    bulkQuestionSize: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
