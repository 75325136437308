import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTopicsFromCompanyAction } from 'redux/actions/topics.actions';
import { stateIsLoaded, stateIsNotInitialized } from 'redux/core/stateHelpers';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { Body2 } from 'style/typography/Body';
import Dropdown from 'assets/icons/filter-caret.svg';
import { getTopicsFolderStructureAction, setTopicsTree } from 'redux/actions/topics.actions';
import TopicDropdownSelectorItem from './TopicDropdownSelectorItem';
import { useTranslation } from 'react-i18next';

import './TopicDropdownSelector.css';

export default function TopicDropdownSelector({ value, onChange, iconSelected, iconNotSelected, width }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const topicsTreeState = useSelector(state => state.content.topics.topicsTree);
    const topicsFolderStructureState = useSelector(state => state.content.topics.topicsFolderStructure);
    const allTopicsFromCompanyState = useSelector(state => state.content.topics.allTopicsFromCompany);

    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const dropdownRef = useRef(null);
    const dropdownMenuRef = useRef(null);

    const searchInputRef = useRef(null);

    function handleClick(event) {
        if (dropdownMenuRef.current && dropdownMenuRef.current.contains(event.target)) {
            return;
        }

        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
            setIsOpen(false);
        } else if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
            setIsOpen(prevData => !prevData);
        }
    }

    useEffect(() => {
        if (stateIsNotInitialized(allTopicsFromCompanyState)) {
            dispatch(getAllTopicsFromCompanyAction());
        }
        if (stateIsNotInitialized(topicsFolderStructureState)) {
            dispatch(getTopicsFolderStructureAction());
        }
    }, []);

    useEffect(() => {
        if (stateIsLoaded(allTopicsFromCompanyState) && stateIsLoaded(topicsFolderStructureState)) {
            dispatch(setTopicsTree(null, searchValue));
        }
    }, [dispatch, topicsFolderStructureState, allTopicsFromCompanyState, searchValue]);

    useEffect(() => {
        if (stateIsLoaded(allTopicsFromCompanyState) && stateIsLoaded(topicsFolderStructureState)) {
            dispatch(setTopicsTree(null, null));
        }
    }, [topicsFolderStructureState, allTopicsFromCompanyState]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        if (isOpen) {
            setSearchValue('');
            searchInputRef.current.focus();
        }
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [isOpen]);

    return (
        <div style={{ position: 'relative', zIndex: 10, width: width }}>
            <div ref={dropdownRef} className="topic-dropdown-selector--select">
                <div className="topic-dropdown-selector--title">
                    <img alt="Selected" className={'ml-2 cursor-pointer'} height={20} width={20} src={value ? iconSelected : iconNotSelected} />
                    {!isOpen && <Body2 color={value ? colors.dark : colors.gray6}>{value ? value.label : t('Select topic')}</Body2>}
                    {isOpen && (
                        <input
                            ref={searchInputRef}
                            value={searchValue ? searchValue : ''}
                            placeholder={t('Search topics')}
                            onChange={e => setSearchValue(e.target.value)}
                            autoFocus={true}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '100%',
                                height: 20,
                                fontWeight: 0,
                                color: colors.dark,
                                background: colors.background,
                            }}
                        />
                    )}
                </div>
                <img alt="Dropdown" className={'ml-2 cursor-pointer'} height={20} width={20} src={Dropdown} />
            </div>
            {isOpen && stateIsLoaded(topicsTreeState) && topicsTreeState.data && (
                <div ref={dropdownMenuRef} className="topic-dropdown-selector--menu">
                    <div style={{ paddingBottom: '5px', paddingTop: '10px', borderTop: `solid 1px ${colors.gray3}` }}>
                        <Caption1 color={colors.gray6}>{t('Topics')}</Caption1>
                    </div>
                    {topicsTreeState.data.map(el => {
                        return <TopicDropdownSelectorItem item={el} onChange={onChange} setIsOpen={setIsOpen} value={value} />;
                    })}
                </div>
            )}
        </div>
    );
}

TopicDropdownSelector.propTypes = {
    iconNotSelected: PropTypes.any,
    iconSelected: PropTypes.any,
    onChange: PropTypes.func,
    value: PropTypes.any,
    width: PropTypes.any,
};
