import React, { useReducer, useEffect, useMemo, useContext } from 'react';
import { insertionControlReducer } from './local-reducer';
import { actionTypes } from './local-actions';
import { optionDefaults, INITIAL_STATE, gameMaxInputChars } from './local-constants';
import { randomize } from 'services/utils/arrayHelpers';
import _ from 'lodash';
import { ContextDevTool } from 'react-context-devtool';
import { useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const InsertionTemporaryContext = React.createContext();

export const ContentInsertionProvider = ({ children, currentType, data }) => {
    const MIN_CATEGORIES = 2;
    const gamesToFillOptions = ['race-cars', 'pinpoint'];

    const singleQuestion = useSelector(state => state.content.singleQuestion);

    let { game } = useParams();

    game = game ?? singleQuestion.data.gameSlug;

    let reducerState = INITIAL_STATE;

    const gameQuestionTypeVariations = useSelector(state => state.content.gameQuestionTypeVariations);

    const [state, dispatch] = useReducer(insertionControlReducer, reducerState);

    useEffect(() => {
        dispatch({
            type: actionTypes.setCurrentType,
            payload: {
                currentType,
            },
        });
    }, [currentType, dispatch]);

    useEffect(() => {
        let minOptions = gameMaxInputChars[game].minOptions;

        if (
            stateIsLoaded(gameQuestionTypeVariations) &&
            gameQuestionTypeVariations.data?.[game] &&
            gameQuestionTypeVariations.data?.[game].minOptions
        ) {
            minOptions = gameQuestionTypeVariations.data[game].minOptions;
        }

        if (game === 'sort-it') {
            minOptions = MIN_CATEGORIES;
        }
        if (data) {
            if (data.options) {
                data.indicativeIndex = data.options.length;

                if (gamesToFillOptions.includes(game) && data.options.length < minOptions) {
                    let optionsToAdd = minOptions - data.options.length;
                    let arrayToAdd = Array.from(Array(optionsToAdd), () => optionDefaults.default);
                    data.options = [...data.options, ...arrayToAdd];
                }
            }

            data.options = data.options.map((op, index) => ({ ...op, id: index }));
            data.removedAnswers = [];

            dispatch({ type: actionTypes.updateState, payload: { currentType, ...data } });
        }

        //eslint-disable-next-line
    }, [currentType, data, gameQuestionTypeVariations]);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <InsertionTemporaryContext.Provider value={contextValue}>
            <>
                <ContextDevTool context={InsertionTemporaryContext} id="uniqContextId" displayName="Questions insert context" />
                {children}
            </>
        </InsertionTemporaryContext.Provider>
    );
};

export const ContentInsertionConsumer = () => {
    return (
        <InsertionTemporaryContext.Consumer>
            {values => {
                if (window._REACT_CONTEXT_DEVTOOL) {
                    window._REACT_CONTEXT_DEVTOOL({ id: 'uniqContextId', displayName: 'Questions insert context', values });
                }
                return null;
            }}
        </InsertionTemporaryContext.Consumer>
    );
};

const useState = () => {
    const { state } = useContext(InsertionTemporaryContext);
    return state;
};

const useDispatch = () => {
    const { dispatch } = useContext(InsertionTemporaryContext);
    return dispatch;
};

export const useInsertionStateReset = () => {
    const dispatch = useDispatch();
    return {
        resetState: () => {
            dispatch({ type: actionTypes.resetState });
        },
    };
};

export const useInsertionState = useState;

//se koristi
export const useContentInsertionQuestion = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        question: state.question,
        // question: state[state.currentType].question,
        setQuestion: question => {
            dispatch({ type: actionTypes.setQuestion, payload: question });
        },
    };
};

export const useSetAndCategory = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        chosenCategoryId: state.chosenCategoryId,
        selectedTraining: state.selectedTraining,

        // question: state[state.currentType].question,
        setChosenCategoryId: catId => {
            dispatch({ type: actionTypes.setCategoryId, payload: catId });
        },
        setSelectedTraining: training => {
            dispatch({ type: actionTypes.setTrainingSet, payload: training });
        },
    };
};

export const useDateAvailableFrom = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        dateAvailableFrom: state.dateAvailableFromMills,

        // question: state[state.currentType].question,
        setDateAvailableFrom: dateAvailableFrom => {
            dispatch({ type: actionTypes.setDateAvailableFrom, payload: dateAvailableFrom });
        },
    };
};
export const useStatus = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        questionStatus: state.status,

        // question: state[state.currentType].question,
        setQuestionStatus: status => {
            dispatch({ type: actionTypes.setStatus, payload: status });
        },
    };
};
//se koristi
export const useMultipleChoiceAnswers = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        ...defaultHandlers(state, dispatch),
        resetAllIncorrect: () => dispatch({ type: actionTypes.resetCheckboxes }),
    };
};

const defaultHandlers = (state, dispatch) => ({
    answers: state.options,
    extra: state.extra,
    updateAnswer: ({ index, element }) => dispatch({ type: actionTypes.updateOption, payload: { index, element } }),
    insertAnswer: () => {
        dispatch({ type: actionTypes.insertOption });
    },
    resetAnswers: () => {
        dispatch({ type: actionTypes.resetAnswers });
    },
    removeAnswer: index => {
        dispatch({ type: actionTypes.removeOption, payload: index });
    },
    setOptions: options => {
        dispatch({ type: actionTypes.setOptions, payload: options });
    },
    setExtra: extra => {
        dispatch({ type: actionTypes.setExtra, payload: extra });
    },
    addImage: (index, image) => dispatch({ type: actionTypes.addImageToOption, payload: { index, image } }),
    replaceImage: (index, image) => dispatch({ type: actionTypes.replaceImageInOption, payload: { index, image } }),
    removeImage: index => dispatch({ type: actionTypes.removeImageFromOption, payload: { index } }),
    removeSingleImage: (index, imageIndex) => dispatch({ type: actionTypes.removeSingleImageFromOption, payload: { index, imageIndex } }),
});

export const useAnswerTextOptions = () => {
    const state = useState();
    const dispatch = useDispatch();
    return defaultHandlers(state, dispatch);
};

export const useImageCategories = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        ...defaultHandlers(state, dispatch),
        addImages: (index, images, croppedImages) => dispatch({ type: actionTypes.addImagesToCategory, payload: { index, images, croppedImages } }),
        // addImage: (index, image) => dispatch({ type: actionTypes.addImageToCateogry, payload: { index, image } }),
    };
};

//ne se koristi - kodot e vnatre vo komponentata
export const useRandomCategorizationImages = count => {
    const state = useState();
    let allCategories = state.options;
    let allImages = allCategories.reduce((acc, cat) => {
        acc = [...acc, ...cat.images.map(im => ({ image: im, category: cat.text }))];
        return acc;
    }, []);
    return randomize(allImages, count, { image: null, category: '' });
};

export const useCompareVisualOptions = () => {
    const state = useState();
    const dispatch = useDispatch();
    return defaultHandlers(state, dispatch);
};

//ne se koristi - direktno komponentata
export const useCompareVisualRandomImages = count => {
    const state = useState();
    let allOptions = state.options.filter(op => op.image);
    return randomize(allOptions, count, optionDefaults.default);
};

export const useNumericalAnswer = () => {
    const state = useState();
    const dispatch = useDispatch();
    return {
        answer: state.options[0],
        setAnswer: text => dispatch({ type: actionTypes.updateOption, payload: { index: 0, element: { text } } }),
        setOptions: options => dispatch({ type: actionTypes.setOptions, payload: options }),
    };
};

export const useRandomNumericalDigits = (count = 7) => {
    const state = useState();
    const digits = String(state.options[0]?.text).split('');
    let result = [...digits];
    if (digits.length < count) {
        result = [
            ...digits,
            ...randomize(
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].filter(n => !digits.map(d => +d).includes(n)),
                count - digits.length,
                0
            ),
        ];
    }
    return randomize(result);
};
