export const validatorCategories = {
    PREVIEW_GAMES_QUESTION_NOT_EMPTY: 'previewGamesQuestionNotEmpty',
    PREVIEW_GAMES_ANSWERS_NOT_EMPTY: 'previewGamesAnswersNotEmpty',
    IMAGE_WITHOUT_VALUE: 'imageWithoutValue',
    SINGLE_ANSWER_NOT_EMPTY: 'singleAnswerNotEmpty',
    CORRECT_ANSWER_REQUIRED: 'correctAnswerRequired',
    NOT_CHOSEN_CATEGORY: 'notChosenCategory',
    INCORRECT_ANSWER_REQUIRED: 'incorrectAnswerRequired',
    COMPARING_CRITERIA_REQUIRED: 'comparingCriteriaRequired',
    IMAGE_REQUIRED: 'imageRequired',
    CHOSEN_CATEGORY: 'chosenCategory',
    MIN_OPTIONS: 'minOptions',
    MAX_OPTIONS: 'maxOptions',
    MIN_CORRECT_OPTIONS: 'minCorrectOptions',
    MIN_WRONG_OPTIONS: 'minWrongOptions',
    NO_DUPLICATE_IMAGES: 'noDuplicateImages',
    NO_DUPLICATE_ANSWERS: 'noDuplicateAnswers',
    NO_DUPLICATE_CATEGORIES: 'noDuplicateCategories',
};

export const validationMessages = {
    [validatorCategories.PREVIEW_GAMES_QUESTION_NOT_EMPTY]: 'question must not be empty',
    [validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY]: 'Answers must not be empty',
    [validatorCategories.IMAGE_WITHOUT_VALUE]: 'You cannot have images without a text or a text without images',
    [validatorCategories.SINGLE_ANSWER_NOT_EMPTY]: 'Answer must not be empty',
    [validatorCategories.CORRECT_ANSWER_REQUIRED]: 'You must select at least one correct answer',
    [validatorCategories.CHOSEN_CATEGORY]: 'You must choose category for the question',
    [validatorCategories.MIN_OPTIONS]: (min, t) => t('You must have a minimum of {{min}} answers', { min: min }),
    [validatorCategories.MAX_OPTIONS]: (max, t) => t('You must have a maximum of {{max}} answers', { max: max }),
    [validatorCategories.MIN_CORRECT_OPTIONS]: (min, t) => t('You must have a minimum of {{min}} correct answers', { min: min }),
    [validatorCategories.MIN_WRONG_OPTIONS]: (min, t) => t('You must have a minimum of {{min}} wrong answers', { min: min }),
    [validatorCategories.INCORRECT_ANSWER_REQUIRED]: 'You must have at least one incorrect answer',
    [validatorCategories.COMPARING_CRITERIA_REQUIRED]: 'You must select comparing criteria',
    [validatorCategories.IMAGE_REQUIRED]: 'You must add an image',
    [validatorCategories.NO_DUPLICATE_IMAGES]: 'All images must be unique',
    [validatorCategories.NO_DUPLICATE_ANSWERS]: 'All answers must be unique',
    [validatorCategories.NO_DUPLICATE_CATEGORIES]: 'All categories must be unique',
};
