import PropTypes from 'prop-types';
import React from 'react';
import placeholder from 'assets/game-previews/sortit-emptycard-big.png';

export default function SortItCategory({ children, image, index }) {
    return (
        <div className="d-flex flex-column align-items-center">
            <div
                style={{
                    width: '35px',
                    height: '35px',
                    backgroundColor: '#bfbfbf',
                    borderRadius: '3px',
                    overflow: 'hidden',
                    transition: 'ease-in-out 0.5s',
                }}
            >
                {!image && (
                    <img
                        id={`category-sort-it-image-placeholder-${index}`}
                        src={placeholder}
                        alt="preview-category"
                        style={{ objectFit: 'cover', width: '100%', height: '100%', transition: 'ease-in-out 0.5s' }}
                    />
                )}
                {image && (
                    <img
                        id={`category-sort-it-image-${index}`}
                        alt="preview-category"
                        src={image}
                        style={{ objectFit: 'cover', width: '100%', height: '100%', transition: 'ease-in-out 0.5s' }}
                    />
                )}
            </div>
            <div className="mt-1" style={{ fontSize: '11px', color: '#8c8b8a' }}>
                {children}
            </div>
        </div>
    );
}

SortItCategory.propTypes = {
    children: PropTypes.any,
    image: PropTypes.any,
    index: PropTypes.any,
};
