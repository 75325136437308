export const handleSuccess = (response, ...rest) => {
    const data = response.data;
    return {
        success: true,
        status: response?.status,
        data,
        ...rest,
    };
};

export const handleError = (response, ...rest) => {

    return {
        success: false,
        status: response?.status,
        data: response?.data,
        ...rest,
    };
};
