import React, { useState } from 'react';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import { colors } from 'style/colors';
import { Body1 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { Draggable } from 'react-beautiful-dnd';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';

import Remove from 'assets/icons/icon-remove.svg';
import Carret from 'assets/icons/filter-caret.svg';
import TrashIcon from 'assets/icons/icon-delete.svg';
import CameraDark from 'assets/icons/camera-dark.svg';
import { useTranslation } from 'react-i18next';

export default function ArticleForm({ index, articlesState, dispatchArticles }) {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);

    const handleFileChange = files => {
        if (files.length > 0) {
            var reader = new FileReader();
            reader.onload = function(e) {
                dispatchArticles({ articleIndex: index, payload: { img: e.target.result, imageFile: files[0] } });
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const removeImage = () => {
        dispatchArticles({ articleIndex: index, payload: { img: '', imageFile: null } });
    };
    return (
        <Draggable key={articlesState[index].id} draggableId={articlesState[index].id.toString()} index={index}>
            {(provided, other) => {
                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(other.isDragging, provided.draggableProps.style)}
                        data-droppable-article={index}
                        className="create-news--article"
                    >
                        <div {...provided.dragHandleProps} className="create-news--index-container">
                            <div className="create-news--drag-container">
                                <div className="dash"></div>
                                <div className="dash"></div>
                            </div>
                            <div className="create-news--index">
                                <Body1 color={colors.gray1} weight="medium">
                                    {index + 1}
                                </Body1>
                            </div>
                        </div>
                        <div className="create-news--article-content">
                            <div className="form-group">
                                <Caption1 color={colors.gray6} weight="medium" style={{ paddingBottom: 2 }}>
                                    {t('TITLE')}
                                </Caption1>
                                <CustomTextField
                                    value={articlesState[index].title}
                                    setValue={val => {
                                        // setTitle(val);
                                        dispatchArticles({ articleIndex: index, payload: { title: val } });
                                    }}
                                    placeholder={t('Insert title of slide here...')}
                                    width={'95%'}
                                />
                            </div>
                            {isVisible && (
                                <div className="form-group mt-2">
                                    <Caption1 color={colors.gray6} weight="medium" style={{ paddingBottom: 2 }}>
                                        {t('STORY')}
                                    </Caption1>
                                    <CustomTextField
                                        value={articlesState[index].story}
                                        multiline={true}
                                        setValue={val => {
                                            dispatchArticles({ articleIndex: index, payload: { story: val } });
                                        }}
                                        height={100}
                                        placeholder={t('Type the text here...')}
                                        width={'95%'}
                                    />
                                </div>
                            )}
                            {isVisible && (
                                <div className=" d-flex flex-column">
                                    <Caption1 color={colors.gray6} weight="medium" style={{ paddingBottom: 2 }}>
                                        {t('IMAGE (optional)')}
                                    </Caption1>
                                    {!articlesState[index].img && (
                                        <FileUploader
                                            dropzoneClass="create-news--dropzone"
                                            dropzoneParagraphClass="create-news--dropzone-paragraph"
                                            showPreviews={true}
                                            showPreviewsInDropzone={true}
                                            dropzoneText={
                                                <Caption1 className={'mt-2'} style={{ textAlign: 'center' }} color={colors.gray6}>
                                                    {t('Add an image')}
                                                </Caption1>
                                            }
                                            acceptedFiles={['.png', '.jpg', '.jpeg', '.svg', '.tiff']}
                                            filesLimit={1}
                                            onChange={handleFileChange}
                                            Icon={() => <img alt="Camera" src={CameraDark} width={25} height={25} color={colors.gray6} />}
                                        ></FileUploader>
                                    )}
                                    {articlesState[index].img && (
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                            <img alt="preview" src={articlesState[index].img} className="create-news--article-image" />
                                            <img
                                                alt="delete"
                                                src={TrashIcon}
                                                height={20}
                                                width={20}
                                                style={{ cursor: 'pointer' }}
                                                onClick={removeImage}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="create-news--article-action-buttons">
                            {articlesState?.length > 1 && (
                                <img
                                    alt="remove"
                                    src={Remove}
                                    height={20}
                                    width={20}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (articlesState?.length > 1) {
                                            dispatchArticles({
                                                actionType: 'remove',
                                                articleIndex: index,
                                                articleId: articlesState[index].articleId,
                                            });
                                        }
                                    }}
                                />
                            )}
                            <img
                                alt="caret"
                                src={Carret}
                                height={20}
                                width={20}
                                onClick={() => setIsVisible(prevState => !prevState)}
                                style={{ transform: isVisible ? 'rotate(180deg)' : '', cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                );
            }}
        </Draggable>
    );
}

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
});
