import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { colors } from 'style/colors';
import { RemoveQuestionFromTopicDialog } from './RemoveQuestionFromTopicDialog';
import { useDispatch } from 'react-redux';

import { Body2 } from 'style/typography/Body';
import Add from 'assets/icons/icon-add.svg';
import Remove from 'assets/icons/icon-remove.svg';
import Ellipsis from 'assets/icons/ellipsis.svg';
import { addQuestionToTopicLocal, removeQuestionFromTopicLocal } from 'redux/actions/topics.actions';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import './Questions.css';
import { useTranslation } from 'react-i18next';

export function Question({ question, used, selectedNode, currentTopic }) {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    function addToTopic() {
        dispatch(addQuestionToTopicLocal(currentTopic, question));
    }

    function removeFromTopic() {
        dispatch(removeQuestionFromTopicLocal(currentTopic, question));
    }

    function questionBelongsToOtherTopic(question) {
        return question.categoryId !== null && question.categoryId !== selectedNode.treeIndex;
    }

    return (
        <div
            className={
                'd-flex flex-row px-2 py-2 align-items-center mt-2 ' +
                (used
                    ? 'questions--used '
                    : question.usedInCategory !== null || question.usedInCategoryNodeIdentifier !== null
                    ? !questionBelongsToOtherTopic(question)
                        ? 'questions--unused'
                        : 'questions--used-in-other'
                    : 'questions--unused')
            }
        >
            <div className={'d-flex flex-row align-items-center'}>
                <Body2 color={used ? colors.dark : questionBelongsToOtherTopic(question) ? colors.gray6 : colors.dark} className={'mb-0'}>
                    {question.questionText}
                </Body2>
            </div>
            <div className={'ml-auto d-flex flex-row align-items-center'}>
                <div role="button" className={'mr-3'}>
                    {used && <img alt="Remove" height={20} width={20} src={Remove} onClick={removeFromTopic} />}
                    {!used && (
                        <img
                            alt="Add"
                            height={20}
                            width={20}
                            src={Add}
                            onClick={() => (questionBelongsToOtherTopic(question) ? setModalOpen(true) : addToTopic())}
                        />
                    )}
                </div>

                <div className="dropleft">
                    <img alt="Mopre" src={Ellipsis} height={17} width={17} data-toggle="dropdown" role="button" />
                    <div role="button" className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Link
                            target={'_blank'}
                            to={`/content/questions/update/${question.questionId}`}
                            className="dropdown-item"
                        >
                            {t("Go to question")}
                        </Link>
                        <div
                            className="dropdown-item"
                            onClick={() => (used ? removeFromTopic() : questionBelongsToOtherTopic(question) ? setModalOpen(true) : addToTopic())}
                        >
                            {used ? t('Remove from topic') : t('Add to topic')}
                        </div>
                    </div>
                </div>
                <RemoveQuestionFromTopicDialog
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    onAction={() => {
                        addToTopic();
                    }}
                />
            </div>
        </div>
    );
}

Question.propTypes = {
    currentTopic: PropTypes.any,
    question: PropTypes.any,
    selectedNode: PropTypes.any,
    used: PropTypes.any,
};
