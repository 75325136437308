import React, { useEffect } from 'react';
import InputContent from 'components/features/content_management/inputs/InputContent';
import { useContentInsertionQuestion, useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import InputAnswerCheckboxes from 'components/features/content_management/inputs/InputAnswerCheckboxes';
import { optionDefaults } from 'services/content-management/insertion-control/local-constants';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';

export default function RaceCarsInputs({ adding, game, id, filters }) {
    const { setQuestion } = useContentInsertionQuestion();
    const { answers: answersList, insertAnswer, setOptions } = useMultipleChoiceAnswers();

    const { t } = useTranslation();

    const initializeQuestion = () => {
        if (adding) {
            setOptions(
                Array(3)
                    .fill(0)
                    .map((_, index) => ({ ...optionDefaults.default, id: index }))
            );
            setQuestion('');
        }
    };

    useEffect(() => {
        initializeQuestion();
    }, []);

    return (
        <InputContent initializeQuestion={initializeQuestion} game={game} id={id} filters={filters}>
            <div className="mt-4">
                <div>
                    <Caption1 color={colors.gray6}>{t("OPTIONS")}</Caption1>
                </div>
                <div>
                    {answersList.map((ans, index) => (
                        <InputAnswerCheckboxes
                            key={`input-answer-checkbox-${ans.id}`}
                            value={ans.text}
                            index={index}
                            isCorrect={ans.isCorrect}
                            showCorrectIncorrect={true}
                            showRemove={answersList.length > 3}
                            onlyOneOption={true}
                        />
                    ))}
                </div>
                {answersList.length < 3 && (
                    <div className="mt-3 cursor-pointer" onClick={insertAnswer}>
                        {t('+ Add')}
                    </div>
                )}
            </div>
        </InputContent>
    );
}
