import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { colors } from 'style/colors';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { Body2 } from 'style/typography/Body';
import { aspectRatioNoImageMap } from 'static/image_constans';
import { PreviewImageDialog } from '../custom_gallery_grid/PreviewImageDialog';
import { formatDateOnly } from 'services/utils/stringHelpers';
import { useTranslation } from 'react-i18next';

import CheckmarkIcon from 'assets/icons/checkmark.svg';
import EyeOpenIcon from 'assets/icons/gallery-icon-preview.svg';
import _ from 'lodash';

import './CustomGalleryList.css';

export default function CustomGalleryList({ images, onSelect }) {
    const { t } = useTranslation();

    const [currentImageId, setCurrentImageId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const [errorImages, setErrorImages] = useState([]);

    const [isHovering, setIsHovering] = useState(null);

    const { aspectRatio, allImagesSelected } = useImageEditor();

    const imageSizes = useRef({});
    return (
        <div className="custom-gallery-list--container">
            <div className="custom-gallery-list--head">
                <div></div>
                <Body2 color={colors.gray6}>{t('Name')}</Body2>
                <Body2 color={colors.gray6}>{t('Size')}</Body2>
                <Body2 color={colors.gray6}>{t('Uploaded')}</Body2>
                <Body2 color={colors.gray6}>{t('Used In')}</Body2>
            </div>
            {images.map(el => {
                if (errorImages.includes(el.fullObj.id)) {
                    return (
                        <div className="custom-gallery-list--row">
                            <div key={el.fullObj.id} className="custom-gallery-list--image">
                                {<div className={`custom-gallery-list--preview-invisible`}></div>}
                                <div key={el.fullObj.id} className="custom-gallery-list--image-wrap">
                                    <img alt="Blank" height={70} src={aspectRatioNoImageMap[aspectRatio]}></img>
                                </div>
                            </div>
                            <Body2 color={colors.secoundaryText}>{el.fullName}</Body2>
                            <Body2 color={colors.secoundaryText}>0 KB</Body2>
                            <Body2 color={colors.secoundaryText}>{formatDateOnly(el.dateCreated)}</Body2>
                            <Body2 color={colors.secoundaryText}>
                                {el.usedInCount} {t('Questions')}
                            </Body2>
                        </div>
                    );
                }
                let elTuhumb = _.cloneDeep(el.thumbnail);
                if (!(elTuhumb in imageSizes.current)) {
                    imageSizes.current[elTuhumb] = 0;

                    fetch(elTuhumb)
                        .then(response => response.blob())
                        .then(blob => {
                            imageSizes.current[elTuhumb] = Math.round(blob.size / 1000);
                        })
                        .catch(e => {
                            console.log('error img', elTuhumb, e);
                        });
                }
                return (
                    <div
                        onMouseOver={() => setIsHovering(el.fullObj.id)}
                        onMouseLeave={() => setIsHovering(null)}
                        className="custom-gallery-list--row"
                    >
                        <div
                            className="custom-gallery-list--image"
                            onClick={e => {
                                onSelect(el);
                                e.stopPropagation();
                            }}
                        >
                            {isHovering === el.fullObj.id && (
                                <div
                                    className={`custom-gallery-list--preview`}
                                    onClick={e => {
                                        setModalOpen(true);
                                        setCurrentImageId(el.fullObj.id);
                                        e.stopPropagation();
                                    }}
                                >
                                    {<img alt="Checkmark" height={22} width={22} src={EyeOpenIcon}></img>}
                                </div>
                            )}
                            {!(isHovering === el.fullObj.id) && <div className={`custom-gallery-list--preview-invisible`}></div>}
                            <div
                                key={el.fullObj.id}
                                className={`custom-gallery-list--image-wrap ${el.isSelected || allImagesSelected ? 'selected' : ''}`}
                            >
                                {(el.isSelected || allImagesSelected) && (
                                    <div className={`custom-gallery-list--selected`}>
                                        {<img alt="Checkmark" height={18} width={18} src={CheckmarkIcon}></img>}
                                    </div>
                                )}
                                <img
                                    style={{ zIndex: -5 }}
                                    alt="Thumbnail"
                                    width={70}
                                    src={el.thumbnail}
                                    onError={() => {
                                        setErrorImages(prevState => [...prevState, el.fullObj.id]);
                                    }}
                                ></img>
                                {/* <imageisntance></imageisntance> */}
                            </div>
                        </div>
                        <Body2 color={colors.secoundaryText}>{el.fullName}</Body2>
                        <Body2 color={colors.secoundaryText}>{imageSizes.current[el.thumbnail] ?? 0} KB</Body2>
                        <Body2 color={colors.secoundaryText}>{formatDateOnly(el.dateCreated)}</Body2>
                        <Body2 color={colors.secoundaryText}>
                            {el.usedInCount} {t('Questions')}
                        </Body2>
                    </div>
                );
            })}
            {modalOpen && (
                <PreviewImageDialog currentImageId={currentImageId} filteredImages={images} modalOpen={modalOpen} setModalOpen={setModalOpen} />
            )}
        </div>
    );
}

CustomGalleryList.propTypes = {
    images: PropTypes.array,
    onSelect: PropTypes.func,
};
