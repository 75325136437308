import React, { useEffect, useState } from 'react';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getAllImagesAction, getImagesFromFolderAction, getUncategorizedImagesAction } from 'redux/actions/image.actions';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { aspectRatiosOptions, sortOptions, tabs } from 'services/image_management/image-editor-management/local-constants';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { AreYouSureDeleteModal } from './action_modals/AreYouSureDeleteModal';

import GridviewIcon from 'assets/icons/gallery-icon-gridview.svg';
import ListviewIcon from 'assets/icons/gallery-icon-listview.svg';
import GridviewSelectedIcon from 'assets/icons/gallery-icon-gridview-selected.svg';
import ListviewSelectedIcon from 'assets/icons/gallery-icon-listview-selected.svg';

import '../Gallery.css';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { CustomSelect } from 'components/elements/selector/CustomSelector';

export default function GalleryActionsContainer() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        aspectRatio,
        setAspectRatio,
        selectedImages,
        currentNode,
        size,
        currentTab,
        currentPage,
        setCurrentPage,
        imageSearchValue,
        setImageSearchValue,
        setIsGrid,
        isGrid,
        sortBy,
        setSortBy,
        setAllImagesSelected,
        setSelectedImages,
    } = useImageEditor();

    const [requestImageDeletionModal, setRequestImageDeletionModal] = useState(false);

    function getImagesAction(filtersChanged, searchQuery, sortValue) {
        if (currentTab === tabs.all_images) {
            dispatch(
                getAllImagesAction({
                    page: filtersChanged ? 0 : currentPage,
                    size: size,
                    searchQuery: searchQuery ?? imageSearchValue,
                    sortBy: sortValue ?? sortBy,
                })
            );
        } else if (currentTab === tabs.uncategorized) {
            dispatch(
                getUncategorizedImagesAction({
                    page: filtersChanged ? 0 : currentPage,
                    size: size,
                    searchQuery: searchQuery ?? imageSearchValue,
                    sortBy: sortValue ?? sortBy,
                })
            );
        } else if (currentTab === tabs.folder) {
            dispatch(
                getImagesFromFolderAction({
                    folderId: currentNode.treeIndex,
                    page: filtersChanged ? 0 : currentPage,
                    size: size,
                    searchQuery: searchQuery ?? imageSearchValue,
                    sortBy: sortValue ?? sortBy,
                })
            );
        }
    }

    function onSearchValueChange(val) {
        setTimeoutLastExecute.addCallback(
            () => {
                setCurrentPage(0);
                setAllImagesSelected(false);
                setSelectedImages([]);
                getImagesAction(true, val, null);
            },
            300,
            'searchImages'
        );
        setImageSearchValue(val);
    }

    useEffect(() => {
        return () => setTimeoutLastExecute.removeCallback('searchImages');
    }, []);

    return (
        <div className="actions-container">
            <AreYouSureDeleteModal
                modalOpen={requestImageDeletionModal}
                setModalOpen={setRequestImageDeletionModal}
                selectedIds={selectedImages.map(el => el.id)}
            />
            <img
                src={isGrid ? GridviewSelectedIcon : GridviewIcon}
                height={20}
                width={20}
                onClick={() => setIsGrid(true)}
                style={{ cursor: 'pointer' }}
            />
            <img
                src={isGrid ? ListviewIcon : ListviewSelectedIcon}
                height={20}
                width={20}
                onClick={() => setIsGrid(false)}
                style={{ cursor: 'pointer', marginRight: 10 }}
            />

            <CustomOutlinedTextField
                isSearch={true}
                placeholder={t('Search images by name')}
                value={imageSearchValue}
                setValue={onSearchValueChange}
            />
            <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Body2 className="mx-2" color={colors.gray6}>
                        {t('Sort by:')}
                    </Body2>
                    <div style={{ width: '150px' }} className="m-1">
                        <CustomSelect
                            options={sortOptions}
                            value={sortOptions.find(el => el.value == sortBy)}
                            placeholder="Options"
                            isClearable={false}
                            onChange={el => {
                                setSortBy(el.value);
                                setCurrentPage(0);
                                getImagesAction(true, null, el.value);
                            }}
                        ></CustomSelect>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Body2 className="mx-2" color={colors.gray6}>
                        {t('Image ratio')}
                    </Body2>
                    <div style={{ width: '70px' }} className="m-1">
                        <CustomSelect
                            options={aspectRatiosOptions}
                            value={aspectRatiosOptions.find(el => el.value == aspectRatio)}
                            placeholder="1/1"
                            isClearable={false}
                            onChange={el => {
                                setAspectRatio(el.value);
                            }}
                        ></CustomSelect>
                    </div>
                </div>
            </div>
        </div>
    );
}
