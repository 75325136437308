import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useContentInsertionQuestion, useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import { optionDefaults } from 'services/content-management/insertion-control/local-constants';
import { InputContentWrapper } from 'components/wrappers/InputContentWrapper';
import SequenceInputs from './SequenceInputs';
import SequencePreview from './SequencePreview';

import './Sequence.css';

const AddQuestionSequence = ({ game, id, filters }) => {
    const { setQuestion } = useContentInsertionQuestion();
    const { answers: answersList, setOptions } = useMultipleChoiceAnswers();

    const [answers, setAnswers] = useState([]);

    const sequenceInterval = useRef(null);

    const initializeQuestion = () => {
        setOptions(
            Array(3)
                .fill(0)
                .map((_, index) => ({ ...optionDefaults.default, id: index, position: index + 1 }))
        );
        setQuestion('');
    };

    useEffect(() => {
        initializeQuestion();
        //eslint-disable-next-line
        () => clearInterval(sequenceInterval.current);
    }, []);

    return (
        <div className="d-flex flex-row h-100 m-0">
            <InputContentWrapper id={id} filters={filters}>
                <div className="d-flex flex-row w-100 h-100">
                    <SequenceInputs answers={answers} initializeQuestion={initializeQuestion} game={game} id={id} filters={filters} />
                    <SequencePreview answers={answers} setAnswers={setAnswers} answersList={answersList} sequenceInterval={sequenceInterval} />
                </div>
            </InputContentWrapper>
        </div>
    );
};

AddQuestionSequence.propTypes = {
    filters: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
};

export default AddQuestionSequence;
