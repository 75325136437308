import { ActionStatus } from '../core/ActionStatus';
import { buildActionType } from '../actions/buildActionType';
import { StateStatus } from '../core/StateStatus';

const initialState = {
    data: [],
    errors: null,
    status: StateStatus.NOT_INITIALIZED,
    version: 0,
};

const basicReducer = actionType => (state = initialState, action) => {
    let resp = actionType.responseStatus ? { responseStatus: actionType.responseStatus } : {};

    switch (action.type) {
        case buildActionType(actionType, ActionStatus.START):
            return { ...state, status: StateStatus.LOADING, version: state.version, entity: actionType.entity, ...resp };

        case buildActionType(actionType, ActionStatus.DONE):
            return { ...state, status: StateStatus.LOADED, data: action.payload, entity: actionType.entity, version: +new Date(), ...resp };

        case buildActionType(actionType, ActionStatus.FAILED):
            return { ...state, status: StateStatus.ERROR, data: action.data, responseStatus:action.responseStatus, errors: action.payload, ...resp };

        case buildActionType(actionType, ActionStatus.RESET):
            return initialState;

        default:
            return state;
    }
};

export { basicReducer };
