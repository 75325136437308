import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { colors } from 'style/colors';

import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';

import { TopicsTree } from 'containers/layout/module/pages/content/trainings/local_components/topics_tree/TopicsTree';
import Add from 'assets/icons/icon-add.svg';
import { Circular } from 'components/features/data_table/Circular.js';
import { Body2 } from 'style/typography/Body';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { NoSelectedTrainingState } from './local_components/empty_training_state/NoSelectedTrainingState';
import {
    addChildToTrainingTree,
    fetchSingleTrainingAction,
    removeTrainingAction,
    saveTrainingChanges,
    updateSingleTrainingAction,
} from 'redux/actions/trainings.actions';
import TrainingSelector from './local_components/training_selector/TrainingSelector';

import _ from 'lodash';
import { getNodeKey } from 'services/utils/treeHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { useUndoRedo } from 'services/undo-wrapper-management';
import { setLocalTopicsAction } from 'redux/actions/topics.actions';
import UndoIcon from 'assets/icons/undo-icon.svg';
import Trash from 'assets/icons/icon-delete.svg';
import { DeleteTrainingDialog } from './local_components/dialogs/DeleteTrainingDialog';
import { useTranslation } from 'react-i18next';

import './Trainings.css';

export function TrainingEditing({
    setCreateNewTrainingSwitcher,
    setCreateTrainingModalOpen,
    changeTraining,
    draggingNode,
    setDraggingNode,
    selectedTraining,
    setSelectedTraining,
    setSelectedNode,
}) {
    const dispatch = useDispatch();

    const singleTrainingState = useSelector(state => state.content.singleTraining);
    const updateTopicsTreeState = useSelector(state => state.content.updateTopicsTree);
    const treeData = useSelector(state => state.content.singleTraining.data);
    const localTopicChangesState = useSelector(state => state.content.topics.localTopicChanges);
    const data = useSelector(state => state.content.singleTraining.data.treeStructure);
    const allRolesState = useSelector(state => state.userManagement.roles);

    const { t } = useTranslation();

    const { addToUndoStack, resetRedoStack, undoStack, redoStack, removeFromUndoStack, removeFromRedoStack, resetStacks } = useUndoRedo();

    const [deleteTrainingDialogOpen, setDeleteTrainingDialogOpen] = useState(false);

    let currentSetId = singleTrainingState.data?.learningSetInfo?.setId ?? singleTrainingState.data?.learningSetInfo?.generatedSetId;

    let hasUndo = undoStack[currentSetId]?.length > 0;
    let hasRedo = redoStack[currentSetId]?.length > 0;

    const onUndo = useCallback(() => {
        let undoStackForLocation = undoStack[currentSetId];
        let undoAction = undoStackForLocation[undoStackForLocation.length - 1];

        if (!undoAction) return;

        removeFromUndoStack(currentSetId, { training: { treeStructure: _.cloneDeep(data) }, localTopics: _.cloneDeep(localTopicChangesState.data) });
        dispatch(updateSingleTrainingAction(undoAction.state.training));
        dispatch(setLocalTopicsAction(undoAction.state.localTopics));
    }, [undoStack, redoStack, data, localTopicChangesState]);

    const onRedo = useCallback(() => {
        let redoStackForLocation = redoStack[currentSetId];
        let redoAction = redoStackForLocation[redoStackForLocation.length - 1];

        if (!redoAction) return;

        removeFromRedoStack(currentSetId, { training: { treeStructure: _.cloneDeep(data) }, localTopics: _.cloneDeep(localTopicChangesState.data) });
        dispatch(updateSingleTrainingAction(redoAction.state.training));
        dispatch(setLocalTopicsAction(redoAction.state.localTopics));
    }, [redoStack, undoStack, data, localTopicChangesState]);

    let onKeyDown = useCallback(
        event => {
            if (event.keyCode === 90 && event.ctrlKey && hasUndo) {
                onUndo();
            } else if (event.keyCode === 89 && event.ctrlKey && hasRedo) {
                onRedo();
            }
        },
        [undoStack, redoStack, data]
    );

    function addNewTopic() {
        if (!selectedTraining) return;

        resetRedoStack(currentSetId);
        addToUndoStack(currentSetId, null, {
            training: { treeStructure: _.cloneDeep(data) },
            localTopics: _.cloneDeep(localTopicChangesState.data),
        });
        dispatch(
            addChildToTrainingTree([treeData.treeStructure[0].treeIndex ?? treeData.treeStructure[0].generatedTreeIndex], getNodeKey, '', false, null)
        );
    }

    function cancelChanges() {
        resetStacks(selectedTraining);
        if (!singleTrainingState?.data?.learningSetInfo?.isNew) {
            dispatch(fetchSingleTrainingAction(selectedTraining));
            // dispatch(getSingleTrainingAction(selectedTraining));
        } else {
            dispatch(removeTrainingAction(selectedTraining));
            setSelectedTraining(null);
        }
    }
    
    useEffect(() => {
        window.addEventListener('keydown', onKeyDown, false);

        return () => {
            window.removeEventListener('keydown', onKeyDown, false);
        };
    }, [undoStack, redoStack, data]);

    return (
        <div style={{ height: '90vh', backgroundColor: colors.gray3 }} className="col-6">
            {deleteTrainingDialogOpen && (
                <DeleteTrainingDialog
                    modalOpen={deleteTrainingDialogOpen}
                    onModalClose={() => setDeleteTrainingDialogOpen(false)}
                    currentSetId={currentSetId}
                    isNew={singleTrainingState?.data?.learningSetInfo?.isNew}
                    selectedTraining={selectedTraining}
                />
            )}
            <div className="row pl-5 mt-5 d-flex flex-row">
                <div className="col-6">
                    <TrainingSelector
                        value={singleTrainingState?.data?.learningSetInfo?.name}
                        currentTraining={singleTrainingState?.data?.learningSetInfo}
                        setSelectedTraining={changeTraining}
                        setCreateNewTrainingSwitcher={setCreateNewTrainingSwitcher}
                        setCreateTrainingModalOpen={setCreateTrainingModalOpen}
                    />
                </div>
            </div>

            {(!stateIsLoaded(singleTrainingState) || Object.keys(singleTrainingState.data).length === 0) && <NoSelectedTrainingState />}
            <div className="trainings--editing-buttons">
                <div className="trainings--editing-button" style={{ opacity: hasUndo ? 1 : 0.4 }} onClick={hasUndo ? onUndo : () => {}}>
                    <img alt="Undo" src={UndoIcon} height={20} width={20} />
                    <Body2 color={colors.gray6}>{t('Undo')}</Body2>
                </div>
                <div className="trainings--editing-button" style={{ opacity: hasRedo ? 1 : 0.4 }} onClick={hasRedo ? onRedo : () => {}}>
                    <img alt="Redo" src={UndoIcon} height={20} width={20} style={{ transform: 'rotateY(180deg)' }} />
                    <Body2 color={colors.gray6}>{t('Redo')}</Body2>
                </div>

                <div
                    className="trainings--editing-button"
                    style={{ opacity: selectedTraining ? 1 : 0.4 }}
                    onClick={selectedTraining ? addNewTopic : null}
                >
                    <img alt="Add" src={Add} height={20} width={20} />
                    <Body2 color={colors.gray6}>{t('Add Topic')}</Body2>
                </div>
                <div
                    className="trainings--editing-button"
                    style={{ opacity: selectedTraining ? 1 : 0.4 }}
                    onClick={() => (selectedTraining ? setDeleteTrainingDialogOpen(true) : {})}
                >
                    <img alt="Delete" src={Trash} height={18} width={18} />
                    <Body2 color={colors.gray6}>{t('Delete Training')}</Body2>
                </div>
            </div>
            <TopicsTree draggingNode={draggingNode} setDraggingNode={setDraggingNode} setSelectedNode={setSelectedNode} />
            <div className="d-flex flex-column justify-items-start ml-5">
                <div
                    id="add-new-topic-button"
                    onClick={() => {
                        resetRedoStack(currentSetId);
                        addToUndoStack(currentSetId, null, {
                            training: { treeStructure: _.cloneDeep(data), localTopics: _.cloneDeep(localTopicChangesState.data) },
                        });
                        dispatch(addChildToTrainingTree([], null, '', true, null));
                    }}
                    className={`d-flex flex-row align-items-center mb-2 ${
                        selectedTraining == -1 ? 'trainings--addtopic' : 'trainings--addtopic-invisible'
                    } `}
                >
                    <img alt="Add" style={{ height: 20, width: 20 }} src={Add} className={'mr-2'} />
                    <Body2 className={'mb-0'}>{t('add new topic')}</Body2>
                </div>

                <div className="d-flex flex-row align-items-center ml-2">
                    <DefaultButton
                        id="topic-save-button"
                        text={t('Save')}
                        textColor={colors.white}
                        className={'pl-4 pr-4'}
                        disabled={
                            stateIsLoading(updateTopicsTreeState) ||
                            !(singleTrainingState?.data?.learningSetInfo?.modified || allRolesState?.data?.rolesModifiedInTraining)
                        }
                        onClick={() => {
                            resetStacks(selectedTraining);
                            dispatch(saveTrainingChanges(singleTrainingState?.data?.learningSetInfo?.isNew));
                        }}
                    />
                    <OutlinedButton
                        id="topic-cancel-button"
                        text={t('Cancel')}
                        className={'pl-4 pr-4 ml-2'}
                        disabled={!(singleTrainingState?.data?.learningSetInfo?.modified || allRolesState?.data?.rolesModifiedInTraining)}
                        onClick={cancelChanges}
                    />
                    {stateIsLoading(updateTopicsTreeState) && <Circular style={{ padding: 0, marginLeft: 10 }} size={20} />}
                    {singleTrainingState?.data?.learningSetInfo?.modified && (
                        <Body2 className="ml-2" color={colors.gray6}>
                            {t('Changes not saved')}
                        </Body2>
                    )}
                </div>
            </div>
        </div>
    );
}

TrainingEditing.propTypes = {
    changeTraining: PropTypes.func,
    draggingNode: PropTypes.any,
    setCreateNewTrainingSwitcher: PropTypes.func,
    setCreateTrainingModalOpen: PropTypes.func,
    setDraggingNode: PropTypes.func,
};
