import React from 'react';
import { InputContentWrapper } from 'components/wrappers/InputContentWrapper';
import RaceCarsInputs from './RaceCarsInputs';
import RaceCarsPreview from './RaceCarsPreview';

const AddQuestionRaceCars = ({ adding, game, id, filters }) => {
    return (
        <div className="d-flex flex-row h-100 m-0">
            <InputContentWrapper id={id} filters={filters}>
                <div className="d-flex flex-row w-100 h-100">
                    <RaceCarsInputs adding={adding} game={game} id={id} filters={filters} />
                    <RaceCarsPreview />
                </div>
            </InputContentWrapper>
        </div>
    );
};

export default AddQuestionRaceCars;
