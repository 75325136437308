import React, { useEffect, useState } from 'react';

import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import TrainingModulesMenu from './TrainingModulesMenu';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTrainingModulesInCompanyAction, resetGetTrainingModuleByIdAction } from 'redux/actions/topics.actions';

import './TrainingModulesWrapper.css';
import EmptyStateModules from './EmptyStateModules';

export function TrainingModulesWrapper() {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState('');

    const allTrainingModulesCompanyState = useSelector(state => state.content.allTrainingModulesCompany);
    const saveTrainingModuleState = useSelector(state => state.content.saveTrainingModule);
    const deleteTrainingModuleState = useSelector(state => state.content.deleteTrainingModule);

    useEffect(() => {
        dispatch(resetGetTrainingModuleByIdAction());
        dispatch(getAllTrainingModulesInCompanyAction());
    }, [saveTrainingModuleState, deleteTrainingModuleState]);


    if (allTrainingModulesCompanyState.data.length === 0) {
        return <EmptyStateModules />;
    }

    return (
        <div className="training-module--container">
            <div>
                <CustomOutlinedTextField
                    isSearch={true}
                    value={searchValue}
                    setValue={setSearchValue}
                    placeholder={t('Search..')}
                ></CustomOutlinedTextField>
            </div>
            <div className="d-flex flex-column training-module--component">
                <TrainingModulesMenu />
            </div>
        </div>
    );
}
