import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';

export function ProgressBar({ progress }) {
    const firstBarWidth = 50;
    const secondBarWidth = 15;
    const thirdBarWidth = 13;
    const fourthBarWidth = 11;
    const fifthBarWidth = 11;

    const [firstBar, setFirstBar] = useState('0%');
    const [secondBar, setSecondBar] = useState('0%');
    const [thirdBar, setThirdBar] = useState('0%');
    const [fourthBar, setFourthBar] = useState('0%');
    const [fifthBar, setFifthBar] = useState('0%');

    const calculatePercent = useCallback(
        (previousBarsWidth, barWidth) => {
            if (barWidth > 0) {
                return ((progress - previousBarsWidth) / barWidth) * 100;
            } else {
                return 0;
            }
        },
        [progress]
    );

    useEffect(() => {
        setFirstBar('0%');
        setSecondBar('0%');
        setThirdBar('0%');
        setFourthBar('0%');
        setFifthBar('0%');
    }, [progress]);

    useEffect(() => {
        if (0 <= progress && progress <= firstBarWidth) {
            setFirstBar(`${progress * 2}%`);
        } else if (firstBarWidth + 1 <= progress && progress <= firstBarWidth + secondBarWidth) {
            setFirstBar(`100%`);
            setSecondBar(`${calculatePercent(firstBarWidth, secondBarWidth)}%`);
        } else if (firstBarWidth + secondBarWidth + 1 <= progress && progress <= firstBarWidth + secondBarWidth + thirdBarWidth) {
            setFirstBar(`100%`);
            setSecondBar(`100%`);
            setThirdBar(`${calculatePercent(firstBarWidth + secondBarWidth, thirdBarWidth)}%`);
        } else if (
            firstBarWidth + secondBarWidth + thirdBarWidth + 1 <= progress &&
            progress <= firstBarWidth + secondBarWidth + thirdBarWidth + fourthBarWidth
        ) {
            setFirstBar(`100%`);
            setSecondBar(`100%`);
            setThirdBar(`100%`);
            setFourthBar(`${calculatePercent(firstBarWidth + secondBarWidth + thirdBarWidth, fourthBarWidth)}%`);
        } else if (
            firstBarWidth + secondBarWidth + thirdBarWidth + fourthBarWidth + 1 <= progress &&
            progress <= firstBarWidth + secondBarWidth + thirdBarWidth + fourthBarWidth + fifthBarWidth
        ) {
            setFirstBar(`100%`);
            setSecondBar(`100%`);
            setThirdBar(`100%`);
            setFourthBar(`100%`);
            setFifthBar(`${calculatePercent(firstBarWidth + secondBarWidth + thirdBarWidth + fourthBarWidth, fifthBarWidth)}%`);
        }
    }, [calculatePercent, fifthBarWidth, firstBarWidth, fourthBarWidth, progress, secondBarWidth, thirdBarWidth]);

    return (
        <div className={'d-flex flex-row'}>
            {getProgressBar(firstBarWidth, firstBar)}
            {getProgressBar(secondBarWidth, secondBar)}
            {getProgressBar(thirdBarWidth, thirdBar)}
            {getProgressBar(fourthBarWidth, fourthBar)}
            {getProgressBar(fifthBarWidth, fifthBar)}
        </div>
    );

    function getProgressBar(barWidth, percentage) {
        return (
            <div style={{ width: `${barWidth}%`, height: '3px' }} className="progress mr-1">
                <div className="progress-bar" role="progressbar" style={{ width: `${percentage}`, backgroundColor: colors.mainTheme }}></div>
            </div>
        );
    }
}

ProgressBar.propTypes = {
    progress: PropTypes.number,
};
