import { create_UUID } from 'services/utils/stringHelpers';

import { getFlatDataFromTree } from 'react-sortable-tree';
import { buildActionType } from './buildActionType';
import {
    DELETE_TRAINING_MODULE,
    GET_ALL_TRAINING_MODULES_COMPANY,
    GET_ALL_TOPICS_FROM_COMPANY,
    GET_LOCAL_TOPIC_CHANGES,
    GET_QUESTIONS_UNUSED_IN_COMPANY,
    GET_QUESTIONS_UNUSED_IN_TOPIC,
    GET_QUESTIONS_USED_IN_TOPIC,
    GET_TRAINING_MODULE_COMPANY,
    GET_TOPIC_CONTENT,
    GET_TOPICS_FOLDER_STRUCTURE,
    HANDLE_OLD_TOPICS_FOLDER_STRUCTURE,
    HANDLE_TOPICS_TREE,
    LOCAL_TOPICS_FOLDER_STRUCTURE_CHANGES,
    SAVE_TRAINING_MODULE_LEARNING_SET,
    UPDATE_MULTIPLE_TOPICS,
    UPDATE_SAVED_TOPIC_INDEX,
    UPDATE_TOPICS_FOLDER_STRUCTURE,
    UPDATE_TOPICS_TREE,
    UPDATE_TOPIC_SETTINGS,
    UPDATE_TOPIC,
    GET_ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO,
    HANDLE_OLD_ALL_TOPICS_FROM_COMPANY,
} from '../constants/content.constants';
import { ActionStatus } from '../core/ActionStatus';
import { updateMultipleQuestionsTopicsAction } from './content.actions';
import { ApiExecutor } from './ApiExecutor';
import {
    deleteTrainingModuleApiRequest,
    getAllTopicsFromCompanyApiRequest,
    getAllTopicsFromCompanyWithAdditionalInfoApiRequest,
    getAllTrainingModulesInCompanyApiRequest,
    getQuestionsUnusedInTopicApiRequest,
    getQuestionsUsedInTopicApiRequest,
    getTrainingModuleApiRequest,
    saveTrainingModuleLearningSetApiRequest,
    topicContentApiRequest,
    topicsFolderStructureApiRequest,
    updateMultipleTopicsApiRequest,
    updateSingleTopicApiRequest,
    updateTopicSettingsApiRequest,
    updateTopicsFolderStructureApiRequest,
} from 'services/api/apiRequests/content';
import { stateIsLoaded } from '../core/stateHelpers';
import { appendTopicsToFolders, expandAll, getNodeKey, traverseDFSAndSetLeftAndRightForTopics } from 'services/utils/treeHelpers';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

import _ from 'lodash';

export const getAllTopicsFromCompanyFromLearningSetInfo = (data, currentLearningSetId) => {
    const learningSets = data?.learningSets?.map(el => [el.categoryTree, el.learningSetInfo]);

    let topics = [];

    let learnerTitles = {};

    if (learningSets) {
        learningSets.forEach((learningSet, index) => {
            let currentTopicIds = topics.map(el => el.treeIndex);
            let learningSetInfo = learningSet[1];

            topics = [...topics, ...learningSet[0].filter(el2 => !currentTopicIds.includes(el2.treeIndex))];

            learningSet[0].forEach(topic => {
                if (!(topic.treeIndex in learnerTitles)) learnerTitles[topic.treeIndex] = {};
                learnerTitles[topic.treeIndex][learningSetInfo.setId] = topic.titleForTraining;
            });
        });
    }

    if (data.unusedCategories) topics = [...topics, ...data.unusedCategories];

    return topics.map(topic => {
        return {
            ...topic,
            titlesInTrainings: learnerTitles[topic.treeIndex],
        };
    });
};

export const getAllTopicsFromCompanyAction = () => async dispatch => {
    await dispatch(ApiExecutor(getAllTopicsFromCompanyApiRequest(), GET_ALL_TOPICS_FROM_COMPANY));

    let response = await getAllTopicsFromCompanyApiRequest();

    if (response.success) {
        dispatch({
            type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
            payload: response.data,
            responseStatus: response.status,
        });
        dispatch({
            type: buildActionType(HANDLE_OLD_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
            payload: response.data,
            responseStatus: response.status,
        });
    } else {
        await dispatch({
            type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.FAILED),
        });
    }
};

export const getAllTopicsFromCompanyWithAdditionalInfoAction = () => async dispatch => {
    await dispatch(ApiExecutor(getAllTopicsFromCompanyWithAdditionalInfoApiRequest(), GET_ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO));
};

export const updateTopicSettingsAction = (trainingId, topicId, title, availableFrom) => async dispatch => {
    await dispatch(ApiExecutor(updateTopicSettingsApiRequest(trainingId, topicId, title, availableFrom), UPDATE_TOPIC_SETTINGS));
};

export const updateSingleTopicAction = (data, id) => async dispatch => {
    await dispatch(ApiExecutor(updateSingleTopicApiRequest(data, id), UPDATE_TOPIC));
};

export const resetUpdateTopicSettingsAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(UPDATE_TOPIC_SETTINGS, ActionStatus.RESET),
    });
};

export const resetGetAllTopicsFromCompanyAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.RESET),
    });
};

export const resetGetAllTopicsWithAdditionalInfoFromCompanyAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO, ActionStatus.RESET),
    });
};

export const resetTopicFolderStructureAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.RESET),
    });
};

export const addChildToTopicsTree = (title, isTopic, setCurrentNode) => async (dispatch, getState) => {
    let topicsTree = getState().content.topics.topicsTree;
    let topicsFolderStructure = getState().content.topics.topicsFolderStructure;

    let topicsState = getState().content.topics.allTopicsFromCompany;

    let newNode = createNewNode(title, isTopic, topicsTree?.data);

    if (newNode) {
        if (isTopic !== true) {
            await dispatch(addToTopicsFolderStructure(newNode, topicsFolderStructure));
            await dispatch(updateHasTopicFolderStructureLocalChanges(true));
        } else {
            await dispatch(addNewTopicToMultipleTopicsAction(newNode, topicsState));
            await dispatch(updateLocalTopicChanges(newNode, {}));
            setCurrentNode(newNode);
        }
    }
};

export const removeNode = node => async dispatch => {
    if (node.topic === true) {
        dispatch(deleteTopic(node));
    } else {
        dispatch(deleteFolder(node));
    }
};

export const moveNode = movingData => async dispatch => {
    let node = movingData.node;
    let newParent = movingData.nextParentNode;
    let newParentTreeIndex = newParent?.treeIndex !== null ? newParent?.treeIndex : newParent?.generatedTreeIndex;

    if (node.topic === true) {
        dispatch(updateLocalTopicChanges(node, { folderId: newParentTreeIndex !== undefined ? newParentTreeIndex : null }, []));
    } else {
        dispatch(moveFolderAction(node, newParentTreeIndex));
        dispatch(updateHasTopicFolderStructureLocalChanges(true));
    }
};

export const removeQuestionFromTopicLocal = (topic, question) => async (dispatch, getState) => {
    let state = getState().content.topics.localTopicChanges;

    dispatch({ type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.START) });
    let prevData = state.data;

    let prevChangedQuestions = prevData.changedQuestions;

    let changedQuestion = {
        ...question,
        categoryId: null,
    };

    let prevChangedQuestionsRemovedCurrent = prevChangedQuestions?.filter(el => el.questionId != question.questionId);

    dispatch({
        type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
        payload: {
            ...prevData,
            changedQuestions: prevChangedQuestionsRemovedCurrent ? [...prevChangedQuestionsRemovedCurrent, changedQuestion] : [changedQuestion],
        },
    });
};

export const setTopicsTree = (treeData, searchValue) => async (dispatch, getState) => {
    let data = treeData;

    if (!treeData) {
        let topicsFolderStructure = getState().content.topics.topicsFolderStructure;
        let topicsState = getState().content.topics.allTopicsFromCompany;
        let localTopicsChanges = getState().content.topics.localTopicChanges;

        data = appendTopicsToFolders(topicsState.data, topicsFolderStructure.data, localTopicsChanges?.data, searchValue);
    }

    dispatch({
        type: buildActionType(HANDLE_TOPICS_TREE, ActionStatus.DONE),
        payload: data,
    });
};

export const updateQuestions = () => async (dispatch, getState) => {
    let localTopicChangesState = getState().content.topics.localTopicChanges;
    let updateMultipleTopicsState = getState().content.topics.updateMultipleTopics;

    let questionsToUpdate = _.cloneDeep(localTopicChangesState.data.changedQuestions);

    //Set the treeIndex to the saved topics by generatedTreeIndex
    if (updateMultipleTopicsState.data?.length > 0 && localTopicChangesState.data.changedQuestions) {
        for (let changedTopicIndx in updateMultipleTopicsState.data) {
            let changedTopic = updateMultipleTopicsState.data[changedTopicIndx];
            if (changedTopic.generatedTreeIndex) {
                for (let i = 0; i < questionsToUpdate.length; i++) {
                    if (questionsToUpdate[i].categoryId == changedTopic.generatedTreeIndex) {
                        questionsToUpdate[i].categoryId = changedTopic.treeIndex;
                    }
                }
            }
        }
    }

    if (questionsToUpdate) {
        dispatch(updateMultipleQuestionsTopicsAction(questionsToUpdate));
    } else {
        dispatch(resetUpdateLocalTopicChanges());
    }
};

export const resetTopicChanges = () => async (dispatch, getState) => {
    let oldTopicsState = getState().content.topics.oldAllTopicsFromCompany;

    dispatch(resetUpdateLocalTopicChanges());
    dispatch({
        type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
        payload: oldTopicsState?.data ? oldTopicsState.data : [],
    });
};

export const addQuestionToTopicLocal = (topic, question) => async (dispatch, getState) => {
    let state = getState().content.topics.localTopicChanges;

    dispatch({ type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.START) });
    let prevData = state.data;

    let prevChangedQuestions = prevData.changedQuestions;

    let changedQuestion = {
        ...question,
        categoryId: topic.treeIndex,
    };

    let prevChangedQuestionsRemovedCurrent = prevChangedQuestions?.filter(el => el.questionId != question.questionId);

    dispatch({
        type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
        payload: {
            ...prevData,
            changedQuestions: prevChangedQuestionsRemovedCurrent ? [...prevChangedQuestionsRemovedCurrent, changedQuestion] : [changedQuestion],
        },
    });
};

export const getQuestionsUsedInTopicAction = (id, query) => async (dispatch, getState) => {
    let state = getState().content.topics.questionsUsedInTopic;
    let localState = getState().content.topics.localTopicChanges;

    let itemCount = 0;

    dispatch({ type: buildActionType(GET_QUESTIONS_USED_IN_TOPIC, ActionStatus.START) });

    let changedQuestions = localState?.data?.changedQuestions;

    //Ignore the questions that have local changes
    if (changedQuestions) {
        let ignoreIds = [];
        ignoreIds.push(...changedQuestions.map(el => el.questionId));
        query = { ...query, ignoreIds: ignoreIds };
    }

    let response = await getQuestionsUsedInTopicApiRequest(id, query);

    let allItems = [];

    let questions = changedQuestions?.filter(el => el.categoryId == id);

    if (questions) {
        itemCount = itemCount + questions.length;
    }

    //Add locally changed questions if on first page
    if (changedQuestions && query.page == 0) {
        if (questions.length > 0) {
            allItems.push(...questions);
        }
    }

    if (response.success) {
        let prevData = state.data;

        //Add the questions from the previous pagination
        if (query.page != 0 && prevData?.content && response.data.number != 0) {
            allItems.push(...prevData.content);
        }
        //Add the newly fetched questions
        allItems.push(...response.data.content);
    }

    itemCount = itemCount + (response.data?.totalElements ?? 0);

    dispatch({
        type: buildActionType(GET_QUESTIONS_USED_IN_TOPIC, ActionStatus.DONE),
        payload: { ...response.data, content: allItems, localItemCount: itemCount },
    });
};

export const getQuestionsUnusedInTopicAction = (id, query) => async (dispatch, getState) => {
    let state = getState().content.topics.questionsUnusedInTopic;
    let localState = getState().content.topics.localTopicChanges;

    let itemCount = 0;

    dispatch({ type: buildActionType(GET_QUESTIONS_UNUSED_IN_TOPIC, ActionStatus.START) });

    let changedQuestions = localState?.data?.changedQuestions;
    if (changedQuestions) {
        let ignoreIds = [];
        ignoreIds.push(...changedQuestions.map(el => el.questionId));
        query = { ...query, ignoreIds: ignoreIds };
    }

    let response = await getQuestionsUnusedInTopicApiRequest(id, query);

    let allItems = [];

    let questions = changedQuestions?.filter(el => el.categoryId && el.categoryId != id);

    if (questions) {
        itemCount = itemCount + questions.length;
    }

    //Add locally changed questions if on first page
    if (changedQuestions && query.page == 0) {
        if (questions.length > 0) {
            allItems.push(...questions);
        }
    }

    if (response.success) {
        let prevData = state.data;

        //Add the questions from the previous pagination
        if (query.page != 0 && prevData?.content && response.data.number != 0) {
            allItems.push(...prevData.content);
        }
        //Add the newly fetched questions
        allItems.push(...response.data.content);
    }
    itemCount = itemCount + (response.data?.totalElements ?? 0);

    dispatch({
        type: buildActionType(GET_QUESTIONS_UNUSED_IN_TOPIC, ActionStatus.DONE),
        payload: { ...response.data, content: allItems, localItemCount: itemCount },
    });
};

export const updateNodeExpanded = (node, expanded) => async (dispatch, getState) => {
    if (node.topic !== true) {
        let index = node.treeIndex ? node.treeIndex : node.generatedTreeIndex;
        let topicsFolderStructureData = getState().content.topics.topicsFolderStructure.data;
        let topic = topicsFolderStructureData.filter(t => t.treeIndex === index || t.generatedTreeIndex === index)[0];

        if (topic) topic.expanded = expanded;

        dispatch({
            type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
            payload: topicsFolderStructureData,
        });
    }
};

export const updateNodeTitle = (node, newTitle) => async (dispatch, getState) => {
    //Folder
    if (node.topic !== true) {
        let index = node.treeIndex ? node.treeIndex : node.generatedTreeIndex;
        let topicsFolderStructureData = getState().content.topics.topicsFolderStructure.data;
        let topic = topicsFolderStructureData.filter(t => t.treeIndex === index || t.generatedTreeIndex === index)[0];

        if (topic) topic.title = newTitle;

        dispatch({
            type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
            payload: topicsFolderStructureData,
        });
        dispatch(updateHasTopicFolderStructureLocalChanges(true));
    }
    //Topic
    else {
        dispatch(updateLocalTopicChanges(node, {}, []));
    }
};

export const resetQuestionsUsedInTopicAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_QUESTIONS_USED_IN_TOPIC, ActionStatus.RESET),
    });
    await dispatch({
        type: buildActionType(GET_QUESTIONS_UNUSED_IN_TOPIC, ActionStatus.RESET),
    });
    await dispatch({
        type: buildActionType(GET_QUESTIONS_UNUSED_IN_COMPANY, ActionStatus.RESET),
    });
};

export const resetUpdateTopicsFolderStructureAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(UPDATE_TOPICS_FOLDER_STRUCTURE, ActionStatus.RESET),
    });
};

export const resetUpdateTopicsTreeAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(UPDATE_TOPICS_TREE, ActionStatus.RESET),
    });
};

export const resetUpdateMultipleTopicsAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(UPDATE_MULTIPLE_TOPICS, ActionStatus.RESET),
    });
};

export const deleteTopic = topic => async (dispatch, getState) => {
    let state = getState().content.topics.localTopicChanges;
    let stateAllTopics = getState().content.topics.allTopicsFromCompany;

    dispatch({ type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.START) });
    let prevData = state.data;

    let prevChangedQuestions = prevData.changedQuestions;

    let questionsWithRemovedTopic = prevChangedQuestions?.map(q => {
        if (q.categoryId == topic.treeIndex) {
            return { ...q, categoryId: null };
        } else return q;
    });

    dispatch({
        type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
        payload: {
            ...prevData,
            [topic.treeIndex]: {
                treeData: prevData[topic.treeIndex]
                    ? { ...prevData[topic.treeIndex], generatedTreeIndex: '-1' }
                    : {
                          ...topic,
                          generatedTreeIndex: '-1',
                      },
            },
            changedQuestions: questionsWithRemovedTopic,
        },
    });
    dispatch({
        type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
        payload: stateAllTopics?.data?.filter(t => t.treeIndex !== topic.treeIndex),
    });
};

export const deleteFolder = folder => async (dispatch, getState) => {
    let state = getState().content.topics.topicsFolderStructure;
    let prevData = state.data;
    let folderIndex = folder.treeIndex !== null ? folder.treeIndex : folder.generatedTreeIndex;

    dispatch({
        type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
        payload: prevData?.filter(f => f.treeIndex !== folderIndex && f.generatedTreeIndex !== folderIndex),
    });
    dispatch(updateHasTopicFolderStructureLocalChanges(true));
};

export const saveTopicLocalChanges = forceSave => async (dispatch, getState) => {
    let localTopicChangesState = getState().content.topics.localTopicChanges;
    let savedTopicIndexes = getState().content.topics.savedTopicIndexes.data;

    let updatedTopics = [];
    let keys = Object.keys(localTopicChangesState.data);
    let localTopicChangesClone = _.cloneDeep(localTopicChangesState.data);

    for (let key in keys) {
        let topic = localTopicChangesClone[keys[key]];
        if (topic?.treeData) {
            let lessonType = topic?.treeData.lessonType;
            let lessonContent = topic?.treeData.lessonContent;

            if (forceSave || !topic.treeData.lastModified) {
                topic.treeData.lastModified = Date.now();
            }

            if (lessonType && !lessonContent) {
                topic.treeData.lessonType = null;
                topic.treeData.lessonContent = null;
            }

            if (lessonContent && typeof lessonContent === 'object') {
                topic.treeData.lessonContent = draftToHtml(convertToRaw(lessonContent.getCurrentContent()));
            }
            if (topic.treeData?.generatedTreeIndex && topic.treeData?.generatedTreeIndex != '-1') {
                ///If the topic was saved through trainings
                let savedTreeIndex = savedTopicIndexes[topic.treeData?.generatedTreeIndex];

                updatedTopics.push({ ...topic.treeData, treeIndex: savedTreeIndex });
            } else {
                updatedTopics.push(topic.treeData);
            }
        }
    }
    if (updatedTopics) {
        dispatch(updateMultipleTopicsAction(updatedTopics));
    }
};

export const changeLessonContentAction = (currentTopicData, lessonContent) => async dispatch => {
    if (currentTopicData.lessonType === 'external') {
        dispatch(updateLocalTopicChanges(currentTopicData, { lessonContent: lessonContent }, []));
    } else {
        dispatch(updateLocalTopicChanges(currentTopicData, { lessonContent: lessonContent }, []));
    }
};
export const updateMultipleTopicsAction = data => async (dispatch, getState) => {
    dispatch({ type: buildActionType(UPDATE_MULTIPLE_TOPICS, ActionStatus.START) });

    let response = await updateMultipleTopicsApiRequest(data);

    if (!response.success) {
        dispatch({
            type: buildActionType(UPDATE_MULTIPLE_TOPICS, ActionStatus.FAILED),
            payload: response.errors,
            responseStatus: response.status,
            data: response.data,
        });
        return;
    }

    let localTopicsChanges = getState().content.topics.localTopicChanges.data;
    let topics = getState().content.topics.allTopicsFromCompany.data;
    let questions = getState().content.topics.localTopicChanges?.data?.changedQuestions;
    let learningSets = getState().content.allTrainings?.data?.learningSets;

    response.data.forEach(t => {
        let indexInTopics = topics.findIndex(topic => topic.treeIndex === t.treeIndex);

        if (indexInTopics === -1) {
            indexInTopics = topics.findIndex(topic => topic.generatedTreeIndex === t.generatedTreeIndex);
        }

        if (learningSets) {
            learningSets.forEach((ls, index) => {
                let learningSetInfo = ls.learningSetInfo;

                let currentTopicInLearningSet = ls.categoryTree.find(el2 => el2.treeIndex == t.treeIndex);

                // if (currentTopicInLearningSet) {
                //     if (!t.learnerTitles) t.learnerTitles = {};
                //     t.learnerTitles[learningSetInfo.setId] = currentTopicInLearningSet.titleForTraining;
                // }
            });
        }

        if (indexInTopics !== -1) topics[indexInTopics] = t;
        else topics.push(t);

        if (questions?.length > 0) {
            questions.filter(q => q.categoryId === t.generatedTreeIndex).forEach(q => (q.categoryId = t.treeIndex));
        }

        if (t.generatedTreeIndex) {
            dispatch(addIndexToSavedIndexes(t.generatedTreeIndex, t.treeIndex));
        }
    });

    dispatch({
        type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
        payload: topics,
    });
    dispatch({
        type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
        payload: {
            ...localTopicsChanges,
            changedQuestions: questions,
        },
    });

    dispatch({ type: buildActionType(UPDATE_MULTIPLE_TOPICS, ActionStatus.DONE) });
};

export const getTopicContentAction = id => async dispatch => {
    await dispatch(ApiExecutor(topicContentApiRequest(id), GET_TOPIC_CONTENT));
};

export const resetGetTopicContentAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_TOPIC_CONTENT, ActionStatus.RESET),
    });
};

export const saveTrainingModuleLearningSetAction = (setId, trainingModuleRootTopicId) => async dispatch => {
    await dispatch(ApiExecutor(saveTrainingModuleLearningSetApiRequest(setId, trainingModuleRootTopicId), SAVE_TRAINING_MODULE_LEARNING_SET));
};

export const resetSaveTrainingModuleLearningSetAction = (setId, trainingModuleRootTopicId) => async dispatch => {
    await dispatch({
        type: buildActionType(SAVE_TRAINING_MODULE_LEARNING_SET, ActionStatus.RESET),
    });
};

export const getAllTrainingModulesInCompanyAction = () => async dispatch => {
    await dispatch(ApiExecutor(getAllTrainingModulesInCompanyApiRequest(), GET_ALL_TRAINING_MODULES_COMPANY));
};

export const resetGetAllTrainingModulesInCompanyAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_ALL_TRAINING_MODULES_COMPANY, ActionStatus.RESET),
    });
};

export const getTrainingModuleByIdAction = trainingModuleId => async dispatch => {
    await dispatch(ApiExecutor(getTrainingModuleApiRequest(trainingModuleId), GET_TRAINING_MODULE_COMPANY));
};

export const resetGetTrainingModuleByIdAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_TRAINING_MODULE_COMPANY, ActionStatus.RESET),
    });
};

export const deleteTrainingModuleByIdAction = trainingModuleId => async dispatch => {
    await dispatch(ApiExecutor(deleteTrainingModuleApiRequest(trainingModuleId), DELETE_TRAINING_MODULE));
};

export const resetDeleteTrainingModuleByIdAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(DELETE_TRAINING_MODULE, ActionStatus.RESET),
    });
};

export const moveFolderAction = (node, newParentTreeIndex) => async (dispatch, getState) => {
    let state = getState().content.topics.topicsFolderStructure;
    let folder = state?.data.filter(
        n =>
            (n.treeIndex !== null && n.treeIndex == node.treeIndex) ||
            (n.generatedTreeIndex != null && n.generatedTreeIndex == node.generatedTreeIndex)
    )[0];

    if (folder !== undefined) {
        folder.parentNode = {
            treeIndex: newParentTreeIndex ? newParentTreeIndex : null,
        };
        await dispatch({
            type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
            payload: state.data,
        });
    }
};
export const updateTopicsFolderStructureAction = data => async (dispatch, getState) => {
    dispatch({ type: buildActionType(UPDATE_TOPICS_FOLDER_STRUCTURE, ActionStatus.START) });
    let response = await updateTopicsFolderStructureApiRequest(data);

    if (!response.success) {
        await dispatch({
            type: buildActionType(UPDATE_TOPICS_FOLDER_STRUCTURE, ActionStatus.FAILED),
        });
        return;
    }
    let allTopics = getState().content.topics.allTopicsFromCompany.data;
    let localChangesTopics = getState().content.topics.localTopicChanges;
    let localChangesTopicsClone = _.cloneDeep(getState().content.topics.localTopicChanges);

    response.data.forEach(folder => {
        if (folder.generatedTreeIndex !== undefined && folder.generatedTreeIndex !== null) {
            allTopics.filter(t => t.folderId === folder.generatedTreeIndex).forEach(t => (t.folderId = folder.treeIndex));

            if (stateIsLoaded(localChangesTopics)) {
                Object.values(localChangesTopicsClone.data)
                    .filter(t => t.treeData.folderId === folder.generatedTreeIndex)
                    .forEach(t => (t.treeData.folderId = folder.treeIndex));
            }
        }
    });

    dispatch({
        type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
        payload: [...response.data],
    });
    dispatch({
        type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
        payload: [...allTopics],
    });

    dispatch(updateHasTopicFolderStructureLocalChanges(false));

    await dispatch({
        type: buildActionType(UPDATE_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
        payload: [...response.data],
    });

    if (stateIsLoaded(localChangesTopics)) {
        dispatch({
            type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
            payload: localChangesTopicsClone.data,
        });
    }
};

export const saveTopicsFolderStructure = data => async dispatch => {
    let dataFiltered = data.filter(n => n.topic !== true);

    if (dataFiltered.length === 0) {
        dispatch(updateTopicsFolderStructureAction([]));
    } else {
        let root = [
            {
                treeIndex: -1,
                generatedTreeIndex: null,
                title: 'Root',
                left: -1,
                right: -1,
                root: true,
                children: [...dataFiltered],
            },
        ];

        root = expandAll(null, root, node => dispatch(updateNodeExpanded(node), true));
        let data = traverseDFSAndSetLeftAndRightForTopics(root[0]);

        let flatDataResult = getFlatDataFromTree({
            treeData: data.children,
            getNodeKey,
            ignoreCollapsed: false,
        });

        let finalResult = [data];

        flatDataResult.forEach((value, index) => {
            let node;

            if (value.node !== null && value.node !== undefined) {
                node = value.node;
            } else {
                node = value;
            }
            if (node.topic !== true) {
                finalResult.push({
                    title: node.title,
                    left: node.left,
                    right: node.right,
                    treeIndex: node.treeIndex ? node.treeIndex : -1,
                    generatedTreeIndex: node.generatedTreeIndex,
                });
            }
        });

        dispatch(updateTopicsFolderStructureAction(finalResult));
    }
};

export const updateHasTopicFolderStructureLocalChanges = newValue => async dispatch => {
    dispatch({
        type: buildActionType(LOCAL_TOPICS_FOLDER_STRUCTURE_CHANGES, ActionStatus.DONE),
        payload: newValue,
    });
};

export const resetFolderStructureChanges = () => async (dispatch, getState) => {
    let oldState = getState().content.topics.oldTopicsFolderStructure;
    dispatch(updateHasTopicFolderStructureLocalChanges(false));
    dispatch({
        type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
        payload: oldState?.data ? oldState.data : [],
    });
};

export const getTopicsFolderStructureAction = () => async dispatch => {
    dispatch({
        type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.START),
    });

    const response = await topicsFolderStructureApiRequest();

    if (response.success) {
        dispatch({
            type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
            payload: response.data,
        });
        dispatch({
            type: buildActionType(HANDLE_OLD_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
            payload: response.data,
        });
    } else {
        dispatch({
            type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.FAILED),
        });
    }
};

const addToTopicsFolderStructure = (newNode, state) => async dispatch => {
    dispatch({
        type: buildActionType(GET_TOPICS_FOLDER_STRUCTURE, ActionStatus.DONE),
        payload: [newNode, ...state?.data],
    });
};

const addNewTopicToMultipleTopicsAction = (newNode, topicsState) => async dispatch => {
    dispatch({
        type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
        payload: topicsState?.data !== undefined ? [newNode, ...topicsState.data] : [newNode],
    });
};

export const resetUpdateLocalTopicChanges = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.RESET),
    });
};

export const addIndexToSavedIndexes = (generatedTreeIndex, treeIndex) => async (dispatch, getState) => {
    let savedTopicIndexes = getState().content.topics.savedTopicIndexes.data;

    await dispatch({
        type: buildActionType(UPDATE_SAVED_TOPIC_INDEX, ActionStatus.DONE),
        payload: {
            ...savedTopicIndexes,
            [generatedTreeIndex]: treeIndex,
        },
    });
};

export const resetAddIndexToSavedIndexesAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(UPDATE_SAVED_TOPIC_INDEX, ActionStatus.RESET),
    });
};

export const updateLocalTopicChanges = (topicOriginal, newData, children) => async (dispatch, getState) => {
    let localTopicsState = getState().content.topics.localTopicChanges;

    let prevData = localTopicsState.data;
    let topicInLocal = prevData[topicOriginal.treeIndex]?.treeData;
    let topicNew = topicInLocal ? { ...topicInLocal, ...newData } : { ...topicOriginal, ...newData };

    dispatch({
        type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
        payload: {
            ...prevData,
            ...children,
            [topicOriginal.treeIndex]: {
                treeData: topicNew,
            },
        },
    });
};

export const setLocalTopicsAction = data => async (dispatch, getState) => {
    dispatch({
        type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
        payload: data,
    });
};

export const removeFromLocalTopicChanges = treeIndex => async (dispatch, getState) => {
    let localTopicsState = getState().content.topics.localTopicChanges.data;

    delete localTopicsState[treeIndex];

    dispatch({
        type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
        payload: {
            ...localTopicsState,
        },
    });
};

export const createNewNode = (title, isTopic, topicsTree) => {
    let uniqueUUID = create_UUID();

    return {
        nodeIdentifier: topicsTree ? topicsTree.length : 0,
        title: title,
        isNew: true,
        titleForTraining: null,
        left: null,
        right: null,
        treeIndex: isTopic ? uniqueUUID : null,
        lessonContent: '',
        main: null,
        lessonType: null,
        lessonTitle: null,
        dateAvailableFrom: null,
        categoryStatus: null,
        parentNode: { treeIndex: 0 },
        numberOfQuestions: 0,
        topic: isTopic,
        folderId: null,
        createdBy: '',
        dateCreated: Date.now(),
        timeCreated: Date.now(),
        usedIn: [],
        generatedTreeIndex: uniqueUUID,
        questions: null,
    };
};
