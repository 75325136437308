import React from 'react';
import { Body1 } from 'style/typography/Body';
import { colors } from 'style/colors';

import EmptyOverTime from 'assets/icons/icon-emptystate-progress-over-time.svg';
import EmptyAccuracy from 'assets/icons/icon-emptystate-accuracy-chart.svg';
import EmptyProgress from 'assets/icons/icon-emptystate-category-progress.svg';

import '../UserAnalytics.css';

export default function UserStatisticsEmptyState({ type }) {
    const typeToIcon = {
        over_time: EmptyOverTime,
        accuracy: EmptyAccuracy,
        progress: EmptyProgress,
    };

    return (
        <div className="user-analytics--empty">
            <img alt="empty-state" src={typeToIcon[type]} height={90} width={90} />
            <Body1 color={colors.gray6} weight="bold">
                Not enough data to show here
            </Body1>
            <Body1 color={colors.gray6}>We don't have sufficient data for this section yet.</Body1>
        </div>
    );
}
