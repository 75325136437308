import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { logout, passwordLoginAction, resetLogout, tokenLoginAction } from 'redux/actions/auth.actions';
import { useParams } from 'react-router-dom';
import { stateIsLoaded, stateIsLoading, stateHasFailed } from 'redux/core/stateHelpers';
import { colors } from 'style/colors';
import Alert from '@material-ui/lab/Alert';
import { DefaultButton } from 'components/elements/Button';
import Collapse from '@material-ui/core/Collapse';
import { authService } from 'services/auth/auth';
import { useTranslation } from 'react-i18next';
import { getLanguageForUser } from 'i18nConfig';
import { Body2 } from 'style/typography/Body';
import { H7 } from 'style/typography/Heading';
import { authorities } from 'static/auth_constants';
import { AnimatedTextField } from 'components/elements/inputs/AnimatedTextField';

import Icon from 'assets/icons/coach-logo-black.svg';

import './Auth.css';

const Login = ({ history }) => {
    const { newUser, newPassword } = useParams();

    const { t, i18n } = useTranslation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
    const [inputsError, setInputsError] = useState(false);
    const [loginInitiated, setLoginInitiated] = useState(false);

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const loggedOut = useSelector(state => state.loggedOut);

    useEffect(() => {
        if (!stateIsLoaded(loggedOut) && (newUser === undefined || newPassword === undefined)) {
            if (localStorage.getItem('tokenInfo') === null) {
                return;
            }

            let tokenInfo = JSON.parse(localStorage.getItem('tokenInfo'));
            let dateStored = tokenInfo.date_stored;
            let expireDate = dateStored + tokenInfo.auth.expires_in;

            let isExpired = expireDate <= Date.now();

            if (isExpired) {
                localStorage.removeItem('tokenInfo');
            } else {
                dispatch(tokenLoginAction(tokenInfo.auth));
            }
        }
    }, [dispatch, loggedOut, newPassword, newUser]);

    useEffect(() => {
        if (stateHasFailed(auth) && loginInitiated) {
            if (auth.error === 401 || auth.error === 400) {
                setValidationMessage(t('Bad credentials!'));
                inputsEmptyOrWrong();
            } else if (auth.error === 403) {
                setValidationMessage(t('You are not authorized to access this application!'));
                inputsEmptyOrWrong();
            } else if (auth.error === 500) {
                setValidationMessage(t('Server error!'));
                inputsEmptyOrWrong();
            } else {
                setValidationMessage(t('Some problem has occurred. Try again'));
            }
        }
    }, [auth, loginInitiated]);

    useEffect(() => {
        if (stateIsLoaded(auth) && auth.persisted) {
            let authority = authService.user.authorities[0];
            if (authority === authorities.ROLE_SUPERADMIN || authority === authorities.ROLE_ADMIN) {
                i18n.changeLanguage(getLanguageForUser());
                history.replace('/dashboard');

                dispatch(resetLogout());
            } else {
                setValidationMessage(t('You are not authorized to use this application!'));
                inputsEmptyOrWrong();
                dispatch(logout());
            }
        }
    }, [auth]);

    function inputsEmptyOrWrong() {
        setInputsError(true);
    }

    function doLogin(event) {
        event.preventDefault();
        if (username.length === 0 || password.length === 0) {
            setValidationMessage(t('Username and password must not be empty'));
            inputsEmptyOrWrong();
        } else {
            setLoginInitiated(true);
            setInputsError(false);
            setValidationMessage('');
            dispatch(passwordLoginAction(username, password));
        }
    }

    return (
        <div className="login-screen">
            <form className="login-screen--form-container" onSubmit={doLogin}>
                <img alt="logo" src={Icon} width={70} height={70}></img>
                <H7 style={{ marginTop: 30 }} color={colors.gray6}>
                    {t('Login to Dashboard')}
                </H7>
                <div className="login-screen--form">
                    <AnimatedTextField
                        label={t('Username')}
                        value={username}
                        disabled={stateIsLoading(auth)}
                        onFocus={() => {
                            setInputsError(false);
                        }}
                        setValue={val => setUsername(val)}
                    />
                    <AnimatedTextField
                        label={t('Password')}
                        type="password"
                        value={password}
                        disabled={stateIsLoading(auth)}
                        onFocus={() => {
                            setInputsError(false);
                        }}
                        setValue={val => setPassword(val)}
                        visibilityIcon={true}
                    />
                    <Collapse in={inputsError}>
                        <Alert id="error-message-login" severity="error">
                            {validationMessage.error ? validationMessage.error : validationMessage}
                        </Alert>
                    </Collapse>
                </div>
                <div>
                    <DefaultButton style={{ width: '150px' }} text={t('Login')} disabled={stateIsLoading(auth)} onClick={doLogin} textColor="white" />
                    <Body2
                        style={{ marginTop: 20, cursor: 'pointer' }}
                        onClick={() => history.push('/users/forgot/request')}
                        color={colors.mainTheme}
                    >
                        {t('Forgot your password?')}
                    </Body2>
                </div>
            </form>
        </div>
    );
};

export default Login;
