import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'style/theme/theme-constants';
import { OutlinedButton } from 'components/elements/Button';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { Body1, Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { shortenString } from 'services/utils/stringHelpers';
import { aspectRatios, views } from 'services/image_management/image-editor-management/local-constants';
import { useTranslation } from 'react-i18next';

import './CroppingModule.css';

export function InfoSideBar({ fromEditorSpace }) {
    const { t } = useTranslation();

    const { currentActivePreviewImage: image, aspectRatio, setAspectRatio, deleteCurrentImage, setView } = useImageEditor();

    return (
        <div className="info-side-bar--container">
            <div>
                <Body1 weight="medium" className="py-1" color={colors.gray6}>
                    {t('IMAGE DETAILS')}
                </Body1>
                <div style={{ maxWidth: '300px', textOverflow: 'ellipsis' }}>
                    {image?.name && <Body2 weight="medium" className="py-1">{`${shortenString(image?.name, 30)}`}</Body2>}
                </div>
                {!fromEditorSpace && (
                    <div className="d-flex py-1">
                        <OutlinedButton
                            className="mr-2"
                            text={t('Edit image')}
                            color="primary"
                            onClick={() => {
                                setView(views[1]);
                            }}
                        />
                        <OutlinedButton
                            text={t('Remove')}
                            color="primary"
                            onClick={() => {
                                deleteCurrentImage();
                            }}
                        />
                    </div>
                )}
            </div>
            <div>
                <Body1 weight="medium" className="py-1" color={colors.gray6}>
                    {t('SIZE PREVIEWS')}
                </Body1>

                <div className="d-flex cursor-pointer" onClick={() => setAspectRatio(aspectRatios[0])}>
                    <img
                        alt="1/1"
                        src={image ? image.croppedImage['1/1'] : ''}
                        style={{
                            width: 100,
                            height: 100,
                            boxSizing: 'content-box',
                            border: `2px solid ${aspectRatio === aspectRatios[0] ? palette.primary.main : 'transparent'}`,
                        }}
                    />
                    <div style={{ padding: 10 }}>
                        <Body2>[1:1]</Body2>
                        <Body2>[360:360]</Body2>
                        <Body2>52kb</Body2>
                    </div>
                </div>

                <div className="d-flex cursor-pointer" onClick={() => setAspectRatio(aspectRatios[1])}>
                    <img
                        alt="2/1"
                        src={image ? image.croppedImage['2/1'] : ''}
                        style={{
                            width: 150,
                            height: 75,
                            border: `2px solid ${aspectRatio === aspectRatios[1] ? palette.primary.main : 'transparent'}`,
                        }}
                    />
                    <div style={{ padding: 10 }}>
                        <Body2>[2:1]</Body2>
                        <Body2>[600:400]</Body2>
                        <Body2>200kb</Body2>
                    </div>
                </div>
                <div className="d-flex cursor-pointer" onClick={() => setAspectRatio(aspectRatios[2])}>
                    <img
                        alt="4/3"
                        src={image ? image.croppedImage['4/3'] : ''}
                        style={{
                            width: 120,
                            height: 90,
                            border: `2px solid ${aspectRatio === aspectRatios[2] ? palette.primary.main : 'transparent'}`,
                        }}
                    />
                    <div style={{ padding: 10 }}>
                        <Body2>[4:3]</Body2>
                        <Body2>[600:400]</Body2>
                        <Body2>200kb</Body2>
                    </div>
                </div>
            </div>
        </div>
    );
}

InfoSideBar.propTypes = {
    fromEditorSpace: PropTypes.bool,
};
