import { cellTypes } from '../content-blocks/local-constants';

export const INITIAL_STATE = {
    contentBlock: null,
    isTemplate: false,
    attributes: [],
    questionAttribute: '',
    answerAttribute: '',
    imageAttribute: '',
    comparatorCriteria: '',
    comparatorCorrect: '',
    questionType: null,
    filters: [],
    correctValuesMap: {},
    autogenerateValue: '',
};

export const contentBlockQuestionTypes = {
    MULTIPLE_CHOICE: 'multiple_choice',
    NUMERICAL: 'numerical',
    COMPARE: 'categorize_visual',
};

export const compareCriterias = {
    VALUE: 'value',
    TEXT: 'text',
    NUMBER: 'number',
};

export const compareCriteriasOptions = [
    { label: 'Value', value: compareCriterias.VALUE },
    { label: 'Text', value: compareCriterias.TEXT },
    { label: 'Number', value: compareCriterias.NUMBER },
];

export const valueCriterias = {
    BIGGER: 'bigger',
    SMALLER: 'smaller',
    BOOLEAN: 'boolean',
};
export const valueCriteriasOptions = [
    { label: 'Bigger', value: valueCriterias.BIGGER },
    { label: 'Smaller', value: valueCriterias.SMALLER },
    { label: 'Boolean', value: valueCriterias.BOOLEAN },
];

export const numberCriterias = {
    LARGER_THAN: 'larger_than',
    SMALLER_THAN: 'smaller_than',
    LARGER_THAN_AND_EQUAL_TO: 'larger_than_and_equal_to',
    SMALLER_THAN_AND_EQUAL_TO: 'smaller_than_and_equal_to',
};
export const numberCriteriasFromOptions = [
    { label: 'Larger than', value: numberCriterias.LARGER_THAN },
    { label: 'Larger than and equal to', value: numberCriterias.LARGER_THAN_AND_EQUAL_TO },
];

export const numberCriteriasToOptions = [
    { label: 'Smaller than', value: numberCriterias.SMALLER_THAN },
    { label: 'Smaller than and equal to', value: numberCriterias.SMALLER_THAN_AND_EQUAL_TO },
];

export const correctOptionsByCriteriaType = {
    [compareCriterias.VALUE]: valueCriteriasOptions,
    [compareCriterias.TEXT]: '',
    [compareCriterias.NUMBER]: numberCriteriasFromOptions,
};

export const games = {
    RACE_CARS: 'race-cars',
    TRAFFIC: 'traffic',
    SORT_IT: 'sort-it',
    NUMBERS: 'numbers',
    PINPOINT: 'pinpoint',
    RECALL: 'recall',
    COMPARE: 'compare',
    SEQUENCE: 'sequence',
    PROGRESS: 'progress',
};

export const constraintsErrorTexts = {
    [cellTypes.NUMERICAL]: '*Numerical attribute required',
    [cellTypes.IMAGE]: '*Image required',
    [cellTypes.TEXT]: '*Text attribute required',
};

export const contentBlockConstraintsByGame = {
    'race-cars': {
        requiredAttributesCount: 2,
        requiredAttributes: {
            [cellTypes.NUMERICAL]: 0,
            [cellTypes.IMAGE]: 0,
            [cellTypes.TEXT]: 0,
        },
        attributesLimit: {
            [cellTypes.IMAGE]: 0,
        },
        minimumCorrectAnswers: 1,
        maximumCorrectAnswers: 1,
        minimumFalseAnswers: 2,
        maximimFalseAnswers: 2,
    },
    traffic: {
        requiredAttributesCount: 2,
        requiredAttributes: {
            [cellTypes.NUMERICAL]: 0,
            [cellTypes.IMAGE]: 0,
            [cellTypes.TEXT]: 0,
        },
        attributesLimit: {
            [cellTypes.IMAGE]: 0,
        },
        minimumCorrectAnswers: 3,
        maximumCorrectAnswers: 10,
        minimumFalseAnswers: 3,
        maximimFalseAnswers: 6,
    },
    pinpoint: {
        requiredAttributesCount: 3,
        requiredAttributes: {
            [cellTypes.NUMERICAL]: 0,
            [cellTypes.IMAGE]: 1,
            [cellTypes.TEXT]: 0,
        },
        attributesLimit: {
            [cellTypes.IMAGE]: 1,
        },
        minimumCorrectAnswers: 1,
        maximumCorrectAnswers: 1,
        minimumFalseAnswers: 2,
        maximimFalseAnswers: 2,
    },
    'sort-it': {
        requiredAttributesCount: 2,
        requiredAttributes: {
            [cellTypes.NUMERICAL]: 0,
            [cellTypes.IMAGE]: 1,
            [cellTypes.TEXT]: 0,
        },
        attributesLimit: {
            [cellTypes.IMAGE]: 1,
        },
        minimumCorrectAnswers: 3,
        maximumCorrectAnswers: 15,
        minimumFalseAnswers: 0,
        maximimFalseAnswers: 0,
    },
    recall: {
        requiredAttributesCount: 2,
        requiredAttributes: {
            [cellTypes.NUMERICAL]: 0,
            [cellTypes.IMAGE]: 0,
            [cellTypes.TEXT]: 0,
        },
        attributesLimit: {
            [cellTypes.IMAGE]: 0,
        },
        minimumCorrectAnswers: 3,
        maximumCorrectAnswers: 30,
        minimumFalseAnswers: 0,
        maximimFalseAnswers: 0,
    },
    numbers: {
        requiredAttributesCount: 2,
        requiredAttributes: {
            [cellTypes.NUMERICAL]: 1,
            [cellTypes.IMAGE]: 0,
            [cellTypes.TEXT]: 0,
        },
        attributesLimit: {
            [cellTypes.NUMERICAL]: 1,
        },
        minimumCorrectAnswers: 1,
        maximumCorrectAnswers: 1,
        minimumFalseAnswers: 0,
        maximimFalseAnswers: 0,
    },
    sequence: {
        requiredAttributesCount: 2,
        requiredAttributes: {
            [cellTypes.NUMERICAL]: 0,
            [cellTypes.IMAGE]: 0,
            [cellTypes.TEXT]: 0,
        },
        attributesLimit: {
            [cellTypes.IMAGE]: 0,
        },
        minimumCorrectAnswers: 1,
        maximumCorrectAnswers: 1,
        minimumFalseAnswers: 0,
        maximimFalseAnswers: 0,
    },
    compare: {
        requiredAttributesCount: 2,
        requiredAttributes: {
            [compareCriterias.VALUE]: {
                [cellTypes.NUMERICAL]: 1,
                [cellTypes.IMAGE]: 1,
                [cellTypes.TEXT]: 0,
            },
            [compareCriterias.TEXT]: {
                [cellTypes.NUMERICAL]: 0,
                [cellTypes.IMAGE]: 1,
                [cellTypes.TEXT]: 1,
            },
            [compareCriterias.NUMBER]: {
                [cellTypes.NUMERICAL]: 1,
                [cellTypes.IMAGE]: 1,
                [cellTypes.TEXT]: 0,
            },
        },
        minimumCorrectAnswers: 1,
        maximumCorrectAnswers: 1,
        minimumFalseAnswers: 2,
        maximimFalseAnswers: 2,
    },
};
