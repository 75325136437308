import React, { useEffect, useState } from 'react';

import './ContentBlocksBuilder.css';

import { Caption1 } from 'style/typography/Caption';
import { Body2 } from 'style/typography/Body';
import { Subtitle2 } from 'style/typography/Subtitle';
import { colors } from 'style/colors';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { getUniqueCorrectAnswers } from '../content_blocks_table/contentBlocksGenerator';
import ContentBlocksDetailsList from './ContentBlocksDetailsList';
import { useContentBlocksBuilder } from 'services/content-blocks-management/content-blocks-builder';
import ContentBlocksValuesList from './ContentBlocksValuesList';
import ContentBlocksCompareCriteria from './ContentBlocksCompareCriteria';
import { useParams } from 'react-router-dom';
import ContentBlocksPicker from './ContentBlocksPicker';
import GenerateOptionsByGame from './GenerateOptionsByGame';
import { contentBlockConstraintsByGame } from 'services/content-blocks-management/content-blocks-builder/local-constants';
import { useContentInsertionQuestion } from 'services/content-management/insertion-control';
import { getContentBlocksPaginated } from 'redux/actions/content.actions';
import { useDispatch, useSelector } from 'react-redux';

export default function ContentBlocksBuilder() {
    const { game } = useParams();
    const dispatch = useDispatch();

    const {
        contentBlock,
        resetState,
        resetValues,
        attributes,
        setAttributes,
        questionAttribute,
        setCorrectValuesMap,
        comparatorCriteria,
        setAnswerAttribute,
        imageAttribute,
        isTemplate,
        setIsTemplate,
    } = useContentBlocksBuilder();

    useEffect(() => {
        dispatch(getContentBlocksPaginated({ page: 0, size: 10 }));
    }, [])

    const { question, setQuestion } = useContentInsertionQuestion();


    const [errorTexts, setErrorTexts] = useState(["No attributes selected"]);

    function elementsAreSelected() {
        return errorTexts.length === 0;
    }

    function compareCriteriaPicked() {
        if (game != 'compare') return true;
        else {
            return comparatorCriteria;
        }
    }


    useEffect(() => {
        if (question) {
            let questionVariable = question.match("<%(.*)%>")?.[1];
            if (questionVariable) {
                let attributeExists = attributes.find(el => el.value == questionVariable);
                if (attributeExists) {
                    setAttributes(attributes.map(el => { return el.value === questionVariable ? { ...el, selected: true } : { ...el, selected: false } }))
                    setIsTemplate(true);
                }
            } else if (isTemplate) {
                setIsTemplate(false);
                resetValues();
            }
        }
    }, [question]);

    useEffect(() => {
        let answerAttribute = attributes.find(el => el.selected && el.value != questionAttribute?.value && el.value != imageAttribute?.value);
        if (questionAttribute && answerAttribute) {
            setAnswerAttribute({ value: answerAttribute.value, id: answerAttribute.id });
            let generatedAnswers = getUniqueCorrectAnswers(contentBlock.data, questionAttribute, answerAttribute, imageAttribute);
            setCorrectValuesMap(generatedAnswers);
        }
    }, [questionAttribute, attributes, contentBlock]);

    useEffect(() => {
        if (contentBlock?.data && attributes.length == 0) {
            let mappedAttributes = contentBlock.data.columns.map(el => {
                return { label: el.name, value: el.key, selected: false, type: el.type, id: el.id };
            })
            setAttributes(mappedAttributes.filter(el => {
                if (contentBlockConstraintsByGame[game]['attributesLimit']?.[el.type] === undefined) {
                    return true;
                } else if (contentBlockConstraintsByGame[game]['attributesLimit'][el.type] > 0) {
                    return true
                }
                return false;
            }
            ))
        }
    }, [contentBlock]);

    if (!contentBlock) return <ContentBlocksPicker />;

    return (
        <div>
            {game == 'compare' && <ContentBlocksCompareCriteria />}
            {compareCriteriaPicked() && (
                <div className="cb-builder--details">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Subtitle2 weight={'bold'}>{contentBlock.name}</Subtitle2>
                        <Caption1
                            style={{ marginLeft: 10, cursor: 'pointer' }}
                            color={colors.gray6}
                            onClick={() => {
                                resetState();
                            }}
                        >
                            Back
                        </Caption1>
                    </div>

                    {attributes && <ContentBlocksDetailsList setErrorTexts={setErrorTexts} />}
                    <div style={{ marginTop: 15 }}>
                        {errorTexts.map(el => {
                            return <Caption1 color={colors.gray6}>{el}</Caption1>;
                        })}
                    </div>
                </div>
            )}
            {elementsAreSelected() && <GenerateOptionsByGame
                game={game}
            />}

        </div>
    );
}
