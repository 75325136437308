import Checkbox from 'components/elements/checkbox/Checkbox';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import React from 'react';
import { useContentBlocksBuilder } from 'services/content-blocks-management/content-blocks-builder';
import {
    compareCriterias,
    compareCriteriasOptions,
    correctOptionsByCriteriaType,
    numberCriteriasFromOptions,
    numberCriteriasToOptions,
} from 'services/content-blocks-management/content-blocks-builder/local-constants';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

export default function ContentBlocksCompareCriteria() {
    const {
        comparatorCriteria,
        comparatorCorrect,
        setComparatorCriteria,
        setComparatorCorrect,
        setCorrectValuesMap,
        attributes,
        setAttributes,
        setQuestionAttribute,
    } = useContentBlocksBuilder();

    var isCriteriaNumber = comparatorCriteria && comparatorCriteria.value == compareCriterias.NUMBER;

    return (
        <div className="cb-criteria--container">
            <div className="cb-criteria--item">
                <div style={{ paddingTop: 10 }}>
                    <Body2 color={colors.gray6}>Criteria:</Body2>
                </div>
                <CustomSelect
                    options={compareCriteriasOptions}
                    value={comparatorCriteria}
                    placeholder={'Select criteria'}
                    onChange={val => {
                        setComparatorCriteria(val);
                        setComparatorCorrect('');
                        setCorrectValuesMap({});
                        setQuestionAttribute(null);
                        setAttributes(
                            attributes.map(el => {
                                el.selected = false;
                                return el;
                            })
                        );
                    }}
                />
            </div>
            {comparatorCriteria && (
                <div className="cb-criteria--item">
                    <div style={{ paddingTop: 10 }}>
                        <Body2 color={colors.gray6}>Correct:</Body2>
                    </div>
                    <div className="cb-criteria--correct">
                        {correctOptionsByCriteriaType[comparatorCriteria.value] && (
                            <div>
                                <div style={isCriteriaNumber ? { display: 'grid', gridTemplateColumns: '70% 25%', gap: 10 } : {}}>
                                    <CustomSelect
                                        options={
                                            isCriteriaNumber ? numberCriteriasFromOptions : correctOptionsByCriteriaType[comparatorCriteria.value]
                                        }
                                        value={isCriteriaNumber ? comparatorCorrect.from : comparatorCorrect}
                                        onChange={val => {
                                            if (isCriteriaNumber) {
                                                setComparatorCorrect({
                                                    ...comparatorCorrect,
                                                    from: val,
                                                });
                                                return;
                                            }
                                            setComparatorCorrect(val);
                                        }}
                                        placeholder={'Select correct'}
                                    />
                                    {isCriteriaNumber && (
                                        <CustomTextField
                                            width={'100%'}
                                            value={comparatorCorrect.fromValue}
                                            setValue={val => {
                                                setComparatorCorrect({
                                                    ...comparatorCorrect,
                                                    fromValue: val,
                                                });
                                                return;
                                            }}
                                            placeholder="Value"
                                        />
                                    )}
                                </div>
                                {isCriteriaNumber && (
                                    <div className="cb-criteria--number-picker">
                                        <div className="cb-criteria--number-picker-item">
                                            <div style={{ position: 'relative' }}>
                                                <Checkbox
                                                    onClick={_ => {
                                                        setComparatorCorrect({
                                                            ...comparatorCorrect,
                                                            criteria: 'OR',
                                                        });
                                                    }}
                                                    checked={comparatorCorrect.criteria == 'OR'}
                                                ></Checkbox>
                                            </div>
                                            <Body2 weight="medium" color={colors.gray6}>
                                                OR
                                            </Body2>
                                        </div>
                                        <div className="cb-criteria--number-picker-item">
                                            <div style={{ position: 'relative' }}>
                                                <Checkbox
                                                    onClick={_ => {
                                                        setComparatorCorrect({
                                                            ...comparatorCorrect,
                                                            criteria: 'AND',
                                                        });
                                                    }}
                                                    checked={comparatorCorrect.criteria == 'AND'}
                                                ></Checkbox>
                                            </div>
                                            <Body2 weight="medium" color={colors.gray6}>
                                                AND
                                            </Body2>
                                        </div>
                                    </div>
                                )}
                                {isCriteriaNumber && (
                                    <div style={isCriteriaNumber ? { display: 'grid', gridTemplateColumns: '70% 25%', gap: 10 } : {}}>
                                        <CustomSelect
                                            options={
                                                isCriteriaNumber ? numberCriteriasToOptions : correctOptionsByCriteriaType[comparatorCriteria.value]
                                            }
                                            value={isCriteriaNumber ? comparatorCorrect.to : comparatorCorrect}
                                            onChange={val => {
                                                if (isCriteriaNumber) {
                                                    setComparatorCorrect({
                                                        ...comparatorCorrect,
                                                        to: val,
                                                    });
                                                    return;
                                                }
                                                setComparatorCorrect(val);
                                            }}
                                            placeholder={'Select correct'}
                                        />
                                        {isCriteriaNumber && (
                                            <CustomTextField
                                                width={'100%'}
                                                value={comparatorCorrect.toValue}
                                                setValue={val => {
                                                    setComparatorCorrect({
                                                        ...comparatorCorrect,
                                                        toValue: val,
                                                    });
                                                    return;
                                                }}
                                                placeholder="Value"
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        {!correctOptionsByCriteriaType[comparatorCriteria.value] && (
                            <CustomTextField width={'100%'} value={comparatorCorrect} setValue={setComparatorCorrect} placeholder="Text to contain" />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
