import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import AnimatedProgressProvider from 'components/features/analytics/animation_providers/AnimatedProgressProvider';
import { easeQuadInOut } from 'd3-ease';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Caption1 } from 'style/typography/Caption';
import { Body1, Body2 } from 'style/typography/Body';
import { H7 } from 'style/typography/Heading';
import { useTranslation } from 'react-i18next';

export function GlobalStatistics({ name, coachScore, increase, percentile, firstRowStats, secondRowStats }) {
    const { t } = useTranslation();

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
                className={'col-4 d-flex flex-column align-items-center border-right'}
                style={{ width: '200px', height: '200px', borderColor: colors.underlineColor }}
            >
                <AnimatedProgressProvider valueStart={0} valueEnd={coachScore} duration={1.4} easingFunction={easeQuadInOut}>
                    {value => {
                        const roundedValue = Math.round(value);
                        return (
                            <CircularProgressbar
                                minValue={0}
                                maxValue={1000}
                                value={value}
                                text={`${roundedValue}`}
                                circleRatio={0.75}
                                styles={buildStyles({
                                    rotation: 1 / 2 + 1 / 8,
                                    strokeLinecap: 'butt',
                                    pathColor: colors.mainTheme,
                                    textColor: 'rgba(0,0,0,1)',
                                    textSize: 32,
                                    pathTransition: 'none',
                                })}
                            />
                        );
                    }}
                </AnimatedProgressProvider>
                <div className="d-flex flex-column align-items-center" style={{ width: '60%' }}>
                    <div className="d-flex flex-row align-items-center" s>
                        <FontAwesomeIcon style={{ color: colors.mainTheme }} size={'xs'} className={'mr-1 mb-1'} icon={faArrowUp} />
                        {/*<FontAwesomeIcon color={'gray'} className={'mr-1'} icon={faArrowDown} />*/}
                        <Body2 className={'mr-1 mb-0'} color={colors.mainTheme}>
                            {t('{{increase}}% increase', { increase: (+increase).toFixed(1) })}
                        </Body2>
                    </div>
                    <Body2 className={'mb-0'}>{t('since last week')}</Body2>
                </div>
                {percentile && (
                    <div className="d-flex">
                        <Body2 className={'mt-2 mr-1'}>{t('{{name}} is in top', { name: name })}</Body2>
                        <Body2 className={'mt-2 mr-1'} color={colors.mainTheme}>
                            {percentile}%
                        </Body2>
                        <Body2 className={'mt-2'}>{t('of all players!')}</Body2>
                    </div>
                )}
            </div>
            <div style={{ height: '100%', width: '100%' }} className="ml-4">
                <div className="row mt-4 justify-content-center">
                    {firstRowStats?.map(value => {
                        return (
                            <div className="col-4 d-flex flex-row align-items-center">
                                <img style={{ width: 30, height: 30 }} src={value.icon}></img>
                                <div className="d-flex flex-column align-items-start ml-2">
                                    <div className="d-flex flex-row align-content-end h-75">
                                        <H7 weight="medium" color={colors.mainTheme} className={'mb-0'}>
                                            {value.firstValue}
                                        </H7>
                                        <Body1 weight="regular" className={'mb-0 pt-1'}>
                                            {value.secondValue}
                                        </Body1>
                                    </div>
                                    <Body2 className={'text-muted mb-0'}>{t(value.description)}</Body2>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="row mt-4 justify-content-center text-center">
                    {secondRowStats?.map(value => {
                        return (
                            <div className="col-4 d-flex flex-row align-items-center">
                                <img style={{ width: 30, height: 30 }} src={value.icon}></img>
                                <div className="d-flex flex-column align-items-start ml-2">
                                    <div className="d-flex flex-row align-content-end h-75">
                                        <H7 weight="medium" color={colors.mainTheme} className={'mb-0'}>
                                            {value.firstValue}
                                        </H7>
                                        <Body1 weight="regular" className={'mb-0 pt-1'}>
                                            {value.secondValue}
                                        </Body1>
                                    </div>
                                    <Body2 className={'text-muted mb-0'}>{t(value.description)}</Body2>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

GlobalStatistics.propTypes = {
    coachScore: PropTypes.any,
    firstRowStats: PropTypes.array,
    increase: PropTypes.any,
    name: PropTypes.any,
    percentile: PropTypes.any,
    secondRowStats: PropTypes.array,
};
