import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import Modal from '@material-ui/core/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getNodeKey } from 'services/utils/treeHelpers';
import { getFlatDataFromTree, getTreeFromFlatData } from 'components/features/trees/SortableTree';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { getFolderStructure } from 'redux/actions/image.actions';
import { H7 } from 'style/typography/Heading';
import { defaultNode } from 'static/default_nodes';
import { useTranslation } from 'react-i18next';
import SelectFolder from 'components/features/content_management/cropping_module/select_folder/SelectFolder';

import '../../Gallery.css';
import { Body1, Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';

export default function MoveToFolderModal({ modalOpen, setModalOpen, onConfirm }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const folderStructure = useSelector(state => state.content.folderStructure);

    const [treeTemp, setTreeTemp] = useState(null);
    const [treeData, setTreeData] = useState([]);

    const [currentNodeFolder, setCurrentNodeFolder] = useState(null);

    function getParentKeyNew(node) {
        return node.parentNode ? node.parentNode.treeIndex : -1;
    }

    function convertFromFlatToTree(data) {
        let myFinalResult = [];

        data.forEach((value, index) => {
            let node = {
                ...defaultNode,
                treeIndex: value.treeIndex,
                title: value.title,
                parentNode: value.parentNode,
                new: value.main,
                main: value.main,
                imageList: value.imageList,
            };
            if (node.parentNode.treeIndex === null) {
                node.parentNode.treeIndex = 0;
            }
            myFinalResult.push(node);
        });

        let myTree = getTreeFromFlatData({
            flatData: myFinalResult,
            getKey: getNodeKey,
            getParentKey: getParentKeyNew,
            rootKey: '0',
        });

        setTreeData(myTree);
    }

    if (stateIsLoaded(folderStructure)) {
        if (treeData.length === 0 && folderStructure.data.length > 0) {
            convertFromFlatToTree(folderStructure.data);
        }
    }

    useEffect(() => {
        dispatch(getFolderStructure());
    }, []);

    useEffect(() => {
        setTreeTemp(
            getFlatDataFromTree({
                ignoreCollapsed: false,
                getNodeKey: getNodeKey,
                treeData: treeData,
            })?.slice(1)
        );
    }, [treeData]);
    useEffect(() => {
        if (stateIsLoaded(folderStructure)) {
            if (treeData.length === 0 && folderStructure.data.length > 0) {
                convertFromFlatToTree(folderStructure.data);
            }
        }
    }, [folderStructure]);

    return (
        <Modal
            open={modalOpen}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className="move-to-folder-modal--container">
                <H7 className="py-2">{t('Select folder to move to')}</H7>
                <Body2 className="pb-2" color={colors.gray6}>
                    {t('The images will be moved to the selected folder')}
                </Body2>
                <div className="move-to-folder--folders-wrapper">
                    <SelectFolder
                        treeTemp={treeTemp}
                        treeData={treeData}
                        setCurrentNodeFolder={setCurrentNodeFolder}
                        currentNodeFolder={currentNodeFolder}
                    />
                </div>
                <div className="move-to-folder--buttons">
                    <DefaultButton
                        className="mr-2"
                        style={{ width: '120px' }}
                        onClick={() => {
                            onConfirm(currentNodeFolder);
                            setModalOpen(false);
                        }}
                    >
                        {t('Save')}
                    </DefaultButton>
                    <OutlinedButton
                        style={{ width: '120px' }}
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        {t('Cancel')}
                    </OutlinedButton>
                </div>
            </div>
        </Modal>
    );
}

MoveToFolderModal.propTypes = {
    modalOpen: PropTypes.any,
    onConfirm: PropTypes.func,
    setModalOpen: PropTypes.func,
};
