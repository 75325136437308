import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { ProgressWithLabel } from './ProgressWithLabel';
import { Body1 } from 'style/typography/Body';

import '../../UserAnalytics.css';

export function TrainingSelect({ title, progress, active, onClick }) {
    return (
        <div
            onClick={onClick}
            role="button"
            className={`user-analytics--training-picker-item ${active ? 'selected' : ''}`}
        >
            <Body1 color={colors.primaryText} weight={`${active ? 'bold' : 'regular'}`}>{title}</Body1>
            <ProgressWithLabel progress={progress}></ProgressWithLabel>
        </div>
    );
}

TrainingSelect.propTypes = {
    active: PropTypes.any,
    onClick: PropTypes.func,
    progress: PropTypes.any,
    title: PropTypes.any,
};
