import React, { useState } from 'react';
import { TopicsWrapper } from './topics/TopicsWrapper';
import { TrainingSettings } from './training_settings/TrainingSettings';
import { useDispatch, useSelector } from 'react-redux';

import { TrainingModulesWrapper } from './training_modules/TrainingModulesWrapper';
import { updateSingleTrainingAction } from 'redux/actions/trainings.actions';
import { tabsSidemenuTraining } from 'static/tabs';
import SideMenu from 'components/elements/sidemenu/SideMenu';
import LearnPreview from './learn_preview/LearnPreview';
import { useTranslation } from 'react-i18next';

export function SideMenuTraining({ draggingNode, setDraggingNode, selectedNode, setSelectedNode }) {
    const [activeTab, setActiveTab] = useState('training-settings');
    const [editingTitle, setEditingTitle] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const setInfo = useSelector(state => state.content.singleTraining.data.learningSetInfo);

    let tabsMapping = {
        // info: <Info />,
        'training-modules': <TrainingModulesWrapper />,
        topics: <TopicsWrapper draggingNode={draggingNode} setDraggingNode={setDraggingNode} setInfo={setInfo} />,
        'training-settings': <TrainingSettings setInfo={setInfo} />,
        'learn-preview': <LearnPreview selectedNode={selectedNode} setSelectedNode={setSelectedNode} />,
    };

    return (
        <SideMenu
            hasData={setInfo}
            isEditingInput={editingTitle}
            setEditingInput={setEditingTitle}
            inputKey={setInfo?.setId ?? setInfo?.generatedSetId}
            defaultValue={setInfo?.name}
            inputOnBlur={() => {
                setEditingTitle(false);
            }}
            inputOnChange={event => {
                dispatch(
                    updateSingleTrainingAction({
                        learningSetInfo: {
                            ...setInfo,
                            name: event.target.value,
                        },
                    }),
                    400
                );
            }}
            tabsSidemenu={tabsSidemenuTraining}
            tabsMapping={tabsMapping}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            noDataText={t('No training selected')}
        />
    );
}
