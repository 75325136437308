import Resizer from 'react-image-file-resizer';

const IMAGE_ASSETS_ENDPOINT = 'http://assets.artistika.me:8081/images/';

export const testAbsoluteUrl = url => {
    let r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
};

export const imageLink = link => {
    if (testAbsoluteUrl(link)) {
        return link;
    } else {
        return `${IMAGE_ASSETS_ENDPOINT}${link}`;
    }
};

export const resizeFileAndReduceQuality = file => {
    return new Promise(resolve => {
        Resizer.imageFileResizer(
            file,
            800,
            800,
            'JPEG',
            80,
            0,
            uri => {
                resolve(uri);
            },
            'blob'
        );
    });
};

export const resizeFile = file =>
    new Promise(resolve => {
        Resizer.imageFileResizer(
            file,
            600,
            600,
            'JPEG',
            100,
            0,
            uri => {
                resolve(uri);
            },
            'blob'
        );
    });

export function getAllSelectedImages(selectedImages) {
    return selectedImages
        ? Object.keys(selectedImages)
              .map(el => selectedImages[el])
              .flat()
        : [];
}

export const areImagesEqual = (el1, el2) => {
    return el1?.id === el2?.id;
};
