import React, { useEffect } from 'react';
import { useContentInsertionQuestion, useAnswerTextOptions, useInsertionState } from 'services/content-management/insertion-control';
import { optionDefaults, questionTypes } from 'services/content-management/insertion-control/local-constants';
import { InputContentWrapper } from 'components/wrappers/InputContentWrapper';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import { validatorCategories } from 'services/validation-module/local-constants';
import RecallInputs from './RecallInputs';
import RecallPreview from './RecallPreview';

const AddQuestionRecall = ({ game, id, editIncomplete, filters }) => {
    const { setQuestion } = useContentInsertionQuestion();
    const { setOptions } = useAnswerTextOptions();
    const insertionState = useInsertionState();

    const initializeQuestion = () => {
        if (insertionState.currentType === questionTypes.ANSWER_TEXT) {
            setOptions(
                Array(3)
                    .fill(0)
                    .map((_, index) => ({ ...optionDefaults[questionTypes.ANSWER_TEXT], id: index }))
            );
            setQuestion('');
        }
    };
    useEffect(() => {
        initializeQuestion();
    }, [insertionState.currentType]);

    return (
        <div className="d-flex flex-row h-100 m-0">
            <InputContentWrapper id={id} filters={filters}>
                <div className="d-flex flex-row w-100 h-100">
                    <RecallInputs game={game} id={id} filters={filters} initializeQuestion />
                    <RecallPreview />
                </div>
                <ValidationPopup category={validatorCategories.CORRECT_ANSWER_REQUIRED} position="right" />
            </InputContentWrapper>
        </div>
    );
};

export default AddQuestionRecall;
