import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import { stateIsLoading } from 'redux/core/stateHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { uploadTrainingAction } from 'redux/actions/trainings.actions';
import { Body1 } from 'style/typography/Body';
import { colors } from 'style/colors';
import { useTranslation } from 'react-i18next';

export function UploadTraining() {
    const dispatch = useDispatch();

    const uploadTrainingState = useSelector(state => state.content.uploadTraining);
    const uploadTrainingProgressState = useSelector(state => state.content.uploadingTrainingProgress);

    const { t } = useTranslation();

    const [file, setFile] = useState(null);

    const handleFileChange = files => {
        if (files.length > 0) {
            setFile(files[0]);
        } else {
            setFile(null);
        }
    };

    const fileUpload = () => {
        if (file !== null) {
            dispatch(uploadTrainingAction(file));
        }
    };

    return (
        <div>
            {stateIsLoading(uploadTrainingState) && (
                <div style={{ height: '40vh' }} className="row justify-content-center align-items-center">
                    <div className="d-flex flex-column text-center" style={{ width: '40vw' }}>
                        <Body1 color={colors.dark}>{t(uploadTrainingProgressState?.data?.currentProcess)}</Body1>
                        <div className="progress bg-white mt-2 w-100">
                            <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: `${uploadTrainingProgressState?.data?.progress}%`, height: 20 }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                {uploadTrainingProgressState?.data?.progress}%
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!stateIsLoading(uploadTrainingState) && (
                <div style={{ overflow: 'scroll' }} className="column">
                    <div className="mt-2 d-flex justify-content-center">
                        <Button
                            id="upload-questions-list-button"
                            disabled={stateIsLoading(uploadTrainingState) || file === null}
                            onClick={fileUpload}
                            className={'ml-2'}
                            variant="contained"
                            color="secondary"
                            style={{ textTransform: 'none' }}
                            startIcon={<PublishIcon />}
                        >
                            {t('Upload content')}
                        </Button>
                    </div>

                    <div className="mt-5 mx-3" style={{ width: '50vw' }} id="upload-area-button">
                        <FileUploader
                            dropzoneText={<Typography variant="subtitle1">{t('Drag and drop the file here or click to upload')}</Typography>}
                            acceptedFiles={['.xls', '.xlsx']}
                            filesLimit={1}
                            onChange={handleFileChange}
                            dropzoneClass="users-upload"
                        ></FileUploader>
                    </div>
                </div>
            )}
        </div>
    );
}

UploadTraining.propTypes = {
    resetModalState: PropTypes.func,
};
