import React from 'react';
import NewAITraining from 'assets/icons/ai-training-new.svg';
import { H4 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { DefaultButton } from 'components/elements/Button';

import '../AITraining.css';
import { colors } from 'style/colors';
import { Trans, useTranslation } from 'react-i18next';

export default function GenerateNewTraining({ setGenerateNew }) {
    const { t } = useTranslation();

    return (
        <div className="ai-training--new-training-container">
            <div className="ai-training--new-training-items">
                <img alt="New Training Word" src={NewAITraining} />
                <H4 color={colors.dark} weight="light" style={{ padding: '20px 0' }}>
                    <Trans>{t('Generate a whole new training <br /> with the click of a button!')}</Trans>
                </H4>
                <Body2 color={colors.dark} weight="light">
                    <Trans>
                        {t(
                            'Our specialized AI can create an entire training for you, complete with topics and questions that will be ready for playing instantly in the app.This way, you can save countless of hours on creating a training from scratch.<br /><br /> All you need to do is upload your already written Word (.docx) file with content, and let us do the rest of the magic while you go about your day.'
                        )}
                    </Trans>
                </Body2>
                <DefaultButton onClick={() => setGenerateNew(true)} style={{ margin: '30px 0' }} text={t('Generate training from file')} />
            </div>
        </div>
    );
}
