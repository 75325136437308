import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper/Paper';
import { colors } from 'style/colors';
import { makeStyles } from '@material-ui/core';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { Subtitle1, Subtitle2 } from 'style/typography/Subtitle';
import Close from 'assets/icons/filter-close.svg';
import CustomModal from 'components/features/modal/CustomModal.js/CustomModal';
import { deleteTrainingAction, getAllTrainingsAction, removeTrainingAction, resetDeleteTrainingAction } from 'redux/actions/trainings.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Body2 } from 'style/typography/Body';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { Caption1 } from 'style/typography/Caption';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { getAllRoles } from 'redux/actions/roles.actions';
import { Alert } from '@material-ui/lab';
import { deleteStructureOnlyText, deleteTopicsAndQuestionsText, deleteTopicsText, deleteTrainingTitlePerStep } from './deleteTrainingConst';

import { useTranslation } from 'react-i18next';
import SuccessfullyDeleted from 'assets/icons/successfully-deleted.svg';
import { resetUpdateLocalTopicChanges } from 'redux/actions/topics.actions';

import './DeleteTrainingDialog.css';

export function DeleteTrainingDialog({ modalOpen, onModalClose, currentSetId, isNew, selectedTraining }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [deleteTopics, setDeleteTopics] = useState(false);
    const [deleteQuestions, setDeleteQuestions] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const deleteTrainingState = useSelector(state => state.content.deleteTraining);

    function deleteTraining() {
        if (isNew) {
            dispatch(removeTrainingAction(selectedTraining));
            setCurrentStep(2);
            return;
        }

        let data = new FormData();
        data.set('deleteStructureOnly', !deleteTopics);
        data.set('deleteQuestions', deleteQuestions && deleteTopics);
        dispatch(deleteTrainingAction(currentSetId, data));
    }

    useEffect(() => {
        if (stateIsLoaded(deleteTrainingState)) {
            dispatch(getAllTrainingsAction());
            dispatch(resetUpdateLocalTopicChanges());
            dispatch(resetDeleteTrainingAction());
            dispatch(getAllRoles());
            setCurrentStep(2);
        }
    }, [deleteTrainingState]);

    return (
        <CustomModal
            modalOpen={modalOpen}
            setModalOpen={_ => {
                onModalClose();
            }}
        >
            <Paper style={{ width: '30vw', border: `solid 1px ${colors.gray4}` }} className={`${classes.paper}`}>
                <div className="col-12 d-flex flex-row p-3">
                    <div className="d-flex flex-column w-100">
                        <Subtitle1 weight="medium">{t(deleteTrainingTitlePerStep[currentStep])}</Subtitle1>
                        {/* <Body2>Are you sure you want to delete this training?</Body2> */}
                        {currentStep === 0 && (
                            <div>
                                <Alert style={{ margin: '5px 0' }} severity="error">
                                    {t('Please consider this carefully before deleting the training')}
                                </Alert>
                                <div className="delete-training--checkbox-item">
                                    <div className="delete-training--checkbox">
                                        <Checkbox onClick={() => {}} checked={true}></Checkbox>
                                    </div>
                                    <Body2>{t('Delete Training Structure')}</Body2>
                                </div>
                                <div className="delete-training--checkbox-item">
                                    <div className="delete-training--checkbox">
                                        <Checkbox onClick={e => setDeleteTopics(prevState => !prevState)} checked={deleteTopics}></Checkbox>
                                    </div>
                                    <Body2>{t('Delete Topics')}*</Body2>
                                </div>
                                <div className="delete-training--checkbox-item">
                                    <div className="delete-training--checkbox">
                                        <Checkbox
                                            onClick={e => setDeleteQuestions(prevState => !prevState)}
                                            checked={deleteQuestions && deleteTopics}
                                            disabled={!deleteTopics}
                                        ></Checkbox>
                                    </div>
                                    <Body2>{t("Delete Questions")}*</Body2>
                                </div>

                                <Caption1 style={{ marginTop: 10 }} color={colors.gray6}>
                                    *{t("If you delete the questions and topics all the insights, user analytics and statistics will be recalculated to update this change.")}
                                </Caption1>
                            </div>
                        )}
                        {currentStep === 1 && (
                            <Body2 style={{ padding: '20px 0' }}>
                                {t(deleteTopics ? (deleteQuestions ? deleteTopicsAndQuestionsText : deleteTopicsText) : deleteStructureOnlyText)}
                            </Body2>
                        )}
                        {currentStep === 2 && (
                            <div className="delete-training--success">
                                <img alt="Success" src={SuccessfullyDeleted} height={70} width={70} />
                                <Subtitle2 style={{ padding: '10px 0' }}>{t("Training successfully deleted!")}</Subtitle2>
                            </div>
                        )}
                    </div>

                    <img
                        alt="Close"
                        onClick={() => {
                            onModalClose();
                        }}
                        className={'ml-auto cursor-pointer'}
                        height={17}
                        width={17}
                        src={Close}
                    />
                </div>
                <div className={'col-12 pr-3 pl-3 d-flex flex-row justify-content-center mt-1 mb-3'}>
                    <DefaultButton
                        id="edit-tag-save-button"
                        text={currentStep === 1 ? t('Confirm and Delete') : t('Continue')}
                        onClick={() => {
                            if (currentStep === 0) {
                                setCurrentStep(1);
                            } else if (currentStep === 1) {
                                deleteTraining();
                            } else {
                                onModalClose();
                            }
                        }}
                        disabled={stateIsLoading(deleteTrainingState)}
                        className={'mr-1'}
                    />

                    <OutlinedButton
                        id="edit-tag-cancel-button"
                        text={t("Cancel")}
                        onClick={() => {
                            onModalClose();
                        }}
                        className={'ml-1'}
                    />
                </div>
            </Paper>
        </CustomModal>
    );
}

DeleteTrainingDialog.propTypes = {
    editedTag: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onModalClose: PropTypes.func,
};

const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: colors.gray4,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
        },
    };
});
