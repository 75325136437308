import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper/Paper';
import { colors } from 'style/colors';
import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { Subtitle1 } from 'style/typography/Subtitle';
import { Body2 } from 'style/typography/Body';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { useDispatch, useSelector } from 'react-redux';
import { create_UUID } from 'services/utils/stringHelpers';

import Close from 'assets/icons/filter-close.svg';
import { updateSingleTrainingAction } from 'redux/actions/trainings.actions';
import { defaultLearningSetInfo, defaultRootNode } from 'static/default_nodes';
import { useTranslation } from 'react-i18next';

export function NewTrainingModal({ modalOpen, setModalOpen, setTraining }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [newTrainingTitle, setNewTrainingTitle] = useState('');
    const allTopicsFromCompanyState = useSelector(state => state.content.topics.allTopicsFromCompany);

    const createNewTraining = () => {
        let newSetId = create_UUID();
        let rootTopicId = create_UUID();

        let rootNode = {
            ...defaultRootNode,
            title: newTrainingTitle,
            titleForTraining: newTrainingTitle,

            dateCreated: Date.now(),
            timeCreated: Date.now(),
            generatedTreeIndex: rootTopicId,
        };

        dispatch(
            updateSingleTrainingAction({
                treeStructure: [rootNode],
                categoryTree: [],
                learningSetInfo: {
                    ...defaultLearningSetInfo,
                    name: newTrainingTitle,
                    generatedSetId: newSetId,
                    totalCategories: allTopicsFromCompanyState.data.length,
                },
            })
        );
        setNewTrainingTitle('');
        setTraining(newSetId);
    };

    return (
        <Modal
            open={modalOpen}
            className={classes.modal}
            onClose={() => {
                setNewTrainingTitle('');
                setModalOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper style={{ width: '40vw', border: `solid 1px ${colors.gray4}` }} className={`${classes.paper} row`}>
                <div className="col-12 d-flex flex-row align-items-start p-3">
                    <div className="d-flex flex-column">
                        <Subtitle1 weight="medium" color={colors.dark}>
                            {t('Add new training')}
                        </Subtitle1>
                        <Body2 className={'mt-1'} color={colors.dark}>
                            {t('Choose a title for your new training and proceed to add topics into it.')}
                        </Body2>
                    </div>
                    <img alt="Close" src={Close} onClick={() => setModalOpen(false)} className="ml-auto cursor-pointer" />
                </div>
                <div className={'col-12 d-flex flex-column mt-2'}>
                    <CustomOutlinedTextField
                        id="new-training-modal-title-input"
                        width={'100%'}
                        value={newTrainingTitle}
                        setValue={setNewTrainingTitle}
                        placeholder={t('Enter title...')}
                    ></CustomOutlinedTextField>
                </div>
                <div className={'col-12 pr-3 pl-3 d-flex flex-row justify-content-center mt-4 mb-3'}>
                    <DefaultButton
                        id="new-training-modal-add-training-button"
                        onClick={() => {
                            setModalOpen(false);
                            createNewTraining();
                        }}
                        text={t('Add training')}
                        className={'mr-1'}
                    ></DefaultButton>
                    <OutlinedButton
                        id="new-training-modal-cancel-button"
                        onClick={() => setModalOpen(false)}
                        text={t('Cancel')}
                        className={'ml-1'}
                    ></OutlinedButton>
                </div>
            </Paper>
        </Modal>
    );
}

NewTrainingModal.propTypes = {
    modalOpen: PropTypes.any,
    setModalOpen: PropTypes.func,
    setTraining: PropTypes.func,
};
const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: colors.gray4,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});
