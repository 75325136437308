import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { colors } from 'style/colors';

import Search from 'assets/icons/filter-search.svg';

import './CustomTextField.css';

export function CustomTextField({
    width = 220,
    height,
    type = 'text',
    error,
    placeholder,
    setError,
    value,
    setValue,
    disabled,
    visibilityIcon,
    multiline,
    onPaste,
    isSearch,
    onBlur,
    font = 14,
    style = {},
}) {
    const [showValue, setShowValue] = useState(false);

    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        const input = ref.current;
        if (input && type !== 'number') input.setSelectionRange(cursor, cursor);
    }, [ref, cursor, value, type]);

    const handleChange = e => {
        setCursor(e.target.selectionStart);
        setValue && setValue(e.target.value);
    };

    return (
        <>
            <div
                className="d-flex flex-row custom-text-field"
                style={{
                    backgroundColor: 'white',
                    flex: 1,
                    padding: 4,
                    paddingLeft: 10,
                    justifyContent: 'space-between',
                    maxWidth: width,
                    width: width,
                    borderRadius: '3px',
                    border: error ? '1px solid red' : 'none',
                    fontColor: error ? 'red' : null,
                    ...style,
                }}
            >
                {!multiline && (
                    <input
                        id="standard-adornment-password"
                        ref={ref}
                        value={value ? value : ''}
                        disabled={disabled}
                        onFocus={() => {
                            if (setError) {
                                setError(false);
                            }
                        }}
                        type={type === 'password' ? (showValue ? 'text' : 'password') : type}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onPaste={onPaste}
                        onBlur={onBlur}
                        style={{
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            height: height ? height : 30,
                            fontWeight: 0,
                            fontSize: font + 'px',
                            color: disabled ? colors.gray6 : colors.dark,
                            backgroundColor: 'white',
                        }}
                    />
                )}
                {multiline && (
                    <textarea
                        value={value ? value : ''}
                        disabled={disabled}
                        ref={ref}
                        onChange={handleChange}
                        onPaste={onPaste}
                        style={{
                            border: 'none',
                            outline: 'none',
                            resize: 'none',
                            width: '100%',
                            height: height ? height : 30,
                            fontWeight: 0,
                            fontSize: font + 'px',
                            color: colors.dark,
                        }}
                        className="custom-text-field-textarea"
                        name="Text1"
                        cols="40"
                        rows="5"
                        placeholder={placeholder}
                    ></textarea>
                )}
                <div className="d-flex flex-row align-items-center">
                    {isSearch && <img alt="Search" style={{ height: 20, width: 20 }} className={'mr-1'} color={colors.gray1} src={Search} />}
                    {visibilityIcon && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowValue(!showValue)}
                                onMouseDown={event => event.preventDefault()}
                            >
                                {showValue ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )}
                </div>
            </div>
        </>
    );
}

CustomTextField.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.any,
    font: PropTypes.number,
    height: PropTypes.any,
    isSearch: PropTypes.bool,
    multiline: PropTypes.bool,
    onPaste: PropTypes.func,
    placeholder: PropTypes.any,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    style: PropTypes.object,
    type: PropTypes.string,
    value: PropTypes.any,
    visibilityIcon: PropTypes.any,
    width: PropTypes.any,
};
