import { ApiExecutor } from './ApiExecutor';
import {
    getTagsApiRequest,
    updateLabelsApiRequest,
    createTagApiRequest,
    getLabelsApiRequest,
    getLabelByIdApiRequest,
    deleteLabelByIdApiRequest,
    getUsersWithTagApiRequest,
    getUsersWithoutTagWithIdApiRequest,
    addUserToTagApiRequest,
    removeUserFromTagApiRequest,
    addUsersToTagsApiRequest,
    downloadUserTagsTemplate,
    uploadUserTagsApiRequest,
    deleteTagByIdApiRequest,
    removeUsersFromTagsApiRequest,
} from 'services/api/apiRequests/content';

import {
    GET_TAGS,
    UPDATE_LABELS,
    CREATE_TAG,
    GET_LABELS,
    GET_LABEL_BY_ID,
    DELETE_LABEL_BY_ID,
    GET_USERS_WITHOUT_TAG_WITH_ID,
    GET_USERS_WITH_TAG,
    ADD_USER_TO_TAG,
    REMOVE_USER_FROM_TAG,
    ADD_USERS_TO_TAGS,
    UPLOAD_USER_TAGS,
    UPLOAD_USER_TAGS_PROGRESS,
    DELETE_TAG_BY_ID,
    REMOVE_USERS_FROM_TAGS,
} from '../constants/content.constants';

import { buildActionType } from './buildActionType';
import { ActionStatus } from '../core/ActionStatus';
import { REMOVE_USERS_FROM_TAGS_API } from 'services/api/api_links';

export const getTagsAction = () => async dispatch => {
    await dispatch(ApiExecutor(getTagsApiRequest(), GET_TAGS));
};

export const uploadUserTagsAction = file => async dispatch => {
    dispatch({ type: buildActionType(UPLOAD_USER_TAGS, ActionStatus.START) });
    await dispatch(ApiExecutor(uploadUserTagsApiRequest(file), UPLOAD_USER_TAGS));
};

export const resetUploadUserTagsAction = file => async dispatch => {
    dispatch({ type: buildActionType(UPLOAD_USER_TAGS, ActionStatus.RESET) });
};

export const downloadUserTagsTemplateAction = () => async dispatch => {
    await downloadUserTagsTemplate();
};

export const getLabelsAction = (queryData) => async dispatch => {
    await dispatch(ApiExecutor(getLabelsApiRequest(queryData), GET_LABELS));
};

export const updateLabelsAction = data => async dispatch => {
    dispatch({ type: buildActionType(UPDATE_LABELS, ActionStatus.START) });

    await dispatch(ApiExecutor(updateLabelsApiRequest(data), UPDATE_LABELS));
};

export const resetUpdateLabelsAction = () => async dispatch => {
    await dispatch({ type: buildActionType(UPDATE_LABELS, ActionStatus.RESET) });
};

export const createTagAction = data => async dispatch => {
    await dispatch(ApiExecutor(createTagApiRequest(data), CREATE_TAG));
};

export const resetCreateTagAction = data => async dispatch => {
    await dispatch({ type: buildActionType(CREATE_TAG, ActionStatus.RESET) });
};

export const getLabelByIdAction = data => async dispatch => {
    await dispatch(ApiExecutor(getLabelByIdApiRequest(data), GET_LABEL_BY_ID));
};

export const resetLabelByIdAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_LABEL_BY_ID, ActionStatus.RESET),
    });
};

export const deleteLabelByIdAction = id => async dispatch => {
    await dispatch(ApiExecutor(deleteLabelByIdApiRequest(id), DELETE_LABEL_BY_ID));
};

export const deleteTagByIdAction = id => async dispatch => {
    await dispatch(ApiExecutor(deleteTagByIdApiRequest(id), DELETE_TAG_BY_ID));
};

export const resetDeleteTagByIdAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(DELETE_TAG_BY_ID, ActionStatus.RESET),
    });
};
export const getUsersWithTagAction = (tagId, queryData) => async (dispatch, getState) => {
    let state = getState().content.usersWithTag;
    let prevData = state.data;

    dispatch({ type: buildActionType(GET_USERS_WITH_TAG, ActionStatus.START) });

    let response = await getUsersWithTagApiRequest(tagId, queryData);
    if (response.success) {
        let usersData = response.data.content;

        if (queryData.page > prevData.page) {
            usersData.unshift(...prevData.users);
        }

        dispatch({
            type: buildActionType(GET_USERS_WITH_TAG, ActionStatus.DONE),
            payload: {
                tagId: tagId,
                users: usersData,
                page: response.data.number,
                totalPages: response.data.totalPages,
                totalUsers: response.data.totalElements,
            },
        });
    } else {
        dispatch({
            type: buildActionType(GET_USERS_WITH_TAG, ActionStatus.FAILED),
        });
    }
};

export const resetGetUsersWithTagAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_USERS_WITH_TAG, ActionStatus.RESET),
    });
};

export const getUsersWithoutTagWithIdAction = (tagId, queryData) => async (dispatch, getState) => {
    let state = getState().content.usersWithoutTagWithId;
    let prevData = state.data;

    dispatch({ type: buildActionType(GET_USERS_WITHOUT_TAG_WITH_ID, ActionStatus.START) });

    let response = await getUsersWithoutTagWithIdApiRequest(tagId, queryData);
    if (response.success) {
        let usersData = response.data.content;

        if (queryData.page > prevData.page) {
            usersData.unshift(...prevData.users);
        }

        dispatch({
            type: buildActionType(GET_USERS_WITHOUT_TAG_WITH_ID, ActionStatus.DONE),
            payload: { tagId: tagId, users: usersData, page: response.data.number, totalPages: response.data.totalPages },
        });
    } else {
        dispatch({
            type: buildActionType(GET_USERS_WITHOUT_TAG_WITH_ID, ActionStatus.FAILED),
        });
    }
};

export const resetGetUsersWithoutTagWithIdAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(GET_USERS_WITH_TAG, ActionStatus.RESET),
    });
};

export const addUserToTagAction = (tagId, userId) => async dispatch => {
    await dispatch(ApiExecutor(addUserToTagApiRequest(tagId, userId), ADD_USER_TO_TAG));
};

export const removeUserFromTagAction = (tagId, userId) => async dispatch => {
    await dispatch(ApiExecutor(removeUserFromTagApiRequest(tagId, userId), REMOVE_USER_FROM_TAG));
};

export const resetRemoveUserFromTagAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(REMOVE_USER_FROM_TAG, ActionStatus.RESET),
    });
};

export const addUsersToTagsAction = (users, tags, allUsersSelected) => async dispatch => {
    await dispatch(ApiExecutor(addUsersToTagsApiRequest(users, tags, allUsersSelected), ADD_USERS_TO_TAGS));
};

export const removeUsersFromTagsAction = (users, tags, allUsersSelected) => async dispatch => {
    await dispatch(ApiExecutor(removeUsersFromTagsApiRequest(users, tags, allUsersSelected), REMOVE_USERS_FROM_TAGS));
};

export const resetAddUsersToTagsAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(ADD_USERS_TO_TAGS, ActionStatus.RESET),
    });
};

export const resetRemoveUsersFromTagsAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(REMOVE_USERS_FROM_TAGS, ActionStatus.RESET),
    });
};

export const resetUpdateUserInTag = () => async dispatch => {
    await dispatch({
        type: buildActionType(ADD_USER_TO_TAG, ActionStatus.RESET),
    });
    await dispatch({
        type: buildActionType(REMOVE_USER_FROM_TAG, ActionStatus.RESET),
    });
};

export const updateUserTagsProgressAction = requestProgress => async dispatch => {
    dispatch({
        type: buildActionType(UPLOAD_USER_TAGS_PROGRESS, ActionStatus.DONE),
        payload: requestProgress,
    });
};
export const resetUpdateUserTagsProgressAction = () => async dispatch =>
    dispatch({
        type: buildActionType(UPLOAD_USER_TAGS_PROGRESS, ActionStatus.RESET),
    });
