import { Action } from '../core/Action';

export const GET_USERS = { entity: 'GET_USERS', action: Action.GET_MANY };
export const GET_USERS_PAGINATED = { entity: 'GET_USERS_PAGINATED', action: Action.GET_MANY };
export const GET_USERS_WITH_UNASSIGNED_ROLE = { entity: 'GET_USERS_WITH_UNASSIGNED_ROLE', action: Action.GET_MANY };
export const GET_USERS_WITH_ROLE = { entity: 'GET_USERS_WITH_ROLE', action: Action.GET_MANY };
export const GET_COMPANY = { entity: 'GET_COMPANY', action: Action.GET };
export const DELETE_USER = { entity: 'DELETE_USER', action: Action.DELETE };
export const DELETE_USERS_IN_BATCH = { entity: 'DELETE_USERS_IN_BATCH', action: Action.DELETE };
export const ACTIVATE_USER = { entity: 'ACTIVATE_USER', action: Action.HANDLE };
export const EDIT_USER = { entity: 'EDIT_USER', action: Action.HANDLE };
export const EDIT_USERS_IN_BATCH = { entity: 'EDIT_USER_IN_BATCH', action: Action.HANDLE };
export const EDIT_USERS_ROLE_IN_BATCH = { entity: 'EDIT_USERS_ROLE_IN_BATCH', action: Action.HANDLE };
export const INVITE_USER = { entity: 'INVITE_USER', action: Action.CREATE };
export const REINVITE_USERS = { entity: 'REINVITE_USERS', action: Action.CREATE };
export const DEACTIVATE_USER = { entity: 'DEACTIVATE_USER', action: Action.HANDLE };
export const DEACTIVATE_USERS_IN_BATCH = { entity: 'DEACTIVATE_USERS_IN_BATCH', action: Action.HANDLE };
export const GET_SINGLE_USER = { entity: 'GET_SINGLE_USER', action: Action.GET };
export const GET_ALL_COMPANY_ROLES = { entity: 'GET_ALL_COMPANY_ROLES', action: Action.GET_MANY };
export const GET_COMPANY_ROLES_PAGINATED = { entity: 'GET_COMPANY_ROLES_PAGINATED', action: Action.GET_MANY };
export const GET_ROLES_IN_SET = { entity: 'GET_ROLES_IN_SET', action: Action.GET_MANY };
export const USERS_MODIFIED_IN_ROLES = { entity: 'USERS_IN_ROLES', action: Action.GET_MANY };
export const GET_SINGLE_ROLE = { entity: 'GET_SINGLE_COMPANY_ROLE', action: Action.GET };
export const SAVE_ALL_ROLES = { entity: 'SAVE_ALL_ROLES', action: Action.UPDATE };
export const SAVE_ALL_ROLES_NEW = { entity: 'SAVE_ALL_ROLES', action: Action.UPDATE };
export const GET_ALL_AUTH_ROLES = { entity: 'GET_ALL_AUTH_ROLES', action: Action.GET_MANY };
export const GET_ROLES_CONNECTED_TO_SET = { entity: 'GET_ROLES_CONNECTED_TO_SET', action: Action.GET_MANY };
export const INVITE_MULTIPLE_USERS = { entity: 'INVITE_MULTIPLE_USERS', action: Action.CREATE };
export const INVITE_MULTIPLE_USERS_PROGRESS = { entity: 'INVITE_MULTIPLE_USERS_PROGRESS', action: Action.UPDATE };
export const INVITE_MULTIPLE_USERS_ADMIN = { entity: 'INVITE_MULTIPLE_USERS_ADMIN', action: Action.CREATE };
export const GET_ACTIVE_USERS_COUNT_FOR_MONTH = { entity: 'GET_ACTIVE_USER_COUNT_FOR_MONTH', action: Action.GET };
export const UPLOAD_USERS = { entity: 'UPLOAD_USERS', action: Action.CREATE };
export const DOWNLOAD_TEMPLATE = { entity: 'DOWNLOAD_TEMPLATE', action: Action.GET };

export const HANDLE_NEXT_ERROR = { entity: 'NEXT_ERROR', action: Action.HANDLE };

export const HANDLE_USERS_INVITE = { entity: 'USERS_INVITE', action: Action.HANDLE };
export const HANDLE_ADMINS_INVITE = { entity: 'ADMINS_INVITE', action: Action.HANDLE };

export const REGISTER_ADMINISTRATOR = { entity: 'REGISTER_ADMINISTRATOR', action: Action.HANDLE };
export const FORGOT_PASSWORD_REQUEST = { entity: 'FORGOT_PASSWORD_REQUEST', action: Action.HANDLE };
export const FORGOT_PASSWORD_SET_NEW = { entity: 'FORGOT_PASSWORD_SET_NEW', action: Action.HANDLE };
export const CHANGE_PASSWORD = { entity: 'CHANGE_PASSWORD', action: Action.HANDLE };

export const nonAuthRequiredConsts = [REGISTER_ADMINISTRATOR.entity, FORGOT_PASSWORD_REQUEST.entity, FORGOT_PASSWORD_SET_NEW.entity];


// User devies
export const GET_DEVICE_REQUESTS_PAGINATED = { entity: 'GET_DEVICE_REQUESTS_PAGINATED', action: Action.GET_MANY };
export const RESOLVE_DEVICE_REQUESTS_BULK = { entity: 'RESOLVE_DEVICE_REQUESTS_BULK', action: Action.UPDATE };
export const DELETE_DEVICE_REQUESTS_BULK = { entity: 'DELETE_DEVICE_REQUESTS_BULK', action: Action.UPDATE };
export const GET_DEVICE_REQUESTS_FOR_USER = { entity: 'GET_DEVICE_REQUESTS_FOR_USER', action: Action.GET_MANY };
