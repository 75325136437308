import React from 'react';
import { useThemePalette } from 'style/theme/consumers';
import { ag_bold, ag_medium, ag_regular, ag_light } from 'style/theme/mui-theme/overrides/fonts';

function Text(weight, tagDefaults, fontWeight, fontHeight, color, style, attr) {
    const palette = useThemePalette();
    const fontFamily = (weight == 'light' && ag_light) || (weight == 'medium' && ag_medium) || (weight == 'bold' && ag_bold) || ag_regular;

    return (
        <div
            {...attr}
            style={{
                fontFamily: fontFamily,
                fontWeight: fontWeight || fontFamily.fontWeight,
                color: color || palette.secondary.main,
                ...tagDefaults,
                ...style,
            }}
        >
            {attr.children}
        </div>
    );
}

export default Text;
