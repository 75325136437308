import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { saveAssetApiRequest, saveImageToFolderApiRequest, uploadImageApiRequest } from 'services/api/apiRequests/content';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import { InternalLessonEditorDialog } from './InternalLessonEditorDialog';
import { useTranslation } from 'react-i18next';

import htmlToDraft from 'html-to-draftjs';
import colorImg from 'assets/icons/colors-icon-4.png';
import { editorToolbar } from 'static/editor_toolbar';
import ExpandIcon from 'assets/icons/expand-icon.svg';

import './InternalLesson.css';

export function InternalLesson({ selectedNode, lessonContent, setLessonContent }) {
    const ref = useRef(null);
    const { t } = useTranslation();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    let editorState = EditorState.createEmpty();

    if (typeof lessonContent === 'string') {
        const html = `${lessonContent}`;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);
        }
    }

    const uploadCallback = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                let data = new FormData();
                data.append('urlOriginal', file);
                // data.set('folder', 'lessons');
                // data.set('replaceIfExists', false);
                let result = await saveImageToFolderApiRequest(data);

                resolve({ data: { link: result.data.urlOriginal } });
            };
            reader.readAsDataURL(file);
        });
    };

    const onEditorStateChange = content => {
        setLessonContent(content);
    };

    const toolbar = {
        ...editorToolbar,
        image: {
            ...editorToolbar.image,
            uploadCallback: uploadCallback,
            alignmentEnabled: false,
            defaultSize: {
                height: 'auto',
                width: '100%',
            },
        },
        colorPicker: {
            ...editorToolbar.colorPicker,
            icon: colorImg,
        },
    };

    return (
        <div key={selectedNode?.nodeIdentifier} className={'mt-3 d-flex flex-column'}>
            {isDialogOpen && (
                <InternalLessonEditorDialog
                    modalOpen={isDialogOpen}
                    onModalClose={() => setIsDialogOpen(false)}
                    editorState={editorState}
                    lessonContent={lessonContent}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={toolbar}
                    title={selectedNode?.title}
                />
            )}
            <div className="internal-lesson--align-caption">
                <Caption1 weight="medium" color={colors.gray6}>
                    {t('CONTENT')}
                </Caption1>

                <div className="internal-lesson--align-caption-expand" onClick={() => setIsDialogOpen(true)}>
                    <img alt={'Expand'} style={{ marginRight: 5 }} src={ExpandIcon} height={15} width={15} />
                    <Caption1 color={colors.gray6}>{t('Expand')}</Caption1>
                </div>
            </div>

            {!isDialogOpen && (
                <Editor
                    ref={ref}
                    editorState={typeof lessonContent === 'string' ? editorState : lessonContent}
                    initialEditorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorStyle={{
                        height: '30vh',
                        maxHeight: '30vh',
                        minHeight: '30vh',
                        width: '37vw',
                        maxWidth: '37vw',
                    }}
                    toolbar={toolbar}
                    onEditorStateChange={onEditorStateChange}
                    stripPastedStyles={true}
                />
            )}
        </div>
    );
}

InternalLesson.propTypes = {
    lessonContent: PropTypes.any,
    selectedNode: PropTypes.any,
    setLessonContent: PropTypes.func,
};
