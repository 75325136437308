import PropTypes from 'prop-types';
import React from 'react';
import { useAnswerTextOptions } from 'services/content-management/insertion-control';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import { validatorCategories } from 'services/validation-module/local-constants';
import { InputWithInfoPopUpWrapper } from 'components/features/content_management/inputs/InputWithInfoPopUpWrapper';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { useTranslation } from 'react-i18next';

const InputAnswerWithPosition = ({ value, index, position, draggableHandleProps, style }) => {
    const { updateAnswer: updateOption } = useAnswerTextOptions();

    const { t } = useTranslation();

    return (
        <div style={style}>
            <div className="row">
                <div className="col-12">
                    <div className="input-group">
                        <div {...draggableHandleProps} className="input-group-prepend">
                            <span className="input-group-text">
                                <DragHandleIcon></DragHandleIcon>
                            </span>
                        </div>

                        <InputWithInfoPopUpWrapper index={index} answer={value}>
                            {({ stopInput, onChange }) => {
                                return (
                                    <input
                                        id={'input-options-sequence-' + index}
                                        type="text"
                                        placeholder={t('Value')}
                                        className="form-control"
                                        value={value}
                                        style={{
                                            fontSize: '15px',
                                        }}
                                        onChange={e =>
                                            onChange(e, () => updateOption({ index, element: { text: e.target.value, position: position } }))
                                        }
                                    />
                                );
                            }}
                        </InputWithInfoPopUpWrapper>
                    </div>
                </div>
            </div>
            <ValidationPopup category={validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY} position="left" index={index} indexed />
        </div>
    );
};

InputAnswerWithPosition.propTypes = {
    draggableHandleProps: PropTypes.any,
    index: PropTypes.any,
    position: PropTypes.any,
    style: PropTypes.any,
    value: PropTypes.any,
};

export default InputAnswerWithPosition;
