import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContentBlockById } from 'redux/actions/content.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useContentBlocksBuilder } from 'services/content-blocks-management/content-blocks-builder';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

export default function ContentBlocksPicker() {
    const [searchValue, setSearchValue] = useState();

    const { setContentBlock } = useContentBlocksBuilder();

    const contentBlocksPaginatedState = useSelector(state => state.content.contentBlocksPaginated);
    const contentBlockByIdState = useSelector(state => state.content.contentBlockById);

    const dispatch = useDispatch();

    function getContentBlock(id) {
        dispatch(getContentBlockById(id));
    }

    useEffect(() => {
        if (stateIsLoaded(contentBlockByIdState) && contentBlockByIdState.data){
            setContentBlock(contentBlockByIdState.data);
        };
    }, [contentBlockByIdState])

    if (!stateIsLoaded(contentBlocksPaginatedState)) {
        return <Body2>Loading..</Body2>
    }
    return (
        <div className="cb-picker--container">
            <CustomTextField value={searchValue} setValue={setSearchValue} placeholder="Search" isSearch={true} />
            <div className="cb-picker--list">
                {contentBlocksPaginatedState.data.content.map(el => {
                    return (
                        <div className="cb-picker--item" onClick={() => getContentBlock(el.id)}>
                            <Body2 weight="medium" color={colors.secoundaryText}>
                                {el.name}
                            </Body2>
                            <Body2 weight="regular" color={colors.gray6}>
                                {el.columnCount} items
                            </Body2>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
