import React, { useEffect, useState } from 'react';
import GenerationProgress from './generation_progress/GenerationProgress';
import { generationPhases } from './generation_progress/generationPhases';
import UploadFile from './generation_tabs/UploadFile';
import TrainingTitle from './generation_tabs/TrainingTitle';
import CheckAndGenerate from './generation_tabs/CheckAndGenerate';
import PreviewTraining from './generation_tabs/PreviewTraining';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Caption1 } from 'style/typography/Caption';
import { generateContentFromFileAction, resetUpdateGeneratingContentProgressAction } from 'redux/actions/content.actions';
import { colors } from 'style/colors';
import { useDispatch, useSelector } from 'react-redux';

import './AIGenerateTraining.css';
import { useTranslation } from 'react-i18next';

export default function AIGenerateTraining() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [file, setFile] = useState('');
    const [errorText, setErrorText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [title, setTitle] = useState('');
    const [selectedTraining, setSelectedTraining] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState({ label: 'English', value: 'en' });
    const [checkedGames, setCheckedGames] = useState({
        'race-cars': {
            selected: true,
            title: 'Race cars'
        },
        traffic: {
            selected: true,
            title: 'Traffic'
        },
        numbers: {
            selected: true,
            title: 'Numbers'
        },
    });

    // const generateContentState = useSelector(state => state.content.generateContent);
    const generatingContentProgressState = useSelector(state => state.content.generatingContentProgress);

    const generationPhasesTabs = {
        [generationPhases[0]]: <UploadFile file={file} setFile={setFile} setTitle={setTitle} checkedGames={checkedGames} setCheckedGames={setCheckedGames} />,
        [generationPhases[1]]: (
            <TrainingTitle
                generateTraining={generateTraining}
                trainingTitle={title}
                setTrainingTitle={setTitle}
                selectedTraining={selectedTraining}
                setSelectedTraining={setSelectedTraining}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
            />
        ),
        [generationPhases[2]]: <CheckAndGenerate />,
        [generationPhases[3]]: <PreviewTraining title={title} />,
    };

    const currentTab = generationPhases[currentIndex];

    function generateTraining() {
        dispatch(generateContentFromFileAction(file, title, selectedTraining ? selectedTraining.value : null, selectedLanguage.value,   Object.keys(checkedGames)
            .filter(key => checkedGames[key].selected === true)
            .map(key => key)));
    }

    function onContinue() {
        if (currentIndex === 0 && !file) {
            setErrorText(t('Please upload a file'));
            return;
        }
        if (currentIndex === 1) generateTraining();
        if (currentIndex === 3) return;
        setCurrentIndex(prevState => prevState + 1);
        setErrorText('');
    }
    const generatingContentData = generatingContentProgressState?.data;
    useEffect(() => {
        if (generatingContentData?.completedProcesses && generatingContentData?.completedProcesses !== generatingContentData?.totalProcesses) {
            setCurrentIndex(2);
        }
    }, []);

    useEffect(() => {
        if (generatingContentData?.completedProcesses && generatingContentData?.completedProcesses === generatingContentData?.totalProcesses) {
            setCurrentIndex(3);
        }
    }, [generatingContentProgressState]);

    return (
        <div>
            <GenerationProgress currentIndex={currentIndex + 1} />
            {generationPhasesTabs[currentTab]}

            <div className="generate-training--buttons-container">
                {currentIndex !== 3 && (
                    <div className="generate-training--buttons">
                        <DefaultButton disabled={currentIndex === 2 || (!Object.keys(checkedGames).map(key => checkedGames[key].selected).includes(true))} onClick={onContinue} text={t("Continue")}ƒ />
                        <OutlinedButton text={t('Cancel')} />
                    </div>
                )}

                {currentIndex === 3 && (
                    <div className="generate-training--buttons">
                        <DefaultButton
                            onClick={() => {
                                let trainingId = generatingContentData?.completedData?.trainingId;
                                history.replace('content/trainings' + (trainingId ? `?trainingId=${trainingId}` : ''));
                                dispatch(resetUpdateGeneratingContentProgressAction());
                            }}
                            text={t('Go to training')}
                        />
                    </div>
                )}
            </div>
            <div className="generate-training--error-text">
                <Caption1 color={colors.red}>{errorText}</Caption1>
            </div>
        </div>
    );
}
