export function downloadCSVDataFile(dataObject) {
    let csvData = getCSVFromData(dataObject);

    let link = document.createElement('a');

    let file = new Blob([csvData], { type: 'text/plain;charset=utf-8' });
    link.href = URL.createObjectURL(file);
    link.download = 'ContentBlocks.csv';
    link.click();
    URL.revokeObjectURL(link.href);
}

export function downloadOriginalDataFile(dataObject) {
    let link = document.createElement('a');

    let file = new Blob([JSON.stringify(dataObject)], { type: 'text/plain;charset=utf-8' });
    link.href = URL.createObjectURL(file);
    link.download = `original_${Date.now()}.json`;
    link.click();
    URL.revokeObjectURL(link.href);
}

export function getCSVFromData(dataObject) {
    let columns = dataObject.columns;
    let rows = dataObject.rows;

    let stringBuilder = '';

    for (let i = 0; i < columns.length; i++) {
        if (stringBuilder.length == 0) {
            stringBuilder += columns[i].name;
        } else {
            stringBuilder += ', ' + columns[i].name;
        }
    }
    stringBuilder += '\r\n';

    for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < columns.length; j++) {
            let cellValue = rows[i].cells.find(el => el.colId == columns[j].id).cellValue;
            if (j == 0) {
                stringBuilder += cellValue ? '"' + cellValue + '"' : ' ';
            } else {
                stringBuilder += ',' + (cellValue ? '"' + cellValue + '"' : ' ');
            }
        }
        stringBuilder += '\r\n';
    }

    return stringBuilder;
}
