import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React from 'react';
import { useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';

export function ModifyFeedbackModal({ modalOpen, setModalOpen, onAction, onClose, bulkQuestionSize, currentActionString }) {
    const preBulkActionFeedbacksState = useSelector(state => state.content.preBulkActionFeedbacks);

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                setModalOpen(false);
                onAction();
            }}
            onClose={onClose}
            title={'Are you sure you want to ' + currentActionString + ' ' + preBulkActionFeedbacksState?.data?.itemsCount + ' feedbacks?'}
            buttons={[
                {
                    title: currentActionString?.charAt(0).toUpperCase() + currentActionString?.slice(1),
                    color: colors.white,
                    disabled: !stateIsLoaded(preBulkActionFeedbacksState),
                },
            ]}
            hasCancel={true}
        ></ModalDialog>
    );
}

ModifyFeedbackModal.propTypes = {
    bulkQuestionSize: PropTypes.any,
    currentActionString: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
