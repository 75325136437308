import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import ArticleForm from './ArticleForm';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { reorder } from 'services/utils/arrayHelpers';
import { Caption1 } from 'style/typography/Caption';
import Add from 'assets/icons/icon-add.svg';
import { useTranslation } from 'react-i18next';

export default function CreateAndEditPostArticles({ articlesState, dispatchArticles }) {
    const { t } = useTranslation();

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        let itemsReordered = reorder(articlesState.articles, result.source.index, result.destination.index);
        itemsReordered.forEach((el, index) => {
            el.position = index + 1;
        });
        dispatchArticles({ payload: { articles: [...itemsReordered] } });
    };

    return (
        <div>
            <div className="create-news--slides-header">
                <Caption1 color={colors.gray6} weight="medium">
                    {t('SLIDES IN POST')}
                </Caption1>
            </div>
            <div className="create-news--articles">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(p, o) => {
                            return (
                                <div {...p.droppableProps} ref={p.innerRef}>
                                    {articlesState.articles?.map((ans, index) => {
                                        return (
                                            <ArticleForm
                                                article={ans}
                                                index={index}
                                                articlesState={articlesState.articles}
                                                dispatchArticles={dispatchArticles}
                                            />
                                        );
                                    })}
                                    {p.placeholder}
                                    {p.placeholder && o.isDraggingOver && <></>}
                                </div>
                            );
                        }}
                    </Droppable>
                </DragDropContext>
            </div>
            {articlesState.articles.length < 10 && (
                <div
                    className="create-news--articles-new"
                    onClick={() => {
                        if (articlesState.articles.length < 10) {
                            dispatchArticles({ actionType: 'insert' });
                        }
                    }}
                >
                    <img alt="Add" height={20} width={20} src={Add} />
                    <Body2>{t('add new slide')}</Body2>
                </div>
            )}
        </div>
    );
}

CreateAndEditPostArticles.propTypes = {
    articlesState: PropTypes.object,
    dispatchArticles: PropTypes.func,
};
