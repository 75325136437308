import React from 'react';
import CustomSelect from 'components/features/forms/form/CustomSelect';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { Body2 } from 'style/typography/Body';
import { aspectRatios } from 'services/image_management/image-editor-management/local-constants';
import EditableImageItem from './EditableImageItem';
import { useTranslation } from 'react-i18next';

export function UploaderSpace() {
    const { imageFiles, aspectRatio, setAspectRatio } = useImageEditor();

    const { t } = useTranslation();

    return (
        <div style={{ height: '90%', flex: 1, padding: 30, overflow: 'auto' }}>
            <div className="d-flex" style={{ flexDirection: 'row', flex: 1 }}>
                <Body2 style={{ flex: 1 }} weight="medium">
                    {t('Review images by setting focal point before cropping')}
                </Body2>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <Body2 className="mr-2">{t('Ratio')}</Body2>
                    <CustomSelect
                        data={aspectRatios}
                        value={aspectRatio}
                        className="ml-1"
                        onChange={value => {
                            setAspectRatio(value);
                        }}
                    ></CustomSelect>
                </div>
            </div>
            <div className="row mt-2" style={{ display: 'flex' }}>
                {imageFiles.filesBlob.map((file, index) => {
                    return <EditableImageItem file={file} index={index} />;
                })}
            </div>
        </div>
    );
}
