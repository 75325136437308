import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import WarningIcon from 'assets/icons/warning-icon.png';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React from 'react';
import { useDispatch } from 'react-redux';
import { initiateNodeRemovalAction } from 'redux/actions/trainings.actions';
import { useTranslation } from 'react-i18next';

export function RemoveTopicFromTreeDialog({ modalOpen, setModalOpen, topicToRemove, onTopicRemoved }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { setId, treeIndex } = topicToRemove;

    const onTopicOnly = () => {
        setModalOpen(false);
        onTopicRemoved();
        dispatch(initiateNodeRemovalAction(setId, treeIndex, false));
    };
    const onTopicAndChildren = () => {
        setModalOpen(false);
        onTopicRemoved();
        dispatch(initiateNodeRemovalAction(setId, treeIndex, true));
    };

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            title={t('The topic contains another topics')}
            text={t(
                'There are other topics assigned as children to the selected topic. You can either delete only the selected topic or both the topic and its children.'
            )}
            buttons={[
                {
                    title: t('Topic only'),
                    color: colors.white,
                    onClick: onTopicOnly,
                },
                {
                    title: t('Topic and children'),
                    color: colors.white,
                    onClick: onTopicAndChildren,
                },
            ]}
            // onClose={onTopicAndChildren}
            hasCancel={false}
            image={WarningIcon}
        ></ModalDialog>
    );
}

RemoveTopicFromTreeDialog.propTypes = {
    modalOpen: PropTypes.any,
    onTopicRemoved: PropTypes.func,
    setModalOpen: PropTypes.func,
    topicToRemove: PropTypes.any,
};
