import { actionsMapper } from 'redux/constants/collections';
import { actionTypes } from './local-actions';
import { INITIAL_STATE } from './local-constants';

export const contentBlocksReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.setContentBlock: {
            return { ...state, contentBlock: action.payload };
        }
        case actionTypes.setAttributes: {
            return { ...state, attributes: action.payload };
        }

        case actionTypes.setImageAttribute: {
            return { ...state, imageAttribute: action.payload };
        }
        case actionTypes.setQuestionAttribute: {
            return { ...state, questionAttribute: action.payload };
        }
        case actionTypes.setAnswerAttribute: {
            return { ...state, answerAttribute: action.payload };
        }
        case actionTypes.setCorrectValuesMap: {
            return { ...state, correctValuesMap: action.payload };
        }
        case actionTypes.setAutogenerateValue: {
            return { ...state, autogenerateValue: action.payload };
        }
        case actionTypes.setComparatorCorrect: {
            return { ...state, comparatorCorrect: action.payload };
        }
        case actionTypes.setComparatorCriteria: {
            return { ...state, comparatorCriteria: action.payload };
        }
        case actionTypes.setIsTemplate: {
            return { ...state, isTemplate: action.payload };
        }
        case actionTypes.resetState: {
            return INITIAL_STATE;
        } case actionTypes.resetValues: {
            return {
                ...INITIAL_STATE, contentBlock: state.contentBlock, attributes: state.attributes.map(el => { return { ...el, selected: false } })
            }
        }
        default: {
            throw new Error(`Unhandled action ${action.type}`);
        }
    }
};
