import React from 'react';

import { changeNodeAtPath, addNodeUnderParent, getFlatDataFromTree, walk } from 'react-sortable-tree';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { saveFoldersAction } from 'redux/actions/image.actions';
import { aspectRatios, tabs } from 'services/image_management/image-editor-management/local-constants';
import { useImageEditor } from './image-editor-management';
import { convertFromFlatToTree, getNodeKey } from '../utils/treeHelpers';
import { create_UUID, getNameFromLink, shortenString } from 'services/utils/stringHelpers';
import { Body2 } from 'style/typography/Body';
import { colors } from 'style/colors';
import _ from 'lodash';
import { areImagesEqual } from './imageHandlers';

export function useImageLibraryHelper() {
    const {
        aspectRatio,
        treeData,
        setTreeData,
        setPaginationTotalPages,
        setGalleryImages,
        currentTab,
        setCurrentNode,
        setCurrentPath,
        galleryImages,
        selectedImages,
        setFilteredImages,
    } = useImageEditor();

    const currentImagesFromFolder = useSelector(state => state.content.imagesFromFolder);
    const allImages = useSelector(state => state.content.allImages);
    const folderStructure = useSelector(state => state.content.folderStructure);
    const uncategorizedImages = useSelector(state => state.content.uncategorizedImages);

    const dispatch = useDispatch();
    let currentId = 0;

    function setExpand({ node, path }) {
        node.expanded = true;
        setTreeData(
            changeNodeAtPath({
                treeData: treeData,
                path: path,
                newNode: { ...node },
                getNodeKey: getNodeKey,
            })
        );
    }

    function expandAll(callback) {
        walk({
            treeData: treeData,
            getNodeKey: getNodeKey,
            callback: setExpand,
        });

        callback(treeData[0]);
    }

    function DFSPreorderCalculateValues() {
        currentId = 0;
        expandAll(preOrderTraverseDFS);
    }

    function preOrderTraverseDFS(node) {
        currentId++;

        node.left = currentId;

        const nodeHasChildren = node.children?.length > 0;

        if (nodeHasChildren) {
            let flatData = getFlatDataFromTree({
                treeData: node.children,
                getNodeKey,
                ignoreCollapsed: false,
            });

            node.right = flatData.length * 2 + currentId + 1;

            node.children.forEach(child => {
                preOrderTraverseDFS(child);
            });
        } else {
            node.right = node.left + 1;

            setTreeData([...treeData]);
            currentId = node.right;
        }

        currentId = node.right;

        setTreeData([...treeData]);
    }

    function saveTree() {
        if (treeData.length > 0) {
            DFSPreorderCalculateValues();
            let flatDataResult = getFlatDataFromTree({
                treeData: treeData,
                getNodeKey,
                ignoreCollapsed: false,
            });

            let finalResult = [];

            flatDataResult.forEach((value, index) => {
                if (value.node !== null && value.node !== undefined) {
                    finalResult.push(value.node);
                } else {
                    finalResult.push(value);
                }
            });
            dispatch(saveFoldersAction(finalResult));
        }
    }

    const newNode = title => {
        return {
            title: title,
            new: true,
            main: false,
            generatedTreeIndex: create_UUID(),
            path: [],
            treeIndex: -1,
            left: -1,
            right: -1,
            children: [],
        };
    };
    function addChild(path, getNodeKey, title, addAsFirstChild) {
        setTreeData(
            _.cloneDeep([
                ...addNodeUnderParent({
                    treeData: treeData,
                    parentKey: addAsFirstChild ? null : path,
                    expandParent: true,
                    getNodeKey,
                    newNode: {
                        ...newNode(title),
                    },
                    addAsFirstChild: addAsFirstChild,
                }).treeData,
            ])
        );
    }

    function updateGalleryImages() {
        let currentState;

        if (currentTab === tabs.all_images) {
            currentState = allImages;
        } else if (currentTab === tabs.uncategorized) {
            currentState = uncategorizedImages;
        } else if (currentTab === tabs.folder) {
            currentState = currentImagesFromFolder;
        }

        if (stateIsLoaded(currentState)) {
            setGalleryImages(
                currentState?.data?.images?.map(el => {
                    let src = el.urlRatio1x1;
                    if (aspectRatio === aspectRatios[1]) {
                        src = el.urlRatio2x1;
                    } else if (aspectRatio === aspectRatios[2]) {
                        src = el.urlRatio4x3;
                    }
                    return {
                        src: src,
                        fullName: getNameFromLink(el.urlOriginal).split('?')[0],
                        caption: shortenString(getNameFromLink(el.urlOriginal).split('?')[0], 20),
                        thumbnailCaption: <Body2 color={colors.gray6}>{shortenString(getNameFromLink(el.urlOriginal), 20)}</Body2>,
                        thumbnail: src,
                        thumbnailWidth: 150,
                        thumbnailHeight: 150,
                        isSelected: false,
                        fullObj: el,
                        dateCreated: el.dateCreated,
                        usedInCount: el.usedInCount,
                    };
                })
            );
            if (currentState?.data?.totalPages) setPaginationTotalPages(currentState?.data?.totalPages);
        }
    }

    function saveTreeData() {
        if (treeData.length > 0) {
            DFSPreorderCalculateValues();
            let flatDataResult = getFlatDataFromTree({
                treeData: treeData,
                getNodeKey,
                ignoreCollapsed: false,
            });

            let finalResult = [];

            flatDataResult.forEach((value, index) => {
                if (value.node !== null && value.node !== undefined) {
                    finalResult.push(value.node);
                } else {
                    finalResult.push(value);
                }
            });
            dispatch(saveFoldersAction(finalResult));
        }
    }

    function updateFolderStructure() {
        let treeDataFromFlat;
        if (!treeData || (treeData.length === 0 && folderStructure.data.length > 0)) {
            treeDataFromFlat = convertFromFlatToTree(folderStructure.data);
        } else if (folderStructure.data.length === 0 && treeData.length === 0) {
            treeDataFromFlat = convertFromFlatToTree([
                {
                    treeIndex: -1,
                    title: 'Root',
                    parentNode: { treeIndex: null },
                    imageList: [],
                },
            ]);
        }
        if (treeDataFromFlat) {
            setTreeData(treeDataFromFlat);
            setCurrentNode(treeDataFromFlat[0]);
            setCurrentPath(getNodeKey(treeDataFromFlat[0]));
        }
    }

    function filterGalleryImages() {
        let images = galleryImages.map(el => {
            let imageIsSelected = selectedImages.filter(el2 => areImagesEqual(el.fullObj, el2)).length > 0;

            return { ...el, isSelected: imageIsSelected };
        });
        setFilteredImages(images);
    }

    return { saveTree, addChild, DFSPreorderCalculateValues, updateGalleryImages, saveTreeData, updateFolderStructure, filterGalleryImages };
}
