import React from 'react';
import { colors } from 'style/colors';
import PropTypes from 'prop-types';
import InputTooltip from './InputTooltip';

const HoverSelectWithCustomComponent = ({ customComp, showDialog, setShowDialog, style, onOptionClick, options }) => {
    return (
        <div
            style={{
                backgroundColor: colors.disabled,
                padding: '5px',
                marginRight: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
                height: '40px',
                width: '40px',
                lineHeight: '30px',
                ...style,
            }}
            onMouseEnter={() => setShowDialog(true)}
            onMouseLeave={() => setShowDialog(false)}
        >
            {customComp}
            {showDialog && (
                <InputTooltip
                    visible={showDialog}
                    onMouseLeave={() => setShowDialog(false)}
                    onMouseEnter={() => setShowDialog(true)}
                    position={'bottom'}
                    options={options}
                    onClick={onOptionClick}
                />
            )}
        </div>
    );
};

HoverSelectWithCustomComponent.propTypes = {
    customComp: PropTypes.any,
    onOptionClick: PropTypes.func,
    options: PropTypes.array,
    setShowDialog: PropTypes.func,
    showDialog: PropTypes.any,
    style: PropTypes.any,
};

export default HoverSelectWithCustomComponent;
