import React, { createElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Button, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';

const useStylesButton = makeStyles({
    button: {
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: 3,
        float: props => props.float || 'none',
        color: props => props.textColor || colors.dark,
        border: props => `1px solid ${props.variant === 'outlined' ? colors.gray1 : 'transparent'}`,
        '&:hover': {
            backgroundColor: props => props.hoverBackground || 'white',
            color: props => props.hoverColor + '!important' || colors.dark,
            boxShadow: 'none',
            border: props => `1px solid ${props.borderColor || colors.mainTheme}`,
        },
        padding: '3px 15px',
    },
    input: {
        display: 'none',
    },
    moreOptions: {
        position: 'absolute',
        top: '0px',
        right: '0px',
    },
});

const BaseButton = props => {
    const classes = useStylesButton(props);
    const { loading, color, to, className, hoverBackground, hoverColor, ...rest } = props;
    const content = (
        <Button disabled={loading || props.disabled} color={color || 'default'} className={className + ' ' + classes.button} {...rest}>
            {props.children || props.text}
            {props.loading && <CircularProgress size={14} />}
        </Button>
    );
    return to ? <Link to={to}>{content}</Link> : content;
};

const ContainedButton = props => {
    const { textColor, backgroundColor, ...rest } = props;
    return <BaseButton variant="contained" {...rest} style={{ color: textColor, backgroundColor: backgroundColor, ...rest?.style }} />;
};

const TextButton = props => <BaseButton {...props} />;

const OutlinedButton = props => (
    <BaseButton
        style={{
            textTransform: 'none',
            color: props.disabled ? colors.primaryText : props.textColor || colors.primaryText,
            border: `solid 1px ${colors.gray5}`,
        }}
        variant="outlined"
        {...props}
    />
);

const CreateButton = props => {
    const extendedProps = { ...props, float: 'right' };
    return <ContainedButton {...extendedProps} color={props.color || 'primary'} text={props.text || 'Create'} />;
};

const DefaultButton = props => (
    <ContainedButton
        {...props}
        style={{
            textTransform: 'none',
            color: props.disabled ? (props.disabledTextColor ?? colors.white) : props.textColor || colors.mainTheme,
            ...props.style,
        }}
        color={props.color || 'secondary'}
        hoverBackground={props.hoverBackground || colors.gray6}
        hoverColor={props.hoverColor}
    />
);

const SaveButton = props => <ContainedButton {...props} type="submit" color={props.color || 'primary'} text={props.text || 'Save'} />;

const CancelButton = props => <OutlinedButton {...props} color={props.color || 'secondary'} text={props.text || 'Cancel'} />;

const IconButtonTyx = props => {
    const { label, tooltip, variant, icon, ...rest } = props;
    const classes = useStylesButton(props);

    if (!label && !tooltip) {
        return (
            <IconButton aria-label={label} {...rest}>
                {icon && createElement(icon)}
                {props.children}
            </IconButton>
        );
    }

    return (
        <Tooltip title={label || tooltip}>
            <IconButton aria-label={label} className={classes[variant]} {...rest}>
                {icon && createElement(icon)}
                {props.children}
            </IconButton>
        </Tooltip>
    );
};

const basePropTypes = {
    loading: PropTypes.bool,
    color: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    variant: PropTypes.string,
};

BaseButton.propTypes = basePropTypes;

ContainedButton.propTypes = basePropTypes;

TextButton.propTypes = basePropTypes;

CreateButton.propTypes = {
    ...basePropTypes,
    float: PropTypes.string,
};

OutlinedButton.propTypes = basePropTypes;

SaveButton.propTypes = basePropTypes;

CancelButton.propTypes = basePropTypes;

IconButtonTyx.propTypes = {
    ...basePropTypes,
    children: PropTypes.any,
    label: PropTypes.string,
};

export {
    TextButton as Button,
    ContainedButton,
    TextButton,
    OutlinedButton,
    SaveButton,
    CancelButton,
    CreateButton,
    IconButtonTyx as IconButton,
    BaseButton,
    DefaultButton,
};
