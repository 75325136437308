import React, { useEffect, useState } from 'react';
import { Header } from '../../local_components/header/Header';
import { CompanyInfo } from './info/CompanyInfo';
import { useParams, useHistory } from 'react-router-dom';
import { RolesWrapper } from './roles/RolesWrapper';
import TagsTab from './tags/TagsTab';

export function Company() {
    const { tab } = useParams();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState(tab);

    useEffect(() => {
        setActiveTab(tab);
    }, [tab]);

    const typesTabs = [
        {
            id: 'company-roles',
            type: 'roles',
            name: 'Roles',
        },
        {
            id: 'company-tags',
            type: 'tags',
            name: 'Tags',
        },
        {
            id: 'company-info',
            type: 'info',
            name: 'Settings',
        },
    ];

    function changeTab(newTab) {
        history.replace(`/company/${newTab}`);
    }

    return (
        <Header title={'Company'} tabs={typesTabs} activeTab={activeTab} setActiveTab={changeTab}>
            {tab === 'roles' && <RolesWrapper />}
            {tab === 'info' && <CompanyInfo />}
            {tab === 'tags' && <TagsTab />}
        </Header>
    );
}
