import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { EmptyState } from './empty_state/EmptyState';
import { colors } from 'style/colors';
import Select from 'react-select';
import { toast } from 'react-hot-toast';
import { Circular } from 'components/features/data_table/Circular';
import { DefaultButton } from 'components/elements/Button';
import { create_UUID } from 'services/utils/stringHelpers';
import { addRole, getAllRoles, removeRole, resetRoleChangesAction, resetSaveAllRoles, saveModifiedRoles } from 'redux/actions/roles.actions';
import {
    clearUsersModifiedInRoles,
    editModifiedUsersInBatchAction,
    resetEditModifiedUsersInBatchAction,
    resetUsersModifiedInRolesAction,
    resetUsersWithRole,
    resetUsersWithUnassignedRole,
} from 'redux/actions/users.actions';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { DeleteRoleDialog } from './local_components/DeleteRoleDialog';
import { Prompt } from 'react-router-dom';
import { Body2 } from 'style/typography/Body';
import { defaultRoleNode } from 'static/default_nodes';
import { SideMenuRoles } from './side_menu/SideMenuRoles';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import RolesListContainer from './local_components/RolesListContainer';
import { CustomSelect } from 'components/elements/selector/CustomSelector';

export function RolesWrapper() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const rolesState = useSelector(state => state.userManagement.roles);
    const saveRolesState = useSelector(state => state.userManagement.saveRolesNew);
    const modifiedUsersState = useSelector(state => state.userManagement.usersModifiedInRoles);
    const editUsersInBatchState = useSelector(state => state.userManagement.editUsersInBatch);

    const [currentNode, setCurrentNode] = useState(null);
    const [currentNodeInfo, setCurrentNodeInfo] = useState(null);
    const [roles, setRoles] = useState([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [newRole, setNewRole] = useState('');
    const [saveInitiated, setSaveInitiated] = useState(false);
    const [nodeToBeDeleted, setNodeToBeDeleted] = useState('');

    const [searchValue, setSearchValue] = useState('');
    const [trainingFilter, setTrainingFilter] = useState('');
    const [unitFilter, setUnitFilter] = useState('');

    const [trainings, setTrainings] = useState([]);
    const [orgUnits, setOrgUnits] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);

    function formatAndSet(data) {
        let trainings = new Set();
        let units = new Set();

        let formatted = data?.map(el => {
            if (el.connectedSetName?.length > 0) {
                trainings.add(el.connectedSetName);
            }

            // eslint-disable-next-line no-unused-expressions
            el.connectedUnits?.forEach(el => {
                if (el.length > 0) {
                    units.add(el);
                }
            });

            return el;
        });

        setRoles(formatted);
        setFilteredRoles(formatted);

        setTrainings(
            [...trainings].map(el => {
                return {
                    value: el,
                    label: el,
                };
            })
        );

        setOrgUnits(
            [...units].map(el => {
                return {
                    value: el,
                    label: el,
                };
            })
        );

        if (formatted?.length >= 1 && currentNode === null) {
            changeSelectedRole(formatted[0].roleId);
        }
    }

    function createNewRole() {
        addChild(null, newRole);
    }

    function removeNode(node) {
        changeSelectedRole(null);

        dispatch(removeRole(node.roleId, node.newRole));
    }

    const addChild = (node, title) => {
        let newNode = {
            ...defaultRoleNode,
            roleName: title ? title : '',
            roleId: create_UUID(),
        };
        dispatch(addRole(newNode));
        changeSelectedRole(newNode.roleId);
    };

    const changeSelectedRole = roleId => {
        setCurrentNode(roleId);
    };

    function resetSaveState() {
        dispatch(resetSaveAllRoles());
        dispatch(resetEditModifiedUsersInBatchAction());
        dispatch(getAllRoles());
        setCurrentNode(null);
        setCurrentNodeInfo(null);
    }

    useEffect(() => {
        dispatch(getAllRoles());
        return () => {
            dispatch(resetRoleChangesAction());
            dispatch(resetUsersModifiedInRolesAction());
            dispatch(resetUsersWithRole());
            dispatch(resetUsersWithUnassignedRole());
        };
    }, []);

    useEffect(() => {
        let role = rolesState.data?.roles?.find(el => el.roleId === currentNode);

        if (role !== undefined) setCurrentNodeInfo(role);
    }, [currentNode, rolesState.data]);

    useEffect(() => {
        let res = roles.filter(r => {
            let result = true;

            if (searchValue.length > 0) {
                result = !result
                    ? false
                    : r.roleName &&
                      r.roleName
                          .trim()
                          .toLowerCase()
                          .includes(searchValue.toLowerCase());
            }

            if (unitFilter?.length > 0) {
                result = !result ? false : r.connectedUnits && r.connectedUnits.map(el => el.toUpperCase()).includes(unitFilter.toUpperCase());
            }
            if (trainingFilter?.length > 0) {
                result = !result ? false : r.connectedSetName && r.connectedSetName.toLowerCase() === trainingFilter.toLowerCase();
            }
            return result;
        });
        setFilteredRoles(res);
    }, [searchValue, unitFilter, trainingFilter, roles]);

    useEffect(() => {
        if (stateIsLoaded(rolesState)) {
            formatAndSet(rolesState.data.roles);
        }
    }, [rolesState]);

    useEffect(() => {
        if (stateIsLoaded(rolesState) || stateIsLoaded(modifiedUsersState)) {
            let modifiedUsers = modifiedUsersState?.data;
            let rolesToRemove = rolesState.data.rolesToRemove;
            let filtered = rolesState.data.roles.filter(r => r.modified === true);
            setHasChanges(filtered.length > 0 || rolesToRemove || modifiedUsers.length > 0);
        }
    }, [rolesState, modifiedUsersState]);

    useEffect(() => {
        if (saveInitiated && !stateIsLoading(saveRolesState)) {
            let modifiedUsers = modifiedUsersState?.data;
            let rolesToRemove = rolesState.data.rolesToRemove;
            let filtered = rolesState.data.roles.filter(r => r.modified === true);

            if (filtered.length > 0 || rolesToRemove?.length > 0) {
                dispatch(saveModifiedRoles(rolesState));
            } else if (modifiedUsers.length > 0) {
                dispatch(editModifiedUsersInBatchAction(modifiedUsersState?.data, [clearUsersModifiedInRoles]));
            }
        }
    }, [saveInitiated]);

    useEffect(() => {
        if (stateIsLoaded(saveRolesState) && saveInitiated) {
            let modifiedUsers = modifiedUsersState?.data;

            if (modifiedUsers.length > 0) {
                setSaveInitiated(false);

                let newRolesData = saveRolesState.data;
                let modifiedUsersClone = _.cloneDeep(modifiedUsers);

                modifiedUsersClone = modifiedUsersClone.map(el => {
                    let roleId = el.companyRole?.roleId;
                    if (roleId) {
                        let previousId = newRolesData.find(el => {
                            return el.previousId && el.previousId == roleId;
                        });
                        if (previousId) {
                            return { ...el, companyRole: { roleId: previousId.roleId } };
                        }
                    }
                    return el;
                });
                dispatch(editModifiedUsersInBatchAction(modifiedUsersClone, [clearUsersModifiedInRoles]));
            } else {
                setSaveInitiated(false);

                toast.success(t('Successfully saved!'));
                resetSaveState();
            }
        }
    }, [saveInitiated, saveRolesState, editUsersInBatchState]);

    useEffect(() => {
        if (stateIsLoaded(editUsersInBatchState)) {
            setSaveInitiated(false);

            toast.success(t('Successfully saved!'));

            resetSaveState();
        }
    }, [editUsersInBatchState]);

    if (stateIsLoading(rolesState)) {
        return (
            <div style={{ height: '90vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                <Circular />
            </div>
        );
    }
    if (roles?.length === 0 && (saveInitiated === true || !hasChanges)) {
        return <EmptyState newRole={newRole} setNewRole={setNewRole} createRole={createNewRole}></EmptyState>;
    }

    return (
        <div className={'ml-4'} style={{ height: '90vh' }}>
            <Prompt when={hasChanges} message={t('You have unsaved changes that will be removed, are you sure you want to leave?')} />
            {nodeToBeDeleted && (
                <DeleteRoleDialog
                    modalOpen={nodeToBeDeleted}
                    setModalOpen={() => setNodeToBeDeleted(null)}
                    onAction={() => {
                        removeNode(nodeToBeDeleted);
                        setNodeToBeDeleted(null);
                    }}
                />
            )}

            <div className="d-flex flex-row" style={{ height: '100%' }}>
                <div style={{ backgroundColor: colors.gray3 }} className="col-6 px-0 pl-4">
                    <div className="d-flex flex-row justify-content-between w-100" style={{ padding: '20px 20px 20px 0' }}>
                        <div className="mr-2">
                            <CustomTextField
                                id="name-roles"
                                isSearch={true}
                                value={searchValue}
                                setValue={setSearchValue}
                                placeholder={t('Name')}
                            ></CustomTextField>
                        </div>
                        <div className="mx-2" style={{ width: '150px' }}>
                            <CustomSelect
                                id="active-training-roles"
                                value={trainingFilter ? [...trainings].filter(option => option.value === trainingFilter) : ''}
                                isClearable
                                backgroundColor={colors.white}
                                onChange={option => setTrainingFilter(option ? option.value : '')}
                                placeholder={t('Active training')}
                                options={[...trainings]}
                            />
                        </div>

                        <div className="ml-auto">
                            <DefaultButton id="add-role-button" className="mx-4" text={t('Add role')} onClick={() => addChild(null, '')} />
                        </div>
                    </div>
                    {roles?.length === 0 && !(saveInitiated === true || !hasChanges) && (
                        <Body2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70vh' }} color={colors.gray6}>
                            {t('No roles exist in this company')}
                        </Body2>
                    )}

                    {roles?.length > 0 && (
                        <RolesListContainer
                            currentNode={currentNode}
                            roles={filteredRoles}
                            setCurrentNode={setCurrentNode}
                            setNodeToBeDeleted={setNodeToBeDeleted}
                        />
                    )}

                    <div className="d-flex flex-row" style={{ marginLeft: '7%', gap: 10, alignItems: 'center' }}>
                        <DefaultButton
                            id="save-roles-button"
                            disabled={saveInitiated === true || !hasChanges}
                            text={t('Save')}
                            className={'pl-4 pr-4'}
                            onClick={() => setSaveInitiated(true)}
                        />
                        {saveInitiated && <Circular style={{ padding: 0, marginLeft: 10 }} size={20} />}
                    </div>
                </div>

                {currentNode !== null && currentNodeInfo !== null && (
                    <SideMenuRoles hasData={filteredRoles?.length > 0} currentNode={currentNodeInfo} saveInitiated={saveInitiated} />
                )}
            </div>
        </div>
    );
}
