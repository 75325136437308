import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    deleteFeedbackAction,
    getFeedbackContentPaginatedAction,
    getSpecificFeedbackAction,
    resetUpdateFeedbackState,
    updateFeedbackAction,
} from 'redux/actions/feedback.actions';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { FeedbackResolveDialog } from 'containers/layout/module/pages/internal_messaging/feedback/local_components/FeedbackResolveDialog';
import { Header } from 'containers/layout/module/local_components/header/Header';
import { Circular } from 'components/features/data_table/Circular';
import { deleteFeedbackModalData, resolvedFeedbackModalData, unresolvedFeedbackModalData } from '../FeedbackModalData';
import FeedbackViewPreview from './FeedbackViewPreview';
import FeedbackViewInfo from './FeedbackViewInfo';
import { useTranslation } from 'react-i18next';

export function FeedbackView({ history }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const feedbackViewState = useSelector(state => state.content.feedbackView);
    const feedbackUpdatedState = useSelector(state => state.content.feedbackUpdated);
    const feedbackDeletedState = useSelector(state => state.content.feedbackDeleted);

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [feedbackResolvedModalOpen, setFeedbackResolvedModalOpen] = useState(false);

    const [feedback, setFeedback] = useState(null);

    let { id } = useParams();

    const updateFeedback = row => {
        dispatch(updateFeedbackAction(row));
    };

    const deleteFeedback = row => {
        dispatch(deleteFeedbackAction(row));
    };

    useEffect(() => {
        if (stateIsLoaded(feedbackUpdatedState)) {
            setUpdateModalOpen(false);
            if (feedback.isResolved) {
                setFeedbackResolvedModalOpen(true);
            }
            let feedbackObj = feedbackUpdatedState.data;
            feedbackObj.modalData =
                feedbackObj.feedbackType == 'POSITIVE'
                    ? deleteFeedbackModalData()
                    : feedbackObj.isResolved
                    ? resolvedFeedbackModalData()
                    : unresolvedFeedbackModalData();
            setFeedback(feedbackObj);
            dispatch(getFeedbackContentPaginatedAction());
            dispatch(resetUpdateFeedbackState());
        }
    }, [dispatch, feedback, feedbackUpdatedState]);

    useEffect(() => {
        if (stateIsLoaded(feedbackDeletedState)) {
            setDeleteModalOpen(false);

            history.goBack();
        }
    }, [dispatch, feedbackDeletedState]);

    useEffect(() => {
        dispatch(getSpecificFeedbackAction(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (stateIsLoaded(feedbackViewState)) {
            let feedbackObj = feedbackViewState.data;

            feedbackObj.modalData =
                feedbackObj.feedbackType == 'POSITIVE'
                    ? deleteFeedbackModalData()
                    : feedbackObj.isResolved
                    ? resolvedFeedbackModalData()
                    : unresolvedFeedbackModalData();
            setFeedback(feedbackObj);
        } else if (stateHasFailed(feedbackViewState)) {
            history.replace('/internal/content-feedback');
        }
    }, [feedback, feedbackViewState]);

    if (stateIsLoading(feedbackViewState) || !feedback) {
        return (
            <div style={{ height: '100vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                <Circular />
            </div>
        );
    }

    return (
        <Header title={t(`User feedback #{{id}}`, { id: id })} stateDependencies={[feedbackViewState]}>
            <ModalDialog
                title={feedback?.modalData.title}
                modalOpen={updateModalOpen}
                setModalOpen={setUpdateModalOpen}
                onAction={() => updateFeedback(feedback)}
                buttons={feedback?.modalData.buttons}
                text={feedback?.modalData.text}
                contentComponent={feedback?.modalData?.contentComponent ? feedback?.modalData?.contentComponent : undefined}
                state={feedbackUpdatedState}
                hasCancel={true}
                hasLoader={true}
            ></ModalDialog>
            <FeedbackResolveDialog
                modalOpen={feedbackResolvedModalOpen}
                setModalOpen={setFeedbackResolvedModalOpen}
                userName={feedback?.user?.split(' ')[0]}
            ></FeedbackResolveDialog>
            <ModalDialog
                title={deleteFeedbackModalData().title}
                modalOpen={deleteModalOpen}
                setModalOpen={setDeleteModalOpen}
                onAction={() => deleteFeedback(feedback)}
                buttons={deleteFeedbackModalData().buttons}
                text={deleteFeedbackModalData().text}
                state={feedbackDeletedState}
                hasLoader={true}
                hasCancel={true}
            ></ModalDialog>
            <div style={{ height: '100%' }} className="d-flex flex-row">
                <FeedbackViewPreview
                    feedback={feedback}
                    history={history}
                    setUpdateModalOpen={setUpdateModalOpen}
                    setDeleteModalOpen={setDeleteModalOpen}
                />
                <FeedbackViewInfo
                    feedback={feedback}
                    history={history}
                    setUpdateModalOpen={setUpdateModalOpen}
                    setDeleteModalOpen={setDeleteModalOpen}
                />
            </div>
        </Header>
    );
}
