import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from 'assets/icons/coach-logo-black.svg';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { colors } from 'style/colors';
import Collapse from '@material-ui/core/Collapse/Collapse';
import Alert from '@material-ui/lab/Alert/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { registerAdministratorAction } from 'redux/actions/users.actions';
import { useTranslation } from 'react-i18next';
import { H7 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { AnimatedTextField } from 'components/elements/inputs/AnimatedTextField';
import { DefaultButton } from 'components/elements/Button';

import './AdministratorRegistration.css';
import Checkbox from 'components/elements/checkbox/Checkbox';

export function AdministratorRegistration({ history }) {
    const { code } = useParams();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [inputChecked, setInputChecked] = useState(false);
    const registerState = useSelector(state => state.userManagement.registerAdministrator);
    const auth = useSelector(state => state.auth);

    function register() {
        if (name === '' || lastName === '') {
            setPasswordError(true);
            setValidationMessage(t('You must enter your first and last name.'));
        } else if (password.length < 6 || repeatPassword.length < 6) {
            setPasswordError(true);
            setValidationMessage(t('Password must be at least 6 characters.'));
        } else if (password !== repeatPassword) {
            setPasswordError(true);
            setValidationMessage(t("Passwords don't match."));
        } else if (!inputChecked) {
            setCheckboxError(true);
            setValidationMessage(t('You must agree to our terms and conditions before continuing'));
        } else {
            dispatch(
                registerAdministratorAction({
                    name: name,
                    lastName: lastName,
                    code: code,
                    password: password,
                })
            );
        }
    }

    useEffect(() => {
        if (stateIsLoaded(registerState)) {
            if (registerState?.data?.isSuccessful) {
                history.push('/users/invite/administrator/success');
            } else {
                setServerError(true);
                setValidationMessage(registerState?.data?.message);
            }
        }
    }, [history, registerState]);

    useEffect(() => {
        if (stateIsLoaded(auth)) {
            history.replace('/dashboard');
        }
    }, [history, auth]);

    return (
        <div className="administrator-registration">
            <img alt="logo" src={Icon} width={70} height={70}></img>
            <H7 style={{ marginTop: 30 }} color={colors.gray6}>
                {t('Welcome to Coach Panel!')}
            </H7>
            <Body2 style={{ maxWidth: '35vw', marginTop: 30 }}>
                {t('Fill in the information below to set up your account as an administrator in our platform.')}
            </Body2>
            <div className="administrator-registration--form">
                <div className="administrator-registration--form-name">
                    <AnimatedTextField
                        label={t('Name')}
                        onFocus={() => {
                            setPasswordError(false);
                            setValidationMessage('');
                        }}
                        value={name}
                        setValue={val => setName(val)}
                        width="145px"
                    />
                    <AnimatedTextField
                        label={t('Last name')}
                        disabled={stateIsLoading(registerState)}
                        onFocus={() => {
                            setPasswordError(false);
                            setValidationMessage('');
                        }}
                        value={lastName}
                        setValue={val => setLastName(val)}
                        width="145px"
                    />
                </div>
                <AnimatedTextField
                    label={t('Password')}
                    type="password"
                    disabled={stateIsLoading(registerState)}
                    onFocus={() => {
                        setPasswordError(false);
                        setValidationMessage('');
                    }}
                    value={password}
                    setValue={val => setPassword(val)}
                />
                <AnimatedTextField
                    label={t('Repeat password')}
                    type="password"
                    disabled={stateIsLoading(registerState)}
                    onFocus={() => {
                        setPasswordError(false);
                        setServerError(false);
                        setValidationMessage('');
                    }}
                    value={repeatPassword}
                    setValue={val => setRepeatPassword(val)}
                />
                <Collapse in={passwordError || checkboxError || serverError}>
                    <Alert severity="error">{validationMessage}</Alert>
                </Collapse>
            </div>
            <div className="administrator-registraton--actions">
                <div className="administrator-registration--terms">
                    <div style={{ position: 'relative', height: '20px', width: '20px' }}>
                        <Checkbox
                            onClick={() => {
                                setCheckboxError(false);
                                setServerError(false);
                                setInputChecked(!inputChecked);
                            }}
                            checked={inputChecked}
                            disabled={stateIsLoading(registerState)}
                        ></Checkbox>
                    </div>
                    <Body2 color={colors.gray6}>{t('I have read and agree to the Coach® platform’s Terms and conditions and Privacy Policy.')}</Body2>
                </div>
                <DefaultButton
                    style={{ width: '150px' }}
                    text={t('Create account')}
                    disabled={stateIsLoading(registerState)}
                    onClick={register}
                    textColor="white"
                />
            </div>
        </div>
    );
}
