import React, { useEffect, useReducer, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getSingleUserAction } from 'redux/actions/users.actions';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from 'containers/layout/module/local_components/header/Header';
import { colors } from 'style/colors';
import { globalStatisticsAction } from 'redux/actions/content.actions';
import { stateHasFailed, stateIsLoaded, stateIsNotReady } from 'redux/core/stateHelpers';
import { UserAnalyticsWrapper } from 'containers/layout/module/pages/users/user_profile/user_analytics/UserAnalyticsWrapper';
import { TrainingAnalyticsWrapper } from 'containers/layout/module/pages/users/user_profile/training_analytics/TrainingAnalyticsWrapper';
import { ProfileSettingsWrapper } from './profile_settings/ProfileSettingsWrapper';
import { Circular } from 'components/features/data_table/Circular';
import { H5 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { Subtitle2 } from 'style/typography/Subtitle';

import { getAllRoles } from 'redux/actions/roles.actions';
import UserProfileHeader from './UserProfileHeader';
import { tabsUserProfile } from 'static/tabs';
import UserProfileNavigation from './UserProfileNavigation';
import { defaultUserData } from 'static/user_constants';
import { useTranslation } from 'react-i18next';


import InactiveUserIcon from 'assets/icons/icon-emptystate-inactive-user.svg';
import NoUserDataIcon from 'assets/icons/icon-emptystate-global-user.svg';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-circular-progressbar/dist/styles.css';

const UserProfile = ({ indx }) => {
    const { id } = useParams();

    const history = useHistory();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('user-analytics');

    const editingUser = useSelector(state => state.userManagement.editingUser);
    const editUser = useSelector(state => state.userManagement.editUser);
    const rolesState = useSelector(state => state.userManagement.roles);
    const authRolesState = useSelector(state => state.userManagement.authRoles);
    const globalStats = useSelector(state => state.content.globalStats);

    const [allCompanyRoles, setAllCompanyRoles] = useState([]);
    const [allAuthRoles, setAllAuthRoles] = useState([]);

    const dispatch = useDispatch();

    const [userData, dispatchUserData] = useReducer((state, action) => {
        return { ...state, ...action };
    }, defaultUserData);

    useEffect(() => {
        dispatch(getAllRoles());
    }, []);

    useEffect(() => {
        dispatch(getSingleUserAction(id));
        dispatch(globalStatisticsAction(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (stateIsLoaded(editUser)) {
            dispatchUserData({
                name: editUser.data.name,
                lastName: editUser.data.lastName,
                companyRole: editUser.data.companyRole,
                department: editUser.data.department,
                authRole: editUser.data.authRole,
                activeLanguage: editUser.data.activeLanguage,
            });
        }
    }, [editUser]);

    useEffect(() => {
        if (stateIsLoaded(editingUser)) {
            dispatchUserData({
                name: editingUser.data.name,
                status: editingUser.data.status,
                isActive: editingUser.data.isActive ? 'Active' : 'Inactive',
                totalGameplays: editingUser.data.totalGameplays,
                companyRole: editingUser.data.companyRole,
                department: editingUser.data.department,
                authRole: editingUser.data.authRole,
                lastName: editingUser.data.lastName,
                email: editingUser.data.email,
                trainings: editingUser.data.trainings,
                activeLanguage: editingUser.data.activeLanguage,
            });
        } else if (stateHasFailed(editingUser)) {
            history.replace('/users/organization');
        }
    }, [editingUser]);

    useEffect(() => {
        if (stateIsLoaded(rolesState)) {
            setAllCompanyRoles(
                rolesState.data?.roles.map(r => {
                    return {
                        value: r.roleId,
                        label: r.roleName,
                    };
                })
            );
        }
    }, [rolesState]);

    useEffect(() => {
        if (stateIsLoaded(authRolesState)) {
            setAllAuthRoles(
                authRolesState.data?.map(r => {
                    var roleNameParsed = r?.toLowerCase().replace('role_', '');
                    return {
                        value: r,
                        label: roleNameParsed[0]?.toUpperCase() + roleNameParsed?.slice(1),
                    };
                })
            );
        }
    }, [authRolesState]);
    return (
        <Header title={t('User profile')} stateDependencies={[editingUser, rolesState, globalStats]}>
            {(stateIsNotReady(globalStats) || stateIsNotReady(editingUser) || stateIsNotReady(rolesState)) && (
                <div style={{ height: '90vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                    <Circular />
                </div>
            )}
            {!stateIsNotReady(globalStats) && !stateIsNotReady(editingUser) && !stateIsNotReady(rolesState) && (
                <div style={{ backgroundColor: colors.gray3 }} className="d-flex flex-column p-4 justify-items-center align-items-center">
                    <UserProfileNavigation id={id} />
                    <UserProfileHeader userData={userData} />

                    <div style={{ borderColor: colors.underlineColor }} className="col-8 border-bottom mt-5 justify-content-center">
                        <div className="d-flex flex-row align-items-end justify-content-center" style={{ gap: 50 }}>
                            {tabsUserProfile?.map((tab, index) => {
                                let tabIsActive = tab.type === activeTab;
                                let id = tab.type === activeTab ? 'active-user-training-analytics' : 'deactivated-user-training-analytics-' + index;
                                return (
                                    <div
                                        id={id}
                                        key={index}
                                        style={{
                                            borderBottom: tabIsActive ? `2px solid ${colors.mainTheme}` : 'none',
                                            paddingBottom: tabIsActive ? 3 : 5,
                                        }}
                                        onClick={() => setActiveTab(tab.type)}
                                        role="button"
                                    >
                                        <Subtitle2 weight="medium" color={tab.type === activeTab ? colors.dark : colors.gray6}>
                                            {t(tab.name)}
                                        </Subtitle2>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {(activeTab === 'user-analytics' || (activeTab === 'training-analytics' && userData.companyRole)) &&
                        userData.isActive === 'Inactive' && (
                            <div style={{ height: '50vh' }} className="row justify-content-center align-items-center">
                                <div className="col-6 d-flex flex-column align-items-center">
                                    <img alt="inactive" src={InactiveUserIcon} height={90} width={90} />
                                    <H5 color={colors.gray6}>{t('Inactive user')}</H5>
                                    <Body2 color={colors.gray6} className={'mt-2'}>
                                        {t('This user is currently deactivated. To change the user status, go to ‘User Settings’.')}
                                    </Body2>
                                </div>
                            </div>
                        )}
                    {(activeTab === 'user-analytics' || (activeTab === 'training-analytics' && userData.companyRole)) &&
                        userData.isActive === 'Active' &&
                        userData.totalGameplays < 10 && (
                            <div style={{ height: '50vh' }} className="row justify-content-center align-items-center">
                                <div className="col-6 d-flex flex-column align-items-center gap-4 text-center">
                                    <img alt="inactive" src={NoUserDataIcon} height={90} width={90} />
                                    <H5 color={colors.gray6}>{t('No user data to show here')}</H5>
                                    <Body2 color={colors.gray6} className={'mt-2'}>
                                        {t(
                                            'This user hasn’t been training in the Coach app enough in order for us to show sufficient user analytics in this section.'
                                        )}
                                    </Body2>
                                </div>
                            </div>
                        )}
                    {activeTab === 'training-analytics' && !userData.companyRole && (
                        <div style={{ height: '50vh' }} className="row justify-content-center align-items-center">
                            <div className="col-12 d-flex flex-column align-items-center">
                                <img alt="no-role" src={NoUserDataIcon} height={90} width={90} />
                                <H5 color={colors.gray6}>{t("This user doesn't have a role")}</H5>
                                <Body2 color={colors.gray6} className={'mt-2'}>
                                    {t('Assign a role to the user to view the training analytics.')}
                                </Body2>
                            </div>
                        </div>
                    )}
                    {activeTab === 'user-analytics' && userData.isActive === 'Active' && userData.totalGameplays >= 10 && (
                        <UserAnalyticsWrapper globalStats={globalStats} name={userData.name} />
                    )}
                    {activeTab === 'training-analytics' &&
                        userData.isActive === 'Active' &&
                        userData.companyRole &&
                        userData.totalGameplays >= 10 && (
                            <TrainingAnalyticsWrapper trainings={userData.trainings} userId={id} userName={userData.name} />
                        )}
                    {activeTab === 'profile-settings' && (
                        <ProfileSettingsWrapper
                            id={id}
                            userData={userData}
                            dispatchUserData={dispatchUserData}
                            userRoles={allCompanyRoles}
                            accessLevels={allAuthRoles}
                            tags={editingUser.data?.tags}
                        />
                    )}
                </div>
            )}
        </Header>
    );
};

export default UserProfile;
