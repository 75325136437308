import { combineReducers } from 'redux';
import { basicReducer } from './basicReducer';
import {
    ACTIVATE_USER,
    CHANGE_PASSWORD,
    CREATE_COMPANY_WITH_CODE,
    createCompanyWithCode,
    DEACTIVATE_USER,
    DEACTIVATE_USERS_IN_BATCH,
    DELETE_USER,
    DELETE_USERS_IN_BATCH,
    DOWNLOAD_TEMPLATE,
    EDIT_USER,
    EDIT_USERS_IN_BATCH,
    EDIT_USERS_ROLE_IN_BATCH,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SET_NEW,
    GET_ACTIVE_USERS_COUNT_FOR_MONTH,
    GET_ALL_AUTH_ROLES,
    GET_ALL_COMPANY_ROLES,
    GET_COMPANY,
    GET_COMPANY_ROLES_PAGINATED,
    GET_ROLES_CONNECTED_TO_SET,
    GET_SINGLE_ROLE,
    GET_SINGLE_USER,
    GET_USERS,
    GET_USERS_PAGINATED,
    GET_USERS_WITH_ROLE,
    GET_USERS_WITH_UNASSIGNED_ROLE,
    HANDLE_ADMINS_INVITE,
    HANDLE_USERS_INVITE,
    INVITE_MULTIPLE_USERS,
    INVITE_MULTIPLE_USERS_ADMIN,
    INVITE_MULTIPLE_USERS_PROGRESS,
    INVITE_USER,
    REGISTER_ADMINISTRATOR,
    REINVITE_USERS,
    SAVE_ALL_ROLES_NEW,
    UPLOAD_USERS,
    USERS_MODIFIED_IN_ROLES,
} from '../constants/users.constants';

import { HANDLE_NEXT_ERROR } from 'redux/constants/users.constants';

export default combineReducers({
    users: basicReducer(GET_USERS),
    usersPaginated: basicReducer(GET_USERS_PAGINATED),
    usersWithUnassignedRole: basicReducer(GET_USERS_WITH_UNASSIGNED_ROLE),
    usersWithRole: basicReducer(GET_USERS_WITH_ROLE),
    editingUser: basicReducer(GET_SINGLE_USER),
    roles: basicReducer(GET_ALL_COMPANY_ROLES),
    rolesPaginated: basicReducer(GET_COMPANY_ROLES_PAGINATED),
    usersModifiedInRoles: basicReducer(USERS_MODIFIED_IN_ROLES),
    singleRole: basicReducer(GET_SINGLE_ROLE),
    saveRolesNew: basicReducer(SAVE_ALL_ROLES_NEW),
    authRoles: basicReducer(GET_ALL_AUTH_ROLES),
    rolesConnectedToSet: basicReducer(GET_ROLES_CONNECTED_TO_SET),
    inviteSingleUser: basicReducer(INVITE_USER),
    company: basicReducer(GET_COMPANY),
    inviteMultipleUsers: basicReducer(INVITE_MULTIPLE_USERS),
    inviteMultipleUsersAdmin: basicReducer(INVITE_MULTIPLE_USERS_ADMIN),
    inviteUsersProgress: basicReducer(INVITE_MULTIPLE_USERS_PROGRESS),
    uploadUsers: basicReducer(UPLOAD_USERS),
    downloadTemplate: basicReducer(DOWNLOAD_TEMPLATE),
    usersInvited: basicReducer(HANDLE_USERS_INVITE),
    adminsInvited: basicReducer(HANDLE_ADMINS_INVITE),
    deactivateUser: basicReducer(DEACTIVATE_USER),
    deactivateUsersInBatch: basicReducer(DEACTIVATE_USERS_IN_BATCH),
    deleteUser: basicReducer(DELETE_USER),
    activateUser: basicReducer(ACTIVATE_USER),
    activeUsersCountForMonth: basicReducer(GET_ACTIVE_USERS_COUNT_FOR_MONTH),
    deleteUsersInBatch: basicReducer(DELETE_USERS_IN_BATCH),
    editUser: basicReducer(EDIT_USER),
    editUsersInBatch: basicReducer(EDIT_USERS_IN_BATCH),
    editUsersRoleInBatch: basicReducer(EDIT_USERS_ROLE_IN_BATCH),
    changePassword: basicReducer(CHANGE_PASSWORD),
    nextUserUploadError: basicReducer(HANDLE_NEXT_ERROR),
    registerAdministrator: basicReducer(REGISTER_ADMINISTRATOR),
    forgotPasswordRequest: basicReducer(FORGOT_PASSWORD_REQUEST),
    forgotPasswordSetNew: basicReducer(FORGOT_PASSWORD_SET_NEW),
    reinviteUsers: basicReducer(REINVITE_USERS),
});
