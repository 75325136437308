import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';

export function DeleteBulkModal({ modalOpen, setModalOpen, onAction, onClose }) {
    const { t } = useTranslation();

    const preBulkActionQuestionsState = useSelector(state => state.content.preBulkActionQuestions);

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                setModalOpen(false);
                onAction();
            }}
            onClose={onClose}
            title={t('Are you sure you want to delete {{bulkQuestionSize}} questions?', {
                bulkQuestionSize: preBulkActionQuestionsState?.data?.itemsCount,
            })}
            text={t(
                'If you delete the selected questions, they will be removed from all of the learning sets that they was previously assigned to. This means that they won’t be shown to the users anymore. All the insights, user analytics and statistics will be recalculated to update this change.'
            )}
            buttons={[
                {
                    title: t('Delete questions'),
                    color: colors.white,
                    disabled: !stateIsLoaded(preBulkActionQuestionsState),
                },
            ]}
            hasCancel={true}
            alert={
                <Alert className={'mt-2 mb-2'} severity="error">
                    {t('Please read this carefully before deleting questions.')}
                </Alert>
            }
        ></ModalDialog>
    );
}

DeleteBulkModal.propTypes = {
    bulkQuestionSize: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
