import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authService } from 'services/auth/auth';
import { H5 } from 'style/typography/Heading';
import HeaderMenu from './HeaderMenu';
import LoaderInfoBox from './LoaderInfoBox';
import { useDispatch, useSelector } from 'react-redux';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { useTranslation } from 'react-i18next';
import { locationToHelpCenterLink } from 'static/location_to_help_center_link';
import QuestionIcon from 'assets/icons/question-mark.svg';
import BellIcon from 'assets/icons/bell-icon.svg';
import InfoTooltip from 'components/elements/tooltips/InfoTooltip';
import { NewsPopupModal } from './NewsPopupModal';
import { resetGetLatestUpdatesAction, resetSetLatestNewsSeenAction } from 'redux/actions/content.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { Subtitle2 } from 'style/typography/Subtitle';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import LanguagePicker from './LanguagePicker';
import SearchShortcuts from './SearchShortcuts';
import LicencesLeft from './LicencesLeft';
import { ErrorBoundaryComponent } from 'components/features/error_handling/error_boundary/ErrorBoundaryComponent';

import './Header.css';
import './loader.css';

export function Header({ tabs, setActiveTab, activeTab, title, children, stateDependencies }) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [userInfo, setUserInfo] = useState(null);
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);
    const [toShowProgressDialog, setToShowProgressDialog] = useState(false);
    const [hovering, setHovering] = useState('');
    const [newsModalOpen, setNewsModalOpen] = useState(false);

    const generatingContentProgressState = useSelector(state => state.content.generatingContentProgress);
    const latestUpdatesState = useSelector(state => state.content.latestUpdates);
    const setLatestUpdatesSeenState = useSelector(state => state.content.setLatestUpdatesSeen);
    const singleQuestion = useSelector(state => state.content.singleQuestion);

    useEffect(() => {
        if (stateIsLoaded(latestUpdatesState) && latestUpdatesState?.data?.length > 0) {
            setNewsModalOpen(true);
        }
    }, [latestUpdatesState]);

    useEffect(() => {
        if (stateIsLoaded(setLatestUpdatesSeenState)) {
            dispatch(resetGetLatestUpdatesAction());
            dispatch(resetSetLatestNewsSeenAction());
        }
    }, [setLatestUpdatesSeenState]);

    useEffect(() => {
        if (!userInfo) {
            setUserInfo(authService.user);
        }
    }, [userInfo]);

    const currentGeneratingContentProgress = generatingContentProgressState?.data?.progress;

    function setToShowDialog(toShow) {
        setTimeoutLastExecute.addCallback(
            () => {
                setToShowProgressDialog(toShow);
            },
            100,
            `showDialogImage`
        );
    }

    function getHelpCenterLinkFromLocation() {
        let link = 'https://www.getcoach.app/help-center-home/';
        let location = history?.location?.pathname.replace(/\d+/g, '#').replace(/\/#$/g, '');

        location = location.includes('content/questions/update') ? location + '/' + singleQuestion.data.gameSlug : location;

        if (locationToHelpCenterLink[location]) {
            link += locationToHelpCenterLink[location];
        }

        return link;
    }

    return (
        <div style={{ background: colors.gray3 }}>
            <ModalDialog
                title={'Remember me on this browser'}
                modalOpen={logoutModalOpen}
                setModalOpen={setLogoutModalOpen}
                buttons={[
                    {
                        title: 'Remember me',
                        color: colors.mainTheme,
                        onClick: () => logout(true),
                    },
                    {
                        title: 'Log out',
                        color: colors.secoundaryText,
                        onClick: () => logout(false),
                    },
                ]}
                text={'You won’t need to enter your log in information the next time you visit Coach Enterprise.'}
                hasCancel={true}
            ></ModalDialog>
            {newsModalOpen && <NewsPopupModal modalOpen={newsModalOpen} setModalOpen={setNewsModalOpen} onClose={() => setNewsModalOpen(false)} />}
            <div className={'pb-0'}>
                <div className=" header-wrapper--header row pt-2 mr-0 ml-4">
                    <div className="col-12 border-bottom d-flex flex-row align-items-end p-0">
                        <div className={'mr-2 pl-0 ml-0'}>
                            <H5 weight="regular">{t(title)}</H5>
                        </div>
                        <div className="d-flex flex-row align-items-end">{renderTabs()}</div>
                        {location?.pathname?.includes('/dashboard') && <LicencesLeft />}
                        <div className={'d-flex flex-row align-items-center ml-auto mb-2'}>
                            <div className={'header--action-buttons'}>
                                <SearchShortcuts hovering={hovering} setHovering={setHovering} />
                                <LanguagePicker />

                                <div className="header--action-icon">
                                    <a href={getHelpCenterLinkFromLocation()} target="_blank" rel="noopener noreferrer">
                                        <img
                                            className="action-img"
                                            onMouseEnter={() => setHovering('help')}
                                            onMouseLeave={() => setHovering('')}
                                            alt="help"
                                            src={QuestionIcon}
                                            height={35}
                                            width={35}
                                        />
                                    </a>
                                    {hovering === 'help' && (
                                        <InfoTooltip
                                            visible={hovering === 'help'}
                                            message={t('Need help with this section? Click to see a tutorial.')}
                                            position={'right'}
                                            width={300}
                                            marginRelative={0}
                                        />
                                    )}
                                </div>
                                <div className="header--action-icon">
                                    <img
                                        className="action-img"
                                        onMouseEnter={() => setHovering('bell')}
                                        onMouseLeave={() => setHovering('')}
                                        alt="bell"
                                        src={BellIcon}
                                        height={35}
                                        width={35}
                                        onClick={() => setNewsModalOpen(true)}
                                    />
                                    {hovering === 'bell' && (
                                        <InfoTooltip
                                            visible={hovering === 'bell'}
                                            message={t("See what's new in the Admin Panel.")}
                                            position={'right'}
                                            width={260}
                                            marginRelative={0}
                                        />
                                    )}
                                </div>
                            </div>
                            {currentGeneratingContentProgress && (
                                <div style={{ position: 'relative' }}>
                                    <div
                                        onMouseEnter={() => setToShowDialog(true)}
                                        onMouseLeave={() => setToShowDialog(false)}
                                        class={`c100 small p${currentGeneratingContentProgress} center mr-3`}
                                        style={{ top: -2 }}
                                    >
                                        <span>{currentGeneratingContentProgress}</span>
                                        <div class="slice">
                                            <div class="bar"></div>
                                            <div class="fill"></div>
                                        </div>
                                    </div>
                                    {toShowProgressDialog && (
                                        <LoaderInfoBox
                                            onClose={() => setToShowDialog(false)}
                                            onMouseEnter={() => setToShowDialog(true)}
                                            onMouseLeave={() => setToShowDialog(false)}
                                        />
                                    )}
                                </div>
                            )}
                            <HeaderMenu userInfo={userInfo} setLogoutModalOpen={setLogoutModalOpen} />
                        </div>
                    </div>
                </div>
                <div className={'header-wrapper--body'}>
                    {<ErrorBoundaryComponent key={activeTab} dependencies={stateDependencies}>{children}</ErrorBoundaryComponent>}
                </div>
            </div>
        </div>
    );

    function renderTabs() {
        return tabs?.map(tab => {
            if (tab.type === activeTab) {
                return (
                    <div
                        key={tab.type}
                        onClick={() => setActiveTab(tab.type)}
                        style={{ borderBottomColor: colors.mainTheme, borderBottomWidth: '3px' }}
                        className={'ml-5 header-wrapper--tab active'}
                    >
                        <Subtitle2 weight="medium">{t(tab.name)}</Subtitle2>
                    </div>
                );
            } else {
                return (
                    <div key={tab.type} onClick={() => setActiveTab(tab.type)} className={'ml-5 header-wrapper--tab'}>
                        <Subtitle2 weight="medium" color={colors.gray6}>
                            {t(tab.name)}
                        </Subtitle2>
                    </div>
                );
            }
        });
    }

    function logout(remember) {
        if (remember) {
            let tokenInfo = {
                auth: authService.auth,
                date_stored: Date.now(),
            };

            localStorage.setItem('tokenInfo', JSON.stringify(tokenInfo));
        }
        history.replace('/logout');
    }
}
