import { API_ENDPOINT } from './requestAgent';

export const LOGIN_API = '/oauth/token';
export const FETCH_USERS_API = '/api/admin/companies/users';
export const FETCH_USERS_PAGINATED_API = '/api/admin/companies/users/paginated';

export const FETCH_USERS_WITH_UNASSIGNED_ROLE_API = (ignoreIds, searchQuery, page) =>
    `/api/admin/companies/users/unassigned_role/paginated?searchQuery=${searchQuery}&page=${page}`;
export const FETCH_USERS_WITH_ROLE_API = (roleId, searchQuery, ignoreIds, page) =>
    `/api/admin/roles/${roleId}/users/paginated?searchQuery=${searchQuery}&page=${page}&ignoreIds=${ignoreIds}`;

export const FETCH_SINGLE_USER_API = id => `/api/admin/users/${id}/profile`;
export const DELETE_USER_API = id => `/api/admin/users/${id}/delete`;
export const EDIT_USER_API = id => `/api/admin/users/${id}/edit`;
export const GET_MAIN_CATEGORIES_FOR_USER = userId => `/api/admin/users/${userId}/main_categories`;
export const EDIT_USER_IN_BATCH_API = `/api/admin/users/edit`;
export const EDIT_USERS_ROLE_IN_BATCH_API = `/api/admin/users/edit/change_role_in_batch`;
export const CHANGE_PASSWORD_API = `/api/users/change_password`;
export const CHANGE_PASSWORD_FOR_USER_API = id => `/api/users/${id}/change_password`;
export const INVITE_USER_API = '/api/admin/users/invite';
export const REINVITE_USERS_API = '/api/admin/users/reinvite';
export const DEACTIVATE_USER_API = id => `/api/admin/users/${id}/deactivate`;
export const ACTIVATE_USER_API = id => `/api/admin/users/${id}/activate`;
export const USER_BATCH_ACTION_API = '/api/admin/users/batch_action';
export const FETCH_COMPANY_ROLES_API = '/api/admin/roles';
export const FETCH_ROLES_CONNECTED_TO_SET_API = setId => `/api/admin/learning_sets/${setId}/roles`;
export const FETCH_COMPANY_ROLES_PAGINATED_API = '/api/admin/roles/paginated';
export const FETCH_SINGLE_COMPANY_ROLE_API = id => `/api/admin/roles/${id}`;
export const FETCH_COMPANY_ROLES_WITH_ADDITIONAL_INFO_API = '/api/admin/roles/additional_info';
export const SAVE_ROLES_NEW_API = '/api/admin/roles/save';
export const FETCH_AUTH_ROLES_API = '/api/admin/auth_roles';
export const GET_COMPANY_API = '/api/admin/companies/details';
export const INVITE_MULTIPLE_USERS_API = '/api/admin/users/invite/multiple';
export const INVITE_MULTIPLE_ADMINS_API = '/api/admin/users/invite/administrators';
export const UPLOAD_USERS_API = '/api/admin/users/upload';
export const UPLOAD_USER_TAGS_API = '/api/admin/tags/upload';
export const GET_ACTIVE_USERS_FOR_MONTH_API = `${API_ENDPOINT}/api/admin/statistics/dashboard/monthly_active_users_export`;
export const GET_ACTIVE_USERS_COUNT_FOR_MONTH_API = `/api/admin/statistics/dashboard/monthly_active_users`;
export const GET_TOPIC_CONTENT_API = id => `/api/admin/categories/${id}`;
export const QUESTIONS_USED_IN_TOPIC_API = id => `/api/admin/categories/${id}/questions/used`;
export const QUESTIONS_UNUSED_IN_TOPIC_API = id => `/api/admin/categories/${id}/questions/unused_topic`;
export const QUESTIONS_UNUSED_IN_COMPANY_API = id => `/api/admin/categories/${id}/questions/unused_company`;
export const GET_ALL_TOPICS_FROM_COMPANY_API = '/api/admin/categories/all';
export const GET_ALL_TOPICS_FROM_COMPANY_WITH_ADDITIONAL_INFO_API = '/api/admin/categories/all/info';
export const UPDATE_TOPICS_TREE_API = '/api/admin/learning_sets/update/tree';
export const UPDATE_TRAINING_SETTINGS_API = setId => `/api/admin/learning_sets/${setId}/update/settings`;
export const CREATE_NEW_TRAINING_API = `/api/admin/learning_sets/create`;
export const UPLOAD_TRAINING_API = `/api/admin/training/upload`;
export const UPDATE_TOPIC_API = id => `/api/admin/categories/${id}/update`;
export const UPDATE_MULTIPLE_TOPICS_API = `/api/admin/categories/update_multiple`;
export const UPDATE_MULTIPLE_QUESTIONS_TOPICS_API = `/api/admin/questions/update_categories`;
export const GET_ALL_QUESTIONS_API = '/api/admin/questions/all';
export const GET_QUESTIONS_PAGINATED_API = '/api/admin/questions/paginated';
export const GET_QUESTIONS_INDEXES_API = '/api/admin/questions/indexes';
export const GET_ALL_QUESTIONS_MINIMIZED_API = '/api/admin/questions/all/minimized';
export const GET_SINGLE_QUESTION_API = id => `/api/admin/questions/${id}`;
export const UPLOAD_IMAGE = '/api/admin/images/upload';

export const INSERT_QUESTION_API = '/api/admin/questions/edit';
export const UPDATE_QUESTIONS_BULK_API = (action, questionIds) =>
    '/api/admin/questions/edit/bulk/' + action + '?questionIds=' + questionIds.join(',');
export const PRE_BULK_ACTION_QUESTIONS_API = questionIds => '/api/admin/questions/edit/bulk/confirm_action?questionIds=' + questionIds.join(',');

export const GET_SETTINGS = '/api/admin/game_settings/all';
export const UPDATE_SETTINGS = '/api/admin/game_settings/update';

export const GET_FOLDER_STRUCTURE_API = '/api/admin/folders/all';
export const SAVE_FOLDERS_API = '/api/admin/folders/save';
export const DELETE_IMAGE_FOLDER_API = id => `/api/admin/folders/${id}`;
export const MOVE_IMAGES_TO_FOLDER_API = folderId => `/api/admin/images/move/${folderId}`;
export const SAVE_IMAGE_TO_FOLDER_API = '/api/admin/images/upload';
export const UPDATE_IMAGE_API = '/api/admin/images/update';
export const SAVE_LESSON_ASSET_API = '/api/admin/images/assets/lessons/upload';
export const GET_ALL_QUESTIONS_FOR_CATEGORY_API = categoryId => '/api/admin/questions/from_category?categoryId=' + categoryId;
export const CHANGE_QUESTION_STATUS_API = (questionId, status) => '/api/admin/questions/change_status?questionId=' + questionId + '&status=' + status;

export const SAVE_ASSET_API = '/api/admin/assets/upload';
export const SAVE_NEWS_API = (type, companyId) => `/api/admin/news/write/${type}${companyId ? '/' + companyId : ''}`;
//Special case
export const DOWNLOAD_TEMPLATE_API = `${API_ENDPOINT}/api/admin/generate_template/user_upload`;
export const DOWNLOAD_USER_TEMPLATE_FULL_API = `${API_ENDPOINT}/api/admin/users/invite/multiple/auto_generate/format`;
export const GENERATE_MULTIPLE_USERS_FULL_API = `${API_ENDPOINT}/api/admin/users/invite/multiple/auto_generate/full`;

export const DOWNLOAD_USER_TAGS_TEMPLATE_API = `${API_ENDPOINT}/api/admin/generate_template/user_tags`;

export const GET_IMAGES_FROM_FOLDER_API = () => `/api/admin/folders/images`;
export const GET_ALL_IMAGES_API = () => `/api/admin/images/all`;
export const GET_IMAGES_COUNT_API = () => `/api/admin/images/count`;
export const GET_UNCATEGORIZED_IMAGES_API = () => `/api/admin/images/uncategorized`;
export const GET_FEEDBACK_API = () => `/api/admin/feedback`;
export const GET_FEEDBACK_CONTENT_PAGINATED_API = () => `/api/admin/feedback/content/paginated`;
export const GET_FEEDBACK_POSITIVE_PAGINATED_API = () => `/api/admin/feedback/positive/paginated`;
export const UPDATE_FEEDBACK_API = (feedbackId, resolverComment) =>
    resolverComment ? `/api/admin/feedback/${feedbackId}/update?resolverComment=${resolverComment}` : `/api/admin/feedback/${feedbackId}/update`;
export const DELETE_FEEDBACK_API = feedbackId => `/api/admin/feedback/${feedbackId}/delete`;
export const PRE_BULK_ACTION_FEEDBACKS_API = feedbackIds => `/api/admin/feedback/bulk/confirm_action?feedbackIds=${feedbackIds}`;
export const RESOLVE_FEEDBACK_BULK_API = feedbackIds => `/api/admin/feedback/resolve_bulk?feedbackIds=${feedbackIds}`;
export const UNRESOLVE_FEEDBACK_BULK_API = feedbackIds => `/api/admin/feedback/unresolve_bulk?feedbackIds=${feedbackIds}`;
export const DELETE_FEEDBACK_BULK_API = feedbackIds => `/api/admin/feedback/delete_bulk?feedbackIds=${feedbackIds}`;
export const GET_SPECIFIC_FEEDBACK_API = feedbackId => `/api/admin/feedback/${feedbackId}`;
export const REGISTER_ADMINISTRATOR_API = () => `/api/users/add/detailed`;
export const FORGOT_PASSWORD_REQUEST_API = () => `/api/users/administrator/forgot_password/request_new`;
export const FORGOT_PASSWORD_SET_NEW_API = () => `/api/users/forgot_password/set_new`;
export const GET_NEWS_API = () => `/api/admin/news`;
export const GET_NEWS_PAGINATED_API = () => `/api/admin/news/paginated`;
export const GET_LATEST_UPDATES_API = `/api/admin/news/latest_updates`;
export const SET_LATEST_UPDATES_SEEN_API = '/api/admin/news/set_seen';
export const GET_SPECIFIC_NEWS_API = newsId => `/api/admin/news/${newsId}`;
export const DELETE_NEWS_API = newsId => `/api/admin/news/${newsId}`;
export const DASHBOARD_STATISTICS_API = '/api/admin/statistics/dashboard/new';
export const GLOBAL_STATISTICS_API = userId => `/api/admin/statistics/users/${userId}/global_stats`;
export const TRAINING_STATISTICS_API = userId => `/api/admin/statistics/users/${userId}/training_stats`;
export const SINGLE_TOPIC_STATS_API = (userId, topicId) => `/api/admin/statistics/users/${userId}/training_stats/${topicId}`;
export const GET_GAMES_API = `/api/admin/games`;
export const GET_ALL_GAMES_API = `/api/admin/games/all`;
export const DOWNLOAD_GAME_TEMPLATES_API = `${API_ENDPOINT}/api/admin/generate_template/games`;
export const EXPORT_GAME_QUESTIONS_API = gameSlug => `${API_ENDPOINT}/api/admin/questions/${gameSlug}/export`;
export const EXPORT_ALL_QUESTIONS_API = `${API_ENDPOINT}/api/admin/questions/export`;
export const UPLOAD_CONTENT_API = `/api/admin/content/upload`;
export const TRAININGS_INFO_API = `/api/admin/learning_sets/info`;
export const SINGLE_TRAINING_API = id => `/api/admin/learning_sets/${id}`;
export const DELETE_TRAINING_API = id => `/api/admin/learning_sets/${id}/delete`;
export const ALL_TRAININGS_API = () => `/api/admin/learning_sets`;
export const SAVE_TRAINING_MODULE_LEARNING_SET_API = () => `/api/admin/learning_sets/training_module/save`;
export const GET_ALL_TRAINING_MODULES_COMPANY_API = () => `/api/admin/learning_sets/training_module/all`;
export const GET_TRAINING_MODULE_COMPANY_API = trainingModuleId => `/api/admin/learning_sets/training_module/${trainingModuleId}`;
export const DELETE_TRAINING_MODULE_API = trainingModuleId => `/api/admin/learning_sets/training_module/${trainingModuleId}/delete`;

export const GET_TOPICS_FOLDER_STRUCTURE_API = `/api/admin/folders/categories`;
export const UPDATE_TOPICS_FOLDER_STRUCTURE_API = `/api/admin/folders/categories/update`;
export const GET_HTML_API = `/api/admin/html`;
export const UPDATE_TOPIC_SETTINGS_API = (trainingId, topicId) => `/api/admin/learning_sets/${trainingId}/categories/${topicId}/update_settings`;
export const COMPANY_UNIT_STRUCTURE_API = '/api/admin/company_structure/units';
export const GET_COMPANY_INFO_API = '/api/admin/company/info';
export const UPDATE_COMPANY_GLOBAL_CONFIG_API = '/api/admin/company/configuration/update';
export const CREATE_COMPANY_WITH_CODE_API = '/api/admin/companies/create_with_code';
export const GET_GAMES_QUESTION_TYPE_VARIATIONS_API = '/api/admin/games_question_type_variations';
export const UPDATE_LABELS_API = '/api/admin/labels/update';
export const GET_TAGS_API = '/api/admin/tags';
export const GET_LABELS_API = '/api/admin/labels';
export const CREATE_TAG_API = '/api/admin/tags/create';
export const GET_LABEL_BY_ID_API = id => `/api/admin/labels/${id}`;
export const DELETE_LABEL_BY_ID_API = id => `/api/admin/labels/${id}/delete`;
export const DELETE_TAG_BY_ID_API = id => `/api/admin/tags/${id}/delete`;
export const GET_USERS_WITH_TAG_API = id => `/api/admin/tags/${id}/users`;
export const GET_USERS_WITHOUT_TAG_WITH_ID_API = id => `/api/admin/tags/${id}/unasigned_users`;
export const ADD_USER_TO_TAG_API = id => `/api/admin/tags/${id}/users/add`;
export const REMOVE_USER_FROM_TAG_API = id => `/api/admin/tags/${id}/users/remove`;
export const ADD_USERS_TO_TAGS_API = '/api/admin/tags/multiple_user_tags/add';
export const REMOVE_USERS_FROM_TAGS_API = '/api/admin/tags/multiple_user_tags/remove';
export const ALL_NOTIFICATIONS_PAGINATED_API = '/api/admin/notifications';
export const GET_SINGLE_NOTIFICATION_API = id => `/api/admin/notifications/${id}`;
export const POST_NEW_NOTIFICATION_API = '/api/admin/notifications/single_update';
export const DELETE_NOTIFICATION_API = id => `/api/admin/notifications/${id}/delete`;

export const SAVE_CONTENT_BLOCK_API = '/api/admin/content_blocks/save';
export const GET_CONTENT_BLOCKS_PAGINATED_API = '/api/admin/content_blocks/paginated';
export const GET_CONTENT_BLOCKS_BY_ID_API = id => `/api/admin/content_blocks/${id}`;
export const DELETE_CONTENT_BLOCKS_BY_ID_API = id => `/api/admin/content_blocks/${id}/delete`;
export const IMAGES_DELETE_SOFT_API = `/api/admin/images/delete_soft`;
export const IMAGES_DELETE_FORCE_API = `/api/admin/images/delete_force`;

export const GENERATE_CONTENT_API = '/api/admin/content/generate';
export const GENERATE_CONTENT_FOR_TOPIC_API = '/api/admin/content/generate/single_category';

// Device requests
export const GET_DEVICE_REQUESTS_PAGINATED_API = '/api/admin/users/device_requests';
export const RESOLVE_DEVICE_REQUESTS_BULK_API = '/api/admin/users/device_requests/resolve/bulk';
export const DELETE_DEVICE_REQUESTS_BULK_API = '/api/admin/users/device_requests/delete/bulk';
export const GET_DEVICE_REQUESTS_FOR_USER_API = userId => `/api/admin/users/${userId}/device_requests`;
