import PropTypes from 'prop-types';
import React from 'react';

import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { asyncForEach } from 'services/utils/arrayHelpers';
import { views } from 'services/image_management/image-editor-management/local-constants';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';

import UploadImage from 'assets/icons/icon-gallery-uploadimage.svg';
import { Body2 } from 'style/typography/Body';

import { toast } from 'react-hot-toast';
import GallerySubheaderActions from './GallerySubheaderActions';
import { useTranslation } from 'react-i18next';
import GalleryActionsContainer from './GalleryActionsContainer';

export default function GallerySubheader({
    index,
    setModalOpen,
    onImagesUploadCallback,
    showSelectImagesButton = false,
    addImage,
    addMultipleImages,
    onImageUploaded,
    showUploadImageButton = false,
}) {
    const { t } = useTranslation();

    const {
        setView,
        setImageFiles,
        setOpenImageResizer,
        selectedImages,
        setAllImagesSelected,
        allImagesSelected,
        setSelectedImages,
    } = useImageEditor();

    const onImagesWithUrlUploadChange = async urls => {
        if (urls.length > 0) {
            let filesBlob = [];
            let files = [];
            let unSuccessful = [];

            await asyncForEach(urls, async imageObj => {
                const fetchParams = {
                    method: 'GET',
                    mode: 'cors',
                };

                const response = await fetch(imageObj.urlOriginal, fetchParams);
                if (response.ok) {
                    const blob = await response.blob();
                    let urlSplitted = imageObj.urlOriginal.split('/');
                    let name = urlSplitted[urlSplitted.length - 1];
                    const file = new File([blob], name, { type: blob.type });
                    files.push(file);
                } else {
                    unSuccessful.push('Not loaded');
                }
            });

            await asyncForEach(files, async (file, index) => {
                filesBlob.push({
                    url: URL.createObjectURL(file),
                    name: file.name,
                    lastModified: Date.now(),
                    size: file.size,
                    crop: {},
                    zoom: {},
                    croppedImage: {},
                    croppedAreaPixels: {},
                    id: urls[index].id,
                });
            });
            if (unSuccessful.length > 0) {
                toast.error(t('Cannot load {{imageCount}} images.', { imageCount: unSuccessful.length }));
            }
            if (files.length > 0) {
                setImageFiles({ index: index, files: files, filesBlob: filesBlob, isEditing: true });
                setView(views[0]);
                if (onImagesUploadCallback) {
                    onImagesUploadCallback();
                }
            }
        }
    };

    function onChooseSelected() {
        let sel = selectedImages;
        if (sel.length == 0) {
            alert(t('Number of selected images is incorrect'));
            return;
        }
        if (addMultipleImages) {
            addMultipleImages(index, sel);
        } else if (addImage) {
            sel.forEach(el => {
                addImage(index, el);
            });
        } else if (onImageUploaded) {
            onImageUploaded(sel);
        }
        setOpenImageResizer(false);
    }

    return (
        <div>
            <div className="d-flex p-2" style={{ background: colors.gray4, height: 60 }}>
                <div className="d-flex flex-row align-items-center" style={{ minHeight: 0, flex: 1 }}>
                    <div className="mx-2">
                        <Caption1 color={colors.gray6}>
                            {allImagesSelected ? t('all images selected') : `${selectedImages.length} ${t('selected')}`}
                        </Caption1>
                    </div>

                    <GallerySubheaderActions onImagesWithUrlUploadChange={onImagesWithUrlUploadChange} clearSelection={() => setSelectedImages([])} />

                    {!showSelectImagesButton && !allImagesSelected && (
                        <DefaultButton
                            className="ml-2 mr-2"
                            onClick={() => {
                                setAllImagesSelected(true);
                            }}
                            backgroundColor={colors.white}
                            textColor={colors.gray6}
                            style={{ border: `1px solid ${colors.gray2}` }}
                        >
                            {t('Select all')}
                        </DefaultButton>
                    )}

                    {(selectedImages.length > 0 || allImagesSelected) && showSelectImagesButton && (
                        <OutlinedButton className="ml-2 mr-2" onClick={onChooseSelected}>
                            {t('Choose selected')}
                        </OutlinedButton>
                    )}

                    {(selectedImages.length > 0 || allImagesSelected) && (
                        <Body2
                            color={colors.gray6}
                            className="ml-2 mr-2"
                            onClick={() => {
                                setSelectedImages([]);
                                setAllImagesSelected(false);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            {t('Clear selection')}
                        </Body2>
                    )}
                </div>
                <div className="d-flex flex-row align-items-center">
                    {showUploadImageButton && (
                        <DefaultButton
                            className="ml-2"
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        >
                            <img alt="Upload" src={UploadImage} style={{ height: '17px', width: '17px', marginRight: '10px' }} />
                            {t('Upload Image')}
                        </DefaultButton>
                    )}
                </div>
            </div>
            <GalleryActionsContainer />
        </div>
    );
}

GallerySubheader.propTypes = {
    addImage: PropTypes.func,
    addMultipleImages: PropTypes.func,
    index: PropTypes.any,
    onImageUploaded: PropTypes.func,
    onImagesUploadCallback: PropTypes.func,
    setModalOpen: PropTypes.func,
    showSelectImagesButton: PropTypes.bool,
    showUploadImageButton: PropTypes.bool,
};
