import React from 'react';
import CroppingComponent from './CroppingComponent';
import { palette } from 'style/theme/theme-constants';
import { views } from 'services/image_management/image-editor-management/local-constants';
import { InfoSideBar } from './InfoSideBar';
import { colors } from 'style/colors';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { H7 } from 'style/typography/Heading';
import { Subtitle2 } from 'style/typography/Subtitle';
import getCroppedImg from 'services/image_management/cropImage';
import { Body1 } from 'style/typography/Body';

import { useTranslation } from 'react-i18next';
import AngleLeftDark from 'assets/icons/angle-left-dark.svg';

import './CroppingModule.css';

export function EditorSpace() {
    const { t } = useTranslation();

    const {
        imageFiles,
        currentActivePreviewImage,
        aspectRatio,
        setCurrentActiveIndex,
        setCroppedAreaPixels,
        setView,
        currentActiveIndex,
        setCropAndZoom,
        index,
    } = useImageEditor();

    function getPrevious() {
        if (currentActiveIndex > 0) {
            setCurrentActiveIndex(currentActiveIndex - 1);
        }
    }

    function getNext() {
        if (currentActiveIndex < imageFiles.filesBlob.length - 1) {
            setCurrentActiveIndex(currentActiveIndex + 1);
        }
    }
    async function onUpdateCroppedAreaPixels(file, croppedAreaPixels, aspectRatio) {
        let croppedUrl = await getCroppedImg(file.url, croppedAreaPixels);
        file.croppedImage[aspectRatio] = croppedUrl;

        setCroppedAreaPixels(file, croppedAreaPixels, aspectRatio);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flex: 1 }}>
            <div
                className="d-flex flex-row align-items-center mr-4"
                style={{ paddingTop: 10, borderBottom: `1px solid ${palette.border.separator}` }}
            >
                <div style={{ marginLeft: 30 }}>
                    <H7 className="my-2" weight="medium">
                        {t('Edit image')}
                    </H7>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center" style={{ flex: 1 }}>
                    <div className="editor-space--angle-icon">
                        <img alt="Angle Left" src={AngleLeftDark} height={17} width={17} onClick={getPrevious} />
                    </div>
                    <Subtitle2 className="my-2 mx-2">
                        {currentActiveIndex + 1}/{imageFiles.filesBlob.length}
                    </Subtitle2>

                    <div className="editor-space--angle-icon">
                        <img alt="Angle Right" height={17} width={17} src={AngleLeftDark} style={{ transform: 'rotate(180deg)' }} onClick={getNext} />
                    </div>
                </div>
                <Body1
                    color={colors.gray6}
                    onClick={() => {
                        setView(views[0]);
                    }}
                    className="cursor-pointer"
                >
                    {t('Back')}
                </Body1>
            </div>

            <div className="d-flex flex-row" style={{ height: '100%' }}>
                <div className="d-flex flex-column pt-3" style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <CroppingComponent
                        key={aspectRatio}
                        setCropAndZoom={setCropAndZoom}
                        hasSlider={true}
                        file={imageFiles.filesBlob[currentActiveIndex]}
                        index={index}
                        croppedAreaPixels={imageFiles.filesBlob[currentActiveIndex].croppedAreaPixels}
                        setCroppedAreaPixels={onUpdateCroppedAreaPixels}
                        aspectRatio={aspectRatio}
                    />
                </div>
                <InfoSideBar fromEditorSpace={true} image={currentActivePreviewImage} aspectRatio={aspectRatio} />
            </div>
        </div>
    );
}
