import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { colors } from 'style/colors';
import { changePasswordForUserAction } from 'redux/actions/users.actions';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoading } from 'redux/core/stateHelpers';
import Alert from '@material-ui/lab/Alert/Alert';
import Collapse from '@material-ui/core/Collapse/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { Body1 } from 'style/typography/Body';
import { OutlinedButton } from 'components/elements/Button';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { Caption1 } from 'style/typography/Caption';
import { useTranslation } from 'react-i18next';

export default function ProfilePasswordSetting({ id, editedUserData, dispatchEditedUser }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const changePasswordState = useSelector(state => state.userManagement.changePassword);

    const [changePasswordInitiated, setChangePasswordInitiated] = useState(false);
    const [changePasswordError, setChangePasswordError] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    function changePassword() {
        if (editedUserData.newPassword !== editedUserData.repeatPassword) {
            setValidationMessage(t("The passwords don't match."));
            setChangePasswordError(true);

            return;
        }
        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        let validatePassword = re.test(editedUserData.newPassword);

        if (!validatePassword) {
            setValidationMessage(
                t(
                    'Password must contain at least one upper case character, lower case character, a number and a special symbol and be at least 8 characters.'
                )
            );
            setChangePasswordError(true);

            return;
        }

        setChangePasswordError(false);
        dispatch(
            changePasswordForUserAction(id, {
                newPassword: editedUserData.newPassword,
            })
        );
    }

    return (
        <div className="d-flex flex-row align-items-center py-4" style={{ borderBottom: `solid 1px ${colors.disabledColor}` }}>
            <Body1 className={'mb-0'}>{t('Password')}</Body1>
            {changePasswordInitiated && (
                <div className="d-flex flex-column ml-auto align-items-end">
                    <div className="d-flex flex-row align-items-center justify-content-end mt-2">
                        <Caption1 className={'mb-0 mr-2'}>{t('New')}</Caption1>
                        <CustomTextField
                            style={{ minWidth: 300 }}
                            id="new-password-input"
                            disabled={stateIsLoading(changePasswordState)}
                            value={editedUserData.newPassword}
                            setValue={val => dispatchEditedUser({ newPassword: val })}
                            error={changePasswordError}
                            setError={setChangePasswordError}
                            visibilityIcon={true}
                            type={'password'}
                        />
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-end mt-2">
                        <Caption1 className={'mb-0 mr-2'}>{t('Retype new')}</Caption1>
                        <CustomTextField
                            style={{ minWidth: 300 }}
                            id="retype-password-input"
                            disabled={stateIsLoading(changePasswordState)}
                            value={editedUserData.repeatPassword}
                            setValue={val => dispatchEditedUser({ repeatPassword: val })}
                            error={changePasswordError}
                            setError={setChangePasswordError}
                            visibilityIcon={true}
                            type={'password'}
                        />
                    </div>
                    <Collapse className={'mt-2'} in={changePasswordError}>
                        <Alert severity="error">{validationMessage}</Alert>
                    </Collapse>
                    <div className="d-flex flex-row mt-4 justify-content-between">
                        {stateIsLoading(changePasswordState) && <CircularProgress className={'mr-2'} style={{ color: colors.mainTheme }} size={25} />}

                        <OutlinedButton
                            disabled={
                                editedUserData.newPassword.length < 6 ||
                                editedUserData.repeatPassword.length < 6 ||
                                stateIsLoading(changePasswordState)
                            }
                            onClick={() => changePassword()}
                            text={t('Save Changes')}
                            className={'mr-2'}
                        />
                        <OutlinedButton
                            disabled={stateIsLoading(changePasswordState)}
                            onClick={() => setChangePasswordInitiated(false)}
                            text={t('Cancel')}
                        />
                    </div>
                </div>
            )}
            {!changePasswordInitiated && (
                <OutlinedButton
                    id="change-password-button"
                    text={t('Change password')}
                    onClick={() => setChangePasswordInitiated(true)}
                    className={'ml-auto'}
                />
            )}
        </div>
    );
}

ProfilePasswordSetting.propTypes = {
    dispatchEditedUser: PropTypes.func,
    editedUserData: PropTypes.any,
    id: PropTypes.number,
};
