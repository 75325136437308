import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useImageCategories } from 'services/content-management/insertion-control';
import RemoveImage from 'assets/icons/icon-removeimage.svg';
import SelectionTooltip from 'components/elements/tooltips/SelectionTooltip';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { getNameFromLink, shortenString } from 'services/utils/stringHelpers';

export default function PreviewImageInputCategories({ file, categoryIndex, imageIndex }) {
    const { removeSingleImage } = useImageCategories();

    const [showImageDialog, setShowImageDialog] = useState(false);

    function setToShowDialog(toShow) {
        setTimeoutLastExecute.addCallback(
            () => {
                setShowImageDialog(toShow);
            },
            100,
            `showDialogImage${imageIndex}-${categoryIndex}`
        );
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
                onMouseEnter={() => setToShowDialog(true)}
                onMouseLeave={() => setToShowDialog(false)}
                id={`preview-image-${imageIndex}-category-${categoryIndex}`}
                src={file}
                alt="preview"
                className="category-images-preview-small"
            />
            {showImageDialog && (
                <SelectionTooltip
                    visible={showImageDialog}
                    onMouseEnter={() => setToShowDialog(true)}
                    onMouseLeave={() => setToShowDialog(false)}
                    position={'dsds'}
                    marginTop={50}
                    options={[
                        { text: shortenString(getNameFromLink(file), 20), type: 'title' },
                        {
                            text: 'Remove',
                            icon: RemoveImage,
                            type: 'button',
                            onClick: () => {
                                removeSingleImage(categoryIndex, imageIndex);
                                setToShowDialog(false);
                            },
                        },
                    ]}
                />
            )}
        </div>
    );
}

PreviewImageInputCategories.propTypes = {
    categoryIndex: PropTypes.any,
    file: PropTypes.any,
    imageIndex: PropTypes.any,
};
