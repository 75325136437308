import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllGamesAction,
    getCompanyInfoAction,
    resetUpdateCompanyGlobalConfigurationAction,
    updateCompanyGlobalConfigurationAction,
} from 'redux/actions/content.actions';
import { stateIsLoaded, stateIsLoading, stateIsNotInitialized } from 'redux/core/stateHelpers';
import { colors } from 'style/colors';
import { Circular } from 'components/features/data_table/Circular';
import { Body1, Body2 } from 'style/typography/Body';
import { DefaultButton } from 'components/elements/Button';
import { Trans, useTranslation } from 'react-i18next';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import CustomSwitch from 'components/elements/switch/CustomSwitch';
import toast from 'react-hot-toast';
import Info from 'assets/icons/info-tooltip.svg';
import InfoTooltip from 'components/elements/tooltips/InfoTooltip';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';

import _ from 'lodash';

import './CompanyInfo.css';
import { Subtitle1, Subtitle2 } from 'style/typography/Subtitle';

export function CompanyInfo() {
    const MIN_SECONDS = 10;
    const MAX_SECONDS = 600;

    const dispatch = useDispatch();

    const [globalConfig, setGlobalConfig] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [industry, setIndustry] = useState('');
    const [email, setEmail] = useState('');
    const [hovering, setHovering] = useState('');
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    const [learnUrlError, setLearnUrlError] = useState(false);

    const companyInfoState = useSelector(state => state.content.companyInfo);
    const updateGlobalConfigState = useSelector(state => state.content.updateCompanyGlobalConfig);
    const companyDetailsState = useSelector(state => state.userManagement.company);
    const allGamesState = useSelector(state => state.content.allGames);

    const { t, i18n } = useTranslation();

    const locales = [
        { label: 'English', value: 'en' },
        { label: 'Macedonian', value: 'mk' },
    ];

    function updateCompanyInfoState() {
        let data = companyInfoState.data;

        setGlobalConfig({ ...data.globalConfiguration, games: data.games });
        setCompanyName(data.companyName);
        setIndustry(data.industry);
        setEmail(data.email);
        setSupportedLanguages(
            data.globalConfiguration.supportedLanguages.map(el => {
                return {
                    label: el,
                    value: el,
                };
            })
        );
    }

    useEffect(() => {
        dispatch(getCompanyInfoAction());
    }, []);

    useEffect(() => {
        if (stateIsLoaded(companyInfoState)) {
            updateCompanyInfoState();
        }
    }, [companyInfoState]);

    useEffect(() => {
        if (stateIsLoaded(updateGlobalConfigState)) {
            toast.success(t('Successfully updated configurations'));

            dispatch(getCompanyInfoAction());
            dispatch(resetUpdateCompanyGlobalConfigurationAction());
        }
    }, [updateGlobalConfigState]);

    function resetState() {
        updateCompanyInfoState();
    }

    function updateConfiguration() {
        if (globalConfig.learnEnabled && (globalConfig.learnUrl === null || globalConfig.learnUrl.length === 0)) {
            setLearnUrlError(true);
            return;
        }
        dispatch(updateCompanyGlobalConfigurationAction(globalConfig));
    }

    function updateGameActive(game) {
        const cloneConfigGames = _.cloneDeep(globalConfig.games);
        const gameConfigClone = cloneConfigGames.find(el => el.gameId === game.gameId);

        if (gameConfigClone) {
            gameConfigClone.isActive = !gameConfigClone.isActive;
        }

        setGlobalConfig({
            ...globalConfig,
            games: cloneConfigGames,
        });
    }

    function updateGameTimer(game, value) {
        const cloneConfigGames = _.cloneDeep(globalConfig.games);
        const gameConfigClone = cloneConfigGames.find(el => el.gameId === game.gameId);

        gameConfigClone.timer = value;

        setGlobalConfig({
            ...globalConfig,
            games: cloneConfigGames,
        });
    }

    function checkGameValue(game) {
        const cloneConfigGames = _.cloneDeep(globalConfig.games);
        const gameConfigClone = cloneConfigGames.find(el => el.gameId === game.gameId);

        if (gameConfigClone.timer < MIN_SECONDS) {
            gameConfigClone.timer = MIN_SECONDS;
        } else if (gameConfigClone.timer > MAX_SECONDS) {
            gameConfigClone.timer = MAX_SECONDS;
        }

        setGlobalConfig({
            ...globalConfig,
            games: cloneConfigGames,
        });
    }

    useEffect(() => {
        if (stateIsNotInitialized(allGamesState)) {
            dispatch(getAllGamesAction());
        }
    }, [allGamesState]);

    if (!stateIsLoaded(companyInfoState)) {
        return (
            <div style={{ height: '90vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                <Circular />
            </div>
        );
    }
    return (
        <div className={'d-flex flex-column'}>
            <div className="company-info--header">
                <div className="company-info--header-item">
                    <Body2 color={colors.gray6} weight="medium">
                        {t('NAME')}
                    </Body2>
                    <Subtitle2 weight="medium" color={colors.dark}>
                        {companyName}
                    </Subtitle2>
                </div>
                <div className="company-info--header-item">
                    <Body2 color={colors.gray6} weight="medium">
                        {t('INDUSTRY')}
                    </Body2>
                    <Subtitle2 color={colors.gray6}>{industry}</Subtitle2>
                </div>
                <div className="company-info--header-item">
                    <Body2 color={colors.gray6} weight="medium">
                        {t('ACTIVE LICENCES LEFT')}
                    </Body2>
                    <Subtitle2 color={colors.gray6}>
                        <Trans>
                            {t('<strong>{{licencesLeft}}</strong> of <strong>{{licences}}</strong>', {
                                licencesLeft: companyDetailsState.data?.userLicencesLeft,
                                licences: companyDetailsState.data?.totalUserLicences,
                            })}
                        </Trans>
                    </Subtitle2>
                </div>
            </div>
            <div className="company-info--settings">
                <div className="company-info--setting">
                    <div className="company-info--title">
                        <Body1 color={colors.dark}>{t('Default language')}</Body1>
                        <div>
                            <img
                                style={{ cursor: 'pointer', marginLeft: 7 }}
                                alt="info"
                                src={Info}
                                height={20}
                                width={20}
                                onMouseEnter={() => setHovering('language')}
                                onMouseLeave={() => setHovering('')}
                            />
                            {hovering === 'language' && (
                                <InfoTooltip
                                    visible={hovering === 'language'}
                                    message={t(
                                        '‘Default language’ shows the currently selected language in the Coach app. You can change the language by pressing the box and selecting one of the options in the drop-down menu.'
                                    )}
                                    position={'bottom'}
                                />
                            )}
                        </div>
                    </div>
                    <CustomSelect
                        id="language-dropdown-button"
                        width={'150px'}
                        isClearable={false}
                        value={locales.filter(el => el.value === globalConfig?.defaultLang)?.[0] ?? locales[0]}
                        onChange={option => {
                            setGlobalConfig({
                                ...globalConfig,
                                defaultLang: option.value,
                            });
                        }}
                        options={locales}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                neutral50: colors.disabled,
                            },
                        })}
                    />
                </div>

                <div className={'company-info--setting'}>
                    <div className="company-info--title">
                        <Body1 color={colors.dark}>{t('Count only active users')}</Body1>
                        <div>
                            <img
                                style={{ cursor: 'pointer', marginLeft: 7 }}
                                alt="info"
                                src={Info}
                                height={20}
                                width={20}
                                onMouseEnter={() => setHovering('active-users')}
                                onMouseLeave={() => setHovering('')}
                            />
                            {hovering === 'active-users' && (
                                <InfoTooltip
                                    visible={hovering === 'active-users'}
                                    message={t(
                                        '‘Count only active users’ shows if you would like to count only currently active users of the application in the general statistics and analytics.'
                                    )}
                                    position={'bottom'}
                                />
                            )}
                        </div>
                    </div>
                    <CustomSwitch
                        checked={globalConfig?.countOnlyActiveUsers}
                        onClick={() => {
                            setGlobalConfig({
                                ...globalConfig,
                                countOnlyActiveUsers: !globalConfig?.countOnlyActiveUsers,
                            });
                        }}
                    />
                </div>
                <div className={'company-info--setting'}>
                    <div className="company-info--title">
                        <Body1 color={colors.dark}>{t('Allow multiple devices for single account')}</Body1>
                        <div>
                            <img
                                style={{ cursor: 'pointer', marginLeft: 7 }}
                                alt="info"
                                src={Info}
                                height={20}
                                width={20}
                                onMouseEnter={() => setHovering('multiple-devices')}
                                onMouseLeave={() => setHovering('')}
                            />
                            {hovering === 'multiple-devices' && (
                                <InfoTooltip
                                    visible={hovering === 'multiple-devices'}
                                    message={t('This enables a single account to be active on multiple devices.')}
                                    position={'bottom'}
                                />
                            )}
                        </div>
                    </div>
                    <CustomSwitch
                        checked={globalConfig?.allowMultipleDevicesForSingleAccount}
                        onClick={() => {
                            setGlobalConfig({
                                ...globalConfig,
                                allowMultipleDevicesForSingleAccount: !globalConfig?.allowMultipleDevicesForSingleAccount,
                            });
                        }}
                    />
                </div>
                <div>
                    <div className="company-info--setting-no-border">
                        <div className="company-info--title">
                            <Body1 color={colors.dark}>{t('Enabled games')}</Body1>
                            <div>
                                <img
                                    style={{ cursor: 'pointer', marginLeft: 7 }}
                                    alt="info"
                                    src={Info}
                                    height={20}
                                    width={20}
                                    onMouseEnter={() => setHovering('games')}
                                    onMouseLeave={() => setHovering('')}
                                />
                                {hovering === 'games' && (
                                    <InfoTooltip
                                        visible={hovering === 'games'}
                                        message={t(
                                            'Select which games will be active in this company and the duration of each game with miniumum being 10s and maximum 600s.'
                                        )}
                                        position={'bottom'}
                                    />
                                )}
                            </div>
                        </div>
                        <Body1 color={colors.gray6}>{t('Game Timer (seconds)')}</Body1>
                    </div>
                    <Body2 style={{ marginTop: 10 }} color={colors.gray6}>
                        {t(
                            'Warning: This will make changes to the gameplay in-app. Please make sure that you understand this setting before making any changes.'
                        )}
                    </Body2>
                    <div>
                        {allGamesState?.data?.map(game => {
                            let gameIsActive = globalConfig?.games?.find(g => g.gameId == game.gameId)?.isActive;
                            return (
                                <div className="company-info--game-setting">
                                    <div className="company-info--game-setting-label">
                                        <div style={{ position: 'relative', height: '20px', width: '20px' }}>
                                            <Checkbox
                                                onClick={() => {
                                                    updateGameActive(game);
                                                }}
                                                checked={gameIsActive}
                                            ></Checkbox>
                                        </div>
                                        <img src={game.gameIcon} height={30} width={30} alt="race-cars" />

                                        <Body2 color={colors.dark}>{t(game.gameName)}</Body2>
                                    </div>
                                    <CustomTextField
                                        value={globalConfig?.games?.find(g => g.gameId == game.gameId)?.timer}
                                        setValue={value => updateGameTimer(game, value)}
                                        onBlur={() => checkGameValue(game)}
                                        disabled={!gameIsActive}
                                        width={'100px'}
                                        type="number"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="company-info--buttons">
                    <DefaultButton
                        id="company-info-save-button"
                        disabled={stateIsLoading(updateGlobalConfigState)}
                        text={t('Save')}
                        className={'pl-4 pr-4'}
                        onClick={() => updateConfiguration()}
                    />
                    <DefaultButton
                        id="company-info-cancel-button"
                        disabled={stateIsLoading(updateGlobalConfigState)}
                        text={t('Cancel')}
                        className={'pl-4 pr-4'}
                        backgroundColor={colors.white}
                        textColor={colors.dark}
                        onClick={() => resetState()}
                    />
                    {stateIsLoading(updateGlobalConfigState) && <Circular style={{ padding: 0 }} size={20} />}
                </div>
            </div>
        </div>
    );
}
