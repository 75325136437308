import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { Body1, Body2 } from 'style/typography/Body';
import Dropdown from 'assets/icons/filter-caret.svg';

import './TrainingSelector.css';
import { useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { Subtitle2 } from 'style/typography/Subtitle';

import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import TrainingSelectorItem from './TrainingSelectorItem';

import Questions from 'assets/icons/topic-icon-questions.svg';
import File from 'assets/icons/topic-icon-paper.svg';
import { ImportTrainingDialog } from '../dialogs/ImportTrainingDialog';
import { useTranslation } from 'react-i18next';

export default function TrainingSelector({ value, currentTraining, setSelectedTraining, setCreateNewTrainingSwitcher, setCreateTrainingModalOpen }) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const dropdownRef = useRef(null);
    const dropdownMenuRef = useRef(null);

    const allTrainingsState = useSelector(state => state.content.allTrainings);
    const [trainings, setTrainings] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    function getTrainings() {
        return trainings?.map(training => {
            return <TrainingSelectorItem training={training} setSelectedTraining={setSelectedTraining} setIsOpen={setIsOpen} />;
        });
    }

    function handleClick(event) {
        if (dropdownMenuRef.current && dropdownMenuRef.current.contains(event.target)) {
            return;
        }

        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
            setIsOpen(false);
        } else if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
            setIsOpen(prevData => !prevData);
        }
    }

    useEffect(() => {
        if (stateIsLoaded(allTrainingsState)) {
            let data = allTrainingsState?.data?.learningSets?.map(el => {
                return { ...el.learningSetInfo };
            });
            setTrainings(data);
        }
    }, [allTrainingsState]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [isOpen]);

    return (
        <div style={{ position: 'relative', zIndex: 10, width: 400 }}>
            <div ref={dropdownRef} className="training-dropdown-selector--select">
                <ImportTrainingDialog open={dialogOpen} setOpen={setDialogOpen} setSelectedTraining={setSelectedTraining} />
                {!currentTraining && <Body2 color={colors.gray6}>{t('Select training')}</Body2>}
                {currentTraining && (
                    <div>
                        <div className="d-flex flex-column justify-content-center ml-2" style={{ width: '330px' }}>
                            <Subtitle2 className="mb-2" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                {currentTraining.name}
                            </Subtitle2>
                            <div className="d-flex flex-row position-relative">
                                <div className="d-flex flex-row align-items-center mr-4">
                                    <img alt="File" style={{ height: 20, width: 20 }} className={'mr-1'} color={colors.gray1} src={File} />
                                    <Body1 color={colors.gray1} className={'mb-0 mr-2'}>
                                        {currentTraining.topics > 0 ? currentTraining.topics - 1 : 0}
                                    </Body1>
                                </div>
                                <div className="d-flex flex-row align-items-center" style={{ position: 'absolute', left: 50 }}>
                                    <img alt="Questions" style={{ height: 20, width: 20 }} className={'mr-2'} color={colors.gray1} src={Questions} />
                                    <Body1 color={colors.gray1} className={'mb-0 mr-2'}>
                                        {currentTraining.questions}
                                    </Body1>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <img
                    alt="Dropdown"
                    style={{ transform: isOpen ? 'rotateX(180deg)' : '' }}
                    className={'ml-2 mr-2 cursor-pointer'}
                    height={20}
                    width={20}
                    src={Dropdown}
                />
            </div>
            {isOpen && (
                <div ref={dropdownMenuRef} className="training-dropdown-selector--menu">
                    <div style={{ paddingBottom: '5px' }}>
                        <Caption1 color={colors.gray6}>{t('Trainings')}</Caption1>
                    </div>
                    {getTrainings()}
                    <DefaultButton
                        text={t('Add new training')}
                        onClick={() => {
                            setCreateNewTrainingSwitcher(true);
                            setCreateTrainingModalOpen(true);
                        }}
                        style={{ margin: '10px' }}
                    />

                    <OutlinedButton
                        text={t('Import training')}
                        textColor={colors.gray6}
                        onClick={() => {
                            setDialogOpen(true);
                        }}
                        style={{ margin: '10px' }}
                    />
                </div>
            )}
        </div>
    );
}

TrainingSelector.propTypes = {
    currentTraining: PropTypes.any,
    setCreateNewTrainingSwitcher: PropTypes.func,
    setCreateTrainingModalOpen: PropTypes.func,
    setSelectedTraining: PropTypes.func,
    value: PropTypes.any,
};
