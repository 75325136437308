export const INITIAL_STATE = {
    contentBlock: null,
    rowsToShow: { top: 0, bottom: 0, left: 0, right: 0 },
    columnWidths: {},
    selectedEdges: {
        leftEndIndx: 0,
        rightEndIndx: 0,
        topEndIndx: 0,
        bottomEndIndx: 0,
    },
    firstCell: null,
    currentCell: null,
    history: [],
};

export const HISTORY_LIMIT = 20;

export const cellTypes = {
    NUMERICAL: 'numerical',
    TEXT: 'text',
    IMAGE: 'image',
};
