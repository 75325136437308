import Checkbox from 'components/elements/checkbox/Checkbox';
import InputTooltip from 'components/elements/tooltips/InputTooltip';
import React, { useRef, useState } from 'react';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { colors } from 'style/colors';
import { contentBlockColumnTypes } from 'static/content_blocks';
import { Body2 } from 'style/typography/Body';

import './ContentBlocksTable.css';

import AngleDown from 'assets/icons/angle-down-mainTheme.svg';
import { useContentBlock } from 'services/content-blocks-management/content-blocks';
import { Caption1, Caption2 } from 'style/typography/Caption';

export default function ContentBlocksTableHeader() {
    const [showDialog, setShowDialog] = useState(false);
    const [newColName, setNewColName] = useState('');
    const [resizingCol, setResizingCol] = useState('');
    const [newColType, setNewColType] = useState(contentBlockColumnTypes.find(el => el.value == 'text'));
    const [showDialogColumn, setShowDialogColumn] = useState(null);

    const editingName = useRef('');

    const { deleteColumn, pasteMultipleValuesInRow, columnWidths, setColWidthTo, addColumn, contentBlock, updateColumn } = useContentBlock();

    const handler = (mouseDownEvent, currentWidth, key) => {
        const startPosition = mouseDownEvent.pageX;
        setResizingCol(key);

        function onMouseMove(mouseMoveEvent) {
            setColWidthTo(key, currentWidth - startPosition + mouseMoveEvent.pageX);
        }
        function onMouseUp() {
            document.body.removeEventListener('mousemove', onMouseMove);
            setResizingCol('');
        }

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp, { once: true });
    };

    function setToShowDialog(toShow) {
        setTimeoutLastExecute.addCallback(
            () => {
                setShowDialog(toShow);
            },
            100,
            `showDialogAddColumn`
        );
    }

    function setToShowDialogColumn(toShow, indx) {
        setTimeoutLastExecute.addCallback(
            () => {
                editingName.current = "";
                setShowDialogColumn(toShow ? indx : null);
            },
            100,
            `showDialogAddColumn${indx}`
        );
    }



    let renderHeader = [
        <div className="cb-header--cell" style={{ position: 'relative', height: 50, width: 35 }}></div>,
        <div className="cb-header--cell" style={{ position: 'relative', height: 50, width: 50 }}>
            <Checkbox onClick={_ => { }} checked={false}></Checkbox>
        </div>,
        ...contentBlock.data.columns.map((el, indx) => {
            return (
                <div style={{ position: 'relative', overflow: 'visible' }}>
                    <div
                        className="cb-header--cell"
                        style={{ width: columnWidths[el.key], cursor: 'pointer' }}
                        onClick={() => setToShowDialogColumn(true, indx)}
                        onMouseLeave={() => setToShowDialogColumn(false, indx)}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', maxWidth: '100%',overflow: 'hidden',whiteSpace:'nowrap' }}>
                            <Body2>{el.name}</Body2>
                            <Caption2 color={colors.gray6}>({el.type})</Caption2>
                        </div>
                        <img src={AngleDown} height={10} width={10}></img>
                    </div>
                    {showDialogColumn === indx && (
                        <div style={{ top: -50, left: 0 }}>
                            <InputTooltip
                                visible={showDialog}
                                onMouseEnter={() => setToShowDialogColumn(true, indx)}
                                onMouseLeave={() => setToShowDialogColumn(false, indx)}
                                position={'bottom'}
                                options={[
                                    {
                                        value: 'Paste values',
                                        type: 'option',
                                        label: 'Paste values',
                                        onClick: (_) => {
                                            navigator.clipboard
                                                .readText()
                                                .then(val => {
                                                    if (val) {
                                                        pasteMultipleValuesInRow(0, indx, null, val);
                                                    }
                                                })
                                                .catch(error => { });
                                            setToShowDialogColumn(false, indx);
                                        },
                                    },
                                    {
                                        value: 'Change type',
                                        label: 'Change type',
                                        type: 'option',
                                        secondaryOptions: [
                                            {
                                                type: 'select',
                                                values: contentBlockColumnTypes,
                                                value: contentBlockColumnTypes.find(elInner => elInner.value == el.type),
                                                onChange: (val) => {
                                                    updateColumn(el.id, el.generatedId, el.name, val.value)
                                                    setToShowDialogColumn(false, indx);
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        value: 'Change name',
                                        label: 'Change name',
                                        type: 'option',
                                        secondaryOptions: [
                                            {
                                                type: 'text',
                                                onChange: (val) => {
                                                    editingName.current = val;
                                                },
                                            },
                                            {
                                                label: 'Update',
                                                type: 'button',
                                                onClick: (_) => {
                                                    if (editingName.current == "") return;

                                                    updateColumn(el.id, el.generatedId, editingName.current, el.type)
                                                    setToShowDialogColumn(false, indx);
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'divider',
                                    },
                                    {
                                        value: 'Delete column',
                                        label: 'Delete column',
                                        type: 'option',

                                        onClick: (_) => {
                                            deleteColumn(el.id, el.key);
                                            setToShowDialogColumn(false, indx);
                                        },
                                    },
                                ]
                                }
                            />
                        </div>
                    )}
                    <div
                        className={`${resizingCol == el.key ? 'cb-header--resize-box-visible' : 'cb-header--resize-box'}`}
                        onMouseDown={e => handler(e, columnWidths[el.key], el.key)}
                    ></div>
                </div>
            );
        }),
        <div style={{ position: 'relative', width: '100%', minWidth: '200px' }}>
            <Body2
                className="cb-header--cell-add-col"
                color={colors.primaryText}
                onClick={() => setToShowDialog(true)}
                onMouseLeave={() => setToShowDialog(false)}
            >
                + Add column
            </Body2>
            {showDialog && (
                <div style={{ top: -50, left: 0 }}>
                    <InputTooltip
                        visible={showDialog}
                        onMouseEnter={() => setToShowDialog(true)}
                        onMouseLeave={() => setToShowDialog(false)}
                        position={'bottom'}
                        options={[
                            {
                                text: 'Attribute name',
                                type: 'text',

                                onChange: newValue => {
                                    setNewColName(newValue);
                                },
                            },
                            {
                                text: 'Type',
                                type: 'select',
                                value: newColType,
                                values: contentBlockColumnTypes,
                                onChange: v => {
                                    setNewColType(v);
                                },
                            },
                            {
                                label: 'Create',
                                type: 'button',
                                onClick: () => {
                                    addColumn(newColName, newColType.value);
                                    setShowDialog(false);
                                },
                            },
                        ]}
                    />
                </div>
            )}
        </div>,
    ];

    return (
        <div>
            <div
                className="cb-header--container"
                style={{ display: 'grid', gridTemplateColumns: `repeat(${contentBlock.data.columns.length + 2},min-content) auto` }}
            >
                {renderHeader}
            </div>
        </div>
    );


}
