import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { useTranslation } from 'react-i18next';

export function ChangeTopicBulkModal({ modalOpen, setModalOpen, onAction, onClose, bulkQuestionSize }) {
    const { t } = useTranslation();

    const allTopicsState = useSelector(state => state.content.topics.allTopicsFromCompany);
    const preBulkActionQuestionsState = useSelector(state => state.content.preBulkActionQuestions);

    const [currentActionOption, setCurrentActionOption] = useState(null);
    let allTopics;
    if (stateIsLoaded(allTopicsState)) {
        allTopics = allTopicsState.data.map((el, i) => {
            return { value: el.treeIndex, label: `${el.title} (${el.treeIndex})` };
        });
    }

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onClose={onClose}
            onAction={() => {
                setModalOpen(false);
                onAction(currentActionOption);
            }}
            title={t('Set topic for {{bulkQuestionSize}} questions', { bulkQuestionSize: preBulkActionQuestionsState?.data?.itemsCount })}
            text={t(
                `Select the status you want to set for your chosen questions. Keep in mind that questions cannot be set to ‘Active’ if they don’t have all the necessary fields in the question editor filled. The system will notify you of the number of questions that cannot their status.`
            )}
            buttons={[
                {
                    title: t('Change topic'),
                    color: colors.white,
                    disabled: !stateIsLoaded(preBulkActionQuestionsState),
                },
            ]}
            hasCancel={true}
            alert={
                <CustomSelect
                    id="add-questions-category"
                    value={currentActionOption}
                    onChange={option => {
                        setCurrentActionOption(option);
                    }}
                    width={200}
                    options={allTopics}
                    placeholder={t('Select action')}
                />
            }
        ></ModalDialog>
    );
}

ChangeTopicBulkModal.propTypes = {
    bulkQuestionSize: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
