import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { palette } from 'style/theme/theme-constants';

const BootstrapInput = withStyles(theme => ({
    input: {
        borderRadius: 5,
        position: 'relative',
        height: 'auto',
        backgroundColor: 'white',
        border: `1px solid ${palette.background.main}`,
        margin: 0,
        padding: '4px 15px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),

        '&:focus': {
            borderRadius: 5,
            borderColor: '#80bdff',
        },
    },
}))(InputBase);

export default function CustomSelect({ value, data, onChange, prefix, suffix }) {
    const handleChange = event => {
        onChange(event.target.value);
    };
    return (
        <div>
            <FormControl>
                <NativeSelect id="demo-customized-select-native" value={value} onChange={handleChange} input={<BootstrapInput />}>
                    {data &&
                        data?.map((el, index) => {
                            return (
                                <option key={el} value={el} style={{ marginBottom: 10 }}>
                                    {prefix ? prefix : ''}
                                    {el}
                                    {suffix ? suffix : ''}
                                </option>
                            );
                        })}
                    {!data && <option aria-label="None" value="" />}
                </NativeSelect>
            </FormControl>
        </div>
    );
}

CustomSelect.propTypes = {
    data: PropTypes.any,
    onChange: PropTypes.func,
    prefix: PropTypes.any,
    suffix: PropTypes.any,
    value: PropTypes.any,
};
