import { addNodeUnderParent, getFlatDataFromTree, removeNodeAtPath, find, changeNodeAtPath } from 'react-sortable-tree';
import { create_UUID } from 'services/utils/stringHelpers';

import { convertFromFlatToTree, DFSPreorderCalculateValues, flatten, getNodeKey, getParentKey } from 'services/utils/treeHelpers';
import _ from 'lodash';
import { ApiExecutor } from './ApiExecutor';
import {
    allTrainingsApiRequest,
    createNewTrainingApiRequest,
    deleteTrainingApiRequest,
    saveAssetApiRequest,
    singleTrainingApiRequest,
    trainingsInfoApiRequest,
    updateTopicsTreeApiRequest,
    uploadTrainingApiRequest,
} from 'services/api/apiRequests/content';
import {
    GET_ALL_TOPICS_FROM_COMPANY,
    UPDATE_TOPICS_TREE,
    SINGLE_TRAINING,
    TRAINING_TREE_SAVING_ERRORS,
    ALL_TRAININGS,
    GET_LOCAL_TOPIC_CHANGES,
    CREATE_NEW_TRAINING,
    TRAININGS_INFO,
    HANDLE_OLD_ALL_TOPICS_FROM_COMPANY,
    TOPICS_FROM_TRAINING,
    UPDATE_TRAINING_SETTINGS,
    DELETE_TRAINING,
    UPLOADING_TRAINING_PROGRESS,
    UPLOAD_TRAINING,
    EXPORT_QUESTIONS_PROGRESS,
} from '../constants/content.constants';
import { buildActionType } from './buildActionType';
import { ActionStatus } from '../core/ActionStatus';

import { setTimeoutLastExecute } from 'services/utils/objectHelpers';
import { getAllTopicsFromCompanyFromLearningSetInfo, removeFromLocalTopicChanges, updateLocalTopicChanges } from './topics.actions';

const setTitleToTitleForTrainingInChildren = currentNode => {
    let allNodes = flatten(currentNode.children);
    allNodes.forEach(nod => {
        nod.titleForTraining = nod.titleForTraining ?? nod.title;
    });
    return currentNode.children;
};

export const addChildToTrainingTree = (path, getNodeKey, title, addAsFirstChild, node) => async (dispatch, getState) => {
    const singleTrainingState = _.cloneDeep(getState().content.singleTraining);
    const localTopics = getState().content.topics.localTopicChanges?.data;

    let treeIndex = create_UUID();
    let currentNode;
    let setId = singleTrainingState.data.learningSetInfo.setId;

    if (node) {
        currentNode = node.node ? node.node : node;
    }
    let newNode = {
        title: currentNode ? currentNode.title : title,
        titleForTraining: currentNode ? currentNode.title : title,
        isNew: !currentNode,
        main: false,
        path: [],
        treeIndex: currentNode ? currentNode.treeIndex : treeIndex,
        left: -1,
        right: -1,
        children: currentNode?.children ? setTitleToTitleForTrainingInChildren(currentNode) : [],
        parentNode: { treeIndex: path[0] ?? 0 },
        numberOfQuestions: 0,
        topic: true,
        expanded: true,
        createdBy: '',
        dateCreated: Date.now(),
        timeCreated: Date.now(),
        generatedTreeIndex: currentNode ? null : treeIndex,
    };

    let tree = addNodeUnderParent({
        treeData: singleTrainingState.data.treeStructure,
        parentKey: addAsFirstChild ? null : path[path.length - 1],
        expandParent: true,
        getNodeKey,
        newNode: newNode,
        addAsFirstChild: true,
    }).treeData;

    dispatch(
        updateSingleTrainingAction(
            {
                treeStructure: tree,
            },
            400
        )
    );

    let childrenToUpdateLocal = currentNode?.children
        ? getFlatDataFromTree({
              treeData: currentNode.children,
              getNodeKey,
              ignoreCollapsed: false,
          })?.map(el => {
              let node = el.node ? el.node : el;
              let topicInLocal = localTopics?.[node.treeIndex];
              return {
                  [node.treeIndex]: {
                      treeData: {
                          ...(topicInLocal ? topicInLocal.treeData : node),
                          usedIn: topicInLocal ? [...topicInLocal.treeData.usedIn, { setId: setId }] : [{ setId: setId }],
                          titlesInTrainings: topicInLocal
                              ? { ...topicInLocal.treeData.titlesInTrainings, [setId]: node.title }
                              : { [setId]: node.title },
                          removedInSetIds: topicInLocal?.treeData?.removedInSetIds?.filter(el => el != setId) ?? [],
                      },
                  },
              };
          })
        : [];

    if (childrenToUpdateLocal && childrenToUpdateLocal.length > 0) {
        childrenToUpdateLocal =
            childrenToUpdateLocal.reduce((obj, v) => {
                let key = Object.keys(v)[0];
                obj[key] = v[key];
                return obj;
            }) ?? [];
    }

    dispatch(updateLocalTopicChanges(newNode, {}, []));
    dispatch(
        updateAllTopicsAction(
            currentNode ? currentNode.treeIndex : treeIndex,
            currentNode ? updateTopicActionsType.update : updateTopicActionsType.add,
            {
                ...newNode,
                usedIn: [...(currentNode?.usedIn ? currentNode.usedIn : []), { setId: setId }],
                // learnerTitles: {
                //     ...(currentNode?.learnerTitles ? currentNode.learnerTitles : {}),
                //     [setId]: newNode.title,
                // },
                learnersTitle: newNode.title,
                setId: setId,
                children: childrenToUpdateLocal,
            }
        )
    );
};

export const createNewTrainingAction = title => async (dispatch, getState) => {
    if (title !== undefined && title !== null && title.length > 0) {
        dispatch({ type: buildActionType(CREATE_NEW_TRAINING, ActionStatus.START) });

        let data = new FormData();
        data.set('title', title);

        let response = await createNewTrainingApiRequest(data);

        if (response.success) {
            await dispatch({
                type: buildActionType(CREATE_NEW_TRAINING, ActionStatus.DONE),
                payload: response.data,
            });
        } else {
            dispatch({ type: buildActionType(CREATE_NEW_TRAINING, ActionStatus.FAILED) });
        }
    }
};

export const getSingleTrainingAction = id => async (dispatch, getState) => {
    const state = _.cloneDeep(getState()?.content?.allTrainings?.data?.learningSets);
    const response = state?.filter(
        el => el.learningSetInfo.setId === id || (el.learningSetInfo.setId == null && el.learningSetInfo.generatedSetId === id)
    )[0];

    dispatch({
        type: buildActionType(SINGLE_TRAINING, ActionStatus.DONE),
        payload: {
            ...response,
        },
    });
};

export const resetSingleTrainingAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(SINGLE_TRAINING, ActionStatus.RESET),
    });
    await dispatch({
        type: buildActionType(TOPICS_FROM_TRAINING, ActionStatus.RESET),
    });
};

export const setSingleTrainingAction = data => async (dispatch, getState) => {
    dispatch({
        type: buildActionType(SINGLE_TRAINING, ActionStatus.DONE),
        payload: {
            ...data,
        },
    });
};

const updateSetInAllTrainings = (set, allTrainings, isNew) => {
    let setId = set?.learningSetInfo?.setId ?? set?.learningSetInfo?.generatedSetId;
    let indexOfSet = allTrainings.learningSets.indexOf(
        allTrainings.learningSets.find(el => {
            return el?.learningSetInfo?.setId === setId || (el?.learningSetInfo?.setId == null && el?.learningSetInfo?.generatedSetId === setId);
        })
    );

    if (indexOfSet != -1) {
        allTrainings.learningSets.splice(indexOfSet, 1, set);
    } else {
        allTrainings.learningSets.push(set);
    }
    return allTrainings;
};

const synchronizeAllTrainingsStateWithSingleTrainingState = () => async (dispatch, getState) => {
    let singleTrainingState = getState().content.singleTraining.data;
    let allTrainings = getState().content.allTrainings.data;

    if (!singleTrainingState || singleTrainingState.length == 0) return;

    allTrainings = updateSetInAllTrainings(singleTrainingState, allTrainings, singleTrainingState.learningSetInfo.isNew);
    dispatch(updateAllLearningSets(allTrainings));
};

const updateSingleTrainingState = (data, singleTrainingState) => {
    if (data.treeStructure) {
        singleTrainingState = { ...singleTrainingState, ...data };
    }
    if (data.learningSetInfo) {
        singleTrainingState = { ...singleTrainingState, learningSetInfo: data.learningSetInfo };
    }
    singleTrainingState.learningSetInfo.modified = true;
    return singleTrainingState;
};

export const updateSingleTrainingAction = (data, updateWithDelay) => async (dispatch, getState) => {
    let singleTrainingState = getState().content.singleTraining.data;
    if (!updateWithDelay) {
        singleTrainingState = updateSingleTrainingState(data, singleTrainingState);
        dispatch({
            type: buildActionType(SINGLE_TRAINING, ActionStatus.DONE),
            payload: singleTrainingState,
        });
        dispatch(synchronizeAllTrainingsStateWithSingleTrainingState());
    } else {
        updateSingleTrainingState(data, singleTrainingState);
        dispatch({
            type: buildActionType(SINGLE_TRAINING, ActionStatus.DONE),
            payload: { ...singleTrainingState, ...data },
        });
        setTimeoutLastExecute.addCallback(
            () => {
                dispatch(synchronizeAllTrainingsStateWithSingleTrainingState());
            },
            updateWithDelay,
            'synchronizeAllTrainingsStateWithSingleTrainingState'
        );
    }
};

export const getAllTrainingsAction = () => async dispatch => {
    dispatch({ type: buildActionType(ALL_TRAININGS, ActionStatus.START) });
    let response = await allTrainingsApiRequest();

    if (response.success) {
        let topics = getAllTopicsFromCompanyFromLearningSetInfo(response.data);
        dispatch(updateAllLearningSets(response.data));

        let topicsData = topics
            .map(t => ({
                ...t,
                topic: true,
            }))
            .filter(t => t.visible);

        dispatch({
            type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
            payload: topicsData,
        });
        dispatch({
            type: buildActionType(HANDLE_OLD_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
            payload: topicsData,
        });
    } else {
        await dispatch({
            type: buildActionType(ALL_TRAININGS, ActionStatus.FAILED),
        });
    }
};

export const getTrainingsInfoAction = () => async dispatch => {
    await ApiExecutor(trainingsInfoApiRequest(), TRAININGS_INFO)(dispatch);
};

export const resetTrainingsInfoStateAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(TRAININGS_INFO, ActionStatus.RESET),
    });
};

export const resetUpdateTrainingSettingsAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(UPDATE_TRAINING_SETTINGS, ActionStatus.RESET),
    });
};

export const updateAllLearningSets = (data, ignoreCategoryTree) => async (dispatch, getState) => {
    data.learningSets = data?.learningSets?.map(set => {
        return {
            treeStructure: ignoreCategoryTree ? data.treeStructure : convertFromFlatToTree(set.categoryTree, getNodeKey, getParentKey),
            ...set,
        };
    });

    dispatch({
        type: buildActionType(ALL_TRAININGS, ActionStatus.DONE),
        payload: { ...data },
    });
};

export const saveTrainingChanges = forceSave => async (dispatch, getState) => {
    const data = _.cloneDeep(getState().content.singleTraining.data.treeStructure);
    const setInfo = _.cloneDeep(getState().content.singleTraining.data.learningSetInfo);
    const allRolesData = _.cloneDeep(getState().userManagement.roles);
    const savedTopicIndexes = getState().content.topics.savedTopicIndexes.data;

    await dispatch({
        type: buildActionType(UPDATE_TOPICS_TREE, ActionStatus.START),
    });

    if (!data || data?.length === 0) {
        dispatch(uploadTrainingChanges([], setInfo));
        return;
    }
    //valid tree - one root
    if (data?.length > 1) {
        dispatch(setSaveTrainingValidationAction('Tree structure error!', 'Multiple root topics found. The tree must contain only root topic.'));
        return;
    }

    DFSPreorderCalculateValues(data[0], true);

    let flatDataResult = getFlatDataFromTree({
        treeData: data,
        getNodeKey,
        ignoreCollapsed: false,
    });

    let finalResult = [];
    let complete = true;

    flatDataResult.forEach((value, index) => {
        let node = value.node ? value.node : value;

        if (node.titleForTraining?.length > 0) {
            finalResult.push({ ...node });
        } else {
            dispatch(
                setSaveTrainingValidationAction(
                    'Missing topic name!',
                    'The tree contains topic without a name. Each topic must have unique name associated to it.'
                )
            );
            complete = false;
        }
    });

    finalResult.forEach(el => {
        if (el.isNew) {
            let savedTreeIndex = savedTopicIndexes[el.generatedTreeIndex];

            if (!savedTreeIndex) {
                dispatch(removeFromLocalTopicChanges(el.generatedTreeIndex));
            }

            el.generatedTreeIndex = el.treeIndex;
            el.treeIndex = savedTreeIndex ?? -1;
        }
        if (isNaN(el.parentNode.treeIndex)) {
            el.parentNode.treeIndex = null;
        }
        delete el.usedIn;
    });

    if (complete) {
        dispatch(uploadTrainingChanges(finalResult, setInfo, forceSave));
    }
    //invalid tree
};

export const uploadTrainingChanges = (categoryTree, learningSetInfo, forceSave) => async (dispatch, getState) => {
    let allTrainings = _.cloneDeep(getState().content.allTrainings.data);
    let allTopics = _.cloneDeep(getState().content.topics.allTopicsFromCompany.data);

    let learningSetInfoDTO = {
        ...learningSetInfo,
        isNew: learningSetInfo.isNew,
    };
    if (learningSetInfo.coverFile && learningSetInfo.learnCoverImage) {
        let data = new FormData();
        data.set('file', learningSetInfo.coverFile);
        data.set('folder', 'covers');
        data.set('replaceIfExists', false);
        data.set('type', 'local');
        let imageResult = await saveAssetApiRequest(data);
        learningSetInfoDTO.learnCoverImage = imageResult?.data?.name;
    } else if (!learningSetInfo.learnCoverImage) {
        learningSetInfoDTO.learnCoverImage = null;
    }

    // let response = await ApiExecutor(
    //     updateTopicsTreeApiRequest({
    //         categoryTree,
    //         learningSetInfo: learningSetInfoDTO,
    //         lastModified: forceSave ? Date.now() : learningSetInfoDTO.lastModified,
    //     }),
    //     UPDATE_TOPICS_TREE
    // )(dispatch);

    let response = await updateTopicsTreeApiRequest({
        categoryTree,
        learningSetInfo: learningSetInfoDTO,
        lastModified: forceSave ? Date.now() : learningSetInfoDTO.lastModified,
    });

    if (response.success) {
        let newSetCompleted = {
            ...response.data,
            treeStructure: convertFromFlatToTree(response.data.categoryTree, getNodeKey, getParentKey),
        };
        let newlyCreatedTopics = response.data.categoryTree.filter(el => el.generatedTreeIndex);

        if (learningSetInfo.isNew) {
            let indexOfSet = allTrainings.learningSets.findIndex(el => {
                return el.learningSetInfo.generatedSetId === learningSetInfoDTO.generatedSetId;
            });
            if (indexOfSet !== -1) {
                allTrainings.learningSets.splice(indexOfSet, 1, newSetCompleted);
            }
        } else {
            let indexOfSet = allTrainings.learningSets.findIndex(el => el.learningSetInfo.setId === learningSetInfoDTO.setId);
            if (indexOfSet !== -1) {
                allTrainings.learningSets[indexOfSet].modified = false;
            }
        }
        allTrainings.learningSets.forEach((learningSet, index) => {
            newlyCreatedTopics.forEach(topic => {
                let result = find({
                    getNodeKey: node => (node.node ? node.node.treeIndex : node.treeIndex),
                    treeData: learningSet.treeStructure,
                    searchQuery: topic.generatedTreeIndex,
                    searchMethod: ({ node, searchQuery }) => {
                        return searchQuery && node.treeIndex === searchQuery;
                    },
                });

                if (result.matches.length === 1) {
                    let node = result.matches[0].node;
                    let path = result.matches[0].path;
                    node.treeIndex = topic.treeIndex;
                    node.isNew = false;

                    allTrainings.learningSets[index].treeStructure = changeNodeAtPath({
                        treeData: learningSet.treeStructure,
                        path: path,
                        newNode: { ...node },
                        getNodeKey,
                    });
                }
            });
        });

        newlyCreatedTopics.forEach(topic => {
            let elem = allTopics.find(el => el.treeIndex === topic.generatedTreeIndex);
            if (elem) {
                elem.treeIndex = topic.treeIndex;
            }
        });

        await dispatch(updateAllLearningSets(allTrainings, true));

        await dispatch({
            type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
            payload: allTopics,
        });

        await dispatch(setSingleTrainingAction(newSetCompleted));

        await dispatch({
            type: buildActionType(UPDATE_TOPICS_TREE, ActionStatus.DONE),
            payload: response.data,
        });
    } else {
        await dispatch({
            type: buildActionType(UPDATE_TOPICS_TREE, ActionStatus.FAILED),
        });
    }
};

export const resetAllTrainingsAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(ALL_TRAININGS, ActionStatus.RESET),
    });
};
export const removeSetFromLocalChangesAction = id => async (dispatch, getState) => {
    const localTopics = getState().content.topics.localTopicChanges?.data;

    await dispatch({
        type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
        payload: {
            ...Object.keys(localTopics)
                .filter(el => el != id)
                .map(el => localTopics[el]),
        },
    });
};

export const deleteTrainingAction = (trainingId, data) => async (dispatch, getState) => {
    await dispatch({
        type: buildActionType(DELETE_TRAINING, ActionStatus.START),
    });
    await dispatch(ApiExecutor(deleteTrainingApiRequest(trainingId, data), DELETE_TRAINING));
};

export const resetDeleteTrainingAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(DELETE_TRAINING, ActionStatus.RESET),
    });
};

export const fetchSingleTrainingAction = id => async (dispatch, getState) => {
    const allTrainings = _.cloneDeep(getState()?.content?.allTrainings.data);
    const localTopics = getState().content.topics.localTopicChanges?.data;
    const allTopics = getState()?.content?.topics?.allTopicsFromCompany?.data;

    const response = await singleTrainingApiRequest(id);

    if (response.data) {
        let indexOfSet = allTrainings.learningSets.findIndex(el => {
            return el.learningSetInfo.setId === response.data.learningSetInfo.setId;
        });

        let flatData = getFlatDataFromTree({
            treeData: allTrainings.learningSets[indexOfSet].treeStructure,
            getNodeKey,
            ignoreCollapsed: false,
        });

        let responseSetCategories = response.data.categoryTree.map(el => {
            let matchedElement = flatData.find(elInner => elInner.node.treeIndex == el.treeIndex);
            let isExpanded =
                !matchedElement || matchedElement == -1 ? true : matchedElement?.node ? matchedElement.node.expanded : matchedElement.expanded;

            return { ...el, expanded: isExpanded };
        });

        if (indexOfSet !== -1) {
            allTrainings.learningSets.splice(indexOfSet, 1, {
                ...response.data,
                treeStructure: convertFromFlatToTree(responseSetCategories, getNodeKey, getParentKey),
            });
        }
        let updatedTopics = response.data.categoryTree.filter(el => el.visible).map(el => el.treeIndex);

        dispatch({
            type: buildActionType(ALL_TRAININGS, ActionStatus.DONE),
            payload: allTrainings,
        });

        let allTopicsUpdatedFromFetch = allTopics.map(el => {
            //If a newer instance of the topic was fetched
            if (updatedTopics.includes(el.treeIndex)) {
                let index = updatedTopics.indexOf(el.treeIndex);
                //Remove the updated topic from the list
                if (index > -1) {
                    updatedTopics.splice(index, 1);
                }
                const savedTopic = response.data.categoryTree.find(elInner => elInner.treeIndex == el.treeIndex);

                return {
                    ...el,
                    usedIn: [...el.usedIn, { setId: id }],
                    titlesInTrainings: {
                        ...el.titlesInTrainings,
                        [id]: savedTopic?.titleForTraining ? savedTopic.titleForTraining : savedTopic.title,
                    },
                };
            }
            //If the topic is not found in the set after a new fetch and was
            //previously used in it, it means it's deleted from the set and should be removed from [usedIn]
            else if (el.usedIn.find(el => el.setId == id)) {
                return {
                    ...el,
                    usedIn: el.usedIn.filter(el => el.setId != id),
                };
            }
            return el;
        });

        //The topics that are left over in [updatedTopics] are new topics to the set
        let newTopicsInSetFromFetch = response.data.categoryTree
            .filter(el => updatedTopics.includes(el.treeIndex))
            .map(el => {
                const savedTopic = response.data.categoryTree.find(elInner => elInner.treeIndex == el.treeIndex);

                return {
                    ...el,
                    usedIn: [...el.usedIn, { setId: id }],
                    titlesInTrainings: {
                        ...el.titlesInTrainings,
                        [id]: savedTopic?.titleForTraining ? savedTopic.titleForTraining : savedTopic.title,
                    },
                };
            });

        dispatch({
            type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
            payload: allTopicsUpdatedFromFetch.concat(newTopicsInSetFromFetch),
        });

        let localTopicsClone = _.cloneDeep(localTopics);

        // Remove local changes for this set in the topics
        Object.keys(localTopicsClone).forEach(key => {
            let topicClone = localTopicsClone[key].treeData;
            if (topicClone) {
                topicClone.usedIn = topicClone?.usedIn?.filter(el => el.setId != id);
                topicClone.removedInSetIds = topicClone?.removedInSetIds?.filter(el => el != id);
            }
        });

        await dispatch({
            type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
            payload: { ...localTopicsClone },
        });
    }
};

export const removeTrainingAction = id => async (dispatch, getState) => {
    const allTrainings = _.cloneDeep(getState()?.content?.allTrainings.data);

    let indexOfSet = allTrainings.learningSets.findIndex(el => {
        let setId = el.learningSetInfo.setId || el.learningSetInfo.generatedSetId;
        return setId == id;
    });

    if (indexOfSet !== -1) {
        allTrainings.learningSets.splice(indexOfSet, 1);
    }

    dispatch({
        type: buildActionType(ALL_TRAININGS, ActionStatus.DONE),
        payload: allTrainings,
    });
};

export const setSaveTrainingValidationAction = (title, text) => async dispatch => {
    if (!title && !text) {
        dispatch({
            type: buildActionType(TRAINING_TREE_SAVING_ERRORS, ActionStatus.DONE),
            payload: false,
        });
    } else {
        dispatch({
            type: buildActionType(TRAINING_TREE_SAVING_ERRORS, ActionStatus.DONE),
            payload: {
                title: title,
                text: text,
            },
        });
    }
};

export const updateTopicActionsType = {
    add: 'add',
    update: 'update',
    delete: 'delete',
};

export const updateAllTopicsAction = (treeIndex, action, payload) => async (dispatch, getState) => {
    const allTopics = getState()?.content?.topics?.allTopicsFromCompany?.data;
    let localTopics = getState().content.topics.localTopicChanges?.data;

    if (action === updateTopicActionsType.update) {
        setTimeoutLastExecute.addCallback(
            () => {
                let topicOriginal;
                let topicUpdated;

                topicOriginal = payload.isNew ? localTopics[treeIndex].treeData : allTopics?.find(t => t.treeIndex === treeIndex);
                topicUpdated = _.cloneDeep(topicOriginal);
                if (!topicUpdated) {
                    return;
                }

                let setId = payload?.setId;

                if (payload.usedIn) {
                    let usedInTopic = localTopics?.[treeIndex]?.treeData.usedIn ? localTopics?.[treeIndex]?.treeData.usedIn : [];
                    topicUpdated.usedIn = [...usedInTopic, ...payload.usedIn];
                } else {
                    delete topicUpdated.usedIn;
                }
                if (payload.learnersTitle) {
                    topicUpdated.titleForTraining = payload.learnersTitle;
                    let titlesInTrainings = localTopics?.[treeIndex]?.treeData?.titlesInTrainings
                        ? localTopics?.[treeIndex]?.treeData.titlesInTrainings
                        : topicUpdated.titlesInTrainings;
                    if (titlesInTrainings) {
                        topicUpdated.titlesInTrainings = { ...titlesInTrainings, [setId]: payload.learnersTitle };
                    } else {
                        topicUpdated.titlesInTrainings = { [setId]: payload.learnersTitle };
                    }
                    if (localTopics[treeIndex]?.treeData?.removedInSetIds?.includes(setId)) {
                        topicUpdated.removedInSetIds = localTopics[treeIndex].treeData.removedInSetIds.filter(el => el !== setId);
                    }
                } else {
                    delete topicUpdated.learnersTitle;
                }
                if (payload.title && (!topicUpdated.usedIn || topicUpdated.usedIn?.length < 2)) topicUpdated.title = payload.title;

                dispatch(updateLocalTopicChanges(topicOriginal, topicUpdated, payload.children));
            },
            200,
            'updateAllTopicsAction'
        );
    }

    if (action === updateTopicActionsType.add) {
        dispatch(updateLocalTopicChanges(payload, payload, []));
    }
    if (action === updateTopicActionsType.delete) {
        let topicsToBeDeleted = allTopics?.filter(el => {
            return payload?.topics.map(el2 => el2.treeIndex).includes(el.treeIndex);
        });
        let localTopicsClone = _.cloneDeep(localTopics);

        let removedInSetIds;
        topicsToBeDeleted.forEach(el => {
            let topicInLocal = localTopicsClone[el.treeIndex];

            if (localTopicsClone && topicInLocal) {
                topicInLocal.treeData.usedIn = topicInLocal.treeData.usedIn?.filter(el => el.setId != payload.setId) ?? [];

                removedInSetIds = topicInLocal.treeData.removedInSetIds;
                removedInSetIds = removedInSetIds ? [...removedInSetIds, payload.setId] : [payload.setId];
            } else {
                removedInSetIds = [payload.setId];
            }

            localTopicsClone[el.treeIndex] = {
                treeData: {
                    ...(topicInLocal ? topicInLocal.treeData : el),
                    removedInSetIds: removedInSetIds,
                },
            };
        });

        await dispatch({
            type: buildActionType(GET_ALL_TOPICS_FROM_COMPANY, ActionStatus.DONE),
            payload: [...allTopics],
        });

        await dispatch({
            type: buildActionType(GET_LOCAL_TOPIC_CHANGES, ActionStatus.DONE),
            payload: { ...localTopicsClone },
        });
    }
};

export const initiateNodeRemovalAction = (setId, treeIndex, deleteChildren) => async (dispatch, getState) => {
    let data = _.cloneDeep(getState().content.allTrainings.data);
    let set = data?.learningSets.find(el => {
        return el.learningSetInfo.setId === setId;
    });
    let indexOfSet = data.learningSets.indexOf(set);
    let treeStructure = set.treeStructure;

    let result = find({
        getNodeKey: node => (node.node ? node.node.treeIndex : node.treeIndex),
        treeData: treeStructure,
        searchQuery: treeIndex,
        searchMethod: ({ node, searchQuery }) => {
            return searchQuery && node.treeIndex === searchQuery;
        },
    });

    let res;
    let node;

    if (result.matches.length === 1) {
        node = result.matches[0].node;
        let path = result.matches[0].path;
        let data = undefined;

        if (!deleteChildren && node.children && node.children?.length > 0) {
            if (node.parentNode?.treeIndex) {
                let resParent = find({
                    getNodeKey: node => (node.node ? node.node.treeIndex : node.treeIndex),
                    treeData: treeStructure,
                    searchQuery: node.parentNode.treeIndex,
                    searchMethod: ({ node, searchQuery }) => {
                        return searchQuery && node.treeIndex === searchQuery;
                    },
                });
                if (resParent.matches.length === 1) {
                    let parentNode = resParent.matches[0].node;
                    let parentPath = resParent.matches[0].path;
                    parentNode.children = parentNode.children ? [...parentNode.children, ...node.children] : [...node.children];

                    data = changeNodeAtPath({
                        treeData: treeStructure,
                        path: parentPath,
                        newNode: { ...parentNode },
                        getNodeKey: node => (node.node ? node.node.treeIndex : node.treeIndex),
                    });
                }
            } else {
                data = treeStructure ? [...treeStructure, ...node.children] : [...node.children];
            }
        }

        res = removeNodeAtPath({
            treeData: data !== undefined ? data : treeStructure,
            path: path,
            getNodeKey: node => (node.node ? node.node.treeIndex : node.treeIndex),
        });
    }
    let newSet = {
        ...set,
        treeStructure: res,
    };
    newSet.learningSetInfo.modified = true;
    data.learningSets.splice(indexOfSet, 1, newSet);

    let newNotUsedTopicsInSet = deleteChildren
        ? getFlatDataFromTree({
              treeData: node.children,
              getNodeKey,
              ignoreCollapsed: false,
          }).map(({ node, parentNode, ...rest }) => {
              return {
                  ...node,
                  parentNode: parentNode ? { treeIndex: parentNode.treeIndex } : { treeIndex: 0 },
                  children: null,
              };
          })
        : [node];

    if (deleteChildren) {
        newNotUsedTopicsInSet.splice(0, 0, node);
    }

    dispatch(
        updateAllTopicsAction(null, updateTopicActionsType.delete, {
            topics: newNotUsedTopicsInSet,
            setId: setId,
        })
    );

    dispatch({
        type: buildActionType(SINGLE_TRAINING, ActionStatus.DONE),
        payload: newSet,
    });

    dispatch(updateAllLearningSets(data));
};

export const updateTrainingProgressAction = requestProgress => async dispatch => {
    dispatch({
        type: buildActionType(UPLOADING_TRAINING_PROGRESS, ActionStatus.DONE),
        payload: requestProgress,
    });
};

export const updateQuestionsExportProgressAction = requestProgress => async dispatch => {
    dispatch({
        type: buildActionType(EXPORT_QUESTIONS_PROGRESS, ActionStatus.DONE),
        payload: requestProgress,
    });
};

export const uploadTrainingAction = file => async dispatch => {
    await dispatch(ApiExecutor(uploadTrainingApiRequest(file), UPLOAD_TRAINING));
};

export const resetUploadTrainingAction = () => async dispatch => {
    await dispatch({
        type: buildActionType(UPLOAD_TRAINING, ActionStatus.RESET),
    });
    await dispatch({
        type: buildActionType(UPLOADING_TRAINING_PROGRESS, ActionStatus.RESET),
    });
};
