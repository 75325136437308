import React, { useEffect, useState } from 'react';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { Caption1 } from 'style/typography/Caption';
import { H4 } from 'style/typography/Heading';

import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { getAllTrainingsAction } from 'redux/actions/trainings.actions';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelect } from 'components/elements/selector/CustomSelector';

import './GenerationTabs.css';
import { languages } from 'static/language';
import { Trans, useTranslation } from 'react-i18next';

export default function TrainingTitle({
    trainingTitle,
    setTrainingTitle,
    selectedTraining,
    setSelectedTraining,
    selectedLanguage,
    setSelectedLanguage,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const allTrainingsState = useSelector(state => state.content.allTrainings);

    const [trainings, setTrainings] = useState([]);

    useEffect(() => {
        if (!stateIsLoaded(allTrainingsState) && !stateIsLoading(allTrainingsState) && !stateHasFailed(allTrainingsState)) {
            dispatch(getAllTrainingsAction());
        } else if (stateIsLoaded(allTrainingsState)) {
            setTrainings(
                allTrainingsState.data.learningSets.map((el, i) => {
                    return { value: el.learningSetInfo.setId, label: el.learningSetInfo.name };
                })
            );
        }
    }, [allTrainingsState]);

    return (
        <div className="generation-tabs--container">
            <div className="generation-tabs--items">
                <H4 color={colors.dark} weight="light" style={{ padding: '20px 0' }}>
                    {t('Title of your new training')}
                </H4>
                <Body2 color={colors.dark} weight="light">
                    <Trans>
                        {t(
                            'According to the content and structure of your uploaded document, this would be the title of your training. <br /> If you wish to change it, just click on the field below, and press ‘Continue’.'
                        )}
                    </Trans>
                </Body2>
                <div className="training-title--text">
                    <Caption1 weight="medium" color={colors.gray6} style={{ marginBottom: 10 }}>
                        {t('TRAINING TITLE')}
                    </Caption1>
                    <CustomTextField
                        type="text"
                        id="training-title-text"
                        value={trainingTitle}
                        width={'100%'}
                        placeholder={'Your training title..'}
                        height={45}
                        font={17}
                        setValue={setTrainingTitle}
                    />
                </div>
                <div className="training-title--training">
                    <Caption1 weight="medium" color={colors.gray6} style={{ marginBottom: 10 }}>
                        {t('APPEND TO TRAINING')}
                    </Caption1>
                    <CustomSelect width={'40vw'} options={trainings} value={selectedTraining} onChange={el => setSelectedTraining(el)} />
                    <Caption1 weight="medium" color={colors.gray6} style={{ margin: '15px 0' }}>
                        {t('SELECT LANGUAGE')}
                    </Caption1>
                    <CustomSelect width={'40vw'} options={languages} value={selectedLanguage} onChange={el => setSelectedLanguage(el)} />
                </div>
            </div>
        </div>
    );
}
