import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { colors } from 'style/colors';

export function ProgressWithLabel({ progress }) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="60%" mr={1}>
                <LinearProgress style={{background:colors.gray2}} variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
            </Box>
        </Box>
    );
}

ProgressWithLabel.propTypes = {
    progress: PropTypes.any,
};
