import React from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { colors } from 'style/colors';
import { makeStyles } from '@material-ui/core';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { useTranslation } from 'react-i18next';

export function NewRoleModal({ modalOpen, setModalOpen, role, setRole, createRole }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Modal
            open={modalOpen}
            className={classes.modal}
            onClose={() => {
                setModalOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper style={{ width: '40vw' }} className={`${classes.paper} row`}>
                {/*<div className={'row'}>*/}
                <div className="col-12 d-flex flex-row p-3">
                    <div className="d-flex flex-column">
                        <h5 className={'text-dark font-weight-bold'}>{t('Add new role')}</h5>
                        <p className={'mt-1'}>{t('Choose a name for your new role.')}</p>
                    </div>
                    <FontAwesomeIcon onClick={() => setModalOpen(false)} className={'ml-auto'} icon={faTimes}></FontAwesomeIcon>
                </div>
                <div className={'col-12 d-flex flex-column'}>
                    <small className={'text-muted mr-2'}>{t('ROLE NAME')}</small>
                    <CustomOutlinedTextField width={'100%'} value={role} setValue={setRole} placeholder={t('Role name...')}></CustomOutlinedTextField>
                </div>
                <div className={'col-12 pr-3 pl-3 d-flex flex-row justify-content-center mt-5 mb-3'}>
                    <DefaultButton
                        text={t('Add role')}
                        onClick={() => {
                            createRole();
                            setModalOpen(false);
                        }}
                        hoverColor={colors.dark}
                    />

                    <OutlinedButton onClick={() => setModalOpen(false)} text={t('Cancel')} style={{ marginLeft: '10px' }} />
                </div>
            </Paper>
        </Modal>
    );
}
const useStyles = makeStyles(theme => {
    return {
        paper: {
            // position: 'absolute',
            // minWidth: '20%',
            // width: '20%',
            backgroundColor: colors.gray4,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            // padding: theme.spacing(2, 4, 3),
            // height: '40%',
            // minHeight: '40%',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});
