import React from 'react';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';

import './RadioInput.css';

export default function RadioInput(props) {
    return (
        <div className='radio-input'>
            <input type="radio" {...props} />
            {props.label && <Body2 color={colors.dark}>{props.label}</Body2>}
        </div>
    );
}
