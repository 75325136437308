import React from 'react';

import { H2, H5 } from 'style/typography/Heading';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { useSelector } from 'react-redux';
import ActiveUsersPerMonth from '../ActiveUsersPerMonth';
import { Caption1 } from 'style/typography/Caption';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

import DashboardUsers from 'assets/icons/dashboard-users.svg';

import './DashStatistics.css';

export default function UsersStatisticsDashboard() {
    const { t } = useTranslation();
    const history = useHistory();

    const dashboardStatsState = useSelector(state => state.content.dashboardStats);

    return (
        <div className="dash-s--users-container">
            <div className="dash-s--users-total-users">
                <img alt="users" src={DashboardUsers} height={60} width={60} />
                <div>
                    <H2 weight="bold" color={colors.mainTheme}>
                        {dashboardStatsState.data?.totalUsers?.toString() ?? '0'}
                    </H2>
                    <Body2 color={colors.primaryText}>{t('total users')}</Body2>
                </div>
            </div>
            <div className="dash-s--users-right">
                <div className="dash-s--users-stats-main">
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push('/users/organization?status=active');
                        }}
                    >
                        <H5 color={colors.primaryText}>{dashboardStatsState.data?.activeUsers?.toString() ?? '0'}</H5>
                        <Caption1 color={colors.gray6}>{t('active')}</Caption1>
                    </div>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push('/users/organization?status=pending');
                        }}
                    >
                        <H5 color={colors.primaryText}>{dashboardStatsState.data?.invitedUsers?.toString() ?? '0'}</H5>
                        <Caption1 color={colors.gray6}>{t('invited')}</Caption1>
                    </div>
                    <div>
                        <H5 color={colors.primaryText}>{dashboardStatsState.data?.activeUsersLastWeek?.toString() ?? '0'}</H5>
                        <Caption1 color={colors.gray6}>{t('active last week')}</Caption1>
                    </div>
                    <div>
                        <H5 color={colors.primaryText}>{dashboardStatsState.data?.adminUsers?.toString() ?? '0'}</H5>
                        <Caption1 color={colors.gray6}>{t('admin users')}</Caption1>
                    </div>
                </div>
                <div className="dash-s--active-users-per-month">
                    <ActiveUsersPerMonth />
                </div>
            </div>
        </div>
    );
}
