import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SortableTree from 'components/features/trees/SortableTree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { colors } from 'style/colors';
import { useImageEditor } from 'services/image_management/image-editor-management';
import { Body2 } from 'style/typography/Body';
import { deleteFolderAction, getImagesFromFolderAction, resetGetImagesFromFolderAction } from 'redux/actions/image.actions';
import { useDispatch, useSelector } from 'react-redux';
import { aspectRatios, tabs } from 'services/image_management/image-editor-management/local-constants';
import { DeleteFolderDialog } from '../../content_management/cropping_module/DeleteFolderDialog';
import { getNodeKey } from 'services/utils/treeHelpers';

import Trash from 'assets/icons/icon-delete.svg';
import Folder from 'assets/icons/icon-gallery-folder.svg';
import FolderOpen from 'assets/icons/icon-gallery-openfolder.svg';

import _ from 'lodash';

import '../Gallery.css';
import './GalleryFolders.css';
import { getNameFromLink, shortenString } from 'services/utils/stringHelpers';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { setTimeoutLastExecute } from 'services/utils/objectHelpers';

export default function GalleryFoldersTree({ toSave }) {
    const dispatch = useDispatch();

    const [folderToDelete, setFolderToDelete] = useState(false);

    const currentImagesFromFolder = useSelector(state => state.content.imagesFromFolder);

    const {
        treeData,
        setTreeData,
        currentNode,
        setCurrentNode,
        setCurrentPath,
        setCurrentTab,
        currentTab,
        setSelectedImages,
        setAllImagesSelected,
        setFilteredImages,
        setGalleryImages,
        sortBy,
        currentPage,
        size,
        imageSearchValue,
        setPaginationTotalPages,
        aspectRatio,
    } = useImageEditor();

    const deleteFolder = folderId => {
        dispatch(deleteFolderAction(folderId));
    };

    useEffect(() => {
        return () => setTimeoutLastExecute.removeCallback('getImagesFromFolderAction');
    }, []);

    useEffect(() => {
        if (
            stateIsLoaded(currentImagesFromFolder) &&
            currentNode?.treeIndex !== undefined &&
            currentImagesFromFolder.data?.images[0]?.folderId == currentNode?.treeIndex
        ) {
            setGalleryImages(
                currentImagesFromFolder?.data?.images?.map(el => {

                    let src = el.urlRatio1x1;
                    if (aspectRatio === aspectRatios[1]) {
                        src = el.urlRatio2x1;
                    } else if (aspectRatio === aspectRatios[2]) {
                        src = el.urlRatio4x3;
                    }
                    return {
                        src: src,
                        fullName: getNameFromLink(el.urlOriginal).split('?')[0],
                        caption: shortenString(getNameFromLink(el.urlOriginal).split('?')[0], 20),
                        thumbnailCaption: <Body2 color={colors.gray6}>{shortenString(getNameFromLink(el.urlOriginal), 20)}</Body2>,
                        thumbnail: src,
                        thumbnailWidth: 150,
                        thumbnailHeight: 150,
                        isSelected: false,
                        fullObj: el,
                        dateCreated: el.dateCreated,
                        usedInCount: el.usedInCount,
                    };
                })
            );
            if (currentImagesFromFolder?.data?.totalPages) setPaginationTotalPages(currentImagesFromFolder?.data?.totalPages);
        }
    }, [currentImagesFromFolder, currentNode, aspectRatio]);

    return (
        <div className="gallery-folders--tree">
            <DeleteFolderDialog
                modalOpen={folderToDelete}
                setModalOpen={() => setFolderToDelete(null)}
                onAction={deleteFolder}
                folderId={folderToDelete}
            ></DeleteFolderDialog>

            <SortableTree
                treeData={treeData[0]?.children || []}
                onChange={(treeDataNew, __) => {
                    let treeCurrent = _.cloneDeep([...treeData]);
                    treeCurrent[0].children = treeDataNew;
                    toSave.current = true;
                    setTreeData(treeCurrent);
                }}
                theme={FileExplorerTheme}
                rowHeight={50}
                className={'rstcustom__rowTitle rstcustom__rowWrapper mt-3'}
                generateNodeProps={({ node, path, treeIndex }) => {
                    const nodeProps = {
                        onClick: event => {
                            setCurrentTab(tabs.folder);
                            setFilteredImages([]);
                            setGalleryImages([]);
                            dispatch(resetGetImagesFromFolderAction());
                            setTimeoutLastExecute.addCallback(
                                () => {
                                    dispatch(
                                        getImagesFromFolderAction({
                                            folderId: node.treeIndex,
                                            page: currentPage,
                                            size: size,
                                            searchQuery: imageSearchValue,
                                            sortBy: sortBy,
                                        })
                                    );
                                },
                                500,
                                'getImagesFromFolderAction'
                            );
                            setCurrentNode(node);
                            setCurrentPath(getNodeKey(node));
                            setSelectedImages([]);
                            setAllImagesSelected(false);
                        },
                        onDoubleClick: event => {
                            node.expanded = !node.expanded;
                            setTreeData([...treeData]);
                        },
                        style: {
                            background: currentTab === tabs.folder && currentNode === node ? colors.mainThemeFaded : colors.white_transparent,
                            borderRadius: '5px',
                            width: '100%',
                        },
                        path: path,
                        canDrag: true,
                        icons: [
                            null,
                            <img
                                alt={'node'}
                                src={node.expanded ? FolderOpen : Folder}
                                style={{ width: 35, height: 35, margin: '3px 0', paddingLeft: '10px' }}
                            />,
                        ],

                        buttons: [
                            <img
                                alt="Delete"
                                height={15}
                                width={15}
                                className="ml-auto mr-2 cursor-pointer"
                                onClick={event => {
                                    setFolderToDelete(node.treeIndex);
                                }}
                                style={{ marginLeft: 'auto' }}
                                src={Trash}
                            />,
                        ],
                        title: (
                            <Body2 weight={currentTab === tabs.folder && currentNode === node ? 'medium' : 'regular'}>
                                {`${node.title} ${node.children.length > 0 ? ' (' + node.children.length + ')' : ''}`}
                            </Body2>
                        ),
                    };
                    return nodeProps;
                }}
            />
        </div>
    );
}

GalleryFoldersTree.propTypes = {
    toSave: PropTypes.any,
};
