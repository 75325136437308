import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'style/colors';
import { Body1, Body2 } from 'style/typography/Body';

export default function FeedbackViewInfoComponent({ itemTitle, value }) {
    const { t } = useTranslation();
    
    return (
        <div className={'mt-4'}>
            <Body2 color={colors.primaryText}>{t(itemTitle)}</Body2>
            <Body1 color={colors.dark}>{value}</Body1>
        </div>
    );
}
