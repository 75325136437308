import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { CSSTransitionGroup } from 'react-transition-group';
import ErrorTooltip from 'components/elements/tooltips/ErrorTooltip';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';
import { countriesWithDialingCode } from 'static/countries_dialing_code';
import { useTranslation } from 'react-i18next';

import './InvitationModal.css';
import { stateIsLoaded } from 'redux/core/stateHelpers';

const InvitationFormNumber = ({
    formObj,
    usersInvited,
    onChangeNumber,
    onChangeRole,
    company,
    onRemoveRow,
    showRemove,
    hasRoles,
    currentOpenIndex,
    setCurrentOpenIndex,
}) => {
    const { t } = useTranslation();

    const roles = useSelector(state => state?.userManagement?.roles?.data?.roles);
    const nextError = useSelector(state => state.userManagement.nextUserUploadError);

    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState('');
    const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
    const [showRoleError, setShowRoleError] = useState(false);

    const [currentCountry, setCurrentCountry] = useState(countriesWithDialingCode[239]);

    const [search, setSearch] = useState('');

    const roleRef = useRef();

    const handleChangeNumber = event => {
        onChangeNumber(formObj, event.target.value, currentCountry.dial_code);
    };

    const handleChangeRole = event => {
        onChangeRole(formObj, event.target.value);
        setShowRoleError(false);
    };

    var filteredData = useMemo(() => {
        if (search) {
            return countriesWithDialingCode.filter(el => el.name.toLowerCase().includes(search.toLowerCase()) || el.dial_code.includes(search));
        } else return countriesWithDialingCode;
    }, [search]);

    function handleClickOutside(event) {
        if (event.target && !(event.target?.id === 'country-dropdown') && currentOpenIndex !== null) {
            setCurrentOpenIndex(null);
        }
    }

    useEffect(() => {
        if (stateIsLoaded(nextError)) {
            if (formObj.formId === nextError.data.id) {
                setErrorMessage(nextError.data.info.message);
                if (nextError.data.info.type === 'role' && roleRef.current) {
                    roleRef.current.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    });
                    setShowRoleError(true);
                }
            }
        }
        //eslint-disable-next-line
    }, [nextError]);

    useEffect(() => {
        if (showRoleError) {
            setShowRoleError(false);
        }
        //eslint-disable-next-line
    }, [usersInvited]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [currentOpenIndex]);

    return (
        <div className="col-12" style={{ zIndex: 99 - formObj.formId }}>
            <div className="row d-flex flex-row align-items-center">
                <div className={`upload-users-email-wrapper mx-2`} data-tip={errorMessage} style={{ position: 'relative', flex: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                        <input
                            className="invite-number--country-picker-input"
                            onClick={() => {
                                if (currentOpenIndex === formObj.formId) {
                                    setCurrentOpenIndex(null);
                                } else {
                                    setSearch('');
                                    setCurrentOpenIndex(formObj.formId);
                                }
                            }}
                            value={currentOpenIndex === formObj.formId ? search : ` ${currentCountry?.flag}  (${currentCountry?.dial_code})`}
                            onChange={e => setSearch(e.target.value)}
                        />

                        <div className="invite-number--country-picker" style={{ display: currentOpenIndex === formObj.formId ? 'flex' : 'none' }}>
                            {filteredData.map(el => {
                                return (
                                    <div
                                        className="invite-number--country-picker-item"
                                        onClick={() => {
                                            setCurrentCountry(el);
                                            setCurrentOpenIndex(null);
                                        }}
                                        id="country-dropdown"
                                    >
                                        {el.flag} {el.name} ({el.dial_code})
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id={'import-role-number-' + formObj.formId}
                            error={showPhoneNumberError}
                            label={t('Phone number')}
                            className="invite-number--number-input"
                            type="number"
                            value={formObj.phoneNumber || ''}
                            onChange={handleChangeNumber}
                            variant="filled"
                            size="small"
                        />
                    </FormControl>
                    <CSSTransitionGroup transitionName="tooltip" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                        {showPhoneNumberError && <ErrorTooltip message={errorMessage} />}
                    </CSSTransitionGroup>
                </div>
                {hasRoles && (
                    <div className="mx-2" style={{ flex: 4, position: 'relative' }} ref={roleRef}>
                        <FormControl className={classes.formControl} variant="filled" margin="dense" id={'invite-label-model-' + formObj.formId}>
                            <InputLabel>{t('Role')}</InputLabel>
                            <Select
                                label={t('Role')}
                                labelId="invite-role-label"
                                value={formObj.role || ''}
                                onChange={handleChangeRole}
                                error={showRoleError}
                            >
                                {roles?.map(r => {
                                    return (
                                        <MenuItem id={'invite-users-' + r.roleName} key={r.roleName} value={r.roleName || ''}>
                                            {r.roleName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <CSSTransitionGroup transitionName="tooltip" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                            {showRoleError && <ErrorTooltip message={errorMessage} />}
                        </CSSTransitionGroup>
                    </div>
                )}
                {showRemove && (
                    <div className="mx-2 text-center" id="remove-user-role">
                        <Caption1 color={colors.gray6} className="cursor-pointer" onClick={() => onRemoveRow(formObj.formId)}>
                            {t('Remove')}
                        </Caption1>
                    </div>
                )}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    input: {
        backgroundColor: 'white',
    },
}));

export default React.memo(InvitationFormNumber);
