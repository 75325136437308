import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Layout from './layout';
import Login from './not_logged_in_pages/Login';
import { AdministratorRegistration } from './not_logged_in_pages/administrator_registration/AdministratorRegistration';
import { AdministratorRegistrationSuccess } from './not_logged_in_pages/administrator_registration/AdministratorRegistrationSuccess';
import { ForgotPasswordRequest } from 'containers/not_logged_in_pages/forgot_password/forgot_password_request/ForgotPasswordRequest';
import { ForgotPasswordRequestSuccess } from 'containers/not_logged_in_pages/forgot_password/forgot_password_request/ForgotPasswordRequestSuccess';
import { ForgotPasswordSetNew } from 'containers/not_logged_in_pages/forgot_password/forgot_password_set_new/ForgotPasswordSetNew';
import { ForgotPasswordSetNewSuccess } from 'containers/not_logged_in_pages/forgot_password/forgot_password_set_new/ForgotPasswordSetNewSuccess';
import useGoogleAnalytics from 'services/analytics/useGoogleAnalytics';
import { CreateCompanyWithCode } from './not_logged_in_pages/company_code_activation/CreateCompanyWithCode';
import { CreateCompanyWithCodeSuccess } from './not_logged_in_pages/company_code_activation/CreateCompanyWithCodeSuccess';

function Routes() {
    useGoogleAnalytics();

    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route path={'/users/invite/administrator/success'} component={AdministratorRegistrationSuccess} />
            <Route path={'/users/invite/administrator/:code'} component={AdministratorRegistration} />
            <Route path={'/users/forgot/request/success'} component={ForgotPasswordRequestSuccess} />
            <Route path={'/users/forgot/request'} component={ForgotPasswordRequest} />
            <Route path={'/users/forgot/set_new/success'} component={ForgotPasswordSetNewSuccess} />
            <Route path={'/users/forgot/set_new/:code'} component={ForgotPasswordSetNew} />
            <Route path={'/companies/create_with_code/success'} exact={true} component={CreateCompanyWithCodeSuccess} />
            <Route path={'/companies/create_with_code/:code?'} component={CreateCompanyWithCode} />
            <Route path="/" component={Layout} />
        </Switch>
    );
}
const Interceptor = () => {
    return (
        <Router>
            <Routes />
        </Router>
    );
};

export default Interceptor;
