import AddQuestionTraffic from './games_questions/Traffic/Traffic';
import AddQuestionSortIt from './games_questions/SortIt/SortIt';
import AddQuestionCompare from './games_questions/Compare/Compare';
import AddQuestionNumbers from './games_questions/Numbers/Numbers';
import AddQuestionRecall from './games_questions/Recall/Recall';
import AddQuestionRaceCars from './games_questions/RaceCars/RaceCars';
import { Header } from 'containers/layout/module/local_components/header/Header';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentInsertionConsumer, ContentInsertionProvider } from 'services/content-management/insertion-control';
import AddQuestionSequence from './games_questions/Sequence/Sequence';
import AddQuestionPinpoint from './games_questions/Pinpoint/Pinpoint';
import { ContentBlocksBuilderConsumer, ContentBlocksBuilderProvider } from 'services/content-blocks-management/content-blocks-builder';
import { gameQuestionTypeMapper } from 'static/game_constants';
import { useTranslation } from 'react-i18next';

export function AddQuestion() {
    const { game } = useParams();
    const { t } = useTranslation();

    return (
        <Header title={t(`Add new question`)}>
            <div>
                <ContentInsertionProvider currentType={gameQuestionTypeMapper[game]}>
                    <ContentBlocksBuilderProvider>
                        <div>
                            {game === 'traffic' && <AddQuestionTraffic game={game} />}
                            {game === 'sort-it' && <AddQuestionSortIt game={game} />}
                            {game === 'compare' && <AddQuestionCompare game={game} />}
                            {game === 'numbers' && <AddQuestionNumbers game={game} />}
                            {game === 'recall' && <AddQuestionRecall game={game} />}
                            {game === 'race-cars' && <AddQuestionRaceCars adding={true} game={game} />}
                            {game === 'sequence' && <AddQuestionSequence game={game} />}
                            {game === 'pinpoint' && <AddQuestionPinpoint adding={true} game={game} />}

                            <ContentInsertionConsumer />
                            <ContentBlocksBuilderConsumer />
                        </div>
                    </ContentBlocksBuilderProvider>
                </ContentInsertionProvider>
            </div>
        </Header>
    );
}
