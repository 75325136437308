const { createTheme } = require('react-data-table-component');
const { colors } = require('style/colors');

export const customStylesTable = {
    table: {
        style: {
            padding: '0',
            margin: '0',
            borderRadius: '10px',
        },
    },
    header: {
        style: {
            color: colors.gray2,
            backgroundColor: colors.gray3,
        },
    },
    subHeader: {
        style: {
            backgroundColor: colors.gray3,
            padding: '15px 0',
        },
    },
    rows: {
        style: {
            color: colors.descriptionText,
            fontSize: 14,
            fontWeight: 0,
            cursor: 'pointer',
        },
    },
    contextMenu: {
        style: {
            borderRadius: '10px',
        },
    },
    headRow: {
        style: {
            backgroundColor: colors.gray4,
            borderBottomWidth: 0,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            fontSize: '20px',
            minHeight: '45px',
        },
        denseStyle: {
            minHeight: '32px',
        },
    },
    progress: {
        style: {
            height: '60vh',
            borderRadius: '10px',
            overflow: 'hidden',
        },
    },
};

createTheme(
    'tableTheme',
    {
        text: {
            primary: colors.disabled,
            secondary: colors.gray6,
        },
        background: {
            default: colors.white,
        },
        highlightOnHover: {
            default: colors.background,
            text: colors.dark,
        },
        context: {
            background: colors.gray5,
            text: colors.gray6,
        },
        divider: {
            default: colors.gray3,
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    },
    'dark'
);
