import PropTypes from 'prop-types';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@material-ui/core';

import { getQuestionById, insertQuestion, resetInsertQuestionAction } from 'redux/actions/content.actions';
import InsideComponent from 'components/wrappers/InsideComponent';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useAnswerTextOptions,
    useContentInsertionQuestion,
    useDateAvailableFrom,
    useInsertionState,
    useInsertionStateReset,
    useSetAndCategory,
    useStatus,
} from 'services/content-management/insertion-control';
import { questionTypeAnswerFilters, questionTypeAnswerMappers } from 'services/content-management/insertion-control/utils';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { useValidator, useValidatorCategoryActions } from 'services/validation-module';
import { validatorCategories } from 'services/validation-module/local-constants';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import 'react-datepicker/dist/react-datepicker.css';
import { colors } from 'style/colors';
import { toast } from 'react-hot-toast';
import { Circular } from 'components/features/data_table/Circular';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import InputQuestion from './InputQuestion';
import { Caption1 } from 'style/typography/Caption';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { CustomDatePicker } from 'components/elements/datepicker/CustomDatePicker';
import IconTopicPickerNotSelected from 'assets/icons/icon-topicpicker-notselected.svg';
import IconTopicPickerSelected from 'assets/icons/icon-topicpicker-selected.svg';
import { StatusCode } from '../../../../redux/core/StatusCode';
import ConflictModal from '../../modal/ConflictModal';

import TopicDropdownSelector from 'components/features/content_management/inputs/topic_dropdown_selector/TopicDropdownSelector';
import ContentBlocksBuilder from 'containers/layout/module/pages/content/content_blocks/content_blocks_builder/ContentBlocksBuilder';
import { useContentBlocksBuilder } from 'services/content-blocks-management/content-blocks-builder';
import {
    generateAnswers,
    generateExtras,
    generateQuestionsContentBlock,
} from 'containers/layout/module/pages/content/content_blocks/content_blocks_table/contentBlocksGenerator';
import GeneratedQuestionsListModal from 'containers/layout/module/pages/content/content_blocks/content_blocks_builder/GeneratedQuestionsListModal';
import { questionStatusOptions } from 'static/status_options';
import { getSingleTrainingAction } from 'redux/actions/trainings.actions';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import GoToLinkLight from 'assets/icons/icon-gotolink-light.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { CustomToast, IconType } from 'components/features/error_handling/toast/CustomToast';

const InputContent = ({ children, game, id, initializeQuestion }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const validator = useValidator();
    const { question } = useContentInsertionQuestion();
    const validationActions = useValidatorCategoryActions();
    const insertionState = useInsertionState();
    const insertionStateReset = useInsertionStateReset();

    const questionInsertion = useSelector(state => state.content.questionInsertion);
    const trainingsInfoState = useSelector(state => state.content.trainingsInfo);
    const singleQuestionState = useSelector(state => state.content.singleQuestion);
    const [topicOptions, setTopicOptions] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const allTopicsFromCompany = useSelector(state => state.content.topics.allTopicsFromCompany);
    const [firstTabSelected, setFirstTabSelected] = useState(true);

    const { selectedTraining, chosenCategoryId, setChosenCategoryId } = useSetAndCategory();
    const { questionStatus, setQuestionStatus } = useStatus();
    const { dateAvailableFrom, setDateAvailableFrom } = useDateAvailableFrom();

    const { insertAnswer, resetAnswers, updateAnswer, setExtra } = useAnswerTextOptions();
    const {
        contentBlock,
        questionAttribute,
        answerAttribute,
        correctValuesMap,
        autoGenerateValue,
        comparatorCriteria,
        comparatorCorrect,
    } = useContentBlocksBuilder();

    const [isGeneratedQuestionsModalOpen, setIsGeneratedQuestionsModalOpen] = useState(false);
    const [generatedQuestions, setGeneratedQuestions] = useState({});

    const [open, setOpen] = React.useState(false);

    const [hasConflict, setHasConflict] = useState(false);
    const [resourceCurrentState, setResourceCurrentState] = useState(null);

    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveAndAddNew = () => {
        setAddNew(true);
        handleSave();
    };

    const tommorow = useMemo(() => {
        let today = new Date();
        today.setDate(today.getDate() + 1);
        return today;
    }, []);

    function validateInputs() {
        return (
            validator[validatorCategories.CHOSEN_CATEGORY].validate() ||
            validator[validatorCategories.PREVIEW_GAMES_QUESTION_NOT_EMPTY].validate() ||
            validator[validatorCategories.IMAGE_WITHOUT_VALUE].validate() ||
            validator[validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY].validate() ||
            validator[validatorCategories.SINGLE_ANSWER_NOT_EMPTY].validate() ||
            validator[validatorCategories.CORRECT_ANSWER_REQUIRED].validate() ||
            validator[validatorCategories.INCORRECT_ANSWER_REQUIRED].validate() ||
            validator[validatorCategories.COMPARING_CRITERIA_REQUIRED].validate() ||
            validator[validatorCategories.IMAGE_REQUIRED].validate() ||
            validator[validatorCategories.MIN_OPTIONS].validate() ||
            validator[validatorCategories.MAX_OPTIONS].validate() ||
            validator[validatorCategories.MIN_CORRECT_OPTIONS].validate() ||
            validator[validatorCategories.NO_DUPLICATE_IMAGES].validate() ||
            validator[validatorCategories.NO_DUPLICATE_CATEGORIES].validate() ||
            validator[validatorCategories.NO_DUPLICATE_ANSWERS].validate() ||
            validator[validatorCategories.MIN_WRONG_OPTIONS].validate()
        );
    }

    const handleSave = force => {
        const options = insertionState?.options;

        let validationError = validateInputs();

        if (validationError && !force) {
            setOpen(true);
        } else if (validationError && force) {
            saveAsDraft(true);
        }

        if (!validationError) {
            dispatch(
                insertQuestion({
                    question: insertionState?.question,
                    questionType: {
                        questionType: insertionState?.currentType,
                    },
                    answersList: options
                        ?.filter(questionTypeAnswerFilters[insertionState.currentType])
                        ?.map(questionTypeAnswerMappers[insertionState.currentType]),

                    extra: insertionState?.extra,
                    categoryId: chosenCategoryId ? chosenCategoryId : null,
                    gameSlug: game,
                    ...(id ? { questionId: id } : {}),
                    answersToRemove: insertionState?.removedAnswers,
                    status: questionStatus,
                    dateAvailableFromMills: questionStatus === 'INACTIVE' ? (dateAvailableFrom ? dateAvailableFrom?.getTime() : null) : null,
                    lastModified: force ? Date.now() : insertionState?.lastModified,
                })
            );
        }
    };

    function getQuestionObject() {
        const options = insertionState.options;

        return {
            question: insertionState.question,
            questionType: {
                questionType: insertionState.currentType,
            },
            answersList: options
                .filter(questionTypeAnswerFilters[insertionState.currentType])
                .map(questionTypeAnswerMappers[insertionState.currentType]),
            extra: insertionState.extra,
            categoryId: chosenCategoryId ? chosenCategoryId : null,
            gameSlug: game,
            ...(id ? { questionId: id } : {}),
            answersToRemove: insertionState.removedAnswers,
            status: questionStatus,
            dateAvailableFromMills: questionStatus === 'INACTIVE' ? (dateAvailableFrom ? dateAvailableFrom?.getTime() : null) : null,
            lastModified: insertionState.lastModified,
        };
    }

    function handleSaveContentBlock() {
        if (!Object.keys(correctValuesMap).length > 0 || !autoGenerateValue) {
            return;
        }
        resetAnswers();
        let generatedAnswers = generateAnswers(
            game,
            contentBlock.data,
            correctValuesMap,
            answerAttribute,
            autoGenerateValue,
            comparatorCriteria,
            comparatorCorrect
        );
        let extras = generateExtras(game, correctValuesMap, autoGenerateValue, comparatorCriteria, comparatorCorrect);
        setExtra(extras);
        generatedAnswers.forEach(el => {
            insertAnswer();
            updateAnswer(el);
        });
    }

    function generateQuestions() {
        if (!Object.keys(correctValuesMap).length > 0) {
            return;
        }
        setGeneratedQuestions(
            generateQuestionsContentBlock(
                game,
                question,
                contentBlock.data,
                correctValuesMap,
                questionAttribute,
                answerAttribute,
                autoGenerateValue,
                comparatorCriteria,
                comparatorCorrect
            )
        );
        setIsGeneratedQuestionsModalOpen(true);
    }

    const handleOutsideClick = () => {
        if (open) return;
        validationActions.hide(validatorCategories.MIN_CORRECT_OPTIONS);
        validationActions.hide(validatorCategories.MIN_WRONG_OPTIONS);
        validationActions.hide(validatorCategories.MIN_OPTIONS);
        validationActions.hide(validatorCategories.MAX_OPTIONS);
        validationActions.hide(validatorCategories.PREVIEW_GAMES_QUESTION_NOT_EMPTY);
        validationActions.hide(validatorCategories.IMAGE_WITHOUT_VALUE);
        validationActions.hide(validatorCategories.PREVIEW_GAMES_ANSWERS_NOT_EMPTY);
        validationActions.hide(validatorCategories.SINGLE_ANSWER_NOT_EMPTY);
        validationActions.hide(validatorCategories.CORRECT_ANSWER_REQUIRED);
        validationActions.hide(validatorCategories.NOT_CHOSEN_CATEGORY);
        validationActions.hide(validatorCategories.INCORRECT_ANSWER_REQUIRED);
        validationActions.hide(validatorCategories.COMPARING_CRITERIA_REQUIRED);
        validationActions.hide(validatorCategories.IMAGE_REQUIRED);
        validationActions.hide(validatorCategories.CHOSEN_CATEGORY);
        validationActions.hide(validatorCategories.NO_DUPLICATE_ANSWERS);
        validationActions.hide(validatorCategories.NO_DUPLICATE_IMAGES);
        validationActions.hide(validatorCategories.NO_DUPLICATE_CATEGORIES);
    };

    const saveAsDraft = force => {
        setOpen(false);
        const options = insertionState.options;

        dispatch(
            insertQuestion({
                question: insertionState.question,
                questionType: {
                    questionType: insertionState.currentType,
                },
                answersList: options
                    .filter(questionTypeAnswerFilters[insertionState.currentType])
                    .map(questionTypeAnswerMappers[insertionState.currentType]),

                extra: insertionState.extra,
                categoryId: chosenCategoryId ? chosenCategoryId : null,
                gameSlug: game,
                ...(id ? { questionId: id } : {}),
                answersToRemove: insertionState.removedAnswers,
                status: questionStatusOptions[2].value,
                dateAvailableFromMills: null,
                lastModified: force ? Date.now() : insertionState.lastModified,
            })
        );
        setQuestionStatus(questionStatusOptions[2].value);
    };

    useEffect(() => {
        if (stateIsLoaded(allTopicsFromCompany)) {
            setTopicOptions(
                allTopicsFromCompany.data?.map(t => {
                    return {
                        value: t.treeIndex,
                        label: t.title,
                    };
                })
            );
        }
    }, [allTopicsFromCompany]);

    useEffect(() => {
        if (stateIsLoaded(questionInsertion)) {
            toast.success(t('Successfully saved!'));

            if (questionInsertion.data.validationErrors && questionInsertion.data.validationErrors.length > 0) {
                toast.custom(t =>
                    CustomToast({
                        t: t,
                        iconType: IconType.INFO,
                        title: 'Question saved as draft because of failed constraints:',
                        message: questionInsertion.data.validationErrors.map(el => (
                            <div>
                                {el} <br />
                            </div>
                        )),
                    })
                );
            }

            dispatch(resetInsertQuestionAction());
            if (addNew) {
                insertionStateReset.resetState();
                if (initializeQuestion) {
                    initializeQuestion();
                }
                setTimeout(() => history.replace('/content/questions/add/' + game), 1000);
            } else if (id) {
                dispatch(getQuestionById(id));
            } else {
                history.replace('/content/questions');
            }
        } else if (stateHasFailed(questionInsertion)) {
            if (questionInsertion.responseStatus === StatusCode.CONFLICT) {
                setResourceCurrentState(questionInsertion.data);
                setHasConflict(true);
            } else {
                toast.error(t('Changes have not been saved!'));
            }
            dispatch(resetInsertQuestionAction());
        }
    }, [questionInsertion, addNew]);

    useEffect(() => {
        if (selectedTraining) {
            dispatch(getSingleTrainingAction(selectedTraining.value));
        }
    }, [selectedTraining]);

    if (
        (stateIsLoading(trainingsInfoState) && !stateIsLoaded(trainingsInfoState)) ||
        (stateIsLoading(allTopicsFromCompany) && !stateIsLoaded(allTopicsFromCompany))
    ) {
        return (
            <div style={{ height: '80vh', width: '100%' }} className={'row align-items-center justify-content-center'}>
                <Circular />
            </div>
        );
    }

    return (
        <div
            // this were used, overflowY: 'scroll', overflowX: 'clip',
            style={{ width: '55%', maxWidth: '55%', height: '75vh' }}
            className="pr-5 pl-3 mt-4 no-scrollbar"
        >
            {generatedQuestions && isGeneratedQuestionsModalOpen && (
                <GeneratedQuestionsListModal
                    modalOpen={isGeneratedQuestionsModalOpen}
                    setModalOpen={setIsGeneratedQuestionsModalOpen}
                    generatedQuestions={generatedQuestions}
                />
            )}
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{t('Validation of question failed')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('The question contains one or more files that are incorrect. Do you want to save the question as draft?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => saveAsDraft(false)}>{t('Yes')}</Button>
                    <Button onClick={handleClose} autoFocus>
                        {t('No')}
                    </Button>
                </DialogActions>
            </Dialog>
            {hasConflict && (
                <ConflictModal
                    modalOpen={hasConflict}
                    setModalOpen={setHasConflict}
                    oldData={{ ...singleQuestionState?.data, ...getQuestionObject() }}
                    newData={resourceCurrentState}
                    forceSave={() => handleSave(true)}
                />
            )}
            <InputQuestion />
            {/* <HorizontalMenuPicker
                firstElement={t('OPTIONS')}
                secondElement={t('CONTENT BLOCKS')}
                firstElementSelected={firstTabSelected}
                setFirstElementSelected={setFirstTabSelected}
                disabled={true}
            /> */}
            {!firstTabSelected && <ContentBlocksBuilder />}
            {firstTabSelected && (
                <>
                    <Caption1 className={'mt-2'} color={colors.gray6}>
                        {t('TOPIC')}
                    </Caption1>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
                        <div style={{ width: '80%', position: 'relative' }}>
                            <TopicDropdownSelector
                                value={chosenCategoryId !== null ? topicOptions.filter(t => t.value === chosenCategoryId)[0] : ''}
                                onChange={topicId => setChosenCategoryId(topicId)}
                                iconSelected={IconTopicPickerSelected}
                                iconNotSelected={IconTopicPickerNotSelected}
                                width={'100%'}
                            />
                            <ValidationPopup category={validatorCategories.CHOSEN_CATEGORY} position="center" />
                        </div>
                        {chosenCategoryId && (
                            <Link to={`/content/topics?topicId=${chosenCategoryId}`}>
                                <img style={{ cursor: 'pointer' }} src={GoToLinkLight} width={20} height={20} alt="link" />
                            </Link>
                        )}
                    </div>
                    <Caption1 className={'mt-4'} color={colors.gray6}>
                        {t('STATUS')}
                    </Caption1>
                    <div className="d-flex flex-row justify-content-start" style={{ width: '80%', padding: '0', margin: '0' }}>
                        <div style={{ width: '100%' }}>
                            <CustomSelect
                                id="add-questions-activation"
                                value={questionStatus ? questionStatusOptions.filter(s => s.value === questionStatus)[0] : ''}
                                onChange={option => {
                                    setQuestionStatus(option ? option.value : '');
                                    if (option.label === 'Inactive') {
                                        setDateAvailableFrom(tommorow);
                                    }
                                }}
                                options={questionStatusOptions}
                                styles={{ width: '100%' }}
                            />
                        </div>
                        <div>
                            {questionStatus === 'INACTIVE' && (
                                <div style={{ marginLeft: '8px' }}>
                                    <CustomDatePicker
                                        value={dateAvailableFrom}
                                        onChange={date => setDateAvailableFrom(date ? date : null)}
                                        border={false}
                                        minDate={tommorow}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                        <ValidationPopup category={validatorCategories.MIN_OPTIONS} position="center" arrowAtBottom={true} />
                        <ValidationPopup category={validatorCategories.MAX_OPTIONS} position="center" arrowAtBottom={true} />
                        <ValidationPopup category={validatorCategories.MIN_CORRECT_OPTIONS} position="center" arrowAtBottom={true} />
                        <ValidationPopup category={validatorCategories.MIN_WRONG_OPTIONS} position="center" arrowAtBottom={true} />
                        <ValidationPopup category={validatorCategories.CORRECT_ANSWER_REQUIRED} position="center" arrowAtBottom={true} />
                        <ValidationPopup category={validatorCategories.NO_DUPLICATE_IMAGES} position="center" arrowAtBottom={true} />
                        <ValidationPopup category={validatorCategories.NO_DUPLICATE_ANSWERS} position="center" arrowAtBottom={true} />
                        <ValidationPopup category={validatorCategories.NO_DUPLICATE_CATEGORIES} position="center" arrowAtBottom={true} />
                        <ValidationPopup category={validatorCategories.INCORRECT_ANSWER_REQUIRED} position="right" arrowAtBottom={true} />
                    </div>
                    <div style={{ overflow: 'auto', height: '50%' }}>{children}</div>
                </>
            )}

            <div className="mt-3 d-flex flex-row ">
                {(firstTabSelected || questionAttribute) && (
                    <InsideComponent onOutsideClick={handleOutsideClick}>
                        <DefaultButton
                            id="save-button-questions"
                            textColor={colors.white}
                            hoverBackground={colors.dark}
                            disabled={stateIsLoading(questionInsertion) || question.length <= 0}
                            text={!firstTabSelected ? t('Generate one') : t('Save')}
                            onClick={() => (!firstTabSelected ? handleSaveContentBlock() : handleSave(false))}
                        />
                        <OutlinedButton
                            id="save-and-add-new-button-questions"
                            disabled={stateIsLoading(questionInsertion) || question.length <= 0}
                            onClick={!firstTabSelected ? generateQuestions : handleSaveAndAddNew}
                            text={!firstTabSelected ? t('Generate multiple') : t('Save and add new')}
                            className="ml-2"
                        />
                    </InsideComponent>
                )}
                {stateIsLoading(questionInsertion) && <CircularProgress className={'ml-2'} style={{ color: colors.mainTheme }} size={25} />}
            </div>
        </div>
    );
};

InputContent.propTypes = {
    children: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
    initializeQuestion: PropTypes.func,
};

export default InputContent;
