import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inviteMultipleAction, setUsersInvited } from 'redux/actions/users.actions';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { Circular } from 'components/features/data_table/Circular';
import { OutlinedButton } from 'components/elements/Button';
import { useTranslation } from 'react-i18next';
import InvitationFormNumber from './InvitationFormNumber';

const MultipleUsersInviteNumber = ({ data, internalSubmit, hasRoles }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const roles = useSelector(state => state.userManagement.roles);
    const usersInvited = useSelector(state => state.userManagement.usersInvited);

    let company = useSelector(state => state.userManagement.company.data);

    const [forms, setForms] = useState([{ formId: 0, email: null, role: '', code: '+1' }]);
    const [formId, setFormId] = useState(0);


    const [currentOpenIndex, setCurrentOpenIndex] = useState(null);

    const emailsMap = useRef({});

    let timeout = useRef(0);

    useEffect(() => {
        clearTimeout(timeout.current);
        timeout.current = null;
        timeout.current = setTimeout(() => {
            dispatch(setUsersInvited(forms));
        }, 500);
    }, [forms]);

    useEffect(() => {
        let checkData = data || forms;

        let newForms = checkData.map((d, index) => {
            return {
                roleError: getRoleError(d.role),
                formId: index,
                email: d.email,
                role: d.role,
            };
        });
        setForms(newForms);
        setFormId(checkData.length);

        //eslint-disable-next-line
    }, [data, roles]);

    function addRow() {
        let item = { formId: formId + 1, email: null, role: '', code: '+1' };
        item.roleError = getRoleError('');
        let newForms = [...forms, item];
        setFormId(formId + 1);
        setForms(newForms);
    }

    const handleRemoveRow = id => {
        let form = forms.find(f => f.formId === id);
        if (form) {
            if (emailsMap.current[form.email]) emailsMap.current[form.email] = emailsMap.current[form.email].filter(f => f !== id);
        }
        let newForms = forms.filter(f => f.formId !== id);

        setForms(newForms);
    };

    const getRoleError = role => {
        if (!role) return { message: t('You must select role') };
        else if (stateIsLoaded(roles)) {
            if (!roles?.data?.roles?.map(r => r.roleName).includes(role)) {
                return {
                    message: t('Invalid role'),
                };
            } else return null;
        } else return null;
    };

    function onChangeNumber(form, number, code) {
        let index = forms.indexOf(form);
        form.phoneNumber = number;
        form.countryCode = code.replace('+', '');
        forms[index] = form;
        setForms([...forms]);
    }
    function onChangeRole(form, role) {
        let index = forms.indexOf(form);
        form.role = role;
        form.roleError = getRoleError(form.role);
        forms[index] = form;
        setForms([...forms]);
    }
    function submit() {
        let filteredForms = [];
        let hasError = false;

        let newForms = forms.map((d, index) => {
            let err = {};

            err.roleError = getRoleError(d.role);

            return {
                ...err,
                formId: index,
                email: d.email,
                role: d.role,
            };
        });
        setForms(newForms);

        if (newForms.filter(f => f.emailError !== null || f.roleError !== null).length > 0) {
            hasError = true;
        }

        if (!hasError) {
            filteredForms = forms.filter(form => form.email.length > 0 && form.role.length > 0);
            dispatch(inviteMultipleAction(filteredForms));
        }
    }

    if (!stateIsLoaded(roles)) {
        return (
            <div style={{ height: '50vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                <Circular />
            </div>
        );
    }



    return (
        <div className={'row justify-content-center p-2 pt-0 mr-2'}>
            {forms.map(form => {
                return (
                    <InvitationFormNumber
                        usersInvited={usersInvited}
                        key={`invitation-multiple-${form.formId}`}
                        company={company.name}
                        onChangeNumber={onChangeNumber}
                        onChangeRole={onChangeRole}
                        formObj={form}
                        onRemoveRow={handleRemoveRow}
                        showRemove={forms.length > 1}
                        hasRoles={hasRoles}
                        currentOpenIndex={currentOpenIndex}
                        setCurrentOpenIndex={setCurrentOpenIndex}
                    />
                );
            })}
            <div className="row mt-3">
                <OutlinedButton id="add-row-button" text={t('Add row')} className="mr-2" onClick={addRow} />
                {internalSubmit && <OutlinedButton text={t('Submit')} className="mr-2" onClick={submit} />}
            </div>
        </div>
    );
};
export default MultipleUsersInviteNumber;
