import React from 'react';
import PropTypes from 'prop-types';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import { colors } from 'style/colors';
import Icon from 'assets/icons/checkmark-img.svg';
import { useTranslation } from 'react-i18next';

export function FeedbackResolveDialog({ modalOpen, setModalOpen, userName }) {
    const { t } = useTranslation();

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => setModalOpen(false)}
            title={t('Issue resolved!')}
            text={t(`An automatic email was sent to {{userName}} in order to notify them that their issue has been resolved.`, {
                userName: userName,
            })}
            buttons={[
                {
                    title: t('Continue'),
                    color: colors.white,
                },
            ]}
            hasCancel={false}
            image={Icon}
        ></ModalDialog>
    );
}

FeedbackResolveDialog.propTypes = {
    modalOpen: PropTypes.any,
    setModalOpen: PropTypes.func,
    userName: PropTypes.any,
};
