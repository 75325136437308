import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import { makeStyles } from '@material-ui/core';
import { colors } from 'style/colors';
import Modal from '@material-ui/core/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { H3 } from 'style/typography/Heading';
import { Body1 } from 'style/typography/Body';
import { UploadTraining } from './UploadTraining';
import { DefaultButton } from 'components/elements/Button';
import { Alert } from '@material-ui/lab';
import { getAllTrainingsAction, resetSingleTrainingAction, resetUploadTrainingAction } from 'redux/actions/trainings.actions';
import { Trans, useTranslation } from 'react-i18next';

export function ImportTrainingDialog({ open, setOpen, setSelectedTraining }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const uploadTrainingState = useSelector(state => state.content.uploadTraining);
    const uploadTrainingProgressState = useSelector(state => state.content.uploadingTrainingProgress);

    useEffect(() => {
        if (stateIsLoaded(uploadTrainingState)) {
            dispatch(resetSingleTrainingAction());
            dispatch(getAllTrainingsAction());
        }
    }, [dispatch, uploadTrainingState]);

    const isUploadFinished =
        stateIsLoaded(uploadTrainingProgressState) &&
        uploadTrainingProgressState.data.totalProcesses === uploadTrainingProgressState.data.completedProcesses;

    function goToTraining() {
        let trainingId = uploadTrainingProgressState?.data?.completedData?.trainingId;
        // history.replace('/content/trainings' + (trainingId ? `?trainingId=${trainingId}` : ''));
        setSelectedTraining(trainingId);
        dispatch(resetUploadTrainingAction());
        setOpen(false);
    }
    return (
        <Modal
            open={open}
            className={classes.modal}
            onClose={() => {
                if (isUploadFinished) {
                    dispatch(resetUploadTrainingAction());
                }
                setOpen(false);
            }}
        >
            <Paper style={{ overflow: 'scroll' }} className={`${classes.paper} d-flex flex-column`}>
                <H3 className="text-center mt-4">{t('Import training')}</H3>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    {!isUploadFinished && (
                        <div className="text-center">
                            <Body1 color={colors.gray6} className="mt-3">
                                <Trans>{t('Make sure to upload <strong>.xlsx or .xls</strong> file and adhere to our format')}</Trans>
                            </Body1>
                            <UploadTraining />
                        </div>
                    )}
                    {isUploadFinished && (
                        <div style={{ marginTop: '10%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Alert severity="success">
                                {t('Training {{training}} uploaded successfully!', {
                                    training: uploadTrainingProgressState?.data?.completedData?.trainingTitle,
                                })}
                            </Alert>
                            <DefaultButton text={t('Go to training')} onClick={goToTraining} style={{ position: 'absolute', bottom: '10%' }} />
                        </div>
                    )}
                </div>
            </Paper>
        </Modal>
    );
}

ImportTrainingDialog.propTypes = {
    open: PropTypes.any,
    setOpen: PropTypes.func,
};

const useStyles = makeStyles(theme => {
    return {
        paper: {
            position: 'absolute',
            width: '70%',
            backgroundColor: theme.palette.background.main,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            height: '70vh',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            textAlign: 'center',
        },
        alertFixPaper: {
            maxWidth: '2000px',
            width: '600px',
        },
    };
});
