import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { useThemePalette } from 'style/theme/consumers';
import { Body2 } from 'style/typography/Body';
import { Caption2 } from 'style/typography/Caption';
import { useTranslation } from 'react-i18next';

const SelectionTooltip = ({ position = 'center', options, onMouseEnter, onMouseLeave, marginTop = 130 }) => {
    const { t } = useTranslation();

    const palette = useThemePalette();
    let optionsElements = options.map(el => {
        return (
            <div className="d-flex flex-row align-items-center">
                {el.icon && <img alt="Element" src={el.icon} height={15} width={15} style={{ margin: '5px 10px 5px 5px ' }}></img>}
                {el.type === 'button' && (
                    <Body2 color={colors.white} style={{ padding: '5px 0', cursor: 'pointer' }} onClick={el.onClick}>
                        {t(el.text)}
                    </Body2>
                )}
                {el.type === 'title' && (
                    <Caption2 color={colors.white} style={{ padding: '5px 0', cursor: 'pointer' }}>
                        {t(el.text)}
                    </Caption2>
                )}
            </div>
        );
    });
    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{
                position: 'absolute',
                width: '110px',
                marginTop: marginTop,
                backgroundColor: palette.secondary.main,
                color: 'white',
                padding: '5px 10px',
                left: position === 'center' ? '50%' : position === 'left' ? '10px' : 'unset',
                right: position === 'right' ? '10px' : 'unset',
                zIndex: 999,
                transform: position === 'center' ? 'translateX(-50%)' : '',
                borderRadius: '5px',
                fontSize: '12px',
                fontWeight: 'light',
            }}
        >
            <div
                style={{
                    width: '20px',
                    height: '20px',
                    transform: 'rotate(45deg) translateX(-50%)',
                    position: 'absolute',
                    left: '50%',
                    backgroundColor: palette.secondary.main,
                    top: '0',
                    zIndex: -1,
                }}
            ></div>
            {optionsElements}
        </div>
    );
};

SelectionTooltip.propTypes = {
    marginTop: PropTypes.number,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    options: PropTypes.array,
    position: PropTypes.string,
};

export default SelectionTooltip;
