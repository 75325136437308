import React, { useState, useEffect, useRef } from 'react';
import { useContentInsertionQuestion, useMultipleChoiceAnswers } from 'services/content-management/insertion-control';
import { randomize } from 'services/utils/arrayHelpers';
import { optionDefaults, questionTypes } from 'services/content-management/insertion-control/local-constants';
import { InputContentWrapper } from 'components/wrappers/InputContentWrapper';
import TrafficInputs from './TrafficInputs';
import TrafficPreview from './TrafficPreview';

const AddQuestionTraffic = ({ game, id, filters }) => {
    const { setQuestion } = useContentInsertionQuestion();
    const { answers: answersList, setOptions } = useMultipleChoiceAnswers();
    const [answers, setAnswers] = useState([]);

    const previousAnswersLength = useRef(0);
    const randomAnswers = useRef(randomize(answersList, 21, optionDefaults.default));

    const initializeQuestion = () => {
        const initial = Array(10)
            .fill(0)
            .map((_, index) => ({ ...optionDefaults.default, id: index }));
        setOptions(initial);
        setQuestion('');
    };

    useEffect(() => {
        if (!id) {
            initializeQuestion();
        }
    }, []);

    useEffect(() => {
        let result = randomize(answersList, 21, optionDefaults[questionTypes.CHECKBOXES]);
        if (previousAnswersLength.current !== answersList.length) {
            setAnswers([...result]);
            randomAnswers.current = result;
        } else {
            let ansNew = randomAnswers.current;
            ansNew.forEach(an => {
                let item = answersList.find(a => a.id === an.id);
                if (item) {
                    an.text = item.text;
                    an.isCorrect = item.isCorrect;
                }
            });
            setAnswers([...randomAnswers.current]);
        }

        previousAnswersLength.current = answersList.length;
        //eslint-disable-next-line
    }, [answersList]);

    return (
        <div className="d-flex flex-row h-100 m-0">
            <InputContentWrapper id={id} filters={filters}>
                <div className="d-flex flex-row w-100 h-100">
                    <TrafficInputs game={game} id={id} filters={filters} initializeQuestion={initializeQuestion} />
                    <TrafficPreview answers={answers} />
                </div>
            </InputContentWrapper>
        </div>
    );
};

export default AddQuestionTraffic;
