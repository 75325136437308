import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body2 } from 'style/typography/Body';
import { getLabelByIdAction, getLabelsAction, resetAddUsersToTagsAction, resetLabelByIdAction } from 'redux/actions/tags.actions';
import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { Caption1 } from 'style/typography/Caption';
import FeedbackIcon from 'assets/icons/icon-emptystate-tags.svg';
import { H8 } from 'style/typography/Heading';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function SetTagsBulkModal({ modalOpen, setModalOpen, onAction, onClose, bulkItemsCount }) {
    const labelsState = useSelector(state => state.content.labels);
    const labelByIdState = useSelector(state => state.content.labelById);
    const addUsersToTagsState = useSelector(state => state.content.addUsersToTags);

    const [currentLabel, setCurrentLabel] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    function getLabel(labelId) {
        setCurrentLabel(prevData => (prevData === labelId ? null : labelId));
    }

    function selectTag(tagId) {
        let tagIsSelected = selectedTags.find(el => el === tagId);
        if (tagIsSelected) {
            setSelectedTags(prevData => prevData.filter(el => el !== tagId));
        } else {
            setSelectedTags(prevData => [...prevData, tagId]);
        }
    }

    useEffect(() => {
        dispatch(getLabelsAction());
    }, []);

    useEffect(() => {
        if (stateIsLoaded(addUsersToTagsState)) {
            dispatch(resetAddUsersToTagsAction());
            setModalOpen(false);
            onClose(true);
        }
    }, [addUsersToTagsState]);

    useEffect(() => {
        if (currentLabel) {
            dispatch(getLabelByIdAction(currentLabel));
        }
    }, [currentLabel]);

    let title = bulkItemsCount > 1 ? t('Set tag for {{bulkItemsCount}} users', { bulkItemsCount: bulkItemsCount }) : t('Set tag for user');

    const buttonsEmptyState = [
        {
            title: t('Create tag'),
            onClick: () => {
                history.replace('/company/tags');
            },
        },
    ];

    const buttons = [
        {
            title: t('Add tags'),
            color: colors.white,
        },
    ];

    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onClose={onClose}
            onAction={() => {
                onAction(selectedTags);
            }}
            title={labelsState.data?.length > 0 ? title : ''}
            buttons={labelsState.data?.length > 0 ? buttons : buttonsEmptyState}
            hasCancel={true}
            alert={
                <div style={{ width: '100%' }}>
                    {labelsState.data?.length == 0 && (
                        <div style={{ paddingTop: 10, alignContent: 'center', textAlign: 'center' }}>
                            <img style={{ width: 80, height: 80 }} src={FeedbackIcon} alt="" />
                            <H8 color={colors.secoundaryText} className={'mt-4'}>
                                {t('No existing tags')}
                            </H8>
                            <Body2 className={'mt-3'} color={colors.gray6}>
                                {t('You can create labels and add tags inside the company tab.')}
                            </Body2>
                        </div>
                    )}
                    {labelsState.data?.length > 0 && (
                        <div>
                            <Body2>{t('Select the tags that you want to add')}</Body2>
                            <div className="tagsModal--container">
                                {labelsState.data?.map(el => {
                                    return (
                                        <div style={{ borderBottom: `1px solid ${colors.gray2}`, padding: '5px 0' }}>
                                            <div
                                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', cursor: 'pointer' }}
                                                onClick={() => {
                                                    if (labelByIdState?.data?.labelId === el.labelId) {
                                                        dispatch(resetLabelByIdAction());
                                                        setCurrentLabel(null);
                                                    } else {
                                                        getLabel(el.labelId);
                                                    }
                                                }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                                    <Body2 weight="medium">{el.label}</Body2>
                                                    <Body2 weight="light">
                                                        ({el.tagsCount} {t('tags')})
                                                    </Body2>
                                                </div>
                                                <img
                                                    alt="Show more"
                                                    height={15}
                                                    width={15}
                                                    className={'ml-2 cursor-pointer'}
                                                    src={currentLabel != null && currentLabel === el.labelId ? AngleUp : AngleDown}
                                                />
                                            </div>
                                            {el.labelId != null &&
                                                labelByIdState?.data?.labelId === el.labelId &&
                                                labelByIdState.data.tags.map(tag => {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                gap: '5px',
                                                                padding: '0px 5px',
                                                                margin: '5px 0px',
                                                            }}
                                                        >
                                                            <div style={{ position: 'relative', height: '20px', width: '20px' }}>
                                                                <Checkbox
                                                                    id={'check'}
                                                                    onClick={() => selectTag(tag.tagId)}
                                                                    checked={selectedTags.find(selTag => selTag === tag.tagId)}
                                                                ></Checkbox>
                                                            </div>
                                                            <Body2>{tag.tag}</Body2>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    );
                                })}
                            </div>
                            <div style={{ paddingTop: 10 }}>
                                <Caption1 color={colors.gray6}>{t('{{selectedTags}} selected tags', { selectedTags: selectedTags.length })}</Caption1>
                            </div>
                        </div>
                    )}
                </div>
            }
        ></ModalDialog>
    );
}

SetTagsBulkModal.propTypes = {
    bulkItemsCount: PropTypes.number,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
