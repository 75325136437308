import React from 'react';

import Text from './Text';
import { colors } from 'style/colors';

const Heading = ({ weight, tag, fontWeight, fontHeight, color = colors.dark, style, ...attr }) => {
    return Text(weight, headingTagDefaults[tag], fontWeight, fontHeight, color, style, attr);
};


const H1 = props => <Heading {...props} tag="h1" />;
const H2 = props => <Heading {...props} tag="h2" />;
const H3 = props => <Heading {...props} tag="h3" />;
const H4 = props => <Heading {...props} tag="h4" />;
const H5 = props => <Heading {...props} tag="h5" />;
const H6 = props => <Heading {...props} tag="h6" />;
const H7 = props => <Heading {...props} tag="h7" />;
const H8 = props => <Heading {...props} tag="h8" />;

const headingTagDefaults = {
    h1: {
        fontSize: 44
    },
    h2: {
        fontSize: 36
    },
    h3: {
        fontSize: 32
    },
    h4: {
        fontSize: 30,
    },
    h5: {
        fontSize: 28,
    },
    h6: {
        fontSize: 26,
    },
    h7: {
        fontSize: 24,
    },
    h8: {
        fontSize: 22,
    },
}


export { H1, H2, H3, H4, H5, H6, H7, H8, };
