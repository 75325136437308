export const initialLearnState = {
    learnEnabled: false,
    learnType: null,
    learnUrl: null,
    learnCoverImage: null,
    coverImageFile: null,
};

export const learnTypes = [
    { value: 'EXTERNAL', label: 'External' },
    { value: 'INTERNAL', label: 'Internal' },
];
