import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'style/colors';
import { makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { useDispatch } from 'react-redux';
import { addChildToTopicsTree } from 'redux/actions/topics.actions';
import { useTranslation } from 'react-i18next';

export function NewTopicModal({ modalOpen, setModalOpen, topic, setTopic, setCurrentNode }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <Modal
            open={modalOpen}
            className={classes.modal}
            onClose={() => {
                setModalOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper style={{ width: '40vw' }} className={`${classes.paper} row`}>
                <div className="col-12 d-flex flex-row p-3">
                    <div className="d-flex flex-column">
                        <h5 className={'text-dark font-weight-bold'}>{t('Add new topic')}</h5>
                        <p className={'mt-1'}>{t('Choose a title for your new topic.')}</p>
                    </div>
                    <FontAwesomeIcon onClick={() => setModalOpen(false)} className={'ml-auto'} icon={faTimes}></FontAwesomeIcon>
                </div>
                <div className={'col-12 d-flex flex-column'}>
                    <small className={'text-muted mr-2'}>{t('TOPIC TITLE')}</small>
                    <CustomOutlinedTextField
                        id="new-topic-modal-title-input"
                        width={'100%'}
                        value={topic}
                        setValue={setTopic}
                        placeholder={t('Enter title...')}
                    ></CustomOutlinedTextField>
                </div>
                <div className={'col-12 pr-3 pl-3 d-flex flex-row justify-content-center mt-5 mb-3'}>
                    <input
                        id="new-topic-modal-add-topic-button"
                        onClick={() => {
                            if (topic?.length > 0) {
                                setModalOpen(false);
                                dispatch(addChildToTopicsTree(topic, true, setCurrentNode));
                            }
                        }}
                        type={'button'}
                        value={t('Add topic')}
                        style={{ color: colors.white }}
                        className={'btn-sm btn-dark mr-1'}
                    ></input>
                    <input
                        id="new-topic-modal-cancel-button"
                        onClick={() => setModalOpen(false)}
                        type={'button'}
                        value={t('Cancel')}
                        style={{ color: colors.dark }}
                        className={'btn-sm btn-white ml-1'}
                    ></input>
                </div>
            </Paper>
        </Modal>
    );
}

NewTopicModal.propTypes = {
    modalOpen: PropTypes.any,
    setCurrentNode: PropTypes.any,
    setModalOpen: PropTypes.func,
    setTopic: PropTypes.func,
    topic: PropTypes.any,
};
const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: colors.gray4,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});
