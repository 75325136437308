import PropTypes from 'prop-types';
import { Area, CartesianGrid, ComposedChart, Line, Tooltip, XAxis, YAxis } from 'recharts';
import React from 'react';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';

export function CustomLineChart({ width, height, data }) {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: colors.mainTheme }} className="custom-tooltip p-1">
                    <Caption1 className="label text-white mb-0 font-weight-bold">{`${payload[0].value}`}</Caption1>
                </div>
            );
        }

        return null;
    };

    return (
        <ComposedChart
            width={width}
            height={height}
            className={'mr-0'}
            style={{ position: '', display: 'flex' }}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <defs>
                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="10%" stopColor={colors.mainTheme} stopOpacity={0.1} />
                    <stop offset="90%" stopColor="#FFFFFF" stopOpacity={0.1} />
                </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis axisLine={false} dataKey="time" />
            <YAxis domain={[0, 100]} dataKey={'value'} unit={'%'} axisLine={false} />
            <Tooltip content={<CustomTooltip />} />
            <Line strokeWidth={2} type="monotone" dataKey="value" stroke={colors.mainTheme} dot={{ r: 5 }} activeDot={{ r: 5 }} />
            <Area type="monotone" dataKey={'value'} stroke={colors.mainTheme} fillOpacity={1} fill="url(#colorValue)" />
        </ComposedChart>
    );
}

CustomLineChart.propTypes = {
    data: PropTypes.any,
    height: PropTypes.any,
    width: PropTypes.any,
};
