import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useImageEditor } from 'services/image_management/image-editor-management';
import { tabs, views } from 'services/image_management/image-editor-management/local-constants';
import { CustomSelect } from 'components/elements/selector/CustomSelector';

import Edit from 'assets/icons/icon-edit.svg';
import MoveToFolderModal from './action_modals/MoveToFolderModal';

import {
    getAllImagesAction,
    getImagesCountAction,
    getImagesFromFolderAction,
    getUncategorizedImagesAction,
    moveToFolderAction,
    resetImagesDeleteForce,
    resetMoveToFolderAction,
} from 'redux/actions/image.actions';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { toast } from 'react-hot-toast';
import { AreYouSureDeleteModal } from './action_modals/AreYouSureDeleteModal';
import { ResultsFromSoftDeleteModal } from './action_modals/ResultsFromSoftDeleteModal';
import { useTranslation } from 'react-i18next';

export default function GallerySubheaderActions({ onImagesWithUrlUploadChange, clearSelection }) {
    const { t } = useTranslation();

    const [openMoveToFolderDialog, setOpenMoveToFolderDialog] = useState(false);
    const [requestImageDeletionModal, setRequestImageDeletionModal] = useState(false);
    const [resultFromSoftImageDeletionModal, setResultFromSoftImageDeletionModal] = useState(false);
    const [currentActionOption, setCurrentActionOption] = useState(false);

    const imagesDeleteSoftState = useSelector(state => state.content.imagesDeleteSoft);
    const imagesDeleteForceState = useSelector(state => state.content.imagesDeleteForce);
    const moveImagesToFolderState = useSelector(state => state.content.moveImagesToFolder);

    const dispatch = useDispatch();

    const {
        setView,
        currentNode,
        setCurrentPage,
        size,
        selectedImages,
        currentTab,
        sortBy,
        allImagesSelected,
        setAllImagesSelected,
        setImageSearchValue,
        imageSearchValue,
    } = useImageEditor();

    const onEditPress = () => {
        onImagesWithUrlUploadChange(selectedImages);
        setView(views[0]);
    };
    const moveToFolderPress = () => {
        setOpenMoveToFolderDialog(true);
    };

    const replaceSelectedPress = () => {
        onImagesWithUrlUploadChange(selectedImages);
        setView(views[0]);
    };

    const onRequestImagesDeletition = () => {
        setRequestImageDeletionModal(true);
    };

    const actionOptionsIndividualSel = [
        {
            value: 'Edit selected',
            iconSelected: Edit,
            iconNotSelected: Edit,
            label: t('Edit selected'),
            onPress: onEditPress,
        },
    ];

    const actionOptions = [
        {
            value: 'Move to folder',
            label: t('Move to folder'),
            onPress: moveToFolderPress,
        },
        {
            value: 'Replace selected',
            icon: Edit,
            label: t('Replace selected'),
            onPress: replaceSelectedPress,
        },
        {
            value: 'Delete selected',
            icon: Edit,
            label: t('Delete selected'),
            onPress: onRequestImagesDeletition,
        },
    ];

    const getFolder = () => {
        if (currentTab === tabs.all_images) {
            return 'all';
        } else if (currentTab === tabs.uncategorized) {
            return 'uncategorized';
        } else if (currentTab === tabs.folder) {
            return currentNode.treeIndex;
        }
    };

    function moveToFolder(folder) {
        let folderId = folder?.treeIndex;
        let imageIdsToMove = selectedImages?.map(el => el.id);

        let fromFolder = getFolder();

        if (folderId && imageIdsToMove.length > 0) {
            dispatch(
                moveToFolderAction(
                    {
                        images: imageIdsToMove,
                        data: {
                            allSelected: allImagesSelected,
                            folderId: !isNaN(Number(fromFolder)) ? fromFolder : null,
                            folderAll: fromFolder === 'all',
                            folderUncategorized: fromFolder === 'uncategorized',
                            filters: { searchQuery: imageSearchValue },
                        },
                    },
                    folderId
                )
            );
        }
    }

    function refreshCurrentImagesView() {
        if (currentTab === tabs.folder && currentNode) {
            dispatch(getImagesFromFolderAction({ folderId: currentNode.treeIndex, page: 0, size: size, searchQuery: '', sortBy: sortBy }));
        } else if (currentTab === tabs.all_images) {
            dispatch(getAllImagesAction({ page: 0, size: size, searchQuery: '', sortBy: sortBy }));
        } else if (currentTab === tabs.uncategorized) {
            dispatch(getUncategorizedImagesAction({ page: 0, size: size, searchQuery: '', sortBy: sortBy }));
        }
        setCurrentPage(0);
        clearSelection();
        setAllImagesSelected(false);
        setImageSearchValue('');
    }

    useEffect(() => {
        if (stateIsLoaded(moveImagesToFolderState)) {
            toast.success(t(`Successfully moved {{imageCount}} images`, { imageCount: moveImagesToFolderState.data.length }));

            dispatch(getImagesCountAction());
            refreshCurrentImagesView();
            dispatch(resetMoveToFolderAction());
        }
    }, [moveImagesToFolderState]);

    useEffect(() => {
        if (stateIsLoaded(imagesDeleteSoftState)) {
            if (imagesDeleteSoftState.data.length == 0) {
                refreshCurrentImagesView();
            }
            dispatch(getImagesCountAction());
            setResultFromSoftImageDeletionModal(true);
        }
    }, [imagesDeleteSoftState]);

    useEffect(() => {
        if (stateIsLoaded(imagesDeleteForceState)) {
            refreshCurrentImagesView();
            dispatch(getImagesCountAction());
            dispatch(resetImagesDeleteForce());
        }
    }, [imagesDeleteForceState]);

    return (
        <div>
            {openMoveToFolderDialog && (
                <MoveToFolderModal modalOpen={openMoveToFolderDialog} setModalOpen={setOpenMoveToFolderDialog} onConfirm={moveToFolder} />
            )}
            <AreYouSureDeleteModal
                modalOpen={requestImageDeletionModal}
                setModalOpen={setRequestImageDeletionModal}
                selectedIds={selectedImages.map(el => el.id)}
            />
            <ResultsFromSoftDeleteModal
                modalOpen={resultFromSoftImageDeletionModal}
                setModalOpen={setResultFromSoftImageDeletionModal}
                selectedIds={imagesDeleteSoftState.data?.map(el => el.imageId)}
                onAction={() => {
                    clearSelection();
                }}
            />

            {(selectedImages.length > 0 || allImagesSelected) && (
                <CustomSelect
                    id="add-questions-category"
                    width={200}
                    value={currentActionOption}
                    onChange={option => {
                        if (option.onPress) {
                            option.onPress();
                        }
                    }}
                    options={allImagesSelected ? actionOptions : [...actionOptionsIndividualSel, ...actionOptions]}
                    placeholder={t('Select action')}
                    style={{ width: 200, flex: 1, zIndex: 10 }}
                />
            )}
        </div>
    );
}

GallerySubheaderActions.propTypes = {
    clearSelection: PropTypes.func,
    onImagesWithUrlUploadChange: PropTypes.func,
};
