import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { colors } from 'style/colors';
import { useDispatch, useSelector } from 'react-redux';
import { Caption1 } from 'style/typography/Caption';
import { stateHasFailed, stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import { Circular } from 'components/features/data_table/Circular';
import { CustomDatePicker } from 'components/elements/datepicker/CustomDatePicker';
import { CustomDateRangePicker } from 'components/elements/daterangepicker/CustomDateRangePicker';
import { updateSingleTrainingAction } from 'redux/actions/trainings.actions';
import { Info } from '../info/Info';
import { useTranslation } from 'react-i18next';
import CustomSwitch from 'components/elements/switch/CustomSwitch';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import { CustomOutlinedTextField } from 'components/elements/inputs/CustomOutlinedTextField';
import { learnTypes } from 'static/training_constants';
import TrainingSettingsItem from './TrainingSettingsItem';
import TrainingRolesPicker from './role_picker_training/TrainingRolesPicker';
import { getRolesConnectedToSetAction, resetSaveAllRoles } from 'redux/actions/roles.actions';
import toast from 'react-hot-toast';

import CameraDark from 'assets/icons/camera-dark.svg';
import TrashIcon from 'assets/icons/icon-delete.svg';

import './TrainingSettings.css';
export function TrainingSettings({ setInfo }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [completionDeadline, setCompletionDeadline] = useState('');
    const [dateRange, setDateRange] = useState(['', '']);

    const allRolesState = useSelector(state => state.userManagement.roles);
    const saveRolesState = useSelector(state => state.userManagement.saveRolesNew);

    useEffect(() => {
        setCompletionDeadline(setInfo?.completionDeadline == null ? '' : new Date(setInfo.completionDeadline));

        if (setInfo?.dateAvailableFrom !== undefined && setInfo?.dateAvailableTo !== undefined) {
            setDateRange([
                setInfo?.dateAvailableFrom === null ? '' : new Date(setInfo?.dateAvailableFrom),
                setInfo?.dateAvailableTo === null ? '' : new Date(setInfo?.dateAvailableTo),
            ]);
        }
    }, [setInfo]);

    const changeDateAvailability = newDate => {
        if (newDate?.length > 1 && newDate[1]) {
            newDate[1].setUTCHours(0, 0, 0, 0);
        }
        dispatch(
            updateSingleTrainingAction({
                learningSetInfo: {
                    ...setInfo,
                    dateAvailableFrom: newDate?.length > 1 ? (newDate[0] ? newDate[0].getTime() : null) : null,
                    dateAvailableTo: newDate?.length > 1 ? (newDate[1] ? newDate[1].getTime() : null) : null,
                },
            })
        );
    };

    const changeCompletionDeadline = newDate => {
        dispatch(
            updateSingleTrainingAction({
                learningSetInfo: {
                    ...setInfo,
                    completionDeadline: newDate ? newDate.getTime() : null,
                },
            })
        );
    };

    const changeHasCompletionDeadline = () => {
        dispatch(
            updateSingleTrainingAction({
                learningSetInfo: {
                    ...setInfo,
                    hasCompletionDeadline: !setInfo.hasCompletionDeadline,
                },
            })
        );
    };

    const handleFileChange = files => {
        if (files.length > 0) {
            var reader = new FileReader();
            reader.onload = function(e) {
                dispatch(
                    updateSingleTrainingAction({
                        learningSetInfo: {
                            ...setInfo,
                            learnCoverImage: e.target.result,
                            coverFile: files[0],
                        },
                    })
                );
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const removeImage = () => {
        dispatch(
            updateSingleTrainingAction({
                learningSetInfo: {
                    ...setInfo,
                    learnCoverImage: null,
                    coverFile: null,
                },
            })
        );
    };

    useEffect(() => {
        if (stateIsLoaded(saveRolesState)) {
            toast.success(t('Successfully saved roles!'));
            dispatch(getRolesConnectedToSetAction(setInfo?.setId));
            dispatch(resetSaveAllRoles());
        } else if (stateHasFailed(saveRolesState)) {
            toast.error(t('Something went wrong saving roles..'));
            dispatch(resetSaveAllRoles());
        }
    }, [saveRolesState]);

    if (stateIsLoading(allRolesState)) {
        return (
            <div
                style={{ height: '100%', backgroundColor: colors.white }}
                className={'col-6 d-flex flex-column align-items-center justify-content-center'}
            >
                <Circular />
            </div>
        );
    }

    return (
        <div className="training-settings--container">
            {!setInfo?.isNew && <TrainingSettingsItem title={'Role for this training'} child={<TrainingRolesPicker />} />}
            <TrainingSettingsItem
                title={"'Learn' enabled"}
                child={
                    <CustomSwitch
                        checked={setInfo?.learnEnabled ?? false}
                        onClick={() => {
                            dispatch(
                                updateSingleTrainingAction({
                                    learningSetInfo: {
                                        ...setInfo,
                                        learnEnabled: !setInfo?.learnEnabled,
                                        learnType: learnTypes[0].value,
                                    },
                                })
                            );
                        }}
                    />
                }
            />
            <TrainingSettingsItem
                title={"'Learn' type"}
                visible={setInfo?.learnEnabled === true}
                child={
                    <CustomSelect
                        id="language-dropdown-button"
                        width={'200px'}
                        isClearable={false}
                        value={learnTypes.find(el => el?.value === setInfo?.learnType)}
                        onChange={option => {
                            dispatch(
                                updateSingleTrainingAction({
                                    learningSetInfo: {
                                        ...setInfo,
                                        learnType: option?.value,
                                    },
                                })
                            );
                        }}
                        options={learnTypes}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                neutral50: colors.disabled,
                            },
                        })}
                    />
                }
            />
            <TrainingSettingsItem
                title={"'Learn' cover image"}
                visible={setInfo?.learnEnabled === true && setInfo?.learnType === 'INTERNAL'}
                child={
                    <div>
                        {!setInfo?.learnCoverImage && (
                            <FileUploader
                                dropzoneClass="create-news--dropzone"
                                dropzoneParagraphClass="create-news--dropzone-paragraph"
                                showPreviews={false}
                                showPreviewsInDropzone={false}
                                dropzoneText={
                                    <Caption1 className={'mt-2'} style={{ textAlign: 'center' }} color={colors.gray6}>
                                        {t('Add an image')}
                                    </Caption1>
                                }
                                acceptedFiles={['.png', '.jpg', '.jpeg', '.svg', '.tiff']}
                                filesLimit={1}
                                onChange={handleFileChange}
                                Icon={() => <img alt="Camera" src={CameraDark} width={25} height={25} color={colors.gray6} />}
                            ></FileUploader>
                        )}
                        {setInfo?.learnCoverImage && (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                <img alt="preview" src={setInfo?.learnCoverImage} className="create-news--article-image" />
                                <img alt="delete" src={TrashIcon} height={20} width={20} style={{ cursor: 'pointer' }} onClick={removeImage} />
                            </div>
                        )}
                    </div>
                }
            />
            <TrainingSettingsItem
                title={"'Learn' external url"}
                visible={setInfo?.learnEnabled === true && setInfo?.learnType === 'EXTERNAL'}
                child={
                    <CustomOutlinedTextField
                        width={'200px'}
                        value={setInfo?.learnUrl ?? ''}
                        setValue={value => {
                            dispatch(
                                updateSingleTrainingAction({
                                    learningSetInfo: {
                                        ...setInfo,
                                        learnUrl: value,
                                    },
                                })
                            );
                        }}
                        placeholder={'Learn url...'}
                    ></CustomOutlinedTextField>
                }
            />
            <TrainingSettingsItem
                title={'Topic switching in-app'}
                child={
                    <CustomSwitch
                        checked={setInfo?.hasSwitcher}
                        onClick={() => {
                            dispatch(
                                updateSingleTrainingAction({
                                    learningSetInfo: {
                                        ...setInfo,
                                        hasSwitcher: !setInfo?.hasSwitcher,
                                    },
                                })
                            );
                        }}
                    />
                }
            />
            <TrainingSettingsItem
                title={'Availability'}
                child={
                    <CustomDateRangePicker
                        border={true}
                        value={dateRange}
                        format="dd/MM/yyyy"
                        onChange={value => changeDateAvailability(value)}
                        showDoubleView={true}
                    ></CustomDateRangePicker>
                }
            />
            <TrainingSettingsItem
                title={'Has completion deadline'}
                child={
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'end', gap: 20 }}>
                        <CustomSwitch checked={setInfo?.hasCompletionDeadline} onClick={changeHasCompletionDeadline} />
                        {setInfo?.hasCompletionDeadline && (
                            <CustomDatePicker value={completionDeadline} onChange={changeCompletionDeadline}></CustomDatePicker>
                        )}
                    </div>
                }
            />
            <Info />
        </div>
    );
}

TrainingSettings.propTypes = {
    setInfo: PropTypes.any,
};
