import React, { useEffect, useReducer, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteNewsAction,
    getSpecificNewsAction,
    resetDeleteNewsAction,
    resetEditingNewsState,
    resetSaveNewsState,
    saveNewsAction,
} from 'redux/actions/content.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'style/colors';
import { stateHasFailed, stateIsLoaded, stateIsLoading, stateIsNotInitialized } from 'redux/core/stateHelpers';
import { toast } from 'react-hot-toast';
import { Circular } from 'components/features/data_table/Circular';
import { Body2 } from 'style/typography/Body';
import { DefaultButton, OutlinedButton } from 'components/elements/Button';
import { Header } from 'containers/layout/module/local_components/header/Header';
import DeleteNewsModal from '../local_components/DeleteNewsModal';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { authService } from 'services/auth/auth';
import PreviewNews from './PreviewNews';
import _ from 'lodash';
import { create_UUID } from 'services/utils/stringHelpers';
import CreateAndEditPostSettings from './CreateAndEditPostSettings';
import CreateAndEditPostArticles from './CreateAndEditPostArticles';
import { saveAssetApiRequest } from 'services/api/apiRequests/content';
import { buttonActions, initialArticleState, initialNewsState } from 'static/news';
import { useTranslation } from 'react-i18next';

export const CreateAndEditPost = () => {
    const { t } = useTranslation();

    const history = useHistory();

    const { newsId } = useParams();
    const editingNewsState = useSelector(state => state.content.editingNews);
    const saveNewsState = useSelector(state => state.content.createNews);
    const deleteNewsState = useSelector(state => state.content.deleteNews);

    const [deleteNewsItem, setDeleteNewsItem] = useState(null);

    const [currentIndex, setCurrentIndex] = useState(0);

    const [status, setStatus] = useState('');
    const dispatch = useDispatch();

    const [articlesState, dispatchArticles] = useReducer((state, action) => {
        //On change value
        if (action.articleIndex === undefined && action.actionType === undefined) {
            return { ...state, ...action.payload };
        }
        //On delete article
        if (action.articleIndex !== undefined && action.actionType === 'remove') {
            let articles = _.cloneDeep(state.articles);
            articles.splice(action.articleIndex, 1);
            return { ...state, articles: [...articles], removedArticleIds: [...state.removedArticleIds, action.articleId] };
        }
        //On insert new article
        if (action.actionType === 'insert') {
            return {
                ...state,
                articles: [...state.articles, initialArticleState],
            };
        }
        //Index out of bounds error
        if (state.articles.length <= action.articleIndex) {
            return { ...state };
        }
        //Change parameter in article
        let editedArticle = { ...state.articles[action.articleIndex], ...action.payload };
        let articles = _.cloneDeep(state.articles);
        articles.splice(action.articleIndex, 1, editedArticle);

        return { ...state, articles: [...articles] };
    }, initialNewsState);

    useEffect(() => {
        return () => dispatch(resetEditingNewsState());
    }, []);

    useEffect(() => {
        if (stateIsLoaded(saveNewsState)) {
            toast.success(t('Successfully saved!'));
            dispatch(resetSaveNewsState());
            history.replace('/internal/news');
        }
        if (stateHasFailed(saveNewsState) || stateHasFailed(deleteNewsState)) {
            toast.error(t('Changes have not been saved!'));
            dispatch(resetSaveNewsState());
            dispatch(resetDeleteNewsAction());
        }
    }, [deleteNewsState, goBack, saveNewsState]);

    useEffect(() => {
        if (newsId !== null && newsId !== undefined) {
            dispatch(getSpecificNewsAction(newsId));
        }
    }, [dispatch, newsId]);

    useEffect(() => {
        if (stateIsLoaded(deleteNewsState)) {
            setDeleteNewsItem(null);
            history.replace('/internal/news');
        } else if (stateHasFailed(deleteNewsState)) {
            toast.error(t('Changes have not been saved!'));
            setDeleteNewsItem(null);
        }
    }, [deleteNewsState]);

    useEffect(() => {
        if (!isNaN(newsId) && stateIsLoaded(editingNewsState) && +newsId === +editingNewsState.data.newsId) {
            let data = editingNewsState.data;
            let dateRange;
            if (data.validFromMills || data.validUntilMills) {
                let dateFrom = '';
                let dateTo = '';
                if (data.validFromMills) {
                    dateFrom = new Date(data.validFromMills);
                }
                if (data.validUntilMills) {
                    dateTo = new Date(data.validUntilMills);
                }
                dateRange = [dateFrom, dateTo];
            }
            dispatchArticles({
                payload: {
                    availability: dateRange,
                    type: data.type,
                    platform: data.platform ?? 'APP',
                    buttonTitle: data.buttonTitle,
                    buttonAction: buttonActions.find(el => el.value === data.buttonAction),
                    companyId: data.companyId,
                    articles: data.articles
                        .map(el => ({
                            id: el.articleId ?? create_UUID(),
                            articleId: el.articleId,
                            title: el.title,
                            story: el.story,
                            img: el.image,
                            position: el.position,
                        }))
                        .sort((a, b) => {
                            if (a.position > b.position) return 1;
                            else return -1;
                        }),
                },
            });
            setStatus(data.status);
        } else if (stateIsNotInitialized(editingNewsState) && !isNaN(newsId)) {
            dispatch(getSpecificNewsAction(newsId));
        } else if (stateIsLoaded(editingNewsState) && editingNewsState.data.newsId === null) {
            history.replace('/internal/news/create');
        }
    }, [editingNewsState]);

    const isDataValid = () => {
        let articlesValid = true;
        let superuserSelections = authService.user.authorities[0] === 'ROLE_SUPERADMIN' ? articlesState.type && articlesState.platform : true;

        articlesState.articles.forEach(a => {
            if (!a.title || !a.story) articlesValid = false;
        });
        return articlesState.availability && superuserSelections && articlesValid;
    };

    const save = async event => {
        event.preventDefault();
        let imageResult = {};
        let articles = [];
        let news = {};

        const isSuperAdmin = authService.user.authorities[0] === 'ROLE_SUPERADMIN';
        const isUpdateNews = articlesState.type === 'global' && articlesState.platform === 'WEB';

        if (!isDataValid()) {
            alert(t('Please fill all required fields'));
            return;
        }
        let pos = 1;
        for (let ind in articlesState.articles) {
            let article = {};
            if (articlesState.articles[ind].imageFile != null && articlesState.articles[ind].img !== undefined) {
                let data = new FormData();
                data.set('file', articlesState.articles[ind].imageFile);
                data.set('folder', 'news');
                data.set('replaceIfExists', false);
                data.set('type', articlesState.type ?? 'local');
                imageResult = await saveAssetApiRequest(data);
                article.image = imageResult?.data?.name;
            } else {
                article.image = articlesState.articles[ind].img;
            }
            if (articlesState.articles[ind].articleId) {
                article.articleId = articlesState.articles[ind].articleId;
            }
            article.title = articlesState.articles[ind].title;
            article.story = articlesState.articles[ind].story;
            article.position = pos;
            pos++;
            articles.push(article);
        }
        if (!isNaN(newsId) && stateIsLoaded(editingNewsState) && editingNewsState.data?.newsId !== null) {
            news.newsId = editingNewsState.data.newsId;
        }
        news.buttonText = articlesState.buttonTitle;
        news.buttonAction = articlesState.buttonAction?.value;
        news.validFrom = articlesState.availability[0].getTime();
        news.validUntil = articlesState.availability[1].getTime();
        news.articles = articles;
        news.removedArticleIds = articlesState.removedArticleIds;
        dispatch(
            saveNewsAction(
                news,
                isSuperAdmin ? (isUpdateNews ? 'updates' : articlesState.type) : 'local',
                isSuperAdmin && articlesState.type === 'local' ? articlesState.companyId : null
            )
        );
    };

    function deleteNews() {
        dispatch(deleteNewsAction(deleteNewsItem));
    }

    function goBack() {
        dispatch(resetEditingNewsState());
    }

    if (stateIsLoading(editingNewsState)) {
        return (
            <div style={{ height: '100vh' }} className={'d-flex flex-row align-items-center justify-content-center'}>
                <Circular />
            </div>
        );
    }

    return (
        <Header stateDependencies={[editingNewsState]} title={newsId ? t('Editing news #{{newsId}}', { newsId: newsId }) : t(`Create news`)}>
            <div className="create-news--container">
                <div className="create-news--edit-container">
                    {deleteNewsItem && (
                        <DeleteNewsModal
                            deleteModalOpen={deleteNewsItem}
                            setDeleteModalOpen={_ => setDeleteNewsItem(null)}
                            onAction={deleteNews}
                            onClose={() => setDeleteNewsItem(null)}
                        />
                    )}
                    <div className="create-news--header-actions">
                        <div
                            role="button"
                            id="back-to-news-list"
                            onClick={() => {
                                history.replace('/internal/news');
                            }}
                            className="create-news--header-item"
                        >
                            <FontAwesomeIcon color={'gray'} icon={faArrowLeft} />
                            <Body2 color={colors.gray6} weight="bold">
                                {t('Back to news list')}
                            </Body2>
                        </div>
                        {!isNaN(newsId) && (
                            <div className="create-news--header-item">
                                <div className="create-news--header-item">
                                    <Body2> {t('Status:')}</Body2>
                                    <Body2 weight="bold" color={colors.primaryText}>
                                        {status ? t(status) : t('Not set')}
                                    </Body2>
                                </div>
                                <DefaultButton
                                    text={t('Delete news')}
                                    backgroundColor={colors.white}
                                    textColor={colors.primaryText}
                                    onClick={() => {
                                        setDeleteNewsItem(editingNewsState.data.newsId);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="create-news--edit-items">
                        <CreateAndEditPostSettings articlesState={articlesState} dispatchArticles={dispatchArticles} />
                        <CreateAndEditPostArticles articlesState={articlesState} dispatchArticles={dispatchArticles} />
                    </div>
                    <div className="create-news--action-buttons">
                        <DefaultButton
                            id="add-news-save-button"
                            onClick={event => save(event)}
                            text={t('Save')}
                            textColor={colors.white}
                            style={{ width: 100 }}
                            disabled={stateIsLoading(saveNewsState) || stateIsLoading(deleteNewsState)}
                            className={'mr-2'}
                        />
                        <OutlinedButton
                            id="add-news-cancel-button"
                            onClick={goBack}
                            text={t('Cancel')}
                            style={{ width: 100 }}
                            disabled={stateIsLoading(saveNewsState) || stateIsLoading(deleteNewsState)}
                        />
                        {(stateIsLoading(saveNewsState) || stateIsLoading(deleteNewsState)) && (
                            <CircularProgress className={'ml-2'} style={{ color: colors.mainTheme }} size={25} />
                        )}
                    </div>
                </div>
                <PreviewNews
                    articleCount={articlesState.articles?.length}
                    currentIndex={currentIndex}
                    setIndex={setCurrentIndex}
                    articlesState={articlesState}
                />
            </div>
        </Header>
    );
};
