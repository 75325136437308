import mk from 'assets/icons/languages/mk.svg';
import en from 'assets/icons/languages/en.svg';

export const languages = [
    { label: 'Macedonian', value: 'mk' },
    { label: 'English', value: 'en' },
    { label: 'Serbian', value: 'rs' },
    { label: 'Swedish', value: 'se' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Finnish', value: 'fi' },
];


export const languagesApp = [
    { label: 'Macedonian', value: 'mk' },
    { label: 'English', value: 'en' },
    { label: 'Serbian', value: 'rs' },
];



export const languageToIcon = {
    mk: mk,
    en: en,
};

export const languageToString = {
    mk: 'Macedonian',
    en: 'English',
};
