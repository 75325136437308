import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { colors } from 'style/colors';

export default function InputFieldPagination({ currentPage, totalPages, getQuestionsPage }) {
    const [pageValue, setPageValue] = useState(+currentPage + 1);

    useEffect(() => {
        setPageValue(+currentPage + 1);
    }, [currentPage]);

    const setPage = () => {
        if (!pageValue || isNaN(pageValue) || pageValue > totalPages || pageValue <= 0) {
            setPageValue(+currentPage + 1);
        } else {
            getQuestionsPage(+pageValue - 1);
        }
    };

    return (
        <input
            key="pageInput"
            placeholder={+currentPage + 1}
            value={pageValue}
            onClick={e => e.target.select()}
            onChange={e => setPageValue(e.target.value)}
            onKeyDown={e => {
                if (e.keyCode === 13) {
                    setPage();
                }
            }}
            size={Math.max(pageValue.toString().length, 3)}
            onBlur={setPage}
            style={{
                // width: 30,
                marginRight: '5px',
                textAlign: 'center',
                color: colors.mainTheme,
                backgroundColor: 'white',
                outline: 'none',
                border: 'none',
                fontWeight: 'bold',
                borderRadius: '2px',
            }}
        />
    );
}

InputFieldPagination.propTypes = {
    currentPage: PropTypes.any,
    getQuestionsPage: PropTypes.func,
    totalPages: PropTypes.any,
};
