import { ModalDialog } from "components/features/modal/ModalDialog";
import React, { useState } from "react";
import { colors } from "style/colors";
import { Body1, Body2 } from "style/typography/Body";
import { Caption1 } from "style/typography/Caption";

import AngleDown from 'assets/icons/angle-down.svg';
import AngleUp from 'assets/icons/angle-up.svg';

import './ContentBlocksBuilder.css';

function isImage(element) {
    return element.includes('https');
}

function QuestionItem({ el, i }) {
    const [showAnswers, setShowAnswers] = useState(true);

    return (
        <div className="gen-questions-dialog--item">
            <div onClick={() => setShowAnswers(prevState => !prevState)} className="gen-questions-dialog--question">
                <div className="gen-questions-dialog--index">
                    <Caption1 color={colors.primaryText}>{i + 1}</Caption1>
                </div>
                <Body1>{el.question}</Body1>
                <img style={{ marginLeft: 'auto' }} height={15} width={15} src={showAnswers ? AngleUp : AngleDown} />
            </div>
            {showAnswers && el.answers?.map(answer => {
                return <div className={`gen-questions-dialog--answer ${answer.correct ? 'correct' : ''}`}>
                    <Caption1 color={colors.gray6}>{answer.correct ? 'Correct: ' : 'Incorrect: '}</Caption1>
                    {!isImage(answer.answer) && <Body2 color={colors.dark}> {answer.answer}</Body2>}
                    {isImage(answer.answer) && <img height={40} width={40} src={answer.answer}></img>}
                </div>
            })}
        </div>
    )
}

export default function GeneratedQuestionsListModal({ modalOpen, setModalOpen, generatedQuestions }) {

    return (
        <ModalDialog
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            title={"Generated questions"}
            buttons={[
                {
                    title: 'Confirm',
                    color: colors.white,
                },
            ]}
            width="45vw"
            hasCancel={true}
            alert={
                <div className="gen-questions-dialog">
                    <Caption1 color={colors.gray6}>Total generated: {generatedQuestions?.length} </Caption1>
                    <div className='gen-questions-dialog--container'>
                        {generatedQuestions?.map((el, i) => {
                            return <QuestionItem
                                el={el}
                                i={i}
                            />
                        })}
                    </div>
                </div>
            }
        ></ModalDialog >
    );
}
