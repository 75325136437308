import React, { useEffect, useRef, useState } from 'react';
import { colors } from 'style/colors';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Search from 'assets/icons/filter-search.svg';

export function CustomOutlinedTextField({
    id,
    width,
    height,
    type,
    placeholder,
    setError,
    value,
    setValue,
    defaultValue,
    disabled,
    visibilityIcon,
    isSearch,
    onBlur,
}) {
    const [showValue, setShowValue] = useState(false);

    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        const input = ref.current;
        if (input) input.setSelectionRange(cursor, cursor);
    }, [ref, cursor, value]);

    const handleChange = e => {
        setCursor(e.target.selectionStart);
        setValue && setValue(e.target.value);
    };

    return (
        <div
            className="d-flex flex-row"
            style={{
                backgroundColor: 'transparent',
                border: `1px solid ${colors.gray5}`,
                flex: 1,
                padding: 4,
                paddingLeft: 10,
                justifyContent: 'space-between',
                alignContent: 'center',
                maxWidth: width ? width : 220,
                width: width ? width : 220,
                borderRadius: '3px',
            }}
        >
            <input
                ref={ref}
                id={id}
                defaultValue={defaultValue}
                value={value}
                disabled={disabled}
                onFocus={() => {
                    if (setError) {
                        setError(false);
                    }
                }}
                onBlur={() => {
                    if (onBlur) {
                        onBlur();
                    }
                }}
                type={type === 'password' ? (showValue ? 'text' : 'password') : 'text'}
                placeholder={placeholder}
                onChange={handleChange}
                style={{
                    border: 'none',
                    outline: 'none',
                    height: height ? height : 30,
                    fontWeight: 0,
                    fontSize: 14,
                    width: '100%',
                    color: colors.dark,
                    backgroundColor: 'transparent',
                }}
            />
            <div className="d-flex flex-row align-items-center">
                {isSearch && <img alt="Search" style={{ height: 20, width: 20 }} className={'mr-1'} color={colors.gray1} src={Search} />}
                {visibilityIcon && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowValue(!showValue)}
                            onMouseDown={event => event.preventDefault()}
                        >
                            {showValue ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )}
            </div>
        </div>
    );
}

CustomOutlinedTextField.propTypes = {
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    height: PropTypes.any,
    id: PropTypes.any,
    isSearch: PropTypes.bool,
    onBlur: PropTypes.func,
    placeholder: PropTypes.any,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.any,
    visibilityIcon: PropTypes.any,
    width: PropTypes.any,
};
