import PropTypes from 'prop-types';
import { ModalDialogContent } from './ModalDialogContent';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { colors } from 'style/colors';

export function ModalDialog({
    setModalOpen,
    modalOpen,
    onClose,
    onAction,
    title,
    contentComponent,
    text,
    buttons,
    hasCancel,
    image,
    alert,
    state,
    hasLoader,
    imageSize,
    width,
}) {
    const classes = useStyles();

    function onModalClose() {
        if (onClose) {
            onClose();
            setModalOpen(false);
        } else {
            setModalOpen(false);
        }
    }
    return (
        <Modal
            open={modalOpen}
            className={classes.modal}
            onClose={() => {
                onModalClose();
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div>
                <ModalDialogContent
                    open={modalOpen}
                    hasCancel={hasCancel}
                    image={image}
                    title={title}
                    text={text}
                    contentComponent={contentComponent}
                    buttons={buttons}
                    onAction={onAction}
                    imageSize={imageSize}
                    onClose={onModalClose}
                    alert={alert}
                    state={state}
                    hasLoader={hasLoader}
                    width={width}
                />
            </div>
        </Modal>
    );
}

ModalDialog.propTypes = {
    alert: PropTypes.any,
    buttons: PropTypes.any,
    contentComponent: PropTypes.any,
    hasCancel: PropTypes.bool,
    hasLoader: PropTypes.bool,
    image: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
    state: PropTypes.any,
    text: PropTypes.any,
    title: PropTypes.any,
    width: PropTypes.any,
};

const useStyles = makeStyles(theme => {
    return {
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `solid 1px ${colors.gray4}`,
        },
    };
});
