import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { Subtitle2 } from 'style/typography/Subtitle';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from 'components/elements/Button';

import PhoneIcon from 'assets/icons/icon-admin-device.svg';

import '../UserDeviceRequests.css';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { useSelector } from 'react-redux';
import { linkBuilderFromQuery } from 'services/api/api_builders';
import { ModifyUserDeviceRequestModal } from './modals/ModifyUserDeviceRequestModal';
import { stateIsLoaded } from 'redux/core/stateHelpers';

export default function UserDeviceRequestViewPreview({ deviceRequest, history, setModalOpenAction, setDeleteModalOpen }) {
    const location = useLocation();
    const { t } = useTranslation();

    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [modifyingState, setModifyingState] = useState(null);

    const deviceRequestsForUserState = useSelector(state => state.content.deviceRequestsForUser);
    const deviceRequestsForUser = deviceRequestsForUserState.data;

    const isResolved = deviceRequestsForUser?.requests?.find(el => el.isResolved === false) === undefined;

    useEffect(() => {
        if (stateIsLoaded(deviceRequestsForUserState)) {
            let activeDevice = deviceRequestsForUser.requests?.find(el => el.isApproved)?.requestedDeviceId;
            if (activeDevice) {
                setSelectedDeviceId(activeDevice);
            }
        }
    }, []);

    return (
        <div className="device-req--preview">
            <ModifyUserDeviceRequestModal
                modalOpenAction={modifyingState}
                setModalOpenAction={setModifyingState}
                deviceIds={[{ deviceId: selectedDeviceId, userId: deviceRequestsForUser?.userId }]}
            />
            <div className="device-req--action-header">
                <div
                    id="back-to-feedbacks-button"
                    className="device-req--action-back"
                    role="button"
                    onClick={() => {
                        history.replace({
                            pathname: '/users/user-device-request',
                            search: location?.state?.filters ? linkBuilderFromQuery('', location?.state?.filters) : '',
                        });
                    }}
                >
                    <FontAwesomeIcon color={'gray'} className={'mr-2'} icon={faArrowLeft} />
                    <Body2 color={colors.gray6} className={'mb-0'}>
                        {t('Back to all requests')}
                    </Body2>
                </div>
                <div className="device-req--action-buttons">
                    {!isResolved && (
                        <DefaultButton
                            id="resolve-unresolve-button-modal"
                            text={t('Approve request')}
                            onClick={() => setModifyingState('approve')}
                            disabled={selectedDeviceId === null}
                        />
                    )}
                    {!isResolved && (
                        <DefaultButton
                            id="resolve-unresolve-button-modal"
                            text={t('Reject request')}
                            backgroundColor={colors.white}
                            textColor={colors.dark}
                            disabledTextColor={colors.gray6}
                            onClick={() => setModifyingState('reject')}
                            disabled={selectedDeviceId === null}
                        />
                    )}
                    {isResolved && <DefaultButton id="resolve-unresolve-button-modal" text={t('Delete')} onClick={() => setDeleteModalOpen(true)} />}
                </div>
            </div>
            <div style={{ marginTop: 20 }}>
                <Body2 color={colors.descriptionText}>{t('Requested device ID')}</Body2>
                {deviceRequestsForUser?.requests?.map(el => {
                    let isSelected = selectedDeviceId == el.requestedDeviceId;
                    return (
                        <div className={`device-req--new-id ${(isResolved ? el.isApproved : isSelected) ? 'selected' : ''}`}>
                            <img height={25} width={25} alt="phone" src={PhoneIcon} />
                            <div style={{ width: '90%', minWidth: '90%', maxWidth: '90%' }}>
                                <Subtitle2 color={isResolved && !el.isApproved ? colors.gray1 : colors.dark} weight="regular">
                                    {el.requestedDeviceName}
                                </Subtitle2>
                                <Body2 color={isResolved && !el.isApproved ? colors.gray1 : colors.gray6} weight="regular" style={{ marginTop: 3 }}>
                                    {el.dateReceived}
                                </Body2>
                            </div>
                            <div style={{ height: '100%', width: '100%', marginLeft: 'auto', position: 'relative' }}>
                                <Checkbox
                                    onClick={e => {
                                        setSelectedDeviceId(el.requestedDeviceId);
                                    }}
                                    checked={isResolved ? el.isApproved : isSelected}
                                    circular={true}
                                    disabled={isResolved}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
