import React from 'react';
import { useSelector } from 'react-redux';
import { stateIsNotInitialized } from '../../redux/core/stateHelpers';
import { Redirect } from 'react-router-dom';
import Module from './module';
import SocketsWrapper from 'components/wrappers/WebsocketWrapper';

const Layout = props => {
    const auth = useSelector(state => state.auth);
    const persist = useSelector(state => state._persist);
    
    if (stateIsNotInitialized(auth) && !persist.rehydrated) {
        return <div> Loading .. </div>;
    }

    if (stateIsNotInitialized(auth) && persist.rehydrated) {
        return <Redirect to={'/login'} />;
    }

    return (
        <SocketsWrapper>
            <Module {...props} />
        </SocketsWrapper>
    );
};

export default Layout;
