import { ActionStatus } from '../core/ActionStatus';
import { buildActionType } from './buildActionType';
import { StatusCode } from '../core/StatusCode';
import { LOGIN } from '../constants/auth.constants';

import toast from 'react-hot-toast';
import { CustomToast } from 'components/features/error_handling/toast/CustomToast';

const ApiExecutor = (apiRequest, action) => async dispatch =>
    new Promise(async (resolve, reject) => {
        dispatch({ type: buildActionType(action, ActionStatus.START) });
        let response = await apiRequest;

        if (response.success) {
            if (response?.data?.isSuccessful !== undefined && response.data.isSuccessful !== null) {
                if (response.data.isSuccessful) {
                    dispatch({
                        type: buildActionType(action, ActionStatus.DONE),
                        payload: response.data,
                        responseStatus: response.status,
                    });
                    resolve(response);
                } else {
                    dispatch({
                        type: buildActionType(action, ActionStatus.FAILED),
                        payload: response.data,
                        responseStatus: response.status,
                    });
                    reject(response);
                }
            } else {
                dispatch({
                    type: buildActionType(action, ActionStatus.DONE),
                    payload: response.data,
                    responseStatus: response.status,
                });
                resolve(response);
            }
        } else {
            if (response.status === StatusCode.CONFLICT) {
                dispatch({
                    type: buildActionType(action, ActionStatus.FAILED),
                    data: response.data,
                    responseStatus: response.status,
                });
                toast.custom(t => CustomToast({ t: t, message: response.data?.error }));
            } else {
                dispatch({
                    type: buildActionType(action, ActionStatus.FAILED),
                    payload: response.data,
                    responseStatus: response.status,
                });
                toast.custom(t => CustomToast({ t: t, message: response.data?.error }));
            }
            if (response.status === StatusCode.NOT_AUTHORIZED) {
                dispatch({
                    type: buildActionType(LOGIN, ActionStatus.FAILED),
                    payload: response.data,
                    responseStatus: response.status,
                });
            }
            reject(response);
        }
        return response;
    });

export { ApiExecutor };
