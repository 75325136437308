import PropTypes from 'prop-types';
import React from 'react';
import { Pie, PieChart, Sector } from 'recharts';
import { colors } from 'style/colors';

export function CustomPieChart({ data, height, width, ...rest }) {
    const renderActiveShape = props => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, midAngle } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius - 60) * cos;
        const sy = cy + (outerRadius - 60) * sin;
        return (
            <Sector
                cx={sx}
                cy={sy}
                innerRadius={innerRadius}
                outerRadius={outerRadius + 4}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={colors.mainTheme}
            />
        );
    };

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

        const indexx = 1 - index;
        const x = cx - radius * Math.cos(-midAngle * RADIAN);
        const y = cy - radius * Math.sin(-midAngle * RADIAN);

        const percentInverted = 1 - percent;

        const isCovered = indexx != 1;

        const invertSymbol = isCovered ? -1 : 1;

        const percentPos = {
            x: x + (isCovered ? 71 : 80) * invertSymbol,
            y: y - (isCovered ? 20 : 60) * invertSymbol,
        };

        const textPos = {
            x: x + (isCovered ? 89 : 80) * invertSymbol,
            y: y - (isCovered ? 35 : 45) * invertSymbol,
        };

        if (percentInverted > 0) {
            return (
                <g className="recharts-layer">
                    <circle cx={x} cy={y} r="2.459"></circle>
                    <line
                        fill="none"
                        stroke={colors.dark}
                        className="recharts-curve recharts-pie-label-line"
                        x1={x}
                        y1={y}
                        x2={x + 20 * invertSymbol}
                        y2={y - 40 * invertSymbol}
                    ></line>
                    <line
                        fill="none"
                        stroke={colors.dark}
                        className="recharts-curve recharts-pie-label-line"
                        x1={x + 20 * invertSymbol}
                        y1={y - 40 * invertSymbol}
                        x2={x + 120 * invertSymbol}
                        y2={y - 40 * invertSymbol}
                    ></line>
                    <text
                        fill={isCovered ? colors.mainTheme : colors.gray1}
                        stroke="none"
                        x={x + 80 * invertSymbol}
                        y={y - (isCovered ? 25 : 45) * invertSymbol}
                        alignment-baseline="middle"
                        className="recharts-text recharts-pie-label-text"
                        text-anchor={isCovered ? 'end' : 'start'}
                    >
                        <tspan fontWeight={'bold'} fontSize={14} x={textPos.x - (isCovered ? -15 : 0)} y={textPos.y - 3}>
                            {isCovered ? 'Covered' : 'Left'}
                        </tspan>
                        <tspan
                            fill={isCovered ? colors.mainTheme : colors.darkWithOpacity1}
                            fontWeight={'bold'}
                            fontSize={28}
                            x={percentPos.x}
                            y={percentPos.y - 5}
                        >
                            {`${(percentInverted * 100).toFixed(0)}%`}
                        </tspan>
                    </text>
                </g>
            );
        }
    };

    return (
        <PieChart {...rest} height={height} width={width}>
            <Pie
                startAngle={-270}
                activeIndex={0}
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={65}
                isAnimationActive={false}
                fill={colors.gray3}
                label={renderCustomizedLabel}
                labelLine={false}
                activeShape={renderActiveShape}
            />
        </PieChart>
    );
}

CustomPieChart.propTypes = {
    data: PropTypes.any,
    height: PropTypes.any,
    width: PropTypes.any,
};
