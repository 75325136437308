export function NumberShortener(number) {
    if (number > 1000) {
        return Math.floor(number / 1000) + 'K';
    }
    return number.toString();
}

export function getHoursAndMinutesParsed(minutesFull) {
    let hours = Math.floor(minutesFull / 60);
    let minutes = minutesFull - (hours * 60);
    return hours + "h " + minutes + 'm';
}