import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper/Paper';
import { colors } from 'style/colors';
import { makeStyles } from '@material-ui/core';
import { OutlinedButton } from 'components/elements/Button';
import { Subtitle1 } from 'style/typography/Subtitle';
import { Editor } from 'react-draft-wysiwyg';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import { Caption1 } from 'style/typography/Caption';
import { convertToRaw } from 'draft-js';
import HorizontalMenuPicker from 'components/elements/horizontal_menu_picker/HorizontalMenuPicker';
import { CustomTextField } from 'components/elements/inputs/CustomTextField';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';

import PhoneMockup from 'assets/icons/phone-mockup-black.svg';
import Close from 'assets/icons/filter-close.svg';
import CustomModal from 'components/features/modal/CustomModal.js/CustomModal';
import { removeEmptyLines } from 'services/utils/stringHelpers';

import './InternalLesson.css';
import 'containers/layout/module/pages/content/trainings/local_components/side_menu/learn_preview/LearnPreview.css';
import { useTranslation } from 'react-i18next';

export function InternalLessonEditorDialog({ modalOpen, onModalClose, editorState, lessonContent, onEditorStateChange, toolbar, title }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const topicContentState = useSelector(state => state.content.topics.topicContent);

    const htmlLessonContent = typeof lessonContent === 'string' ? lessonContent : draftToHtml(convertToRaw(lessonContent.getCurrentContent()));

    const [firstTabSelected, setFirstTabSelected] = useState(true);

    function ErrorFallback({ error, resetErrorBoundary }) {
        return (
            <div>
                <Caption1 style={{ marginBottom: 10 }} weight="medium" color={colors.dark}>
                    {t('There is an error in your sintax:')}
                    <br />
                </Caption1>
                <Caption1 style={{ marginBottom: 10 }} color={colors.red}>
                    {error.message}
                </Caption1>
                <Caption1 style={{ marginBottom: 10 }} weight="medium" color={colors.dark}>
                    {t('Fix the error and try again')}
                    <br />
                </Caption1>
                <OutlinedButton text={t('Refresh')} onClick={resetErrorBoundary} />
            </div>
        );
    }

    function process(str) {
        var div = document.createElement('div');
        div.innerHTML = str.trim();

        return removeEmptyLines(format(div, 0).innerHTML);
    }

    function format(node, level) {
        var indentBefore = new Array(level++ + 1).join('  '),
            indentAfter = new Array(level - 1).join('  '),
            textNode;

        for (var i = 0; i < node.children.length; i++) {
            textNode = document.createTextNode('\n' + indentBefore);
            node.insertBefore(textNode, node.children[i]);

            format(node.children[i], level);

            if (node.lastElementChild == node.children[i]) {
                textNode = document.createTextNode('\n' + indentAfter);
                node.appendChild(textNode);
            }
        }

        return node;
    }

    function resetLesson() {
        onEditorStateChange(topicContentState.data?.lessonContent ?? '');
    }

    return (
        <CustomModal
            modalOpen={modalOpen}
            setModalOpen={_ => {
                onModalClose();
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 20 }}>
                <Paper style={{ width: '55vw', border: `solid 1px ${colors.adsdsa.gray4}` }} className={`${classes.paper}`}>
                    <div className="col-12 d-flex flex-row p-3">
                        <div className="d-flex flex-column">
                            <Subtitle1 weight="medium">{t('Internal lesson editor of {{title}}', { title: title })}</Subtitle1>
                        </div>
                        <img
                            alt={t('Close')}
                            onClick={() => {
                                onModalClose();
                            }}
                            className={'ml-auto cursor-pointer'}
                            height={17}
                            width={17}
                            src={Close}
                        />
                    </div>
                    <div className="lesson-editor--body">
                        <HorizontalMenuPicker
                            firstElement={t('Rich editor')}
                            secondElement={t('HTML editor')}
                            firstElementSelected={firstTabSelected}
                            setFirstElementSelected={setFirstTabSelected}
                        />
                        <div className="lesson-editor--body-content">
                            {firstTabSelected && (
                                <div className="lesson-editor--editor">
                                    <Editor
                                        editorState={typeof lessonContent === 'string' ? editorState : lessonContent}
                                        initialEditorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        editorStyle={{
                                            height: '65vh',
                                            maxHeight: '65vh',
                                            minHeight: '65vh',
                                            width: '50vw',
                                            maxWidth: '50vw',
                                        }}
                                        toolbar={toolbar}
                                        onEditorStateChange={onEditorStateChange}
                                        stripPastedStyles={true}
                                    />
                                </div>
                            )}
                            {!firstTabSelected && (
                                <div className="lesson-editor--html">
                                    <div className="lesson-editor--html-options">
                                        <OutlinedButton text={t('Format')} onClick={() => onEditorStateChange(process(htmlLessonContent))} />
                                        <OutlinedButton text={t('Reset')} onClick={resetLesson} />
                                    </div>
                                    <CustomTextField
                                        height={'70vh'}
                                        width={'50vw'}
                                        value={htmlLessonContent}
                                        setValue={val => onEditorStateChange(val)}
                                        multiline={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {/* <div className={'col-12 pr-3 pl-3 d-flex flex-row justify-content-center mt-4 mb-3'}>
                    <DefaultButton id="edit-tag-save-button" text="Done" onClick={onModalClose} className={'mr-1'} />
                </div> */}
                </Paper>
                <div className="lesson-editor--preview">
                    <div className="preview--phone">
                        <img alt="Phone mockup" src={PhoneMockup} width={250}></img>
                        <div className="preview--phone-inner">
                            <div className="preview--phone-header">
                                <div className="preview--header-title">
                                    <Caption1>{title}</Caption1>
                                </div>
                            </div>
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                <div className="preview--content">{parse(htmlLessonContent)}</div>
                            </ErrorBoundary>
                        </div>
                    </div>
                    <Caption1 style={{ marginBottom: 5 }} weight="medium" color={colors.gray6}>
                        {t('Preview')}
                    </Caption1>
                </div>
            </div>
        </CustomModal>
    );
}

InternalLessonEditorDialog.propTypes = {
    editedTag: PropTypes.any,
    editorState: PropTypes.any,
    lessonContent: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onEditorStateChange: PropTypes.func,
    onModalClose: PropTypes.func,
    title: PropTypes.any,
    toolbar: PropTypes.any,
};

const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: colors.gray4,
            border: '1px solid #000',
            boxShadow: theme.shadows[5],
        },
    };
});
