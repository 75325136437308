import PropTypes from 'prop-types';
import React from 'react';
import { H4 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { DefaultButton } from 'components/elements/Button';
import { colors } from 'style/colors';

import NewsEmptyIcon from 'assets/icons/icon-emptystate-news.svg';
import { useTranslation } from 'react-i18next';

export default function NewsEmptyState({ createNews }) {
    const { t } = useTranslation();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <img alt="empty-icon" src={NewsEmptyIcon} height={100} width={100} />
            <H4 weight="light" color={colors.primaryText}>
                {t('Add your first news post')}
            </H4>
            <Body2 color={colors.secoundaryText} weight="light" style={{ padding: '10px 0 30px 0' }}>
                {t('If you want to inform your players or make an announcment in the app, add a post here.')}
            </Body2>
            <DefaultButton text={t('Create post')} style={{ width: 150 }} onClick={createNews} />
        </div>
    );
}

NewsEmptyState.propTypes = {
    createNews: PropTypes.func,
};
