import React, { useEffect, useState } from 'react';

import { colors } from 'style/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import AngleLeft from 'assets/icons/angle-left.svg';
import AngleRight from 'assets/icons/angle-right.svg';
import { Body2 } from 'style/typography/Body';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { stateIsLoaded } from 'redux/core/stateHelpers';
import { linkBuilderFromQuery } from 'services/api/api_builders';
import { fetchUsersPaginatedAction, resetFetchUsersPaginatedAction } from 'redux/actions/users.actions';
import { useTranslation } from 'react-i18next';

export default function UserProfileNavigation({ id }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    const usersPaginatedState = useSelector(state => state.userManagement.usersPaginated);

    const [hasPrevUser, setHasPrevUser] = useState(false);
    const [hasNextUser, setHasNextUser] = useState(false);

    const [getNext, setGetNext] = useState(false);
    const [getPrev, setGetPrev] = useState(false);

    const navigateToUser = userId => {
        history.replace(`/user/${userId}`, { filters: location?.state?.filters });
    };

    function getHasPrevOrNextStates(indxOfCurrentUser) {
        setHasPrevUser(usersPaginatedState.data.number > 0 || indxOfCurrentUser != 0);
        setHasNextUser(
            indxOfCurrentUser + 1 < usersPaginatedState.data.content.length ||
                usersPaginatedState.data.number + 1 < usersPaginatedState.data.totalPages
        );
    }

    useEffect(() => {
        if (stateIsLoaded(usersPaginatedState)) {
            let indxOfCurrentUser = usersPaginatedState.data?.content?.findIndex(el => el.id == id) ?? 0;
            getHasPrevOrNextStates(indxOfCurrentUser);
        }

        if (stateIsLoaded(usersPaginatedState) && usersPaginatedState.data && (getNext || getPrev)) {
            setGetNext(false);
            setGetPrev(false);
            let nextIndex = getNext ? 0 : usersPaginatedState.data.size - 1;
            navigateToUser(usersPaginatedState.data.content[nextIndex].id);
        }
    }, [usersPaginatedState]);

    const goBackFunction = () => {
        history.replace({
            pathname: '/users/organization',
            search: location?.state?.filters ? linkBuilderFromQuery('', location?.state?.filters) : '',
        });
    };

    function getNextUser(nav) {
        if (stateIsLoaded(usersPaginatedState)) {
            let indxOfCurrentUser = usersPaginatedState.data?.content?.findIndex(el => el.id == id) ?? 0;
            if (nav == 'next') {
                if (indxOfCurrentUser == usersPaginatedState.data.size - 1) {
                    dispatch(resetFetchUsersPaginatedAction());
                    dispatch(fetchUsersPaginatedAction({ ...location?.state?.filters, page: usersPaginatedState.data.number + 1 }));
                    setGetNext(true);
                } else {
                    navigateToUser(usersPaginatedState.data.content[indxOfCurrentUser + 1].id);
                    getHasPrevOrNextStates(indxOfCurrentUser + 1);
                }
            } else if (nav == 'prev') {
                if (indxOfCurrentUser == 0) {
                    dispatch(resetFetchUsersPaginatedAction());
                    dispatch(fetchUsersPaginatedAction({ ...location?.state?.filters, page: usersPaginatedState.data.number - 1 }));
                    setGetPrev(true);
                } else {
                    navigateToUser(usersPaginatedState.data.content[indxOfCurrentUser - 1].id);
                    getHasPrevOrNextStates(indxOfCurrentUser - 1);
                }
            }
        }
    }

    return (
        <div className="d-flex flex-row ml-5 mb-3 w-100 justify-content-between">
            <div role="button" onClick={goBackFunction} className="d-flex flex-row align-items-center w-50">
                <FontAwesomeIcon color={'gray'} className={'mr-2'} icon={faArrowLeft} />
                <Body2 color={colors.gray6} className={'mb-0'}>
                    {t('Back to users list')}
                </Body2>
            </div>
            <div className="d-flex flex-row" style={{ marginRight: '50px' }}>
                {hasPrevUser && (
                    <div role="button" onClick={() => getNextUser('prev')} className="d-flex flex-row align-items-center ml-5">
                        <img alt="Prev" className="mr-2" src={AngleLeft} height={17} width={17} />
                        <Body2 color={colors.gray6} className={'mb-0'}>
                            {t('Prev User')}
                        </Body2>
                    </div>
                )}
                {hasNextUser && (
                    <div role="button" onClick={() => getNextUser('next')} className="d-flex flex-row align-items-center ml-5">
                        <Body2 color={colors.gray6} className={'mb-0 mr-2'}>
                            {t('Next User')}
                        </Body2>
                        <img alt="Next" src={AngleRight} height={17} width={17} />
                    </div>
                )}
            </div>
        </div>
    );
}
