export function linkBuilderFromQuery(link, queriesList) {
    let filtersString = '?';
    for (let query in queriesList) {
        if (queriesList[query]) {
            if (filtersString.length > 1) filtersString = filtersString + '&';
            filtersString = filtersString + query + '=' + queriesList[query];
        }
    }
    return link + (filtersString.length > 1 ? filtersString : '');
}
