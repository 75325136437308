import React, { useRef, useEffect } from 'react';

const InsideComponent = ({ children, onOutsideClick = () => {}, onOutsideMouseOver = () => {}, style }) => {
    let node = useRef();
    useEffect(() => {
        const handleClick = e => {
            if (node.current.contains(e.target)) {
                return;
            }
            onOutsideClick(e);
        };
        const handleMouseOver = e => {
            if (node.current.contains(e.target)) {
                return;
            }
            onOutsideMouseOver(e);
        };
        const handleEscape = e => {
            e.stopPropagation();
            if (e.keyCode === 27) {
                onOutsideClick(e);
            }
        };
        document.addEventListener('keydown', handleEscape);
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('mouseover', handleMouseOver);

        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('mouseover', handleMouseOver);
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onOutsideClick, onOutsideMouseOver]);

    return (
        <div ref={node} style={style}>
            {children}
        </div>
    );
};

export default InsideComponent;
