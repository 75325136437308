import React from 'react';
import PropTypes from 'prop-types';
import { useThemePalette } from 'style/theme/consumers';

const InfoTooltip = ({ message, position = 'center', width = '250px', marginRelative = 10 }) => {
    const palette = useThemePalette();

    return (
        <div
            style={{
                position: 'absolute',
                width: width,
                marginTop: '20px',
                backgroundColor: palette.secondary.main,
                color: 'white',
                padding: '10px 15px',
                left: position === 'center' ? '50%' : position === 'left' ? marginRelative : 'unset',
                right: position === 'right' ? marginRelative : 'unset',
                zIndex: 999,
                transform: position === 'center' ? 'translateX(-50%)' : '',
                // borderRadius: '5px',
                fontSize: '14px',
                fontWeight: 'light',
            }}
        >
            <div
                style={{
                    width: '20px',
                    height: '20px',
                    transform: 'rotate(45deg) translateX(-50%)',
                    position: 'absolute',
                    backgroundColor: palette.secondary.main,
                    top: '0',
                    right: position === 'right' ? 0 : 'unset',
                    zIndex: -1,
                }}
            ></div>
            {message}
        </div>
    );
};

InfoTooltip.propTypes = {
    message: PropTypes.any,
    position: PropTypes.string,
};

export default InfoTooltip;
