import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import Collapse from '@material-ui/core/Collapse/Collapse';
import Alert from '@material-ui/lab/Alert/Alert';
import { colors } from 'style/colors';
import { createCompanyWithCodeAction } from 'redux/actions/companies.actions';
import { useTranslation } from 'react-i18next';
import { H7 } from 'style/typography/Heading';
import { Body2 } from 'style/typography/Body';
import { AnimatedTextField } from 'components/elements/inputs/AnimatedTextField';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { DefaultButton } from 'components/elements/Button';
import Checkbox from 'components/elements/checkbox/Checkbox';
import { languagesApp } from 'static/language';

import Icon from 'assets/icons/coach-logo-black.svg';

import './CreateCompanyWithCode.css';

export const CreateCompanyWithCode = () => {
    const { code } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [industry, setIndustry] = useState('');
    const [email, setEmail] = useState('');
    const [chosenLanguage, setChosenLanguage] = useState('en');
    const [activationCode, setActivationCode] = useState(code !== undefined ? code : '');
    const [error, setError] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [inputChecked, setInputChecked] = useState(false);
    const createCompanyState = useSelector(state => state.content.createCompanyWithCode);

    function createCompany() {
        if (name === '' || industry === '') {
            setError(true);
            setValidationMessage(t("You must enter your company's name and industry"));
        } else if (email.length < 5 && !email.includes('@')) {
            setError(true);
            setValidationMessage(t('You must enter valid email address.'));
        } else if (code === '') {
            setError(true);
            setValidationMessage(t('You must enter activation code.'));
        } else if (chosenLanguage == null) {
            setCheckboxError(true);
            setValidationMessage(t('You must choose default language for the company.'));
        } else if (!inputChecked) {
            setCheckboxError(true);
            setValidationMessage(t('You must agree to our terms and conditions before continuing'));
        } else {
            dispatch(
                createCompanyWithCodeAction({
                    name: name,
                    industry: industry,
                    code: activationCode,
                    email: email,
                    defaultLanguage: chosenLanguage,
                })
            );
        }
    }

    useEffect(() => {
        if (stateIsLoaded(createCompanyState)) {
            history.push('/companies/create_with_code/success');
        }
    }, [history, createCompanyState]);

    return (
        <div className="create-company">
            <img alt="logo" src={Icon} width={70} height={70}></img>
            <H7 style={{ marginTop: 30 }} color={colors.gray6}>
                {t('Welcome to Coach Panel!')}
            </H7>
            <Body2 style={{ maxWidth: '35vw', marginTop: 30 }}>{t('Fill in the information below to create a brand new company in Coach!')}</Body2>
            <div className="create-company--form">
                <AnimatedTextField
                    label={t('Company name')}
                    onFocus={() => {
                        setError(false);
                        setValidationMessage('');
                    }}
                    value={name}
                    setValue={val => setName(val)}
                />
                <AnimatedTextField
                    label={t('Industry')}
                    onFocus={() => {
                        setError(false);
                        setValidationMessage('');
                    }}
                    value={industry}
                    setValue={val => setIndustry(val)}
                />
                <AnimatedTextField
                    label={t('Activation code')}
                    onFocus={() => {
                        setError(false);
                        setValidationMessage('');
                    }}
                    value={activationCode}
                    setValue={val => setActivationCode(val)}
                />
                <AnimatedTextField
                    label={t('Email')}
                    onFocus={() => {
                        setError(false);
                        setValidationMessage('');
                    }}
                    value={email}
                    setValue={val => setEmail(val)}
                />
                <div>
                    <label style={{ fontSize: 15, color: colors.secoundaryText }} htmlFor="">
                        {t('Default language')}
                    </label>
                    <CustomSelect
                        options={languagesApp}
                        width={300}
                        onChange={option => setChosenLanguage(option ? option.value : null)}
                        isClearable={true}
                        value={chosenLanguage ? languagesApp.filter(option => option.value === chosenLanguage) : null}
                        placeholder={t('Choose language')}
                    />
                </div>
                <Collapse in={error || checkboxError}>
                    <Alert severity="error">{validationMessage}</Alert>
                </Collapse>
            </div>

            <div className="create-company--actions">
                <div className="create-company--terms">
                    <div style={{ position: 'relative', height: '20px', width: '20px' }}>
                        <Checkbox
                            onClick={() => {
                                setCheckboxError(false);
                                setInputChecked(!inputChecked);
                            }}
                            checked={inputChecked}
                            disabled={stateIsLoading(createCompanyState)}
                        ></Checkbox>
                    </div>
                    <Body2 color={colors.gray6}>{t('I have read and agree to the Coach® platform’s Terms and conditions and Privacy Policy.')}</Body2>
                </div>
                <DefaultButton
                    style={{ width: '150px' }}
                    text={t('Create company')}
                    disabled={stateIsLoading(createCompanyState)}
                    onClick={createCompany}
                    textColor="white"
                />
            </div>
        </div>
    );
};
