import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { Subtitle2 } from 'style/typography/Subtitle';
import { useTranslation } from 'react-i18next';

const UsersInvitationHeader = ({ onTabChange, tabs, position, importTabHidden = false }) => {
    // const tabs = ['Invite by e-mail', 'Import list'];

    const { t } = useTranslation();

    const [value, setValue] = React.useState(0);
    const classes = useStyles();

    useEffect(() => {
        if (importTabHidden) {
            setValue(0);
            onTabChange(0);
        }
    }, [importTabHidden, onTabChange]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onTabChange(newValue);
    };

    return (
        <Paper className={classes.paper}>
            <Tabs
                classes={{ root: classes.tabs, flexContainer: position === 'left' ? classes.leftTabsFlexContainer : classes.tabsFlexContainer }}
                value={value}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs &&
                    tabs.map(el => {
                        return <Tab label={<Subtitle2>{t(el)} </Subtitle2>} />;
                    })}
            </Tabs>
        </Paper>
    );
};

const useStyles = makeStyles(theme => {
    return {
        paper: {
            boxShadow: 'none',
            borderBottom: `1px solid ${theme.palette.border.separator}`,
            backgroundColor: theme.palette.background.main,
        },
        tabs: {},
        tabsFlexContainer: {
            justifyContent: 'center',
        },
        leftTabsFlexContainer: {
            justifyContent: 'left',
        },
    };
});

export default UsersInvitationHeader;
