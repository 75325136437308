import PropTypes from 'prop-types';
import React from 'react';

import { colors } from 'style/colors';
import { Body1, Body2 } from 'style/typography/Body';
import Edit from 'assets/icons/icon-edit.svg';
import { useTranslation } from 'react-i18next';
import { H6 } from 'style/typography/Heading';
import { ErrorBoundaryComponent } from 'components/features/error_handling/error_boundary/ErrorBoundaryComponent';

import './SideMenu.css';

export default function SideMenu({
    hasData,
    isEditingInput,
    setEditingInput,
    inputKey,
    defaultValue,
    inputOnBlur,
    inputOnChange,
    captionText,
    tabsSidemenu,
    tabsMapping,
    activeTab,
    setActiveTab,
    noDataText,
    noDataImg,
    noDataTitle,
}) {
    const { t } = useTranslation();

    return (
        <div className={'col-6 sidemenu--wrap'}>
            {!hasData && (
                <div className="sidemenu--empty-container">
                    {noDataImg && <img alt="no-data" src={noDataImg} height={90} width={90} />}
                    <H6 style={{ padding: '10px 0' }} weight="light" color={colors.primaryText}>
                        {t(noDataTitle)}
                    </H6>
                    <Body2 color={colors.gray6}>{t(noDataText)}</Body2>
                </div>
            )}

            {hasData && (
                <div className="d-flex flex-column h-100 w-100" id="right-side">
                    <div className={'sidemenu--title'}>
                        <div className={'d-flex flex-row align-content-center'}>
                            <input
                                key={inputKey}
                                defaultValue={defaultValue}
                                disabled={!isEditingInput}
                                size={defaultValue?.length}
                                autoFocus={isEditingInput}
                                onBlur={inputOnBlur}
                                onChange={inputOnChange}
                                style={{
                                    outline: isEditingInput ? `1px solid ${colors.gray3}` : `1px solid ${colors.background}`,
                                    maxWidth: '30vw',
                                }}
                                className={'font-weight-bold title'}
                            ></input>
                            <img
                                alt="Edit"
                                src={Edit}
                                height={17}
                                width={17}
                                className={'ml-2 mt-1 cursor-pointer'}
                                onClick={() => setEditingInput(prevValue => !prevValue)}
                            />
                        </div>
                        <Body2 color={colors.descriptionText} className="ml-1">
                            {captionText}
                        </Body2>
                    </div>

                    <div className={'sidemenu--tab-container'}>
                        {tabsSidemenu.map(tab => {
                            return (
                                <Body1
                                    id={'training-settings-' + tab.type}
                                    key={tab.type}
                                    onClick={() => {
                                        setActiveTab(tab.type);
                                    }}
                                    color={tab.type === activeTab ? colors.dark : colors.secoundaryText}
                                    weight={tab.type === activeTab ? 'medium' : 'regular'}
                                    style={tab.type === activeTab ? tabsStyle.active : tabsStyle.inactive}
                                    className={'px-4 pt-2'}
                                >
                                    {t(tab.name)}
                                </Body1>
                            );
                        })}
                    </div>
                    <div className={'mt-3 ml-5'} style={{ height: '94%', width: '94%' }}>
                        <ErrorBoundaryComponent key={activeTab}>{tabsMapping[activeTab]}</ErrorBoundaryComponent>
                    </div>
                </div>
            )}
        </div>
    );
}

SideMenu.propTypes = {
    activeTab: PropTypes.any,
    defaultValue: PropTypes.any,
    hasData: PropTypes.any,
    inputKey: PropTypes.any,
    inputOnBlur: PropTypes.func,
    inputOnChange: PropTypes.func,
    isEditingInput: PropTypes.bool,
    setActiveTab: PropTypes.func,
    setEditingInput: PropTypes.func,
    tabsMapping: PropTypes.object,
    tabsSidemenu: PropTypes.array,
};

const tabsStyle = {
    active: {
        borderLeft: `0.3px solid ${colors.gray5}`,
        borderRight: `0.3px solid ${colors.gray5}`,
        borderTop: `3px solid ${colors.mainTheme}`,
        borderBottom: `0.3px solid ${colors.gray4}`,
        marginTop: '-3px',
        cursor: 'pointer',
        paddingBottom: 5,
    },
    inactive: {
        borderBottom: `0.3px solid ${colors.gray5}`,
        cursor: 'pointer',
    },
};
