import React from 'react';
import PropTypes from 'prop-types';
import InputContent from 'components/features/content_management/inputs/InputContent';
import InputNumericalAnswer from 'components/features/content_management/inputs/InputNumericalAnswer';
import ValidationPopup from 'components/features/forms/validation/ValidationPopup';
import { validatorCategories } from 'services/validation-module/local-constants';

export default function NumbersInput({ initializeQuestion, game, id, filters }) {
    return (
        <InputContent initializeQuestion={initializeQuestion} game={game} id={id} filters={filters}>
            <div className="mt-4">
                <InputNumericalAnswer />
                <ValidationPopup category={validatorCategories.SINGLE_ANSWER_NOT_EMPTY} position="left" />
            </div>
        </InputContent>
    );
}

NumbersInput.propTypes = {
    filters: PropTypes.any,
    game: PropTypes.any,
    id: PropTypes.any,
    initializeQuestion: PropTypes.func,
};
