import React from 'react';
import PropTypes from 'prop-types';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { contentActionsOptions } from 'static/feedback_constants';

import IconTopicPickerNotSelected from 'assets/icons/icon-topicpicker-notselected.svg';
import IconTopicPickerSelected from 'assets/icons/icon-topicpicker-selected.svg';
import { useTranslation } from 'react-i18next';

export default function FeedbackTableActions({ currentActionOption, totalFeedbacksSelected, type, onChange }) {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-d-row justify-content-center align-items-center" style={{ zIndex: 10000 }}>
            <div className="d-flex flex-row align-items-center">
                <Caption1 style={{ fontSize: 15 }} weight={'bold'}>
                    {totalFeedbacksSelected}
                </Caption1>
                <Caption1 color={colors.gray6} style={{ padding: '10px 10px', borderRight: `1px solid ${colors.gray1}` }}>
                    {t('feedbacks selected')}
                </Caption1>
            </div>
            <div className="d-flex flex-d-row align-items-center" style={{ marginLeft: 20, width: 500 }}>
                <CustomSelect
                    id="add-feedback-category"
                    width={200}
                    isClearable={false}
                    value={null}
                    onChange={onChange}
                    options={type === 'content' ? contentActionsOptions(t) : []}
                    placeholder={t('Select action')}
                    iconSelected={IconTopicPickerSelected}
                    iconNotSelected={IconTopicPickerNotSelected}
                    style={{ width: 200, flex: 1 }}
                />
            </div>
        </div>
    );
}

FeedbackTableActions.propTypes = {
    currentActionOption: PropTypes.any,
    onChange: PropTypes.func,
    totalFeedbacksSelected: PropTypes.any,
    type: PropTypes.string,
};
