import PropTypes from 'prop-types';
import { colors } from 'style/colors';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React from 'react';

export function DeleteNotificationModal({ modalOpen, setModalOpen, onAction, onClose }) {
    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => {
                setModalOpen(false);
                onAction();
            }}
            onClose={onClose}
            title={'Are you sure you want to delete this notification?'}
            buttons={[
                {
                    title: 'Delete',
                    color: colors.white,
                },
            ]}
            hasCancel={true}
        ></ModalDialog>
    );
}

DeleteNotificationModal.propTypes = {
    bulkQuestionSize: PropTypes.any,
    currentActionString: PropTypes.any,
    modalOpen: PropTypes.any,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    setModalOpen: PropTypes.func,
};
