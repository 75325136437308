import React from 'react';
import PropTypes from 'prop-types';
import { CustomSelect } from 'components/elements/selector/CustomSelector';
import { colors } from 'style/colors';
import { Caption1 } from 'style/typography/Caption';
import { userActionsOptions, userActionsOptionsWithResend } from 'static/table_select';
import { useTranslation } from 'react-i18next';

export default function UsersTableActions({
    currentActionOption,
    totalSelectedItems,
    setIsTagModalOpen,
    setIsRemoveTagModalOpen,
    setIsRolesModalOpen,
    setIsRemoveRolesModalOpen,
    setIsResendInvitesModalOpen,
    filters,
}) {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-d-row justify-content-center align-items-center" style={{ zIndex: 1000 }}>
            <div className="d-flex flex-row align-items-center">
                <Caption1 style={{ fontSize: 15 }} weight={'bold'}>
                    {totalSelectedItems}
                </Caption1>
                <Caption1 color={colors.gray6} style={{ padding: '10px 10px', borderRight: `1px solid ${colors.gray1}` }}>
                    {t('users selected')}
                </Caption1>
            </div>
            <div style={{ marginLeft: 20, width: '300px' }}>
                <CustomSelect
                    id="users-selection-action"
                    value={null}
                    width={200}
                    isClearable={false}
                    onChange={option => {
                        if (option?.value === userActionsOptions(t)[0]?.value) {
                            setIsTagModalOpen(true);
                        } else if (option?.value === userActionsOptions(t)[1]?.value) {
                            setIsRemoveTagModalOpen(true);
                        } else if (option?.value === userActionsOptions(t)[2]?.value) {
                            setIsRolesModalOpen(true);
                        } else if (option?.value === userActionsOptions(t)[3]?.value) {
                            setIsRemoveRolesModalOpen(true);
                        } else if (option?.value === userActionsOptionsWithResend(t)[4]?.value) {
                            setIsResendInvitesModalOpen(true);
                        }
                    }}
                    options={filters.status === 'pending' ? userActionsOptionsWithResend(t) : userActionsOptions(t)}
                    placeholder={t('Select action')}
                    style={{ width: '100%', flex: 1, zIndex: 10 }}
                />
            </div>
        </div>
    );
}

UsersTableActions.propTypes = {
    currentActionOption: PropTypes.any,
    setIsTagModalOpen: PropTypes.func,
    totalSelectedItems: PropTypes.any,
    filters: PropTypes.any,
};
