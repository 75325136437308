import { actionTypes } from './local-actions';
import { optionDefaults } from './local-constants';
import { cloneProperties } from 'services/utils/objectHelpers';
import { INITIAL_STATE } from './local-constants';
import _ from 'lodash';

export const insertionControlReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.updateState: {
            return { ...action.payload };
        }
        case actionTypes.setCurrentType: {
            return { ...state, currentType: action.payload.currentType };
        }
        case actionTypes.setQuestion: {
            return { ...state, question: action.payload };
        }
        case actionTypes.setOptions: {
            return { ...state, options: [...action.payload], indicativeIndex: action.payload.length };
        }
        case actionTypes.setExtra: {
            return { ...state, extra: action.payload ? { ...state.extra, ...action.payload } : {} };
        }
        case actionTypes.updateOption: {
            let newState = { ...state };
            let option = newState.options[action.payload.index];
            cloneProperties(action.payload.element, option);
            newState.options = [...newState.options]; //This is required - TRUST ME
            return { ...newState };
        }
        case actionTypes.insertOption: {
            return {
                ...state,
                indicativeIndex: +state.indicativeIndex + 1,
                options: [
                    ...state.options,
                    {
                        ...optionDefaults.default,
                        id: +state.indicativeIndex + 1,
                        position: +state.indicativeIndex + 1,
                        text: state.currentType === 'compare_visual' && state.extra?.criteria === 'boolean' ? '0' : null,
                    },
                ],
            };
        }
        case actionTypes.removeOption: {
            let answerId = state.options[action.payload].answerId;
            let options = state.options.filter((_, ind) => ind !== +action.payload);
            return {
                ...state,
                removedAnswers: answerId ? [...state.removedAnswers, answerId] : [...state.removedAnswers],
                options: [...options],
            };
        }
        case actionTypes.addImagesToCategory: {
            let newState = { ...state };
            let option = newState.options[action.payload.index];
            option.images = action.payload.images;
            option.croppedImages = action.payload.croppedImages;
            newState.options = [...newState.options];
            return { ...newState };
        }
        case actionTypes.addImageToOption: {
            let newState = { ...state };
            let option = newState.options[action.payload.index];

            option.images = [...option.images, action.payload.image];
            newState.options = [...newState.options];
            return { ...newState };
        }
        case actionTypes.replaceImageInOption: {
            let newState = { ...state };
            let option = newState.options[action.payload.index];
            option.images = [action.payload.image];
            newState.options = [...newState.options];
            return { ...newState };
        }
        case actionTypes.setCategoryId: {
            return { ...state, chosenCategoryId: action.payload };
        }

        case actionTypes.setTrainingSet: {
            return { ...state, selectedTraining: action.payload };
        }

        case actionTypes.setDateAvailableFrom: {
            return { ...state, dateAvailableFromMills: action.payload };
        }

        case actionTypes.setStatus: {
            return { ...state, status: action.payload };
        }
        case actionTypes.removeImageFromOption: {
            let newState = { ...state };
            newState.options[action.payload.index].images = [];
            newState.options = [...newState.options];
            return { ...newState };
        }
        case actionTypes.removeSingleImageFromOption: {
            let newState = { ...state };
            newState.options = newState.options.map((el, index) => {
                if (index == action.payload.index) {
                    return { ...el, images: el.images.filter((_, index) => index != action.payload.imageIndex) };
                } else return el;
            });
            return { ...newState };
        }

        case actionTypes.resetState: {
            return {
                currentType: state.currentType,
                ...INITIAL_STATE,
            };
        }

        case actionTypes.resetAnswers: {
            return {
                ...state,
                options: [],
            };
        }
        case actionTypes.resetCheckboxes: {
            return { ...state, options: state.options.map(op => ({ ...op, isCorrect: false })) };
        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};
