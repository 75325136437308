import { actionTypes } from './local-actions';

export const validationStateReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.show: {
            state[action.payload.category] = { isVisible: true, message: action.payload.message, index: action.payload.index };
            return { ...state };
        }
        case actionTypes.hide: {
            state[action.payload.category] = { isVisible: false, message: '', index: null };
            return { ...state };
        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};
