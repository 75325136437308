import { colors } from 'style/colors';
import React, { useState } from 'react';
import { Body1 } from 'style/typography/Body';
import { Subtitle2 } from 'style/typography/Subtitle';

import InfoTooltipIcon from 'assets/icons/info-tooltip.svg';
import { useTranslation } from 'react-i18next';
import InfoTooltip from 'components/elements/tooltips/InfoTooltip';

export function AnalyticsContainer({
    title,
    description,
    twoSided,
    secondTitle,
    secondDescription,
    children,
    customColWidth,
    infoString = 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
    // hovering,
}) {
    const { t } = useTranslation();
    const [hovering, setHovering] = useState('');
    return (
        <div style={{ backgroundColor: colors.white }} className={`${customColWidth ? customColWidth : 'col-8'} mt-5 rounded p-3`}>
            <div className="d-flex flex-row">
                <div style={{ width: twoSided ? '50%' : '100%' }} className="d-flex flex-column mb-4 px-3">
                    <Subtitle2 weight="bold">{title}</Subtitle2>
                    <Body1 className={'text-muted mt-2'}>{description}</Body1>
                </div>
                {twoSided && (
                    <div className="d-flex flex-column mr-0 px-3">
                        <Subtitle2 weight="bold">{secondTitle}</Subtitle2>
                        <Body1 className={'text-muted mt-2'}>{secondDescription}</Body1>
                    </div>
                )}

                <div style={{marginLeft:'auto'}}>
                    <img
                        alt="Info Tooltip"
                        src={InfoTooltipIcon}
                        className="ml-auto cursor-pointer"
                        height={20}
                        width={20}
                        onMouseEnter={() => setHovering(title)}
                        onMouseLeave={() => setHovering('')}
                    />
                    {hovering === title && <InfoTooltip visible={hovering === title} message={t(infoString)} width='50%' position={'right'} />}
                </div>
            </div>
            {children}
        </div>
    );
}
