export const tabsSidemenuTraining = [
    {
        type: 'training-settings',
        name: 'Training settings',
    },
    {
        type: 'learn-preview',
        name: 'Preview',
    },
    {
        type: 'topics',
        name: 'Topics',
    },
    {
        type: 'training-modules',
        name: 'Modules',
    },
    // {
    //     type: 'info',
    //     name: 'Info',
    // },
];

export const tabsSidemenuTopics = [
    {
        type: 'content',
        name: 'Content',
    },
    {
        type: 'questions',
        name: 'Questions',
    },
    {
        type: 'info',
        name: 'Info',
    },
];

export const tabsSidemenuRoles = [
    {
        type: 'settings',
        name: 'Settings',
    },
    {
        type: 'info',
        name: 'Info',
    },
];

export const tabsUserProfile = [
    {
        type: 'user-analytics',
        name: 'User analytics',
    },
    {
        type: 'training-analytics',
        name: 'Training analytics',
    },
    {
        type: 'profile-settings',
        name: 'Profile settings',
    },
];
