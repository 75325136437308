import React from 'react';

export default function TrafficAnswer({ children, id, correct }) {
    return (
        <div
            style={{
                whiteSpace: 'nowrap',
                margin: '0 7px',
                backgroundColor: correct ? '#febe10' : 'black',
                color: 'white',
                padding: '3px 15px',
                borderRadius: '15px',
                fontSize: '12px',
                transition: 'ease-in-out 0.5s',
            }}
            id={id}
        >
            {children}
        </div>
    );
}
