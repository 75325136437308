import { actionTypes } from './local-actions';

import _ from 'lodash';

export const insertionControlReducer = (state, action) => {

    switch (action.type) {
        case actionTypes.addToUndoStack: {
            let prevStackState = state.undoStack[action.payload.location];
            let undoStateToAdd = { state: action.payload.state, actionType: action.payload.actionType };
            return {
                ...state,
                undoStack: {
                    ...state.undoStack,
                    [action.payload.location]: prevStackState ? [...prevStackState, undoStateToAdd] : [undoStateToAdd]
                }
            };
        }
        case actionTypes.removeFromUndoStack: {
            let prevUndoStackState = state.undoStack[action.payload.location];
            let prevRedoStackState = state.redoStack[action.payload.location];

            let redoStateToAdd = { actionType: action.payload.actionType, state: action.payload.currentState };
            return {
                ...state,
                undoStack: {
                    ...state.undoStack,
                    [action.payload.location]: [...prevUndoStackState.slice(0, -1)]
                },
                redoStack: {
                    ...state.redoStack,
                    [action.payload.location]: prevRedoStackState ? [...prevRedoStackState, redoStateToAdd] : [redoStateToAdd]
                }
            };
        }
        case actionTypes.resetRedoStack: {
            return {
                ...state,
                redoStack: {
                    ...state.redoStack,
                    [action.payload.location]: []
                }
            };
        }
        case actionTypes.addToRedoStack: {
            let prevStackState = state.redoStack[action.payload.location];
            let redoStateToAdd = { state: action.payload.state, actionType: action.payload.actionType };
            return {
                ...state,
                redoStack: {
                    ...state.redoStack,
                    [action.payload.location]: prevStackState ? [...prevStackState, redoStateToAdd] : [redoStateToAdd]
                }
            };
        }
        case actionTypes.removeFromRedoStack: {
            let prevRedoStackState = state.redoStack[action.payload.location];
            let prevUndoStackState = state.undoStack[action.payload.location];

            let undoStateToAdd = { actionType: action.payload.actionType, state: action.payload.currentState };
            return {
                ...state,
                redoStack: {
                    ...state.redoStack,
                    [action.payload.location]: [...prevRedoStackState.slice(0, -1)]
                },
                undoStack: {
                    ...state.undoStack,
                    [action.payload.location]: prevUndoStackState ? [...prevUndoStackState, undoStateToAdd] : [undoStateToAdd]
                }
            };
        }
        case actionTypes.resetStacks: {
            if (action.payload) {
                return {
                    ...state,
                    undoStack: {
                        ...state.undoStack,
                        [action.payload]: []
                    },
                    redoStack: {
                        ...state.redoStack,
                        [action.payload]: []
                    }
                }
            } else {
                return {
                    ...state,
                    undoStack: [
                        { action: '', state: {} }
                    ],
                    redoStack: [
                        { action: '', state: {} }
                    ],
                }
            }

        }
        default: {
            throw new Error(`Unhandled type: ${action.type}`);
        }
    }
};
