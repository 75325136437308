import React from 'react';

import GenerationIndex from './GenerationIndex';
import './GenerationProgress.css';

export default function GenerationProgress({ currentIndex }) {
    function getStatus(index) {
        if (currentIndex === index) {
            return 'selected';
        } else if (index < currentIndex) {
            return 'finished';
        } else if (index > currentIndex) {
            return 'not-selected';
        }
    }
    return (
        <div className="generation-progress">
            <div className="generation-progress-container">
                <GenerationIndex title="Upload file" index={1} status={getStatus(1)} />
                <div className="progress-index--divider">
                    <div className={`progress-index--divider-outter ${currentIndex > 1 ? 'finished' : ''}`}></div>
                </div>
                <GenerationIndex title="Training title" index={2} status={getStatus(2)} />
                <div className="progress-index--divider">
                    <div className={`progress-index--divider-outter ${currentIndex > 2 ? 'finished' : ''}`}></div>
                </div>
                <GenerationIndex title="Check & Generate" index={3} status={getStatus(3)} />
                <div className="progress-index--divider">
                    <div className={`progress-index--divider-outter ${currentIndex > 3 ? 'finished' : ''}`}></div>
                </div>
                <GenerationIndex title="Preview training" index={4} status={getStatus(4)} />
            </div>
        </div>
    );
}
