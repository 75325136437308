import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { FileUploader } from 'components/features/forms/form/FileUploader';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { useDispatch, useSelector } from 'react-redux';
import {
    downloadTemplateAction,
    downloadUsersFullTemplateAction,
    uploadUsersAction,
    uploadUsersFullAction,
    uploadUserTemplateDoneAction,
} from 'redux/actions/users.actions';
import { stateIsLoaded, stateIsLoading } from 'redux/core/stateHelpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import readXlsxFile from 'read-excel-file';
import { parseExcelRows } from 'services/utils/parsers';
import { Typography } from '@material-ui/core';
import MultipleUsersInvite from './MultipleUsersInvite';

export const UploadUsersFull = ({ onChangeUsers }) => {
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);
    const uploadUsersState = useSelector(state => state.userManagement.uploadUsers);
    const [uploadDone, setUploadDone] = useState(false);
    const [failedInvites, setFailedInvites] = useState([]);
    const [file, setFile] = useState('');

    const [parsedUsers, setParsedUsers] = useState(null);

    function fileUpload() {
        setUploadDone(false);
        setFailedInvites([]);
        dispatch(uploadUsersFullAction(authState.data.access_token, file));
        setFile('');
    }
    function downloadTemplate() {
        dispatch(downloadUsersFullTemplateAction(authState.data.access_token));
    }
    if (stateIsLoaded(uploadUsersState) && !uploadDone) {
        if (!uploadDone) {
            setUploadDone(true);
            setFailedInvites(uploadUsersState.data);
        }
    }

    const handleFileChange = files => {
        if (files.length > 0) {
            setFile(files[0]);
        }
    };

    return (
        <div className={'row justify-content-center p-2'}>
            <div className="col-12 mt-2 d-flex justify-content-center">
                <Button
                    onClick={downloadTemplate}
                    className="download-template-users"
                    variant="contained"
                    color="secondary"
                    startIcon={<GetAppIcon />}
                >
                    Download template
                </Button>
                {stateIsLoading(uploadUsersState) && (
                    <CircularProgress style={{ position: 'absolute', zIndex: 2, marginLeft: 10 }} size={35} className={'text-info'} />
                )}
                <Button
                    disabled={file.length < 1}
                    onClick={fileUpload}
                    className={'ml-2'}
                    style={{ textTransform: 'none' }}
                    variant="contained"
                    color="secondary"
                    startIcon={<PublishIcon />}
                >
                    Upload users
                </Button>
            </div>

            <div className="col-12 p-4 pt-2">
                <div style={{ display: 'block' }} className="mx-3">
                    <FileUploader
                        dropzoneText={<Typography variant="subtitle1">Drag and drop the file here or click to upload</Typography>}
                        acceptedFiles={['.xlsx']}
                        filesLimit={1}
                        // onChange={file => setFile(file)}
                        // onDrop={files => setFile(files[0])}
                        onChange={handleFileChange}
                        dropzoneClass="users-upload"
                    ></FileUploader>
                </div>
            </div>
        </div>
    );
};
