import React, { useState } from 'react';
import { colors } from 'style/colors';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Body2 } from 'style/typography/Body';

import Search from 'assets/icons/filter-search.svg';

import './AnimatedTextField.css';

export function AnimatedTextField({
    id,
    width = "300px",
    height,
    label,
    type,
    placeholder,
    onFocus,
    setError,
    value,
    setValue,
    defaultValue,
    disabled,
    visibilityIcon,
    isSearch,
    onBlur,
}) {
    const [showValue, setShowValue] = useState(false);

    const handleChange = e => {
        setValue && setValue(e.target.value);
    };

    return (
        <div className="d-flex flex-row input-field">
            <input
                id={id}
                defaultValue={defaultValue}
                value={value}
                style={{ width: width }}
                disabled={disabled}
                onFocus={() => {
                    if (setError) {
                        setError(false);
                    }
                    if (onFocus) {
                        onFocus();
                    }
                }}
                onBlur={() => {
                    if (onBlur) {
                        onBlur();
                    }
                }}
                type={type === 'password' ? (showValue ? 'text' : 'password') : 'text'}
                placeholder={placeholder}
                onChange={handleChange}
                spellCheck={false}
                required={true}
            />
            <label>
                <Body2 color={colors.gray6}>{label}</Body2>
            </label>
            <div className="d-flex flex-row align-items-center">
                {isSearch && <img alt="Search" style={{ height: 20, width: 20 }} className={'mr-1'} color={colors.gray1} src={Search} />}
                {visibilityIcon && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowValue(!showValue)}
                            onMouseDown={event => event.preventDefault()}
                        >
                            {showValue ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )}
            </div>
        </div>
    );
}

AnimatedTextField.propTypes = {
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    height: PropTypes.any,
    id: PropTypes.any,
    isSearch: PropTypes.bool,
    onBlur: PropTypes.func,
    placeholder: PropTypes.any,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.any,
    visibilityIcon: PropTypes.any,
    width: PropTypes.any,
};
