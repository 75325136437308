import { colors } from 'style/colors';
import WarningIcon from 'assets/icons/warning-icon.png';
import { ModalDialog } from 'components/features/modal/ModalDialog';
import React from 'react';

export function TreeErrorDialog({ modalOpen, setModalOpen, message }) {
    return (
        <ModalDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onAction={() => setModalOpen(false)}
            title={'Tree structure error!'}
            text={message}
            buttons={[
                {
                    title: 'OK',
                    color: colors.white,
                },
            ]}
            hasCancel={false}
            image={WarningIcon}
        ></ModalDialog>
    );
}
