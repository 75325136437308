import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import App from './App';
import store from './redux/config/store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-sortable-tree/style.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as Sentry from '@sentry/react';
import './i18nConfig';

Sentry.init({
    dsn: 'https://2e49ab05e053549afba3b4591339291f@o422782.ingest.sentry.io/4505629437394944',
    autoSessionTracking: true,
    tracePropagationTargets: ['localhost'],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.2,
    beforeSend: event => {
        if (window.location.hostname === 'localhost') {
            console.log('Event not sent: ', event);
            return null;
        }
        return event;
    },
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
    <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
            <App />
        </DndProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
