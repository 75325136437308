import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { deleteFolderAction } from 'redux/actions/image.actions';
import { colors } from 'style/colors';
import { Body2 } from 'style/typography/Body';
import { DeleteFolderDialog } from './DeleteFolderDialog';

import Trash from 'assets/icons/icon-delete.svg';
import Folder from 'assets/icons/icon-gallery-folder.svg';

import './CroppingModule.css';

export function CustomFolder({ setCurrentNode, currentNode, folder, setTreeData, treeData }) {
    const dispatch = useDispatch();
    const [hovered, setHovered] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const deleteFolder = () => {
        dispatch(deleteFolderAction(folder.treeIndex));
    };

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="d-flex flex-column justify-content-center align-items-center text-center cursor-pointer"
        >
            <DeleteFolderDialog modalOpen={modalOpen} setModalOpen={setModalOpen} onAction={deleteFolder}></DeleteFolderDialog>
            <div
                className="d-flex flex-row align-items-center ml-2 my-2 custom-folder--node"
                onClick={() => {
                    currentNode.expanded = true;
                    setTreeData([...treeData]);
                    setCurrentNode(folder);
                }}
            >
                <div style={{ position: 'relative' }}>
                    <img alt="Folder" src={Folder} style={{ width: 22, height: 22, marginleft: '10px', marginRight: '15px' }} />
                </div>

                <Body2 weight="medium" color={colors.dark}>
                    {folder.title}
                </Body2>
                <img
                    alt="Trash"
                    style={{ display: hovered ? 'block' : 'none' }}
                    className="ml-auto mr-2 custom-folder--icon-delete"
                    height={17}
                    width={20}
                    onClick={event => {
                        event.stopPropagation();
                        setModalOpen(true);
                    }}
                    src={Trash}
                />
            </div>
        </div>
    );
}

CustomFolder.propTypes = {
    currentNode: PropTypes.any,
    folder: PropTypes.any,
    setCurrentNode: PropTypes.func,
    setTreeData: PropTypes.func,
    treeData: PropTypes.any,
};
