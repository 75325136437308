import React from 'react';
import PreviewContent from 'components/features/content_management/PreviewContent';
import GameHeader from 'containers/layout/module/pages/content/questions/question_editor/games_preview/GameHeader';
import { GameQuestionLeft } from 'style/styled/GameElements';
import { useContentInsertionQuestion } from 'services/content-management/insertion-control';
import TrafficLines from './TrafficLines';

export default function TrafficPreview({ answers }) {
    const { question } = useContentInsertionQuestion();

    return (
        <PreviewContent game={'Traffic'}>
            <GameHeader />
            <GameQuestionLeft>{question || 'Question'}</GameQuestionLeft>
            <div style={{ position: 'absolute', bottom: '20%' }}>
                <TrafficLines answers={answers} />
            </div>
        </PreviewContent>
    );
}
