import React from 'react';

import GenerationStatusItem from './GenerationStatusItem';
import { useSelector } from 'react-redux';
import { Caption1 } from 'style/typography/Caption';
import { colors } from 'style/colors';

import '../GenerationTabs.css';
import { truncateString } from 'services/utils/stringHelpers';
import { useTranslation } from 'react-i18next';

export default function GenerationStatus() {
    const { t } = useTranslation();

    const generatingContentProgressState = useSelector(state => state.content.generatingContentProgress);

    let currentProcessIndex = generatingContentProgressState?.data?.completedProcesses;

    const getStatus = index => {
        if (currentProcessIndex === index) {
            if (generatingContentProgressState.data?.isError) {
                return 'error';
            }
            return 'loading';
        } else if (currentProcessIndex > index) {
            return 'finished';
        } else return 'pending';
    };

    return (
        <div className="generation-status--container">
            <GenerationStatusItem status={getStatus(1)} text={t('Parsing word document..')} />
            <GenerationStatusItem status={getStatus(2)} text={t('Converting parsed information to Coach-compatible data...')} />
            <GenerationStatusItem status={getStatus(3)} text={t('Saving newly created training, topics and lessons...')} />
            <GenerationStatusItem
                status={getStatus(4)}
                text={t('Patiently waiting for GPT to do its magic and generate questions. This may take a while...')}
            />
            <GenerationStatusItem status={getStatus(5)} text={t('Saving generated questions...')} />
            <GenerationStatusItem status={getStatus(6)} text={t('All done!')} />
            {generatingContentProgressState?.data?.isError && (
                <Caption1 style={{ marginTop: 30 }} color={colors.red}>
                    {truncateString(generatingContentProgressState.data.errorMessage, 200)}
                </Caption1>
            )}
        </div>
    );
}
